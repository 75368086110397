import certificates from "@/classes/objectTypes/mobileSecurity/certificates/certificates"
import apiKeys from "@/classes/objectTypes/unifiedSecurity/apiKeys/apiKeys"
import auditlog from "@/classes/objectTypes/unifiedSecurity/auditlog/auditlog"
import licenses from "@/classes/objectTypes/unifiedSecurity/licenses/licenses"
import roles from "@/classes/objectTypes/unifiedSecurity/roles/roles"
import users from "@/classes/objectTypes/unifiedSecurity/users/users"
import type { UserSession } from "../storeSession"
import androidDevices from "./mobileSecurity/android/androidDevices"
import androidProfiles from "./mobileSecurity/android/androidProfiles"
import zeroTouchConfigurations from "./mobileSecurity/android/zerotouchConfigurations"
import zeroTouchCustomers from "./mobileSecurity/android/zerotouchCustomers"
import zeroTouchDevices from "./mobileSecurity/android/zerotouchDevices"
import depDevices from "./mobileSecurity/apple/depDevices"
import depProfiles from "./mobileSecurity/apple/depProfiles"
import homescreenlayouts from "./mobileSecurity/apple/homescreenLayouts"
import iosApps from "./mobileSecurity/apple/iosApps"
import iosDevices from "./mobileSecurity/apple/iosDevices"
import iosProfiles from "./mobileSecurity/apple/iosProfiles"
import vppassets from "./mobileSecurity/apple/vppAssets"
import vppAssignments from "./mobileSecurity/apple/vppAssignments"
import vppUsers from "./mobileSecurity/apple/vppUsers"
import secureDnsProfiles from "./mobileSecurity/secure-dns/secure-dns"
import vpns from "./mobileSecurity/vpns/vpns"
import windowsEnrollmentTokens from "./mobileSecurity/windows/windowsEnrollmenttokens"
import windowsProfiles from "./mobileSecurity/windows/windowsProfiles"
import windowsVpns from "./mobileSecurity/windows/windowsVpns"
import windowsVpnStates from "./mobileSecurity/windows/windowsVpnStates"
import type ObjectType from "./objectType"
import reports from "./unifiedReporting/reports/reports"
import usrUtms from "./unifiedReporting/utms/usrUtms"
import activeUsers from "./unifiedSecurity/activeUsers/activeUsers"
import images from "./unifiedSecurity/images/images"
import notifications from "./unifiedSecurity/notifications/notifications"
import utmNodes from "./unifiedSecurityConsole/nodes"
import { useUscSettingsStore } from "./unifiedSecurityConsole/settingsStore"
import topologies from "./unifiedSecurityConsole/topologies"
import uscProfiles from "./unifiedSecurityConsole/uscProfiles"
import uscUtms from "./unifiedSecurityConsole/uscUtms"
import uscUtmStates from "./unifiedSecurityConsole/uscUtmStates"
import uscZtProfiles from "./unifiedSecurityConsole/uscZtProfile"

const products = {
    mobileSecurity: {
        iosApps,
        iosDevices,
        depDevices,
        vppassets,
        vppAssignments,
        vppUsers,
        androidDevices,
        androidProfiles,
        certificates,
        vpns,
        zeroTouchCustomers,
        zeroTouchConfigurations,
        zeroTouchDevices,
        iosProfiles,
        homescreenlayouts,
        depProfiles,

        activeUsers
    },
    unifiedSecurity: {
        licenses,
        auditlog,
        roles,
        users,
        apiKeys,
        notifications,
        images
    },
    unifiedReporting: {
        reports,
        usrUtms
    },
    unifiedSecurityConsole: {
        uscUtms,
        uscUtmStates,
        uscProfiles,
        uscZtProfiles,
        topologies,
        utmNodes,
        windowsEnrollmentTokens,
        windowsVpnStates,
        windowsVpns,
        windowsProfiles
    },
    secureDns: {
        secureDnsProfiles
    }
}

export const allObjectTypes = {
    ...(function () {
        let result: { [name: string]: ObjectType<any> } = {}
        Object.keys(products).forEach((productName) => {
            let product = products[productName as keyof typeof products]
            Object.keys(product).forEach((objectTypeName) => {
                result[objectTypeName] = product[objectTypeName as keyof typeof product]
            })
        })
        return result
    })()
}
export const prepareObjectTypeStores = async (session: UserSession) => {
    const tenantArray = session.scopes.list_accounts?.map((accountInfo) => {
        return {
            tenantDomain: accountInfo.accountid + ".sms"
        }
    })
    Object.keys(allObjectTypes).forEach((objectTypeName) => {
        const objectType = allObjectTypes[objectTypeName] as ObjectType<any>
        const objectTypeStore = objectType.useStore?.()
        if (objectTypeStore) {
            objectTypeStore.setAccounts(tenantArray)
        }
    })
    useUscSettingsStore().setAccounts(tenantArray)
}
export const clearObjectTypeStores = async () => {
    Object.keys(allObjectTypes).forEach((objectTypeName) => {
        const objectType = allObjectTypes[objectTypeName] as ObjectType<any>
        const objectTypeStore = objectType.useStore?.()
        if (objectTypeStore) {
            objectTypeStore.clearStore()
        }
    })
}
export default products
