import { useVue } from "@/app"
import i18n, { T } from "@/classes/i18n"
import type { UserSession } from "@/classes/storeSession"
import getterHelpers from "@/helpers/helpers.getters"
import tenantHelpers from "@/helpers/helpers.tenants"
import queries from "@/queries/queries"
import router from "@/router/router"
import { ActionTypes, MutationTypes, useStore } from "@/store/vuex.store"
import dialogs from "./dialogs"

const unifiedSecurityDialogs = {
    loginDialog: async function (redirectTo?: string) {
        let modal: any = {
            id: "login",
            abortable: false,
            content: {
                title: {
                    text: T("Login"),
                    icon: "fal fa-sign-in-alt"
                },
                body: {
                    use: true,
                    component: "login-dialog",
                    properties: {
                        redirectTo: redirectTo
                    }
                }
            },
            buttons: [
                {
                    text: T("Abort"),
                    icon: "fal fa-times",
                    onClick: async function () {
                        router.navigate("/sms/#logout")
                        useStore().commit(MutationTypes.removeModal, {})
                    },
                    align: "left",
                    loading: false
                }
            ]
        }
        useStore().commit(MutationTypes.addModal, modal)
    },
    setupTenant: async function (accountid: string) {
        let licenseTerms: string = await $.get(
            "client/assets/common/license-conditions/dist/html/" +
                (i18n.getLanguage().toLowerCase() || "en") +
                "/mobile-security-licence-conditions.html"
        )
        let modal: any = {
            id: "tenantSetup",
            abortable: false,
            content: {
                title: {
                    text: T("License Conditions"),
                    icon: "fal fa-info-circle"
                },
                body: {
                    use: true,
                    content: '<div class="box box-shadow padding-xs-2">' + licenseTerms + "</div>"
                }
            },
            buttons: [
                {
                    text: T("Decline"),
                    icon: "fal fa-times",
                    onClick: async function () {
                        const targetDomain = tenantHelpers.getAccountId(
                            (useStore().state.session.userInfo as UserSession).targetDomain
                        )
                        const activeAccountId: string | undefined =
                            useStore().state.session.activeAccountId || ""
                        const isResellerAccount =
                            useStore().state.session.accounts[targetDomain]?.unifiedSecurity.account
                                .parent_accountid == null

                        if (
                            isResellerAccount &&
                            targetDomain != activeAccountId &&
                            targetDomain !== "undefined" &&
                            targetDomain != ""
                        ) {
                            await useStore().dispatch(ActionTypes.switchAccount, targetDomain)
                            useStore().commit(MutationTypes.removeModal, {})
                        } else {
                            router.navigate("#logout")
                        }
                    },
                    align: "left",
                    loading: false
                },
                {
                    text: T("Accept"),
                    icon: "fal fa-check",
                    onClick: async function () {
                        this.loading = true
                        try {
                            await queries.unifiedSecurity.setTenantProperty(accountid, "setup", "1")
                        } catch (e: any) {
                            console.error(e)
                        }
                        await useStore().dispatch(ActionTypes.updateAccount, accountid)
                        useStore().commit(MutationTypes.removeModal, {})
                    },
                    align: "right",
                    loading: false
                }
            ]
        }
        getterHelpers.useStore().dispatch(ActionTypes.addModal, modal)
    },
    disablePrivacy: async function (submit: any, abort: any) {
        let modal: any = {
            id: "disablePrivacy",
            abortable: true,
            content: {
                title: {
                    text: T("Reason"),
                    icon: "fal fa-info-circle"
                },
                body: {
                    use: true,
                    content: `<textarea id="reasonInput" placeholder="${T("Reason")}"></textarea>`
                }
            },
            onAbort: function () {
                abort()
            },
            buttons: [
                {
                    text: T("Cancel"),
                    icon: "fal fa-times",
                    onClick: async function () {
                        abort()
                        getterHelpers.useStore().commit(MutationTypes.removeModal)
                    },
                    align: "left",
                    loading: false
                },
                {
                    text: T("Save"),
                    icon: "fal fa-save",
                    onClick: async function () {
                        this.loading = true
                        await submit($("textarea#reasonInput").val())
                        getterHelpers.useStore().commit(MutationTypes.removeModal)
                    },
                    align: "right",
                    loading: false,
                    hasLocation: false
                }
            ]
        }
        useVue().$refs.modals.addModal(modal)
    },
    setInventoryLocation: function (
        inventoryLocation: object,
        onSubmit: (
            inventoryLocation: ObjectInventory["inventoryLocation"],
            inventoryLocationDescription?: string
        ) => void,
        inventoryLocationDescription?: string
    ) {
        let modal: any = {
            id: "updateInventoryLocation",
            abortable: true,
            content: {
                title: {
                    text: T("Update inventory location"),
                    icon: "fal fa-edit"
                },
                body: {
                    use: true,
                    content: undefined,
                    component: "set-inventory-location-dialog",
                    properties: {
                        callback: onSubmit,
                        inventoryLocation: inventoryLocation,
                        inventoryLocationDescription: inventoryLocationDescription
                    }
                }
            },
            onAbort: function () {
                getterHelpers.useStore().commit(MutationTypes.removeModal)
            },
            buttons: [
                {
                    text: T("Cancel"),
                    icon: "fal fa-times",
                    onClick: async function () {
                        getterHelpers.useStore().commit(MutationTypes.removeModal)
                    },
                    align: "left",
                    loading: false
                }
            ]
        }
        useVue().$refs.modals.addModal(modal)
    },

    renderDeleteNotificationDialog: async function (tenantDomain: string, notificationId: string) {
        const accountId = tenantHelpers.getAccountId(tenantDomain)
        dialogs.misc.confirmDialog(
            accountId,
            T("Confirm Delete"),
            T("Do you want to delete the notification") + "?",
            async () => {
                await useStore().dispatch(ActionTypes.deleteObject, {
                    accountId: useStore().state.session.activeAccountId,
                    objectType: "notifications",
                    productType: "unifiedSecurity",
                    objectIdProperty: "serviceId",
                    objectId: notificationId
                })
                useStore().commit(MutationTypes.removeModal, { accountId: accountId })
            }
        )
    }
}
export default unifiedSecurityDialogs
