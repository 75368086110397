<template>
    <table v-if="modelValue.length > 0" @mouseup="stopDragging" @mouseleave="stopDragging">
        <thead>
            <tr>
                <th>{{ T("Category") }}</th>
                <th v-for="hour in 24" :key="hour">
                    {{ hour - 1 }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="category in modelValue" :key="category.id">
                <td>{{ T(cfCategories[category.id].category) }}</td>
                <template v-for="hour in 24" :key="hour"></template>
                <td
                    v-for="hour in 24"
                    :key="hour"
                    @mousedown="startDragging(category, hour - 1)"
                    @mouseenter="dragToCell(category, hour - 1)"
                >
                    <div class="cell-content time-label" :class="getCellClass(category, hour - 1)">
                        <span v-if="showHour(category, hour - 1)" class="hour">{{ hour - 1 }}</span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <div>
        <inputVueSelect
            v-model="newCategories"
            :multiple="true"
            :placeholder="T('Add entries')"
            :selectOptions="getCategoriesObject()"
        />

        <div class="padding-xs-t">
            <a class="btn btn-primary" v-on:click="selectAll()">
                <i class="fal fa-plus"></i> {{ T("Select all") }}
            </a>

            <a class="btn btn-primary" v-on:click="deselectAll()">
                <i class="fal fa-minus"></i> {{ T("Deselect all") }}
            </a>
        </div>
    </div>
</template>

<script setup lang="ts">
import { T } from "@/classes/i18n"
import { onMounted, ref, watch } from "vue"
import { useStore } from "vuex"
import inputVueSelect from "./input-vue-select.vue"

type TRow = {
    id: string
    schedule: number[]
}

const props = defineProps<{
    modelValue: TRow[]
}>()
const emit = defineEmits(["update:modelValue"])

const newCategories = ref<string[]>([])

const isDragging = ref(false)
const dragValue = ref(0)
const currentCategory = ref<TRow | null>(null)
const cfCategories = useStore().state?.resources?.ContentFilterCategories as Record<
    string,
    { ip: string; category: string }
>

const defaultState = 0 // red

onMounted(() => {
    newCategories.value = props.modelValue.map((c) => c.id)
})

watch(newCategories, (val) => {
    // new categories
    val.forEach((ip) => {
        if (!props.modelValue.find((c) => c.id === ip)) {
            props.modelValue.push({
                id: ip,
                schedule: new Array(24).fill(defaultState)
            })
        }
    })

    // remove old categories
    emit(
        "update:modelValue",
        props.modelValue.filter((c) => val.includes(c.id))
    )
})

function getCellClass(category: TRow, hour: number) {
    const value = category.schedule[hour]
    const prevValue = hour > 0 ? category.schedule[hour - 1] : null
    const nextValue = hour < 23 ? category.schedule[hour + 1] : null

    const isStartOfPeriod = value === 1 && (prevValue === 0 || prevValue === undefined)
    const isEndOfPeriod = value === 1 && (nextValue === 0 || nextValue === undefined)

    const isBeforePeriod = value === 0 && prevValue === 1
    const isAfterPeriod = value === 0 && nextValue === 1

    const isFirstHour = hour === 0
    const isLastHour = hour === 23

    return {
        red: value === 0,
        green: value === 1,
        "rounded-top": isStartOfPeriod || isFirstHour || isBeforePeriod,
        "rounded-bottom": isEndOfPeriod || isLastHour || isAfterPeriod
    }
}

function showHour(category: TRow, hour: number) {
    const value = category.schedule[hour]
    const prevValue = hour > 0 ? category.schedule[hour - 1] : null
    const nextValue = hour < 23 ? category.schedule[hour + 1] : null

    const isStartOfPeriod = value === 1 && (prevValue === 0 || prevValue === undefined)
    const isEndOfPeriod = value === 1 && (nextValue === 0 || nextValue === undefined)

    const isBeforePeriod = value === 0 && prevValue === 1
    const isAfterPeriod = value === 0 && nextValue === 1

    const isFirstHour = hour === 0
    const isLastHour = hour === 23

    return (
        isFirstHour ||
        isLastHour ||
        isStartOfPeriod ||
        isEndOfPeriod ||
        isBeforePeriod ||
        isAfterPeriod
    )
}

function startDragging(category: TRow, hour: number) {
    isDragging.value = true
    currentCategory.value = category
    dragValue.value = (category.schedule[hour] + 1) % 2
    updateCell(category, hour)
}

function dragToCell(category: TRow, hour: number) {
    if (isDragging.value && currentCategory.value === category) {
        updateCell(category, hour)
    }
}

function stopDragging() {
    isDragging.value = false
    currentCategory.value = null
}

function updateCell(category: TRow, hour: number) {
    category.schedule[hour] = dragValue.value
}

function getCategoriesObject() {
    const ignoredCats = new Set(["127.0.99.99"])

    return Object.values(cfCategories || [])
        .map((info) => {
            return {
                id: info.ip,
                text: info.category
            }
        })
        .filter((category) => !ignoredCats.has(category.id))
}

function selectAll() {
    newCategories.value = Object.keys(cfCategories)
}

function deselectAll() {
    newCategories.value = []
}
</script>

<style scoped>
table {
    width: 100%;
    text-align: left;
    border: 0;
    border-collapse: collapse;
    padding: 0;
    margin: 1.6em 0;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
    border-top: 2px solid rgba(0, 0, 0, 0.05);
}

tr td,
tr th {
    padding: 0.5em;
    text-align: left;
}

td:first-child,
th:first-child {
    padding-right: 2em;
    white-space: nowrap;
}
tr td {
    padding-left: 0;
    padding-right: 0;
}

th,
td {
    text-align: center;
    border: none;
}

th {
    font-weight: bold;
}

.cell-content {
    height: 20px;
    margin: 0 auto;
}

.red {
    background-color: #e74c3c;
}

.green {
    background-color: #2dcc71;
}

.rounded-top {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    margin-left: 1px;
}

.rounded-bottom {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    margin-right: 1px;
}

.time-label {
    display: flex;
    align-items: center;
    justify-content: center;
}

.hour {
    color: white;
    font-weight: bold;
    text-align: center;
}
</style>
