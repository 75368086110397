<script setup lang="ts">
import config from "@/classes/config"
import { T } from "@/classes/i18n"
import objectStores from "@/classes/init"
import products from "@/classes/objectTypes"
import { type AndroidDevice } from "@/classes/objectTypes/mobileSecurity/android/androidDevices"
import { type EnterpriseDevice } from "@/classes/objectTypes/mobileSecurity/android/enterpriseDevices"
import { type AppleDevice } from "@/classes/objectTypes/mobileSecurity/apple/iosDevices"
import { type TSecureDns } from "@/classes/objectTypes/mobileSecurity/secure-dns/secure-dns"
import { type Vpn } from "@/classes/objectTypes/mobileSecurity/vpns/vpns"
import { WindowsVpn } from "@/classes/objectTypes/mobileSecurity/windows/windowsVpns"
import { type License } from "@/classes/objectTypes/unifiedSecurity/licenses/licenses"
import dialogs from "@/dialogs/dialogs"
import arrayHelpers from "@/helpers/helpers.arrays"
import deviceHelpers from "@/helpers/helpers.devices"
import getterHelpers from "@/helpers/helpers.getters"
import licenseHelpers from "@/helpers/helpers.license"
import mixinHelpers from "@/helpers/helpers.mixins"
import tenantHelpers from "@/helpers/helpers.tenants"
import timeHelpers from "@/helpers/helpers.time"
import queries from "@/queries/queries"
import requestHandler from "@/queries/requests"
import router from "@/router/router"
import useRouterStore from "@/router/routerStore"
import { ActionTypes, useStore } from "@/store/vuex.store"
import Button from "@/templates/components/button/button"
import { computed, onMounted, ref, useTemplateRef } from "vue"
import buttonComponent from "../components/button/button.vue"
import loaderComponent from "../components/loader.vue"
import inputNumber from "../inputtypes/input-number.vue"
import inputVueSelect from "../inputtypes/input-vue-select.vue"
// REF
const initialized = ref(false)
const activeTab = ref("general")
const loader = ref({
    loading: true,
    saving: false,
    loaderText: "Loading..."
})
const devicesValue = ref(<string[]>[])
const enrollmentLimit = ref(<number>-1)
const enrollmentSaveButton = ref(<Button | undefined>undefined)
const enrollmentDeleteButton = ref(<Button | undefined>undefined)
const defaultLicenseButton = ref(<Button | undefined>undefined)

const licenseAssignmentSelectRef = useTemplateRef("licenseAssignmentSelect")

//COMPUTED
const activeAccountId = computed(() => {
    return getterHelpers.useStore()?.state.session.activeAccountId || ""
})
const account = computed(() => {
    return getterHelpers.useStore()?.state.session.accounts[activeAccountId.value] || ""
})
const isUserSupport = computed(() => {
    return (
        getterHelpers.useStore()?.state?.session?.userInfo?.roles?.indexOf("__SUPPORT__") != -1 ||
        undefined
    )
})
const objectId = computed(() => {
    return useRouterStore().getObjectId || ""
})
const darkmode = computed(() => {
    return useStore().state.browserSettings.darkmode == "1"
})
const licenses = computed(() => {
    if (config.canUseNewObjectType("licenses")) {
        return (products.unifiedSecurity.licenses
            .useStore?.()
            ?.getObjectStoreObjects(activeAccountId.value) || []) as License[]
    } else {
        return (account.value.unifiedSecurity?.licenses?.items || []) as License[]
    }
})
const license = computed(() => {
    return (
        licenses.value.find(function (license: any) {
            return license.id == objectId.value
        }) || undefined
    )
})

const devices = computed(() => {
    return products.mobileSecurity.iosDevices.sortIosDevicesByAliasId(
        (config.canUseNewObjectType("iosDevices")
            ? products.mobileSecurity.iosDevices
                  .useStore?.()
                  .getObjectStoreObjects(activeAccountId.value)
            : account.value.mobileSecurity?.devices?.items) || []
    ) as AppleDevice[]
})
const enterpriseDevices = computed(() => {
    return products.mobileSecurity.androidDevices.sortAndroidDevicesByAliasId(
        (config.canUseNewObjectType("androidDevices")
            ? products.mobileSecurity.androidDevices
                  .useStore?.()
                  .getObjectStoreObjects(activeAccountId.value)
            : account.value.mobileSecurity?.enterpriseDevices?.items) || []
    )
})
const windowsVpns = computed(() => {
    return (
        products.unifiedSecurityConsole.windowsVpns
            .useStore?.()
            .getObjectStoreObjects(activeAccountId.value) || []
    ).filter((vpn) => {
        return vpn.licenseUUID != undefined && vpn.licenseUUID != ""
    })
})
const vpns = computed(() => {
    return arrayHelpers.sortByObjProperty(
        (config.canUseNewObjectType("vpns")
            ? products.mobileSecurity.vpns.useStore?.().getObjectStoreObjects(activeAccountId.value)
            : account.value.mobileSecurity?.vpns?.items) || [],
        "vpnname"
    ) as Vpn[]
})
const secureDnsProfiles = computed(() => {
    return (
        products.secureDns.secureDnsProfiles
            .useStore?.()
            .getObjectStoreObjects(activeAccountId.value) || []
    ).filter((profile) => {
        return !!profile.licenseUUID
    })
})

const assignment = computed(() => {
    return {
        thisDevices: devices.value.filter((device: any) => {
            return device.licenseUUID == license.value?.uuid
        }),
        thisEnterpriseDevices: enterpriseDevices.value.filter((device: any) => {
            return device.licenseUUID == license.value?.uuid
        }),
        thisVpns: vpns.value.filter((vpn: any) => {
            return vpn.licenseUUID == license.value?.uuid
        }),
        thisWindowsVpns: windowsVpns.value.filter((vpn: any) => {
            return vpn.licenseUUID == license.value?.uuid
        }),
        thisSecureDnsProfiles: secureDnsProfiles.value.filter((profile) => {
            return profile.licenseUUID == license.value?.uuid
        })
    }
})
const isReseller = computed(() => {
    return useStore().state.session.isReseller || false
})
const licenseLimit = computed(() => {
    return license.value ? mixinHelpers.getLicenseLimit(license.value) : 0
})
const isMdmLicense = computed(() => {
    return ["MDM", "Mobile Security"].indexOf(license.value?.type || "") != -1
})
const canBeDefaultLicenseUuid = computed(() => {
    let result = false
    if (
        license.value &&
        license.value &&
        (license.value.type == "Mobile Security" || license.value.type == "MDM") &&
        license.value.uuid &&
        license.value.assignedUser < mixinHelpers.getLicenseLimit(license.value) &&
        !license.value.expired &&
        !license.value.dead
    ) {
        result = true
    }
    return result
})
const defaultLicenseUuid = computed(() => {
    return (
        useStore()?.state?.session?.accounts[activeAccountId.value]?.mobileSecurity?.settings
            ?.defaultDeviceLicenseUUID || ""
    )
})

// METHODS

const getDate = (timestamp: any, withTime: boolean) => {
    return timeHelpers.formatDate(timestamp, timeHelpers.getDateFormatI18n(withTime))
}
const setActiveTab = (tabName: string) => {
    activeTab.value = tabName
}
const init = async () => {
    loader.value.loading = true
    loader.value.loaderText = "Getting license..."
    await refresh()
    initialized.value = true
}
const refresh = async () => {
    loader.value.loaderText = "Getting license..."
    let hasObject: any = new Error()
    if (config.canUseNewObjectType("licenses")) {
        hasObject = await products.unifiedSecurity.licenses.queries.getObjectFromApi(
            activeAccountId.value,
            objectId.value
        )
    } else {
        hasObject = await useStore().dispatch(ActionTypes.getObjectInfo, {
            accountId: activeAccountId.value,
            objectType: "licenses",
            objectId: objectId.value,
            productType: "unifiedSecurity"
        })
    }

    if (!hasObject || hasObject instanceof Error) {
        router.navigate("404-" + objectId.value)
    }

    if (["Mobile Security", "MDM"].indexOf(license.value?.type || "") != -1) {
        enrollmentLimit.value = license.value ? mixinHelpers.getLicenseLimit(license.value) : 0
        loader.value.loaderText = "Getting devices..."
        let requesObjectTypes: string[] = []

        if (config.canUseNewObjectType("iosDevices")) {
            await products.mobileSecurity.iosDevices.queries.getObjectsFromApi(
                activeAccountId.value,
                undefined,
                [
                    { property: "props[]", value: "deviceId" },
                    { property: "props[]", value: "deviceType" },
                    { property: "props[]", value: "signedIn" },
                    { property: "props[]", value: "profile" },
                    { property: "props[]", value: "tags" },
                    { property: "props[]", value: "ownership" },
                    { property: "props[]", value: "lastContact" },
                    { property: "props[]", value: "configured" },
                    { property: "props[]", value: "props" },
                    { property: "props[]", value: "licenseUUID" },
                    { property: "props[]", value: "managedAppleId" },
                    { property: "props[]", value: "inventory" },
                    { property: "props[]", value: "alias" },
                    { property: "props[]", value: "info" },
                    { property: "props[]", value: "username" },
                    { property: "props[]", value: "serialNumber" }
                ]
            )
        } else {
            requesObjectTypes.push(
                "devices?props[]=deviceId&props[]=deviceType&props[]=signedIn&props[]=profile&props[]=tags&props[]=ownership&props[]=lastContact&props[]=managedAppleId&props[]=info&props[]=configured&props[]=props&props[]=licenseUUID&props[]=username&props[]=alias"
            )
        }

        if (tenantHelpers.hasFunctionality(activeAccountId.value, "enterprise")) {
            if (config.canUseNewObjectType("androidDevices")) {
                await products.mobileSecurity.androidDevices.queries.getObjectsFromApi(
                    activeAccountId.value,
                    undefined,
                    [
                        { property: "props[]", value: "deviceId" },
                        { property: "props[]", value: "deviceType" },
                        { property: "props[]", value: "signedIn" },
                        { property: "props[]", value: "profile" },
                        { property: "props[]", value: "tags" },
                        { property: "props[]", value: "ownership" },
                        { property: "props[]", value: "lastContact" },
                        { property: "props[]", value: "managedAppleId" },
                        { property: "props[]", value: "signedIn" },
                        { property: "props[]", value: "info" },
                        { property: "props[]", value: "configured" },
                        { property: "props[]", value: "props" },
                        { property: "props[]", value: "licenseUUID" },
                        { property: "props[]", value: "username" },
                        { property: "props[]", value: "name" },
                        { property: "props[]", value: "alias" }
                    ]
                )
            } else {
                requesObjectTypes.push(
                    "enterpriseDevices?props[]=appliedPolicyName&props[]=appliedState&props[]=name&props[]=enrollmentTime&props[]=props&props[]=hardwareInfo&props[]=lastStatusReportTime&props[]=managementMode&props[]=name&props[]=ownership&props[]=policyName&props[]=previousDeviceNames&props[]=state&props[]=alias&props[]=deviceId&props[]=username&props[]=consent&props[]=licenseUUID&props[]=signedIn&fields=*(name,policyName,appliedPolicyName,hardwareInfo,softwareInfo,lastStatusReportTime,previousDeviceNames,ownership,enrollmentTime,managementMode,networkInfo,nonComplianceDetails,state,appliedState)"
                )
            }
        }
        if (config.canUseNewObjectType("vpns")) {
            await products.mobileSecurity.vpns.queries.getObjectsFromApi(activeAccountId.value)
        } else {
            requesObjectTypes.push("vpns")
        }
        await products.unifiedSecurityConsole.windowsVpns.queries.getObjectsFromApi(
            activeAccountId.value
        )
        try {
            await products.secureDns.secureDnsProfiles.queries.getObjectsFromApi(
                activeAccountId.value
            )
        } catch (e) {}
        try {
            await useStore().dispatch(ActionTypes.getObjectInfos, {
                accountId: activeAccountId.value,
                objectTypes: requesObjectTypes
            })
        } catch (e) {}
    }
    loader.value.loading = false
    loader.value.loaderText = "Loading..."
    setDevicesValue()
    return true
}
const setDevicesValue = () => {
    arrayHelpers
        .sortByObjProperty(assignment.value.thisDevices, "alias", "ASC")
        .forEach((device: AppleDevice) => {
            let deviceId: string = device.deviceId
            if (devicesValue.value.indexOf(deviceId) == -1) {
                devicesValue.value.push(deviceId)
            }
        })

    arrayHelpers
        .sortByObjProperty(assignment.value.thisEnterpriseDevices, "alias", "ASC")
        .forEach((device: AndroidDevice) => {
            let deviceId: string = device.id
            if (devicesValue.value.indexOf(deviceId) == -1) {
                devicesValue.value.push(deviceId)
            }
        })

    arrayHelpers
        .sortByObjProperty(assignment.value.thisVpns, "vpnname", "ASC")
        .forEach((vpn: Vpn) => {
            let vpnId: string = vpn.vpnId
            if (devicesValue.value.indexOf(vpnId) == -1) {
                devicesValue.value.push(vpnId)
            }
        })

    arrayHelpers
        .sortByObjProperty(assignment.value.thisWindowsVpns, "alias", "ASC")
        .forEach((vpn: WindowsVpn) => {
            let vpnId: string = vpn.id
            if (devicesValue.value.indexOf(vpnId) == -1) {
                devicesValue.value.push(vpnId)
            }
        })

    arrayHelpers
        .sortByObjProperty(assignment.value.thisSecureDnsProfiles, "name", "ASC")
        .forEach((profile: TSecureDns) => {
            let profileId = profile.id
            if (devicesValue.value.indexOf(profileId) == -1) {
                devicesValue.value.push(profileId)
            }
        })
}
const getOptions = () => {
    let options: selectOption[] = []

    devices.value.forEach((device) => {
        let deviceId: string = device.deviceId
        let isDisabled: boolean = false
        let disabledReason: string = ""
        let licenseInfo = licenseHelpers.getLicenseByUUID(
            activeAccountId.value,
            device?.licenseUUID || ""
        )
        if (device.deviceId != undefined && !device.configured) {
            isDisabled = true
            disabledReason = T("Unconfigured")
        } else if (
            ["MDM", "Mobile Security"].indexOf(licenseInfo?.type || "") != -1 &&
            (licenseInfo?.expirationDate || 0) > new Date().toISOString().substring(0, 10) &&
            device.licenseUUID != license.value?.uuid
        ) {
            if (licenseInfo?.type == "MDM" && license.value?.type == "Mobile Security") {
            } else {
                isDisabled = true
                disabledReason = T("Assigned to another license")
            }
        } else if (device.signedIn === false) {
            isDisabled = true
            disabledReason = T("Signed out")
        }

        if (disabledReason.length > 0) {
            disabledReason = " (" + disabledReason + ")"
        }

        let thisOption: selectOption = {
            id: deviceId,
            icon: "fab fa-apple",
            text:
                deviceHelpers.getAliasedShortDeviceId(device.deviceId, device.alias) +
                disabledReason,
            locked: true,
            objectTypeName: "devices",
            disabled: license.value?.uuid != device.licenseUUID ? isDisabled : false
        }

        options.push(thisOption)
    })

    enterpriseDevices.value.forEach((device) => {
        let deviceId: string = device.id
        let isDisabled: boolean = false
        let disabledReason: string = ""
        let licenseInfo = licenseHelpers.getLicenseByUUID(
            activeAccountId.value,
            (<EnterpriseDevice>device)?.licenseUUID || ""
        )
        if (
            ["MDM", "Mobile Security"].indexOf(licenseInfo?.type || "") != -1 &&
            (licenseInfo?.expirationDate || 0) > new Date().toISOString().substring(0, 10) &&
            (<EnterpriseDevice>device).licenseUUID != license.value?.uuid
        ) {
            if (licenseInfo?.type == "MDM" && license.value?.type == "Mobile Security") {
            } else {
                isDisabled = true
                disabledReason = T("Assigned to another license")
            }
        }

        if (disabledReason.length > 0) {
            disabledReason = " (" + disabledReason + ")"
        }

        let thisOption: selectOption = {
            id: deviceId,
            icon: "fab fa-android",
            text:
                deviceHelpers.getAliasedShortDeviceId(device.id, (<EnterpriseDevice>device).alias) +
                disabledReason,
            locked: true,
            objectTypeName: "enterpriseDevices",
            disabled:
                license.value?.uuid != (<EnterpriseDevice>device).licenseUUID ? isDisabled : false
        }
        options.push(thisOption)
    })

    vpns.value.forEach((vpn) => {
        let isDisabled: boolean = false
        let disabledReason: string = ""
        let licenseInfo = licenseHelpers.getLicenseByUUID(
            activeAccountId.value,
            vpn?.licenseUUID || ""
        )
        if (
            ["MDM", "Mobile Security"].indexOf(licenseInfo?.type || "") != -1 &&
            (licenseInfo?.expirationDate || 0) > new Date().toISOString().substring(0, 10) &&
            vpn.licenseUUID != license.value?.uuid
        ) {
            isDisabled = true
            disabledReason = T("Assigned to another license")
        } else if (license.value?.type == "MDM") {
            isDisabled = true
            disabledReason = T("Incompatible with license")
        }

        if (disabledReason.length > 0) {
            disabledReason = " (" + disabledReason + ")"
        }

        let thisOption: selectOption = {
            id: vpn.vpnId,
            icon: "fal fa-fw fa-laptop",
            text: vpn.vpnname + disabledReason,
            locked: true,
            objectTypeName: "vpns",
            disabled: license.value?.uuid != vpn.licenseUUID ? isDisabled : false
        }

        options.push(thisOption)
    })
    windowsVpns.value.forEach((vpn) => {
        let isDisabled: boolean = false
        let disabledReason: string = ""
        let licenseInfo = licenseHelpers.getLicenseByUUID(
            activeAccountId.value,
            vpn?.licenseUUID || ""
        )
        if (
            ["MDM", "Mobile Security"].indexOf(licenseInfo?.type || "") != -1 &&
            (licenseInfo?.expirationDate || 0) > new Date().toISOString().substring(0, 10) &&
            vpn.licenseUUID != license.value?.uuid
        ) {
            isDisabled = true
            disabledReason = T("Assigned to another license")
        } else if (license.value?.type == "MDM") {
            isDisabled = true
            disabledReason = T("Incompatible with license")
        }

        if (disabledReason.length > 0) {
            disabledReason = " (" + disabledReason + ")"
        }

        let thisOption: selectOption = {
            id: vpn.id,
            icon: "fab fa-fw fa-windows",
            text: vpn.alias + disabledReason,
            locked: true,
            objectTypeName: "windowsVpns",
            disabled: license.value?.uuid != vpn.licenseUUID ? isDisabled : false
        }

        options.push(thisOption)
    })

    secureDnsProfiles.value.forEach((profile) => {
        options.push({
            id: profile.id,
            icon: "fal fa-fw fa-globe",
            text: `${profile.name} (${profile.devicesCount} ${T("users")})`,
            locked: true,
            objectTypeName: "secureDnsProfiles",
            disabled: true
        })
    })

    return arrayHelpers.sortByObjProperty(options, "text")
}

const openRSP = () => {
    let win: any
    if (document.location.origin.indexOf("portal-dev") != -1) {
        win = window.open(
            "https://devmy.securepoint.de/licenses/?search=" + objectId.value,
            "_blank"
        )
    } else {
        win = window.open("https://my.securepoint.de/licenses/?search=" + objectId.value, "_blank")
    }
    win.focus()
}
const onDeviceAdd = (option: selectOption) => {
    if (license.value != undefined) {
        dialogs.misc.confirmDialog(
            activeAccountId.value,
            "Confirm assignment",
            T("Do you want to assign that device to this license?"),
            async () => {
                if (license.value != undefined) {
                    try {
                        switch (option.objectTypeName) {
                            case "androidDevices":
                                if (config.canUseNewObjectType("androidDevices")) {
                                    await products.mobileSecurity.androidDevices.queries.updateObjectPropertiesFromApi(
                                        activeAccountId.value,
                                        option.id as string,
                                        { licenseUUID: license.value?.uuid },
                                        "licenseUUID",
                                        undefined,
                                        undefined,
                                        "/properties?updateMask[]=licenseUUID"
                                    )
                                } else {
                                    await queries.mobileSecurity.setDeviceProperty(
                                        activeAccountId.value,
                                        option.id as string,
                                        "licenseUUID",
                                        license.value?.uuid
                                    )
                                }
                                break
                            case "devices":
                            case "iosDevices":
                                await queries.mobileSecurity.setDeviceProperty(
                                    activeAccountId.value,
                                    option.id as string,
                                    "licenseUUID",
                                    license.value?.uuid
                                )
                                break
                            case "enterpriseDevices":
                                await queries.mobileSecurity.setEnterpriseDeviceProperty(
                                    activeAccountId.value,
                                    option.id as string,
                                    ["licenseUUID"],
                                    { licenseUUID: license.value?.uuid }
                                )
                                break
                            case "vpns":
                                if (config.canUseNewObjectType("vpns")) {
                                    await products.mobileSecurity.vpns.queries.updateObjectPropertiesFromApi(
                                        activeAccountId.value,
                                        option.id as string,
                                        license.value.uuid,
                                        "licenseUUID",
                                        undefined,
                                        undefined,
                                        "/properties/licenseUUID"
                                    )
                                } else {
                                    await queries.mobileSecurity.setVPNProperty(
                                        tenantHelpers.getTenantDomain(activeAccountId.value),
                                        option.id as string,
                                        "licenseUUID",
                                        license.value.uuid
                                    )
                                }

                                break
                        }
                        getterHelpers
                            .useStore()
                            .dispatch(ActionTypes.updateLicenseQuota, activeAccountId.value)
                        //devicesValue.value.push(option.id)
                    } catch (e: any) {
                        console.error(e)
                    }
                    refresh()
                }
            },
            undefined,
            undefined,
            undefined,
            true,
            undefined,
            async () => {
                devicesValue.value = devicesValue.value.filter((deviceId: string) => {
                    return deviceId !== option.id
                })
                licenseAssignmentSelectRef.value?.updateModelValue()
                return true
            }
        )
    }
}
const saveEnrollmentLimit = async () => {
    if (
        license.value &&
        <number>enrollmentLimit.value >= license.value.assignedUser &&
        <number>enrollmentLimit.value < 5001 &&
        isMdmLicense &&
        license.value.isAllowedToSetLimit
    ) {
        enrollmentSaveButton.value?.startLoader()
        enrollmentSaveButton.value?.disable()
        enrollmentDeleteButton.value?.disable()
        let result: any
        // Set EnrollmentLimit
        if (config.canUseNewObjectType("licenses")) {
            result =
                await products.unifiedSecurity.licenses.queries.updateEnrollmentlimitFromLicense?.(
                    activeAccountId.value,
                    objectId.value,
                    enrollmentLimit.value
                )
        } else {
            result = await objectStores["licenses"].updateEnrollmentlimitFromLicense(
                activeAccountId.value,
                objectId.value,
                enrollmentLimit.value
            )
            await objectStores["licenses"].getObjectFromApi(activeAccountId.value, objectId.value)
        }
        // Getting new EnrollmentAllowed
        try {
            if (
                useStore()?.state.session.accounts[activeAccountId.value]?.mobileSecurity?.settings
                    ?.enrollmentAllowed != undefined
            ) {
                let enrollmentAllowed =
                    useStore().state.session.accounts[activeAccountId.value].mobileSecurity.settings
                        .enrollmentAllowed
                enrollmentAllowed = await requestHandler.request(
                    "GET",
                    "/sms-mgt-api/api/2.0/tenants/" +
                        tenantHelpers.getTenantDomain(activeAccountId.value) +
                        "?props[]=enrollmentAllowed"
                )
                enrollmentAllowed = enrollmentAllowed.enrollmentAllowed
                useStore().state.session.accounts[
                    activeAccountId.value
                ].mobileSecurity.settings.enrollmentAllowed = enrollmentAllowed
            }
            getterHelpers.useStore().dispatch(ActionTypes.updateLicenseQuota, activeAccountId.value)
        } catch (e) {
            console.error(e)
        }
        result = result
        enrollmentLimit.value = license.value.enrollmentLimit
        enrollmentSaveButton.value?.stopLoader()
        enrollmentSaveButton.value?.enable()
        enrollmentDeleteButton.value?.enable()
    }
}
const deleteEntrollmentLimit = async () => {
    if (isMdmLicense && license.value?.isAllowedToSetLimit)
        dialogs.misc.confirmDialog(
            activeAccountId.value,
            T("Delete Device Limit"),
            T(
                "If you delete the device limit the tenant can enroll the maximum amount of devices from the license. The maximum amount is %s"
            ).replace("%s", license.value.user),
            async () => {
                if (license.value != undefined) {
                    try {
                        enrollmentSaveButton.value?.disable()
                        enrollmentDeleteButton.value?.startLoader()
                        enrollmentDeleteButton.value?.disable()

                        let result: any = undefined
                        // Set EnrollmentLimit
                        if (config.canUseNewObjectType("licenses")) {
                            result =
                                await products.unifiedSecurity.licenses.queries.deleteEntrollmentlimitFromLicense?.(
                                    activeAccountId.value,
                                    objectId.value
                                )
                        } else {
                            result = await objectStores[
                                "licenses"
                            ].deleteEntrollmentlimitFromLicense(
                                activeAccountId.value,
                                objectId.value
                            )
                            await objectStores["licenses"].getObjectFromApi(
                                activeAccountId.value,
                                objectId.value
                            )
                        }

                        enrollmentLimit.value = license.value.user
                        result = result

                        // Getting new EnrollmentAllowed
                        try {
                            if (
                                useStore()?.state.session.accounts[activeAccountId.value]
                                    ?.mobileSecurity?.settings?.enrollmentAllowed != undefined
                            ) {
                                let enrollmentAllowed =
                                    useStore().state.session.accounts[activeAccountId.value]
                                        .mobileSecurity.settings.enrollmentAllowed
                                enrollmentAllowed = await requestHandler.request(
                                    "GET",
                                    "/sms-mgt-api/api/2.0/tenants/" +
                                        tenantHelpers.getTenantDomain(activeAccountId.value) +
                                        "?props[]=enrollmentAllowed"
                                )
                                enrollmentAllowed = enrollmentAllowed.enrollmentAllowed
                                useStore().state.session.accounts[
                                    activeAccountId.value
                                ].mobileSecurity.settings.enrollmentAllowed = enrollmentAllowed
                            }
                        } catch (e) {
                            console.error(e)
                        }
                    } catch (e) {
                        console.error(e)
                    } finally {
                        enrollmentDeleteButton.value?.stopLoader()
                        enrollmentDeleteButton.value?.enable()
                        enrollmentSaveButton.value?.enable()
                    }
                }
            }
        )
}
const beforeFilterInput = () => {
    if (enrollmentDeleteButton.value != undefined) {
        enrollmentDeleteButton.value.disable()
    }
    if (enrollmentSaveButton.value != undefined) {
        enrollmentSaveButton.value.disable()
    }
}
const afterFilterInput = () => {
    if (enrollmentDeleteButton.value != undefined) {
        enrollmentDeleteButton.value.enable()
    }
    if (enrollmentSaveButton.value != undefined) {
        enrollmentSaveButton.value.enable()
    }
}

// EVENTS

onMounted(() => {
    if (
        isMdmLicense.value &&
        (isReseller.value || isUserSupport.value) &&
        license.value?.isAllowedToSetLimit
    ) {
        enrollmentDeleteButton.value = new Button({
            title: T("Delete"),
            text: T("Delete"),
            onClick: deleteEntrollmentLimit,
            icon: "fal fa-trash",
            loading: false,
            disabled: false
        })
        enrollmentSaveButton.value = new Button({
            title: T("Save"),
            text: T("Save"),
            onClick: saveEnrollmentLimit,
            icon: "fal fa-save",
            loading: false,
            disabled: false
        })
    }
    if (canBeDefaultLicenseUuid.value) {
        defaultLicenseButton.value = new Button({
            title: T("Assign as iOS default"),
            text: T("Assign as iOS default"),
            icon: "fal fa-key",
            onClick: async () => {
                try {
                    defaultLicenseButton.value?.startLoader()
                    defaultLicenseButton.value?.disable()
                    await queries.mobileSecurity.setDefaultDeviceLicenseUUID(
                        activeAccountId.value,
                        license.value?.uuid || "",
                        true
                    )
                    defaultLicenseButton.value?.stopLoader()
                    defaultLicenseButton.value?.enable()
                } catch (e: unknown) {
                    console.error(e)
                }
            }
        })
    }

    if (!initialized.value) {
        init()
    }
})
</script>

<template>
    <div>
        <section
            class="padding-xs padding-md-2 padding-lg-4 pagecontent"
            v-if="initialized && license != undefined"
        >
            <div class="row itemlist show-details view-grid">
                <div
                    class="col-xs-24 col-print-24 col-lg-12 padding-xs item"
                    style="z-index: 999999"
                >
                    <div class="box-shadow">
                        <div class="box-body">
                            <div class="box-icon-left">
                                <div
                                    class="icon-circle color-white bg-gray hover-bg-red"
                                    :title="T(license.name)"
                                >
                                    <i class="fal fa-key"></i>
                                </div>
                            </div>
                            <div class="box-content-2">
                                <div class="box-title">
                                    <strong class="margin-xs-r">{{ license.name }}</strong>
                                    <template v-if="license.laasEnabled">
                                        <span
                                            :title="T('USR') + ' ' + T('booked')"
                                            class="label bg-green margin-xs-r"
                                            >{{ T("USR") + " " + T("booked") }}</span
                                        >
                                    </template>
                                    <template v-if="license.uscMessagingEnabled">
                                        <span
                                            :title="T('USC') + ' ' + T('booked')"
                                            class="label bg-green margin-xs-r"
                                            >{{ T("USC") + " " + T("booked") }}</span
                                        >
                                    </template>
                                    <template v-if="license.state != 'valid'">
                                        <span
                                            :title="T(license.state)"
                                            class="label bg-yellow margin-xs-r"
                                            >{{ T(license.state) }}</span
                                        >
                                    </template>
                                    <template
                                        v-if="license.uuid && license.uuid == defaultLicenseUuid"
                                    >
                                        <span
                                            :title="'iOS Default'"
                                            class="label bg-blue margin-xs-r"
                                            >{{ "iOS Default" }}</span
                                        >
                                    </template>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div class="box-body details">
                            <div class="text-smaller">
                                <div class="data-row">
                                    <span class="data-key text-semibold">
                                        <i class="fal fa-fw fa-key"></i> {{ T("Type") }}:
                                    </span>
                                    <div class="data-value">
                                        {{ license.type }}
                                    </div>
                                </div>
                                <div class="data-row">
                                    <span class="data-key text-semibold">
                                        <i class="fal fa-fw fa-user"></i> {{ T("Users") }}:
                                    </span>
                                    <div class="data-value">
                                        <template
                                            v-if="
                                                ['Mobile Security', 'MDM'].indexOf(license.type) !=
                                                -1
                                            "
                                        >
                                            {{ license.assignedUser }}/{{ licenseLimit }}
                                        </template>
                                        <template v-else>
                                            {{ license.user || "" }}
                                        </template>
                                    </div>
                                </div>
                                <div class="data-row">
                                    <span class="data-key text-semibold">
                                        <i class="fal fa-fw fa-hashtag"></i>
                                        {{ T("License") + " UUID" }}:
                                    </span>
                                    <div class="data-value">
                                        {{ license.uuid ? license.uuid : "" }}
                                    </div>
                                </div>
                                <div class="data-row">
                                    <span class="data-key text-semibold">
                                        <i class="fal fa-fw fa-clock"></i> {{ T("Expires") }}:
                                    </span>
                                    <div class="data-value">
                                        {{ getDate(license.expirationDate, false) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-24 col-print-24 col-lg-12 padding-xs"></div>
            </div>
            <div class="row">
                <div class="col-xs-24 padding-xs">
                    <div class="box-shadow">
                        <div class="tabs">
                            <nav>
                                <ul class="nav nav-tabs hide-on-print">
                                    <li>
                                        <a
                                            :class="{ active: activeTab == 'general' }"
                                            v-on:click="setActiveTab('general')"
                                        >
                                            <i class="fal fa-fw fa-info-circle"></i>
                                            {{ T("General") }}
                                        </a>
                                    </li>
                                    <li
                                        v-if="
                                            ['Mobile Security', 'MDM'].indexOf(license.type) != -1
                                        "
                                    >
                                        <a
                                            :class="{ active: activeTab == 'assignment' }"
                                            v-on:click="setActiveTab('assignment')"
                                        >
                                            <i class="fal fa-fw fa-info-circle"></i>
                                            {{ T("Assignment") }}
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            <div class="tabcontent">
                                <div :class="{ hidden: activeTab != 'general' }">
                                    <div class="box-content-2">
                                        <div class="row">
                                            <div class="col-xs-24 col-xl-16">
                                                <h4>{{ T("Summary") }}</h4>
                                                <table class="noborder border-h">
                                                    <tbody>
                                                        <tr>
                                                            <td class="title">
                                                                <i class="fal fa-fw fa-key"></i>
                                                                {{ T("License type") }}
                                                            </td>
                                                            <td>
                                                                {{ license.type }}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td class="title">
                                                                <i
                                                                    class="fal fa-fw fa-mobile-alt"
                                                                ></i>
                                                                {{ T("Assigned users") }}
                                                            </td>
                                                            <td>
                                                                <template
                                                                    v-if="
                                                                        [
                                                                            'Mobile Security',
                                                                            'MDM'
                                                                        ].indexOf(license.type) !=
                                                                        -1
                                                                    "
                                                                >
                                                                    {{ license.assignedUser }}
                                                                </template>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="title">
                                                                <i
                                                                    class="fal fa-fw fa-mobile-alt"
                                                                ></i>
                                                                {{ T("Max. users") }}
                                                            </td>
                                                            <td>
                                                                {{ licenseLimit }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="title">
                                                                <i
                                                                    class="fal fa-fw fa-question"
                                                                ></i>
                                                                {{ T("Valid") }}
                                                            </td>
                                                            <td>
                                                                <template
                                                                    v-if="license.state == 'valid'"
                                                                >
                                                                    {{ T("Yes") }}
                                                                </template>
                                                                <template
                                                                    v-else-if="
                                                                        license.state == 'expired'
                                                                    "
                                                                >
                                                                    <a v-on:click="openRSP()">{{
                                                                        T("Extend")
                                                                    }}</a>
                                                                </template>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="title">
                                                                <i
                                                                    class="fal fa-fw fa-calendar"
                                                                ></i>
                                                                {{ T("Expires") }}
                                                            </td>
                                                            <td>
                                                                {{
                                                                    getDate(
                                                                        license.expirationDate,
                                                                        false
                                                                    )
                                                                }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="title">
                                                                <i
                                                                    class="fal fa-fw fa-calendar"
                                                                ></i>
                                                                {{ T("Stale") }}
                                                            </td>
                                                            <td>
                                                                {{
                                                                    getDate(license.deadDate, false)
                                                                }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td class="title">
                                                                <i
                                                                    class="fal fa-fw fa-address-card"
                                                                ></i>
                                                                {{ T("Licensee") }}
                                                            </td>
                                                            <td>{{ license.accountName }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td class="title">
                                                                <i class="fal fa-fw fa-hashtag"></i>
                                                                {{ T("Account ID") }}
                                                            </td>
                                                            <td>{{ license.accountId }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table
                                                    v-if="
                                                        isMdmLicense && license.isAllowedToSetLimit
                                                    "
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <td class="title">
                                                                <i class="fal fa-mobile fa-fw"></i>
                                                                {{ T("Allowed users") }}
                                                            </td>
                                                            <td>
                                                                <div class="row">
                                                                    <div
                                                                        class="col-xs-24 col-lg-10 col-xl-12"
                                                                    >
                                                                        <input-number
                                                                            v-if="
                                                                                isReseller ||
                                                                                isUserSupport
                                                                            "
                                                                            v-model="
                                                                                enrollmentLimit
                                                                            "
                                                                            :max="license.user"
                                                                            :min="
                                                                                license.assignedUser ||
                                                                                0
                                                                            "
                                                                            :debounce="1000"
                                                                            :beforeFilterInput="
                                                                                beforeFilterInput
                                                                            "
                                                                            :afterFilterInput="
                                                                                afterFilterInput
                                                                            "
                                                                        />
                                                                        <input
                                                                            v-else
                                                                            :disabled="true"
                                                                            type="number"
                                                                            :value="enrollmentLimit"
                                                                            style="width: 50%"
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        class="col-xs-24 col-lg-14 col-xl-12"
                                                                    >
                                                                        <buttonComponent
                                                                            v-if="
                                                                                enrollmentSaveButton
                                                                            "
                                                                            :buttonOptions="
                                                                                enrollmentSaveButton
                                                                            "
                                                                        />
                                                                        <buttonComponent
                                                                            v-if="
                                                                                enrollmentDeleteButton
                                                                            "
                                                                            :buttonOptions="
                                                                                enrollmentDeleteButton
                                                                            "
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table v-if="canBeDefaultLicenseUuid">
                                                    <tbody>
                                                        <tr>
                                                            <td class="title">
                                                                <i class="fal fa-key"></i>
                                                                {{ T("iOS Default") }}
                                                            </td>
                                                            <td>
                                                                <buttonComponent
                                                                    v-if="defaultLicenseButton"
                                                                    :button-options="
                                                                        defaultLicenseButton
                                                                    "
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div :class="{ hidden: activeTab != 'assignment' }">
                                    <div class="box-content-2">
                                        <div class="row">
                                            <div class="col-xs-24">
                                                <h4>{{ T("Assignment") }}</h4>
                                                <div class="row flexrow">
                                                    <div class="col-xs-24">
                                                        <input-vue-select
                                                            :selectOptions="getOptions()"
                                                            :dontEscapeMarkup="true"
                                                            v-model="devicesValue"
                                                            :multiple="true"
                                                            :onSelecting="onDeviceAdd"
                                                            id="licenseSelection"
                                                            :noTranslations="true"
                                                            :disabled="
                                                                licenseLimit <= license.assignedUser
                                                            "
                                                            ref="licenseAssignmentSelect"
                                                        >
                                                        </input-vue-select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <template v-else>
            <p class="text-center padding-xs-t-4 text-size-4 padding-xs-b-0 text-center">
                <loaderComponent class="color-red"></loaderComponent>
            </p>
            <p class="text-center">{{ T(loader.loaderText) }}</p>
        </template>
    </div>
</template>
