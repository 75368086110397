import { useVue } from "@/app"
import apis from "@/classes/apis"
import config from "@/classes/config"
import { useFeatureStore } from "@/classes/featureStore"
import { T } from "@/classes/i18n"
import devLog from "@/classes/log"
import { useUserLogStore } from "@/classes/userLogStore"
import dialogs from "@/dialogs/dialogs"
import miscDialogs from "@/dialogs/dialogs.misc"
import deviceHelpers from "@/helpers/helpers.devices"
import encodingHelpers from "@/helpers/helpers.encoding"
import getterHelpers from "@/helpers/helpers.getters"
import jsonHelpers from "@/helpers/helpers.json"
import licenseHelpers from "@/helpers/helpers.license"
import numberHelpers from "@/helpers/helpers.numbers"
import promiseHelpers from "@/helpers/helpers.promises"
import tenantHelpers from "@/helpers/helpers.tenants"
import timeHelpers from "@/helpers/helpers.time"
import queries from "@/queries/queries"
import requestHandler from "@/queries/requests"
import router from "@/router/router"
import { MutationTypes, useStore } from "@/store/vuex.store"
import Button from "@/templates/components/button/button"
import ModalObject, { ModalObjectButton } from "@/templates/components/modals/modalClass"
import moment from "moment"
import { sprintf } from "sprintf-js"
import products from "../../"
import ObjectType, {
    type AccountId,
    type AddObjectTypeObject,
    type ItemlistItemDetail,
    type MenuEntry,
    type ObjectTypeDialogs,
    type ObjectTypePayload
} from "../../objectType"
import { useAndroidPublishStore } from "./androidProfilePublish"
import type { ProfileSunPoolInfo } from "./androidProfiles"
import enterpriseDevices, { type EnterpriseDevice } from "./enterpriseDevices"
import type { ZeroTouchDevice } from "./zerotouchDevices"
import zeroTouchDevices from "./zerotouchDevices"

export type EnterpriseDevicePlusZeroTouch = EnterpriseDevice & {
    zeroTouchDevice: boolean
    zeroTouchInfo: ZeroTouchDevice
}
export type AndroidDevice = ZeroTouchDevice | EnterpriseDevice | EnterpriseDevicePlusZeroTouch

type AndroidDeviceActionType =
    | "LOCK"
    | "REBOOT"
    | "RESET_PASSWORD"
    | "LOCATION"
    | "RELINQUISH_OWNERSHIP"
    | "START_LOST_MODE"
    | "STOP_LOST_MODE"
export type AndroidDeviceActionStates = {
    [name in AndroidDeviceActionType]: boolean
}

interface AndroidDevicesDialogs extends ObjectTypeDialogs<AndroidDevice> {
    inviteDeviceDialog: (accountId: AccountId) => void
    enrollDeviceDialog: (accountId: AccountId) => void
}

class AndroidDevices extends ObjectType<AndroidDevice> {
    constructor(payload: ObjectTypePayload<AndroidDevice>) {
        super(payload)
        this.itemlist.getSortingOptions = () => {
            return [
                {
                    id: "alias",
                    text: "Name"
                },
                {
                    id: "id",
                    text: "ID"
                },
                {
                    id: "hardwareInfo.serialNumber",
                    text: "Serial"
                },
                {
                    id: "hardwareInfo.model",
                    text: "Product name"
                },
                {
                    id: "lastStatusReportTime",
                    text: "Contact"
                }
            ]
        }
        this.itemlist.getInfoBoxContent = (accountId: AccountId, itemlistComponent: any) => {
            itemlistComponent = itemlistComponent?.exposed
                ? itemlistComponent?.exposed
                : itemlistComponent
            let result = ""

            if (itemlistComponent.hasNotEnrolledEnterpriseDevices()) {
                result +=
                    (result.length ? "<br>" : "") +
                    '<i class="fal fa-fw fa-exclamation-triangle color-red"></i>&nbsp;&nbsp;' +
                    T(
                        "We have detected that one or more devices deny app installation with state NOT_ENROLLED. This either means the enterprise is no longer enrolled with Managed Google Play or the admin has not accepted the latest Managed Google Play Terms of Service."
                    )
                result +=
                    " " +
                    '<a href="https://play.google.com/work/termsofservice" target="_blank">' +
                    T("Click here") +
                    "</a>" +
                    " "
                result += T("to accept the new Terms of Service.")
            }
            if (itemlistComponent.hasDevicesWithoutLicense()) {
                result +=
                    (result.length ? "<br>" : "") +
                    '<i class="fal fa-fw fa-exclamation-triangle color-red"></i>&nbsp;&nbsp;' +
                    T(
                        "One or more devices are unlicensed, please assign a license to each of your devices."
                    )
            }

            const store = useAndroidPublishStore()
            const isPublishing = store.isPublishing
            const profileName =
                (store?.publishState?.templatePolicyName || "").split("/").pop() ?? ""

            if (isPublishing == true && profileName) {
                result +=
                    (result.length ? "<br>" : "") +
                    `<i class="fal fa-info-circle"></i> ` +
                    T(
                        "Aktuell werden Profile für %s generiert und konvertiert. Für diesen Zeitraum können keine Geräte gelöscht werden."
                    ).replace("%s", profileName)
            }

            return result
        }
        this.itemlist.getToolbarButtons = (accountId, itemlistComponent) => {
            itemlistComponent = itemlistComponent?.exposed
                ? itemlistComponent?.exposed
                : itemlistComponent
            let result = <any>[]
            if (
                this.isEnrollmentAllowed(accountId) &&
                itemlistComponent.accountRestrictions.value == 0
            ) {
                result.push(
                    {
                        icon: "fal fa-envelope",
                        title: T("Send invite"),
                        onClick: () => {
                            this.dialogs.inviteDeviceDialog(accountId)
                        },
                        id: "devicesButtonInvite",
                        vIf: false
                    },
                    {
                        icon: "fal fa-plus",
                        title: T("Enroll new device"),
                        onClick: () => {
                            this.dialogs.enrollDeviceDialog(accountId)
                        },
                        id: "devicesButtonEnroll",
                        vIf: false
                    }
                )
            } else if (itemlistComponent.accountRestrictions.value > 0) {
                result.push({
                    icon: "fal fa-exclamation-triangle",
                    title: T("Enrollment disabled"),
                    link: "",
                    id: "devicesButtonEnroll",
                    disabled: true,
                    vIf: false
                })
            } else if (!this.isEnrollmentAllowed(accountId)) {
                result.push({
                    icon: "fal fa-exclamation-triangle",
                    title: T("You have reached the devices limit"),
                    link: "",
                    id: "devicesButtonEnroll",
                    disabled: true,
                    vIf: false
                })
            }

            if (
                useFeatureStore().isEnabled("CSV_EXPORT") &&
                this.useStore?.().getObjectStoreObjects(accountId)?.length &&
                accountId
            ) {
                result.push({
                    icon: "fal fa-download",
                    title: T("Download CSV"),
                    hoverText: T("Download device details as a list"),
                    onClick: async () => {
                        try {
                            const tenantDomain = tenantHelpers.getTenantDomain(accountId)
                            await queries.misc.download(
                                `/sms-mgt-api/api/2.0/tenants/${tenantDomain}/devices/android/csv`,
                                `devices_android_${tenantDomain}_${new Date()
                                    .toISOString()
                                    .slice(0, 19)
                                    .replace(/[:T]/g, "-")}.csv`
                            )
                        } catch (e) {
                            console.error(e)
                            dialogs.misc.errorDialog(
                                accountId,
                                T("An error has occurred"),
                                T("An error occurred while exporting Android devices as CSV")
                            )
                        }
                    },
                    id: "exportAndroidDeviceButton",
                    vIf: false
                })
            }
            result.push({
                icon: "fal fa-link",
                title: T("Assign licenses"),
                link:
                    "#show-tenant-" +
                    itemlistComponent.activeTenantDomain.value +
                    "-licenses-dashboard",
                id: "assignLicenses",
                vIf: "hasDevicesWithoutLicense"
            })

            return result
        }

        this.itemlistItem.getTitle = (item, component) => {
            component = component?.exposed ? component?.exposed : component
            let primaryName: string | undefined = ""
            let secondaryName: string | undefined = ""
            if ((<ZeroTouchDevice>item)?.deviceIdentifier != undefined) {
                primaryName =
                    item && this.options.objectTypeInfo.nameProperty.primary
                        ? deviceHelpers.getShortDeviceId(
                              (<AndroidDevice>item)[
                                  this.options.objectTypeInfo.nameProperty
                                      .secondary as keyof AndroidDevice
                              ] as string
                          )
                        : ""
                secondaryName = undefined
            } else {
                primaryName =
                    item &&
                    (this.options.objectTypeInfo.nameProperty.primary as keyof AndroidDevice)
                        ? ((<AndroidDevice>item)[
                              this.options.objectTypeInfo.nameProperty
                                  .primary as keyof AndroidDevice
                          ] as string)
                        : ""
                secondaryName = deviceHelpers.getShortDeviceId(
                    (item && this.options.objectTypeInfo.nameProperty.secondary
                        ? (<AndroidDevice>item)[
                              this.options.objectTypeInfo.nameProperty
                                  .secondary as keyof AndroidDevice
                          ]
                        : item
                          ? (<AndroidDevice>item).id
                          : "") as string
                )
            }

            return {
                title: primaryName ? primaryName : secondaryName,
                small: primaryName ? secondaryName : "",
                link:
                    item?.name.indexOf("enterprises") != -1
                        ? {
                              innerHtml: '<i class="fal fa-edit"></i>',
                              onClick: function () {
                                  component.editAlias.value = true
                              },
                              showIf: function () {
                                  if (component) {
                                      return component.editAlias.value == false
                                  }
                                  return false
                              }
                          }
                        : undefined
            }
        }

        this.itemlistItem.getDisabledState = (accountId, item): boolean => {
            if (item?.name.indexOf("enterprises") != -1) {
                if (
                    (<EnterpriseDevice>item)?.licenseUUID == "" &&
                    (<EnterpriseDevice>item)?.signedIn
                ) {
                    return true
                }
                return false
            }
            return true
        }
        this.itemlistItem.isClickable = (accountId, item): boolean => {
            if (item?.name.indexOf("enterprises") != -1) {
                if (
                    !licenseHelpers.hasOneOfLicenses(accountId, ["Mobile Security", "MDM"], "valid")
                ) {
                    return false
                }
                if (
                    (<EnterpriseDevice>item)?.licenseUUID == "" ||
                    (<EnterpriseDevice>item)?.signedIn === false
                ) {
                    return false
                }
                return true
            }
            return false
        }
        this.itemlistItem.onClick = (accountId, item) => {
            let objectId = item[
                this.options.objectTypeInfo.primaryKeyProperty.property as keyof AndroidDevice
            ] as string
            if (item?.name.indexOf("enterprises") != -1) {
                if (
                    (<EnterpriseDevice>item).consent == "" ||
                    (<EnterpriseDevice>item).consent != config.devicesConsentDate
                ) {
                    dialogs.mobileSecurity.assignDeviceOwnership(
                        accountId,
                        "mobileSecurity",
                        "androidDevices",
                        item.id || "",
                        item
                    )
                } else {
                    router.navigate(
                        "show-tenant-" +
                            tenantHelpers.getTenantDomain(accountId) +
                            "-android-device-" +
                            objectId +
                            "-details"
                    )
                }
            }
        }
        this.itemlistItem.hasCheckbox = (item) => {
            return item?.name.indexOf("enterprises") != -1
        }
        this.itemlistItem.getStatus = (accountId: AccountId, item) => {
            return undefined
        }

        this.itemlistItem.getMenuEntries = (accountId: AccountId, item) => {
            let menuEnties: MenuEntry[] = []
            if (item?.name.indexOf("enterprises") != -1) {
                if (item) {
                    let objectId = item[
                        this.options.objectTypeInfo.primaryKeyProperty
                            .property as keyof AndroidDevice
                    ] as string
                    if (
                        (<EnterpriseDevice>item).consent == "" ||
                        (<EnterpriseDevice>item).consent != config.devicesConsentDate
                    ) {
                        menuEnties.push(
                            new Button({
                                id: "licenseconditions",
                                text: T("License conditions"),
                                title: T("License conditions"),
                                icon: "fal fa-check-square",
                                onClick: () => {
                                    dialogs.mobileSecurity.assignDeviceOwnership(
                                        accountId,
                                        "mobileSecurity",
                                        "androidDevices",
                                        item.id || "",
                                        item
                                    )
                                }
                            })
                        )
                    } else {
                        menuEnties.push(
                            new Button({
                                id: "details",
                                text: T("Details"),
                                title: T("Details"),
                                icon: "fal fa-info-circle",
                                onClick: () => {
                                    router.navigate(
                                        "show-tenant-" +
                                            tenantHelpers.getTenantDomain(accountId) +
                                            "-android-device-" +
                                            objectId +
                                            "-details-new"
                                    )
                                }
                            })
                        )
                    }
                    menuEnties.push(
                        new Button({
                            id: "delete",
                            text: T("Delete"),
                            title: T("Delete"),
                            icon: "fal fa-trash",
                            onClick: () => {
                                this.dialogs.getDeleteObjectDialog(accountId, item)
                            },
                            disabled: useAndroidPublishStore().isPublishing == true
                        })
                    )
                }
            }
            return menuEnties
        }

        this.itemlistItem.getLabels = (accountId, item) => {
            let result = []
            if (item?.name.indexOf("enterprises") != -1) {
                item = item as EnterpriseDevice | EnterpriseDevicePlusZeroTouch
                let thisProfile = products.mobileSecurity.androidProfiles
                    .useStore?.()
                    .getObjectStoreObject(accountId, this.getPolicyName(item))
                var timeEnrolled = item?.enrollmentTime
                    ? moment().unix() - moment(item.enrollmentTime).unix()
                    : null
                if (timeEnrolled != null) {
                    if (timeEnrolled < 60 * 60 * 24) {
                        result.push({
                            title: T("New"),
                            text: T("New"),
                            class: "bg-green",
                            icon: "fa fa-info-circle"
                        })
                    }
                }
                if (item.consent == "" || item.consent != config.devicesConsentDate) {
                    result.push({
                        title: T("Terms not accepted"),
                        text: T("Terms not accepted"),
                        class: "bg-red",
                        icon: "fa fa-times-circle"
                    })
                }
                if (item?.appliedState != item?.state && item?.appliedState != "LOST") {
                    let state = item?.state == "DISABLED" ? T("Disabling...") : T("Activating...")
                    result.push({
                        title: state,
                        text: state,
                        class: "bg-yellow",
                        icon: "fa fa-info-circle"
                    })
                } else if (item?.appliedState == "DISABLED") {
                    result.push({
                        title: T("Disabled"),
                        text: T("Disabled"),
                        class: "bg-red",
                        icon: "fa fa-exclamation-triangle"
                    })
                }

                if (item?.signedIn === false) {
                    result.push({
                        title: T("Signed out"),
                        text: T("Signed out"),
                        class: "bg-yellow",
                        icon: "fa fa-sign-out"
                    })
                }

                if (!item?.licenseUUID) {
                    result.push({
                        title: T("Not licensed"),
                        text: T("Not licensed"),
                        class: "bg-red",
                        icon: "fa fa-exclamation-triangle"
                    })
                }
                if (
                    item?.licenseInformation != undefined &&
                    !licenseHelpers.isLicenseValid(item.licenseInformation)
                ) {
                    result.push({
                        title: T("License expired"),
                        text: T("License expired"),
                        class: "red",
                        icon: "fa fa-exclamation-triangle"
                    })
                }

                if ((<EnterpriseDevicePlusZeroTouch>item)?.zeroTouchDevice) {
                    result.push({
                        title: T("Zero Touch"),
                        text: T("Zero Touch"),
                        class: "",
                        icon: "fab fa-android"
                    })
                }

                // #44680
                if (Array.isArray(thisProfile?.sun) && thisProfile.sun.length > 0) {
                    let uncErrors: { pool: ProfileSunPoolInfo; error: string }[] = []

                    if (
                        Array.isArray((<EnterpriseDevice>item)?.isInExhaustedSunPools) &&
                        (<EnterpriseDevice>item).isInExhaustedSunPools!.length > 0
                    ) {
                        thisProfile.sun.forEach((sunInfo) => {
                            if (Array.isArray(sunInfo?.pools)) {
                                sunInfo.pools.forEach((pool) => {
                                    if (
                                        (<EnterpriseDevice>item)?.isInExhaustedSunPools?.includes(
                                            pool.id
                                        )
                                    ) {
                                        uncErrors.push({
                                            pool: pool,
                                            error: T(
                                                "Dieses Gerät ist einem Profil zugewiesen, das Teil eines Transfernetzes in der VPN-Konfiguration ist. Dieses Transfernetz hat keine ausreichende Kapazität für dieses Gerät mehr."
                                            )
                                        })
                                    }
                                })
                            }
                        })
                    }

                    result.push({
                        text: "VPN",
                        htmlTooltip: false,
                        title:
                            uncErrors.length == 0
                                ? T(
                                      "Dieses Gerät ist einem Profil zugewiesen, das Teil eines Transfernetzes in der VPN-Konfiguration ist."
                                  )
                                : uncErrors.map((error) => {
                                      return error.pool.name + ": " + error.error
                                  }),
                        class: uncErrors.length == 0 ? "" : "bg-red",
                        icon: "fa fa-puzzle"
                    })
                }

                if (item?.lostMode === true) {
                    result.push({
                        title: T("Lost"),
                        text: T("Lost"),
                        class: "bg-red",
                        icon: "fa fa-circle-question"
                    })
                }
            } else {
                item = item as ZeroTouchDevice
                result.push({
                    title: T("Zero Touch"),
                    text: T("Zero Touch"),
                    class: "",
                    icon: "fab fa-android"
                })
            }

            return result
        }
        this.itemlistItem.getDetails = (accountId, item, component) => {
            let result: ItemlistItemDetail[] = []
            let canSetUser: boolean = true
            component = component?.exposed ? component?.exposed : component
            if (item?.name.indexOf("enterprises") != -1) {
                item = item as EnterpriseDevice | EnterpriseDevicePlusZeroTouch
                let operatingMode: string = ""
                if (
                    item?.managementMode === "PROFILE_OWNER" &&
                    item?.ownership === "COMPANY_OWNED"
                ) {
                    operatingMode = "Profile Owner (COPE)"
                } else if (item?.managementMode === "PROFILE_OWNER") {
                    operatingMode = "Profile Owner (BYOD)"
                    if (typeof item.username == "string" && item.username != "") {
                        canSetUser = false
                    }
                } else if (item?.managementMode === "DEVICE_OWNER") {
                    operatingMode = "Device Owner (COBO)"
                }

                result = [
                    {
                        iconClass: "fal fa-fw fa-microchip",
                        title: T("Model"),
                        key: T("Model"),
                        value: item
                            ? item?.hardwareInfo?.brand
                                ? item?.hardwareInfo?.brand + " - " + item?.hardwareInfo?.model
                                : item?.hardwareInfo?.model || ""
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    },
                    {
                        iconClass: "fal fa-fw fa-hashtag",
                        title: T("OS"),
                        key: T("OS"),
                        value: item
                            ? item?.softwareInfo?.androidVersion
                                ? "Android " + item?.softwareInfo?.androidVersion
                                : ""
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    },
                    {
                        iconClass: "fal fa-fw fa-hashtag",
                        title: T("Serial Number"),
                        key: T("Serial Number"),
                        value: item
                            ? item?.hardwareInfo?.serialNumber || ""
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    },
                    {
                        iconClass: "fal fa-fw fa-hashtag",
                        title: T("IMEI"),
                        key: T("IMEI"),
                        value: item
                            ? item?.networkInfo?.imei || ""
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    },
                    {
                        iconClass: "fal fa-fw fa-address-card",
                        title: T("Ownership"),
                        key: T("Ownership"),
                        value: item
                            ? item.managementMode == "DEVICE_OWNER"
                                ? "COPE"
                                : item.ownership == "COMPANY_OWNED"
                                  ? "COPE"
                                  : "BYOD"
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    },
                    {
                        iconClass: "fal fa-fw fa-address-card",
                        title: T("Operating mode"),
                        key: T("Operating mode"),
                        value: item
                            ? operatingMode
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    },
                    {
                        iconClass: "fal fa-fw fa-user",
                        title: T("User"),
                        key: T("User"),
                        value: item
                            ? item.username || ""
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>",
                        editableContent:
                            licenseHelpers.hasOneOfLicenses(
                                accountId,
                                ["Mobile Security", "MDM"],
                                "valid"
                            ) && canSetUser == true
                                ? {
                                      type: "select",
                                      options: "users",
                                      ref: "editUser",
                                      value: item?.username,
                                      editingBoolProperty: "editUsername",
                                      editButton: new Button({
                                          onClick: function () {
                                              component.editUsername.value = true
                                          },
                                          icon: "fal fa-edit"
                                      }),
                                      submitFunction: async (value: string) => {
                                          try {
                                              value = value === "noUserSelected" ? "" : value
                                              await this.queries.updateObjectPropertiesFromApi(
                                                  accountId,
                                                  item.id,
                                                  { username: value },
                                                  "username",
                                                  undefined,
                                                  undefined,
                                                  "/properties?updateMask[]=username"
                                              )
                                              component.editUsername.value = false
                                          } catch (e: any) {
                                              component.editUsername.value = false
                                              component.error.value = true
                                              console.error(e)
                                              setTimeout(() => {
                                                  component.error.value = false
                                              }, 2000)
                                          }
                                      },
                                      abortFunction: function () {
                                          component.editUsername.value = false
                                      }
                                  }
                                : undefined
                    },
                    {
                        iconClass: "fal fa-fw fa-shield-alt",
                        title: T("Device profile"),
                        key: T("Device profile"),
                        [item ? "labels" : "value"]: item
                            ? [
                                  {
                                      id: "test",
                                      text: this.getPolicyName(item) || T("Unknown"),
                                      title: this.getPolicyName(item) || T("Unknown"),
                                      icon:
                                          this.getPolicyStatus(item) != "INSTALLED"
                                              ? "fa fa-exclamation-triangle"
                                              : undefined,
                                      onClick: () => {
                                          if (
                                              (<EnterpriseDevice>item)?.policyName?.indexOf("^") ==
                                              -1
                                          ) {
                                              router.navigate(
                                                  "edit-tenant-" +
                                                      accountId +
                                                      ".sms-android-profile-" +
                                                      this.getPolicyName(item as EnterpriseDevice)
                                              )
                                          }
                                      },
                                      displayType: "label"
                                  }
                              ]
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    }
                ]

                if ((<AndroidDevice>item)?.zeroTouchDevice == true) {
                    let objectId =
                        (<EnterpriseDevicePlusZeroTouch>item)?.zeroTouchInfo?.configuration?.split(
                            "/"
                        )[3] || undefined
                    let configuration: any = undefined

                    if (objectId) {
                        configuration = config.canUseNewObjectType("zeroTouchConfigurations")
                            ? products.mobileSecurity.zeroTouchConfigurations
                                  .useStore?.()
                                  .getObjectStoreObject(accountId, objectId)
                            : useStore()?.getters.getObject({
                                  accountId: accountId,
                                  productType: "mobileSecurity",
                                  objectType: "zeroTouchConfigurations",
                                  objectId: objectId
                              })
                    }

                    result.push({
                        iconClass: "fal fa-fw fa-wrench",
                        title: "Zero-Touch",
                        key: "Zero-Touch",
                        value: item
                            ? configuration
                                ? configuration.configurationName
                                : ""
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    })
                }
                if (
                    item?.licenseUUID &&
                    item?.licenseUUID != "" &&
                    item?.licenseInformation?.type != undefined
                ) {
                    result.push({
                        iconClass: "fal fa-fw fa-key",
                        title: T("License"),
                        key: T("License"),
                        [item ? "labels" : "value"]: item
                            ? [
                                  {
                                      id: item.licenseUUID + "_" + item.licenseInformation?.name,
                                      text:
                                          item.licenseInformation?.type +
                                          " (" +
                                          deviceHelpers.getShortDeviceId(
                                              String(item?.licenseInformation?.id)
                                          ) +
                                          ")",
                                      title:
                                          item.licenseInformation?.type +
                                          " (" +
                                          deviceHelpers.getShortDeviceId(
                                              String(item?.licenseInformation?.id)
                                          ) +
                                          ")",
                                      onClick: () => {
                                          router.navigate(
                                              "show-tenant-" +
                                                  accountId +
                                                  ".sms-license-" +
                                                  (item as EnterpriseDevice)?.licenseInformation
                                                      ?.id +
                                                  "-details"
                                          )
                                      },
                                      displayType: "label"
                                  }
                              ]
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    })
                } else {
                    result.push({
                        iconClass: "fal-fa-fw fa-key",
                        title: T("License"),
                        key: T("License"),
                        [item ? "labels" : "value"]: item
                            ? [
                                  {
                                      id: "",
                                      text: T("Not licensed"),
                                      title: T("Not licensed"),
                                      displayType: "label"
                                  }
                              ]
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    })
                }
                result.push({
                    iconClass: "fal fa-fw fa-clock",
                    title: T("Contact"),
                    key: T("Contact"),
                    value: item
                        ? undefined
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>",
                    regularlyUpdatedData: !item
                        ? undefined
                        : {
                              inputValue: item ? item.lastStatusReportTime : undefined,
                              method: (inputValue: any) => {
                                  return inputValue ? moment(inputValue).fromNow() : ""
                              },
                              refreshAfterMs: 10000
                          }
                })
            } else {
                item = item as ZeroTouchDevice
                result = [
                    {
                        iconClass: "fal fa-fw fa-microchip",
                        title: T("Model"),
                        key: T("Model"),
                        value: item
                            ? (<ZeroTouchDevice>item).deviceIdentifier?.model || ""
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    },
                    {
                        iconClass: "fal fa-fw fa-hashtag",
                        title: T("OS"),
                        key: T("OS"),
                        value: item
                            ? "Android"
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    },
                    {
                        iconClass: "fal fa-fw fa-hashtag",
                        title: T("Serial Number"),
                        key: T("Serial Number"),
                        value: item
                            ? (<ZeroTouchDevice>item).deviceIdentifier?.serialNumber || ""
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    },
                    {
                        iconClass: "fal fa-fw fa-hashtag",
                        title: T("IMEI"),
                        key: T("IMEI"),
                        value: item
                            ? (<ZeroTouchDevice>item).deviceIdentifier?.imei || ""
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    },
                    {
                        iconClass: "fal fa-fw fa-address-card",
                        title: T("Ownership"),
                        key: T("Ownership"),
                        value: item
                            ? ""
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    },
                    {
                        iconClass: "fal fa-fw fa-user",
                        title: T("User"),
                        key: T("User"),
                        value: item
                            ? ""
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    },
                    {
                        iconClass: "fal fa-fw fa-shield-alt",
                        title: T("Device profile"),
                        key: T("Device profile"),
                        ["value"]: item
                            ? ""
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    },
                    {
                        iconClass: "fal fa-fw fa-key",
                        title: T("License"),
                        key: T("License"),
                        value: item
                            ? ""
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    },
                    {
                        iconClass: "fal fa-fw fa-clock",
                        title: T("Contact"),
                        key: T("Contact"),
                        value: item
                            ? ""
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    }
                ]
            }

            return result
        }

        this.queries.getCountFromApi = async (accountId, updateStore = true) => {
            let result: number | Error | undefined = undefined
            try {
                let response = await enterpriseDevices.queries.getCountFromApi(accountId, false)
                result = Number(response)
                if (typeof result !== "number") {
                    throw "Error getting count"
                } else if (updateStore) {
                    this.useStore?.().setObjectTypeCount(accountId, Number(result))
                }
                return result
            } catch (e: any) {
                devLog.log("ObjectType", e.message, e, "error")
                //throw e as Error
                return 0
            }
        }

        this.queries.getObjectsFromApi = async (
            accountId,
            customerId?,
            props?,
            updateStore = true,
            removeOldObjects = false
        ) => {
            let store = this.useStore?.().getObjectStore(accountId)
            let timestamp = store?.objectsTimestamp || 0
            let isRequestable = (function () {
                return (
                    store?.gettingObjects == false && timeHelpers.getUnixTimeStamp() - timestamp > 5
                )
            })()
            if ((store && isRequestable) || store == undefined) {
                if (store) {
                    store.gettingObjects = true
                    store.objectsTimestamp = timeHelpers.getUnixTimeStamp()
                }
                let result: AndroidDevice[] = []
                try {
                    let promiseResult = await promiseHelpers.resolvePromiseObject({
                        enterpriseDevices:
                            props?.[0]?.property == "zerotouchOnly" && props?.[0]?.value == true
                                ? null
                                : enterpriseDevices.queries.getObjectsFromApi(
                                      accountId,
                                      undefined,
                                      props,
                                      false
                                  ),
                        zeroTouchDevices:
                            props?.[0]?.property == "enterpriseOnly" && props?.[0]?.value == true
                                ? null
                                : tenantHelpers.hasFunctionality(accountId, "zerotouch")
                                  ? zeroTouchDevices.queries.getObjectsFromApi(
                                        accountId,
                                        customerId,
                                        undefined,
                                        false
                                    )
                                  : null
                    })
                    let enterpriseDevicesResponse = promiseResult.enterpriseDevices
                    let zeroTouchDevicesResponse = promiseResult.zeroTouchDevices
                    if (enterpriseDevicesResponse instanceof Error) {
                        throw enterpriseDevicesResponse
                    } else if (zeroTouchDevicesResponse instanceof Error) {
                        throw zeroTouchDevicesResponse
                    } else {
                        result = this.mergeEnterpriseAndZeroTouchDevices(
                            enterpriseDevicesResponse || [],
                            zeroTouchDevicesResponse || []
                        )
                    }
                    if (updateStore && result.length) {
                        if (removeOldObjects == true) {
                            this.useStore?.().clearObjectTypeObjectsFromStore(accountId)
                        }
                        this.useStore?.().setObjectTypeCount(accountId, result.length)
                        this.useStore?.().setObjectTypeObjects(accountId, result)
                    }
                    if (store) {
                        store.gettingObjects = false
                    }
                    return result
                } catch (e) {
                    if (store) {
                        store.gettingObjects = false
                    }
                    throw e
                }
            } else {
                return this.useStore?.().getObjectStoreObjects(accountId) || []
            }
        }

        this.replaceStoreFunctionWith.setObjectTypeObjects = (accountId, objects) => {
            let objectTypeInfo = this.options.objectTypeInfo
            let idProperty = objectTypeInfo?.primaryKeyProperty.property
            let idPropertyPath = objectTypeInfo?.primaryKeyProperty.pathToPrimaryProperty
            let objectStore = this.useStore?.()
            let accountObjectStore = this.useStore?.().getObjectStore(accountId)
            if (accountObjectStore?.objects.length) {
                if (idProperty) {
                    let existingObject:
                        | AndroidDevice
                        | EnterpriseDevice
                        | EnterpriseDevicePlusZeroTouch
                        | ZeroTouchDevice
                        | undefined = undefined
                    objects.forEach((object) => {
                        const addToStore = () => {
                            existingObject = this.useStore?.().getObjectStoreObject(
                                accountId,
                                thisObjectId
                            )
                            if (existingObject != undefined) {
                                // replace
                                Object.keys(object).forEach((key) => {
                                    //@ts-ignore
                                    existingObject[key as keyof AndroidDevice] =
                                        object[key as keyof AndroidDevice]
                                })
                            } else if (accountObjectStore) {
                                // add object
                                accountObjectStore.objects?.push(object)
                            }
                        }

                        if (object && this.convertObjectForStore) {
                            this.convertObjectForStore(accountId, object)
                        }

                        existingObject = undefined
                        // check if object already exists
                        const thisObjectId = idPropertyPath
                            ? jsonHelpers.getObjectProperty(
                                  object,
                                  idPropertyPath + "." + String(idProperty)
                              )
                            : object[idProperty as keyof typeof object]
                        let deviceType = this.getDeviceType(object)
                        if (deviceType == "zeroTouchDevice") {
                            object.zeroTouchDevice = true
                            const ztImei =
                                (<ZeroTouchDevice>object).deviceIdentifier.imei || undefined
                            const ztMeid =
                                (<ZeroTouchDevice>object).deviceIdentifier.meid || undefined
                            const ztManufacturer =
                                (<ZeroTouchDevice>object).deviceIdentifier.manufacturer || undefined
                            const ztSerialNumber =
                                (<ZeroTouchDevice>object).deviceIdentifier.serialNumber || undefined
                            const ztModel =
                                (<ZeroTouchDevice>object).deviceIdentifier.model || undefined
                            let enterpriseDevice = accountObjectStore?.objects.find(
                                (existingItem: EnterpriseDevice | ZeroTouchDevice) => {
                                    if (
                                        (<ZeroTouchDevice>existingItem).deviceIdentifier ==
                                        undefined
                                    ) {
                                        // Is enterprise device
                                        const aeImei =
                                            (<EnterpriseDevice>existingItem).networkInfo?.imei || ""
                                        const aeMeid =
                                            (<EnterpriseDevice>existingItem).networkInfo?.meid || ""
                                        const aeManufacturer =
                                            (<EnterpriseDevice>existingItem).hardwareInfo
                                                ?.manufacturer || ""
                                        const aeBrand =
                                            (<EnterpriseDevice>existingItem).hardwareInfo?.brand ||
                                            ""
                                        const aeSerialNumber =
                                            (<EnterpriseDevice>existingItem).hardwareInfo
                                                ?.serialNumber || ""
                                        const aeModel =
                                            (<EnterpriseDevice>existingItem).hardwareInfo?.model ||
                                            ""
                                        const hasSameImei = ztImei ? ztImei === aeImei : false
                                        const hasSameMeid = ztMeid ? ztMeid === aeMeid : false
                                        const hasSameManufacturer = ztManufacturer
                                            ? ztManufacturer.toLowerCase() ===
                                                  aeManufacturer.toLowerCase() ||
                                              ztManufacturer.toLowerCase() === aeBrand.toLowerCase()
                                            : false
                                        const hasSameSerial = ztSerialNumber
                                            ? ztSerialNumber === aeSerialNumber
                                            : false
                                        const hasSameModel = ztModel ? ztModel === aeModel : false

                                        return (
                                            hasSameImei ||
                                            (hasSameMeid && hasSameManufacturer) ||
                                            (hasSameSerial && hasSameModel)
                                        )
                                    }
                                }
                            )

                            if (enterpriseDevice != undefined) {
                                // found enterprise device
                                ;(<EnterpriseDevicePlusZeroTouch>enterpriseDevice).zeroTouchDevice =
                                    true
                                ;(<EnterpriseDevicePlusZeroTouch>enterpriseDevice).zeroTouchInfo =
                                    object as ZeroTouchDevice
                            } else {
                                addToStore()
                            }
                        } else if (deviceType == "enterpriseDevice") {
                            const aeImei = (<EnterpriseDevice>object).networkInfo?.imei || ""
                            const aeMeid = (<EnterpriseDevice>object).networkInfo?.meid || ""
                            const aeManufacturer =
                                (<EnterpriseDevice>object).hardwareInfo?.manufacturer || ""
                            const aeBrand = (<EnterpriseDevice>object).hardwareInfo?.brand || ""
                            const aeSerialNumber =
                                (<EnterpriseDevice>object).hardwareInfo?.serialNumber || ""
                            const aeModel = (<EnterpriseDevice>object).hardwareInfo?.model || ""

                            let ztDeviceIndex =
                                accountObjectStore?.objects.findIndex(
                                    (
                                        existingItem:
                                            | EnterpriseDevice
                                            | ZeroTouchDevice
                                            | EnterpriseDevicePlusZeroTouch
                                    ) => {
                                        if (
                                            (<ZeroTouchDevice>existingItem).deviceIdentifier !=
                                            undefined
                                        ) {
                                            // Is zero touch device
                                            const ztImei =
                                                (<ZeroTouchDevice>existingItem).deviceIdentifier
                                                    .imei || undefined
                                            const ztMeid =
                                                (<ZeroTouchDevice>existingItem).deviceIdentifier
                                                    .meid || undefined
                                            const ztManufacturer =
                                                (<ZeroTouchDevice>existingItem).deviceIdentifier
                                                    .manufacturer || undefined
                                            const ztSerialNumber =
                                                (<ZeroTouchDevice>existingItem).deviceIdentifier
                                                    .serialNumber || undefined
                                            const ztModel =
                                                (<ZeroTouchDevice>existingItem).deviceIdentifier
                                                    .model || undefined
                                            const hasSameImei = ztImei ? ztImei === aeImei : false
                                            const hasSameMeid = ztMeid ? ztMeid === aeMeid : false
                                            const hasSameManufacturer = ztManufacturer
                                                ? ztManufacturer.toLowerCase() ===
                                                      aeManufacturer.toLowerCase() ||
                                                  ztManufacturer.toLowerCase() ===
                                                      aeBrand.toLowerCase()
                                                : false
                                            const hasSameSerial = ztSerialNumber
                                                ? ztSerialNumber === aeSerialNumber
                                                : false
                                            const hasSameModel = ztModel
                                                ? ztModel === aeModel
                                                : false

                                            return (
                                                hasSameImei ||
                                                (hasSameMeid && hasSameManufacturer) ||
                                                (hasSameSerial && hasSameModel)
                                            )
                                        }
                                        return false
                                    }
                                ) || -1

                            if (ztDeviceIndex != -1 && accountObjectStore) {
                                let ztDeviceInfo: ZeroTouchDevice = jsonHelpers.copyObject(
                                    accountObjectStore?.objects[ztDeviceIndex]
                                )
                                // replace zero Touch device with enterprise device plus zero touch info
                                accountObjectStore.objects[ztDeviceIndex] =
                                    object as EnterpriseDevice
                                accountObjectStore.objects[ztDeviceIndex].zeroTouchDevice = true
                                ;(<EnterpriseDevicePlusZeroTouch>(
                                    accountObjectStore.objects[ztDeviceIndex]
                                )).zeroTouchInfo = ztDeviceInfo
                            } else {
                                addToStore()
                            }
                        } else {
                            addToStore()
                        }
                    })
                }
            } else if (accountObjectStore) {
                objects.forEach((object) => {
                    if (object && this?.convertObjectForStore) {
                        this.convertObjectForStore(accountId, object)
                    }
                })
                accountObjectStore.objects = objects
            }
        }
    }

    getDeviceType = (
        object: AndroidDevice
    ): "enterpriseDevice" | "zeroTouchDevice" | "mergedDevice" => {
        if (
            object.zeroTouchDevice &&
            (<EnterpriseDevicePlusZeroTouch>object).zeroTouchInfo &&
            (<EnterpriseDevice>object).consent != undefined
        ) {
            return "mergedDevice"
        } else if (
            (<EnterpriseDevice>object).consent != undefined &&
            !(<EnterpriseDevicePlusZeroTouch>object).zeroTouchInfo
        ) {
            return "enterpriseDevice"
        } else if (
            (<EnterpriseDevice>object).consent == undefined &&
            (<ZeroTouchDevice>object).deviceIdentifier
        ) {
            return "zeroTouchDevice"
        }
        return "enterpriseDevice"
    }

    isEnrollmentAllowed(accountId: AccountId) {
        return (
            (<boolean | undefined>(
                useStore()?.state.session.accounts[accountId]?.mobileSecurity?.settings
                    ?.enrollmentAllowed
            ) &&
                licenseHelpers.hasOneOfLicenses(accountId, ["Mobile Security", "MDM"], "valid")) ||
            false
        )
    }
    getPolicyName(object: EnterpriseDevice) {
        let result: string = ""
        if (object) {
            if (object.policyName && object.appliedPolicyName) {
                if (object.policyName == object.appliedPolicyName) {
                    result = object.policyName.split("/")[3]
                } else {
                    result = object.policyName.split("/")[3]
                }
            } else if (object.appliedPolicyName) {
                result = object.appliedPolicyName.split("/")[3]
            } else if (object.policyName) {
                result = object.policyName.split("/")[3]
            }

            if (result && result != "") {
                let policy = products.mobileSecurity.androidProfiles
                    .useStore?.()
                    .getObjectStoreObject(
                        getterHelpers.useStore().state.session.activeAccountId || "",
                        result
                    )
                if (policy && policy.type == "autogenerated policy") {
                    result = result.replace(/\^(.*)/, " ($1)")
                }
            }
        }

        return result
    }

    getPolicyStatus(object: EnterpriseDevice) {
        if (object) {
            if (object.policyName && object.appliedPolicyName) {
                if (object.policyName == object.appliedPolicyName) {
                    return "INSTALLED"
                } else {
                    return "PENDING"
                }
            } else if (object.appliedPolicyName) {
                return "INSTALLED"
            } else if (object.policyName) {
                return "PENDING"
            }
            return "UNKOWN STATUS"
        }
        return undefined
    }

    mergeEnterpriseAndZeroTouchDevices = (
        enterpriseDeviceArray: EnterpriseDevice[],
        zeroTouchDeviceArray: ZeroTouchDevice[]
    ): AndroidDevice[] => {
        let result: AndroidDevice[] = []
        if (zeroTouchDeviceArray.length > 0 && enterpriseDeviceArray.length == 0) {
            return zeroTouchDeviceArray
        } else if (zeroTouchDeviceArray.length == 0 && enterpriseDeviceArray.length > 0) {
            return enterpriseDeviceArray
        }
        result = jsonHelpers.copyObject(enterpriseDeviceArray)
        zeroTouchDeviceArray.forEach((item: ZeroTouchDevice) => {
            item.zeroTouchDevice = true
            if (result.length) {
                const ztImei: string | undefined = item.deviceIdentifier.imei || undefined
                const ztMeid: string | undefined = item.deviceIdentifier.meid || undefined
                const ztManufacturer: string | undefined =
                    item.deviceIdentifier.manufacturer || undefined
                const ztSerialNumber: string | undefined =
                    item.deviceIdentifier.serialNumber || undefined
                const ztModel: string | undefined = item.deviceIdentifier.model || undefined

                let enterpriseDevice: AndroidDevice | undefined = result.find(
                    (existingItem: EnterpriseDevice | ZeroTouchDevice) => {
                        existingItem.zeroTouchDevice =
                            existingItem.zeroTouchDevice === true ||
                            (<EnterpriseDevicePlusZeroTouch>existingItem).zeroTouchInfo != undefined

                        // Is enterprise device
                        const aeImei: string =
                                (<EnterpriseDevice>existingItem).networkInfo?.imei || "",
                            aeMeid: string =
                                (<EnterpriseDevice>existingItem).networkInfo?.meid || "",
                            aeManufacturer: string =
                                (<EnterpriseDevice>existingItem).hardwareInfo?.manufacturer || "",
                            aeBrand: string =
                                (<EnterpriseDevice>existingItem).hardwareInfo?.brand || "",
                            aeSerialNumber: string =
                                (<EnterpriseDevice>existingItem).hardwareInfo?.serialNumber || "",
                            aeModel: string =
                                (<EnterpriseDevice>existingItem).hardwareInfo?.model || "",
                            hasSameImei: boolean = ztImei ? ztImei === aeImei : false,
                            hasSameMeid: boolean = ztMeid ? ztMeid === aeMeid : false,
                            hasSameManufacturer: boolean = ztManufacturer
                                ? ztManufacturer.toLowerCase() === aeManufacturer.toLowerCase() ||
                                  ztManufacturer.toLowerCase() === aeBrand.toLowerCase()
                                : false,
                            hasSameSerial: boolean = ztSerialNumber
                                ? ztSerialNumber === aeSerialNumber
                                : false,
                            hasSameModel: boolean = ztModel ? ztModel === aeModel : false

                        const isSame =
                            hasSameImei ||
                            (hasSameMeid && hasSameManufacturer) ||
                            (hasSameSerial && hasSameModel)

                        return isSame
                    }
                )
                if (enterpriseDevice != undefined) {
                    ;(<EnterpriseDevicePlusZeroTouch>enterpriseDevice).zeroTouchDevice = true
                    ;(<EnterpriseDevicePlusZeroTouch>enterpriseDevice).zeroTouchInfo = item
                } else {
                    result.push(item)
                }
            } else {
                result.push(item)
            }
        })
        return result
    }

    dialogs: AndroidDevicesDialogs = {
        getDeleteObjectDialog: (accountId, object, customerId, confirm = false) => {
            let singularOfObjectType = this.options.appearance.text.singular.toLocaleLowerCase()
            let objectIdProperty = this.options.objectTypeInfo.primaryKeyProperty.property
            let objectId = this.getObjectId(object)
            let title = (<EnterpriseDevice>object).alias
                ? (<EnterpriseDevice>object).alias
                : deviceHelpers.getShortDeviceId(object.deviceId)
            miscDialogs.confirmDialog(
                accountId,
                T("Confirm delete"),
                sprintf(T("Do you want to delete device %s?"), title) +
                    "<br>" +
                    T(
                        "When you delete this device in the MDM portal, the following happens:<br>BYOD: Profile is removed<br>COPE/COBO/COSU: Device is being reset"
                    ),
                async () => {
                    await this.queries.deleteObjectFromApi(accountId, objectId, customerId)
                },
                confirm ? T("Delete") : undefined
            )
        },
        inviteDeviceDialog: (accountId: AccountId) => {
            try {
                accountId = tenantHelpers.getAccountId(accountId)
                if (useStore().getters.hasAccountid(accountId) == false) {
                    throw "Wrong accountid, stopping process"
                }
                let modal: any = {
                    id: "inviteDialog",
                    abortable: true,
                    content: {
                        title: {
                            text: T("Choose users which shall be invited or enter email addresses"),
                            icon: "fab fa-google"
                        },
                        body: {
                            use: true,
                            content: undefined,
                            component: "android-invite-dialog"
                        }
                    },
                    buttons: [
                        {
                            text: T("Cancel"),
                            icon: "fal fa-times",
                            onClick: async function (this: any) {
                                useStore()?.commit(MutationTypes.removeModal, { accountId })
                            },
                            align: "left",
                            disabled: false,
                            loading: false
                        },
                        {
                            text: T("Send invitation"),
                            icon: "fal fa-fw fa-envelope",
                            onClick: async () => {
                                let payload: any = {
                                    to: [],
                                    type: useVue().$refs.modals.$refs.modal.$refs.modalComponent
                                        .enrollmentType
                                }

                                if (["0", "1"].indexOf(payload.type) != -1) {
                                    payload.licenseUUID =
                                        useVue().$refs.modals.$refs.modal.$refs.modalComponent.license.selected
                                }

                                if (payload.type == "2") {
                                    let tokenIndex: number =
                                        useVue().$refs.modals.$refs.modal.$refs.modalComponent
                                            .enterprise.selectedToken.index
                                    payload.enrollmentTokenId =
                                        useVue().$refs.modals.$refs.modal.$refs.modalComponent.enterprise.enrollmentTokens[
                                            tokenIndex
                                        ].name.split("/")[3]
                                }

                                for (
                                    let i: number = 0;
                                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.value
                                        .length > i;
                                    i++
                                ) {
                                    let thisMail: string | any =
                                        useVue().$refs.modals.$refs.modal.$refs.modalComponent
                                            .value[i]

                                    if (payload.type == 1) {
                                        thisMail = JSON.parse(thisMail)
                                        payload.to.push({
                                            email: thisMail.email,
                                            appleId: thisMail.appleId,
                                            clientUserIdStr: thisMail.clientUserIdStr
                                        })
                                    } else {
                                        payload.to.push(
                                            thisMail.slice(
                                                0,
                                                thisMail.indexOf("#usrNr") != -1
                                                    ? thisMail.indexOf("#usrNr")
                                                    : thisMail.length
                                            )
                                        )
                                    }
                                }
                                try {
                                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.initialized =
                                        false
                                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.loaderText =
                                        T("Sending emails...")
                                    await requestHandler.request(
                                        "POST",
                                        "/sms-mgt-api/api/1.1/tenants/" +
                                            accountId +
                                            ".sms/emails/invite",
                                        payload
                                    )
                                    useUserLogStore().addLogEntry(
                                        "fal fa-fw fa-tablet-alt",
                                        "color-white",
                                        T("Sent invite to:") + payload.to,
                                        undefined
                                    )
                                    getterHelpers
                                        .useStore()
                                        .commit(MutationTypes.removeModal, { accountId: accountId })
                                    let modalSent: any = {
                                        id: "inviteDialog",
                                        abortable: true,
                                        content: {
                                            title: {
                                                text: T("Done"),
                                                icon: "fab fa-google"
                                            },
                                            body: {
                                                use: true,
                                                content:
                                                    '<i class="fal fa-check"></i> ' +
                                                    T("E-Mail(s) have been sent!"),
                                                component: undefined
                                            }
                                        },
                                        buttons: [
                                            {
                                                text: T("Close"),
                                                icon: "fal fa-times",
                                                onClick: async function (this: any) {
                                                    getterHelpers
                                                        .useStore()
                                                        .commit(MutationTypes.removeModal, {
                                                            accountId: accountId
                                                        })
                                                },
                                                align: "left",
                                                disabled: false,
                                                loading: false
                                            },
                                            {
                                                text: T("Invite more"),
                                                icon: "fal fa-envelope",
                                                onClick: async () => {
                                                    getterHelpers
                                                        .useStore()
                                                        .commit(MutationTypes.removeModal, {
                                                            accountId: accountId
                                                        })
                                                    this.dialogs.inviteDeviceDialog(accountId)
                                                },
                                                align: "right",
                                                disabled: false,
                                                loading: false
                                            }
                                        ]
                                    }
                                    useVue().$refs.modals.addModal(modalSent)
                                } catch (e: any) {
                                    useUserLogStore().addLogEntry(
                                        "fal fa-fw fa-ban",
                                        "color-red",
                                        T("Error sending invite"),
                                        e
                                    )
                                }
                            },
                            align: "right",
                            disabled: false,
                            loading: false
                        }
                    ]
                }
                useVue().$refs.modals.addModal(modal)
            } catch (e: any) {
                devLog.log("dialog", e, undefined, "error", 0)
            }
        },
        enrollDeviceDialog: function (accountId: string) {
            try {
                let modal: any = {
                    id: "enrollmentDialog",
                    abortable: true,
                    content: {
                        title: {
                            text: T("Enroll new Device"),
                            icon: "fab fa-google"
                        },
                        body: {
                            use: true,
                            content: undefined,
                            component: "android-enrollment-dialog-new",
                            maxHeight: "535",
                            properties: {
                                accountid: accountId
                            }
                        }
                    },
                    buttons: [
                        {
                            text: T("Close"),
                            icon: "fal fa-times",
                            onClick: async function (this: any) {
                                getterHelpers
                                    .useStore()
                                    .commit(MutationTypes.removeModal, { accountId: accountId })
                            },
                            align: "right",
                            disabled: false,
                            loading: false
                        }
                    ]
                }
                useVue().$refs.modals.addModal(modal)
            } catch (e: any) {
                devLog.log("dialog", e, undefined, "error", 0)
            }
        }
    }

    execDeviceAction = async (
        accountId: AccountId,
        objectId: string,
        deviceAction: AndroidDeviceActionType,
        thisDeviceActionStates: AndroidDeviceActionStates,
        locationWaiter?: Function
    ) => {
        if (deviceAction == "RESET_PASSWORD") {
            let newModal: any = {
                id: "resetPassword",
                abortable: true,
                content: {
                    title: {
                        text: T("Reset password")
                    },
                    body: {
                        use: true,
                        content: undefined,
                        component: "device-password-reset"
                    }
                },
                onAbort: function () {
                    thisDeviceActionStates[deviceAction] = false
                },
                buttons: [
                    {
                        text: T("Cancel"),
                        icon: "fal fa-times",
                        onClick: async function () {
                            thisDeviceActionStates[deviceAction] = false
                            useStore().commit(MutationTypes.removeModal, { accountId: accountId })
                        },
                        align: "left",
                        loading: false
                    },
                    {
                        text: T("Save"),
                        icon: "fal fa-fw fa-save",
                        onClick: async (modal: any) => {
                            thisDeviceActionStates[deviceAction] = true
                            useStore().getters.getActiveModal(accountId).buttons[1].disabled = true

                            let deviceActionObj: any = {
                                type: "RESET_PASSWORD",
                                resetPasswordFlags: []
                            }

                            if (modal.$refs.modalComponent.newPassword != "") {
                                deviceActionObj.newPassword = modal.$refs.modalComponent.newPassword
                            }
                            if (modal.$refs.modalComponent.REQUIRE_ENTRY) {
                                deviceActionObj.resetPasswordFlags.push("REQUIRE_ENTRY")
                            }
                            if (modal.$refs.modalComponent.DO_NOT_ASK_CREDENTIALS_ON_BOOT) {
                                deviceActionObj.resetPasswordFlags.push(
                                    "DO_NOT_ASK_CREDENTIALS_ON_BOOT"
                                )
                            }
                            if (modal.$refs.modalComponent.LOCK_NOW) {
                                deviceActionObj.resetPasswordFlags.push("LOCK_NOW")
                            }

                            try {
                                await requestHandler.request(
                                    "POST",
                                    this.getApiUrl() +
                                        "/tenants/" +
                                        tenantHelpers.getTenantDomain(accountId) +
                                        "/android/emm/enterprise/devices/" +
                                        objectId,
                                    deviceActionObj
                                )
                                useStore().commit(MutationTypes.removeModal, {
                                    accountId: accountId
                                })
                                thisDeviceActionStates[deviceAction] = false
                            } catch (e: any) {
                                console.error(e)
                                thisDeviceActionStates[deviceAction] = false
                            }
                        },
                        align: "right",
                        disabled: false,
                        loading: false
                    }
                ]
            }

            useVue()?.$refs.modals.addModal(newModal)
        } else if (deviceAction == "LOCATION") {
            new ModalObject({
                accountId: accountId,
                id: "androidLocalizeDevice",
                content: {
                    title: {
                        icon: "fal fa-pencil-alt",
                        text: T("Text der Nachricht")
                    },
                    body: {
                        component: "device-locate",
                        properties: {}
                    }
                },
                buttons: [
                    new ModalObjectButton({
                        text: T("Abort"),
                        icon: "fal fa-times",
                        disabled: false,
                        onClick: () => {
                            getterHelpers.useStore().commit(MutationTypes.removeModal, {})
                        }
                    }),
                    new ModalObjectButton({
                        text: T("Confirm"),
                        icon: "fal fa-check",
                        disabled: false,
                        onClick: async (modalComponent: any, modal) => {
                            const { customReason, selectedOption } =
                                modalComponent?.$refs?.modalComponent
                            const message =
                                selectedOption == T("Other reason") ? customReason : selectedOption
                            const modalButton: ModalObjectButton | undefined = modal?.buttons?.[1]

                            if (message) {
                                try {
                                    // Active Loading
                                    thisDeviceActionStates[deviceAction] = true
                                    modalButton?.disable?.()
                                    modalButton?.startLoader?.()

                                    await requestHandler.request(
                                        "POST",
                                        this.getApiUrl() +
                                            "/tenants/" +
                                            tenantHelpers.getTenantDomain(accountId) +
                                            "/android/emm/enterprise/devices/" +
                                            objectId,
                                        message
                                            ? { type: deviceAction, payload: { message: message } }
                                            : {
                                                  type: deviceAction,
                                                  payload: { message: "Lokalisierung" }
                                              }
                                    )
                                    locationWaiter?.(true, undefined)

                                    // Deactivate Loading
                                    setTimeout(function () {
                                        thisDeviceActionStates[deviceAction] = false
                                        modalButton?.stopLoader?.()
                                        modalButton?.enable?.()
                                    }, 5000)
                                } catch (e: any) {
                                    console.error(e)

                                    // Deactivate Loading
                                    thisDeviceActionStates[deviceAction] = false
                                    modalButton?.stopLoader?.()
                                    modalButton?.enable?.()

                                    locationWaiter?.(false, e)
                                }
                            }
                            getterHelpers
                                .useStore()
                                .commit(MutationTypes.removeModal, { accountId: accountId })
                        }
                    })
                ]
            }).show()
        } else if (deviceAction == "RELINQUISH_OWNERSHIP") {
            dialogs.misc.confirmDialog(
                accountId,
                T("Confirm"),
                `
							<p><strong>${T("Do you really want to relinquish this device?")}</strong></p>
							<p>${T("This removes the work profile and all policies from a company-owned Android 8.0+ device, relinquishing the device for personal use. Apps and data associated with the personal profile(s) are preserved. The device will be deleted from the server after it acknowledges the command.")}</p>
						`,
                async () => {
                    try {
                        thisDeviceActionStates[deviceAction] = true
                        await requestHandler.request(
                            "POST",
                            this.getApiUrl() +
                                "/tenants/" +
                                tenantHelpers.getTenantDomain(accountId) +
                                "/android/emm/enterprise/devices/" +
                                objectId,
                            { type: deviceAction }
                        )
                        await timeHelpers.sleep(5000)
                        thisDeviceActionStates[deviceAction] = false
                        this.queries.deleteObjectFromApi(accountId, objectId)
                        router.navigate(
                            "#show-tenant-" +
                                tenantHelpers.getTenantDomain(accountId) +
                                "-android-devices-dashboard"
                        )
                    } catch (e: any) {
                        console.error(e)
                        thisDeviceActionStates[deviceAction] = false
                    }
                },
                T("RELINQUISH")
            )
        } else if (deviceAction == "START_LOST_MODE") {
            dialogs.mobileSecurity.androidLostMode(accountId, async (component: any) => {
                const {
                    lostPhoneNumber,
                    lostStreetAddress,
                    lostOrganization,
                    lostEmailAddress,
                    reason
                } = component

                thisDeviceActionStates[deviceAction] = true

                await requestHandler.request(
                    "POST",
                    this.getApiUrl() +
                        "/tenants/" +
                        tenantHelpers.getTenantDomain(accountId) +
                        "/android/emm/enterprise/devices/" +
                        objectId,
                    {
                        type: deviceAction,
                        payload: {
                            message: reason
                        },
                        startLostModeParams: {
                            ...(lostOrganization && {
                                lostOrganization: {
                                    defaultMessage: lostOrganization
                                }
                            }),
                            ...(lostStreetAddress && {
                                lostStreetAddress: {
                                    defaultMessage: lostStreetAddress
                                }
                            }),
                            ...(lostPhoneNumber && {
                                lostPhoneNumber: {
                                    defaultMessage: lostPhoneNumber
                                }
                            }),
                            ...(lostEmailAddress && {
                                lostEmailAddress: lostEmailAddress
                            })
                        }
                    }
                )
                await timeHelpers.sleep(5000)

                thisDeviceActionStates[deviceAction] = false
            })
        } else if (deviceAction == "STOP_LOST_MODE") {
            try {
                thisDeviceActionStates[deviceAction] = true
                await requestHandler.request(
                    "POST",
                    this.getApiUrl() +
                        "/tenants/" +
                        tenantHelpers.getTenantDomain(accountId) +
                        "/android/emm/enterprise/devices/" +
                        objectId,
                    {
                        type: deviceAction,
                        stopLostModeParams: {}
                    }
                )
                await timeHelpers.sleep(5000)
                thisDeviceActionStates[deviceAction] = false
            } catch (e: any) {
                console.error(e)
                thisDeviceActionStates[deviceAction] = false
            }
        } else {
            try {
                thisDeviceActionStates[deviceAction] = true
                await requestHandler.request(
                    "POST",
                    this.getApiUrl() +
                        "/tenants/" +
                        tenantHelpers.getTenantDomain(accountId) +
                        "/android/emm/enterprise/devices/" +
                        objectId,
                    { type: deviceAction }
                )
                setTimeout(function () {
                    thisDeviceActionStates[deviceAction] = false
                }, 5000)
            } catch (e: any) {
                console.error(e)
                thisDeviceActionStates[deviceAction] = false
            }
        }
    }
    sortAndroidDevicesByAliasId = (devices: AndroidDevice[]): AndroidDevice[] => {
        return devices.sort((deviceA: AndroidDevice, deviceB: AndroidDevice) => {
            //@ts-ignore
            let aliasA = deviceHelpers
                .getAliasedShortDeviceId(deviceA.deviceId, deviceA.alias || "")
                .toLowerCase()
            //@ts-ignore
            let aliasB = deviceHelpers
                .getAliasedShortDeviceId(deviceB.deviceId, deviceB.alias || "")
                .toLowerCase()
            return aliasA < aliasB ? -1 : aliasA > aliasB ? 1 : 0
        })
    }

    sendDeviceMessage = async (accountid: AccountId, deviceId: string, message: any) => {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            result = await requestHandler.request(
                "POST",
                this.getApiUrl() + "/devices/" + deviceId + "/messages",
                message
            )
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return result
    }

    /**
     * Converts Object for ObjectTypeStore
     */
    convertObjectForStore(accountId: AccountId, objectBase: AddObjectTypeObject<AndroidDevice>) {
        objectBase.id = objectBase?.name?.split("/")[3]
        if ((<EnterpriseDevice>objectBase).licenseUUID) {
            ;(<EnterpriseDevice>objectBase).licenseInformation = licenseHelpers.getLicenseByUUID(
                accountId,
                (<EnterpriseDevice>objectBase).licenseUUID || ""
            )
        }
        if (
            (<EnterpriseDevice>objectBase).inventory?.inventoryLocation &&
            typeof (<EnterpriseDevice>objectBase).inventory?.inventoryLocation == "string"
        ) {
            //@ts-ignore
            ;(<EnterpriseDevice>objectBase).inventory.inventoryLocation = JSON.parse(
                <string>(<EnterpriseDevice>objectBase).inventory.inventoryLocation
            )
        }
    }

    getEnterpriseEnrollmentTokens = async function (accountid: AccountId) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request(
                "GET",
                apis.getTenantApiUrl(tenantDomain, "androidEnterprise") +
                    "/android/emm/enterprise/enrollmentTokens"
            )
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return apis.parseApiResponse(result)
    }
    createEnterpriseEnrollmentToken = async function (
        accountid: AccountId,
        policyId: string,
        enrollmentTokenObj: any
    ) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request(
                "POST",
                apis.getTenantApiUrl(tenantDomain, "androidEnterprise") +
                    "/android/emm/enterprise/policies/" +
                    encodingHelpers.encodeURI(policyId) +
                    "/enrollmentTokens",
                enrollmentTokenObj
            )
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return apis.parseApiResponse(result)
    }
    deleteEnterpriseEnrollmentToken = async function (
        accountid: AccountId,
        enrollmentToken: string
    ) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request(
                "DELETE",
                apis.getTenantApiUrl(tenantDomain, "androidEnterprise") +
                    "/android/emm/enterprise/enrollmentTokens/" +
                    enrollmentToken
            )
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return apis.parseApiResponse(result)
    }
}

const androidDevices = new AndroidDevices({
    objectType: "androidDevices",
    slug: "androidDevices",
    productType: "mobileSecurity",
    hasStore: true,
    appearance: {
        color: "green",
        iconClass: "fal fa-mobile-alt",
        text: {
            singular: "Device",
            plural: "Devices",
            sidebarName: "Devices",
            title: "Devices"
        }
    },
    objectTypeInfo: {
        nameProperty: {
            primary: "alias",
            secondary: "id"
        },
        primaryKeyProperty: {
            property: "id"
        }
    },
    apiInfo: {
        url: "/sms-mgt-api/api/2.0",

        getObjectPath: "/tenants/{tenantDomain}/android/emm/enterprise/devices/{objectId}",
        updateObjectMethod: "PUT",
        updateObjectPath: "/tenants/{tenantDomain}/android/emm/enterprise/devices/{objectId}",
        deleteObjectPath: "/tenants/{tenantDomain}/android/emm/enterprise/devices/{objectId}"
    }
})
export default androidDevices
