import config from "@/classes/config"
import { T } from "@/classes/i18n"
import objectStores from "@/classes/init"
import products from "@/classes/objectTypes"
import encodingHelpers from "@/helpers/helpers.encoding"
import getterHelpers from "@/helpers/helpers.getters"
import timeHelpers from "@/helpers/helpers.time"
import { useGlobalMixin } from "@/mixins/mixins.global"
import requestHandler from "@/queries/requests"
import { MutationTypes } from "@/store/vuex.store"
import loaderComponent from "../components/loader.vue"

const uscProfilesManyRequestsDialogComponent = {
    mixins: [useGlobalMixin()],
    name: "uscProfiles-many-requests-dialog",
    template: `
        <div>
          <div class="text-center padding-xs-y-8 col-xs">
              <div class="text-size-3">
                <loader v-if="!initialized || loading" :progress="percentage" class="text-size-2 color-red" />
                <i v-if="initialized && !loading" class="fal fa-check color-green" style="line-height:79px !important"></i>
              </div>
              <span v-if="loaderInfo" style="opacity: 0.8;">{{ T(loaderInfo) }}</span>
          </div>
        </div>
    `,
    data: () => {
        return {
            initialized: false,
            loading: false,
            loaderInfo: T("Loading..."),
            percentage: 0,
            accountsWithVulnerableUtms: {}
        }
    },
    computed: {},
    props: {
        properties: {
            required: true,
            default: () => {
                return {
                    childObject: {},
                    addProfiles: [],
                    updateProfiles: [],
                    deleteProfiles: []
                }
            }
        }
    },
    methods: {
        init: async function (this: any) {
            this.loading = true
            this.initialized = true

            const addProfiles = this.properties.addProfiles || []
            const updateProfiles = this.properties.updateProfiles || []
            const deleteProfiles = this.properties.deleteProfiles || []
            const childObject = this.properties.childObject

            const requestCount = updateProfiles.length + deleteProfiles.length + addProfiles.length
            const percentagePerRequest = parseFloat((100 / requestCount).toFixed(2))

            if (childObject) {
                if (addProfiles.length) {
                    // add profiles
                    for (let i = 0; addProfiles.length > i; i++) {
                        const tenantDomain = addProfiles[i]
                        this.loaderInfo =
                            T("Adding profile to tenant: " + tenantDomain) +
                            " (" +
                            i +
                            "/" +
                            addProfiles.length +
                            ")"
                        await requestHandler.request(
                            "POST",
                            (config.canUseNewObjectType("uscProfiles")
                                ? products.unifiedSecurityConsole.uscProfiles.options.apiInfo.url
                                : objectStores.uscProfiles.settings.apiInfo.url) +
                                "/tenants/" +
                                tenantDomain +
                                "/utms/profiles",
                            childObject
                        )
                        this.percentage = this.percentage + percentagePerRequest
                    }
                    config.canUseNewObjectType("uscProfiles")
                        ? await products.unifiedSecurityConsole.uscProfiles.queries.getObjectsFromApi(
                              this.activeAccountId
                          )
                        : await objectStores.uscProfiles.getObjectsFromApi(this.activeAccountId)
                }
                if (updateProfiles.length) {
                    // update profiles
                    for (let i = 0; updateProfiles.length > i; i++) {
                        const tenantDomain = updateProfiles[i].split("/")[0]
                        const profileId = updateProfiles[i].split("/")[1]
                        this.loaderInfo =
                            T("Updating profile in tenant: " + tenantDomain) +
                            " (" +
                            i +
                            "/" +
                            updateProfiles.length +
                            ")"
                        await requestHandler.request(
                            "PUT",
                            (config.canUseNewObjectType("uscProfiles")
                                ? products.unifiedSecurityConsole.uscProfiles.options.apiInfo.url
                                : objectStores.uscProfiles.settings.apiInfo.url) +
                                "/tenants/" +
                                tenantDomain +
                                "/utms/profiles/" +
                                encodingHelpers.encodeURI(profileId),
                            childObject
                        )
                        this.percentage = this.percentage + percentagePerRequest
                    }
                }
            }
            if (deleteProfiles.length) {
                // delete profiles
                for (let i = 0; deleteProfiles.length > i; i++) {
                    const tenantDomain = deleteProfiles[i].split("/")[0]
                    const profileId = deleteProfiles[i].split("/")[1]
                    this.loaderInfo =
                        T("Deleting profile in tenant: " + tenantDomain) +
                        " (" +
                        i +
                        "/" +
                        deleteProfiles.length +
                        ")"
                    await requestHandler.request(
                        "DELETE",
                        (config.canUseNewObjectType("uscProfiles")
                            ? products.unifiedSecurityConsole.uscProfiles.options.apiInfo.url
                            : objectStores.uscProfiles.settings.apiInfo.url) +
                            "/tenants/" +
                            tenantDomain +
                            "/utms/profiles/" +
                            encodingHelpers.encodeURI(profileId)
                    )
                    this.percentage = this.percentage + percentagePerRequest
                }
            }
            this.percentage = 100
            this.loading = false
            this.loaderInfo = T("Done") + "!"

            getterHelpers
                .useStore()
                .getters.getActiveModal(
                    getterHelpers.useStore().state.session.activeAccountId
                ).buttons[0].text = "OK"
            getterHelpers
                .useStore()
                .getters.getActiveModal(
                    getterHelpers.useStore().state.session.activeAccountId
                ).buttons[0].icon = "fal fa-check"
            getterHelpers
                .useStore()
                .getters.getActiveModal(
                    getterHelpers.useStore().state.session.activeAccountId
                ).buttons[0].loading = false
            getterHelpers
                .useStore()
                .getters.getActiveModal(
                    getterHelpers.useStore().state.session.activeAccountId
                ).buttons[0].disabled = false
            getterHelpers
                .useStore()
                .getters.getActiveModal(
                    getterHelpers.useStore().state.session.activeAccountId
                ).buttons[0].align = "right"

            getterHelpers
                .useStore()
                .getters.getActiveModal(
                    getterHelpers.useStore().state.session.activeAccountId
                ).buttons[0].onClick = function () {
                getterHelpers.useStore().commit(MutationTypes.removeModal, {
                    accountId: getterHelpers.useStore().state.session.activeAccountId
                })
            }
        }
    },
    mounted: async function (this: any) {
        await timeHelpers.sleep(500)
        this.init()
    },
    watch: {},
    beforeUnmount: function (this: any) {},
    components: {
        loader: loaderComponent
    }
}
export default uscProfilesManyRequestsDialogComponent
