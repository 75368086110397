import { useFeatureStore } from "@/classes/featureStore"
import { T } from "@/classes/i18n"
import dialogs from "@/dialogs/dialogs"
import miscDialogs from "@/dialogs/dialogs.misc"
import secureDnsDialogs from "@/dialogs/dialogs.secure.dns"
import deviceHelpers from "@/helpers/helpers.devices"
import licenseHelpers from "@/helpers/helpers.license"
import tenantHelpers from "@/helpers/helpers.tenants"
import router from "@/router/router"
import { ActionTypes, MutationTypes, useStore } from "@/store/vuex.store"
import Button from "@/templates/components/button/button"
import IntroductionComponent from "@/templates/components/introductions/secure-dns-introduction.vue"
import { sprintf } from "sprintf-js"
import products from "../.."
import ObjectType, { type ObjectTypePayload } from "../../objectType"

export enum ESecDnsType {
    MDM = 0,
    EXTERNAL_DEVICES = 1
}
export interface TSecureDns {
    id: string
    name: string
    type: ESecDnsType
    licenseUUID: string
    devicesCount: number
    blockedCategories: string[]
    allowedCategories: {
        id: string
        schedule: number[]
    }[]
    blockedCountries: string[]
    allowlist: {
        ips: string[]
        hosts: string[]
    }
    denylist: {
        ips: string[]
        hosts: string[]
    }
    performance: {
        cacheBoost: boolean
    }
    security: {
        malwareAiProtection: boolean
        dnsRebindingProtection: boolean
        idnHomographProtection: boolean
        safeSearch: boolean
        youtubeRm: boolean
        cnameCloakingProtection: boolean
    }
    rewrites: {
        domain: string
        answer: string
    }[]
    logs: {
        enabled: boolean
        clientName: boolean
        domains: boolean
    }
    blockPage: {
        enabled: boolean
    }
    endpoints: {
        doh: string
        dot: string
        mobileConfig: string
        shortId: string
    }
}

async function confirmDialogAsync(
    accountid: string,
    title: string,
    content: string,
    enterString?: string,
    okButtonText?: string,
    okButtonIcon?: string,
    abortable: boolean = true,
    dialogIcon?: string,
    autoRemove: boolean = true
): Promise<boolean> {
    return new Promise((resolve) => {
        miscDialogs.confirmDialog(
            accountid,
            title,
            content,
            () => {
                resolve(true)
            },
            enterString,
            okButtonText,
            okButtonIcon,
            abortable,
            dialogIcon,
            () => {
                resolve(false)
            },
            autoRemove
        )
    })
}

class SecureDns extends ObjectType<TSecureDns> {
    constructor(payload: ObjectTypePayload<TSecureDns>) {
        super(payload)

        this.itemlist.getSortingOptions = () => {
            return [
                {
                    id: "name",
                    text: T("Name")
                }
            ]
        }
        this.itemlist.getToolbarButtons = (accountId, itemlistComponent) => {
            let thisToolbarEntries = []

            if (useFeatureStore().isEnabled("SECURE_DNS_EXTERNAL_DEVICES")) {
                thisToolbarEntries.push({
                    icon: "fal fa-envelope",
                    title: T("Send invite"),
                    onClick: () => {
                        dialogs.secureDns.inviteSecureDNSClients(accountId)
                    },
                    id: "vpnsButtonAdd",
                    vIf: false
                })
            }

            thisToolbarEntries.push({
                icon: "fal fa-plus",
                title: T("Add profile"),
                link:
                    "#add-tenant-" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "-secureDnsProfiles",
                id: "vpnsButtonAdd",
                vIf: false
            })

            return thisToolbarEntries
        }

        this.itemlistItem.hasCheckbox = (item, accountId) => {
            return accountId ? !SecureDns.getLinkedMDMProfiles(accountId, item).isMdmLinked : false
        }

        this.itemlistItem.getTitle = (item, component) => {
            return {
                title: item?.name,
                small: `(${deviceHelpers.getShortDeviceId(item?.id)})`
            }
        }

        this.itemlistItem.getLabels = (accountId, item) => {
            const labels: any[] = []

            if (item.type === ESecDnsType.MDM) {
                labels.push({
                    title: T("MDM"),
                    text: T("MDM"),
                    icon: "fal fa-mobile",
                    displayType: "label"
                })
            }

            if (item.type === ESecDnsType.EXTERNAL_DEVICES) {
                labels.push({
                    title: T("External devices"),
                    text: T("External devices"),
                    icon: "fal fa-router",
                    displayType: "label"
                })
            }

            return labels
        }

        this.itemlistItem.getMenuEntries = (accountId, item) => {
            let menuLinks = []
            const tenantDomain = tenantHelpers.getTenantDomain(accountId)
            const profiles = SecureDns.getLinkedMDMProfiles(accountId, item)

            menuLinks.push(
                new Button({
                    title: T("Edit"),
                    text: T("Edit"),
                    link: "#edit-tenant-" + tenantDomain + "-secureDnsProfiles-" + item?.id,
                    icon: "fal fa-edit"
                })
            )

            menuLinks.push(
                new Button({
                    id: "copy",
                    title: T("Copy"),
                    text: T("Copy"),
                    onClick: () => {
                        const obj = JSON.parse(JSON.stringify(item))

                        obj.oldItemName = obj.name
                        obj.name = obj.name + T("_Copy")
                        delete obj.id
                        delete obj.endpoints
                        delete obj.stats

                        useStore().commit(MutationTypes.setClipboard, {
                            objectType: "secureDnsProfiles",
                            objects: [obj]
                        })

                        useStore().dispatch(ActionTypes.addNotification, {
                            accountId: accountId,
                            content: {
                                title: {
                                    icon: "fal fa-exclamation-triangle",
                                    text: "Clipboard"
                                },
                                body: {
                                    content: "Added item to clipboard"
                                }
                            },
                            highlightNew: true
                        })
                    },
                    icon: "fal fa-clone"
                })
            )

            menuLinks.push(
                new Button({
                    title: T("Delete"),
                    text: T("Delete"),
                    onClick: () => {
                        this.dialogs.getDeleteObjectDialog(accountId, item)
                    },
                    icon: "fal fa-trash",
                    disabled: profiles.isMdmLinked
                })
            )
            return menuLinks
        }

        this.itemlistItem.getDetails = (accountId, item) => {
            if (!item) {
                return []
            }

            const profiles = SecureDns.getLinkedMDMProfiles(accountId, item)

            let logInfo = ""
            if (item.logs.enabled) {
                logInfo = T("Enabled")

                logInfo += ` (${T("Domains")}: ${item.logs.domains ? T("Yes") : T("No")} - `
                logInfo += ` ${T("Client Names")}: ${item.logs.clientName ? T("Yes") : T("No")})`
            } else {
                logInfo = T("Disabled")
            }

            const details: any[] = [
                {
                    iconClass: "fal fa-fw fa-check",
                    title: T("Allowed hosts"),
                    key: T("Allowed hosts"),
                    value: item.allowlist.hosts.length + item.allowlist.ips.length
                },
                {
                    iconClass: "fal fa-fw fa-ban",
                    title: T("Blocked hosts"),
                    key: T("Blocked hosts"),
                    value: item.denylist.hosts.length + item.denylist.ips.length
                },
                {
                    iconClass: "fal fa-fw fa-filter",
                    title: T("Filter categories"),
                    key: T("Filter categories"),
                    value: item.blockedCategories?.length || 0
                },
                {
                    iconClass: "fal fa-fw fa-globe",
                    title: T("Filtered countries"),
                    key: T("Filtered countries"),
                    value: item.blockedCountries.length
                },
                {
                    iconClass: "fal fa-fw fa-bars",
                    title: T("Logs"),
                    key: T("Logs"),
                    value: logInfo
                }
            ]

            if (item.type === ESecDnsType.MDM) {
                if (profiles.android.length || profiles.apple.length) {
                    details.push({
                        iconClass: "fal fa-shield-alt",
                        title: T("MDM-Profiles"),
                        key: T("MDM-Profiles"),
                        labels: profiles.apple
                            .map((profile) => {
                                return {
                                    id: profile.profileId,
                                    title: profile.profilename,
                                    text: profile.profilename,
                                    onClick: function () {
                                        router.navigate(
                                            `#edit-tenant-${accountId}.sms-ios-profile-${profile.profileId}`
                                        )
                                    },
                                    displayType: "label",
                                    icon: "fab fa-apple"
                                }
                            })
                            .concat(
                                profiles.android.map((profile) => {
                                    const name = profile.name.split("/")[3]

                                    return {
                                        id: name,
                                        title: name,
                                        text: name,
                                        onClick: function () {
                                            router.navigate(
                                                `#edit-tenant-${accountId}.sms-android-profile-${name}`
                                            )
                                        },
                                        displayType: "label",
                                        icon: "fab fa-android"
                                    }
                                })
                            )
                    })
                } else {
                    details.push({
                        iconClass: "fal fa-shield-alt",
                        title: T("MDM-Profiles"),
                        key: T("MDM-Profiles"),
                        value: T("None")
                    })
                }
            } else {
                details.push({
                    iconClass: "fal fa-mobile",
                    title: T("Devices Count"),
                    key: T("Devices Count"),
                    value: item.devicesCount
                })
            }

            details.push({
                iconClass: "fal fa-fw fa-cog",
                title: T("Configuration"),
                key: T("Configuration"),
                labels: [
                    {
                        title: T("Show configuration"),
                        text: T("show"),
                        onClick: function () {
                            secureDnsDialogs.showEndpointGenerator(accountId, item, false)
                        },
                        icon: "fal fa-eye",
                        class: "bg-red",
                        displayType: "label"
                    }
                ]
            })

            return details
        }

        this.itemlistItem.onClick = (accountId, item) => {
            router.navigate("#edit-tenant-" + accountId + ".sms-secureDnsProfiles-" + item?.id)
        }

        this.itemlist.getIntroductionComponent = () => {
            return IntroductionComponent
        }
    }

    async beforeSavingForm(
        accountId: string,
        form: any,
        newObject: TSecureDns,
        objectId?: string,
        rootObject?: TSecureDns
    ): Promise<boolean> {
        if (newObject.type !== ESecDnsType.EXTERNAL_DEVICES) {
            return true
        }

        let oldObject: TSecureDns | undefined = undefined
        if (objectId) {
            const obj = this.useStore?.().getObjectStoreObject(accountId, objectId)
            if (obj) {
                oldObject = obj
            }
        }

        if (
            oldObject &&
            oldObject.devicesCount === newObject.devicesCount &&
            oldObject.licenseUUID === newObject.licenseUUID
        ) {
            return true
        }

        const license = licenseHelpers.getLicenseByUUID(accountId, newObject.licenseUUID)

        const firstWarning = await confirmDialogAsync(
            accountId,
            "Securepoint Cloud Shield",
            sprintf(
                T(
                    "When this Cloud Shield profile is saved, <b>%d devices</b> are booked to the license <b>%s</b>. Depending on the agreed license model, this may incur costs.<br>Do you want to continue?"
                ),
                newObject.devicesCount,
                license?.name || newObject.licenseUUID
            ),
            undefined,
            T("Book")
        )

        // ignore if the user cancels the dialog or the device count is below 100
        if (newObject.devicesCount < 100 || !firstWarning) {
            return firstWarning
        }

        const secondWarning = await confirmDialogAsync(
            accountId,
            "Securepoint Cloud Shield",
            sprintf(
                T("Do you really want to book Cloud Shield for <b>%d devices</b>?"),
                newObject.devicesCount
            ),
            undefined,
            T("Book")
        )

        return secondWarning
    }

    private static getLinkedMDMProfiles(accountId: string, item: TSecureDns) {
        if (!item.endpoints?.shortId) {
            return {
                apple: [],
                android: [],
                isMdmLinked: false
            }
        }

        const appleProfiles = (
            products.mobileSecurity.iosProfiles.useStore?.().getObjectStoreObjects(accountId) || []
        ).filter((profile) => {
            return profile.profile?.secureDns?.profile === item.id
        })

        const androidProfiles = (
            products.mobileSecurity.androidProfiles.useStore?.().getObjectStoreObjects(accountId) ||
            []
        ).filter((profile) => {
            return profile.applications?.some(
                (app) =>
                    app.packageName === "de.securepoint.securedns" &&
                    app.managedConfiguration?.configId === item.endpoints?.shortId
            )
        })

        const isMdmLinked = !!appleProfiles.length || !!androidProfiles.length

        return {
            apple: appleProfiles,
            android: androidProfiles,
            isMdmLinked
        }
    }
}

const secureDns = new SecureDns({
    productType: "secureDns",
    slug: "secureDnsProfiles",
    objectType: "secureDnsProfiles",
    hasStore: true,
    appearance: {
        iconClass: "fal fa-shield-alt",
        text: {
            plural: "Cloud Shield profiles",
            title: "Cloud Shield",
            sidebarName: "Cloud Shield",
            singular: "Cloud Shield profile"
        },
        color: "red",
        showInSidebar: true,
        showOnDashboard: true
    },
    objectTypeInfo: {
        primaryKeyProperty: {
            property: "id",
            pathToPrimaryProperty: undefined
        },
        nameProperty: {
            primary: "name",
            pathToPrimaryProperty: undefined,
            secondary: undefined,
            pathToSecondaryProperty: undefined
        }
    },
    apiInfo: {
        url: "/sms-mgt-api/api/2.0",
        getCountGETProperties: "?props[]=null&select=data.count",
        // GET
        getObjectListResponseProperty: "securedns",
        getObjectListMethod: "GET",
        getObjectListPath: "/tenants/{tenantDomain}/secure-dns/configs",
        // Update
        updateObjectMethod: "PUT",
        updateObjectPath: "/tenants/{tenantDomain}/secure-dns/configs/{objectId}"
    }
})

export default secureDns
