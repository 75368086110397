import config from "@/classes/config"
import products, { prepareObjectTypeStores } from "../classes/objectTypes/index"
import type { UserSession } from "../classes/storeSession"
import tenantHelpers from "../helpers/helpers.tenants"
import unifiedSecurity from "../queries/unified.security"
import { ActionTypes, MutationTypes, useStore } from "../store/vuex.store"

const accounts = {
    getAccounts: async (session: UserSession) => {
        let vuexStore = useStore()
        await prepareObjectTypeStores(session)
        let tenants: any
        if (session.roles.indexOf("__RESELLER__") != -1) {
            await unifiedSecurity.syncTenants()
        }
        if (session?.scopes?.list_accounts) {
            try {
                tenants = await unifiedSecurity.getTenants()
            } catch (e: any) {
                tenants = {
                    tenants: []
                }
            }
        }
        vuexStore.commit(MutationTypes.setAccounts, { session: session, tenants: tenants?.tenants })

        if (config.canUseNewObjectType("licenses")) {
            await products.unifiedSecurity.licenses.queries.getObjectsFromApi(
                session.targetDomain || session.domain,
                undefined,
                [
                    { property: "with_subtenants", value: "true" },
                    { property: "props[]", value: "accountId" },
                    { property: "props[]", value: "accountName" },
                    { property: "props[]", value: "accountNr" },
                    { property: "props[]", value: "clusterUuid" },
                    { property: "props[]", value: "creationDate" },
                    { property: "props[]", value: "dead" },
                    { property: "props[]", value: "deadDate" },
                    { property: "props[]", value: "deadTimestamp" },
                    { property: "props[]", value: "deletionRequest" },
                    { property: "props[]", value: "deviceId" },
                    { property: "props[]", value: "expirationDate" },
                    { property: "props[]", value: "expirationTimestamp" },
                    { property: "props[]", value: "expired" },
                    { property: "props[]", value: "expiredDays" },
                    { property: "props[]", value: "extendsLicenseId" },
                    { property: "props[]", value: "family" },
                    { property: "props[]", value: "id" },
                    { property: "props[]", value: "isAllowedToSetLimit" },
                    { property: "props[]", value: "laasEnabled" },
                    { property: "props[]", value: "licenseScopes" },
                    { property: "props[]", value: "markedDeleted" },
                    { property: "props[]", value: "mobsec" },
                    { property: "props[]", value: "mspLicenseId" },
                    { property: "props[]", value: "name" },
                    { property: "props[]", value: "nfr" },
                    { property: "props[]", value: "serial" },
                    { property: "props[]", value: "spare" },
                    { property: "props[]", value: "state" },
                    { property: "props[]", value: "tenantDomain" },
                    { property: "props[]", value: "type" },
                    { property: "props[]", value: "uscMessagingEnabled" },
                    { property: "props[]", value: "uscMonitoringEnabled" },
                    { property: "props[]", value: "user" },
                    { property: "props[]", value: "uuid" }
                ]
            )
        } else {
            await vuexStore.dispatch(ActionTypes.getObjectInfos, {
                accountId: tenantHelpers.getAccountId(session.targetDomain || session.domain),
                objectTypes: [
                    "licenses?with_subtenants=true&props[]=accountId&props[]=accountName&props[]=accountNr&props[]=clusterUuid&props[]=creationDate&props[]=dead&props[]=deadDate&props[]=deadTimestamp&props[]=deletionRequest&props[]=deviceId&props[]=expirationDate&props[]=expirationTimestamp&props[]=expired&props[]=expiredDays&props[]=extendsLicenseId&props[]=family&props[]=id&props[]=isAllowedToSetLimit&props[]=laasEnabled&props[]=licenseScopes&props[]=markedDeleted&props[]=mobsec&props[]=mspLicenseId&props[]=name&props[]=nfr&props[]=serial&props[]=spare&props[]=state&props[]=tenantDomain&props[]=type&props[]=uscMessagingEnabled&props[]=uscMonitoringEnabled&props[]=user&props[]=uuid"
                ]
            })
        }

        vuexStore.state.session.initialized = true
    }
}
export default accounts
