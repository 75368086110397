import ClipboardHandler from "@/classes/clipboard"
import i18n, { T } from "@/classes/i18n"
import frontendNotifications from "@/classes/notifications"
import dialogs from "@/dialogs/dialogs"
import miscDialogs from "@/dialogs/dialogs.misc"
import deviceHelpers from "@/helpers/helpers.devices"
import getterHelpers from "@/helpers/helpers.getters"
import licenseHelpers from "@/helpers/helpers.license"
import mixinHelpers from "@/helpers/helpers.mixins"
import numberHelpers from "@/helpers/helpers.numbers"
import tenantHelpers from "@/helpers/helpers.tenants"
import timeHelpers from "@/helpers/helpers.time"
import miscQueries from "@/queries/misc"
import router from "@/router/router"
import { ActionTypes, MutationTypes } from "@/store/vuex.store"
import Button from "@/templates/components/button/button"
import IntroductionComponent from "@/templates/components/introductions/windows-vpns-introduction.vue"
import { ModalObjectButton } from "@/templates/components/modals/modalClass"
import ObjectType, {
    type ItemlistItemDetail,
    type Label,
    type ObjectTypeObject,
    type ObjectTypePayload
} from "../../objectType"

export interface WindowsEnrollmentToken {
    id: string
    name?: string
    comment?: string
    details?: {
        tags?: string[]
        expiresIn: number
        maxDevices: number
    }
    tags?: string[]
    token?: string
    isExpired?: boolean
    enrolledDevices?: number
    expiresAt?: number
    lastInvalidEnrollmentAttempt?: number
    lastEnrollmentAttempt?: number
    enrolledDevicesCount: number
    licenseUUID: string
}

class WindowsEnrollmentTokens extends ObjectType<WindowsEnrollmentToken> {
    constructor(payload: ObjectTypePayload<WindowsEnrollmentToken>) {
        super(payload)
        const thisObjectType = this
        this.itemlist.getSortingOptions = () => {
            return [
                {
                    id: "lastEnrollmentAttempt",
                    text: T("Last use")
                },
                {
                    id: "name",
                    text: T("Name")
                },
                {
                    id: "isExpired",
                    text: T("Validity")
                }
            ]
        }

        this.itemlist.getIntroductionComponent = () => {
            return IntroductionComponent
        }

        this.itemlistItem.getLabels = (
            accountId: string,
            item: ObjectTypeObject<WindowsEnrollmentToken>
        ) => {
            let result: Label[] = []
            if (item?.hasOwnProperty("isExpired") && item?.isExpired) {
                result.push({
                    icon: "fal fa-clock",
                    class: "bg-red",
                    displayType: "label",
                    text: T("Expired"),
                    title: T("Expired")
                })
            }
            const lastInvalidAttempt = this.checkLastInvalidAttempt(
                item?.lastInvalidEnrollmentAttempt
            )
            if (lastInvalidAttempt.isInvalid == true) {
                let text =
                    T("Used After Expiry") + ". " + T("Dieses Label wird noch %s %y angezeigt.")
                let dayNumber = 31 - lastInvalidAttempt.abs
                let lang = i18n.getLanguage()
                let days = dayNumber > 1 ? dayNumber.toString() : ""
                let textException =
                    dayNumber > 1
                        ? T("Tage")
                        : lang == "de"
                          ? T("einen") + " " + T("Tag")
                          : T("Tag")

                text = text.replace("%s", days)
                text = text.replace("%y", textException)

                result.push({
                    icon: "fal fa-triangle-exclamation",
                    class: "bg-red",
                    displayType: "label",
                    text: T("Used After Expiry"),
                    title: text
                })
            }

            if (item?.licenseUUID && item?.licenseUUID != "") {
                let licenseInfo = licenseHelpers.getLicenseByUUID(accountId, item.licenseUUID)
                if (
                    licenseInfo &&
                    licenseInfo.assignedUser >= mixinHelpers.getLicenseLimit(licenseInfo)
                ) {
                    result.push({
                        icon: "fal fa-triangle-exclamation",
                        class: "bg-red",
                        displayType: "label",
                        text: T("License limit reached"),
                        title: T("License limit reached")
                    })
                }
            } else {
                result.push({
                    icon: "fal fa-triangle-exclamation",
                    class: "bg-red",
                    displayType: "label",
                    text: T("Missing license"),
                    title: T("Missing license")
                })
            }

            return result
        }

        this.itemlist.getToolbarButtons = (accountId, itemlistComponent) => {
            itemlistComponent = itemlistComponent.exposed
            let toolBarEntries = []
            toolBarEntries.push({
                id: "addEnrollmentToken",
                icon: "fal fa-plus",
                title: T("Add enrollment token"),
                text: T("Add enrollment token"),
                link:
                    "#add-tenant-" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "-windows-enrollmenttoken"
            })

            if (
                this.isEnrollmentAllowed(accountId) &&
                itemlistComponent?.accountRestrictions.value == 0
            ) {
                toolBarEntries.push({
                    icon: "fal fa-paper-plane",
                    title: T("Enroll new VPN Client"),
                    onClick: () => {
                        dialogs.adaptiveSecureConnect.inviteWindowsVpnClient(accountId)
                    },
                    id: "vpnClientsButtonEnroll",
                    vIf: false
                })
            } else {
                toolBarEntries.push({
                    icon: "fal fa-exclamation-triangle",
                    title: T("Enrollment disabled"),
                    link: "",
                    id: "vpnClientsButtonEnroll",
                    disabled: true,
                    vIf: false
                })
            }

            toolBarEntries.push({
                id: "toggleExpiredTokensButton",
                icon: "fal fa-clock",
                title: T("Show expired tokens"),
                onClick: async () => {
                    itemlistComponent.toggleExpiredTokens()
                },
                class: itemlistComponent.showExpiredTokens.value == true ? "active" : ""
            })
            return toolBarEntries
        }

        this.itemlistItem.hasCheckbox = () => {
            return true
        }

        this.itemlistItem.getMenuEntries = (accountId, item) => {
            let menuLinks = []
            const isExpired = item ? item.hasOwnProperty("isExpired") && item.isExpired : false
            let licenseInfo = undefined
            if (item?.licenseUUID && item?.licenseUUID != "") {
                licenseInfo = licenseHelpers.getLicenseByUUID(accountId, item.licenseUUID)
            }
            const isUsable =
                licenseInfo &&
                licenseInfo.assignedUser < mixinHelpers.getLicenseLimit(licenseInfo) &&
                !isExpired
            if (isUsable) {
                menuLinks.push(
                    new Button({
                        title: T("Send invitation Email"),
                        text: T("Send invitation Email"),
                        onClick: () => {
                            dialogs.adaptiveSecureConnect.inviteWindowsVpnClient(accountId, item)
                        },
                        icon: "fal fa-paper-plane"
                    })
                )
            }

            if (isUsable) {
                menuLinks.push(
                    new Button({
                        title: T("Export"),
                        text: T("Export"),
                        onClick: () => {
                            this.exportObject(accountId, item?.id)
                        },
                        icon: "fal fa-download"
                    })
                )
            }

            menuLinks.push(
                new Button({
                    title: T("Delete"),
                    text: T("Delete"),
                    onClick: () => {
                        this.dialogs.getDeleteObjectDialog(accountId, item)
                    },
                    icon: "fal fa-trash"
                })
            )
            return menuLinks
        }

        this.itemlistItem.getDetails = (accountId, item) => {
            const thisClass = this
            const isExpired = item ? item.hasOwnProperty("isExpired") && item.isExpired : false
            let licenseInfo = undefined
            if (item?.licenseUUID && item?.licenseUUID != "") {
                licenseInfo = licenseHelpers.getLicenseByUUID(accountId, item.licenseUUID)
            }
            const isUsable =
                licenseInfo &&
                licenseInfo.assignedUser < mixinHelpers.getLicenseLimit(licenseInfo) &&
                !isExpired
            let expiryString = T("Expired")
            if (item.hasOwnProperty("isExpired") && item.isExpired == false && item.expiresAt) {
                const unixTimetamp: number = item.expiresAt * 1000
                const today = new Date()
                const expiryDay = new Date(unixTimetamp)
                const diff = expiryDay.getTime() - today.getTime()
                const dayDiff = Math.floor(diff / (60 * 60 * 1000) / 24)
                expiryString = timeHelpers.formatDate(
                    unixTimetamp,
                    timeHelpers.getDateFormatI18n(true)
                )
                if (dayDiff > 366) {
                    expiryString = T("Never")
                }
            }

            let lastUseString = item.lastEnrollmentAttempt
                ? timeHelpers.formatDate(
                      item.lastEnrollmentAttempt * 1000,
                      timeHelpers.getDateFormatI18n(true)
                  )
                : T("Not used yet")

            let thisDetails: ItemlistItemDetail[] = [
                {
                    iconClass: "fal fa-fw fa-tags",
                    title: "Tags",
                    key: "Tags",
                    [item ? "labels" : "value"]: item
                        ? (item?.tags || item?.details?.tags)?.map(function (tag: string) {
                              return {
                                  id: tag,
                                  text: tag,
                                  title: tag,
                                  onClick: undefined,
                                  displayType: "label"
                              }
                          })
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-clock",
                    title: T("Expiry date"),
                    key: T("Expiry date"),
                    value: item
                        ? expiryString
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-clock",
                    title: T("Last use"),
                    key: T("Last use"),
                    value: item
                        ? lastUseString
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-computer",
                    title: T("Devices"),
                    key: T("Devices"),
                    value: item
                        ? item.enrolledDevicesCount
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-comment",
                    title: T("Comment"),
                    key: T("Comment"),
                    value: item
                        ? item.comment
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-key",
                    title: T("Key"),
                    key: T("Key"),
                    [item ? "labels" : "content"]: item
                        ? [
                              {
                                  title: isUsable
                                      ? T("Copy to clipboard")
                                      : T("Your license has exceeded its user limit"),
                                  text: T("Copy to clipboard"),
                                  onClick: !isUsable
                                      ? undefined
                                      : function () {
                                            if (item.token) {
                                                const clipboard = new ClipboardHandler()
                                                clipboard.copy(item.token || "")
                                                frontendNotifications.addNotification(accountId, {
                                                    accountId: accountId,
                                                    content: {
                                                        body: {
                                                            content:
                                                                item.token?.substring(0, 20) + "..."
                                                        },
                                                        title: {
                                                            text:
                                                                T("Enrollment token") +
                                                                " " +
                                                                thisObjectType.itemlistItem.getTitle(
                                                                    item,
                                                                    undefined
                                                                ).title +
                                                                " " +
                                                                T("copied"),
                                                            icon: "fal fa-check"
                                                        }
                                                    },
                                                    highlightNew: true
                                                })
                                            }
                                        },
                                  icon: "fal fa-copy",
                                  class: !isUsable ? "disabled" : "bg-red",
                                  displayType: "label"
                              }
                          ]
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-file-zipper",
                    title: T("Export"),
                    key: T("Export"),
                    [item ? "labels" : "content"]: item
                        ? [
                              {
                                  title: isUsable
                                      ? T("Download token and client")
                                      : T("Your license has exceeded its user limit"),
                                  text: T("Download token and client"),
                                  onClick: !isUsable
                                      ? undefined
                                      : async function () {
                                            await thisClass.exportObject(accountId, item.id)
                                        },
                                  icon: "fal fa-download",
                                  class: !isUsable ? "disabled" : "bg-red",
                                  displayType: "label"
                              }
                          ]
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                }
            ]

            if (item?.licenseUUID && item?.licenseUUID != "" && licenseInfo) {
                thisDetails.push({
                    iconClass: "fal fa-fw fa-key",
                    title: T("License"),
                    key: T("License"),
                    [item ? "labels" : "value"]: item
                        ? [
                              {
                                  id: item.licenseUUID + "_" + licenseInfo?.name,
                                  text:
                                      licenseInfo?.type +
                                      " (" +
                                      deviceHelpers.getShortDeviceId(String(item.licenseUUID)) +
                                      ")" +
                                      " " +
                                      "[" +
                                      licenseInfo?.assignedUser +
                                      "/" +
                                      mixinHelpers.getLicenseLimit(licenseInfo) +
                                      "]",
                                  title:
                                      licenseInfo?.type +
                                      " (" +
                                      deviceHelpers.getShortDeviceId(String(item.licenseUUID)) +
                                      ")" +
                                      " " +
                                      "[" +
                                      licenseInfo?.assignedUser +
                                      "/" +
                                      mixinHelpers.getLicenseLimit(licenseInfo) +
                                      "]",
                                  onClick: () => {
                                      router.navigate(
                                          "show-tenant-" +
                                              accountId +
                                              ".sms-license-" +
                                              licenseInfo?.id +
                                              "-details"
                                      )
                                  },
                                  displayType: "label"
                              }
                          ]
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                })
            } else {
                thisDetails.push({
                    iconClass: "fal-fa-fw fa-key",
                    title: T("License"),
                    key: T("License"),
                    [item ? "labels" : "value"]: item
                        ? [
                              {
                                  id: "",
                                  text: T("Not licensed"),
                                  title: T("Not licensed"),
                                  displayType: "label"
                              }
                          ]
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                })
            }

            return thisDetails
        }

        this.itemlistItem.onClick = (accountId, item) => {
            router.navigate("edit-tenant-" + accountId + ".sms-windows-enrollmenttoken-" + item.id)
        }

        /**
         * Converts Object for ObjectTypeStore
         */
        this.convertObjectForStore = (accountId, objectBase) => {}
        this.dialogs = {
            getDeleteObjectDialog: (accountId, object, customerId, confirm = false) => {
                let objectId = this.getObjectId(object)
                let objectName = this.itemlistItem.getTitle(
                    object as ObjectTypeObject<WindowsEnrollmentToken>,
                    undefined
                ).title
                miscDialogs.confirmDialog(
                    accountId,
                    T("Confirm delete"),
                    T("Do you really want to delete this enrollment token?") +
                        (objectName ? " (" + objectName + ")" : ""),
                    async () => {
                        await this.queries.deleteObjectFromApi(accountId, objectId, customerId)
                    },
                    confirm ? T("Delete") : undefined
                )
            }
        }
    }
    isEnrollmentAllowed(accountId: string) {
        return (
            <boolean | undefined>(
                getterHelpers.useStore().state.session.accounts[accountId]?.mobileSecurity?.settings
                    ?.enrollmentAllowed
            ) ?? false
        )
    }
    copyItemToClipboard = (objectId: string, accountId: string) => {
        const thisClass = this
        const enrollmentToken = thisClass.useStore?.().getObjectStoreObject(accountId, objectId)

        if (enrollmentToken && enrollmentToken.token) {
            const clipboard = new ClipboardHandler()

            clipboard.copy(enrollmentToken.token)
            frontendNotifications.addNotification(accountId, {
                accountId: accountId,
                content: {
                    body: {
                        content: enrollmentToken.token.substring(0, 20) + "..."
                    },
                    title: {
                        text:
                            T("xEnrollmenttoken") +
                            " " +
                            thisClass.itemlistItem.getTitle(enrollmentToken, undefined).title +
                            " " +
                            T("copied"),
                        icon: "fal fa-check"
                    }
                },
                highlightNew: true
            })
        }
    }
    /**
     *
     * @param lastInvalidEnrollmentAttempt Timestamp, when the last enroll attempt was tried
     * @returns An object with { isInvalid: boolean, abs: number } isInvalid: Whether the last attempt is within the last 30 days, abs: Number of Days the token is expired
     */
    checkLastInvalidAttempt = (lastInvalidEnrollmentAttempt: number | undefined) => {
        let result: { isInvalid: boolean; abs: number } = {
            isInvalid: false,
            abs: 0
        }
        if (typeof lastInvalidEnrollmentAttempt == "number" && lastInvalidEnrollmentAttempt > 0) {
            const unixTimetamp = lastInvalidEnrollmentAttempt * 1000
            const today = new Date()
            const lastInvalidDate = new Date(unixTimetamp)
            const dayDiff = Math.floor(
                Math.abs(today.getTime() - lastInvalidDate.getTime()) / (1000 * 60 * 60 * 24)
            )

            if (dayDiff < 31) {
                result.isInvalid = true
                result.abs = dayDiff
            }
        }
        return result
    }
    showWindowsPrivacyPolicy = (tenantDomain: string, navigateUrl?: string) => {
        const accountId = tenantHelpers.getAccountId(tenantDomain)

        getterHelpers.useStore().dispatch(ActionTypes.addModal, {
            accountId: accountId,
            id: "windowsPrivacyPolicy",
            content: {
                title: {
                    text: T("Privacy Policy"),
                    icon: "fal fa-info-circle"
                },
                body: {
                    content: undefined,
                    component: "windows-privacy-policy",
                    properties: {}
                }
            },
            buttons: [
                new ModalObjectButton({
                    text: T("OK"),
                    icon: "fal fa-check",
                    onClick: async function () {
                        getterHelpers.useStore().commit(MutationTypes.removeModal, {
                            accountId: accountId
                        })
                        if (navigateUrl) {
                            router.navigate(navigateUrl)
                        }
                    },
                    align: "left",
                    disabled: false,
                    loading: false
                })
            ]
        })
    }
    showWindowsLicenseConditions = async (tenantDomain: string, navigateUrl?: string) => {
        const accountId = tenantHelpers.getAccountId(tenantDomain)

        getterHelpers.useStore().dispatch(ActionTypes.addModal, {
            accountId: accountId,
            id: "windowsLicenseConditions",
            content: {
                title: {
                    text: T("License conditions"),
                    icon: "fal fa-info-circle"
                },
                body: {
                    content: undefined,
                    component: "windows-license-conditions",
                    properties: {}
                }
            },
            buttons: [
                new ModalObjectButton({
                    text: T("OK"),
                    icon: "fal fa-check",
                    onClick: async function (modalComponent, modal) {
                        getterHelpers.useStore().commit(MutationTypes.removeModal, {
                            accountId: accountId
                        })
                        if (navigateUrl) {
                            router.navigate(navigateUrl)
                        }
                    },
                    align: "left",
                    disabled: false,
                    loading: false
                })
            ]
        })
    }

    exportObject = async (accountId: string, tokenId: string) => {
        try {
            const token = await this.queries.getObjectFromApi(accountId, tokenId)

            if (token instanceof Error) {
                throw token
            }
            miscQueries.nativeBrowserDownload(
                "/sms-mgt-api/api/2.0/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/windows/enrollmentTokens/" +
                    tokenId +
                    "/download"
            )
        } catch (e: unknown) {
            console.error(e)
        }
    }
}

const windowsEnrollmentTokens = new WindowsEnrollmentTokens({
    productType: "unifiedSecurityConsole",
    slug: "windowsEnrollmentTokens",
    objectType: "windowsEnrollmentTokens",
    hasStore: true,
    appearance: {
        iconClass: "fal fa-sign-in",
        text: {
            plural: "Enrollmenttokens",
            title: "Enrollmenttokens",
            sidebarName: "xEnrollmenttokens",
            singular: "xEnrollmenttoken"
        },
        color: "blue",
        showInSidebar: true,
        showOnDashboard: true
    },
    objectTypeInfo: {
        primaryKeyProperty: {
            property: "id",
            pathToPrimaryProperty: undefined
        },
        nameProperty: {
            primary: "name",
            pathToPrimaryProperty: undefined,
            secondary: undefined,
            pathToSecondaryProperty: undefined
        }
    },
    apiInfo: {
        url: "/sms-mgt-api/api/2.0",
        getCountGETProperties: "?props[]=null&select=data.count",
        // GET
        getObjectListResponseProperty: "enrollmentTokens",
        getObjectListPath: "/tenants/{tenantDomain}/windows/enrollmentTokens",
        // POST
        addObjectPath: "/tenants/{tenantDomain}/windows/enrollmentTokens",
        // PUT
        updateObjectPath: "/tenants/{tenantDomain}/windows/enrollmentTokens/{objectId}",
        // DELETE
        deleteObjectPath: "/tenants/{tenantDomain}/windows/enrollmentTokens/{objectId}"
    }
})

export default windowsEnrollmentTokens
