<script setup lang="ts">
import { T } from "@/classes/i18n"
import { useAndroidPublishStore } from "@/classes/objectTypes/mobileSecurity/android/androidProfilePublish"
import mixinHelpers from "@/helpers/helpers.mixins"
import { computed } from "vue"

const props = defineProps<{
    properties: undefined
}>()

// Stores

const androidProfilePublish = useAndroidPublishStore()

// Computed

const publishState = computed(() => {
    return androidProfilePublish.publishState
})

const darkmode = computed(() => {
    return mixinHelpers.getDarkmode()
})

const displayName = computed(() => {
    return (publishState.value?.templatePolicyName || "").split("/").pop() ?? ""
})

function getPolicyName(policyName?: string) {
    return typeof policyName == "string" ? policyName.split("/").pop() : policyName
}
</script>
<template>
    <div class="content-3">
        <div class="row">
            <div class="col-xs-24">
                {{
                    T("Die Profile für %s werden generiert und konvertiert.").replace(
                        "%s",
                        displayName
                    )
                }}
            </div>
        </div>
        <div class="row padding-xs-t-2">
            <div class="col-xs-24">
                <div
                    class="progress-bar progress-bar-sm color-blue"
                    :data-progress="'0'"
                    style="height: 10px"
                >
                    <div
                        style="position: absolute; border-bottom: 1em solid; top: 0"
                        :style="
                            'width:' +
                            (publishState.published / publishState.publishTotal) * 100 +
                            '%'
                        "
                    ></div>
                </div>
            </div>
        </div>
        <div class="row flexrow padding-xs-b" style="justify-content: center">
            <span class="text-center">
                {{ publishState.published + " / " + publishState.publishTotal }}
            </span>
        </div>
        <div class="policy-error-container">
            <div
                class="row padding-xs-b padding-xs-l padding-xs-r"
                v-if="publishState?.isWaiting == true"
            >
                <p class="notification" :class="{ 'color-white': darkmode == '1' }">
                    <i class="fas fa-info-circle"></i>
                    &nbsp;&nbsp;
                    {{
                        T(
                            "Aktuell wird auf einem anderen Tenant ein Template Profil veröffentlicht. Die Veröffentlichung dieses Profils kann sich dadurch verzögern."
                        )
                    }}
                </p>
            </div>
            <div
                class="row padding-xs-b padding-xs-l padding-xs-r"
                v-for="error in publishState.errors"
            >
                <p class="notification" :class="{ 'color-white': darkmode == '1' }">
                    <i class="fal fa-exclamation-triangle fa-fw color-red"></i>
                    &nbsp;&nbsp;
                    {{ getPolicyName(error?.data?.policyName) + ": " + error.message }}
                </p>
            </div>
        </div>
    </div>
</template>
<style scoped>
.policy-error-container {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}
</style>
