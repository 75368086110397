import {
    createPopper,
    preventOverflow,
    type Placement,
    type Instance as PopperInstance
} from "@popperjs/core"
import stringHelpers from "../../../helpers/helpers.strings"

type AllOptions = Partial<Tooltip>
export type TooltipOptions = Omit<
    AllOptions,
    | "registerEventListeners"
    | "removeEventListeners"
    | "showTooltip"
    | "hideTooltip"
    | "popperElement"
    | "setElement"
>
export type TooltipOptionsWithoutElement = Omit<
    AllOptions,
    | "registerEventListeners"
    | "removeEventListeners"
    | "showTooltip"
    | "hideTooltip"
    | "popperElement"
    | "element"
>

export const isTooltip = (obj: any) => {
    return obj instanceof Tooltip
}
export default class Tooltip {
    id: string = stringHelpers.generateUUID()
    element?: Element
    contents?: Array<string | number>
    htmlTooltip?: boolean = false
    placement?: Placement = "bottom"
    popperElement?: PopperInstance | undefined = undefined
    constructor(options?: TooltipOptions) {
        this.id = stringHelpers.generateUUID()
        if (options) {
            Object.assign(this, options)
        }
        if (this.element) {
            this.registerEventListeners()
        }
    }

    setElement = (el: Element) => {
        this.element = el
        this.removeEventListeners()
        this.registerEventListeners()
    }

    showTooltip = () => {
        let bodyEl = document.getElementsByTagName("body")[0]
        let newEl = document.createElement("div")
        newEl.classList.add("tooltipContent")
        newEl.id = "tooltip#" + this.id
        if (this.htmlTooltip) {
            newEl.innerHTML =
                "<span>" + this.contents?.join(" ") + ' </span><span class="arrow"></span>'
        } else {
            newEl.innerHTML = '<span></span><span class="arrow"></span>'
            newEl.children[0].textContent = this.contents?.join(" ") || null
        }
        bodyEl.appendChild(newEl)
        let contentEl = document.getElementById("tooltip#" + this.id)
        if (this.element && contentEl) {
            this.popperElement = createPopper(this.element, contentEl, {
                placement: this.placement,
                onFirstUpdate: (e) => {
                    setTimeout(() => {
                        e.elements?.popper.classList.add("show")
                    }, 500)
                },

                modifiers: [
                    preventOverflow,
                    {
                        name: "offset",
                        options: {
                            offset: [0, 12]
                        }
                    }
                ]
            })
        }
    }
    hideTooltip = () => {
        let contentEl = document.getElementById("tooltip#" + this.id)
        if (contentEl) {
            contentEl.remove()
        }
    }

    registerEventListeners = () => {
        this.element?.addEventListener("mouseenter", this.showTooltip)
        this.element?.addEventListener("mouseleave", this.hideTooltip)
    }
    removeEventListeners = () => {
        this.element?.removeEventListener("mouseenter", this.showTooltip)
        this.element?.removeEventListener("mouseleave", this.hideTooltip)
    }
}
