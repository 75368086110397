<script setup lang="ts">
import { computed } from "vue"

const props = defineProps<{
    modelValue: boolean
}>()

const emit = defineEmits(["update:modelValue"])

const value = computed({
    get: () => props.modelValue,
    set: (thisValue) => {
        emit("update:modelValue", thisValue)
    }
})
</script>
<template>
    <label class="checkbox toggle">
        <input class="toggle" type="checkbox" v-bind="$attrs" :value="value" v-model="value" />
        <span></span>
    </label>
</template>
