<template>
    <i
        class="fal fa-fw"
        style="
            line-height: 1;
            display: inline-block;
            vertical-align: middle;
            position: relative;
            top: -2px;
        "
    >
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            :width="props.width"
            viewBox="260 200 500 550"
            preserveAspectRatio="xMidYMid meet"
            :height="props.height"
        >
            <g
                transform="translate(5.000000,1024.000000) scale(0.100000,-0.100000)"
                :fill="props.color"
                stroke="none"
            >
                <path
                    d="M5024 7651 c-70 -12 -106 -24 -284 -99 -85 -35 -184 -75 -220 -87
               -36 -12 -99 -38 -140 -58 -41 -19 -120 -51 -175 -72 -55 -20 -115 -45 -135
               -55 -19 -10 -51 -25 -70 -34 -19 -8 -89 -40 -155 -70 -66 -30 -271 -118 -455
               -195 -218 -92 -359 -158 -403 -188 -124 -83 -215 -212 -252 -353 -35 -131 -7
               -602 56 -935 202 -1085 724 -1963 1503 -2529 217 -158 542 -339 666 -372 81
               -21 215 -23 298 -5 73 16 272 111 422 201 1001 601 1633 1639 1811 2975 25
               192 37 566 20 648 -17 80 -80 201 -140 268 -97 108 -127 123 -1086 530 -495
               210 -932 392 -971 405 -79 25 -213 37 -290 25z m1269 -793 c811 -344 822 -350
               867 -438 35 -69 40 -115 30 -305 -54 -1007 -439 -1928 -1070 -2555 -211 -210
               -488 -416 -732 -545 l-108 -57 0 2165 0 2165 128 -54 c70 -30 468 -199 885
               -376z"
                ></path>
            </g>
        </svg>
    </i>
</template>

<script setup lang="ts">
const props = withDefaults(
    defineProps<{
        color?: string
        width?: string
        height?: string
    }>(),
    {
        color: "white",
        width: "13px",
        height: "15px"
    }
)
</script>
