<template>
    <CenterDialog class="info-display" app-name="Cloud Shield">
        <div class="info-display">
            <div class="info-group">
                <div class="info-header">
                    <h4>
                        <i class="fas fa-globe"></i>
                        {{ T("Your current IP Address") }}:
                    </h4>
                </div>

                <div class="info-list">
                    <div class="info-item">
                        <div class="info-item__content">
                            <loader v-if="loading" />
                            <template v-else>
                                <div class="value">
                                    {{ data.client.ip }}
                                </div>

                                <div class="ip-info">
                                    <div class="ip-version">IPv{{ data.client.ipVersion }}</div>
                                </div>
                            </template>
                        </div>
                        <i class="fa-solid fa-chart-network info-item__icon"></i>
                    </div>
                </div>
            </div>

            <div class="info-group">
                <div class="info-header">
                    <h4>
                        <i class="fas fa-shield"></i>
                        {{ T("DNS Configuration") }}:
                    </h4>
                </div>

                <div class="info-list">
                    <div class="info-item">
                        <div class="info-item__content">
                            <label>{{ T("DNS Status") }}:</label>

                            <loader v-if="loading" />
                            <template v-else>
                                <div v-if="data.status === 'unconfigured'" class="status-value">
                                    {{ T("Not Using Cloud Shield") }}
                                </div>
                                <div v-else-if="isInvalidProfile" class="status-value text-bold">
                                    {{
                                        T(
                                            "Your device is using an invalid configuration ID and is therefore unprotected!"
                                        )
                                    }}
                                </div>
                                <div v-else class="color-green">
                                    {{ T("Using Cloud Shield") }}
                                </div>
                            </template>
                        </div>
                        <i class="fa-solid fa-shield-halved info-item__icon"></i>
                    </div>

                    <div class="info-item">
                        <div class="info-item__content">
                            <label>{{ T("Profil") }}:</label>

                            <loader v-if="loading" />
                            <template v-else-if="data.config">
                                <div class="value">
                                    {{ data.config.name || T("Unknown") }}
                                </div>
                                <div v-if="data.deviceName" class="sub-value">
                                    {{ data.deviceName }}
                                </div>
                            </template>
                            <div v-else class="value">{{ T("Unknown") }}</div>
                        </div>
                        <i class="fa-solid fa-gear info-item__icon"></i>
                    </div>

                    <div class="info-item">
                        <div class="info-item__content">
                            <label>{{ T("Protocol") }}:</label>

                            <loader v-if="loading" />
                            <div v-else-if="data.protocol" class="value">
                                {{
                                    T(protocolMap[data.protocol as keyof typeof protocolMap]) ||
                                    T("Unknown")
                                }}
                            </div>
                            <div v-else class="value">
                                {{ T("Unknown") }}
                            </div>
                        </div>
                        <i class="fa-solid fa-network-wired info-item__icon"></i>
                    </div>
                </div>
            </div>
        </div>
    </CenterDialog>
</template>

<script setup lang="ts">
import { T } from "@/classes/i18n"
import stringHelpers from "@/helpers/helpers.strings"
import Loader from "@/templates/components/loader.vue"
import CenterDialog from "@/templates/templates/center-dialog.vue"
import { computed, onMounted, onUnmounted, ref } from "vue"

const domain = computed(() => {
    const origin = document.location.origin

    if (origin.includes("portal-dev") || origin.includes("localhost")) {
        return "sdns-test-dev.securepoint.de"
    } else {
        return "sdns-test.securepoint.de"
    }
})

const loading = ref(true)
const urlTemplate = computed(() => `https://{ID}.${domain.value}`)
const intervalTimer = ref<number | null>(null)
const interval = ref(5000)

const data = ref<any>(null)

const isInvalidProfile = computed(() => {
    {
        return data.value?.config?.name === null
    }
})

const protocolMap = {
    DOH: "DNS over HTTPS (DoH)",
    DOT: "DNS over TLS (DoT)"
}

async function loadSecureDnsStatus() {
    try {
        const url = urlTemplate.value.replace("{ID}", stringHelpers.generateRandomString(12))
        const result = await fetch(url)
        data.value = await result.json()
    } catch (e) {
        console.error("failed to fetch Cloud Shield status", e)
    }
}

onMounted(async () => {
    await loadSecureDnsStatus()
    loading.value = false

    intervalTimer.value = setInterval(() => {
        loadSecureDnsStatus()
    }, interval.value)
})

onUnmounted(() => {
    if (intervalTimer.value) {
        clearInterval(intervalTimer.value)
    }
})
</script>

<style lang="scss">
.info-display {
    padding: 1rem;
    background: #f3f3f3;

    .darkmode & {
        background: #202125;
    }
}

.info-group {
    margin-bottom: 2rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.info-header {
    display: flex;
    margin-bottom: 1rem;
    font-size: 0.8rem;
}

.info-card,
.info-item {
    background: white;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-bottom: 0.5rem;

    .darkmode & {
        background: #303338;
    }
}
.ip-info {
    .ip-main {
        font-size: 1.125rem;
        font-weight: 500;
        margin-bottom: 0.25rem;
    }

    .ip-version {
        display: inline-block;
        background: rgba(#3b82f6, 0.15);
        color: #60a5fa;
        padding: 0.25rem 0.75rem;
        border-radius: 1rem;
        font-size: 0.75rem;
        margin-top: 0.5rem;
    }
}

.info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__content {
        flex: 1;
    }

    &__icon {
        font-size: 1.25rem;
        margin-left: 1rem;
    }

    label {
        display: block;
        font-size: 0.875rem;
        margin-bottom: 0.25rem;
        font-weight: bold;
    }

    .value {
        font-size: 0.9375rem;
    }

    .status-value {
        color: #e74c3c;
    }

    .sub-value {
        font-size: 0.8125rem;
        margin-top: 0.25rem;
    }
}

.info-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
</style>
