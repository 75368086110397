import apis from "@/classes/apis"
import config from "@/classes/config"
import { useFeatureStore } from "@/classes/featureStore"
import { T } from "@/classes/i18n"
import dialogs from "@/dialogs/dialogs"
import miscDialogs from "@/dialogs/dialogs.misc"
import arrayHelpers from "@/helpers/helpers.arrays"
import deviceHelpers from "@/helpers/helpers.devices"
import getterHelpers from "@/helpers/helpers.getters"
import jsonHelpers from "@/helpers/helpers.json"
import licenseHelpers from "@/helpers/helpers.license"
import numberHelpers from "@/helpers/helpers.numbers"
import type { PromiseObject } from "@/helpers/helpers.promises"
import promiseHelpers from "@/helpers/helpers.promises"
import stringHelpers from "@/helpers/helpers.strings"
import tenantHelpers from "@/helpers/helpers.tenants"
import timeHelpers from "@/helpers/helpers.time"
import mobileSecurityQueries from "@/queries/mobile.security"
import queries from "@/queries/queries"
import requestHandler from "@/queries/requests"
import router from "@/router/router"
import { ActionTypes, MutationTypes } from "@/store/vuex.store"
import Button from "@/templates/components/button/button"
import moment from "moment"
import products from "../.."
import ObjectType, {
    type AccountId,
    type AddObjectTypeObject,
    type ItemlistItemDetail,
    type ObjectTypePayload
} from "../../objectType"
import type { License } from "../../unifiedSecurity/licenses/licenses"
import type { ProfileSunPoolInfo } from "../android/androidProfiles"
import type { DepDevice } from "./depDevices"
import deviceNames from "./deviceNames"

export interface AppleDevice {
    tenantDomain: string
    deviceId: string
    dep: boolean
    deviceFamily: string
    licenseUUID: string
    ownership: "UNKNOWN" | "COPE" | "BYOD"
    configured: boolean
    deviceType: string
    battery: {
        event: string
        batteryPercentage: number
    }
    storage: {
        internal: string
    }
    declarativeManagement: {
        managed: boolean
        passCode: {
            present: any
            compliance: any
        }
    }
    info: {
        deviceType: string
        manufacturer: string
        os: string
        AwaitingConfiguration: boolean
        BluetoothMAC: string
        BuildVersion: string
        DeviceID: string
        DeviceName: string
        DevicePropertiesAttestation: string[]
        IsSupervised: boolean
        MDMOptions: any
        Model: string
        ModelName: string
        OSVersion: string
        ProductName: string
        SerialNumber: string
        SupplementalBuildVersion: string
        TimeZone: string
        UDID: string
        WiFiMAC: string
        iTunesStoreAccountHash: string
        iTunesStoreAccountIsActive: boolean
        AppAnalyticsEnabled: boolean
        AvailableDeviceCapacity: number
        BatteryLevel: number
        CellularTechnology: number
        DataRoamingEnabled: boolean
        DeviceCapacity: number
        DiagnosticSubmissionEnabled: boolean
        EASDeviceIdentifier: string
        IMEI: string
        IsActivationLockEnabled: boolean
        IsCloudBackupEnabled: boolean
        IsDeviceLocatorServiceEnabled: boolean
        IsDoNotDisturbInEffect: boolean
        IsMDMLostModeEnabled: boolean
        IsMultiUser: boolean
        IsNetworkTethered: boolean
        IsRoaming: boolean
        LastCloudBackupDate: string
        MEID: string
        ModemFirmwareVersion: string
        PersonalHotspotEnabled: boolean
        PhoneNumber?: any
        ServiceSubscriptions: [
            {
                IMEI: string
                Slot: string
            }
        ]
        SoftwareUpdateDeviceID: string
        SubscriberMCC: string
        SubscriberMNC: string
        VoiceRoamingEnabled: boolean
        AccessibilitySettings: {
            BoldTextEnabled: boolean
            IncreaseContrastEnabled: boolean
            ReduceMotionEnabled: boolean
            ReduceTransparencyEnabled: boolean
            TextSize: number
            TouchAccommodationsEnabled: boolean
            VoiceOverEnabled: boolean
            ZoomEnabled: boolean
        }
    }
    availableUpdates: {
        Build: string
        ExpirationDate: string
        PostingDate: string
        ProductVersion: string
    }[]
    iOSPerUserChannelProfiles: any[]
    profile: {
        profileId: string
        profileName: string
        status: string
        compliant: boolean
        parts: string[]
        installed: string[]
    }
    lastContact: 0
    lostMode: boolean
    signedIn: boolean
    enrolledAt: number
    enrollmentTime: string
    unenrollmentTime: string
    tags: string[]
    username: string
    alias: string
    consent: string
    inventory: {
        inventoryExternalLink: string
        warrantyStartDate: string
        warrantyEndDate: string
        billNumber: string
        billDate: string
        contractNumber: string
        contractDuration: string
        contractDate: string
        providerCustomerNumber: string
        providerName: string
        providerTariff: string
        hardwareDescription: string
        handoverDate: string
        inventoryLocation: {
            street: string
            postalCode: string
            city: string
            latitude: number
            longitude: number
        }
    }
    managedAppleId: string
    serialNumber: string
    osVersion: string
    unenrolledAt: number
    email: string

    depDevice: boolean
    depDeviceInfo?: DepDevice
    isInExhaustedSunPools?: string[]
}
export type IosDevice = AppleDevice | DepDevice

class IosDevices extends ObjectType<IosDevice> {
    constructor(payload: ObjectTypePayload<IosDevice>) {
        super(payload)
        const thisObjectType = this

        this.itemlist.getInfoBoxContent = (accountId, component) => {
            let result = ""
            component = component?.exposed ? component?.exposed : component

            const licenses = products.unifiedSecurity.licenses
                .useStore?.()
                .getObjectStoreObjects(accountId)
            const devices = products.mobileSecurity.iosDevices
                .useStore?.()
                .getObjectStoreObjects(accountId)

            let freeLicenseSlots = 0
            ;(licenses || []).forEach((license) => {
                if (
                    (license.type == "Mobile Security" || license.type == "MDM") &&
                    license.state == "valid"
                ) {
                    freeLicenseSlots +=
                        (license.enrollmentLimit && license.enrollmentLimit < license.user
                            ? license.enrollmentLimit
                            : license.user) - license.assignedUser
                }
            })

            const signedOutDevicesCount =
                devices?.filter((device) => {
                    return Object.hasOwn(<AppleDevice>device, "signedIn")
                        ? (<AppleDevice>device).signedIn === false
                        : false
                }).length || 0

            if (signedOutDevicesCount > freeLicenseSlots) {
                result +=
                    (result.length ? "<br>" : "") +
                    '<i class="fal fa-fw fa-exclamation-triangle color-red"></i>&nbsp;&nbsp;' +
                    T(
                        "Ihre Lizenzen reichen nicht aus, um alle ausgeloggten Geräte zu lizenzieren."
                    ) +
                    "<br>" +
                    T("Bitte erweitern Sie Ihre Lizenzen bei Bedarf.")
            }

            if (component.hasDevicesWithoutLicense(accountId)) {
                result +=
                    (result.length ? "<br>" : "") +
                    '<i class="fal fa-fw fa-exclamation-triangle color-red"></i>&nbsp;&nbsp;' +
                    T(
                        "One or more devices are unlicensed, please assign a license to each of your devices."
                    )
            }
            return result
        }
        this.itemlist.getSortingOptions = (accountId, component) => {
            return [
                { id: "alias", text: T("Name") },
                { id: "deviceId", text: T("ID") },
                { id: "serialNumber", text: T("Serial") },
                { id: "profile.profileName", text: T("Profile") },
                { id: "lastContact", text: T("Contact") }
            ]
        }

        this.itemlist.getToolbarButtons = (accountId, component) => {
            let thisToolbarEntries = []
            component = component?.exposed ? component?.exposed : component
            if (this.isEnrollmentAllowed(accountId) && component.accountRestrictions.value == 0) {
                thisToolbarEntries.push(
                    {
                        icon: "fal fa-envelope",
                        title: T("Send invite"),
                        onClick: () => {
                            dialogs.mobileSecurity.inviteDeviceDialog(accountId, "iosDevices")
                        },
                        id: "devicesButtonInvite",
                        vIf: false
                    },
                    {
                        icon: "fal fa-plus",
                        title: T("Enroll new device"),
                        onClick: () => {
                            dialogs.mobileSecurity.enrollDeviceDialog(accountId, "iosDevices")
                        },
                        id: "devicesButtonEnroll",
                        vIf: false
                    }
                )
            } else if (component.accountRestrictions.value > 0) {
                thisToolbarEntries.push({
                    icon: "fal fa-exclamation-triangle",
                    title: T("Enrollment disabled"),
                    link: "",
                    id: "devicesButtonEnroll",
                    disabled: true,
                    vIf: false
                })
            } else if (!this.isEnrollmentAllowed(accountId)) {
                thisToolbarEntries.push({
                    icon: "fal fa-exclamation-triangle",
                    title: T("You have reached the devices limit"),
                    link: "",
                    id: "devicesButtonEnroll",
                    disabled: true,
                    vIf: false
                })
            }

            if (
                useFeatureStore().isEnabled("CSV_EXPORT") &&
                this.useStore?.().getObjectStoreObjects(accountId)?.length &&
                accountId
            ) {
                thisToolbarEntries.push({
                    icon: "fal fa-download",
                    title: T("Download CSV"),
                    hoverText: T("Download device details as a list"),
                    onClick: async () => {
                        try {
                            const tenantDomain = tenantHelpers.getTenantDomain(accountId)
                            await queries.misc.download(
                                `/sms-mgt-api/api/2.0/tenants/${tenantDomain}/devices/apple/csv`,
                                `devices_apple_${tenantDomain}_${new Date()
                                    .toISOString()
                                    .slice(0, 19)
                                    .replace(/[:T]/g, "-")}.csv`
                            )
                        } catch (e) {
                            console.error(e)
                            dialogs.misc.errorDialog(
                                accountId,
                                T("An error has occurred"),
                                T("An error occurred while exporting apple devices as CSV")
                            )
                        }
                    },
                    id: "exportAppleDeviceButton",
                    vIf: false
                })
            }
            thisToolbarEntries.push(
                {
                    icon: "fal fa-link",
                    title: T("Assign licenses"),
                    link: "#show-tenant-" + accountId + ".sms-licenses-dashboard",
                    id: "assignLicenses",
                    vIf: "hasDevicesWithoutLicense"
                },
                {
                    icon: "fal fa-sync",
                    title: T("OS update"),
                    onClick: async () => {
                        dialogs.mobileSecurity.updateAppleDevice(
                            accountId,
                            async (updateType: any) => {
                                let promiseObject: PromiseObject = {}
                                ;(this.useStore?.().getObjectStoreObjects(accountId) || []).forEach(
                                    (item: any) => {
                                        if (
                                            item?.deviceId &&
                                            item.deviceType == "IOS" &&
                                            item.availableUpdates?.length > 0 &&
                                            (item.info?.supervised == true ||
                                                item?.info.IsSupervised == true)
                                        ) {
                                            promiseObject[item.deviceId] = this.sendDeviceMessage(
                                                accountId,
                                                item.deviceId,
                                                {
                                                    type: "SCHEDULE_OS_UPDATE",
                                                    payload: {
                                                        Updates: [
                                                            {
                                                                InstallAction: updateType,
                                                                ProductVersion:
                                                                    item.availableUpdates[0]
                                                                        .ProductVersion
                                                            }
                                                        ],
                                                        RequestRequiresNetworkTether: false
                                                    }
                                                }
                                            )
                                        }
                                    }
                                )
                                await promiseHelpers.resolvePromiseObject(promiseObject)
                            }
                        )
                    },
                    id: "updateDevices",
                    vIf: "hasUpdatableDevices"
                }
            )

            return thisToolbarEntries
        }

        this.itemlistItem.getDetails = (accountId, item, component) => {
            component = component?.exposed ? component?.exposed : component
            let thisTagsInlineEditable: boolean = licenseHelpers.hasOneOfLicenses(
                accountId,
                ["Mobile Security", "MDM"],
                "valid"
            )
            let thisUsernameInlineEditable: boolean = licenseHelpers.hasOneOfLicenses(
                accountId,
                ["Mobile Security", "MDM"],
                "valid"
            )
                ? (<AppleDevice>item)?.ownership == "COPE"
                : false
            let thisProfilesArray = []
            let thisDEPProfilesArray = []
            let inlineDeviceId: string = "'" + (<AppleDevice>item)?.deviceId + "'"
            let inlineTenantDomain: string = "'" + accountId + ".sms'"
            let licenseInfo = licenseHelpers.getLicenseByUUID(
                accountId,
                (<AppleDevice>item)?.licenseUUID || ""
            )
            const canUseAppleDEP = tenantHelpers.hasFunctionality(accountId, "dep")
            const depProfiles =
                (config.canUseNewObjectType("depProfiles")
                    ? products.mobileSecurity.depProfiles
                          .useStore?.()
                          .getObjectStoreObjects(accountId)
                    : getterHelpers.useStore().getters.getObjects({
                          accountId: accountId,
                          objectType: "depProfiles",
                          productType: "mobileSecurity"
                      })) || []

            let thisDetails: ItemlistItemDetail[] = []
            if (canUseAppleDEP && item?.depDevice === true) {
                let thisDeviceDepProfile = depProfiles.find((depProfile: any) => {
                    if (depProfile?.profile_uuid) {
                        return (
                            depProfile?.profile_uuid ==
                            ((<AppleDevice>item)?.depDeviceInfo?.profile_uuid
                                ? (<AppleDevice>item).depDeviceInfo?.profile_uuid
                                : (<DepDevice>item)?.profile_uuid)
                        )
                    }
                    return false
                })
                const profileStatus =
                    (<AppleDevice>item)?.depDeviceInfo?.profile_status ||
                    (<DepDevice>item)?.profile_status
                const profileAssignmentTime =
                    (<AppleDevice>item)?.depDeviceInfo?.profile_assign_time ||
                    (<DepDevice>item)?.profile_assign_time
                const profileText = thisDeviceDepProfile
                    ? deviceHelpers.getAliasedShortDeviceId(
                          thisDeviceDepProfile?.profile_uuid,
                          thisDeviceDepProfile?.profile_name.length > 20
                              ? thisDeviceDepProfile?.profile_name.substr(0, 20) + "..."
                              : thisDeviceDepProfile?.profile_name,
                          false
                      )
                    : undefined
                if (profileText) {
                    thisDEPProfilesArray.push({
                        id: profileText + "_title",
                        text: profileText,
                        title: profileText,
                        icon:
                            profileStatus == "assigned" ? "fa fa-exclamation-triangle" : undefined,
                        onClick: function () {
                            router.navigate(
                                "edit-tenant-" +
                                    accountId +
                                    ".sms-dep-profile-" +
                                    thisDeviceDepProfile?.profile_uuid
                            )
                        },
                        displayType: "label"
                    })
                } else {
                    thisDEPProfilesArray.push({
                        id: T("Unkown") + "_title",
                        text: T("Unkown"),
                        title: T("Unkown"),
                        icon: "fa fa-question-circle",
                        displayType: "label"
                    })
                }
                let depProfileStatusInfo = this.depProfileStatusInfo
                let thisStatus: string = T(
                    depProfileStatusInfo[profileStatus as keyof typeof depProfileStatusInfo]
                )
                if (["empty", "assigned"].indexOf(profileStatus) == -1 && profileAssignmentTime) {
                    thisStatus +=
                        T(" at ") +
                        timeHelpers.formatDate(
                            profileAssignmentTime,
                            timeHelpers.getDateFormatI18n(true)
                        )
                }
                thisDEPProfilesArray.push({
                    id:
                        (thisDeviceDepProfile
                            ? deviceHelpers.getAliasedShortDeviceId(
                                  thisDeviceDepProfile?.profile_uuid,
                                  thisDeviceDepProfile?.profile_name.length > 20
                                      ? thisDeviceDepProfile?.profile_name.substr(0, 20) + "..."
                                      : thisDeviceDepProfile?.profile_name,
                                  false
                              )
                            : T("Unknown")) + "_state",
                    text: thisStatus,
                    title: thisStatus,
                    lineBreakBefore: true
                })
            }
            if ((<AppleDevice>item)?.configured) {
                if (
                    (<AppleDevice>item)?.profile &&
                    (<AppleDevice>item)?.profile?.profileId != "" &&
                    (<AppleDevice>item)?.profile?.status != "REVOKED" &&
                    (<AppleDevice>item).profile.profileName
                ) {
                    thisProfilesArray.push({
                        id: (<AppleDevice>item).profile.profileName + "_title",
                        text: (<AppleDevice>item).profile.profileName,
                        title: (<AppleDevice>item).profile.profileName,
                        onClick: function () {
                            router.navigate(
                                "edit-tenant-" +
                                    accountId +
                                    ".sms-ios-profile-" +
                                    (<AppleDevice>item).profile.profileId
                            )
                        },
                        displayType: "label"
                    })
                    if (
                        (<AppleDevice>item)?.profile.status != "INSTALLED" &&
                        (<AppleDevice>item)?.profile.status != ""
                    ) {
                        thisProfilesArray.push({
                            id: (<AppleDevice>item).profile.profileName + "_state",
                            text: T((<AppleDevice>item)?.profile.status),
                            title: T((<AppleDevice>item)?.profile.status),
                            class: "bg-yellow",
                            onClick: function () {
                                router.navigate(
                                    "edit-tenant-" +
                                        accountId +
                                        ".sms-ios-profile-" +
                                        (<AppleDevice>item).profile.profileId
                                )
                            },
                            displayType: "label"
                        })
                    }
                }
            }

            // Get Model info
            let productName: string = ""
            if ((<AppleDevice>item)?.deviceFamily) {
                productName = (<AppleDevice>item)?.deviceFamily
            } else if (
                deviceNames[(<AppleDevice>item)?.info?.ProductName as keyof typeof deviceNames]
            ) {
                productName =
                    deviceNames[(<AppleDevice>item)?.info?.ProductName as keyof typeof deviceNames]
            } else if (deviceNames[(<AppleDevice>item)?.info?.Model as keyof typeof deviceNames]) {
                productName =
                    deviceNames[(<AppleDevice>item)?.info?.Model as keyof typeof deviceNames]
            } else {
                productName = (<AppleDevice>item)?.info?.ProductName || ""
            }
            let model = [
                (<AppleDevice>item)?.info?.manufacturer || "",
                productName,
                ((<AppleDevice>item)?.info?.os || "") +
                    " " +
                    ((<AppleDevice>item)?.info?.OSVersion || "")
            ]
                .filter((entry: string) => {
                    return entry.length > 1
                })
                .join(" - ")

            // get operating mode
            let operatingMode = T("Unconfigured")
            if (
                (<AppleDevice>item)?.configured &&
                (<AppleDevice>item)?.info?.ModelName === "AppleTV"
            ) {
                operatingMode = "Device Owner (COBO)"
            } else if (
                (<AppleDevice>item)?.configured &&
                (<AppleDevice>item)?.info?.IsMultiUser === true
            ) {
                operatingMode = "Shared iPad"
            } else if (
                (<AppleDevice>item)?.configured &&
                typeof (<AppleDevice>item)?.managedAppleId === "string" &&
                (<AppleDevice>item).managedAppleId !== ""
            ) {
                operatingMode = "Profile Owner (Userenrollment)"
            } else if (
                (<AppleDevice>item)?.configured &&
                (<AppleDevice>item)?.info?.IsSupervised === true
            ) {
                operatingMode = "Device Owner (COBO)"
            } else if (
                (<AppleDevice>item)?.configured &&
                (<AppleDevice>item)?.info?.IsSupervised === false &&
                (<AppleDevice>item)?.dep === false
            ) {
                operatingMode = "Profile Owner (BYOD)"
            }

            if (!jsonHelpers.getObjectProperty(item, "depOnly")) {
                thisDetails = [
                    {
                        iconClass: "fal fa-fw fa-microchip",
                        title: T("Model"),
                        key: T("Model"),
                        value: item
                            ? (<AppleDevice>item).deviceType != "UNKNOWN"
                                ? model
                                : ""
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    },
                    {
                        iconClass: "fal fa-fw fa-hashtag",
                        title: T("Serial Number"),
                        key: T("Serial Number"),
                        value: item
                            ? this.getSerialNumber(item) || ""
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    },
                    {
                        iconClass: "fal fa-fw fa-hashtag",
                        title: T("IMEI"),
                        key: T("IMEI"),
                        value: item
                            ? stringHelpers.removeSpaces(this.getIMEI(item) || "")
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    },
                    {
                        iconClass: "fal fa-fw fa-phone",
                        title: T("Phone"),
                        key: T("Phone"),
                        value: item
                            ? (<AppleDevice>item)?.info?.PhoneNumber &&
                              (<AppleDevice>item)?.configured
                                ? (<AppleDevice>item)?.info.PhoneNumber
                                : ""
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    },
                    {
                        iconClass: "fal fa-fw fa-address-card",
                        title: T("Ownership"),
                        key: T("Ownership"),
                        value: item
                            ? (<AppleDevice>item)?.configured
                                ? (<AppleDevice>item)?.ownership
                                : T("Unconfigured")
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    },
                    {
                        iconClass: "fal fa-fw fa-address-card",
                        title: T("Operating mode"),
                        key: T("Operating mode"),
                        value: item
                            ? operatingMode
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    },
                    {
                        iconClass: "fal fa-fw fa-shopping-cart",
                        title: T("Store-Account"),
                        key: T("Store-Account"),
                        value: item
                            ? (<AppleDevice>item)?.info?.iTunesStoreAccountIsActive
                                ? (<AppleDevice>item)?.configured
                                    ? T("Active")
                                    : ""
                                : T("Inactive")
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    },
                    {
                        iconClass: "fal fa-fw fa-user",
                        title: T("User"),
                        key: T("User"),
                        value: item
                            ? (<AppleDevice>item)?.configured
                                ? (<AppleDevice>item)?.username || ""
                                : ""
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>",
                        editableContent: thisUsernameInlineEditable
                            ? {
                                  type: "select",
                                  options: "users",
                                  ref: "editUser",
                                  value: (<AppleDevice>item)?.username,
                                  editingBoolProperty: "editUsername",
                                  editButton: new Button({
                                      onClick: function () {
                                          component.editUsername.value = true
                                      },
                                      icon: "fal fa-edit"
                                  }),
                                  submitFunction: async (value: string) => {
                                      if (value !== "noUserSelected") {
                                          try {
                                              await this.queries.updateObjectPropertiesFromApi(
                                                  accountId,
                                                  (<AppleDevice>item)?.deviceId,
                                                  { username: value },
                                                  "username",
                                                  undefined,
                                                  undefined,
                                                  "/properties/username"
                                              )
                                              component.editUsername.value = false
                                          } catch (e: any) {
                                              component.editUsername.value = false
                                              component.error.value = true
                                              console.error(e)
                                              if (e?.errors?.errors?.[0]?.message != undefined) {
                                                  component.errorMsg.value =
                                                      e?.errors?.errors?.[0]?.message +
                                                      ". " +
                                                      T("Tags may not contain spaces or umlauts.")
                                              }
                                              setTimeout(function () {
                                                  component.error.value = true
                                                  component.errorMsg.value = ""
                                              }, 4000)
                                          }
                                      }
                                      // Delete Username from Device
                                      else {
                                          try {
                                              await requestHandler.request(
                                                  "DELETE",
                                                  config.mgtApiUri +
                                                      "/tenants/" +
                                                      accountId +
                                                      ".sms/devices/" +
                                                      (<AppleDevice>item)?.deviceId +
                                                      "/properties/username"
                                              )
                                              this.useStore?.().setObjectTypeObjectProperty(
                                                  accountId,
                                                  (<AppleDevice>item)?.deviceId,
                                                  "",
                                                  "username"
                                              )
                                              component.editUsername.value = false
                                          } catch (e: any) {
                                              component.editUsername.value = false
                                              console.error(e)
                                          }
                                      }
                                  },
                                  abortFunction: function () {
                                      component.editUsername.value = false
                                  }
                              }
                            : undefined
                    },
                    {
                        iconClass: "fal fa-fw fa-shield-alt",
                        title: T("Device profile"),
                        key: T("Device profile"),
                        [item && thisProfilesArray.length > 0 ? "labels" : "value"]:
                            item && thisProfilesArray.length > 0 ? thisProfilesArray : ""
                    }
                ]

                if ((<AppleDevice>item)?.managedAppleId) {
                    thisDetails.push({
                        iconClass: "fab fa-fw fa-apple",
                        title: "Managed Apple ID",
                        key: "Managed Apple ID",
                        value: item
                            ? (<AppleDevice>item)?.managedAppleId
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    })
                }
                if (
                    canUseAppleDEP &&
                    item?.depDevice === true &&
                    (<AppleDevice>item)?.depDeviceInfo != undefined
                ) {
                    thisDetails.push({
                        iconClass: "fab fa-fw fa-apple",
                        title: T("DEP Profile"),
                        key: T("DEP Profile"),
                        [item && thisDEPProfilesArray.length ? "labels" : "value"]:
                            item && thisDEPProfilesArray.length > 0 ? thisDEPProfilesArray : ""
                    })
                }
            } else {
                thisDetails = [
                    {
                        iconClass: "fal fa-fw fa-microchip",
                        title: T("Model"),
                        key: T("Model"),
                        value: item
                            ? (<AppleDevice>item)?.info?.Model || ""
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    },
                    {
                        iconClass: "fal fa-fw fa-hashtag",
                        title: T("Serial Number"),
                        key: T("Serial Number"),
                        value: item
                            ? this.getSerialNumber(item) || ""
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    }
                ]
                if (
                    canUseAppleDEP &&
                    item?.depDevice === true &&
                    (<AppleDevice>item)?.depDeviceInfo != undefined
                ) {
                    thisDetails.push({
                        iconClass: "fab fa-fw fa-apple",
                        title: T("DEP Profile"),
                        key: T("DEP Profile"),
                        [item && thisDEPProfilesArray.length ? "labels" : "value"]:
                            item && thisDEPProfilesArray.length > 0 ? thisDEPProfilesArray : ""
                    })
                } else if (
                    canUseAppleDEP &&
                    item?.depDevice === true &&
                    (<DepDevice>item)?.depOnly === true
                ) {
                    thisDetails.push({
                        iconClass: "fab fa-fw fa-apple",
                        title: T("DEP Profile"),
                        key: T("DEP Profile"),
                        [item && thisDEPProfilesArray.length ? "labels" : "value"]:
                            item && thisDEPProfilesArray.length > 0 ? thisDEPProfilesArray : ""
                    })
                }
            }
            if ((<AppleDevice>item)?.iOSPerUserChannelProfiles?.length > 0) {
                let thisUserProfilesArray: any[] = []
                ;(<AppleDevice>item)?.iOSPerUserChannelProfiles.forEach((profile: any) => {
                    if (profile.status.toLowerCase() == "installed") {
                        thisUserProfilesArray.push({
                            id: profile.profileName + "_title",
                            text: profile.profileName,
                            title: profile.profileName,
                            onClick: function () {},
                            displayType: "label"
                        })
                    }
                })
                thisDetails.push({
                    iconClass: "fab fa-fw fa-apple",
                    title: T("User profiles"),
                    key: T("User profiles"),
                    [item && thisUserProfilesArray.length ? "labels" : "value"]:
                        item && thisUserProfilesArray.length ? thisUserProfilesArray : ""
                })
            }

            if ((<DepDevice>item)?.depOnly != true) {
                thisDetails.push({
                    iconClass: "fal fa-fw fa-tags",
                    title: T("Tags"),
                    key: T("Tags"),
                    [item ? "labels" : "value"]: item
                        ? (<AppleDevice>item)?.tags?.map(function (tag: string) {
                              return {
                                  id: tag,
                                  text: tag,
                                  title: tag,
                                  onClick: undefined,
                                  displayType: "label"
                              }
                          })
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>",
                    editableContent: thisTagsInlineEditable
                        ? {
                              type: "select2",
                              options: "tags",
                              select2Settings: {
                                  tags: true,
                                  multiple: true,
                                  placeholder: "Select tags"
                              },
                              ref: "editTags",
                              value: (<AppleDevice>item)?.tags || [],
                              editingBoolProperty: "editTags",
                              editButton: new Button({
                                  onClick: function () {
                                      component.editTags.value = true
                                  },
                                  icon: "fal fa-edit"
                              }),
                              submitFunction: async (value: string[]) => {
                                  try {
                                      await this.queries.updateObjectPropertiesFromApi(
                                          accountId,
                                          (<AppleDevice>item).deviceId,
                                          value,
                                          "tags",
                                          undefined,
                                          undefined,
                                          "/tags",
                                          "POST"
                                      )
                                      getterHelpers
                                          .useStore()
                                          .commit(MutationTypes.setObjectInfos, {
                                              accountId: accountId,
                                              products: {
                                                  mobileSecurity: {
                                                      tags: value
                                                          .filter(arrayHelpers.onlyUniqueFilter)
                                                          .map(function (tag: string) {
                                                              return { id: tag, text: tag }
                                                          })
                                                  }
                                              }
                                          })
                                      component.editTags.value = false
                                  } catch (e: any) {
                                      component.editTags.value = false
                                      component.error.value = true
                                      console.error(e)

                                      if (e.responseJSON != undefined) {
                                          e = e.responseJSON
                                      }

                                      if (e?.errors?.errors?.[0]?.message != undefined) {
                                          component.errorMsg.value =
                                              e?.errors?.errors?.[0]?.message +
                                              ". " +
                                              T("Tags may not contain spaces or umlauts.")
                                      }
                                      setTimeout(function () {
                                          component.error.value = false
                                          component.errorMsg.value = ""
                                      }, 4000)
                                  }
                              },
                              abortFunction: function () {
                                  component.editTags.value = false
                              }
                          }
                        : undefined
                })

                if (licenseInfo?.type != undefined) {
                    thisDetails.push({
                        iconClass: "fal fa-fw fa-key",
                        title: T("License"),
                        key: T("License"),
                        [item ? "labels" : "value"]: item
                            ? [
                                  {
                                      id: (<AppleDevice>item).licenseUUID + "_" + licenseInfo.name,
                                      text:
                                          licenseInfo.type +
                                          " (" +
                                          deviceHelpers.getShortDeviceId(licenseInfo?.id) +
                                          ")",
                                      title:
                                          licenseInfo.type +
                                          " (" +
                                          deviceHelpers.getShortDeviceId(licenseInfo?.id) +
                                          ")",
                                      onClick: function () {
                                          if ((<License>licenseInfo)?.state == "valid") {
                                              router.navigate(
                                                  "show-tenant-" +
                                                      accountId +
                                                      ".sms-license-" +
                                                      licenseInfo.id +
                                                      "-details"
                                              )
                                          }
                                      },
                                      displayType: "label"
                                  }
                              ]
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    })
                } else {
                    thisDetails.push({
                        iconClass: "fal fa-fw fa-key",
                        title: T("License"),
                        key: T("License"),
                        [item ? "labels" : "value"]: item
                            ? [
                                  {
                                      id: "",
                                      text: T("Not licensed"),
                                      title: T("Not licensed"),
                                      displayType: "label"
                                  }
                              ]
                            : "<span class='content-placeholder' style='width:" +
                              numberHelpers.getRandomArbitrary(50, 250) +
                              "px;'></span>"
                    })
                }
            }

            if (!jsonHelpers.getObjectProperty(item, "depOnly")) {
                thisDetails.push({
                    iconClass: "fal fa-fw fa-clock",
                    title: T("Contact"),
                    key: T("Contact"),
                    value: item
                        ? undefined
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>",
                    regularlyUpdatedData: !item
                        ? undefined
                        : {
                              inputValue: item ? (<AppleDevice>item).lastContact * 1000 : undefined,
                              method: (inputValue: any) => {
                                  let result = inputValue ? moment(inputValue).fromNow() : ""
                                  if (
                                      !jsonHelpers.getObjectProperty(item, "depOnly") &&
                                      !jsonHelpers.getObjectProperty(item, "enterpriseDevice")
                                  ) {
                                      let loaderHtml = `<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 50 50"  style="stroke-width: 4px;">
											ƒ	<circle cx="25" cy="25" r="20" style="stroke:rgba(0, 0, 0, 0.1); fill:none;"/>
												<g transform="translate(25,25) rotate(-90)">
														<circle style=" fill:none; stroke-linecap: round" stroke-dasharray="110" stroke-dashoffset="0" cx="0" cy="0" r="20">
															<animate attributeName="stroke-dashoffset" values="360;140" dur="2.2s" keyTimes="0;1" calcMode="spline" fill="freeze" keySplines="0.41,0.314,0.8,0.54" repeatCount="indefinite" begin="0"/>
															<animate attributeName="stroke" fill="freeze" dur="8s" begin="0" repeatCount="indefinite"/>
														</circle>
												</g>
											</svg>`
                                      if (
                                          (<AppleDevice>item)?.signedIn &&
                                          (<AppleDevice>item)?.configured &&
                                          licenseHelpers.hasOneOfLicenses(
                                              accountId,
                                              ["Mobile Security", "MDM"],
                                              "valid"
                                          )
                                      ) {
                                          result +=
                                              ' <a id="pingbutton-' +
                                              (<AppleDevice>item)?.deviceId +
                                              '" class="btn padding-xs-y-0 btn-loader twist-in ' +
                                              (getterHelpers.useStore()?.state.browserSettings
                                                  .darkmode == "1"
                                                  ? "btn-darkbluegrey"
                                                  : "btn-white color-gray") +
                                              ' " onClick="arguments[0].stopPropagation();event.cancelBubble = true; app.queries.mobileSecurity.sendPushNotification(' +
                                              inlineTenantDomain +
                                              ", " +
                                              inlineDeviceId +
                                              ')" title="' +
                                              T("Ping device") +
                                              '"><span class="animate" style="padding-top:0;padding-bottom:0;">' +
                                              loaderHtml +
                                              '</span><span style="padding-top:0;padding-bottom:0;"><i class="fal fa-sync"></i></span></a>'
                                      }
                                  }
                                  return result
                              },
                              refreshAfterMs: 10000
                          }
                })
            }
            return thisDetails
        }

        this.itemlistItem.getDisabledState = (accountId, item) => {
            return (
                ((<AppleDevice>item)?.configured == true &&
                    (<AppleDevice>item)?.licenseUUID == "" &&
                    (<AppleDevice>item)?.signedIn) ||
                (<DepDevice>item)?.depOnly === true
            )
        }

        this.itemlistItem.getLabels = (accountId, item) => {
            let thisLabels = []
            let licenseInfo = licenseHelpers.getLicenseByUUID(
                accountId,
                (<AppleDevice>item)?.licenseUUID || ""
            )
            var timeEnrolled = (<AppleDevice>item)?.enrolledAt
                ? Date.now() - (<AppleDevice>item)?.enrolledAt * 1000
                : null
            if (timeEnrolled != null) {
                if (timeEnrolled < 1000 * 60 * 60 * 24) {
                    thisLabels.push({
                        title: T("New"),
                        text: T("New"),
                        class: "bg-green",
                        icon: "fa fa-info-circle"
                    })
                }
            }
            if (
                ((!((<AppleDevice>item)?.configured && (<AppleDevice>item).consent == "") &&
                    (<AppleDevice>item).consent != config.devicesConsentDate) ||
                    !(<AppleDevice>item)?.configured) &&
                !(<DepDevice>item).depOnly
            ) {
                thisLabels.push({
                    title: T("Terms not accepted"),
                    text: T("Terms not accepted"),
                    class: "bg-red",
                    icon: "fa fa-exclamation-triangle"
                })
            }

            if (item?.depDevice == true) {
                thisLabels.push({
                    title: T("DEP"),
                    text: T("DEP"),
                    class: "",
                    icon: "fab fa-apple"
                })
            }
            if ((<AppleDevice>item)?.declarativeManagement?.managed == true) {
                thisLabels.push({
                    title: T("Status reporting"),
                    text: T("Status reporting"),
                    class: "",
                    icon: "fa fa-info-circle"
                })
            }

            if (
                (<AppleDevice>item)?.availableUpdates?.length > 0 &&
                (<AppleDevice>item)?.info.IsSupervised == true
            ) {
                thisLabels.push({
                    title: T("Update available"),
                    text: T("Update available"),
                    class: "bg-green",
                    icon: "fa fa-sync"
                })
            }

            if ((<AppleDevice>item)?.signedIn === false && !(<DepDevice>item)?.depOnly) {
                thisLabels.push({
                    title: T("Signed out"),
                    text: T("Signed out"),
                    class: "bg-yellow",
                    icon: "fa fa-sign-out"
                })
            }
            if ((<AppleDevice>item)?.configured) {
                if ((<AppleDevice>item)?.licenseUUID != "") {
                    if (
                        !(<DepDevice>item).depOnly &&
                        (<AppleDevice>item)?.info.IsSupervised == true
                    ) {
                        thisLabels.push({
                            title: T("Supervised"),
                            text: T("Supervised"),
                            class: "",
                            icon: "fa fa-briefcase"
                        })
                    }
                    if ((<AppleDevice>item)?.lostMode == true) {
                        thisLabels.push({
                            title: T("Lost"),
                            text: T("Lost"),
                            class: "bg-red",
                            icon: "fa fa-circle-question"
                        })
                    }
                } else if ((<AppleDevice>item).signedIn) {
                    thisLabels.push({
                        title: T("Not licensed"),
                        text: T("Not licensed"),
                        class: "bg-red",
                        icon: "fa fa-exclamation-triangle"
                    })
                }
            } else {
                if ((<AppleDevice>item)?.deviceType == "UNKNOWN") {
                    thisLabels.push({
                        title: T("Waiting for device info"),
                        text: T("Waiting for device info"),
                        class: "bg-yellow",
                        icon: "fa fa-hourglass-start"
                    })
                } else {
                    thisLabels.push({
                        title: T("Unconfigured"),
                        text: T("Unconfigured"),
                        class: "",
                        icon: "fa fa-info-circle"
                    })
                }
            }
            if (licenseInfo != undefined && !licenseHelpers.isLicenseValid(licenseInfo)) {
                thisLabels.push({
                    title: T("License expired"),
                    text: T("License expired"),
                    class: "bg-red",
                    icon: "fa fa-exclamation-triangle"
                })
            }

            // #44680
            if ((<AppleDevice>item)?.profile?.profileId) {
                let thisProfile = products.mobileSecurity.iosProfiles
                    .useStore?.()
                    .getObjectStoreObject(accountId, (<AppleDevice>item).profile.profileId)

                if (Array.isArray(thisProfile?.sun) && thisProfile.sun.length > 0) {
                    let uncErrors: { pool: ProfileSunPoolInfo; error: string }[] = []

                    if (
                        Array.isArray((<AppleDevice>item)?.isInExhaustedSunPools) &&
                        (<AppleDevice>item).isInExhaustedSunPools!.length > 0
                    ) {
                        thisProfile.sun.forEach((sunInfo) => {
                            if (Array.isArray(sunInfo.pools)) {
                                sunInfo.pools.forEach((pool) => {
                                    if (
                                        (<AppleDevice>item).isInExhaustedSunPools?.includes(pool.id)
                                    ) {
                                        uncErrors.push({
                                            pool: pool,
                                            error: T(
                                                "Dieses Gerät ist einem Profil zugewiesen, das Teil eines Transfernetzes in der VPN-Konfiguration ist. Dieses Transfernetz hat keine ausreichende Kapazität für dieses Gerät mehr."
                                            )
                                        })
                                    }
                                })
                            }
                        })
                    }

                    thisLabels.push({
                        text: "VPN",
                        htmlTooltip: false,
                        title:
                            uncErrors.length == 0
                                ? T(
                                      "Dieses Gerät ist einem Profil zugewiesen, das Teil eines Transfernetzes in der VPN-Konfiguration ist."
                                  )
                                : uncErrors.map((error) => {
                                      return error.pool.name + ": " + error.error
                                  }),
                        class: uncErrors.length == 0 ? "" : "bg-red",
                        icon: "fa fa-puzzle"
                    })
                }
            }

            return thisLabels
        }

        this.itemlistItem.getMenuEntries = (accountId, item, component) => {
            let menuLinks = []
            if (licenseHelpers.hasOneOfLicenses(accountId, ["Mobile Security", "MDM"], "valid")) {
                if ((<AppleDevice>item)?.licenseUUID != "") {
                    if (!jsonHelpers.getObjectProperty(item, "depOnly")) {
                        if (
                            (<AppleDevice>item)?.configured &&
                            !((<AppleDevice>item)?.info?.ProductName || "").includes("Mac")
                        ) {
                            if ((<AppleDevice>item)?.signedIn) {
                                menuLinks.push(
                                    new Button({
                                        title: T("Details"),
                                        text: T("Details"),
                                        link:
                                            "#show-tenant-" +
                                            accountId +
                                            ".sms-ios-" +
                                            "device-" +
                                            (<AppleDevice>item)?.deviceId +
                                            "-details",
                                        icon: "fal fa-info-circle"
                                    })
                                )
                            }
                            if ((<AppleDevice>item)?.signedIn) {
                                menuLinks.push(
                                    new Button({
                                        title: T("Renew MDM-Profile"),
                                        text: T("Renew MDM-Profile"),
                                        onClick: () => {
                                            requestHandler.request(
                                                "POST",
                                                "/sms-mgt-api/api/" +
                                                    config.mgtApiVersion +
                                                    "/tenants/" +
                                                    accountId +
                                                    ".sms/devices/" +
                                                    (<AppleDevice>item)?.deviceId +
                                                    "/reenroll"
                                            )
                                        },
                                        icon: "fal fa-sync-alt"
                                    }),
                                    new Button({
                                        title: T("Download mobileconfig"),
                                        text: T("Download mobileconfig"),
                                        link:
                                            "#download-tenant-" +
                                            accountId +
                                            ".sms-device-" +
                                            (<AppleDevice>item)?.deviceId +
                                            "-mobileconfig",
                                        icon: "fal fa-download"
                                    })
                                )
                            }
                        }

                        if (item?.depDevice) {
                            menuLinks.push(
                                new Button({
                                    title: T("Assign DEP-Profile"),
                                    text: T("Assign DEP-Profile"),
                                    onClick: () => {
                                        this.renderAssignDepProfile(accountId, item)
                                    },
                                    icon: "fal fa-link"
                                })
                            )
                        }
                        if (
                            (<AppleDevice>item)?.depDeviceInfo?.profile_uuid &&
                            (<AppleDevice>item)?.depDeviceInfo?.profile_uuid != ""
                        ) {
                            menuLinks.push(
                                new Button({
                                    title: T("Remove DEP-Profile"),
                                    text: T("Remove DEP-Profile"),
                                    onClick: async () => {
                                        dialogs.misc.confirmDialog(
                                            accountId,
                                            T("Confirm Delete"),
                                            T(
                                                "Do you want to remove the profile from this device?"
                                            ),
                                            async () => {
                                                await this.unassignDepProfile(
                                                    accountId,
                                                    (<AppleDevice>item)?.depDeviceInfo
                                                        ?.profile_uuid,
                                                    [
                                                        (<AppleDevice>item)?.depDeviceInfo
                                                            ?.serial_number
                                                    ]
                                                )
                                                getterHelpers
                                                    .useStore()
                                                    .commit(MutationTypes.removeModal, {
                                                        accountId: accountId
                                                    })
                                            }
                                        )
                                    },
                                    icon: "fal fa-trash"
                                })
                            )
                        }
                    }
                } else {
                    if ((<AppleDevice>item)?.configured) {
                        if ((<AppleDevice>item)?.signedIn) {
                            menuLinks.push(
                                new Button({
                                    title: T("Assign license"),
                                    text: T("Assign license"),
                                    link: "#show-tenant-" + accountId + ".sms-licenses-dashboard",
                                    icon: "fal fa-link"
                                })
                            )
                        }
                    }
                    if (!(<DepDevice>item)?.depOnly) {
                        if (
                            (<AppleDevice>item)?.deviceType == "IOS" &&
                            (<AppleDevice>item)?.signedIn
                        ) {
                            menuLinks.push(
                                new Button({
                                    title: T("Download mobileconfig"),
                                    text: T("Download mobileconfig"),
                                    link:
                                        "#download-tenant-" +
                                        accountId +
                                        ".sms-device-" +
                                        (<AppleDevice>item)?.deviceId +
                                        "-mobileconfig",
                                    icon: "fal fa-download"
                                })
                            )
                        }
                    }
                }
            }

            if (!jsonHelpers.getObjectProperty(item, "depOnly")) {
                menuLinks.push(
                    new Button({
                        title: T("Delete"),
                        text: T("Delete"),
                        onClick: () => {
                            this.dialogs.getDeleteObjectDialog(accountId, item, undefined, true)
                        },
                        icon: "fal fa-trash"
                    })
                )
            }
            return menuLinks
        }

        this.itemlistItem.getStatus = (accountId, item) => {
            return item
                ? !(<AppleDevice>item)?.configured
                    ? T("Unconfigured")
                    : (<AppleDevice>item)?.signedIn === false
                      ? T("Signed out")
                      : T("Signed in")
                : ""
        }

        this.itemlistItem.getTitle = (item, component) => {
            component = component?.exposed ? component?.exposed : component

            let result: any = {}
            if ((<AppleDevice>item)?.alias) {
                result.title = (<AppleDevice>item)?.alias
                result.small =
                    "(" + deviceHelpers.getShortDeviceId((<AppleDevice>item)?.deviceId) + ")"
            } else {
                result.title = deviceHelpers.getShortDeviceId((<AppleDevice>item)?.deviceId)
            }
            if ((<AppleDevice>item)?.configured) {
                result.link = {
                    innerHtml: '<i class="fal fa-edit"></i>',
                    onClick: function () {
                        component.editAlias.value = true
                    },
                    showIf: function () {
                        return component.editAlias.value == false
                    }
                }
            }
            return result
        }

        this.itemlistItem.hasCheckbox = (item) => {
            return !(<DepDevice>item).depOnly
        }

        this.itemlistItem.isClickable = (accountId, item) => {
            let result = true
            if (!licenseHelpers.hasOneOfLicenses(accountId, ["Mobile Security", "MDM"], "valid")) {
                result = false
            }

            if ((<DepDevice>item)?.depOnly) {
                result = false
            }

            //device is a "Stellvertreter"
            if (!(<AppleDevice>item)?.signedIn && (<AppleDevice>item)?.licenseUUID) {
                result = false
            }

            if (((<AppleDevice>item)?.info?.ProductName || "")?.indexOf("Mac") != -1) {
                result = false
            }

            return result
        }

        this.itemlistItem.onClick = (accountId, item) => {
            if (
                licenseHelpers.hasOneOfLicenses(accountId, ["Mobile Security", "MDM"], "valid") &&
                (<AppleDevice>item)?.deviceId
            ) {
                if (!(<AppleDevice>item)?.configured && !(<DepDevice>item).depOnly) {
                    if ((<AppleDevice>item)?.managedAppleId) {
                        dialogs.mobileSecurity.assignDeviceOwnership(
                            accountId,
                            "mobileSecurity",
                            "managedAppleIdDevices",
                            (<AppleDevice>item)?.deviceId,
                            item
                        )
                    } else {
                        dialogs.mobileSecurity.assignDeviceOwnership(
                            accountId,
                            "mobileSecurity",
                            "devices",
                            (<AppleDevice>item)?.deviceId,
                            item
                        )
                    }
                } else if (
                    !((<AppleDevice>item)?.configured && (<AppleDevice>item).consent == "") &&
                    (<AppleDevice>item).consent != config.devicesConsentDate
                ) {
                    dialogs.mobileSecurity.assignDeviceOwnership(
                        accountId,
                        "mobileSecurity",
                        "devicesConsent",
                        (<AppleDevice>item)?.deviceId,
                        item
                    )
                } else if ((<DepDevice>item)?.depOnly) {
                    this.renderAssignDepProfile(accountId, item)
                } else if (
                    (<AppleDevice>item)?.licenseUUID == "" ||
                    !(<AppleDevice>item)?.signedIn ||
                    (<DepDevice>item)?.depOnly
                ) {
                    // do nothing
                } else {
                    router.navigate(
                        "#show-tenant-" +
                            accountId +
                            ".sms-ios-device-" +
                            (<AppleDevice>item)?.deviceId +
                            "-details"
                    )
                }
            }
        }

        this.queries.getObjectsFromApi = async (
            accountId,
            customerId?,
            props?,
            updateStore = true,
            removeOldObjects?: boolean
        ) => {
            let store = this.useStore?.().getObjectStore(accountId)
            let timestamp = store?.objectsTimestamp || 0
            let isRequestable = (function () {
                return (
                    store?.gettingObjects == false && timeHelpers.getUnixTimeStamp() - timestamp > 5
                )
            })()
            if ((store && isRequestable) || store == undefined) {
                if (store) {
                    store.gettingObjects = true
                    store.objectsTimestamp = timeHelpers.getUnixTimeStamp()
                }
                const propertiesString: string = props ? this.getPropertiesString(props) : ""
                let result: IosDevice[] = []

                try {
                    let iosDevices: any = { devices: [] }
                    let depDevices: any = []
                    try {
                        iosDevices =
                            props?.[0]?.property == "depDevicesOnly" && props?.[0]?.value == true
                                ? null
                                : await requestHandler.request(
                                      this.options.apiInfo.getObjectListMethod,
                                      this.getListUrl(accountId) + propertiesString
                                  )
                    } catch (e: any) {
                        throw e.message
                    }
                    try {
                        depDevices =
                            props?.[0]?.property == "iosDevicesOnly" && props?.[0]?.value == true
                                ? null
                                : tenantHelpers.hasFunctionality(accountId, "dep")
                                  ? await products.mobileSecurity.depDevices.queries.getObjectsFromApi(
                                        accountId
                                    )
                                  : null
                    } catch (e: any) {
                        mobileSecurityQueries.dep.doDepErrorCheck(e)
                        console.error(e)
                        depDevices = []
                    }

                    let iosDevicesResponse = iosDevices
                    if (iosDevicesResponse?.devices) {
                        iosDevicesResponse = iosDevicesResponse.devices
                    }
                    let depDevicesResponse = depDevices

                    if (iosDevicesResponse instanceof Error) {
                        throw iosDevicesResponse
                    } else if (depDevicesResponse instanceof Error) {
                        throw depDevicesResponse
                    } else {
                        result = this.mergeIosAndDepDevices(
                            iosDevicesResponse || [],
                            depDevicesResponse || []
                        )
                    }
                    if (updateStore && result.length) {
                        if (removeOldObjects == true) {
                            this.useStore?.().clearObjectTypeObjectsFromStore(accountId)
                        }
                        this.useStore?.().setObjectTypeCount(accountId, result.length)
                        this.useStore?.().setObjectTypeObjects(accountId, result)
                    }
                    if (store) {
                        store.gettingObjects = false
                    }
                    return result
                } catch (e) {
                    if (store) {
                        store.gettingObjects = false
                    }
                    throw e
                }
            } else {
                return this.useStore?.().getObjectStoreObjects(accountId) || []
            }
        }

        this.dialogs.getDeleteObjectDialog = (accountId, object, customerId, confirm = false) => {
            let singularOfObjectType = this.options.appearance.text.singular.toLocaleLowerCase()
            let objectIdProperty = this.options.objectTypeInfo.primaryKeyProperty.property
            let objectId = this.getObjectId(object)
            let objectName = this.itemlistItem.getTitle(object, undefined).title
            miscDialogs.confirmDialog(
                accountId,
                T("Confirm delete"),
                T(
                    "<p>Beim Löschen eines iOS-Geräts werden das Enrollment-Profil und das Konfigurations-Profil vom Gerät gelöscht:</p><ul><li><b>Für betreute Geräte wird das Gerät komplett auf die Werkseinstellungen zurückgesetzt!</b></li><li>Für unbetreute und Account Based User Enrollment Geräte hingegen werden nur die Profile gelöscht.<br>Die lokalen Daten des Geräts bleiben erhalten.</li></ul>"
                ),
                async () => {
                    await this.queries.deleteObjectFromApi(accountId, objectId, customerId)
                    await this.queries.getCountFromApi(accountId)
                    await this.queries.getObjectsFromApi(accountId)
                },
                confirm ? T("Delete") : undefined
            )
        }

        this.replaceStoreFunctionWith.setObjectTypeObjects = (accountId, objects) => {
            let objectTypeInfo = this.options.objectTypeInfo
            let idProperty = objectTypeInfo?.primaryKeyProperty.property
            let idPropertyPath = objectTypeInfo?.primaryKeyProperty.pathToPrimaryProperty
            let accountObjectStore = this.useStore?.().getObjectStore(accountId)
            let objectStore = this.useStore?.()
            if (accountObjectStore?.objects.length) {
                if (idProperty) {
                    let existingObject: IosDevice | DepDevice | undefined = undefined
                    objects.forEach((object) => {
                        const addToStore = (object: IosDevice | DepDevice, objectId: any) => {
                            existingObject = this.useStore?.().getObjectStoreObject(
                                accountId,
                                thisObjectId
                            )
                            if (existingObject != undefined) {
                                // replace
                                Object.keys(object).forEach((key) => {
                                    //@ts-ignore
                                    existingObject[key as keyof IosDevice] =
                                        object[key as keyof IosDevice]
                                })
                            } else if (accountObjectStore) {
                                // add object
                                accountObjectStore.objects?.push(object)
                            }
                        }

                        if (object && this.convertObjectForStore) {
                            this.convertObjectForStore(accountId, object)
                        }
                        existingObject = undefined
                        // check if object already exists
                        const thisObjectId = idPropertyPath
                            ? jsonHelpers.getObjectProperty(
                                  object,
                                  idPropertyPath + "." + String(idProperty)
                              )
                            : object[idProperty as keyof typeof object]

                        if ((<DepDevice>object).depOnly === true) {
                            const depDeviceSerial = (<DepDevice>object).serial_number || undefined
                            let iosDevice = accountObjectStore?.objects.find(
                                (existingItem: IosDevice | DepDevice) => {
                                    if ((<DepDevice>existingItem).serial_number == undefined) {
                                        // is ios Device
                                        const iosDeviceSerial =
                                            (<AppleDevice>existingItem).serialNumber || ""
                                        return depDeviceSerial === iosDeviceSerial
                                    }
                                }
                            )
                            let depDevice = accountObjectStore?.objects.find(
                                (existingItem: IosDevice | DepDevice) => {
                                    if ((<DepDevice>existingItem).serial_number != undefined) {
                                        // is dep Device
                                        return (
                                            depDeviceSerial ===
                                                (<DepDevice>existingItem).serial_number || ""
                                        )
                                    }
                                }
                            )
                            if (iosDevice != undefined) {
                                // found device
                                ;(<AppleDevice>iosDevice).dep = true
                                ;(<AppleDevice>iosDevice).depDeviceInfo = object as DepDevice
                            } else if (depDevice != undefined) {
                                // found device
                                depDevice = object
                            } else {
                                addToStore(object, thisObjectId)
                            }
                        } else if ((<AppleDevice>object).serialNumber != undefined) {
                            const iosDeviceSerial = (<AppleDevice>object).serialNumber || ""
                            let foundDevice = false
                            accountObjectStore?.objects.forEach(
                                (existingItem: IosDevice | DepDevice, index: number) => {
                                    if ((<DepDevice>existingItem).depOnly === true) {
                                        // Is DEP device
                                        const depDeviceSerial =
                                            (<DepDevice>existingItem).serial_number || undefined
                                        if (depDeviceSerial === iosDeviceSerial) {
                                            foundDevice = true
                                            let depDeviceInfo: DepDevice =
                                                jsonHelpers.copyObject(existingItem)
                                            // replace DEP device with Ios device plus DEP info
                                            if (accountObjectStore) {
                                                accountObjectStore.objects[index] =
                                                    object as IosDevice
                                                ;(<AppleDevice>(
                                                    accountObjectStore.objects[index]
                                                )).dep = true
                                                ;(<AppleDevice>(
                                                    accountObjectStore.objects[index]
                                                )).depDeviceInfo = depDeviceInfo
                                            }
                                        }
                                    }
                                }
                            )

                            if (!foundDevice && accountObjectStore) {
                                addToStore(object, thisObjectId)
                            }
                        } else {
                            addToStore(object, thisObjectId)
                        }
                    })
                }
            } else if (accountObjectStore) {
                objects.forEach((object) => {
                    if (object && this?.convertObjectForStore) {
                        this.convertObjectForStore(accountId, object)
                    }
                })
                accountObjectStore.objects = objects
            }
        }
    }

    mergeIosAndDepDevices = (iosDevices: IosDevice[], depDevices: DepDevice[]): IosDevice[] => {
        let result: IosDevice[] = []

        if (depDevices.length > 0 && iosDevices.length == 0) {
            return depDevices
        } else if (depDevices.length == 0 && iosDevices.length > 0) {
            return iosDevices
        }

        result = jsonHelpers.copyObject(iosDevices)

        if (!Array.isArray(depDevices)) {
            depDevices = []
        }

        depDevices.forEach((depDevice) => {
            depDevice.depDevice = true
            if (result.length) {
                const depDeviceSerial = depDevice.serial_number
                let foundDevice = result.find((iosDevice) => {
                    const iosDeviceSerial = (<AppleDevice>iosDevice).serialNumber
                    return iosDeviceSerial == depDeviceSerial
                })

                if (foundDevice != undefined) {
                    foundDevice.depDevice = true
                    //@ts-ignore
                    delete (<AppleDevice>foundDevice).depOnly
                    ;(<AppleDevice>foundDevice).depDeviceInfo = depDevice
                } else {
                    depDevice.depOnly = true
                    result.push(depDevice)
                }
            } else {
                depDevice.depOnly = true
                result.push(depDevice)
            }
        })
        return result
    }

    public depProfileStatusInfo = {
        empty: "No DEP Profile assigned",
        assigned:
            "The profile has been assigned to the device, but will not be applied until the next reset",
        pushed: "The profile has been applied to the device",
        removed: "The profile has been removed from the device"
    }

    getSerialNumber = (item: IosDevice) => {
        if (item) {
            return (
                (<AppleDevice>item).serialNumber ||
                (<DepDevice>item).serial_number ||
                (<AppleDevice>item).info?.SerialNumber
            )
        }
        return undefined
    }
    sortIosDevicesByAliasId = (devices: IosDevice[]): IosDevice[] => {
        return devices.sort((deviceA: IosDevice, deviceB: IosDevice) => {
            let aliasA = deviceHelpers
                .getAliasedShortDeviceId(
                    (<AppleDevice>deviceA).deviceId,
                    (<AppleDevice>deviceA).alias
                )
                .toLowerCase()
            let aliasB = deviceHelpers
                .getAliasedShortDeviceId(
                    (<AppleDevice>deviceB).deviceId,
                    (<AppleDevice>deviceB).alias
                )
                .toLowerCase()
            return aliasA < aliasB ? -1 : aliasA > aliasB ? 1 : 0
        })
    }
    getIMEI = (item: IosDevice) => {
        if (item) {
            return (
                (<AppleDevice>item).info?.IMEI ||
                (<AppleDevice>item).info?.ServiceSubscriptions?.[0].IMEI
            )
        }
        return undefined
    }

    hasDevicesWithoutLicense = (accountId: string) => {
        let items = this.useStore?.().getObjectStoreObjects(accountId) || []
        return items.some((device) => {
            return (
                (<AppleDevice>device).signedIn &&
                !(<AppleDevice>device)?.licenseUUID &&
                !(<DepDevice>device).depOnly &&
                (<AppleDevice>device).configured == true
            )
        })
    }
    isEnrollmentAllowed = (accountId: string) => {
        return (
            (<boolean | undefined>(
                getterHelpers.useStore().state.session.accounts[accountId]?.mobileSecurity?.settings
                    ?.enrollmentAllowed
            ) &&
                licenseHelpers.hasOneOfLicenses(accountId, ["Mobile Security", "MDM"], "valid")) ||
            false
        )
    }

    sendDeviceMessage = async (accountid: string, deviceId: string, message: any) => {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (getterHelpers.useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            result = await requestHandler.request(
                "POST",
                apis.getTenantApiUrl(accountid, "apple") + "/devices/" + deviceId + "/messages",
                message
            )
        } catch (e: any) {
            console.error(e)
            result = false
        }
        return apis.parseApiResponse(result)
    }

    renderAssignDepProfile = (accountId: AccountId, deviceInfo?: any) => {
        let newModal: any = {
            id: "assignDepProfile",
            accountId: accountId,
            abortable: true,
            content: {
                title: {
                    text: T("Assign DEP-Profile"),
                    icon: "fal fa-exclamation-triangle"
                },
                body: {
                    use: true,
                    content: undefined,
                    component: "dep-profile-assignment",
                    properties: {
                        deviceInfo: deviceInfo
                    }
                }
            },
            buttons: [
                {
                    loading: false,
                    onClick: function () {
                        getterHelpers
                            .useStore()
                            .commit(MutationTypes.removeModal, { accountId: accountId })
                    },
                    icon: "fal fa-times",
                    text: T("Cancel"),
                    align: "left",
                    disabled: false
                },
                {
                    loading: false,
                    onClick: async (modal: any) => {
                        getterHelpers
                            .useStore()
                            .getters.getActiveModal(accountId).buttons[1].disabled = true
                        getterHelpers
                            .useStore()
                            .getters.getActiveModal(accountId).buttons[1].loading = true

                        let profileUUID: string = modal.$refs.modalComponent.form.depProfile.value

                        let deviceSerials: string[] =
                            deviceInfo != undefined
                                ? [deviceInfo.depDeviceInfo.serial_number]
                                : modal.$refs.modalComponent.form.depDevices.value
                        try {
                            await this.assignDepProfile(accountId, profileUUID, deviceSerials)
                            getterHelpers
                                .useStore()
                                .getters.getActiveModal(accountId).buttons[1].disabled = false
                            getterHelpers
                                .useStore()
                                .getters.getActiveModal(accountId).buttons[1].loading = false
                        } catch (e: any) {
                            console.error(e)
                        }
                        getterHelpers
                            .useStore()
                            .commit(MutationTypes.removeModal, { accountId: accountId })
                    },
                    icon: "fal fa-save",
                    text: T("Save"),
                    align: "right",
                    disabled: false
                }
            ]
        }
        getterHelpers.useStore().dispatch(ActionTypes.addModal, newModal)
    }

    assignDepProfile = async (accountid: string, profile_uuid: string, deviceSerials: string[]) => {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (this.useStore?.()?.hasAccount(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            let payload: any = {
                profile_uuid: profile_uuid,
                devices: deviceSerials
            }
            result = await requestHandler.request(
                "POST",
                apis.getTenantApiUrl(tenantDomain, "appleDEP") + "/dep/profile/devices",
                payload
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = e
        }
        return apis.parseApiResponse(result)
    }

    unassignDepProfile = async (
        accountid: string,
        profile_uuid: string | undefined,
        deviceSerials: (string | undefined)[]
    ) => {
        let result: any = null
        if (profile_uuid) {
            try {
                accountid = tenantHelpers.getAccountId(accountid)
                if (this.useStore?.()?.hasAccount(accountid) == false) {
                    throw { status: 401 }
                }
                let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
                let payload: any = {
                    profile_uuid: profile_uuid,
                    devices: deviceSerials
                }
                result = await requestHandler.request(
                    "DELETE",
                    apis.getTenantApiUrl(tenantDomain, "appleDEP") + "/dep/profile/devices",
                    payload
                )
            } catch (e: any) {
                if (e.status == 401) {
                    //app.router.push({'name':'401'})
                } else {
                    console.error(e)
                }
                result = e
            }
            return apis.parseApiResponse(result)
        }
        return false
    }

    /**
     * Converts Object for ObjectTypeStore
     */
    convertObjectForStore(accountId: AccountId, objectBase: AddObjectTypeObject<IosDevice>) {
        if ((<DepDevice>objectBase).depOnly) {
            ;(<AppleDevice>objectBase).deviceId = (<DepDevice>objectBase).serial_number
        }

        if (
            (<AppleDevice>objectBase).inventory?.inventoryLocation &&
            typeof (<AppleDevice>objectBase).inventory?.inventoryLocation == "string"
        ) {
            //@ts-ignore
            objectBase.inventory.inventoryLocation = JSON.parse(
                <string>objectBase.inventory.inventoryLocation
            )
        }
    }
}

const iosDevices = new IosDevices({
    productType: "mobileSecurity",
    slug: "iosdevices",
    objectType: "iosDevices",
    hasStore: true,
    appearance: {
        iconClass: "fal fa-mobile-alt",
        text: {
            plural: "Devices",
            title: "Devices",
            sidebarName: "Devices",
            singular: "Device"
        },
        color: "red",
        showInSidebar: true,
        showOnDashboard: true
    },
    objectTypeInfo: {
        primaryKeyProperty: {
            property: "deviceId",
            pathToPrimaryProperty: undefined
        },
        nameProperty: {
            primary: "alias",
            pathToPrimaryProperty: undefined,
            secondary: undefined,
            pathToSecondaryProperty: undefined
        }
    },
    apiInfo: {
        url: "/sms-mgt-api/api/2.0",
        getCountGETProperties: "?props[]=null&select=data.count",
        // GET
        getObjectListResponseProperty: "devices",
        getObjectListMethod: "GET",
        getObjectListPath: "/tenants/{tenantDomain}/apple/devices",
        // Add
        addObjectMethod: "POST",
        addObjectPath: "/tenants/{tenantDomain}/apple/devices",
        // Update
        updateObjectMethod: "PUT",
        updateObjectPath: "/tenants/{tenantDomain}/apple/devices/{objectId}",
        // Delete
        deleteObjectPath: "/tenants/{tenantDomain}/apple/devices/{objectId}"
    }
})
export default iosDevices
