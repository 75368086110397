<script setup lang="ts">
import { T } from "@/classes/i18n"
import getterHelpers from "@/helpers/helpers.getters"
import darkImage from "@/img/vpn_landingpage_dark.svg"
import lightImgae from "@/img/vpn_landingpage_light.svg"
import router from "@/router/router"
import { useStore } from "@/store/vuex.store"
import { computed, ref } from "vue"
import Button from "../button/button"
import buttonComponent from "../button/button.vue"

const darkmode = computed(() => {
    return getterHelpers.useStore().state.browserSettings.darkmode == "1"
})

const accountId = computed(() => {
    return useStore().state.session.activeAccountId || ""
})

const addTokenButton = ref(
    new Button({
        icon: "fa-plus",
        text: T("Add Windows enrollment token"),
        title: T("Add Windows enrollment token"),
        onClick: () => {
            router.navigate(`#add-tenant-${accountId.value}.sms-windows-enrollmenttoken`)
        }
    })
)
</script>

<template>
    <div class="row flexrow">
        <div class="col-xs-24 col-lg-16">
            <div class="box-shadow padding-xs-2" style="height: 100%">
                <h4 class="margin-xs-b-2">
                    {{ T("Windows Enrollment und Konfiguration") }}
                </h4>

                <p>
                    {{
                        T(
                            "Über das Windows Enrollment können Sie Ihre Windows VPN-Clients einfach in das Securepoint-Portal integrieren. Die Zuweisung von vorkonfigurierten Profilen ermöglicht die schnelle und einfache Konfiguration Ihrer Clients. Über die VPN-Konfiguration können Sie für Ihre Windows-Geräte VPN-Verbindungen zu Ihren Standorten einrichten."
                        )
                    }}
                </p>

                <p class="text-bold margin-xs-t-3">
                    {{ T("Wichtig:") }}
                </p>
                <p>
                    {{
                        T(
                            "Nutzen Sie für Ihre Windows-Geräte die neueste Version des Securepoint VPN Clients (>= 3.0.0)."
                        )
                    }}
                </p>

                <p class="text-bold margin-xs-t-3">{{ T("Erste Schritte:") }}</p>
                <ol class="margin-xs-b-3">
                    <li>
                        {{
                            T(
                                "Erstellen Sie ein Enrollmenttoken und wählen Sie die MDM-Lizenz für das Gerät aus."
                            )
                        }}
                    </li>
                    <li>
                        {{
                            T(
                                "Sie können den Enrollmentoken z.B. per E-Mail-Einladung zur manuellen Eingabe an Ihre Benutzer versenden oder mit Software-Verteilungssystemen nutzen. Ein Benutzer kann dann mit Hilfe des Tokens seinen VPN-Client im Portal registrieren."
                            )
                        }}
                    </li>
                    <li>
                        {{
                            T(
                                "Erstellen Sie ein Windows-Profil und legen Sie dort Berechtigungen, Sicherheitseinstellungen und weitere Konfigurationen fest."
                            )
                        }}
                    </li>
                    <li>
                        {{
                            T(
                                "Weisen Sie dem Gerät über die direkte Zuweisung oder über die Vergabe eines Tags ein Konfigurationsprofil zu."
                            )
                        }}
                    </li>
                </ol>

                <p>
                    {{ T("Weitere Informationen finden Sie in unserem Wiki unter") }}
                    <a href="https://wiki.securepoint.de/VPN" target="_blank">{{
                        T("Windows VPN")
                    }}</a
                    >.
                </p>
                <p class="margin-xs-t-4 text-center" style="font-size: 1.15em">
                    <buttonComponent :button-options="addTokenButton" />
                </p>
            </div>
        </div>
        <div class="col-xs-24 col-lg-8">
            <div class="box-shadow padding-xs-2 text-center" style="height: 100%">
                <img
                    :src="darkmode ? darkImage : lightImgae"
                    style="
                        display: inline;
                        max-width: 400px;
                        width: 100%;
                        filter: grayscale(1);
                        opacity: 0.7;
                    "
                />
            </div>
        </div>
    </div>
</template>
