<template>
    <section class="padding-xs-2 padding-lg-4 padding-print-0 pagecontent">
        <div class="row">
            <template v-for="product in resources.registeredProducts || []">
                <template
                    v-if="
                        hasOneOfLicenses(activeAccountId, product.licenses) &&
                        showProduct(product.featureFlag)
                    "
                >
                    <div class="col-xs-24 padding-xs margin-xs-b-2">
                        <h2 class="padding-xs margin-xs-b-2 border-bottom">
                            <span>
                                <secure-dns-nav-icon
                                    v-if="product.text.icon === 'secure-dns-nav-icon'"
                                    width="20px"
                                    height="20px"
                                    color="#e74b3c"
                                />
                                <i
                                    v-else
                                    class="fal fa-fw"
                                    :class="product.text.icon || 'fa-mobile'"
                                ></i>

                                {{ T(product.text.singular) }}
                                <template
                                    v-if="
                                        !hasOneOfLicenses(
                                            activeAccountId,
                                            product.licenses,
                                            'valid'
                                        )
                                    "
                                >
                                    <span class="label bg-red" style="font-size: 0.55em">{{
                                        T("expired")
                                    }}</span>
                                </template>
                            </span>
                        </h2>
                    </div>

                    <div class="col-xs-24 padding-xs-0">
                        <div class="row flexrow flex-wrap">
                            <template v-for="(objectInfo, _) in product.objectTypes">
                                <template
                                    v-if="
                                        objectInfo.appearance?.showOnDashboard &&
                                        areConditionsFulfilled(
                                            undefined,
                                            objectInfo.appearance.showConditions || []
                                        ) &&
                                        showProduct(objectInfo.featureFlag)
                                    "
                                >
                                    <div class="col-xs-6 col-lg-2 padding-xs text-center">
                                        <a
                                            class="icon-link"
                                            :href="
                                                objectInfo.link.href.replace(
                                                    '{tenantDomain}',
                                                    activeAccountId + '.sms'
                                                )
                                            "
                                            :target="objectInfo.link.target"
                                            :title="T(objectInfo.text.plural)"
                                        >
                                            <span
                                                class="icon-circle"
                                                :class="'bg-' + objectInfo.appearance.color"
                                            >
                                                <i :class="objectInfo.appearance.icon"></i>
                                            </span>
                                            <span class="link-text">
                                                {{ T(objectInfo.text.dashboard) }}
                                            </span>
                                        </a>
                                    </div>
                                </template>
                            </template>
                        </div>
                    </div>
                </template>
            </template>
        </div>
    </section>
</template>

<script setup lang="ts">
import { useFeatureStore } from "@/classes/featureStore"
import { T } from "@/classes/i18n"
import jsonFormHelpers from "@/helpers/helpers.jsonForms"
import licenseHelpers from "@/helpers/helpers.license"
import { useStore } from "@/store/vuex.store"
import { computed } from "vue"
import SecureDnsNavIcon from "../components/secure-dns-nav-icon.vue"

const activeAccountId = computed(() => {
    return useStore()?.getters.getActiveAccountId
})
const resources = computed(() => {
    return useStore().state.resources
})

const hasOneOfLicenses = licenseHelpers.hasOneOfLicenses
const areConditionsFulfilled = jsonFormHelpers.areConditionsFulfilled
const featureStore = useFeatureStore()

function showProduct(feature?: string) {
    if (!feature) {
        return true
    }

    return featureStore.isEnabled(feature)
}
</script>
