import apis from "@/classes/apis"
import { T } from "@/classes/i18n"
import devLog from "@/classes/log"
import getterHelpers from "@/helpers/helpers.getters"
import jsonHelpers from "@/helpers/helpers.json"
import timeHelpers from "@/helpers/helpers.time"
import requestHandler from "@/queries/requests"
import { MutationTypes } from "@/store/vuex.store"
import ObjectType, { type ObjectTypePayload } from "../../objectType"

export type ImageOnAdd = string
export interface Image {
    imageId: string
    img?: string
}

class Images extends ObjectType<Image> {
    constructor(payload: ObjectTypePayload<Image>) {
        super(payload)
        let thisClass = this

        this.queries.getObjectsFromApi = async (
            accountId,
            customerId?,
            props?,
            updateStore = true,
            removeOldObjects = false
        ) => {
            let store = this.useStore?.().getObjectStore(accountId)
            let timestamp = store?.objectsTimestamp || 0
            let isRequestable = (function () {
                return store?.gettingObjects == false
            })()
            if ((store && isRequestable) || store == undefined) {
                if (store) {
                    store.gettingObjects = true
                    store.objectsTimestamp = timeHelpers.getUnixTimeStamp()
                }

                let propertiesString: string = props ? this.getPropertiesString(props) : ""
                if (this.getListUrl(accountId).indexOf("?") != -1) {
                    propertiesString = propertiesString.replace("?", "&")
                }

                let result: ImageOnAdd[] | Image[] | Error

                try {
                    let response = await requestHandler.request(
                        this.options.apiInfo.getObjectListMethod,
                        this.getListUrl(accountId) + propertiesString
                    )
                    response = apis.parseApiResponse(response)
                    let propertyInresponse = this.options.apiInfo.getObjectListResponseProperty
                    if (
                        typeof propertyInresponse == "string" &&
                        propertyInresponse.length > 0 &&
                        response[propertyInresponse]
                    ) {
                        result = response[propertyInresponse as string] as ImageOnAdd[]
                    } else if (
                        typeof propertyInresponse == "function" &&
                        response[propertyInresponse()]
                    ) {
                        result = response[propertyInresponse()] as ImageOnAdd[]
                    } else if (Array.isArray(response)) {
                        result = response as ImageOnAdd[]
                    } else {
                        throw new Error("Error getting Objects")
                    }
                    if (Array.isArray(result)) {
                        result = result.map((imageString) => {
                            return {
                                imageId: imageString
                            }
                        })
                    }
                    if (updateStore) {
                        if (removeOldObjects == true) {
                            this.useStore?.().clearObjectTypeObjectsFromStore(accountId)
                        }

                        this.useStore?.().setObjectTypeObjects(accountId, result)

                        // all new items are added to store, so we can set the count
                        if (removeOldObjects) {
                            this.useStore?.().setObjectTypeCount(accountId, result.length)
                        }
                    }

                    if (store) {
                        store.gettingObjects = false
                    }
                    return result
                } catch (e: any) {
                    if (store) {
                        store.gettingObjects = false
                    }
                    devLog.log("ObjectType", e.message, e, "error")
                    throw e as Error
                }
            } else {
                return this.useStore?.().getObjectStoreObjects(accountId) || []
            }
        }

        this.queries.getObjectFromApi = async (
            accountId,
            objectId,
            customerId?,
            props?,
            updateStore = true,
            deleteOldObject = false
        ) => {
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            let result: string | Image | Error
            try {
                if (!objectId) throw "Missing objectId"
                let response = await requestHandler.request(
                    this.options.apiInfo.getObjectMethod,
                    this.getSingleObjectUrl(accountId, objectId, customerId) + propertiesString
                )
                response = apis.parseApiResponse(response)
                if (!jsonHelpers.isObjectEmpty(response)) {
                    result = response as string
                } else {
                    throw "Error getting objects"
                }
                if (typeof result == "string") {
                    result = {
                        imageId: objectId as string,
                        img: result
                    }
                }
                if (updateStore) {
                    if (deleteOldObject == true) {
                        this.useStore?.().deleteObjectTypeObjectFromStore(accountId, objectId)
                    }
                    this.useStore?.().setObjectTypeObject(accountId, String(objectId), result)
                }
                return result
            } catch (e: any) {
                devLog.log("ObjectType", e.message, e, "error")
                throw e as Error
            }
        }
    }

    openMediaSelectorDialog(onSubmit: any) {
        let modal: any = {
            id: "mediaSelectionDialog",
            abortable: true,
            content: {
                title: {
                    text: T("Select an image"),
                    icon: "fal fa-hand-pointer"
                },
                body: {
                    use: true,
                    content: undefined,
                    component: "mediabrowser",
                    properties: {}
                }
            },
            onAbort: function () {
                getterHelpers.useStore().commit(MutationTypes.removeModal)
            },
            buttons: [
                {
                    text: T("Cancel"),
                    icon: "fal fa-times",
                    onClick: async function () {
                        getterHelpers.useStore().commit(MutationTypes.removeModal)
                    },
                    align: "left",
                    loading: false
                },
                {
                    text: T("Select"),
                    icon: "fal fa-hand-pointer",
                    onClick: async function () {
                        let selectedImageId: string =
                            getterHelpers.useVue().$refs.modals.$refs.modal.$refs.modalComponent
                                .selectedImageId

                        onSubmit(selectedImageId)
                        getterHelpers.useStore().commit(MutationTypes.removeModal)
                    },
                    align: "right",
                    loading: false,
                    disabled: true
                }
            ]
        }
        getterHelpers.useVue().$refs.modals.addModal(modal)
    }
}

const images = new Images({
    objectType: "images",
    productType: "unifiedSecurity",
    slug: "images",
    appearance: {
        color: "red",
        iconClass: "fal fa-image fa-fw",
        showInSidebar: false,
        showOnDashboard: false,
        text: {
            plural: "Images",
            singular: "Image",
            sidebarName: "Image",
            title: "Images"
        }
    },
    apiInfo: {
        url: "/sms-mgt-api/api/2.0",
        // GET
        getObjectListMethod: "GET",
        getObjectListPath: "/tenants/{tenantDomain}/images",
        getObjectListResponseProperty: "images"
    },
    hasStore: true
})
export default images
