import apis from "@/classes/apis"
import { T } from "@/classes/i18n"
import devLog from "@/classes/log"
import miscDialogs from "@/dialogs/dialogs.misc"
import deviceHelpers from "@/helpers/helpers.devices"
import getterHelpers from "@/helpers/helpers.getters"
import jsonHelpers from "@/helpers/helpers.json"
import licenseHelpers from "@/helpers/helpers.license"
import numberHelpers from "@/helpers/helpers.numbers"
import tenantHelpers from "@/helpers/helpers.tenants"
import queries from "@/queries/queries"
import requestHandler from "@/queries/requests"
import router from "@/router/router"
import { ActionTypes, MutationTypes, useStore } from "@/store/vuex.store"
import Button from "@/templates/components/button/button"
import download from "downloadjs"
import products from "../.."
import ObjectType, {
    type ItemlistItemDetail,
    type ObjectTypeObject,
    type ObjectTypePayload
} from "../../objectType"
import type { License } from "../../unifiedSecurity/licenses/licenses"

export interface Vpn {
    vpnId: string
    vpnname: string
    tenantDomain?: string
    ssl_bump_mode?: string
    proto?: string
    pf_groups_type?: string
    licenseUUID?: string
    enrollmentTime?: string
    inventory?: ObjectInventory
    exclude_routes?: Array<string>
    exclude_domains?: Array<string>
    cf_blacklist?: Array<string>
    cf_whitelist?: Array<string>
    licenseInformation?: License
}

class Vpns extends ObjectType<Vpn> {
    constructor(payload: ObjectTypePayload<Vpn>) {
        super(payload)
        const thisObjectType = this
        this.itemlist.getSortingOptions = () => {
            return [
                {
                    id: "vpnname",
                    text: T("Name")
                }
            ]
        }
        this.itemlist.getToolbarButtons = (accountId, itemlistComponent) => {
            let thisToolbarEntries = []
            if (
                itemlistComponent.exposed.enrollmentAllowed.value &&
                itemlistComponent.exposed.accountRestrictions.value == 0
            ) {
                thisToolbarEntries.push({
                    icon: "fal fa-plus",
                    title: T("Add VPN"),
                    link: "#add-tenant-" + tenantHelpers.getTenantDomain(accountId) + "-vpn",
                    id: "vpnsButtonAdd",
                    vIf: false
                })
            } else if (itemlistComponent.exposed.accountRestrictions.value > 0) {
                thisToolbarEntries.push({
                    icon: "fal fa-exclamation-triangle",
                    title: T("Enrollment disabled"),
                    link: "",
                    id: "vpnsButtonAdd",
                    disabled: true,
                    vIf: false
                })
            } else if (!itemlistComponent.exposed.enrollmentAllowed.value) {
                thisToolbarEntries.push({
                    icon: "fal fa-exclamation-triangle",
                    title: T("You have reached the devices limit"),
                    link: "",
                    id: "vpnsButtonAdd",
                    disabled: true,
                    vIf: false
                })
            }
            return thisToolbarEntries
        }

        this.itemlistItem.hasCheckbox = () => {
            return true
        }

        this.itemlistItem.getMenuEntries = (accountId, item) => {
            let menuLinks = []
            if (
                item?.licenseUUID &&
                licenseHelpers.hasOneOfLicenses(accountId, ["Mobile Security", "MDM"], "valid")
            ) {
                menuLinks.push(
                    new Button({
                        title: T("Edit"),
                        text: T("Edit"),
                        link:
                            "#edit-tenant-" +
                            tenantHelpers.getTenantDomain(accountId) +
                            "-vpn-" +
                            item?.vpnId,
                        icon: "fal fa-edit"
                    }),
                    new Button({
                        title: T("Copy"),
                        text: T("Copy"),
                        onClick: function () {
                            thisObjectType.saveToClipBoard(accountId, item)
                        },
                        icon: "fal fa-clone"
                    }),
                    new Button({
                        title: T("Export"),
                        text: T("Export"),
                        onClick: function () {
                            thisObjectType.exportObject(
                                tenantHelpers.getTenantDomain(accountId),
                                item?.vpnId
                            )
                        },
                        icon: "fal fa-download"
                    }),
                    new Button({
                        title: T("Download config"),
                        text: T("Download config"),
                        onClick: function () {
                            queries.mobileSecurity.downloadVPNConfig(
                                accountId,
                                item?.vpnId,
                                item?.vpnname
                            )
                        },
                        icon: "fal fa-download"
                    }),
                    new Button({
                        title: T("Download CA.crt"),
                        text: T("Download CA.crt"),
                        onClick: function () {
                            queries.mobileSecurity.downloadCA(accountId, item?.vpnId, "crt")
                        },
                        icon: "fal fa-download"
                    })
                )
            }
            menuLinks.push(
                new Button({
                    title: T("Delete"),
                    text: T("Delete"),
                    onClick: () => {
                        this.dialogs.getDeleteObjectDialog(accountId, item)
                    },
                    icon: "fal fa-trash"
                })
            )
            return menuLinks
        }
        this.itemlistItem.getDetails = (accountId, item) => {
            let thisDetails: ItemlistItemDetail[] = []
            let thisSSLInterceptionText = ""

            if (item?.ssl_bump_mode) {
                const bumpModes: any = {
                    default: "Default",
                    bump: "Always",
                    splice: "Never"
                }
                thisSSLInterceptionText = bumpModes[item.ssl_bump_mode]
            }

            thisDetails = [
                {
                    iconClass: "fal fa-fw fa-user",
                    title: T("SSL Interception"),
                    key: T("SSL Interception"),
                    value: item
                        ? T(thisSSLInterceptionText)
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-mobile-alt",
                    title: T("Allowlist #"),
                    key: T("Allowlist #"),
                    value: item
                        ? (item.cf_whitelist || []).length
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-cloud",
                    title: T("Blocklist #"),
                    key: T("Blocklist #"),
                    value: item
                        ? (item.cf_blacklist || []).length
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-sticky-note",
                    title: T("IP addresses #"),
                    key: T("IP addresses #"),
                    value: item
                        ? (item.exclude_routes || []).length
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                }
            ]

            if (item?.licenseInformation?.type != undefined) {
                thisDetails.push({
                    iconClass: "fal fa-fw fa-key",
                    title: T("License"),
                    key: T("License"),
                    value: item
                        ? undefined
                        : '<span class="content-placeholder" style="width:' +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          'px;" title="' +
                          T("Loading...") +
                          '"></span>',
                    labels: item
                        ? [
                              {
                                  text:
                                      item.licenseInformation.name +
                                      " (" +
                                      deviceHelpers.getShortDeviceId(
                                          String(item?.licenseInformation?.id)
                                      ) +
                                      ")",
                                  title:
                                      item.licenseInformation.name +
                                      " (" +
                                      deviceHelpers.getShortDeviceId(
                                          String(item?.licenseInformation?.id)
                                      ) +
                                      ")",
                                  onClick: function () {
                                      if ((<License>item?.licenseInformation)?.state == "valid") {
                                          router.navigate(
                                              "show-tenant-" +
                                                  accountId +
                                                  ".sms-license-" +
                                                  String(item.licenseInformation?.id) +
                                                  "-details"
                                          )
                                      }
                                  },
                                  displayType: "label"
                              }
                          ]
                        : undefined
                })
            } else {
                thisDetails.push({
                    iconClass: "fal fa-fw fa-key",
                    title: T("License"),
                    key: T("License"),
                    value: item
                        ? undefined
                        : '<span class="content-placeholder" style="width:' +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          'px;" title="' +
                          T("Loading...") +
                          '"></span>',
                    labels: item
                        ? [
                              {
                                  text: T("Not licensed"),
                                  title: T("Not licensed"),
                                  displayType: "label"
                              }
                          ]
                        : undefined
                })
            }
            return thisDetails
        }
        this.itemlistItem.onClick = (accountId, item) => {
            if (
                item?.licenseUUID != "" &&
                licenseHelpers.hasOneOfLicenses(accountId, ["Mobile Security", "MDM"], "valid") &&
                item?.vpnId
            ) {
                router.navigate("#edit-tenant-" + accountId + ".sms-vpn-" + item?.vpnId)
            }
        }

        /**
         * Converts Object for ObjectTypeStore
         */
        this.convertObjectForStore = (accountId, objectBase) => {
            if (objectBase.licenseUUID) {
                objectBase.licenseInformation = licenseHelpers.getLicenseByUUID(
                    accountId,
                    objectBase.licenseUUID
                )
            }
        }

        // #38347
        this.dialogs.getDeleteObjectDialog = (accountId, object, customerId, confirm = false) => {
            let singularOfObjectType = this.options.appearance.text.singular.toLocaleLowerCase()
            let objectIdProperty = this.options.objectTypeInfo.primaryKeyProperty.property
            let objectId = this.getObjectId(object)
            let objectName = this.itemlistItem.getTitle(
                object as ObjectTypeObject<Vpn>,
                undefined
            ).title
            let licenseId = object?.licenseUUID || ""
            miscDialogs.confirmDialog(
                accountId,
                T("Confirm delete"),
                T("Do you really want to delete this " + singularOfObjectType + "?") +
                    (objectName ? " (" + objectName + ")" : ""),
                async () => {
                    await this.queries.deleteObjectFromApi(accountId, objectId, customerId)
                    await products.unifiedSecurity.licenses.queries.getObjectFromApi(
                        accountId,
                        licenseId
                    )
                },
                confirm ? T("Delete") : undefined
            )
        }

        // #37977
        this.queries.addObjectToApi = async (
            accountId,
            object,
            customerId?,
            updateStore = true
        ) => {
            let result: Vpn | Error
            try {
                let response = await requestHandler.request(
                    this.options.apiInfo.addObjectMethod,
                    this.getAddObjectApiUrlPath(accountId, customerId),
                    object
                )
                response = apis.parseApiResponse(response)
                if (!jsonHelpers.isObjectEmpty(response)) {
                    result = object
                    if (response[this.options.objectTypeInfo.primaryKeyProperty.property]) {
                        object[this.options.objectTypeInfo.primaryKeyProperty.property] =
                            response[this.options.objectTypeInfo.primaryKeyProperty.property]
                    } else if (typeof response == "string") {
                        object[this.options.objectTypeInfo.primaryKeyProperty.property] = response
                    }
                } else {
                    throw "Error getting objects"
                }
                if (updateStore) {
                    await this.queries.getObjectsFromApi(accountId)
                }
                getterHelpers.useStore().dispatch(ActionTypes.updateLicenseQuota, accountId)
                return result
            } catch (e: any) {
                devLog.log("ObjectType", e.message, e, "error")
                throw e as Error
            }
        }
    }

    saveToClipBoard = (accountId: string, object: Vpn | any, addNotification: boolean = true) => {
        if (object) {
            if (typeof object == "object") {
                object = jsonHelpers.copyObject(object)
            }

            object.oldItemName = object.vpnname
            object.vpnname = object.vpnname + T("_Copy")
            delete object.vpnId
            delete object.tenantDomain

            useStore().commit(MutationTypes.setClipboard, {
                objectType: "vpns",
                objects: [object]
            })
            if (addNotification) {
                useStore().dispatch(ActionTypes.addNotification, {
                    accountId: accountId,
                    content: {
                        title: {
                            icon: "fal fa-exclamation-triangle",
                            text: "Clipboard"
                        },
                        body: {
                            content: "Added item to clipboard"
                        }
                    }
                })
            }
        }
    }

    exportObject = async (accountId: string, objectId: string) => {
        try {
            let object = (await this.queries.getObjectFromApi(accountId, objectId)) as any | Error
            if (!(object instanceof Error)) {
                let filename: string = (function () {
                    return object.vpnname
                })()
                let extension: string = "vpn"

                delete object.vpnname
                delete object.tenantDomain
                delete object.hasIcon
                delete object.checksums
                delete object.affectedDeviceIds
                delete object.potentiallyAffectedDeviceIds

                if (object.devices?.length) {
                    object.devices = []
                }
                if (object.roles?.length) {
                    object.roles = []
                }
                if (object.users?.length) {
                    object.users = []
                }
                object.vpnname = filename

                download(
                    new Blob([JSON.stringify(object)]),
                    `${filename}.${extension}`,
                    "application/json"
                )
            } else {
                throw object
            }
        } catch (e: any) {
            console.error(e)
        }
    }
}

const vpns = new Vpns({
    productType: "mobileSecurity",
    slug: "vpns",
    objectType: "vpns",
    hasStore: true,
    appearance: {
        iconClass: "fal fa-laptop",
        text: {
            plural: "Other devices (VPN)",
            title: "Other devices (VPN)",
            sidebarName: "Other devices (VPN)",
            singular: "Other device (VPN)"
        },
        color: "red",
        showInSidebar: true,
        showOnDashboard: true
    },
    objectTypeInfo: {
        primaryKeyProperty: {
            property: "vpnId",
            pathToPrimaryProperty: undefined
        },
        nameProperty: {
            primary: "vpnname",
            pathToPrimaryProperty: undefined,
            secondary: undefined,
            pathToSecondaryProperty: undefined
        }
    },
    apiInfo: {
        url: "/sms-mgt-api/api/1.1",
        getCountGETProperties: "?props[]=null&select=count",
        // GET
        getObjectListResponseProperty: "vpns",
        getObjectListMethod: "GET",
        getObjectListPath: "/tenants/{tenantDomain}/vpns",
        // Update
        updateObjectMethod: "PUT",
        updateObjectPath: "/tenants/{tenantDomain}/vpns/{objectId}"
    }
})

export default vpns
