import { useGlobalMixin } from "@/mixins/mixins.global"
import { nextTick } from "vue"
import loaderComponent from "../../components/loader.vue"
import addCertificateDialogComponent from "../../dialogs/add-certificate.vue"
import appInstallUninstallDialogComponent from "../../dialogs/app-install-uninstall-async"
import appleDepDialogComponent from "../../dialogs/apple-dep-async"
import applePushCertDialogComponent from "../../dialogs/apple-push-certificate-async"
import appleVPPLicenseAssignmentDialogComponent from "../../dialogs/apple-vpp-assignment.vue"
import appleVPPDialogComponent from "../../dialogs/apple-vpp-async"
import confirmDialogComponent from "../../dialogs/confirm-async"
import newUtmWebSessionDialogComponent from "../../dialogs/usc-websession.vue"
import utmLicenseConditionsDialogComponent from "../../dialogs/utm-license-conditions-async"
import newScanDialogComponent from "../../dialogs/utm-new-scan-async"
import utmPrivacyPolicyDialogComponent from "../../dialogs/utm-privacy-policy-async"
import utmRestoreBackupDialogComponent from "../../dialogs/utm-restore-backup-async"
import utmsCheckForUpdatesDialogComponent from "../../dialogs/utms-check-for-updates-async"

import cookies from "@/classes/cookieHandler"
import addEditCoreDns from "@/templates/dialogs/add-edit-core-dns.vue"
import tooltip from "../../components/tooltip.vue"
import addAndEditCoreUtm from "../../dialogs/add-edit-core-utm.vue"
import addAndEditRule from "../../dialogs/add-edit-rule.vue"
import addAndEditSatellite from "../../dialogs/add-edit-satellite.vue"
import addGmailAddress from "../../dialogs/add-gmail-address.vue"
import addNetworkObject from "../../dialogs/add-network-object.vue"
import addServiceComponent from "../../dialogs/add-service.vue"
import androidEnrollmentComponent from "../../dialogs/android-enrollment.vue"
import androidLostMode from "../../dialogs/android-lost-mode.vue"
import appleUpdateDialogComponent from "../../dialogs/apple-update-device"
import uncPublishChanges from "../../dialogs/asc-publish-changes.vue"
import assignDefaultMdmLicense from "../../dialogs/assign-mdm-default-license.vue"
import getBrandingPreviewComponent from "../../dialogs/branding-preview.vue"
import deanonymizeDeviceDialogComponent from "../../dialogs/deanonymize-device-async"
import deleteCoreUtm from "../../dialogs/delete-core-utm.vue"
import depProfileAssignment from "../../dialogs/dep-profile-assignment"
import deviceLocate from "../../dialogs/device-locate.vue"
import deviceSetupDialogComponent from "../../dialogs/device-setup-async.vue"
import disabledSelectDialogComponent from "../../dialogs/disabled-select-dialog.vue"
import editCertificateComponent from "../../dialogs/edit-certificate.vue"
import iosEnrollmentDialogComponent from "../../dialogs/enroll-ios-device.vue"
import enrollWindowsVpns from "../../dialogs/enroll-windows-vpns.vue"
import devicePasswordResetComponent from "../../dialogs/enterprise.device.passreset"
import userDialogComponent from "../../dialogs/import-users.vue"
import inputDialogComponent from "../../dialogs/input-async"
import internalServerError from "../../dialogs/internal-server-error.vue"
import androidInviteDialogComponent from "../../dialogs/invite-android-device-async"
import iosInviteDialogComponent from "../../dialogs/invite-ios-device.vue"
import inviteSecureDns from "../../dialogs/invite-securedns.vue"
import keyAppStatesComponent from "../../dialogs/key-app-states"
import licenseAssignmentDialogComponent from "../../dialogs/license-assignment-dialog-async"
import linkToIdentityProviderDialogComponent from "../../dialogs/link-to-identityprovider-async"
import portalUpdateComponent from "../../dialogs/portal-update.vue"
import renamePoliciesOnPasteComponent from "../../dialogs/rename-policies-onpaste.vue"
import secureDnsExceptionRule from "../../dialogs/secure-dns-exception-rule.vue"
import secureDnsEndpointGenerator from "../../dialogs/securedns-endpoint-generator.vue"
import setInventoryLocationDialogComponent from "../../dialogs/set-inventory-location-async"
import setPasswordComponent from "../../dialogs/set-new-password"
import showReportsDialogComponent from "../../dialogs/show-reports.vue"
import templatePolicyProgess from "../../dialogs/template-policy-progess.vue"
import uscOneTimeUpdate from "../../dialogs/usc-one-time-update.vue"
import uscProfilesManyRequestsDialogComponent from "../../dialogs/uscProfiles-many-requests-async"
import utmEnterPinComponent from "../../dialogs/utm-enter-pin.vue"
import utmForceUpdatesComponent from "../../dialogs/utm-force-updates.vue"
import utmLicenseConditionsPlain from "../../dialogs/utm-license-conditions-plain.vue"
import utmPrivacyPolicyPlain from "../../dialogs/utm-privacy-policy-plain.vue"
import windowsLicenseConditions from "../../dialogs/windows-license-conditions.vue"
import windowsPrivacyPolicy from "../../dialogs/windows-privacy-policy.vue"
import windowsVpnConnectionDiagnosis from "../../dialogs/windows-vpn-connection-diagnosis.vue"
import connectionRemove from "../../dialogs/windowsVpn-connection-remove.vue"
import windowsVpnConnectionLog from "../../dialogs/windowsvpn-log.vue"
import inputManagedPropertyConfiguratorComponent from "../../inputtypes/input-managed-property-configurator-async"
import mediabrowserComponent from "../mediabrowser.vue"
import uscNetworkobjectTable from "../uscNetworkobjectTable/usc-networkobject-table.vue"

import getterHelpers from "@/helpers/helpers.getters"
import tenantHelpers from "@/helpers/helpers.tenants"
import { MutationTypes } from "@/store/vuex.store"
import ModalObject, { type Modal } from "./modalClass"

const modalComponent: any = {
    mixins: [useGlobalMixin()],
    name: "modal",
    template: `
        <div class="modal" ref="modal">
            <div class="modal-dialog" :style="maximized ? 'margin:0; max-width:100%; height:100%;' : ''">
                <div class="modal-content" :style="modalContentStyle">
                    <div class="modal-header" v-if="modal.content && modal.content.title">
                        <h4 class="modal-title float-xs-left">
                            <i v-if="modal.content.title.icon" class="fa-fw" :class="modal.content.title.icon"></i>
                            {{ T(modal.content.title.text) }}
                        </h4>&nbsp;
                        <button type="button" v-if="modal.buttons.length == 0 && modal.abortable" class="close" v-on:click="abort()">
                            <span>
                                <i class="fal fa-times"></i>
                            </span>
                        </button>
                        <button v-if="modal.canUseFullscreen == true" type="button" class="maximize" v-on:click="maximized = !maximized">
                            <span>
                                <template v-if="maximized">
                                    <i :class="{ 'fal fa-window-restore': true, 'color-gray': darkmode == '1' }"></i>
                                </template>
                                <template v-else>
                                    <i :class="{ 'fal fa-window-maximize': true, 'color-gray': darkmode == '1' }"></i>
                                </template>
                            </span>
                        </button>
                    </div>
                    <div 
                        class="modal-body" 
                        v-if="modal.content && modal.content.body && modal.content.body.use" 
                        :style="modalBodyStyle"
                    >
                            <div v-if="modal.content.body.content" v-html="modal.content.body.content"></div>
                            <component v-if="modal.content.body.component" :is="modal.content.body.component" ref="modalComponent" :properties="modal.content.body.properties" :maximized="maximized" />
                    </div>
                    <template v-else>
                        <template v-if="modal.content && modal.content.body">
                            <div v-if="modal.content.body.content" v-html="modal.content.body.content" :style="modal.content.body.maxHeight ? ('max-height:'+modal.content.body.maxHeight+'px') : undefined"></div>
                            <component v-if="modal.content.body.component" :is="modal.content.body.component" ref="modalComponent" :properties="modal.content.body.properties" :maximized="maximized" />
                        </template>
                    </template>
                    <template v-if="isSuspendable">
                        <hr>
                        <div class="box-footer content-2">
                            <div class="row flexrow">
                                <div class="col-xs padding-xs-x text-center">
                                    <label class="input-checkbox checkbox">
                                        <input type="checkbox" v-model="suspend"><span></span><span>&nbsp;{{ T("Don't show this message again.") }}
                                        <tooltip
                                            class="text-left"
                                            :tooltip="T('This dialog will be hidden for the next 45 days.') + '<br>' + T('If you have already hidden other dialogs, these will also only be displayed again after the 45 days have expired.')"
                                            :is-tag="'span'"
                                            :htmlTooltip="true"
                                        >
                                            <i class="fal fa-info-circle"></i>
                                        </tooltip></span>
                                   
                                    </label>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="modal.buttons">
                        <hr>
                        <div class="box-footer content-2">
                            <div class="row flexrow">
                                <div class="col-xs padding-xs-x text-center" id="modalButtonWrapper">
                                    <template v-for="button,i in modal.buttons">
                                        <button
                                            :id="'dialogbutton-'+i+'-'+button.id"
                                            class="btn btn-default btn-loader twist-in margin-xs-x"
                                            :class="{
                                                'btn-loading':button.loading,
                                                'disabled':button.disabled
                                            }"
                                            :disabled="button.disabled || null"
                                            v-on:click="buttonOnClick(button)"
                                            @keyup.enter="buttonOnClick(button)"
                                            :autofocus="button.focusOnMount ? true : null"
                                        >
                                            <span class="animate">
                                                <loader style="top:-3px;" class="text-size-2"/>
                                            </span>
                                            <span>
                                                <template v-if="button.icon">
                                                    <i class="fa-fw" :class="button.icon"></i>
                                                </template>
                                                {{ T(button.text) }}
                                            </span>
                                        </button>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    `,
    data() {
        return {
            maximized: false,
            suspend: false
        }
    },
    props: {
        modal: {
            required: true,
            default: () => {
                return <Modal | ModalObject>{
                    id: "",
                    abortable: true,
                    canUseFullscreen: false,
                    content: {
                        title: {
                            text: "",
                            icon: "fal fa-info-circle"
                        },
                        body: {
                            use: true,
                            content: "",
                            maxHeight: undefined,
                            component: undefined,
                            properties: undefined
                        }
                    },
                    buttons: [
                        {
                            text: "Close",
                            icon: "fal fa-times",
                            onClick: function () {
                                this.loading = true
                                getterHelpers.useStore().commit(MutationTypes.removeModal)
                            },
                            align: "left",
                            loading: false
                        },
                        {
                            text: "OK",
                            icon: "fal fa-check",
                            onClick: function () {
                                this.loading = true
                                setTimeout(function () {
                                    getterHelpers.useStore().commit(MutationTypes.removeModal)
                                }, 1000)
                            },
                            align: "right",
                            loading: false
                        }
                    ],
                    onInit: undefined,
                    onAbort: undefined
                }
            }
        }
    },
    computed: {
        isSuspendable(this: any) {
            return cookies.dialogSuspender.suspendedDialogsFlagMap.indexOf(this.modal.id) != -1
        },
        modalContentStyle(this: any) {
            if (this.maximized == true) {
                return "height:100%"
            } else {
                return ""
            }
        },
        modalBodyStyle(this: any) {
            if (this.maximized != true && this?.content?.body?.maxHeight) {
                return "max-height:" + this.content.body.maxHeight + "px"
            } else if (this.maximized == true) {
                // 116,5 = 48px (Modal-Header) + 68,5px (Modal-Footer) + 5 ( Some space for scrollbar )
                const height = window.innerHeight - 121.5
                return "height: " + height + "px; max-height: " + height + "px"
            } else {
                return ""
            }
        }
    },
    methods: {
        removeModal: function (this: any) {
            if ((<ModalObject>this.modal)?.delete) {
                this.modal.delete()
            } else {
                if (this.modal.accountId) {
                    getterHelpers.useStore().commit(MutationTypes.removeModal, {
                        accountId: tenantHelpers.getAccountId(this.modal.accountId)
                    })
                } else {
                    getterHelpers.useStore().commit(MutationTypes.removeModal)
                }
            }
        },
        abort: function (this: any) {
            if (this.$refs.modalComponent && this.$refs.modalComponent.initialized != undefined) {
                this.$refs.modalComponent.initialized = false
                if (this.$refs.modalComponent.loaderText != undefined) {
                    this.$refs.modalComponent.loaderText = "Closing..."
                }
            }
            nextTick(() => {
                if (this.onAbort != undefined) {
                    this.onAbort(this)
                }
                this.removeModal()
            })
        },
        buttonOnClick(this: any, button: any) {
            if (
                this.suspend == true &&
                this.modal.id != undefined &&
                cookies.dialogSuspender.suspendedDialogsFlagMap.indexOf(this.modal.id) != -1
            ) {
                cookies.dialogSuspender.setSuspendedDialog(this.modal.id, true) // suspend dialog
            }
            if (typeof button.onClick == "function") {
                button.onClick(this, this.modal)
            }
        },
        getMaximizedState: function (this: any) {
            return this.maximized
        }
    },
    mounted: function (this: any) {
        if (typeof this.onInit == "function") {
            this.onInit(this)
        }
        nextTick(() => {
            if (
                this.buttons &&
                (<Modal["buttons"]>this.buttons || []).some((button) => {
                    return button.focusOnMount
                })
            ) {
                let buttonIndex = (<Modal["buttons"]>this.buttons || []).findIndex((button) => {
                    return button.focusOnMount
                })
                let buttonWrapper = document.getElementById("modalButtonWrapper")
                if (buttonIndex != -1) {
                    let buttonElement = buttonWrapper?.children[buttonIndex]
                    if (buttonElement) {
                        ;(<HTMLElement>buttonElement).focus()
                    }
                }
            }
        })
    },
    watch: {
        maximized: function (this: any) {
            if (typeof this?.$refs?.modalComponent?.calcHeight == "function") {
                this.$refs.modalComponent.calcHeight()
            }
        }
    },
    components: {
        loader: loaderComponent,
        "confirm-dialog": confirmDialogComponent,
        "new-scan-dialog": newScanDialogComponent,
        "new-utm-websession-dialog": newUtmWebSessionDialogComponent,
        "utm-license-conditions-dialog": utmLicenseConditionsDialogComponent,
        "utm-privacy-policy-dialog": utmPrivacyPolicyDialogComponent,
        "utm-restore-backup-dialog": utmRestoreBackupDialogComponent,
        "utms-check-for-updates-dialog": utmsCheckForUpdatesDialogComponent,
        "apple-push-cert-dialog": applePushCertDialogComponent,
        "apple-dep-dialog": appleDepDialogComponent,
        "apple-vpp-dialog": appleVPPDialogComponent,
        "ios-enrollment-dialog": iosEnrollmentDialogComponent,
        "android-enrollment-dialog-new": androidEnrollmentComponent,
        "ios-invite-dialog": iosInviteDialogComponent,
        "android-invite-dialog": androidInviteDialogComponent,
        "device-setup-dialog": deviceSetupDialogComponent,
        "deanonymize-device-dialog": deanonymizeDeviceDialogComponent,
        mediabrowser: mediabrowserComponent,
        "input-dialog": inputDialogComponent,
        "set-inventory-location-dialog": setInventoryLocationDialogComponent,
        "app-install-uninstall-dialog": appInstallUninstallDialogComponent,
        "add-certificate-dialog": addCertificateDialogComponent,
        "show-reports-dialog": showReportsDialogComponent,
        "input-managed-property-configurator": inputManagedPropertyConfiguratorComponent,
        "apple-vpp-license-assignment-dialog": appleVPPLicenseAssignmentDialogComponent,
        "link-to-identityprovider-dialog": linkToIdentityProviderDialogComponent,
        "uscProfiles-many-requests-dialog": uscProfilesManyRequestsDialogComponent,
        "license-assignment-dialog": licenseAssignmentDialogComponent,
        "get-branding-preview-dialog": getBrandingPreviewComponent,
        "apple-update-dialog": appleUpdateDialogComponent,
        "rename-policies-onpaste": renamePoliciesOnPasteComponent,
        "dep-profile-assignment": depProfileAssignment,
        "set-password": setPasswordComponent,
        "key-app-states": keyAppStatesComponent,
        "device-password-reset": devicePasswordResetComponent,
        "disabled-select-dialog": disabledSelectDialogComponent,
        "import-users": userDialogComponent,
        "portal-updates-dialog": portalUpdateComponent,
        "utm-force-update": utmForceUpdatesComponent,
        "utm-enter-pin": utmEnterPinComponent,
        "edit-certificate-dialog": editCertificateComponent,
        "usc-one-time-update": uscOneTimeUpdate,
        tooltip: tooltip,
        "add-gmail-address": addGmailAddress,
        "add-edit-core-utm": addAndEditCoreUtm,
        "add-edit-satellite": addAndEditSatellite,
        "add-edit-rule": addAndEditRule,
        enrollWindowsVpnClients: enrollWindowsVpns,
        "delete-core-utm": deleteCoreUtm,
        "asc-publish-changes": uncPublishChanges,
        "add-service": addServiceComponent,
        "add-network-object": addNetworkObject,
        "windows-vpn-log": windowsVpnConnectionLog,
        "windows-vpn-connection-diagnosis": windowsVpnConnectionDiagnosis,
        "windows-vpn-connection-remove": connectionRemove,
        "android-lost-mode": androidLostMode,
        "device-locate": deviceLocate,
        "securedns-endpoint-generator": secureDnsEndpointGenerator,
        "utm-license-conditions-plain": utmLicenseConditionsPlain,
        "utm-privacy-policy-plain": utmPrivacyPolicyPlain,
        uscNetworkobjectTable: uscNetworkobjectTable,
        "add-edit-core-dns": addEditCoreDns,
        "invite-securedns": inviteSecureDns,
        "internal-server-error": internalServerError,
        "secure-dns-exception-rule": secureDnsExceptionRule,
        "assign-mdm-default-license": assignDefaultMdmLicense,
        "template-policy-progress": templatePolicyProgess,
        "windows-license-conditions": windowsLicenseConditions,
        "windows-privacy-policy": windowsPrivacyPolicy
    }
}

export default modalComponent
