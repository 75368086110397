<script setup lang="ts">
import config from "@/classes/config"
import i18n, { T } from "@/classes/i18n"
import objectStores from "@/classes/init"
import frontendNotifications from "@/classes/notifications"
import products from "@/classes/objectTypes"
import { type UtmProfile } from "@/classes/objectTypes/unifiedSecurityConsole/uscProfiles"
import type { UscUtm as UscUtmNew } from "@/classes/objectTypes/unifiedSecurityConsole/uscUtms"
import { type UscUtmState } from "@/classes/objectTypes/unifiedSecurityConsole/uscUtmStates"
import type { UscProfile } from "@/classes/unifiedSecurityConsole/uscProfiles"
import type { CanUseFeatureNames, UscUtm } from "@/classes/unifiedSecurityConsole/uscUtms"
import dialogs from "@/dialogs/dialogs"
import encodingHelpers from "@/helpers/helpers.encoding"
import getterHelpers from "@/helpers/helpers.getters"
import numberHelpers from "@/helpers/helpers.numbers"
import sessionHelpers from "@/helpers/helpers.session"
import stringHelpers from "@/helpers/helpers.strings"
import tenantHelpers from "@/helpers/helpers.tenants"
import timeHelpers from "@/helpers/helpers.time"
import validationHelpers from "@/helpers/helpers.validation"
import queries from "@/queries/queries"
import requestHandler from "@/queries/requests"
import applicationNames from "@/resources/usc/applicationNames"
import scanPorts from "@/resources/usc/scanPorts"
import router from "@/router/router"
import useRouterStore from "@/router/routerStore"
import { ActionTypes, MutationTypes, useStore } from "@/store/vuex.store"
import Button from "@/templates/components/button/button"
import Icon from "@/templates/components/icon/icon"
import Label from "@/templates/components/label/label"
import tabsComponent from "@/templates/components/tabs.vue"
import Chart from "chart.js/auto"
import moment from "moment"
import { sprintf } from "sprintf-js"
import { computed, nextTick, onBeforeUnmount, onMounted, ref, useTemplateRef, watch } from "vue"
import jsonHelpers from "../../helpers/helpers.json"
import buttonComponent from "../components/button/button.vue"
import itemlistItem from "../components/itemlist-item.vue"
import loader from "../components/loader.vue"
import googleMapComponent from "../components/map"
import table2Component from "../components/table2"
import tableNext, { type TableEntryInfo } from "../components/tableNext.vue"
import cronojoblistComponent from "../components/usc-crono-joblist.vue"
import Tooltip from "../directives/tooltip/tooltip"
import inputDate from "../inputtypes/input-date.vue"
import inputNumber from "../inputtypes/input-number.vue"
type RiskStatus = "never" | "none" | "medium" | "high" | "no license" | "loading"
const LANG = i18n.getLanguage()
const dateFormat = timeHelpers.getDateFormatI18n(false)

// Computed

const activeAccountId = computed(() => {
    return getterHelpers.useStore()?.state.session.activeAccountId || ""
})
const objectId = computed(() => {
    return useRouterStore().getObjectId || ""
})
const darkmode = computed(() => {
    return useStore().state.browserSettings.darkmode == "1"
})
const isReseller = computed(() => {
    return useStore().state.session.isReseller
})

const utm = computed(() => {
    refreshCount.value
    if (config.canUseNewObjectType("uscUtms")) {
        return products.unifiedSecurityConsole.uscUtms
            .useStore?.()
            .getObjectStoreObject(activeAccountId.value, objectId.value)
    }
    return useStore().getters.getObject({
        accountId: activeAccountId.value,
        productType: "unifiedSecurityConsole",
        objectType: "uscUtms",
        objectId: objectId.value || ""
    })
})
const utmStates = computed(() => {
    if (config.canUseNewObjectType("uscUtmStates")) {
        return (
            products.unifiedSecurityConsole.uscUtmStates
                .useStore?.()
                .getObjectStoreObjects(activeAccountId.value) || []
        )
    }
    return (useStore().getters.getObjects({
        accountId: activeAccountId.value,
        productType: "unifiedSecurityConsole",
        objectType: "ccutmStates"
    }) || []) as UscUtmState[]
})
const canUseUscJobs = computed(() => {
    return true
})
const utmStatus = computed(() => {
    let result: UscUtmState | undefined = undefined
    if (config.canUseNewObjectType("uscUtmStates")) {
        return (products.unifiedSecurityConsole.uscUtmStates
            .useStore?.()
            .getObjectStoreObject(activeAccountId.value, objectId.value) ||
            undefined) as UscUtmState
    } else {
        if ((utmStates.value || []).length) {
            result = ((utmStates.value.filter((state: any) => {
                return objectId.value == state.deviceId
            }) || [])[0] || undefined) as UscUtmState
        }
    }
    return result
})
const isRebooting = computed(() => {
    let result: boolean = false
    let lastFeatureStatus: any = getLastDeviceActionStatusFromOperationLog("system reboot")
    if (lastFeatureStatus == "REBOOTING" || lastFeatureStatus == "PENDING") {
        result = true
    }
    return result
})
const canUseWebsession = computed(() => {
    return sessionHelpers.hasOneOfScopes(["usc:administration", "usc:monitoring"])
})
const mergedOperationsLog = computed(() => {
    return (<any[]>[]).concat(operationsLog.value || [], profilePublishMessages.value || [])
})
const shouldDisplayLock = computed(() => {
    return (
        validationHelpers.versionCompare(
            getUtmMessageData(utm.value, "spcli-system-info")?.version || "0",
            config.unifiedSecurityConsole.utmVersionForPin
        ) !== -1
    )
})

// Data
const initialized = ref(<boolean>false)
const loadingCluster = ref(<boolean>false)
const tabChanged = ref(<boolean>false)
const loaderText = ref("Loading...")
const gettingHistory = ref(<boolean>false)
const rrdLoaded = ref(<boolean>false)
const rrdInfo = ref(<any>{
    cpu0Info: undefined,
    loadInfo: undefined,
    memoryInfo: undefined,
    dfDataInfo: undefined,
    timestamp: 0
})
const charts = ref({
    cpu0Chart: <any>undefined,
    loadChart: <any>undefined,
    memoryChart: <any>undefined,
    dfDataChart: <any>undefined
})

const activeTab = ref("overview")
const refreshCount = ref(0)
const refreshCountDetails = ref(0)
const applicationsTableData = ref({
    loading: true,
    tableData: <any>[]
})

const profilePublishMessages = ref(<any[]>[])
const operationsLog = ref(<any[]>[])
const operationsLogTableData = ref({
    loading: false,
    operationsLogSelectableColumns: <TableEntryInfo[]>[
        {
            property: "timestamp",
            text: T("Time"),
            displayType: "text",
            getSortValue: (message: any) => {
                return message?.issued || message?.created || message.lastUpdate || ""
            },
            getValue: (message: any) => {
                let date = message?.issued || message?.created || message.lastUpdate || ""
                return date != ""
                    ? timeHelpers.formatDate(date * 1000, timeHelpers.getDateFormatI18n(true))
                    : date
            }
        },
        {
            property: "type",
            text: T("Job"),
            displayType: "text",
            getValue: (message: any) => {
                let result: string
                if (
                    message.isProfilePublishMessage == true &&
                    message.content?.payload?.clientContext
                ) {
                    result = stringHelpers.capitalizeFirstLetter(
                        message.content.payload.clientContext
                            .split("-")
                            .slice(1)
                            .join(" ")
                            .toLowerCase()
                    )
                } else {
                    result =
                        message.context ||
                        (message.type
                            ? stringHelpers.capitalizeFirstLetter(
                                  message.type.replaceAll("_", " ").toLowerCase()
                              )
                            : "")
                }
                return result
            },
            getSortValue: (message: any) => {
                let result: string
                if (
                    message.isProfilePublishMessage == true &&
                    message.content?.payload?.clientContext
                ) {
                    result = stringHelpers.capitalizeFirstLetter(
                        message.content.payload.clientContext
                            .split("-")
                            .slice(1)
                            .join(" ")
                            .toLowerCase()
                    )
                } else {
                    result =
                        message.context ||
                        (message.type
                            ? stringHelpers.capitalizeFirstLetter(
                                  message.type.replaceAll("_", " ").toLowerCase()
                              )
                            : "")
                }
                return result
            }
        },
        {
            property: "profile",
            text: T("Profile"),
            displayType: "labels",
            getValue: (message: any) => {
                let uscProfile: undefined | UscProfile | UtmProfile = undefined
                if (message?.content?.payload?.profileId) {
                    uscProfile = config.canUseNewObjectType("uscProfiles")
                        ? products.unifiedSecurityConsole.uscProfiles
                              .useStore?.()
                              .getObjectStoreObject(
                                  activeAccountId.value,
                                  message?.content?.payload?.profileId
                              )
                        : objectStores.uscProfiles.getObjectFromStore(
                              activeAccountId.value,
                              message?.content?.payload?.profileId
                          )
                }
                if (uscProfile != undefined) {
                    return [
                        new Label({
                            icon: new Icon({ class: "" }),
                            text: uscProfile?.name ? uscProfile.name : "",
                            title: uscProfile?.name ? uscProfile.name : "",
                            onClick: () => {
                                if (uscProfile != undefined) {
                                    router.navigate(
                                        "edit-tenant-" +
                                            tenantHelpers.getTenantDomain(activeAccountId.value) +
                                            "-usc-profiles-" +
                                            uscProfile.id
                                    )
                                }
                            }
                        })
                    ]
                }
                return []
            },
            getSortValue: (message: any) => {
                let uscProfile: undefined | UscProfile | UtmProfile = undefined
                if (message?.content?.payload?.profileId) {
                    uscProfile = config.canUseNewObjectType("uscProfiles")
                        ? products.unifiedSecurityConsole.uscProfiles
                              .useStore?.()
                              .getObjectStoreObject(
                                  activeAccountId.value,
                                  message?.content?.payload?.profileId
                              )
                        : objectStores.uscProfiles.getObjectFromStore(
                              activeAccountId.value,
                              message?.content?.payload?.profileId
                          )
                }
                if (uscProfile != undefined) {
                    return uscProfile?.name ? uscProfile.name : ""
                }
                return ""
            }
        },
        {
            property: "direction",
            text: T("Direction"),
            displayType: "labels",
            getValue: (message: any) => {
                return [
                    new Label({
                        icon: new Icon({
                            class:
                                message?.queue == "in" ? "fal fa-arrow-left" : "fal fa-arrow-right"
                        }),
                        text: T(stringHelpers.capitalizeFirstLetter(message?.queue)),
                        title:
                            message?.queue == "in"
                                ? T("Message from device to server")
                                : T("Message from server to device")
                    })
                ]
            },
            getSortValue: (message: any) => {
                return message?.queue == "in"
                    ? T("Message from device to server")
                    : T("Message from server to device")
            }
        },
        {
            property: "status",
            text: T("xState"),
            displayType: "labels",
            getValue: (message: any) => {
                return [
                    new Label({
                        title: message?.statusText
                            ? message?.statusText
                            : T(stringHelpers.capitalizeFirstLetter(message?.status)),
                        text: T(stringHelpers.capitalizeFirstLetter(message?.status)),
                        class:
                            message?.status == "ERROR"
                                ? "bg-red"
                                : message?.status == "PENDING"
                                  ? "bg-yellow"
                                  : "",
                        icon: new Icon({
                            class:
                                message?.status == "ERROR"
                                    ? "fal fa-exclamation-triangle"
                                    : message?.status == "PENDING"
                                      ? "fal fa-bell"
                                      : "fal fa-info"
                        })
                    })
                ]
            },
            getSortValue: (message: any) => {
                return T(stringHelpers.capitalizeFirstLetter(message?.status))
            }
        },
        {
            property: "statusText",
            text: T("Info"),
            displayType: "text",
            getValue: (message: any) => {
                return T(message.statusText) || ""
            },
            getSortValue: (message: any) => {
                return T(message.statusText) || ""
            }
        },
        {
            property: "actions",
            text: T("Actions"),
            displayType: "buttons",
            getValue: (message: any) => {
                let thisbuttons: any = []
                if (message.status == "PENDING" && !message?.content?.payload?.profileId) {
                    thisbuttons.push({
                        text: T("Revoke"),
                        title: T("Revoke"),
                        icon: "fal fas-fw fa-undo",
                        onClick: async function () {
                            if (config.canUseNewObjectType("uscUtms")) {
                                await products.unifiedSecurityConsole.uscUtms.revokeJob(
                                    activeAccountId.value,
                                    objectId.value,
                                    message.jobId
                                )
                            } else {
                                await objectStores.uscUtms.revokeJob(
                                    activeAccountId.value,
                                    objectId.value,
                                    message.jobId
                                )
                            }

                            getOperationsLogData()
                        },
                        disabled: function () {},
                        loading: function () {}
                    })
                }
                return thisbuttons
            }
        }
    ]
})

const utmVars = ref(<any>{
    backups: <any[]>[],
    state: {
        loading: true,
        period: "today",
        rejectsHistogramm: {
            loading: true
        },
        totalRejects: {
            loading: true,
            value: undefined
        },
        latestRejects: {
            loading: true,
            tableData: {
                rows: [],
                titles: []
            }
        }
    }
})
const makingBackUp = ref(<boolean>false)
const backupsTableData = ref({
    loading: true
})
const savingInventory = ref(<boolean>false)
const inventoryForm = ref({
    billNumber: <undefined | string>"",
    billDate: <undefined | string>"",
    hardwareDescription: <undefined | string>"",
    providerName: <undefined | string>"",
    providerTariff: <undefined | string>"",
    contractDate: <undefined | string>"",
    contractDuration: <undefined | string>"",
    handoverDate: <undefined | string>"",
    contractNumber: <undefined | string>"",
    providerCustomerNumber: <undefined | string>"",
    warrantyStartDate: <undefined | string>"",
    warrantyEndDate: <undefined | string>"",
    inventoryExternalLink: <undefined | string>"",
    inventoryLocationDescription: <undefined | string>"",
    inventoryLocation: <undefined | any>undefined
})
const widgets = ref({
    period: "currentmonth",
    blockedThreats: {
        title: "Blocked threats",
        riskStatus: <RiskStatus>"loading",
        data: {
            HttpVirus: 0,
            MailVirus: 0,
            KernelLog: 0,
            KernelReject: 0,
            HttpUrlBlocked: 0,
            MailUrlBlocked: 0
        }
    },
    antivirusClamStatus: {
        title: "Antivirus",
        riskStatus: <RiskStatus>"loading",
        text: "",
        data: {
            LastUpdate: <any>undefined,
            State: <any>undefined
        }
    },
    antivirusCtavStatus: {
        title: "Antivirus CT",
        riskStatus: <RiskStatus>"loading",
        text: "",
        data: {
            LastUpdate: <any>undefined,
            State: <any>undefined
        }
    },
    kernelStatus: {
        title: "Kernel",
        riskStatus: <RiskStatus>"loading",
        dropsText: "",
        rejectsText: "",
        data: {
            Drops: <any>undefined,
            Rejects: <any>undefined
        }
    },
    alertStatus: {
        title: "Alerts",
        riskStatus: <RiskStatus>"loading",
        text: "",
        data: {
            Risk: <any>undefined,
            NoRisk: <any>undefined
        }
    },
    httpTrafficStatus: {
        title: "Traffic HTTP",
        riskStatus: <RiskStatus>"loading",
        text: "",
        data: {
            Traffic: <any>undefined
        }
    },
    loginStatus: {
        title: "Failed logins",
        riskStatus: <RiskStatus>"loading",
        text: "",
        data: {
            Logins: <any>undefined
        }
    }
})

// METHODS

const cronojoblistRef = useTemplateRef("cronojoblistRef")
const getCronoJobsList = async function () {
    gettingHistory.value = true
    await cronojoblistRef.value?.getJobList?.()
    gettingHistory.value = false
}

const getPlanSingleUpdateDialog = function () {
    let existingDate = undefined
    if (cronojoblistRef.value?.thisData?.[0] != undefined) {
        let lastUpdateEntry = cronojoblistRef.value.thisData.find((entry: any) => {
            return entry.type == "utm/update" && entry.status == "SCHEDULED"
        })
        if (lastUpdateEntry && lastUpdateEntry.nextTrigger > moment().unix()) {
            existingDate = moment(lastUpdateEntry.nextTrigger * 1000).format("DD.MM.YYYY HH:mm")
        }
    }
    if (config.canUseNewObjectType("uscUtms")) {
        return products.unifiedSecurityConsole.uscUtms.getPlanSingleUpdateDialog(
            activeAccountId.value,
            objectId.value,
            existingDate
        )
    } else {
        objectStores.uscUtms.getPlanSingleUpdateDialog(
            activeAccountId.value,
            objectId.value,
            existingDate
        )
    }
}

const getTooltip = (reasons: Array<string> | string | undefined) => {
    let reasonString: string | undefined = undefined
    if (reasons && Array.isArray(reasons) && reasons.length > 0) {
        reasonString = ""
        reasons.forEach((reason) => {
            reasonString = reason + ", " + reasonString
        })
        reasonString = reasonString.slice(0, -2)
    } else if (typeof reasons == "string") {
        reasonString = reasons
    }
    return reasonString
}

const getLaasState = function (utm: UscUtm) {
    if (config.canUseNewObjectType("uscUtms")) {
        return products.unifiedSecurityConsole.uscUtms.getLaasState(utm as UscUtmNew)
    }
    return objectStores.uscUtms.getLaasState(utm)
}
const tabsRef = useTemplateRef("tabs")
const setActiveTab = function (tabName: string) {
    tabsRef.value?.setActiveTab(tabName)
    tabChanged.value = true
}

const goToClusterUtm = async function () {
    try {
        loadingCluster.value = true
        let partnerUtm: UscUtm | undefined = undefined

        let result: any | undefined
        if (config.canUseNewObjectType("uscUtms")) {
            result = await products.unifiedSecurityConsole.uscUtms.queries.getObjectsFromApi(
                activeAccountId.value,
                undefined,
                [
                    { property: "props[]", value: "utmId" },
                    { property: "props[]", value: "cluster" },
                    { property: "props[]", value: "license" },
                    { property: "props[]", value: "permissions" },
                    { property: "props[]", value: "pinState" },
                    { property: "props[]", value: "sunTopologyIds" }
                ]
            )
        } else {
            result = await useStore().dispatch(ActionTypes.getObjectInfos, {
                accountId: activeAccountId.value,
                objectTypes: ["uscUtms?props[]=utmId&props[]=cluster&props[]=license"]
            })
        }

        if (result) {
            result = undefined
            if (config.canUseNewObjectType("uscUtms")) {
                result = products.unifiedSecurityConsole.uscUtms
                    .useStore?.()
                    .getObjectStoreObjects(activeAccountId.value)
            } else {
                result = useStore().getters.getObjects({
                    accountId: activeAccountId.value,
                    productType: "unifiedSecurityConsole",
                    objectType: "uscUtms"
                })
            }
            if (result && Array.isArray(result)) {
                partnerUtm = result.find((uscUtm: UscUtm) => {
                    return (
                        uscUtm.license?.clusterUuid === utm.value?.license?.clusterUuid &&
                        uscUtm.utmId !== objectId.value
                    )
                })
            }
            if (partnerUtm) {
                router.navigate(
                    "show-tenant-" +
                        tenantHelpers.getTenantDomain(activeAccountId.value) +
                        "-usc-utms-" +
                        partnerUtm.utmId
                )
            } else {
                throw new Error(T("No other cluster UTM for this cluster could be found"))
            }
        } else {
            throw new Error(T("An Error occurred"))
        }
    } catch (e: unknown) {
        console.error(e)
        let message: string = T("An Error occurred")
        if (e instanceof Error) message = T(e.message)
        if (typeof e == "string") message = T(e)
        dialogs.misc.errorDialog(
            activeAccountId.value,
            T("Error Switchting Cluster Memeber"),
            message
        )
    } finally {
        loadingCluster.value = false
    }
}

const getMemoryPercentage = function (memory: any) {
    return memory ? (memory.total - memory.available) / (memory.total / 100) : 0
}

const getStoragePercentage = function (storage: any) {
    return storage ? (storage.total - storage.available) / (storage.total / 100) : 0
}

const getCpuPercentage = function (cpuInfo: any) {
    let other =
        100 -
        parseInt(cpuInfo.idle) -
        (parseInt(cpuInfo.nice) + parseInt(cpuInfo.system) + parseInt(cpuInfo.user))
    let percentage = cpuInfo
        ? parseInt(cpuInfo.nice) + parseInt(cpuInfo.system) + parseInt(cpuInfo.user) + other
        : 0
    return percentage
}

const getDate = function (timestamp: any, dateTime: boolean = false) {
    if (typeof timestamp == "number") {
        return timeHelpers.formatDate(timestamp * 1000, timeHelpers.getDateFormatI18n(dateTime))
    } else {
        return timeHelpers.formatDate(timestamp, timeHelpers.getDateFormatI18n(dateTime))
    }
}

const getUtmInfo = async function () {
    loaderText.value = "Getting UTM info..."
    let hasUtm: any = false

    if (config.canUseNewObjectType("uscUtms")) {
        hasUtm = await products.unifiedSecurityConsole.uscUtms.queries.getObjectFromApi(
            activeAccountId.value,
            objectId.value || ""
        )
    } else {
        hasUtm = await useStore().dispatch(ActionTypes.getObjectInfo, {
            accountId: activeAccountId.value,
            productType: "unifiedSecurityConsole",
            objectType: "uscUtms",
            queryType: "uscUtms",
            objectId: objectId.value
        })
    }

    if (hasUtm === false) {
        router.navigate("404-" + objectId.value)
    } else {
        if (config.canUseNewObjectType("uscUtmStates")) {
            await products.unifiedSecurityConsole.uscUtmStates.queries.getObjectsFromApi(
                activeAccountId.value
            )
        } else {
            await useStore().dispatch(ActionTypes.getObjectInfos, {
                accountId: activeAccountId.value,
                objectTypes: ["ccutmStates"]
            })
        }

        loaderText.value = "Getting Operationslog info..."
        await getOperationsLogData()
        loaderText.value = "Getting Scans info..."
        await getScansData()
        loaderText.value = "Getting Jobs info..."
        getAppsTableData()
        loaderText.value = "Initiating inventory form..."
        initInventoryform()
        loaderText.value = "Initiating USR info..."
        getUsrData()
        initialized.value = true
        nextTick(async function () {
            refreshCount.value++
        })
    }
}

const getOperationsLogData = async function () {
    let operationsLogData: any = undefined
    operationsLogTableData.value.loading = true
    try {
        if (config.canUseNewObjectType("uscUtms")) {
            operationsLogData = objectId
                ? await products.unifiedSecurityConsole.uscUtms.getJobs(
                      activeAccountId.value,
                      objectId.value,
                      "?limit=20&state=all&queue=all"
                  )
                : { jobs: [] }
        } else {
            operationsLogData = objectId
                ? await objectStores.uscUtms.getJobs(
                      activeAccountId.value,
                      objectId.value,
                      "?limit=20&state=all&queue=all"
                  )
                : { jobs: [] }
        }
        if (operationsLogData?.jobs) {
            operationsLogData = operationsLogData.jobs
            operationsLog.value = operationsLogData
        }
        let messages = config.canUseNewObjectType("uscUtms")
            ? await products.unifiedSecurityConsole.uscUtms.getUtmMessages(
                  activeAccountId.value,
                  objectId.value || ""
              )
            : await objectStores.uscUtms.getUtmMessages(activeAccountId.value, objectId.value || "")
        if (messages?.messages) {
            messages.messages.forEach((message: any) => {
                message.isProfilePublishMessage = true
            })
            profilePublishMessages.value = messages.messages
        }
    } catch (e: any) {
        console.error(e)
    }
    operationsLogTableData.value.loading = false
    return true
}

const startNewScan = async function () {
    const rendernewScanDialog = config.canUseNewObjectType("uscUtms")
        ? products.unifiedSecurityConsole.uscUtms.renderNewScanDialog
        : objectStores.uscUtms.dialogs.renderNewScanDialog

    const startScan = config.canUseNewObjectType("uscUtms")
        ? products.unifiedSecurityConsole.uscUtms.startScan
        : objectStores.uscUtms.startScan

    rendernewScanDialog(activeAccountId.value, async function (scanOptions: any) {
        const scanData = {
            host: {
                address: scanOptions.host.address,
                interface: scanOptions.host.interface
            },
            profile: {
                name: scanOptions.profile.name,
                tcp: scanOptions.selectOptions.profiles[scanOptions.profile.name].tcp || true,
                udp: scanOptions.selectOptions.profiles[scanOptions.profile.name].udp || false
            }
        }
        try {
            let result = await startScan(activeAccountId.value, objectId.value, scanData)

            const thisScan = {
                id: result.jobId,
                issued: result.timestamp,
                lastUpdate: result.timestamp,
                status: result.status,
                statusText: result.status,
                result: {
                    host: {
                        address: scanOptions.host.address
                    }
                }
            }
            if (result) {
                scans.value.unshift(thisScan)
            }
            return result
        } catch (e) {
            return e
        }
    })
}

const getScansData = async function (uncached: boolean = false) {
    scansLoading.value = true
    try {
        let getScans = config.canUseNewObjectType("uscUtms")
            ? products.unifiedSecurityConsole.uscUtms.getScans
            : objectStores.uscUtms.getScans

        let newScans = objectId
            ? await getScans(
                  activeAccountId.value,
                  objectId.value,
                  "?limit=20&state=all" + (uncached ? "&_=" + timeHelpers.getUnixTimeStamp() : "")
              )
            : { scans: [] }

        if (newScans?.scans) {
            newScans = newScans.scans
            scans.value = newScans
        }
    } catch (e: any) {
        console.error(e)
    }
    scansLoading.value = false
    return true
}

const appsTableInfo: TableEntryInfo[] = [
    {
        text: T("Name"),
        displayType: "text",
        getValue: (entry: any) => {
            return (
                applicationNames[entry.application as keyof typeof applicationNames]?.[
                    LANG as "de" | "en"
                ] || entry.application
            )
        },
        getSortValue: (entry: any) => {
            return (
                applicationNames[entry.application as keyof typeof applicationNames]?.[
                    LANG as "de" | "en"
                ] || entry.application
            )
        },
        property: "application"
    },
    {
        text: T("Flags"),
        displayType: "labels",
        getValue: (entry: any) => {
            return entry.flags.map((flag: string) => {
                return new Label({
                    text: flag,
                    title: flag
                })
            })
        },
        getSortValue: (entry: any) => {
            return entry.flags.join(",")
        },
        property: "flags"
    },
    {
        text: T("xState"),
        displayType: "labels",
        getValue: (entry: any) => {
            return Array.isArray(entry?.flags) &&
                entry.flags.includes("DISABLED_IF_SPARE") &&
                (entry.state == "ERROR (should up)" ||
                    entry.state == "SPARE (disabled, normally up)")
                ? <Label[]>[
                      new Label({
                          title: T("Deaktiviert weil Spare"),
                          text: T("Deaktiviert"),
                          class: "bg-gray"
                      })
                  ]
                : <Label[]>[
                      new Label({
                          title: T(entry.state == "UP" ? "Active" : "Inactive"),
                          text: T(entry.state == "UP" ? "Active" : "Inactive"),
                          class:
                              entry.state == "UP"
                                  ? "bg-green"
                                  : entry.state == "DOWN"
                                    ? "bg-red"
                                    : "bg-yellow"
                      })
                  ]
        },
        getSortValue: (entry: any) => {
            return Array.isArray(entry?.flags) &&
                entry.flags.includes("DISABLED_IF_SPARE") &&
                (entry.state == "ERROR (should up)" ||
                    entry.state == "SPARE (disabled, normally up)")
                ? T("Deaktiviert weil Spare")
                : entry.state == "UP"
                  ? "Active"
                  : "Inactive"
        },
        property: "state"
    },
    {
        text: T("Actions"),
        displayType: "buttons",
        getValue: (entry: any) => {
            let thisActionButtons: Button[] = []
            if (entry.state == "UP") {
                thisActionButtons = [
                    new Button({
                        text: T("Restart"),
                        title: T("Restart"),
                        icon: "fal fa-fw fa-sync",
                        onClick: async function () {
                            await execDeviceAction(
                                utm.value,
                                "appmgmt restart application",
                                entry.application
                            )
                            getAppsTableData()
                        },
                        disabled: false,
                        loading: false
                    }),
                    new Button({
                        text: T("Stop"),
                        title: T("Stop"),
                        icon: "fal fa-fw fa-stop",
                        onClick: async function () {
                            await execDeviceAction(
                                utm.value,
                                "appmgmt stop application",
                                entry.application
                            )
                            getAppsTableData()
                        },
                        disabled: false,
                        loading: false
                    })
                ]
            } else if (entry.state == "DOWN") {
                thisActionButtons = [
                    new Button({
                        text: T("Start"),
                        title: T("Start"),
                        icon: "fal fa-fw fa-play",
                        onClick: async function () {
                            await execDeviceAction(
                                utm.value,
                                "appmgmt start application",
                                entry.application
                            )
                            getAppsTableData()
                        },
                        disabled: false,
                        loading: false
                    })
                ]
            }
            return thisActionButtons
        },
        property: "actions"
    }
]

const getAppsTableData = async function (useLoader: boolean = false, refresh: boolean = false) {
    let appList: any = []
    applicationsTableData.value.loading = true
    applicationsTableData.value.tableData.titles = [
        {
            id: "name",
            text: "Name",
            sortable: false
        },
        {
            id: "flags",
            text: "Flags",
            sortable: false
        },
        {
            id: "status",
            text: "Status",
            sortable: false
        },
        {
            id: "action",
            text: "Action",
            sortable: false
        }
    ]

    let thisMessages: any = Array.isArray(getUtmMessageData(utm.value, "spcli-appmgmt-status"))
        ? getUtmMessageData(utm.value, "spcli-appmgmt-status")
        : []
    thisMessages.forEach(function (message: any) {
        message.applicationName =
            applicationNames[message.application as keyof typeof applicationNames]?.[
                LANG as "de" | "en"
            ] || message.application
    })

    thisMessages = thisMessages
        .sort(function (a: any, b: any) {
            if ((a.applicationName || "") > (b.applicationName || "")) {
                return 1
            } else {
                return -1
            }
        })
        .filter((application: any) => {
            return application.flags?.indexOf("HIDDEN") == -1
        })

    applicationsTableData.value.tableData.rows = Array.isArray(thisMessages)
        ? thisMessages.map((application: any) => {
              let thisActionButtons: any = {}
              let applicationFlagLabels: Label[] = []

              application.flags.map((flag: string) => {
                  return {
                      text: flag,
                      title: flag
                  }
              })

              if (application.state == "UP") {
                  thisActionButtons = {
                      buttons: [
                          {
                              text: T("Restart"),
                              title: T("Restart"),
                              icon: "fal fa-fw fa-sync",
                              onClick: async function () {
                                  await execDeviceAction(
                                      utm.value,
                                      "appmgmt restart application",
                                      application.application
                                  )
                                  getAppsTableData()
                              },
                              disabled: false,
                              loading: false
                          },
                          {
                              text: T("Stop"),
                              title: T("Stop"),
                              icon: "fal fa-fw fa-stop",
                              onClick: async function () {
                                  await execDeviceAction(
                                      utm.value,
                                      "appmgmt stop application",
                                      application.application
                                  )
                                  getAppsTableData()
                              },
                              disabled: false,
                              loading: false
                          }
                      ]
                  }
              } else if (application.state == "DOWN") {
                  thisActionButtons = {
                      buttons: [
                          {
                              text: T("Start"),
                              title: T("Start"),
                              icon: "fal fa-fw fa-play",
                              onClick: async function () {
                                  await execDeviceAction(
                                      utm.value,
                                      "appmgmt start application",
                                      application.application
                                  )
                                  getAppsTableData()
                              },
                              disabled: false,
                              loading: false
                          }
                      ]
                  }
              }

              return {
                  data: {
                      name:
                          applicationNames[
                              application.application as keyof typeof applicationNames
                          ]?.[LANG as "de" | "en"] || application.application,
                      flags: application.flags ? applicationFlagLabels.join(" ") : "",
                      status: {
                          labels:
                              Array.isArray(application?.flags) &&
                              application.flags.includes("DISABLED_IF_SPARE") &&
                              (application.state == "ERROR (should up)" ||
                                  application.state == "SPARE (disabled, normally up)")
                                  ? <Label[]>[
                                        new Label({
                                            title: T("Deaktiviert weil Spare"),
                                            text: T("Deaktiviert").toUpperCase(),
                                            class: "bg-gray"
                                        })
                                    ]
                                  : <Label[]>[
                                        new Label({
                                            title: T(application.state),
                                            text: T(application.state),
                                            class:
                                                application.state == "UP"
                                                    ? "bg-green"
                                                    : application.state == "DOWN"
                                                      ? "bg-red"
                                                      : "bg-yellow"
                                        })
                                    ]
                      },
                      action: thisActionButtons
                  }
              }
          })
        : []

    refreshCount.value++
    applicationsTableData.value.loading = false
}

const getInfoBoxText = () => {
    let utmStreet: any = jsonHelpers.getObjectProperty(utm, "inventory.inventoryLocation.street")
    let utmPostalCode: any = jsonHelpers.getObjectProperty(
        utm,
        "inventory.inventoryLocation.postalCode"
    )
    let utmCity: any = jsonHelpers.getObjectProperty(utm, "inventory.inventoryLocation.city")
    let utmLatitude: any = jsonHelpers.getObjectProperty(
        utm,
        "inventory.inventoryLocation.latitude"
    )
    let utmLongitude: any = jsonHelpers.getObjectProperty(
        utm,
        "inventory.inventoryLocation.longitude"
    )
    let utmInventoryLocationDescription: any = jsonHelpers.getObjectProperty(
        utm,
        "inventory.inventoryLocationDescription"
    )

    let template: any = "<div>"
    if (utmStreet) {
        template +=
            '<i class="fal fa-fw fa-road"></i>&nbsp;' +
            encodingHelpers.escapeHTML(utmStreet) +
            "<br>"
    }
    if (utmPostalCode) {
        template +=
            '<i class="fal fa-fw fa-globe"></i>&nbsp;' +
            encodingHelpers.escapeHTML(utmPostalCode) +
            "<br>"
    }
    template += encodingHelpers.escapeHTML(utmCity) + "<br>"
    if (utmInventoryLocationDescription) {
        template += "<p>" + encodingHelpers.escapeHTML(utmInventoryLocationDescription) + "</p>"
    }
    template += "<div>"
    return template
}

const deviceActionStatus = function (context: string) {
    let showElement: boolean = true
    let isUtmDisabled: boolean = false
    let isUtmLoading: boolean = false
    let disabledReason: string | undefined
    let isUtmRebooting: boolean = isRebooting.value
    let lastFeatureStatus: any = getLastDeviceActionStatusFromOperationLog(context)
    if (lastFeatureStatus == "PENDING") {
        isUtmLoading = true
        isUtmDisabled = true
    } else if (lastFeatureStatus == "REBOOTING") {
        isUtmLoading = true
        isUtmDisabled = true
        isUtmRebooting = true
    }

    const contextToPinRequired = {
        "system reboot": true,
        "system poweroff": true,
        "system config factorysettings": true,
        "system upgrade rollback": shouldDisplayLock.value,
        "system upgrade dryrun": shouldDisplayLock.value,
        "system upgrade finalize": shouldDisplayLock.value,
        "system upgrade confirm eula": shouldDisplayLock.value,
        "system upgrade confirm privacy": shouldDisplayLock.value
    }

    if (contextToPinRequired[context as keyof typeof contextToPinRequired] && utm.value.pinState) {
        isUtmDisabled = !utm.value.pinState?.enabled || utm.value?.pinState.locked
        if (!utm.value.pinState.enabled) {
            disabledReason = T(
                "Dieses Feature ist auf dieser UTM nicht verfügbar, da die PIN deaktiviert ist."
            )
        }
        if (utm.value.pinState.locked) {
            disabledReason = T(
                "Dieses Feature ist auf dieser UTM nicht verfügbar, da die PIN gesperrt ist."
            )
        }
    }

    return {
        view: showElement,
        disabled: isUtmDisabled,
        loading: isUtmLoading,
        rebooting: isUtmRebooting,
        disabledReason: disabledReason
    }
}

const execDeviceAction = function (item: UscUtm, context: string, payload?: any) {
    if (config.canUseNewObjectType("uscUtms")) {
        return products.unifiedSecurityConsole.uscUtms.execDeviceAction(
            item as UscUtmNew,
            context,
            payload
        )
    }
    return objectStores.uscUtms.execDeviceAction(item, context, payload)
}

const getLastDeviceActionStatusFromOperationLog = function (context: string) {
    let status: any = undefined
    let thisActions: any[] = operationsLog.value.filter((entry) => {
        let contextMap = products.unifiedSecurityConsole.uscUtms.contextMap
        return (
            entry.context == context ||
            contextMap[context as keyof typeof contextMap] == entry.context
        )
    })
    if (thisActions.length) {
        status = thisActions[0].status
    }
    return status
}

const initInventoryform = function () {
    inventoryForm.value.billDate = utm.value?.inventory?.billDate
        ? timeHelpers.formatDate(parseInt(utm.value?.inventory.billDate) * 1000, dateFormat)
        : undefined
    inventoryForm.value.billNumber = utm.value?.inventory?.billNumber || undefined
    inventoryForm.value.contractDate = utm.value?.inventory?.contractDate
        ? timeHelpers.formatDate(parseInt(utm.value?.inventory.contractDate) * 1000, dateFormat)
        : undefined
    inventoryForm.value.contractDuration = utm.value?.inventory?.contractDuration || ""
    inventoryForm.value.contractNumber = utm.value?.inventory?.contractNumber || undefined
    inventoryForm.value.handoverDate = utm.value?.inventory?.handoverDate
        ? timeHelpers.formatDate(parseInt(utm.value?.inventory.handoverDate) * 1000, dateFormat)
        : undefined
    inventoryForm.value.hardwareDescription = utm.value?.inventory?.hardwareDescription || undefined
    inventoryForm.value.inventoryExternalLink =
        utm.value?.inventory?.inventoryExternalLink || undefined
    inventoryForm.value.inventoryLocation = utm.value?.inventory?.inventoryLocation || {}
    inventoryForm.value.inventoryLocationDescription =
        utm.value?.inventory?.inventoryLocationDescription || undefined
    inventoryForm.value.providerCustomerNumber =
        utm.value?.inventory?.providerCustomerNumber || undefined
    inventoryForm.value.providerName = utm.value?.inventory?.providerName || undefined
    inventoryForm.value.providerTariff = utm.value?.inventory?.providerTariff || undefined
    inventoryForm.value.warrantyEndDate = utm.value?.inventory?.warrantyEndDate
        ? timeHelpers.formatDate(parseInt(utm.value?.inventory.warrantyEndDate) * 1000, dateFormat)
        : undefined
    inventoryForm.value.warrantyStartDate = utm.value?.inventory?.warrantyStartDate
        ? timeHelpers.formatDate(
              parseInt(utm.value?.inventory.warrantyStartDate) * 1000,
              dateFormat
          )
        : undefined
}

const getRPAccountLink = function () {
    return (
        "https://" +
        (document.location.origin.indexOf("portal-dev") != -1 ? "dev" : "") +
        "my.securepoint.de/2.0/accounts/" +
        activeAccountId.value
    )
}

const saveInventory = async function () {
    savingInventory.value = true
    let tenantDomain: any = tenantHelpers.getTenantDomain(activeAccountId.value)
    let deviceId: any = objectId.value
    let properties: any = jsonHelpers.copyObject(inventoryForm.value)

    delete properties.inventoryLocation

    if (properties.billDate) {
        properties.billDate = moment(properties.billDate, "DD.MM.YYYY").format("X")
    }
    if (properties.contractDate) {
        properties.contractDate = moment(properties.contractDate, "DD.MM.YYYY").format("X")
    }
    if (properties.handoverDate) {
        properties.handoverDate = moment(properties.handoverDate, "DD.MM.YYYY").format("X")
    }
    if (properties.warrantyStartDate) {
        properties.warrantyStartDate = moment(properties.warrantyStartDate, "DD.MM.YYYY").format(
            "X"
        )
    }
    if (properties.warrantyEndDate) {
        properties.warrantyEndDate = moment(properties.warrantyEndDate, "DD.MM.YYYY").format("X")
    }
    if (
        properties.inventoryExternalLink &&
        properties.inventoryExternalLink.indexOf("http://") == -1 &&
        properties.inventoryExternalLink.indexOf("https://") == -1
    ) {
        properties.inventoryExternalLink = "http://" + properties.inventoryExternalLink
    }
    if (typeof properties.contractDuration == "number") {
        properties.contractDuration = properties.contractDuration.toString()
    }
    try {
        if (config.canUseNewObjectType("uscUtms")) {
            await products.unifiedSecurityConsole.uscUtms.queries.updateObjectPropertiesFromApi(
                activeAccountId.value,
                deviceId,
                properties,
                undefined,
                ["inventory"],
                undefined,
                "/properties",
                "POST"
            )
        } else {
            await objectStores.uscUtms.setUtmProperties(tenantDomain, deviceId, properties)
        }
    } catch (e: any) {
        console.error(e)
    }

    initInventoryform()
    savingInventory.value = false
}

const getInventoryLocationDialog = function () {
    dialogs.unifiedSecurity.setInventoryLocation(
        utm.value?.inventory?.inventoryLocation || {},
        async (inventoryLocation, inventoryLocationDescription) => {
            if (!jsonHelpers.isObjectEmpty(inventoryLocation)) {
                if (config.canUseNewObjectType("uscUtms")) {
                    await products.unifiedSecurityConsole.uscUtms.updateObjectInventory(
                        activeAccountId.value,
                        objectId.value,
                        {
                            inventoryLocation: JSON.stringify(inventoryLocation),
                            inventoryLocationDescription
                        }
                    )
                } else {
                    await useStore().dispatch(ActionTypes.updateObjectInventory, {
                        accountId: activeAccountId.value,
                        productType: "unifiedSecurityConsole",
                        objectType: "uscUtms",
                        queryObjectType: "ccutmProperties",
                        objectId: objectId.value,
                        object: {
                            inventoryLocation: JSON.stringify(inventoryLocation),
                            inventoryLocationDescription
                        }
                    })
                }
                return true
            } else {
                return false
            }
        },
        utm.value?.inventory?.inventoryLocationDescription
    )
}

const renderSetCloudBackupPassword = function (activateCloudBackup: boolean = false) {
    let newModal = {
        id: "utm-new-password",
        accountId: activeAccountId.value,
        abortable: true,
        content: {
            title: {
                text: activateCloudBackup ? T("Enable cloud-backup") : T("Set new password"),
                icon: "fal fa-exclamation-triangle"
            },
            body: {
                use: true,
                content: undefined,
                component: "set-password",
                properties: undefined
            }
        },
        buttons: [
            {
                loading: false,
                onClick: () => {
                    getterHelpers.useStore().commit(MutationTypes.removeModal, {
                        accountId: activeAccountId.value
                    })
                },
                icon: "fal fa-times",
                text: T("Cancel"),
                align: "left",
                disabled: false
            },
            {
                loading: false,
                onClick: async (modal: any) => {
                    getterHelpers.useStore().state.session.accounts[
                        activeAccountId.value
                    ].modals[0].buttons[1].disabled = true
                    getterHelpers.useStore().state.session.accounts[
                        activeAccountId.value
                    ].modals[0].buttons[1].loading = true
                    try {
                        let password: string = modal.$refs.modalComponent.password
                        if (config.canUseNewObjectType("uscUtms")) {
                            let result =
                                await products.unifiedSecurityConsole.uscUtms.sendSpcliMessage(
                                    activeAccountId.value,
                                    utm.value.utmId,
                                    {
                                        data: ["system", "cloudbackup", "set", "password", password]
                                    }
                                )
                        } else {
                            let result = await objectStores.uscUtms.sendSpcliMessage(
                                activeAccountId.value,
                                utm.value.utmId,
                                {
                                    data: ["system", "cloudbackup", "set", "password", password]
                                }
                            )
                        }
                        if (activateCloudBackup) {
                            if (config.canUseNewObjectType("uscUtms")) {
                                await products.unifiedSecurityConsole.uscUtms.sendSpcliMessage(
                                    activeAccountId.value,
                                    utm.value.utmId,
                                    {
                                        data: [
                                            "extc",
                                            "global",
                                            "set",
                                            "variable",
                                            "GLOB_CLOUDBACKUP_TIME",
                                            "value",
                                            "1"
                                        ]
                                    }
                                )
                            } else {
                                await objectStores.uscUtms.sendSpcliMessage(
                                    activeAccountId.value,
                                    utm.value.utmId,
                                    {
                                        data: [
                                            "extc",
                                            "global",
                                            "set",
                                            "variable",
                                            "GLOB_CLOUDBACKUP_TIME",
                                            "value",
                                            "1"
                                        ]
                                    }
                                )
                            }
                        }
                        if (config.canUseNewObjectType("uscUtms")) {
                            await products.unifiedSecurityConsole.uscUtms.sendSpcliMessage(
                                activeAccountId.value,
                                utm.value.utmId,
                                { data: ["system", "config", "save"] }
                            )
                            await products.unifiedSecurityConsole.uscUtms.sendSpcliMessage(
                                activeAccountId.value,
                                utm.value.utmId,
                                { data: ["system", "update", "system"] }
                            )
                        } else {
                            await objectStores.uscUtms.sendSpcliMessage(
                                activeAccountId.value,
                                utm.value.utmId,
                                { data: ["system", "config", "save"] }
                            )
                            await objectStores.uscUtms.sendSpcliMessage(
                                activeAccountId.value,
                                utm.value.utmId,
                                { data: ["system", "update", "system"] }
                            )
                        }
                        getBackupInfo()
                    } catch (e) {
                        console.error(e)
                    }
                    getterHelpers.useStore().state.session.accounts[
                        activeAccountId.value
                    ].modals[0].buttons[1].disabled = false
                    getterHelpers.useStore().state.session.accounts[
                        activeAccountId.value
                    ].modals[0].buttons[1].loading = false
                    getterHelpers.useStore().commit(MutationTypes.removeModal, {
                        accountId: activeAccountId.value
                    })
                },
                icon: "fal fa-save",
                text: T("Save"),
                align: "right",
                disabled: true
            }
        ]
    }
    useStore().dispatch(ActionTypes.addModal, newModal)
}

const getUtmMessageData = config.canUseNewObjectType("uscUtms")
    ? products.unifiedSecurityConsole.uscUtms.getUtmMessageData
    : objectStores.uscUtms.getUtmMessageData

const getUsrData = async function () {
    if (getLaasState(utm.value) == "active") {
        widgets.value.blockedThreats.riskStatus = "loading"
        widgets.value.antivirusClamStatus.riskStatus = "loading"
        widgets.value.antivirusCtavStatus.riskStatus = "loading"
        widgets.value.kernelStatus.riskStatus = "loading"
        widgets.value.alertStatus.riskStatus = "loading"
        widgets.value.httpTrafficStatus.riskStatus = "loading"
        widgets.value.loginStatus.riskStatus = "loading"

        let defaultQueryObject: any = {
            query: {
                modul: "CloudUtm",
                name: "USRSum",
                options: {
                    relate: "count",
                    period: widgets.value.period,
                    size: 1,
                    offset: 0,
                    timezone: "+02:00",
                    order: {
                        asc: false
                    },
                    filter: [
                        {
                            field: "device",
                            operator: "equal",
                            value: objectId.value
                        }
                    ]
                }
            }
        }

        let countVirusQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
        countVirusQueryObject.query.options.filter.push({
            field: "query",
            operator: "equal",
            value: "CountVirus"
        })

        let countMailScannerVirusQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
        countMailScannerVirusQueryObject.query.options.filter.push({
            field: "query",
            operator: "equal",
            value: "CountMailScannerVirus"
        })

        let countKernelCommandLogQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
        countKernelCommandLogQueryObject.query.options.filter.push({
            field: "query",
            operator: "equal",
            value: "CountKernelCommandLog"
        })

        let countKernelCommandRejectQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
        countKernelCommandRejectQueryObject.query.options.filter.push({
            field: "query",
            operator: "equal",
            value: "CountKernelCommandReject"
        })

        let countUrlsTIFBlockedQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
        countUrlsTIFBlockedQueryObject.query.options.filter.push({
            field: "query",
            operator: "equal",
            value: "CountUrlsTIFBlocked"
        })

        let countMailScannerTIFQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
        countMailScannerTIFQueryObject.query.options.filter.push({
            field: "query",
            operator: "equal",
            value: "CountMailScannerTIF"
        })

        let antivirusClamStatusQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
        antivirusClamStatusQueryObject.query.name = "USRLatest"
        antivirusClamStatusQueryObject.query.options.filter.push({
            field: "query",
            operator: "equal",
            value: "ValueSystemClamUpdate"
        })

        let valueSystemCtavUpdateQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
        valueSystemCtavUpdateQueryObject.query.name = "USRLatest"
        valueSystemCtavUpdateQueryObject.query.options.filter.push({
            field: "query",
            operator: "equal",
            value: "ValueSystemCtavUpdate"
        })

        let kernelStatusDropsQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
        kernelStatusDropsQueryObject.query.options.filter.push({
            field: "query",
            operator: "equal",
            value: "CountKernelDrop"
        })

        let kernelStatusRejectsQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
        kernelStatusRejectsQueryObject.query.options.filter.push({
            field: "query",
            operator: "equal",
            value: "CountKernelReject"
        })

        let countAlertsRiskQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
        countAlertsRiskQueryObject.query.options.filter.push({
            field: "query",
            operator: "equal",
            value: "CountAlertsRisk"
        })

        let countAlertsNoRiskQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
        countAlertsNoRiskQueryObject.query.options.filter.push({
            field: "query",
            operator: "equal",
            value: "CountAlertsNoRisk"
        })

        let countTrafficQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
        countTrafficQueryObject.query.options.filter.push({
            field: "query",
            operator: "equal",
            value: "CountTraffic"
        })

        let loginStatusQueryObject: any = jsonHelpers.copyObject(defaultQueryObject)
        loginStatusQueryObject.query.options.filter.push({
            field: "query",
            operator: "equal",
            value: "CountAuthLoginsFailed"
        })

        let results: any = await Promise.all([
            requestHandler.request(
                "POST",
                "/sms-mgt-api/api/2.0/tenants/" +
                    tenantHelpers.getTenantDomain(activeAccountId.value) +
                    "/stats/execute",
                countVirusQueryObject
            ),
            requestHandler.request(
                "POST",
                "/sms-mgt-api/api/2.0/tenants/" +
                    tenantHelpers.getTenantDomain(activeAccountId.value) +
                    "/stats/execute",
                countMailScannerVirusQueryObject
            ),
            requestHandler.request(
                "POST",
                "/sms-mgt-api/api/2.0/tenants/" +
                    tenantHelpers.getTenantDomain(activeAccountId.value) +
                    "/stats/execute",
                countKernelCommandLogQueryObject
            ),
            requestHandler.request(
                "POST",
                "/sms-mgt-api/api/2.0/tenants/" +
                    tenantHelpers.getTenantDomain(activeAccountId.value) +
                    "/stats/execute",
                countKernelCommandRejectQueryObject
            ),
            requestHandler.request(
                "POST",
                "/sms-mgt-api/api/2.0/tenants/" +
                    tenantHelpers.getTenantDomain(activeAccountId.value) +
                    "/stats/execute",
                countUrlsTIFBlockedQueryObject
            ),
            requestHandler.request(
                "POST",
                "/sms-mgt-api/api/2.0/tenants/" +
                    tenantHelpers.getTenantDomain(activeAccountId.value) +
                    "/stats/execute",
                countMailScannerTIFQueryObject
            ),

            requestHandler.request(
                "POST",
                "/sms-mgt-api/api/2.0/tenants/" +
                    tenantHelpers.getTenantDomain(activeAccountId.value) +
                    "/stats/execute",
                antivirusClamStatusQueryObject
            ),

            requestHandler.request(
                "POST",
                "/sms-mgt-api/api/2.0/tenants/" +
                    tenantHelpers.getTenantDomain(activeAccountId.value) +
                    "/stats/execute",
                valueSystemCtavUpdateQueryObject
            ),

            requestHandler.request(
                "POST",
                "/sms-mgt-api/api/2.0/tenants/" +
                    tenantHelpers.getTenantDomain(activeAccountId.value) +
                    "/stats/execute",
                kernelStatusDropsQueryObject
            ),
            requestHandler.request(
                "POST",
                "/sms-mgt-api/api/2.0/tenants/" +
                    tenantHelpers.getTenantDomain(activeAccountId.value) +
                    "/stats/execute",
                kernelStatusRejectsQueryObject
            ),

            requestHandler.request(
                "POST",
                "/sms-mgt-api/api/2.0/tenants/" +
                    tenantHelpers.getTenantDomain(activeAccountId.value) +
                    "/stats/execute",
                countAlertsRiskQueryObject
            ),
            requestHandler.request(
                "POST",
                "/sms-mgt-api/api/2.0/tenants/" +
                    tenantHelpers.getTenantDomain(activeAccountId.value) +
                    "/stats/execute",
                countAlertsNoRiskQueryObject
            ),

            requestHandler.request(
                "POST",
                "/sms-mgt-api/api/2.0/tenants/" +
                    tenantHelpers.getTenantDomain(activeAccountId.value) +
                    "/stats/execute",
                countTrafficQueryObject
            ),

            requestHandler.request(
                "POST",
                "/sms-mgt-api/api/2.0/tenants/" +
                    tenantHelpers.getTenantDomain(activeAccountId.value) +
                    "/stats/execute",
                loginStatusQueryObject
            )
        ])

        let countVirus: number = results[0] ? results[0].result[0].value : 0
        let countMailScannerVirus: number = results[1] ? results[1].result[0].value : 0
        let countKernelCommandLog: number = results[2] ? results[2].result[0].value : 0
        let countKernelCommandReject: number = results[3] ? results[3].result[0].value : 0
        let countUrlsTIFBlocked: number = results[4] ? results[4].result[0].value : 0
        let countMailScannerTIF: number = results[5] ? results[5].result[0].value : 0
        let antivirusClamStatusKey: number | string = results[6] ? results[6].result[0].key : 0
        let antivirusClamStatusValue: number = results[6] ? results[6].result[0].value : 0
        let antivirusCtavStatusKey: number | string = results[7] ? results[7].result[0].key : 0
        let antivirusCtavStatusValue: number = results[7] ? results[7].result[0].value : 0
        let kernelStatusDrops: number = results[8] ? results[8].result[0].value : 0
        let kernelStatusRejects: number = results[9] ? results[9].result[0].value : 0
        let countAlertsRisk: number | string = results[10] ? results[10].result[0].value : 0
        let countAlertsNoRisk: number | string = results[11] ? results[11].result[0].value : 0
        let countTraffic: number = results[12] ? results[12].result[0].value : 0
        let loginStatus: number = results[13] ? results[13].result[0].value : 0

        if (typeof countAlertsRisk == "string") countAlertsRisk = parseInt(countAlertsRisk) || 0
        if (typeof countAlertsNoRisk == "string")
            countAlertsNoRisk = parseInt(countAlertsNoRisk) || 0

        widgets.value.blockedThreats.data.HttpVirus = countVirus
        widgets.value.blockedThreats.data.MailVirus = countMailScannerVirus
        widgets.value.blockedThreats.data.KernelLog = countKernelCommandLog
        widgets.value.blockedThreats.data.KernelReject = countKernelCommandReject
        widgets.value.blockedThreats.data.HttpUrlBlocked = countUrlsTIFBlocked
        widgets.value.blockedThreats.data.MailUrlBlocked = countMailScannerTIF
        widgets.value.blockedThreats.riskStatus = "none"

        widgets.value.antivirusClamStatus.data.State = antivirusClamStatusKey
        widgets.value.antivirusClamStatus.data.LastUpdate = antivirusClamStatusValue

        if (antivirusClamStatusKey == "as shipped") {
            widgets.value.antivirusClamStatus.riskStatus = "never"
            widgets.value.antivirusClamStatus.text = "Scanner is disabled"
        } else if (antivirusClamStatusKey == "not in use") {
            widgets.value.antivirusClamStatus.riskStatus = "never"
            widgets.value.antivirusClamStatus.text = "Not in use"
        } else if (antivirusClamStatusKey == "signature update started") {
            widgets.value.antivirusClamStatus.riskStatus = "never"
            widgets.value.antivirusClamStatus.text = "Update started"
        } else if (
            [
                "up to date",
                "signatures successfully updated",
                "signatures up to date, nothing to do"
            ].indexOf(antivirusClamStatusKey as string) != -1
        ) {
            widgets.value.antivirusClamStatus.riskStatus = "none"
            if (moment().unix() - antivirusClamStatusValue < 86400 * 2) {
                widgets.value.antivirusClamStatus.riskStatus = "none"
                widgets.value.antivirusClamStatus.text = "Pattern is up to date"
            } else if (moment().unix() - antivirusClamStatusValue >= 86400 * 2) {
                if (antivirusClamStatusValue == 0) {
                    widgets.value.antivirusClamStatus.riskStatus = "never"
                    widgets.value.antivirusClamStatus.text = "No info"
                } else {
                    widgets.value.antivirusClamStatus.riskStatus = "medium"
                    widgets.value.antivirusClamStatus.text =
                        T("Last Update:") +
                        " " +
                        moment(antivirusClamStatusValue * 1000).format(dateFormat)
                }
            } else if (moment().unix() - antivirusClamStatusValue >= 86400 * 3) {
                widgets.value.antivirusClamStatus.riskStatus = "high"
                widgets.value.antivirusClamStatus.text = "Update is older than 2 days"
            }
        } else if (["not running"].indexOf(antivirusClamStatusKey as string) != -1) {
            widgets.value.antivirusClamStatus.riskStatus = "medium"
            widgets.value.antivirusClamStatus.text = "Scanner has been stopped"
        } else if (
            ["is deactivated", "license expired", "missing license"].indexOf(
                antivirusClamStatusKey as string
            ) != -1
        ) {
            widgets.value.antivirusClamStatus.riskStatus = "high"
            widgets.value.antivirusClamStatus.text = "License error"
            if (antivirusClamStatusKey == "is deactivated") {
                widgets.value.antivirusClamStatus.text = "Update has been deactivated"
            }
        } else {
            widgets.value.antivirusClamStatus.riskStatus = "never"
            widgets.value.antivirusClamStatus.text = "No info"
        }

        widgets.value.antivirusCtavStatus.data.State = antivirusCtavStatusKey
        widgets.value.antivirusCtavStatus.data.LastUpdate = antivirusCtavStatusValue

        if (antivirusCtavStatusKey == "as shipped") {
            widgets.value.antivirusCtavStatus.riskStatus = "never"
            widgets.value.antivirusCtavStatus.text = "Scanner is disabled"
        } else if (antivirusCtavStatusKey == "not in use") {
            widgets.value.antivirusCtavStatus.riskStatus = "never"
            widgets.value.antivirusCtavStatus.text = "Not in use"
        } else if (antivirusCtavStatusKey == "signature update started") {
            widgets.value.antivirusCtavStatus.riskStatus = "never"
            widgets.value.antivirusCtavStatus.text = "Update started"
        } else if (
            [
                "up to date",
                "signatures successfully updated",
                "signatures up to date, nothing to do"
            ].indexOf(antivirusCtavStatusKey as string) != -1
        ) {
            widgets.value.antivirusCtavStatus.riskStatus = "none"
            if (moment().unix() - antivirusCtavStatusValue < 86400) {
                widgets.value.antivirusCtavStatus.riskStatus = "none"
                widgets.value.antivirusCtavStatus.text = "Pattern is up to date"
            } else if (moment().unix() - antivirusCtavStatusValue >= 86400) {
                if (antivirusCtavStatusValue == 0) {
                    widgets.value.antivirusCtavStatus.riskStatus = "never"
                    widgets.value.antivirusCtavStatus.text = "No info"
                } else {
                    widgets.value.antivirusCtavStatus.riskStatus = "medium"
                    widgets.value.antivirusCtavStatus.text =
                        T("Last Update:") +
                        " " +
                        moment(antivirusCtavStatusValue * 1000).format(dateFormat)
                }
            } else if (moment().unix() - antivirusCtavStatusValue >= 86400 * 2) {
                widgets.value.antivirusCtavStatus.riskStatus = "high"
                widgets.value.antivirusCtavStatus.text = "Update is older than 2 days"
            }
        } else if (["not running"].indexOf(antivirusCtavStatusKey as string) != -1) {
            widgets.value.antivirusCtavStatus.riskStatus = "medium"
            widgets.value.antivirusCtavStatus.text = "Scanner has been stopped"
        } else if (
            ["is deactivated", "license expired", "missing license"].indexOf(
                antivirusCtavStatusKey as string
            ) != -1
        ) {
            widgets.value.antivirusCtavStatus.riskStatus = "high"
            widgets.value.antivirusCtavStatus.text = "License error"
            if (antivirusCtavStatusKey == "is deactivated") {
                widgets.value.antivirusCtavStatus.text = "Update has been deactivated"
            }
        } else {
            widgets.value.antivirusCtavStatus.riskStatus = "never"
            widgets.value.antivirusCtavStatus.text = "No info"
        }

        widgets.value.kernelStatus.data.Drops = kernelStatusDrops == 0 ? "-" : kernelStatusDrops
        widgets.value.kernelStatus.data.Rejects =
            kernelStatusRejects == 0 ? "-" : kernelStatusRejects
        widgets.value.kernelStatus.riskStatus = "never"

        if (kernelStatusDrops == 0) {
            widgets.value.kernelStatus.dropsText = "No drops"
        } else if (kernelStatusDrops > 0) {
            widgets.value.kernelStatus.dropsText = "Drops"
        }
        if (kernelStatusRejects == 0) {
            widgets.value.kernelStatus.rejectsText = "No rejects"
        } else if (kernelStatusRejects > 0) {
            widgets.value.kernelStatus.rejectsText = "Rejects"
        }

        widgets.value.alertStatus.data.Risk = countAlertsRisk
        widgets.value.alertStatus.data.NoRisk = countAlertsNoRisk

        if (countAlertsRisk == 0) {
            widgets.value.alertStatus.text = T("No alerts occurred")
            widgets.value.alertStatus.riskStatus = "none"
        } else if (countAlertsRisk > 0) {
            widgets.value.alertStatus.text =
                countAlertsRisk +
                "(" +
                (countAlertsRisk + countAlertsNoRisk) +
                ")" +
                " " +
                T("alerts occurred")
            widgets.value.alertStatus.riskStatus = "medium"
        }

        widgets.value.httpTrafficStatus.data.Traffic = countTraffic
        if (countTraffic != 0) {
            widgets.value.httpTrafficStatus.riskStatus = "none"
        } else {
            widgets.value.httpTrafficStatus.riskStatus = "never"
        }

        widgets.value.loginStatus.data.Logins = loginStatus

        if (loginStatus == 0) {
            widgets.value.loginStatus.text = "No Logins"
            widgets.value.loginStatus.riskStatus = "none"
        } else if (loginStatus > 5) {
            widgets.value.loginStatus.text = "Logins"
            widgets.value.loginStatus.riskStatus = "high"
        } else if (loginStatus > 0) {
            widgets.value.loginStatus.text = "Logins"
            widgets.value.loginStatus.riskStatus = "medium"
        }
    } else {
        widgets.value.blockedThreats.riskStatus = "no license"
        widgets.value.antivirusClamStatus.riskStatus = "no license"
        widgets.value.antivirusCtavStatus.riskStatus = "no license"
        widgets.value.kernelStatus.riskStatus = "no license"
        widgets.value.alertStatus.riskStatus = "no license"
        widgets.value.httpTrafficStatus.riskStatus = "no license"
        widgets.value.loginStatus.riskStatus = "no license"
    }
}
const getBlockedThreadsLines = function () {
    let result = []

    if (widgets.value.blockedThreats.data.HttpVirus > 0) {
        result.push(
            widgets.value.blockedThreats.data.HttpVirus + " " + T("malicious HTTP-Requests blocked")
        )
    }
    if (widgets.value.blockedThreats.data.MailVirus > 0) {
        result.push(widgets.value.blockedThreats.data.MailVirus + " " + T("E-Mail viruses blocked"))
    }
    if (widgets.value.blockedThreats.data.KernelLog > 0) {
        result.push(widgets.value.blockedThreats.data.KernelLog + " " + T("C&C-Accesses logged"))
    }
    if (widgets.value.blockedThreats.data.KernelReject > 0) {
        result.push(
            widgets.value.blockedThreats.data.KernelReject + " " + T("C&C-Accesses blocked")
        )
    }
    if (
        widgets.value.blockedThreats.data.HttpUrlBlocked +
            widgets.value.blockedThreats.data.MailUrlBlocked >
        0
    ) {
        result.push(
            Number(widgets.value.blockedThreats.data.HttpUrlBlocked) +
                Number(widgets.value.blockedThreats.data.MailUrlBlocked) +
                " " +
                T("harmful URLs blocked")
        )
    }
    if (result.length == 0) {
        result.push(T("The system is protected"))
    }
    return result
}
const getWidgetBackground = function (status: RiskStatus, area: "header" | "body"): string {
    let bgAssignment = {
        never: {
            header: "#808080",
            body: "#A8A8A8"
        },
        none: {
            header: "#2DCC71",
            body: "#81E0AA"
        },
        medium: {
            header: "#F0AD4E",
            body: "#F6CE95"
        },
        high: {
            header: "#E74C3C",
            body: "#F1948A"
        },
        "no license": {
            header: "#777777",
            body: "#868686"
        },
        loading: {
            header: "#777777",
            body: "#868686"
        }
    }
    return bgAssignment[status][area]
}
const getWidgetIcon = function (status: RiskStatus) {
    let iconAssignment = {
        loading: "fal fa-spinner",
        never: "fal fa-shield",
        none: "fal fa-shield-check",
        medium: "fal fa-exclamation-circle",
        high: "fal fa-exclamation-triangle",
        "no license": "fal fa-shield"
    }
    return iconAssignment[status]
}

const mapClamAvAndCtAvStatusToText = function (statusClam: string, statusCtAv: string) {
    const statusListByPriority = ["loading", "never", "high", "medium", "none", "no license"]
    const clamStatusPriority = statusListByPriority.indexOf(statusClam)
    const ctStatusPriority = statusListByPriority.indexOf(statusCtAv)
    if (clamStatusPriority > ctStatusPriority) {
        return widgets.value.antivirusClamStatus.text
    } else if (clamStatusPriority < ctStatusPriority) {
        return widgets.value.antivirusCtavStatus.text
    } else {
        if (
            (widgets.value.antivirusClamStatus.text == "No info" &&
                widgets.value.antivirusCtavStatus.text == "Update started") ||
            (widgets.value.antivirusClamStatus.text == "Update started" &&
                widgets.value.antivirusCtavStatus.text == "No info")
        ) {
            return "Update started"
        }
        return widgets.value.antivirusClamStatus.text
    }
}

const mapClamAvAndCtAvStatus = function (statusClam: RiskStatus, statusCtAv: RiskStatus) {
    const statusListByPriority = ["loading", "never", "high", "medium", "none", "no license"]
    const clamStatusPriority = statusListByPriority.indexOf(statusClam)
    const ctStatusPriority = statusListByPriority.indexOf(statusCtAv)
    if (clamStatusPriority > ctStatusPriority) {
        return statusClam
    } else if (clamStatusPriority < ctStatusPriority) {
        return statusCtAv
    }
    return statusClam
}

const getRrdInfo = async function () {
    objectId.value
        ? await (
              config.canUseNewObjectType("uscUtms")
                  ? products.unifiedSecurityConsole.uscUtms
                  : objectStores.uscUtms
          ).sendRrdMessage(activeAccountId.value, objectId.value, {
              data: { def: "cpu-0", start: "-0h1440m", end: "-0h0m" }
          })
        : null
    objectId.value
        ? await (
              config.canUseNewObjectType("uscUtms")
                  ? products.unifiedSecurityConsole.uscUtms
                  : objectStores.uscUtms
          ).sendRrdMessage(activeAccountId.value, objectId.value, {
              data: { def: "memory", start: "-0h1440m", end: "-0h0m" }
          })
        : null
    objectId.value
        ? await (
              config.canUseNewObjectType("uscUtms")
                  ? products.unifiedSecurityConsole.uscUtms
                  : objectStores.uscUtms
          ).sendRrdMessage(activeAccountId.value, objectId.value, {
              data: { def: "load", start: "-0h1440m", end: "-0h0m" }
          })
        : null
    objectId.value
        ? await (
              config.canUseNewObjectType("uscUtms")
                  ? products.unifiedSecurityConsole.uscUtms
                  : objectStores.uscUtms
          ).sendRrdMessage(activeAccountId.value, objectId.value, {
              data: { def: "df-data", start: "-0h1440m", end: "-0h0m" }
          })
        : null
    rrdLoaded.value = true
}
const getBackupInfo = async function () {
    backupsTableData.value.loading = true
    delete utmVars.value.passwordSet
    utmVars.value.backups = []
    delete utmVars.value.GLOB_CLOUDBACKUP_TIME
    if (utmStatus.value?.online == true) {
        await execDeviceAction(utm.value, "extc global get variable", "GLOB_CLOUDBACKUP_TIME")
    }
    let result: any = await requestHandler.request(
        "GET",
        config.mgtApiUriNext +
            "/tenants/" +
            tenantHelpers.getTenantDomain(activeAccountId.value) +
            "/utms/" +
            objectId.value +
            "/backups?_=" +
            Date.now()
    )
    utmVars.value.backups = result?.filter((data: any) => {
        return data.cfgName ? true : false
    })
    backupsTableData.value.loading = false
    // #33726
    queuedBackupLoading()
}
const queuedBackupLoading = async function () {
    if (
        activeTab.value == "cloudBackup" &&
        utmVars.value.GLOB_CLOUDBACKUP_TIME == undefined &&
        utmStatus.value?.online == true
    ) {
        tabChanged.value = false

        // Waiting for 45 Seconds
        for (let iteration = 1; iteration <= 45; iteration++) {
            await timeHelpers.sleep(1000)
            // If the tab is switched, then the dialog should not be displayed
            //@ts-ignore
            if (tabChanged.value == true) break
        }
        if (
            activeTab.value == "cloudBackup" &&
            !tabChanged.value &&
            utmVars.value.GLOB_CLOUDBACKUP_TIME == undefined
        ) {
            dialogs.misc.errorDialog(
                activeAccountId.value,
                T("No UTM Connection"),
                T("The process takes unusually long. Please check your connection to the UTM.")
            )
        }
    }
}
const makeBackup = async function () {
    await (
        config.canUseNewObjectType("uscUtms")
            ? products.unifiedSecurityConsole.uscUtms
            : objectStores.uscUtms
    ).sendSpcliMessage(activeAccountId.value, objectId.value, {
        data: ["system", "cloudbackup", "store"]
    })
    getBackupInfo()
}
const deleteBackup = async function (backupId: string) {
    dialogs.misc.confirmDialog(
        activeAccountId.value,
        T("Are you sure?"),
        T("Do you really want to delete this backup?"),
        async function () {
            await (
                config.canUseNewObjectType("uscUtms")
                    ? products.unifiedSecurityConsole.uscUtms
                    : objectStores.uscUtms
            ).sendSpcliMessage(activeAccountId.value, utm.value.utmId, {
                data: ["system", "cloudbackup", "delete", "id", backupId]
            })
            useStore().commit(MutationTypes.addSubscriptionHook, {
                accountId: activeAccountId.value,
                hookKey: "utmDeleteBackup",
                hookFunction: async function (message: any) {
                    if (
                        message?.data?.utmId == objectId.value &&
                        message?.data?.clientContext == "spcli-system-cloudbackup-delete" &&
                        message?.data?.status == 200
                    ) {
                        getBackupInfo()
                        useStore().commit(MutationTypes.removeModal, {
                            accountId: activeAccountId.value
                        })
                        useStore().commit(MutationTypes.deleteSubscriptionHook, {
                            accountId: activeAccountId.value,
                            hookKey: "utmDeleteBackup"
                        })
                    }
                }
            })
        },
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        false
    )
}
const restoreBackup = async function (backupId: string, ctime: string = "") {
    await (
        config.canUseNewObjectType("uscUtms")
            ? products.unifiedSecurityConsole.uscUtms
            : objectStores.uscUtms.dialogs
    ).utmEnterPinDialog(
        utm.value,
        ["system", "cloudbackup", "restore", "id", backupId, "ctime", ctime],
        undefined,
        T("Restore backup"),
        true,
        ctime
    )
    getBackupInfo()
}
const initRrdGraphs = function () {
    let xCat: any[] = [],
        series: any[] = [],
        seriesCount: number = 0,
        dataEntryCount: number = 0,
        seriesColors: any[] = [
            {
                color: "#337AB7",
                borderColor: "#337AB7"
            },
            {
                color: "#F0AD4E",
                borderColor: "#F0AD4E"
            },
            {
                color: "#2DCC71",
                borderColor: "#2DCC71"
            },
            {
                color: "#E74C3C",
                borderColor: "#E74C3C"
            },
            {
                color: "#3498DB",
                borderColor: "#3498DB"
            },
            {
                color: "#673AB7",
                borderColor: "#673AB7"
            },
            {
                color: "#EB3976",
                borderColor: "#EB3976"
            },
            {
                color: "#FF5722",
                borderColor: "#FF5722"
            },
            {
                color: "#16B8A9",
                borderColor: "#16B8A9"
            },
            {
                color: "#795548",
                borderColor: "#795548"
            }
        ],
        propertyToChart = {
            cpu0Info: "cpu0Chart",
            loadInfo: "loadChart",
            memoryInfo: "memoryChart",
            dfDataInfo: "dfDataChart"
        }

    for (let property in rrdInfo.value) {
        let chartName = propertyToChart[property as keyof typeof propertyToChart]
        series = []
        seriesCount = 0
        dataEntryCount = 0
        xCat = []
        if (
            rrdInfo.value[property as keyof typeof rrdInfo.value] != undefined &&
            property != "timestamp"
        ) {
            rrdInfo.value[property as keyof typeof rrdInfo.value]?.meta.legend.forEach(function (
                name: string
            ) {
                let seriesObject: any = {
                    name: stringHelpers.capitalizeFirstLetter(name),
                    color: seriesColors[seriesCount].color,
                    borderColor: seriesColors[seriesCount].borderColor,
                    data: rrdInfo.value[property as keyof typeof rrdInfo.value].data.map(function (
                        dataEntry: number[]
                    ) {
                        let result: any = moment(
                            rrdInfo.value[property as keyof typeof rrdInfo.value].meta.start *
                                1000 +
                                dataEntryCount *
                                    (rrdInfo.value[property as keyof typeof rrdInfo.value].meta
                                        .step *
                                        1000)
                        ).format(LANG === "de" ? "HH:mm" : "h:mm A")
                        xCat.push(result)
                        dataEntryCount++
                        return dataEntry[seriesCount]
                    })
                }

                series.push(seriesObject)
                seriesCount++
            })

            let chartData: any = {
                chart: {
                    type: "line",
                    height: 250,
                    options: {
                        responsive: true,
                        maintainAspectRatio: false
                    }
                },
                credits: false,
                exporting: false,
                title: {
                    text: ""
                },
                xAxis: {
                    categories: xCat
                },

                plotOptions: {
                    series: {
                        stacking: "normal"
                    }
                },
                tooltip: {
                    formatter: function (this: any) {
                        return ["dfDataInfo", "memoryInfo"].indexOf(property) == -1
                            ? "<strong>" + this.point.series.name + "</strong><br> " + this.y + "%"
                            : "<strong>" +
                                  this.point.series.name +
                                  "</strong><br> " +
                                  numberHelpers.formatBytes(this.y, 2).value +
                                  numberHelpers.formatBytes(this.y).unit
                    }
                },
                series: series
            }

            if (
                charts.value[chartName as keyof typeof charts.value] == undefined ||
                charts.value[chartName as keyof typeof charts.value] == false
            ) {
                charts.value[chartName as keyof typeof charts.value] = chartData
                /*
                Highcharts.chart(
                    chartName,
                    chartData
                )
                    */
            } else {
                /*
                charts.value[chartName as keyof typeof charts.value].update(chartData)
                */
            }
        } else if (
            charts.value[chartName as keyof typeof charts.value] == undefined &&
            property != "timestamp"
        ) {
            charts.value[chartName as keyof typeof charts.value] = false
        }
    }
}
const getUtmState = async function (refresh: boolean = false) {
    utmVars.value.state.loading = true
    if (tenantHelpers.hasAccountRole("__SUPPORT__")) {
        if (refresh || utmVars.value.state.rejectsHistogramm.loading) {
            utmVars.value.state.rejectsHistogramm.InfoText = ""
            let totalRejects = 0
            try {
                let histogrammData = objectId.value
                    ? await (
                          config.canUseNewObjectType("uscUtms")
                              ? products.unifiedSecurityConsole.uscUtms
                              : objectStores.uscUtms
                      ).getUtmRejectsHistogramm(
                          activeAccountId.value,
                          objectId.value,
                          utmVars.value.state.period
                      )
                    : { result: [] }
                var histogrammChartOptions = {
                    type: "line",
                    data: {
                        labels: <string[]>[],
                        datasets: [
                            {
                                label: "",
                                data: <number[]>[],
                                backgroundColor: "rgba(235, 76, 54, 0.2)",
                                borderColor: "rgba(235, 76, 54, 1)",
                                fill: "start"
                            }
                        ]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        elements: { line: { tension: 0.4 } },
                        legend: { display: false },
                        scales: {
                            xAxes: [{ stacked: true }],
                            yAxes: [
                                {
                                    ticks: { beginAtZero: true },
                                    scaleLabel: { display: true, labelString: "Rejects" }
                                }
                            ]
                        }
                    }
                }

                if (histogrammData.result?.length > 0) {
                    histogrammData.result.forEach((bucket: { count: number; interval: number }) => {
                        let bucketTime = bucket.interval
                        let rejectsCount = bucket.count
                        let bucketDate = timeHelpers.formatDate(
                            bucketTime,
                            timeHelpers.getDateFormatI18n(
                                ["lastday", "today"].indexOf(utmVars.value.state.period) != -1
                            )
                        )

                        totalRejects = totalRejects + rejectsCount

                        histogrammChartOptions.data.datasets[0].data.push(rejectsCount)
                        histogrammChartOptions.data.labels.push(bucketDate)
                    })
                    utmVars.value.state.rejectsHistogramm.loading = false

                    let thisChart =
                        Chart.getChart("0") || Chart.getChart("utm-rejects-histogramm") || undefined
                    if (thisChart) {
                        thisChart.destroy()
                    }

                    var context = (<HTMLCanvasElement>(
                        document.getElementById("utm-rejects-histogramm")
                    ))!.getContext("2d")
                    //@ts-ignore
                    var chart = new Chart(context, histogrammChartOptions)
                } else {
                    utmVars.value.state.rejectsHistogramm.loading = false
                    utmVars.value.state.rejectsHistogramm.InfoText = T("No data found")
                }
                utmVars.value.state.totalRejects.value = totalRejects
                utmVars.value.state.totalRejects.loading = false
            } catch (e: any) {
                console.error(e)
            }
        }

        if (refresh || utmVars.value.state.latestRejects.loading) {
            let rejectsData = objectId.value
                ? await (
                      config.canUseNewObjectType("uscUtms")
                          ? products.unifiedSecurityConsole.uscUtms
                          : objectStores.uscUtms
                  ).getUtmLatestRejects(
                      activeAccountId.value,
                      objectId.value,
                      utmVars.value.state.period
                  )
                : { result: [] }
            if (rejectsData.result?.length > 0) {
                let titles: any = [
                    {
                        id: "connectedOn",
                        text: "Connected on",
                        sortable: false,
                        width: 185
                    },
                    {
                        id: "ip",
                        text: "IP",
                        sortable: false,
                        width: 200
                    },
                    {
                        id: "lastContact",
                        text: "Last contact",
                        sortable: false,
                        width: 200
                    },
                    {
                        id: "server",
                        text: "Server",
                        sortable: false,
                        width: 200
                    },
                    {
                        id: "type",
                        text: "Type",
                        sortable: false
                    }
                ]
                let rows: any = []
                rejectsData.result.forEach(
                    (bucket: {
                        connectedOn: string
                        ip: string
                        isodate: number
                        lastContact: number
                        server: string
                        type: string
                    }) => {
                        rows.push({
                            data: {
                                connectedOn: bucket.connectedOn,
                                ip: bucket.ip,
                                lastContact: timeHelpers.formatDate(
                                    bucket.lastContact,
                                    timeHelpers.getDateFormatI18n(true)
                                ),
                                server: bucket.server,
                                type: bucket.type
                            }
                        })
                    }
                )

                utmVars.value.state.latestRejects.tableData.titles = titles
                utmVars.value.state.latestRejects.tableData.rows = rows
            }
            utmVars.value.state.latestRejects.tableData.loading = false
        }
    }
    utmVars.value.state.loading = false
}
const getWebsessionDialog = function () {
    ;(config.canUseNewObjectType("uscUtms")
        ? products.unifiedSecurityConsole.uscUtms
        : objectStores.uscUtms.dialogs
    ).renderNewWebSessionDialog(activeAccountId.value, objectId.value)
}

const canUseFeature = function (utmId: UscUtm["utmId"], featureName: CanUseFeatureNames) {
    return (
        config.canUseNewObjectType("uscUtms")
            ? products.unifiedSecurityConsole.uscUtms
            : objectStores.uscUtms
    ).canUseFeature(utmId, featureName, activeAccountId.value)
}
const tabsList = ref([
    {
        text: T("Overview"),
        tooltip: new Tooltip({
            contents: [T("Overview")]
        }),
        id: "overview",
        icon: "fal fa-server"
    },
    {
        text: T("Cluster"),
        tooltip: new Tooltip({
            contents: [T("Cluster")]
        }),
        id: "cluster",
        icon: "fal fa-boxes",
        visible: utm.value?.cluster?.isCluster
    },
    {
        text: T("Operations"),
        id: "operations",
        icon: "fal fa-wrench",
        tooltip: new Tooltip({
            contents: [
                canUseFeature(objectId.value, "sendJobs").result === false &&
                utmStatus.value?.online
                    ? getTooltip(canUseFeature(objectId.value, "sendJobs").reasons)
                    : T("Operations")
            ]
        }),
        disabled: () => {
            return !utmStatus.value?.online || !canUseFeature(objectId.value, "sendJobs").result
        }
    },
    {
        text: T("Websession"),
        tooltip: new Tooltip({
            contents: [
                (function () {
                    return canUseFeature(objectId.value, "websession").result === false &&
                        utmStatus.value?.online
                        ? getTooltip(canUseFeature(objectId.value, "websession").reasons)
                        : T("Websession")
                })()
            ]
        }),
        id: "websession",
        icon: "fal fa-laptop",
        disabled: () => {
            return !utmStatus.value?.online || !canUseFeature(objectId.value, "websession").result
        }
    },
    {
        text: T("Cloud-Backup"),
        tooltip: new Tooltip({
            contents: [
                canUseFeature(objectId.value, "cloudBackup").result === false &&
                utmStatus.value?.online
                    ? getTooltip(canUseFeature(objectId.value, "cloudBackup").reasons)
                    : T("Cloud-Backup")
            ]
        }),
        id: "cloudBackup",
        icon: "fal fa-cloud-upload-alt",
        disabled: () => {
            return !canUseFeature(objectId.value, "cloudBackup").result
        },
        onClick: () => {
            getBackupInfo()
        }
    },
    {
        text: T("Operations") + " Log",
        tooltip: new Tooltip({
            contents: [T("Operations") + " Log"]
        }),
        id: "operationsLog",
        icon: "fal fa-list"
    },
    {
        text: T("Applications"),
        tooltip: new Tooltip({
            contents: [
                canUseFeature(objectId.value, "applications").result === false &&
                utmStatus.value?.online
                    ? getTooltip(canUseFeature(objectId.value, "applications").reasons)
                    : T("Applications")
            ]
        }),
        id: "applications",
        icon: "fal fa-list",
        disabled: () => {
            return !utmStatus.value?.online || !canUseFeature(objectId.value, "applications").result
        }
    },
    {
        text: T("xState"),
        tooltip: new Tooltip({
            contents: [
                canUseFeature(objectId.value, "status").result === false && utmStatus.value?.online
                    ? getTooltip(canUseFeature(objectId.value, "status").reasons)
                    : T("xState")
            ]
        }),
        id: "status",
        icon: "fal fa-rocket",
        disabled: () => {
            return !utmStatus.value?.online || !canUseFeature(objectId.value, "status").result
        },
        onClick: () => {
            if (!rrdLoaded.value) {
                getRrdInfo()
            }
        }
    },
    {
        text: T("Security Scan"),
        tooltip: new Tooltip({
            contents: [
                canUseFeature(objectId.value, "scans").result === false && utmStatus.value?.online
                    ? getTooltip(canUseFeature(objectId.value, "scans").reasons)
                    : T("Security Scan")
            ]
        }),
        id: "securityScan",
        icon: "fal fa-eye",
        disabled: () => {
            return !utmStatus.value?.online || !canUseFeature(objectId.value, "scans").result
        }
    },
    {
        text: T("Inventory"),
        tooltip: new Tooltip({
            contents: [T("Inventory")]
        }),
        id: "inventory",
        icon: "fal fa-info"
    },
    {
        text: T("UTM-State"),
        tooltip: new Tooltip({
            contents: [T("UTM-State")]
        }),
        id: "utmState",
        icon: "fal fa-project-diagram",
        visible: () => {
            return tenantHelpers.hasAccountRole("__SUPPORT__")
        },
        onClick: () => {
            getUtmState(true)
        }
    },
    {
        text: T("Cloud Scheduler Log"),
        tooltip: new Tooltip({
            contents: [T("Cloud Scheduler Log")]
        }),
        id: "jobs",
        icon: "fal fa-list",
        visible: () => {
            return canUseUscJobs.value
        }
    }
])
const versionCompare = validationHelpers.versionCompare
const hasLessThan3GBRam = function (item: UscUtm) {
    return (
        config.canUseNewObjectType("uscUtms")
            ? products.unifiedSecurityConsole.uscUtms
            : objectStores.uscUtms
    ).hasLessThan3GBRam(item as UscUtmNew)
}

/* Cloud Backup */
const disableCloudBackup = function () {
    dialogs.misc.confirmDialog(
        activeAccountId.value,
        T("Are you sure?"),
        T("Are you sure to disable the cloud-backup functionality?"),
        async () => {
            await (
                config.canUseNewObjectType("uscUtms")
                    ? products.unifiedSecurityConsole.uscUtms
                    : objectStores.uscUtms
            ).sendSpcliMessage(activeAccountId.value, objectId.value, {
                data: ["extc", "global", "set", "variable", "GLOB_CLOUDBACKUP_TIME", "value", "0"]
            })
            await (
                config.canUseNewObjectType("uscUtms")
                    ? products.unifiedSecurityConsole.uscUtms
                    : objectStores.uscUtms
            ).sendSpcliMessage(activeAccountId.value, objectId.value, {
                data: ["system", "config", "save"]
            })
            await (
                config.canUseNewObjectType("uscUtms")
                    ? products.unifiedSecurityConsole.uscUtms
                    : objectStores.uscUtms
            ).sendSpcliMessage(activeAccountId.value, objectId.value, {
                data: ["system", "update", "system"]
            })
        }
    )
}
const enableCloudBackup = function () {
    if (utmVars.value.passwordSet == false) {
        renderSetCloudBackupPassword(true)
    } else {
        dialogs.misc.confirmDialog(
            activeAccountId.value,
            T("Are you sure?"),
            T("Are you sure to enable the cloud-backup functionality?"),
            async () => {
                await (
                    config.canUseNewObjectType("uscUtms")
                        ? products.unifiedSecurityConsole.uscUtms
                        : objectStores.uscUtms
                ).sendSpcliMessage(activeAccountId.value, objectId.value, {
                    data: [
                        "extc",
                        "global",
                        "set",
                        "variable",
                        "GLOB_CLOUDBACKUP_TIME",
                        "value",
                        "1"
                    ]
                })
                await (
                    config.canUseNewObjectType("uscUtms")
                        ? products.unifiedSecurityConsole.uscUtms
                        : objectStores.uscUtms
                ).sendSpcliMessage(activeAccountId.value, objectId.value, {
                    data: ["system", "config", "save"]
                })
                await (
                    config.canUseNewObjectType("uscUtms")
                        ? products.unifiedSecurityConsole.uscUtms
                        : objectStores.uscUtms
                ).sendSpcliMessage(activeAccountId.value, objectId.value, {
                    data: ["system", "update", "system"]
                })
            }
        )
    }
}

let backupReqex = /SPCB\-(.*)?\-(....\-..\-.. ..:..:..)_?(.*)?/
const backupsTableInfo: TableEntryInfo[] = [
    {
        text: T("Time"),
        displayType: "text",
        getValue: (entry: any) => {
            const strippedData = entry.cfgName.match(backupReqex) || [, "", "", ""]
            const thisDate = strippedData[2] || ""
            return getDate(thisDate, true)
        },
        getSortValue: (entry: any) => {
            const strippedData = entry.cfgName.match(backupReqex) || [, "", "", ""]
            const thisDate = strippedData[2] || ""
            return String(moment(thisDate).unix())
        },
        property: "time"
    },
    {
        text: "Name",
        displayType: "text",
        getValue: (entry: any) => {
            const strippedData = entry.cfgName.match(backupReqex) || [, "", "", ""]
            const thisName = strippedData[3] || ""
            return thisName
        },
        property: "cfgName",
        getSortValue: (entry) => {
            const strippedData = entry.cfgName.match(backupReqex) || [, "", "", ""]
            const thisName = strippedData[3] || ""
            return thisName
        }
    },
    {
        text: "Version",
        displayType: "text",
        getValue: (entry: any) => {
            return entry.cfgVersion
        },
        getSortValue: (entry: any) => {
            return entry.cfgVersion
        },
        property: "cfgVersion"
    },
    {
        text: T("Actions"),
        displayType: "buttons",
        getValue: (entry: any) => {
            const strippedData = entry.cfgName.match(backupReqex) || [, "", "", ""]
            const thisDate = strippedData[2] || ""
            return [
                new Button({
                    text: T("Download"),
                    title: T("Download config"),
                    icon: "fal fas-fw fa-download",
                    onClick: async function () {
                        queries.misc.download(
                            config.mgtApiUriNext +
                                "/tenants/" +
                                tenantHelpers.getTenantDomain(activeAccountId.value) +
                                "/utms/" +
                                objectId.value +
                                "/backups/" +
                                entry.file_id,
                            "utm_cloud_backup_" + thisDate + ".utm"
                        )
                    },
                    disabled: false,
                    loading: false
                }),
                new Button({
                    text: T("Restore"),
                    title: !utm.value.pinState.enabled
                        ? T(
                              "Dieses Feature ist auf dieser UTM nicht verfügbar, da die PIN deaktiviert ist."
                          )
                        : utm.value.pinState.locked
                          ? T(
                                "Dieses Feature ist auf dieser UTM nicht verfügbar, da die PIN gesperrt ist."
                            )
                          : T("Restore"),
                    icon: "fal fas-fw fa-undo",
                    onClick: async function () {
                        restoreBackup(
                            entry.file_id,
                            Math.floor(new Date(entry.uploadDate).getTime() / 1000).toString()
                        )
                    },
                    disabled: !utm.value.pinState.enabled || utm.value.pinState.locked,
                    loading: false,
                    topRightCircle: {
                        icon: "fal fa-lock"
                    }
                }),
                new Button({
                    text: T("Delete"),
                    title: T("Delete"),
                    icon: "fal fa-fw fa-trash",
                    onClick: async function () {
                        deleteBackup(entry.file_id)
                    },
                    disabled: false,
                    loading: false
                })
            ]
        },
        property: "actions"
    }
]

watch(
    utm,
    (utm: any, utmOld: any) => {
        if (utm != undefined) {
            refreshCountDetails.value++
        }
    },
    {
        deep: true
    }
)

watch(
    rrdInfo,
    (rrdInfo: any[]) => {
        nextTick(function () {
            initRrdGraphs()
        })
    },
    {
        deep: true
    }
)

watch(
    () => utmVars.value.state.period,
    function () {
        getUtmState(true)
    }
)
watch(
    () => widgets.value.period,
    function () {
        getUsrData()
    }
)

/* Scans */

const scans = ref(<any[]>[])
const openedScanSubTables = ref(<string[]>[])
const scansTable: TableEntryInfo[] = [
    {
        text: T("Time"),
        displayType: "text",
        property: "latUpdate",
        getValue: (entry: any) => {
            return getDate(entry.lastUpdate, true)
        },
        getSortValue: (entry: any) => {
            return entry.lastUpdate
        }
    },
    {
        text: T("Interface"),
        displayType: "text",
        property: "host",
        getValue: (entry: any) => {
            return entry.status == "RECEIVED" ? entry.result?.host?.address || "" : ""
        },
        getSortValue: (entry: any) => {
            return entry.status == "RECEIVED" ? entry.result?.host?.address || "" : ""
        }
    },
    {
        text: T("Profil"),
        displayType: "text",
        property: "profile",
        getValue: (entry: any) => {
            return "Securepoint Top Ports"
        },
        getSortValue: (entry: any) => {
            return "Securepoint Top Ports"
        }
    },
    {
        text: T("xState"),
        displayType: "labels",
        property: "state",
        getValue: (entry: any) => {
            return [
                new Label({
                    title: T(stringHelpers.capitalizeFirstLetter(entry.status)),
                    text: T(stringHelpers.capitalizeFirstLetter(entry.status)),
                    class:
                        entry.status == "ERROR"
                            ? "bg-red"
                            : entry.status == "PENDING"
                              ? "bg-yellow"
                              : "",
                    icon:
                        entry.status == "ERROR"
                            ? "fal fa-exclamation-triangle"
                            : entry.status == "PENDING"
                              ? "fal fa-bell"
                              : "fal fa-info"
                })
            ]
        },
        getSortValue: (entry: any) => {
            return T(stringHelpers.capitalizeFirstLetter(entry.status))
        }
    },
    {
        text: T("Result"),
        displayType: "text",
        property: "result",
        getValue: (entry: any) => {
            return (function () {
                let result = ""
                let invalidScanIpRegex = new RegExp(
                    /Invalid scan ip. Request ip ([0-9.]*) is not equals with the scan ip ([0-9.]*)/i
                )
                if (entry.status == "RECEIVED") {
                    result =
                        entry.result?.host?.ports?.length == 1
                            ? T("Found 1 open port")
                            : sprintf(
                                  T("Found %s open ports"),
                                  entry.result?.host?.ports?.length || "0"
                              )
                } else {
                    let regexResult = invalidScanIpRegex.exec(entry.statusText)

                    if (regexResult?.length == 3) {
                        result = sprintf(
                            T("Invalid scan IP. Request IP %s is not equal to %s."),
                            regexResult[1],
                            regexResult[2]
                        )
                    } else {
                        result = T(entry.statusText)
                    }
                }
                return result
            })()
        },
        getSortValue: (entry: any) => {
            return (function () {
                let result = ""
                let invalidScanIpRegex = new RegExp(
                    /Invalid scan ip. Request ip ([0-9.]*) is not equals with the scan ip ([0-9.]*)/i
                )
                if (entry.status == "RECEIVED") {
                    result =
                        entry.result?.host?.ports?.length == 1
                            ? T("Found 1 open port")
                            : sprintf(
                                  T("Found %s open ports"),
                                  entry.result?.host?.ports?.length || "0"
                              )
                } else {
                    let regexResult = invalidScanIpRegex.exec(entry.statusText)

                    if (regexResult?.length == 3) {
                        result = sprintf(
                            T("Invalid scan IP. Request IP %s is not equal to %s."),
                            regexResult[1],
                            regexResult[2]
                        )
                    } else {
                        result = T(entry.statusText)
                    }
                }
                return result
            })()
        }
    },
    {
        text: T("Actions"),
        displayType: "buttons",
        property: "actions",
        getValue: (entry: any) => {
            if (entry.status == "RECEIVED") {
                return [
                    new Button({
                        text:
                            openedScanSubTables.value.indexOf(entry.id as string) == -1
                                ? T("Show details")
                                : T("Hide details"),
                        title:
                            openedScanSubTables.value.indexOf(entry.id as string) == -1
                                ? T("Show details")
                                : T("Hide details"),
                        icon: "fal fa-info-circle",
                        onClick: () => {
                            if (openedScanSubTables.value.indexOf(entry.id as string) != -1) {
                                openedScanSubTables.value = openedScanSubTables.value.filter(
                                    (id) => {
                                        return id != entry.id
                                    }
                                )
                            } else {
                                openedScanSubTables.value.push(entry.id)
                            }
                        },
                        disabled: false,
                        loading: false
                    })
                ]
            }
            return []
        }
    },
    {
        displayType: "subTable",
        getObjects: (entry) => {
            return entry.result?.host?.ports
        },
        isOpened: (entry) => {
            return openedScanSubTables.value.indexOf(entry.id as string) != -1
        },
        entries: [
            {
                property: "port",
                title: T("Port"),
                text: T("Port"),
                displayType: "text",
                getValue: (entry) => {
                    return entry.number
                }
            },
            {
                property: "protocol",
                title: T("Protocol"),
                text: T("Protocol"),
                displayType: "text",
                getValue: (entry) => {
                    return entry.protocol
                }
            },
            {
                property: "reason",
                title: T("Reason"),
                text: T("Reason"),
                displayType: "text",
                getValue: (entry) => {
                    return entry.reason
                }
            },
            {
                property: "service",
                title: T("Service"),
                text: T("Service"),
                displayType: "text",
                getValue: (entry) => {
                    return (
                        (
                            scanPorts.filter((thisPortInfo: any) => {
                                return thisPortInfo.port == entry.number
                            }) || []
                        ).map((thisPortInfo: any) => {
                            return thisPortInfo.usedBy
                        }) || []
                    ).join(", ")
                }
            }
        ]
    }
]
const scansLoading = ref(true)
watch(
    scans,
    (scansData: any[]) => {
        nextTick(() => {
            scansLoading.value = false
        })
    },
    { deep: true }
)

onMounted(() => {
    useStore().commit(MutationTypes.addSubscriptionHook, {
        accountId: activeAccountId.value,
        hookKey: "utmPage",
        hookFunction: async function (message: any) {
            if (message.topic == "/usc/utm/" + objectId.value + "/message") {
                let jobId: string = message.data?.id
                let jobType: string = message.data?.type || "spcli"
                let jobContext: string =
                    message.data?.clientContext
                        .replace(jobType + "-", "")
                        .split("-")
                        .join(" ") || "UNKNOWN"
                let jobStatus: string = (
                    message.data?.data?.state ||
                    message.data?.data?.hint?.status ||
                    (message.data?.status == 200 ? "OK" : "UNKNOWN") ||
                    "UNKNOWN"
                ).toUpperCase()
                let jobStatusText: string =
                    message.data?.data?.message ||
                    message.data?.data?.hint?.message ||
                    message.data?.data?.[0]?.message ||
                    message.data?.data?.payload?.[0]?.message ||
                    ""
                let jobLastUpdate: number = message.timestamp || moment().unix()
                let jobIssued: undefined | number = message.data?.data?.start || undefined
                let jobEnded: undefined | number = message.data?.data?.end || undefined
                let jobQueue: string = "in"

                let isAbortable: boolean = true

                try {
                    if (
                        message.data?.status == 500 ||
                        message.data?.data.status == 500 ||
                        message.data?.data.status == 403
                    ) {
                        const nonErrorsMsgs: string[] = [
                            //"backup_restore: file already exists"
                            "locked",
                            "Invalid pin"
                        ]
                        if (
                            jobStatusText ===
                                "process_response: command failed: Rate-Limit exceeded." &&
                            jobContext == "system cloudbackup store"
                        ) {
                            jobStatusText = T(
                                "Process response: Command failed. You can only create a backup every 24h."
                            )
                        }

                        if (nonErrorsMsgs.indexOf(jobStatusText) == -1) {
                            frontendNotifications.addNotification(activeAccountId.value, {
                                accountId: activeAccountId.value,
                                content: {
                                    title: {
                                        text:
                                            utm.value.utmname +
                                            " | " +
                                            (message.data?.data.error || "An error occurred"),
                                        icon: "fal fa-exclamation"
                                    },
                                    body: {
                                        content: jobStatusText
                                    }
                                },
                                id: "utmDetailsError-" + timeHelpers.getUnixTimeStamp() * 1000,
                                timestamp: timeHelpers.getUnixTimeStamp() * 1000,
                                highlightNew: true,
                                state: "new",
                                product: "Unified Security Console"
                            })
                        }
                    }

                    if (jobContext == "system info") {
                        if (message.data?.status == 200) {
                            jobStatus = "RECEIVED"
                        }
                        // add spcli-system-info if it does not exist
                        if (!getUtmMessageData(utm.value, "spcli-system-info")) {
                            if (config.canUseNewObjectType("uscUtms")) {
                                products.unifiedSecurityConsole.uscUtms
                                    .useStore?.()
                                    .setObjectTypeObjectProperty(
                                        activeAccountId.value,
                                        message.data?.utmId,
                                        {
                                            timestamp: 0,
                                            data: {
                                                av: {},
                                                cpu: {},
                                                license: {},
                                                memory: {},
                                                network: {},
                                                storage: {},
                                                swap: {},
                                                update: {},
                                                timestamp: 0
                                            }
                                        },
                                        "spcli-system-info",
                                        "messages"
                                    )
                            } else {
                                await getterHelpers
                                    .useStore()
                                    .commit(MutationTypes.setObjectProperty, {
                                        accountId: activeAccountId.value,
                                        objectId: message.data?.utmId,
                                        productType: "unifiedSecurityConsole",
                                        objectType: "uscUtms",
                                        queryType: "uscUtms",
                                        property: "spcli-system-info",
                                        objectPropertyPath: ["messages"],
                                        value: {
                                            timestamp: 0,
                                            data: {
                                                av: {},
                                                cpu: {},
                                                license: {},
                                                memory: {},
                                                network: {},
                                                storage: {},
                                                swap: {},
                                                update: {},
                                                timestamp: 0
                                            }
                                        }
                                    })
                            }
                        }

                        // update system info
                        for (let property in message.data?.data) {
                            // skip if timestamp is older than the one in utm object
                            if (
                                property == "timestamp" &&
                                message.data.data?.timestamp <
                                    getUtmMessageData(utm.value, "spcli-system-info")?.timestamp
                            ) {
                                continue
                            }
                            if (config.canUseNewObjectType("uscUtms")) {
                                products.unifiedSecurityConsole.uscUtms
                                    .useStore?.()
                                    .setObjectTypeObjectProperty(
                                        activeAccountId.value,
                                        message.data?.utmId,
                                        message.data?.data[property],
                                        property,
                                        ["messages", "spcli-system-info", "data"]
                                    )
                            } else {
                                await getterHelpers
                                    .useStore()
                                    .commit(MutationTypes.setObjectProperty, {
                                        accountId: activeAccountId.value,
                                        objectId: message.data?.utmId,
                                        productType: "unifiedSecurityConsole",
                                        objectType: "uscUtms",
                                        queryType: "uscUtms",
                                        property: property,
                                        objectPropertyPath: [
                                            "messages",
                                            "spcli-system-info",
                                            "data"
                                        ],
                                        value: message.data?.data[property]
                                    })
                            }
                        }

                        if (message.data?.data.update) {
                            let updateInfo: any = message.data?.data
                            if (config.canUseNewObjectType("uscUtms")) {
                                products.unifiedSecurityConsole.uscUtms
                                    .useStore?.()
                                    .setObjectTypeObjectProperty(
                                        activeAccountId.value,
                                        message.data?.utmId,
                                        message.data?.data.update,
                                        "data",
                                        ["messages", "merged-update-info"]
                                    )
                            } else {
                                await getterHelpers
                                    .useStore()
                                    .commit(MutationTypes.setObjectProperty, {
                                        accountId: activeAccountId.value,
                                        objectId: message.data?.utmId,
                                        productType: "unifiedSecurityConsole",
                                        objectType: "uscUtms",
                                        queryType: "uscUtms",
                                        property: "data",
                                        objectPropertyPath: ["messages", "merged-update-info"],
                                        value: message.data?.data.update
                                    })
                            }
                        }

                        refreshCount.value++
                    }

                    if (
                        [
                            "appmgmt start application",
                            "appmgmt stop application",
                            "appmgmt restart application"
                        ].indexOf(jobContext) != -1
                    ) {
                        if (message.data?.status == 200) {
                            execDeviceAction(utm.value, "appmgmt status")
                        }
                    }

                    if (jobContext == "appmgmt status") {
                        if (message?.data?.data?.length) {
                            let appData: any[] = message.data?.data
                            if (config.canUseNewObjectType("uscUtms")) {
                                products.unifiedSecurityConsole.uscUtms
                                    .useStore?.()
                                    .setObjectTypeObjectProperty(
                                        activeAccountId.value,
                                        message.data?.utmId,
                                        message.data?.data,
                                        "spcli-appmgmt-status",
                                        ["messages"]
                                    )
                            } else {
                                await getterHelpers
                                    .useStore()
                                    .commit(MutationTypes.setObjectProperty, {
                                        accountId: activeAccountId.value,
                                        objectId: message.data?.utmId,
                                        productType: "unifiedSecurityConsole",
                                        objectType: "uscUtms",
                                        queryType: "uscUtms",
                                        property: "spcli-appmgmt-status",
                                        objectPropertyPath: ["messages"],
                                        value: message.data?.data
                                    })
                            }
                            refreshCount.value++
                        }
                        await getAppsTableData()
                    }

                    if (jobContext == "extc global get") {
                        if (message.data?.status == 200) {
                            jobStatus = "RECEIVED"
                        }
                        // loop through data array
                        execDeviceAction(utm.value, "system cloudbackup password status get")
                        message.data?.data.forEach(function (data: any) {
                            utmVars.value[data.name] = data.value
                        })
                    }
                    if (jobContext == "extc global set") {
                        if (message.data?.status == 200) {
                            jobStatus = "RECEIVED"
                        }
                        backupsTableData.value.loading = true
                        setTimeout(() => {
                            getBackupInfo()
                        }, 4000)
                    }

                    if (jobContext == "system cloudbackup password") {
                        if (message.data?.status == 200) {
                            jobStatus = "RECEIVED"
                        }
                        if (message.data?.data[0].state === 1) {
                            utmVars.value.passwordSet = true
                            //execDeviceAction(utm.value,'system cloudbackup get')
                        } else {
                            utmVars.value.passwordSet = false
                        }
                    }
                    if (jobContext == "system cloudbackup get") {
                        if (message.data?.status == 200) {
                            jobStatus = "RECEIVED"
                        }
                        utmVars.value.backups = message.data?.data?.filter((data: any) => {
                            return data.cfgName ? true : false
                        })
                    }
                    if (
                        jobContext == "system cloudbackup store" &&
                        (message.data?.status == 200 || message.data?.data?.status == 200)
                    ) {
                        getBackupInfo()
                    }

                    if (jobContext == "system reboot") {
                        if (jobStatus != "OK") {
                            if (!getterHelpers.useVue()?.$refs?.modals?.$refs?.modal) {
                                throw "system reboot"
                            }
                        }
                        if (jobStatusText == "UTM is going down for reboot") {
                            jobStatus = "REBOOTING"
                        } else if (jobStatusText == "rollback") {
                            jobQueue = "in"
                            jobStatus = "REBOOTED"
                            let rebootDialogText = T("The UTM rebooted was successful")
                            if (jobEnded && jobIssued) {
                                jobStatusText =
                                    T("Time to reboot:") +
                                    " " +
                                    moment
                                        .duration(
                                            moment.unix(jobEnded).diff(moment.unix(jobIssued))
                                        )
                                        .asSeconds() +
                                    " " +
                                    T("seconds")
                                if (
                                    moment
                                        .duration(
                                            moment.unix(jobEnded).diff(moment.unix(jobIssued))
                                        )
                                        .asSeconds() >= 0
                                ) {
                                    rebootDialogText =
                                        T("The reboot of the UTM took") +
                                        " " +
                                        moment
                                            .duration(
                                                moment.unix(jobEnded).diff(moment.unix(jobIssued))
                                            )
                                            .asSeconds() +
                                        " " +
                                        T("seconds")
                                }
                            }

                            dialogs.misc.confirmDialog(
                                activeAccountId.value,
                                T("Reboot"),
                                rebootDialogText,
                                async function () {
                                    useStore().commit(MutationTypes.removeModal, {
                                        accountId: activeAccountId.value
                                    })
                                },
                                undefined,
                                undefined,
                                undefined,
                                false
                            )
                        }
                    }

                    if (jobContext == "system config factorysettings") {
                        if (jobStatus != "OK") {
                            if (!getterHelpers.useVue()?.$refs?.modals?.$refs?.modal) {
                                throw "system config factorysettings"
                            }
                        }
                        if (jobStatusText == "UTM restored the factory settings") {
                            jobQueue = "in"
                            jobStatus = "ACKNOWLEDGED"
                            if (jobEnded && jobIssued) {
                                jobStatusText =
                                    T("Time to reset:") +
                                    " " +
                                    moment
                                        .duration(
                                            moment.unix(jobEnded).diff(moment.unix(jobIssued))
                                        )
                                        .asSeconds() +
                                    " " +
                                    T("seconds")

                                dialogs.misc.confirmDialog(
                                    activeAccountId.value,
                                    T("Rollback"),
                                    T("The factory reset of the UTM took") +
                                        " " +
                                        moment
                                            .duration(
                                                moment.unix(jobEnded).diff(moment.unix(jobIssued))
                                            )
                                            .asSeconds() +
                                        " " +
                                        T("seconds"),
                                    async function () {
                                        useStore().commit(MutationTypes.removeModal, {
                                            accountId: activeAccountId.value
                                        })
                                    }
                                )
                            }
                        }
                    }
                    if (jobContext == "cpu 0") {
                        if (message.data?.status == 200) {
                            jobStatus = "OK"
                        }
                        if (jobStatus != "OK") {
                            throw "Error"
                        }
                        rrdInfo.value.cpu0Info = {
                            meta: message.data?.data?.payload?.meta,
                            data: message.data?.data?.payload?.data
                        }
                        rrdInfo.value.timestamp = message.timestamp
                    }
                    if (jobContext == "load") {
                        if (message.data?.status == 200) {
                            jobStatus = "OK"
                        }
                        if (jobStatus != "OK") {
                            throw "Error"
                        }
                        rrdInfo.value.loadInfo = {
                            meta: message.data?.data?.payload?.meta,
                            data: message.data?.data?.payload?.data
                        }
                        rrdInfo.value.timestamp = message.timestamp
                    }
                    if (jobContext == "memory") {
                        if (message.data?.status == 200) {
                            jobStatus = "OK"
                        }
                        if (jobStatus != "OK") {
                            throw "Error"
                        }
                        rrdInfo.value.memoryInfo = {
                            meta: message.data?.data?.payload?.meta,
                            data: message.data?.data?.payload?.data
                        }
                        rrdInfo.value.timestamp = message.timestamp
                    }
                    if (jobContext == "df data") {
                        if (message.data?.status == 200) {
                            jobStatus = "OK"
                        }
                        if (jobStatus != "OK") {
                            throw "Error"
                        }
                        rrdInfo.value.dfDataInfo = {
                            meta: message.data?.data?.payload?.meta,
                            data: message.data?.data?.payload?.data,
                            tooltip: {
                                formatter: function (this: any) {
                                    return numberHelpers.formatBytesToMb(Number(this.y), 5)
                                }
                            }
                        }
                        rrdInfo.value.timestamp = message.timestamp
                    }

                    if (
                        jobContext == "system upgrade finalize" ||
                        jobContext.indexOf("eula") != -1 ||
                        jobContext.indexOf("privacy") != -1
                    ) {
                        execDeviceAction(utm.value, "system info")
                    }

                    if (jobContext == "merged update info") {
                        if (message.data?.status == 200) {
                            jobStatus = "OK"
                        }
                        if (jobStatus != "OK") {
                            throw "merged update info"
                        }
                        let updateInfo: any = message.data?.data
                        if (config.canUseNewObjectType("uscUtms")) {
                            products.unifiedSecurityConsole.uscUtms
                                .useStore?.()
                                .setObjectTypeObjectProperty(
                                    activeAccountId.value,
                                    message.data?.utmId,
                                    updateInfo,
                                    "data",
                                    ["messages", "merged-update-info"]
                                )
                        } else {
                            await getterHelpers.useStore().commit(MutationTypes.setObjectProperty, {
                                accountId: activeAccountId.value,
                                objectId: message.data?.utmId,
                                productType: "unifiedSecurityConsole",
                                objectType: "uscUtms",
                                queryType: "uscUtms",
                                property: "data",
                                objectPropertyPath: ["messages", "merged-update-info"],
                                value: updateInfo
                            })
                        }
                    }
                    if (jobContext == "merged reboot info") {
                        await objectStores.uscUtms.getObjectFromApi(
                            activeAccountId.value,
                            objectId.value
                        )
                        if (message.data?.status == 200) {
                            jobStatus = "OK"
                        }
                        if (jobStatus != "OK") {
                            if (!getterHelpers.useVue()?.$refs?.modals?.$refs?.modal) {
                                throw "system reboot"
                            }
                        }
                        let rebootInfo: any = message.data?.data
                        if (config.canUseNewObjectType("uscUtms")) {
                            products.unifiedSecurityConsole.uscUtms
                                .useStore?.()
                                .setObjectTypeObjectProperty(
                                    activeAccountId.value,
                                    message.data?.utmId,
                                    rebootInfo,
                                    "data",
                                    ["messages", "merged-reboot-info"]
                                )
                        } else {
                            await getterHelpers.useStore().commit(MutationTypes.setObjectProperty, {
                                accountId: activeAccountId.value,
                                objectId: message.data?.utmId,
                                productType: "unifiedSecurityConsole",
                                objectType: "uscUtms",
                                queryType: "uscUtms",
                                property: "data",
                                objectPropertyPath: ["messages", "merged-reboot-info"],
                                value: rebootInfo
                            })
                        }
                    }
                    if (jobType == "scan" && jobContext == "state finished") {
                        await getScansData(true)
                    }

                    let contextMap = products.unifiedSecurityConsole.uscUtms.contextMap
                    jobContext = jobContext.replace("-", " ").replace("variable", " ")
                    jobContext = contextMap[jobContext as keyof typeof contextMap] || jobContext

                    let operationsLogEntry: any = {
                        jobId: jobId,
                        queue: jobQueue,
                        lastUpdate: jobLastUpdate,
                        type: jobType,
                        status: jobStatus,
                        statusText: jobStatusText,
                        context: jobContext,
                        issued: jobIssued,
                        ended: jobEnded
                    }
                    if (operationsLogEntry.jobId) {
                        let index = operationsLog.value.findIndex((entry: any) => {
                            return entry.jobId == operationsLogEntry.jobId
                        })
                        if (index != -1) {
                            operationsLog.value[index].status = "ACKNOWLEDGE"
                        }

                        operationsLog.value.unshift(operationsLogEntry)
                    }
                } catch (e: any) {
                    console.error("COULD NOT EXECUTE JOB", e)

                    dialogs.misc.confirmDialog(
                        activeAccountId.value,
                        T("Error") + " - " + T("Could not execute job") + ": " + jobType,
                        "<code>" + e + "</code>",
                        async function () {
                            useStore().commit(MutationTypes.removeModal, {
                                accountId: activeAccountId.value
                            })
                        },
                        undefined,
                        "OK",
                        "fal fa-check"
                    )
                }
            }
        }
    })
    getUtmInfo()
})

onBeforeUnmount(() => {
    initialized.value = false
    useStore().commit(MutationTypes.deleteSubscriptionHook, {
        accountId: activeAccountId.value,
        hookKey: "utmPage"
    })
})

defineExpose({
    operationsLog
})
</script>

<template>
    <section class="padding-xs padding-md-2 padding-lg-4 pagecontent" v-if="!initialized">
        <div class="row media-screen-only">
            <div class="col-xs-24 padding-xs">
                <div class="box-shadow">
                    <div v-if="!initialized" class="page-loader">
                        <div class="text-size-3 text-center padding-xs-t-4">
                            <loader class="color-red"></loader>
                        </div>
                        <template v-if="loaderText">
                            <div
                                class="text-center padding-xs-t-2 padding-xs-b-4"
                                style="opacity: 0.8"
                            >
                                <span>
                                    {{ T(loaderText) }}
                                </span>
                            </div>
                        </template>
                        <template v-else>
                            <div class="padding-xs-b-4"></div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="padding-xs padding-md-2 padding-lg-4 pagecontent" v-else-if="initialized">
        <div class="row itemlist show-details view-grid">
            <div class="col-xs-24 col-print-24 col-lg-10 padding-xs">
                <itemlist-item
                    :item="utm"
                    :index="1"
                    :showInfo="true"
                    :key="'utmDetails'"
                    :ref="'utmDetailsTile'"
                    :noCol="true"
                    style="height: 100%"
                    :refreshCounterProp="refreshCount"
                    :full-title="true"
                />
            </div>

            <div class="col-xs-24 col-print-24 col-lg-14 padding-xs">
                <div class="box-shadow box-fullheight">
                    <div class="padding-xs-t-2 padding-xs-l-2 padding-xs-r-2 padding-xs-b-0">
                        <div class="row flexrow" style="flex-wrap: wrap">
                            <div class="col-xs-12">
                                <h4>Unified Security Report</h4>
                                <p
                                    v-if="utm?.cluster?.isCluster === true"
                                    class="input-description"
                                >
                                    {{ T("Aggregated from cluster members") }}
                                </p>
                            </div>
                            <div class="col-xs-12 text-right">
                                <label class="form-field margin-xs-b-0" style="margin-bottom: 8px">
                                    <span class="form-icon-prefix"
                                        ><i class="fal fa-clock"></i
                                    ></span>
                                    <select
                                        id="tenant-report-dashboard-period"
                                        v-model="widgets.period"
                                        class="form-control"
                                    >
                                        <option value="lastday">{{ T("Yesterday") }}</option>
                                        <option value="lastweek">{{ T("Last week") }}</option>
                                        <option value="currentmonth">
                                            {{ T("Current month") }}
                                        </option>
                                    </select>
                                </label>
                            </div>
                        </div>

                        <div class="row flexrow" style="flex-wrap: wrap">
                            <div class="col-xs-24 col-md-12 padding-xs-b-2">
                                <div
                                    class="box border box-fullheight"
                                    style="color: #fff; border-radius: 5px; overflow: hidden"
                                    :style="
                                        'background:' +
                                        getWidgetBackground(
                                            widgets.blockedThreats.riskStatus,
                                            'body'
                                        )
                                    "
                                >
                                    <div
                                        class="box-title text-center border-bottom padding-xs"
                                        :style="
                                            'background:' +
                                            getWidgetBackground(
                                                widgets.blockedThreats.riskStatus,
                                                'header'
                                            )
                                        "
                                    >
                                        <h4 style="color: #fff" class="text-bold">
                                            {{ T(widgets.blockedThreats.title) }}
                                        </h4>
                                    </div>
                                    <div class="box-content padding-xs-y-2">
                                        <template
                                            v-if="widgets.blockedThreats.riskStatus != 'loading'"
                                        >
                                            <div class="row flexrow" style="flex-wrap: wrap">
                                                <template
                                                    v-if="
                                                        getWidgetIcon(
                                                            widgets.blockedThreats.riskStatus
                                                        )
                                                    "
                                                >
                                                    <div
                                                        class="icon-area padding-xs-y"
                                                        style="
                                                            font-size: 50pt;
                                                            text-align: center;
                                                            display: flex;
                                                            align-content: center;
                                                        "
                                                        :style="
                                                            'width:' +
                                                            (getBlockedThreadsLines().length == 1
                                                                ? '100%'
                                                                : '90px')
                                                        "
                                                    >
                                                        <i
                                                            :class="
                                                                getWidgetIcon(
                                                                    widgets.blockedThreats
                                                                        .riskStatus
                                                                )
                                                            "
                                                            style="
                                                                display: block;
                                                                align-self: center;
                                                                margin: 0 auto;
                                                            "
                                                        ></i>
                                                    </div>
                                                    <template
                                                        v-if="
                                                            getBlockedThreadsLines().length == 1 &&
                                                            utm.license.laasEnabled
                                                        "
                                                    >
                                                        <p class="text-bold text-center col-xs-24">
                                                            {{ getBlockedThreadsLines()[0] }}
                                                        </p>
                                                    </template>
                                                </template>
                                                <div class="padding-xs-x">
                                                    <template
                                                        v-if="getBlockedThreadsLines().length > 1"
                                                    >
                                                        <template
                                                            v-for="thread in getBlockedThreadsLines()"
                                                        >
                                                            <p class="padding-xs-t">
                                                                <strong>
                                                                    {{ thread }}
                                                                </strong>
                                                            </p>
                                                        </template>
                                                    </template>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="text-center padding-xs-y-4">
                                                <loader style="font-size: 3em"></loader>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-24 col-md-12 padding-xs-b-2">
                                <div
                                    class="box border box-fullheight"
                                    style="color: #fff; border-radius: 5px; overflow: hidden"
                                    :style="
                                        'background:' +
                                        getWidgetBackground(
                                            mapClamAvAndCtAvStatus(
                                                widgets.antivirusClamStatus.riskStatus,
                                                widgets.antivirusCtavStatus.riskStatus
                                            ),
                                            'body'
                                        )
                                    "
                                >
                                    <div
                                        class="box-title text-center border-bottom padding-xs"
                                        :style="
                                            'background:' +
                                            getWidgetBackground(
                                                mapClamAvAndCtAvStatus(
                                                    widgets.antivirusClamStatus.riskStatus,
                                                    widgets.antivirusCtavStatus.riskStatus
                                                ),
                                                'header'
                                            )
                                        "
                                    >
                                        <h4 style="color: #fff" class="text-bold">
                                            {{ T(widgets.antivirusClamStatus.title) }}
                                        </h4>
                                    </div>
                                    <div class="box-content padding-xs-y-2">
                                        <template
                                            v-if="
                                                widgets.antivirusClamStatus.riskStatus != 'loading'
                                            "
                                        >
                                            <div
                                                class="icon-area padding-xs-y"
                                                style="
                                                    font-size: 50pt;
                                                    width: 100%;
                                                    text-align: center;
                                                    display: flex;
                                                    align-content: center;
                                                "
                                            >
                                                <i
                                                    :class="
                                                        getWidgetIcon(
                                                            mapClamAvAndCtAvStatus(
                                                                widgets.antivirusClamStatus
                                                                    .riskStatus,
                                                                widgets.antivirusCtavStatus
                                                                    .riskStatus
                                                            )
                                                        )
                                                    "
                                                    style="
                                                        display: block;
                                                        align-self: center;
                                                        margin: 0 auto;
                                                    "
                                                ></i>
                                            </div>
                                            <div class="text-center padding-xs-y">
                                                <strong>
                                                    {{
                                                        T(
                                                            mapClamAvAndCtAvStatusToText(
                                                                widgets.antivirusClamStatus
                                                                    .riskStatus,
                                                                widgets.antivirusCtavStatus
                                                                    .riskStatus
                                                            )
                                                        )
                                                    }}</strong
                                                >
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="text-center padding-xs-y-4">
                                                <loader style="font-size: 3em"></loader>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-24 col-md-6 padding-xs-b-2">
                                <div
                                    class="box border box-fullheight"
                                    style="color: #fff; border-radius: 5px; overflow: hidden"
                                    :style="
                                        'background:' +
                                        getWidgetBackground(widgets.kernelStatus.riskStatus, 'body')
                                    "
                                >
                                    <div
                                        class="box-title text-center border-bottom padding-xs"
                                        :style="
                                            'background:' +
                                            getWidgetBackground(
                                                widgets.kernelStatus.riskStatus,
                                                'header'
                                            )
                                        "
                                    >
                                        <h4 style="color: #fff" class="text-bold">
                                            {{ T(widgets.kernelStatus.title) }}
                                        </h4>
                                    </div>
                                    <div class="box-content padding-xs-y-2">
                                        <template
                                            v-if="
                                                widgets.kernelStatus.riskStatus != 'loading' &&
                                                widgets.kernelStatus.data.Drops != undefined &&
                                                widgets.kernelStatus.data.Rejects != undefined
                                            "
                                        >
                                            <div class="text-center padding-xs-y">
                                                <p>
                                                    <span
                                                        class="text-h3 text-bold"
                                                        style="color: #fff"
                                                    >
                                                        {{ T(widgets.kernelStatus.data.Drops) }}
                                                    </span>
                                                    <br />
                                                    <strong>{{
                                                        T(widgets.kernelStatus.dropsText)
                                                    }}</strong>
                                                </p>
                                                <p class="padding-xs-t">
                                                    <span
                                                        class="text-h3 text-bold"
                                                        style="color: #fff"
                                                    >
                                                        {{ T(widgets.kernelStatus.data.Rejects) }}
                                                    </span>
                                                    <br />
                                                    <strong>{{
                                                        T(widgets.kernelStatus.rejectsText)
                                                    }}</strong>
                                                </p>
                                            </div>
                                        </template>
                                        <template
                                            v-else-if="widgets.kernelStatus.riskStatus == 'loading'"
                                        >
                                            <div class="text-center padding-xs-y-4">
                                                <loader style="font-size: 3em"></loader>
                                            </div>
                                        </template>
                                        <template
                                            v-else-if="
                                                widgets.kernelStatus.data.Drops == undefined &&
                                                widgets.kernelStatus.data.Rejects == undefined
                                            "
                                        >
                                            <div
                                                class="icon-area padding-xs-y"
                                                style="
                                                    font-size: 50pt;
                                                    width: 100%;
                                                    text-align: center;
                                                    display: flex;
                                                    align-content: center;
                                                "
                                            >
                                                <i
                                                    :class="
                                                        getWidgetIcon(
                                                            widgets.kernelStatus.riskStatus
                                                        )
                                                    "
                                                    style="
                                                        display: block;
                                                        align-self: center;
                                                        margin: 0 auto;
                                                    "
                                                ></i>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-24 col-md-6 padding-xs-b-2">
                                <div
                                    class="box border box-fullheight"
                                    style="color: #fff; border-radius: 5px; overflow: hidden"
                                    :style="
                                        'background:' +
                                        getWidgetBackground(widgets.alertStatus.riskStatus, 'body')
                                    "
                                >
                                    <div
                                        class="box-title text-center border-bottom padding-xs"
                                        :style="
                                            'background:' +
                                            getWidgetBackground(
                                                widgets.alertStatus.riskStatus,
                                                'header'
                                            )
                                        "
                                    >
                                        <h4 style="color: #fff" class="text-bold">
                                            {{ T(widgets.alertStatus.title) }}
                                        </h4>
                                    </div>
                                    <div class="box-content padding-xs-y-2">
                                        <template
                                            v-if="widgets.alertStatus.riskStatus != 'loading'"
                                        >
                                            <div
                                                class="icon-area padding-xs-y"
                                                style="
                                                    font-size: 50pt;
                                                    width: 100%;
                                                    text-align: center;
                                                    display: flex;
                                                    align-content: center;
                                                "
                                            >
                                                <i
                                                    :class="
                                                        getWidgetIcon(
                                                            widgets.alertStatus.riskStatus
                                                        )
                                                    "
                                                    style="
                                                        display: block;
                                                        align-self: center;
                                                        margin: 0 auto;
                                                    "
                                                ></i>
                                            </div>
                                            <div class="text-center padding-xs-y">
                                                <strong>{{ T(widgets.alertStatus.text) }}</strong>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="text-center padding-xs-y-4">
                                                <loader style="font-size: 3em"></loader>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-24 col-md-6 padding-xs-b-2">
                                <div
                                    class="box border box-fullheight"
                                    style="color: #fff; border-radius: 5px; overflow: hidden"
                                    :style="
                                        'background:' +
                                        getWidgetBackground(
                                            widgets.httpTrafficStatus.riskStatus,
                                            'body'
                                        )
                                    "
                                >
                                    <div
                                        class="box-title text-center border-bottom padding-xs"
                                        :style="
                                            'background:' +
                                            getWidgetBackground(
                                                widgets.httpTrafficStatus.riskStatus,
                                                'header'
                                            )
                                        "
                                    >
                                        <h4 style="color: #fff" class="text-bold">
                                            {{ T(widgets.httpTrafficStatus.title) }}
                                        </h4>
                                    </div>
                                    <div class="box-content padding-xs-y-2">
                                        <template
                                            v-if="widgets.httpTrafficStatus.riskStatus != 'loading'"
                                        >
                                            <template
                                                v-if="
                                                    widgets.httpTrafficStatus.riskStatus !=
                                                    'no license'
                                                "
                                            >
                                                <template
                                                    v-if="
                                                        widgets.httpTrafficStatus.data.Traffic > 0
                                                    "
                                                >
                                                    <div
                                                        class="text-center"
                                                        style="
                                                            padding-top: 34px;
                                                            padding-bottom: 34px;
                                                        "
                                                    >
                                                        <strong>
                                                            <span class="text-size-2">
                                                                {{
                                                                    numberHelpers.formatBytes(
                                                                        widgets.httpTrafficStatus
                                                                            .data.Traffic
                                                                    ).value
                                                                }}
                                                            </span>
                                                            <br />
                                                            {{
                                                                numberHelpers.formatBytes(
                                                                    widgets.httpTrafficStatus.data
                                                                        .Traffic
                                                                ).fullUnit
                                                            }}
                                                        </strong>
                                                    </div>
                                                </template>
                                                <template
                                                    v-if="
                                                        widgets.httpTrafficStatus.data.Traffic == 0
                                                    "
                                                >
                                                    <div
                                                        class="icon-area padding-xs-y"
                                                        style="
                                                            font-size: 50pt;
                                                            width: 100%;
                                                            text-align: center;
                                                            display: flex;
                                                            align-content: center;
                                                        "
                                                    >
                                                        <i
                                                            :class="
                                                                getWidgetIcon(
                                                                    widgets.httpTrafficStatus
                                                                        .riskStatus
                                                                )
                                                            "
                                                            style="
                                                                display: block;
                                                                align-self: center;
                                                                margin: 0 auto;
                                                            "
                                                        ></i>
                                                    </div>
                                                    <p class="text-center">
                                                        <strong class="">{{
                                                            T("No traffic")
                                                        }}</strong>
                                                    </p>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <div
                                                    class="icon-area padding-xs-y"
                                                    style="
                                                        font-size: 50pt;
                                                        width: 100%;
                                                        text-align: center;
                                                        display: flex;
                                                        align-content: center;
                                                    "
                                                >
                                                    <i
                                                        :class="
                                                            getWidgetIcon(
                                                                widgets.alertStatus.riskStatus
                                                            )
                                                        "
                                                        style="
                                                            display: block;
                                                            align-self: center;
                                                            margin: 0 auto;
                                                        "
                                                    ></i>
                                                </div>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <div class="text-center padding-xs-y-4">
                                                <loader style="font-size: 3em"></loader>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-24 col-md-6 padding-xs-b-2">
                                <div
                                    class="box border box-fullheight"
                                    style="color: #fff; border-radius: 5px; overflow: hidden"
                                    :style="
                                        'background:' +
                                        getWidgetBackground(widgets.loginStatus.riskStatus, 'body')
                                    "
                                >
                                    <div
                                        class="box-title text-center border-bottom padding-xs"
                                        :style="
                                            'background:' +
                                            getWidgetBackground(
                                                widgets.loginStatus.riskStatus,
                                                'header'
                                            )
                                        "
                                    >
                                        <h4 style="color: #fff" class="text-bold">
                                            {{ T(widgets.loginStatus.title) }}
                                        </h4>
                                    </div>
                                    <div class="box-content padding-xs-y-2">
                                        <template
                                            v-if="widgets.loginStatus.riskStatus != 'loading'"
                                        >
                                            <div
                                                class="icon-area padding-xs-y"
                                                style="
                                                    font-size: 50pt;
                                                    width: 100%;
                                                    text-align: center;
                                                    display: flex;
                                                    align-content: center;
                                                "
                                            >
                                                <i
                                                    :class="
                                                        getWidgetIcon(
                                                            widgets.loginStatus.riskStatus
                                                        )
                                                    "
                                                    style="
                                                        display: block;
                                                        align-self: center;
                                                        margin: 0 auto;
                                                    "
                                                ></i>
                                            </div>
                                            <div class="text-center padding-xs-y">
                                                <strong>
                                                    <template
                                                        v-if="widgets.loginStatus.data.Logins != 0"
                                                    >
                                                        {{
                                                            widgets.loginStatus.data.Logins
                                                        }} </template
                                                    >&nbsp;{{ T(widgets.loginStatus.text) }}
                                                </strong>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="text-center padding-xs-y-4">
                                                <loader style="font-size: 3em"></loader>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <template v-if="getLaasState(utm) != 'active'">
                        <div
                            class="no-license-overlay"
                            style="
                                position: absolute;
                                left: 0;
                                top: 0;
                                z-index: 5;
                                background: rgba(0, 0, 0, 0.5);
                                color: #fff;
                                right: 0;
                                bottom: 0;
                                padding-top: 159px;
                                text-align: center;
                            "
                        >
                            <div class="overlay-text" style="text-shadow: 0px 0px 7px #000">
                                <h2 class="color-white">
                                    <template v-if="getLaasState(utm) == 'not_licensed'">
                                        {{ T("Unified Reporting is not licensed for you") }}
                                    </template>

                                    <template v-else-if="getLaasState(utm) == 'licensed'">
                                        {{ T("Unified Reporting is not activated for this UTM") }}
                                    </template>
                                </h2>
                                <template v-if="isReseller">
                                    <p
                                        style="font-size: 1.5em"
                                        v-if="getLaasState(utm) == 'not_licensed'"
                                    >
                                        {{ T("License now!") }}
                                    </p>
                                </template>
                                <template v-else>
                                    <p
                                        style="font-size: 1.5em"
                                        v-if="getLaasState(utm) == 'not_licensed'"
                                    >
                                        {{
                                            T(
                                                "Please contact your IT system house to license this product"
                                            )
                                        }}
                                    </p>
                                    <p
                                        style="font-size: 1.5em"
                                        v-if="getLaasState(utm) == 'licensed'"
                                    >
                                        {{ T("Please activate this feature for your UTM") }}
                                    </p>
                                </template>
                            </div>
                            <template v-if="isReseller">
                                <a
                                    rel="noreferrer noopener"
                                    style="
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                        display: block;
                                    "
                                    :href="getRPAccountLink()"
                                    target="_blank"
                                ></a>
                            </template>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-24 padding-xs">
                <div class="box-shadow">
                    <tabsComponent ref="tabs" v-if="utm" :tabs="tabsList">
                        <template v-slot:overview>
                            <div>
                                <div class="row padding-xs-y">
                                    <div class="col-xs-24 col-lg-12">
                                        <small
                                            >{{ T("Last update:") }}
                                            <span>{{
                                                getUtmMessageData(utm, "spcli-system-info")
                                                    ? getDate(
                                                          getUtmMessageData(
                                                              utm,
                                                              "spcli-system-info"
                                                          )?.timestamp,
                                                          true
                                                      )
                                                    : T("Unknown")
                                            }}</span></small
                                        >
                                    </div>
                                    <div class="col-xs-24 col-lg-12 text-right">
                                        <buttonComponent
                                            id="refreshUtmInfo"
                                            :buttonOptions="{
                                                loading: deviceActionStatus('system info').loading,
                                                disabled:
                                                    deviceActionStatus('system info').disabled ||
                                                    deviceActionStatus('system poweroff')
                                                        .rebooting ||
                                                    !utmStatus?.online ||
                                                    false,
                                                title: T('Refresh'),
                                                text: undefined,
                                                icon: 'fal fa-sync',
                                                onClick: () => {
                                                    execDeviceAction(utm, 'system info')
                                                    execDeviceAction(utm, 'interface address get')
                                                }
                                            }"
                                        ></buttonComponent>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-24 col-lg-12">
                                        <h4>{{ T("Memory info") }}</h4>
                                        <template
                                            v-if="
                                                getUtmMessageData(utm, 'spcli-system-info') &&
                                                getUtmMessageData(utm, 'spcli-system-info').memory
                                            "
                                        >
                                            <div
                                                class="progress-bar progress-bar-sm color-red"
                                                :data-progress="'0'"
                                                :class="{
                                                    'color-red':
                                                        getMemoryPercentage(
                                                            getUtmMessageData(
                                                                utm,
                                                                'spcli-system-info'
                                                            ).memory
                                                        ) >= 90,
                                                    'color-yellow':
                                                        getMemoryPercentage(
                                                            getUtmMessageData(
                                                                utm,
                                                                'spcli-system-info'
                                                            ).memory
                                                        ) < 90 &&
                                                        getMemoryPercentage(
                                                            getUtmMessageData(
                                                                utm,
                                                                'spcli-system-info'
                                                            ).memory
                                                        ) >= 70,
                                                    'color-green':
                                                        getMemoryPercentage(
                                                            getUtmMessageData(
                                                                utm,
                                                                'spcli-system-info'
                                                            ).memory
                                                        ) < 70
                                                }"
                                            >
                                                <div
                                                    style="
                                                        position: absolute;
                                                        border-bottom: 1em solid;
                                                        top: 0;
                                                    "
                                                    :style="
                                                        'width:' +
                                                        getMemoryPercentage(
                                                            getUtmMessageData(
                                                                utm,
                                                                'spcli-system-info'
                                                            ).memory
                                                        ) +
                                                        '%'
                                                    "
                                                    :data-progress="'0'"
                                                ></div>
                                            </div>
                                            <span>
                                                {{ T("Memory usage") }}:
                                                {{
                                                    numberHelpers.formatBytes(
                                                        getUtmMessageData(utm, "spcli-system-info")
                                                            .memory.total *
                                                            1024 -
                                                            getUtmMessageData(
                                                                utm,
                                                                "spcli-system-info"
                                                            ).memory.available *
                                                                1024,
                                                        2
                                                    ).value +
                                                    numberHelpers.formatBytes(
                                                        getUtmMessageData(utm, "spcli-system-info")
                                                            .memory.total *
                                                            1024 -
                                                            getUtmMessageData(
                                                                utm,
                                                                "spcli-system-info"
                                                            ).memory.available *
                                                                1024,
                                                        2
                                                    ).unit
                                                }}
                                                {{ T("of") }}
                                                {{
                                                    numberHelpers.formatBytes(
                                                        getUtmMessageData(utm, "spcli-system-info")
                                                            .memory.total * 1024,
                                                        2
                                                    ).value +
                                                    numberHelpers.formatBytes(
                                                        getUtmMessageData(utm, "spcli-system-info")
                                                            .memory.total * 1024,
                                                        2
                                                    ).unit
                                                }}
                                            </span>
                                        </template>

                                        <h4>{{ T("CPU") }}</h4>
                                        <template
                                            v-if="
                                                getUtmMessageData(utm, 'spcli-system-info') &&
                                                getUtmMessageData(utm, 'spcli-system-info').cpu &&
                                                getUtmMessageData(utm, 'spcli-system-info').cpu
                                                    .stats
                                            "
                                        >
                                            <div
                                                class="progress-bar progress-bar-sm color-red"
                                                :data-progress="'0'"
                                            >
                                                <div
                                                    style="
                                                        position: absolute;
                                                        border-bottom: 1em solid;
                                                        top: 0;
                                                    "
                                                    :style="
                                                        'width:' +
                                                        getCpuPercentage(
                                                            getUtmMessageData(
                                                                utm,
                                                                'spcli-system-info'
                                                            ).cpu.stats
                                                        ) +
                                                        '%'
                                                    "
                                                    :class="{
                                                        'color-red':
                                                            getCpuPercentage(
                                                                getUtmMessageData(
                                                                    utm,
                                                                    'spcli-system-info'
                                                                ).cpu.stats
                                                            ) >= 90,
                                                        'color-yellow':
                                                            getCpuPercentage(
                                                                getUtmMessageData(
                                                                    utm,
                                                                    'spcli-system-info'
                                                                ).cpu.stats
                                                            ) < 90 &&
                                                            getCpuPercentage(
                                                                getUtmMessageData(
                                                                    utm,
                                                                    'spcli-system-info'
                                                                ).cpu.stats
                                                            ) >= 70,
                                                        'color-green':
                                                            getCpuPercentage(
                                                                getUtmMessageData(
                                                                    utm,
                                                                    'spcli-system-info'
                                                                ).cpu.stats
                                                            ) < 70
                                                    }"
                                                ></div>
                                            </div>
                                            <span>
                                                {{ T("CPU load") }}:
                                                {{
                                                    getCpuPercentage(
                                                        getUtmMessageData(utm, "spcli-system-info")
                                                            .cpu.stats
                                                    )
                                                }}%&nbsp;&nbsp;<small>
                                                    ({{ T("Nice") }}:
                                                    {{
                                                        getUtmMessageData(utm, "spcli-system-info")
                                                            .cpu.stats.nice
                                                    }}
                                                    | {{ T("System") }}:
                                                    {{
                                                        getUtmMessageData(utm, "spcli-system-info")
                                                            .cpu.stats.system
                                                    }}
                                                    | {{ T("User") }}:
                                                    {{
                                                        getUtmMessageData(utm, "spcli-system-info")
                                                            .cpu.stats.user
                                                    }}
                                                    | {{ T("Other") }}:
                                                    {{
                                                        100 -
                                                        parseInt(
                                                            getUtmMessageData(
                                                                utm,
                                                                "spcli-system-info"
                                                            ).cpu.stats.idle || 0
                                                        ) -
                                                        parseInt(
                                                            getUtmMessageData(
                                                                utm,
                                                                "spcli-system-info"
                                                            ).cpu.stats.nice || 0
                                                        ) -
                                                        parseInt(
                                                            getUtmMessageData(
                                                                utm,
                                                                "spcli-system-info"
                                                            ).cpu.stats.system || 0
                                                        ) -
                                                        parseInt(
                                                            getUtmMessageData(
                                                                utm,
                                                                "spcli-system-info"
                                                            ).cpu.stats.user || 0
                                                        )
                                                    }}%)</small
                                                >
                                            </span>
                                        </template>

                                        <h4>{{ T("Storage") }}</h4>
                                        <template
                                            v-if="
                                                getUtmMessageData(utm, 'spcli-system-info') &&
                                                getUtmMessageData(utm, 'spcli-system-info').storage
                                            "
                                        >
                                            <div
                                                class="progress-bar progress-bar-sm color-red"
                                                :data-progress="'0'"
                                                :class="{
                                                    'color-red':
                                                        getStoragePercentage(
                                                            getUtmMessageData(
                                                                utm,
                                                                'spcli-system-info'
                                                            ).storage
                                                        ) >= 90,
                                                    'color-yellow':
                                                        getStoragePercentage(
                                                            getUtmMessageData(
                                                                utm,
                                                                'spcli-system-info'
                                                            ).storage
                                                        ) < 90 &&
                                                        getStoragePercentage(
                                                            getUtmMessageData(
                                                                utm,
                                                                'spcli-system-info'
                                                            ).storage
                                                        ) >= 70,
                                                    'color-green':
                                                        getStoragePercentage(
                                                            getUtmMessageData(
                                                                utm,
                                                                'spcli-system-info'
                                                            ).storage
                                                        ) < 70
                                                }"
                                            >
                                                <div
                                                    style="
                                                        position: absolute;
                                                        border-bottom: 1em solid;
                                                        top: 0;
                                                    "
                                                    :style="
                                                        'width:' +
                                                        getStoragePercentage(
                                                            getUtmMessageData(
                                                                utm,
                                                                'spcli-system-info'
                                                            ).storage
                                                        ) +
                                                        '%'
                                                    "
                                                ></div>
                                            </div>
                                            <span>
                                                {{ T("Storage used") }}:
                                                {{
                                                    numberHelpers.formatBytes(
                                                        getUtmMessageData(utm, "spcli-system-info")
                                                            .storage.total -
                                                            getUtmMessageData(
                                                                utm,
                                                                "spcli-system-info"
                                                            ).storage.available,
                                                        2
                                                    ).value +
                                                    numberHelpers.formatBytes(
                                                        getUtmMessageData(utm, "spcli-system-info")
                                                            .storage.total -
                                                            getUtmMessageData(
                                                                utm,
                                                                "spcli-system-info"
                                                            ).storage.available,
                                                        2
                                                    ).unit
                                                }}
                                                {{ T("of") }}
                                                {{
                                                    numberHelpers.formatBytes(
                                                        getUtmMessageData(utm, "spcli-system-info")
                                                            .storage.total,
                                                        2
                                                    ).value +
                                                    numberHelpers.formatBytes(
                                                        getUtmMessageData(utm, "spcli-system-info")
                                                            .storage.total,
                                                        2
                                                    ).unit
                                                }}
                                            </span>
                                        </template>

                                        <h4>{{ T("Upgrades") }}</h4>
                                        <table class="noborder border-h" v-if="true">
                                            <tbody>
                                                <tr
                                                    v-if="
                                                        getUtmMessageData(
                                                            utm,
                                                            'merged-update-info'
                                                        ) &&
                                                        (
                                                            getUtmMessageData(
                                                                utm,
                                                                'merged-update-info'
                                                            ).hint || ''
                                                        ).indexOf('rollback') != -1
                                                    "
                                                >
                                                    <td class="title">
                                                        <i class="fal fa-fw fa-sync"></i>
                                                        {{ T("Rollback") }}
                                                    </td>
                                                    <td>
                                                        {{ T("Rollback possible") }}
                                                    </td>
                                                    <td>
                                                        <template v-if="utmStatus?.online">
                                                            <div
                                                                :title="
                                                                    canUseFeature(
                                                                        objectId,
                                                                        'sendJobs'
                                                                    ).reasons?.join(', ')
                                                                "
                                                            >
                                                                <a
                                                                    :class="{
                                                                        'btn btn-sm': true,
                                                                        disabled: !canUseFeature(
                                                                            objectId,
                                                                            'sendJobs'
                                                                        ).result
                                                                    }"
                                                                    v-on:click="
                                                                        setActiveTab('operations')
                                                                    "
                                                                    >{{ T("Go to operations") }}</a
                                                                >
                                                            </div>
                                                        </template>
                                                    </td>
                                                </tr>
                                                <tr
                                                    v-if="
                                                        getUtmMessageData(
                                                            utm,
                                                            'merged-update-info'
                                                        ) &&
                                                        (
                                                            getUtmMessageData(
                                                                utm,
                                                                'merged-update-info'
                                                            ).hint || ''
                                                        ).indexOf('finalize') != -1
                                                    "
                                                >
                                                    <td class="title">
                                                        <i class="fal fa-fw fa-sync"></i>
                                                        {{ T("Finalize") }}
                                                    </td>
                                                    <td>
                                                        {{ T("Finalize possible") }}
                                                    </td>
                                                    <td>
                                                        <template v-if="utmStatus?.online">
                                                            <div
                                                                :title="
                                                                    canUseFeature(
                                                                        objectId,
                                                                        'sendJobs'
                                                                    ).reasons?.join(', ')
                                                                "
                                                            >
                                                                <a
                                                                    :class="{
                                                                        'btn btn-sm': true,
                                                                        disabled: !canUseFeature(
                                                                            objectId,
                                                                            'sendJobs'
                                                                        ).result
                                                                    }"
                                                                    v-on:click="
                                                                        setActiveTab('operations')
                                                                    "
                                                                    >{{ T("Go to operations") }}</a
                                                                >
                                                            </div>
                                                        </template>
                                                    </td>
                                                </tr>
                                                <tr
                                                    v-if="
                                                        getUtmMessageData(
                                                            utm,
                                                            'merged-update-info'
                                                        ) &&
                                                        (
                                                            getUtmMessageData(
                                                                utm,
                                                                'merged-update-info'
                                                            ).hint || ''
                                                        ).indexOf('confirm eula') != -1
                                                    "
                                                >
                                                    <td class="title">
                                                        <i class="fal fa-fw fa-sync"></i>
                                                        {{ T("Confirm eula") }}
                                                    </td>
                                                    <td>
                                                        {{ T("Eula needs to be confirmed") }}
                                                    </td>
                                                    <td>
                                                        <template v-if="utmStatus?.online">
                                                            <div
                                                                :title="
                                                                    canUseFeature(
                                                                        objectId,
                                                                        'sendJobs'
                                                                    ).reasons?.join(', ')
                                                                "
                                                            >
                                                                <a
                                                                    :class="{
                                                                        'btn btn-sm': true,
                                                                        disabled: !canUseFeature(
                                                                            objectId,
                                                                            'sendJobs'
                                                                        ).result
                                                                    }"
                                                                    v-on:click="
                                                                        setActiveTab('operations')
                                                                    "
                                                                    >{{ T("Go to operations") }}</a
                                                                >
                                                            </div>
                                                        </template>
                                                    </td>
                                                </tr>
                                                <tr
                                                    v-if="
                                                        getUtmMessageData(
                                                            utm,
                                                            'merged-update-info'
                                                        ) &&
                                                        (
                                                            getUtmMessageData(
                                                                utm,
                                                                'merged-update-info'
                                                            ).hint || ''
                                                        ).indexOf('confirm privacy') != -1
                                                    "
                                                >
                                                    <td class="title">
                                                        <i class="fal fa-fw fa-sync"></i>
                                                        {{ T("Confirm privacy") }}
                                                    </td>
                                                    <td>
                                                        {{ T("Privacy needs to be confirmed") }}
                                                    </td>
                                                    <td>
                                                        <template v-if="utmStatus?.online">
                                                            <div
                                                                :title="
                                                                    canUseFeature(
                                                                        objectId,
                                                                        'sendJobs'
                                                                    ).reasons?.join(', ')
                                                                "
                                                            >
                                                                <a
                                                                    :class="{
                                                                        'btn btn-sm': true,
                                                                        disabled: !canUseFeature(
                                                                            objectId,
                                                                            'sendJobs'
                                                                        ).result
                                                                    }"
                                                                    v-on:click="
                                                                        setActiveTab('operations')
                                                                    "
                                                                    >{{ T("Go to operations") }}</a
                                                                >
                                                            </div>
                                                        </template>
                                                    </td>
                                                </tr>
                                                <template
                                                    v-if="
                                                        getUtmMessageData(
                                                            utm,
                                                            'merged-update-info'
                                                        ) &&
                                                        (
                                                            getUtmMessageData(
                                                                utm,
                                                                'merged-update-info'
                                                            ).hint || ''
                                                        ).indexOf('confirm eula') == -1 &&
                                                        getUtmMessageData(
                                                            utm,
                                                            'merged-update-info'
                                                        ) &&
                                                        (
                                                            getUtmMessageData(
                                                                utm,
                                                                'merged-update-info'
                                                            ).hint || ''
                                                        ).indexOf('confirm privacy') == -1
                                                    "
                                                >
                                                    <tr
                                                        v-if="
                                                            getUtmMessageData(
                                                                utm,
                                                                'merged-update-info'
                                                            ) &&
                                                            (
                                                                getUtmMessageData(
                                                                    utm,
                                                                    'merged-update-info'
                                                                ).hint || ''
                                                            ).indexOf('dryrun') != -1
                                                        "
                                                    >
                                                        <td class="title">
                                                            <i class="fal fa-fw fa-sync"></i>
                                                            {{ T("Dry run") }}
                                                        </td>
                                                        <td>
                                                            <span
                                                                class="label padding-xs-y margin-xs-b"
                                                            >
                                                                <i
                                                                    class="fal fa-fw fa-exclamation-triangle"
                                                                ></i>
                                                                {{
                                                                    T("The update to") +
                                                                    " " +
                                                                    getUtmMessageData(
                                                                        utm,
                                                                        "merged-update-info"
                                                                    ).new +
                                                                    " " +
                                                                    T(
                                                                        "has been downloaded and can be activated"
                                                                    )
                                                                }} </span
                                                            ><br />
                                                            {{ T("Dry run possible") }}
                                                        </td>
                                                        <td>
                                                            <template v-if="utmStatus?.online">
                                                                <div
                                                                    :title="
                                                                        canUseFeature(
                                                                            objectId,
                                                                            'sendJobs'
                                                                        ).reasons?.join(', ')
                                                                    "
                                                                >
                                                                    <a
                                                                        :class="{
                                                                            'btn btn-sm': true,
                                                                            disabled:
                                                                                !canUseFeature(
                                                                                    objectId,
                                                                                    'sendJobs'
                                                                                ).result
                                                                        }"
                                                                        v-on:click="
                                                                            setActiveTab(
                                                                                'operations'
                                                                            )
                                                                        "
                                                                        >{{
                                                                            T("Go to operations")
                                                                        }}</a
                                                                    >
                                                                </div>
                                                            </template>
                                                        </td>
                                                    </tr>
                                                </template>
                                                <tr
                                                    v-if="
                                                        getUtmMessageData(
                                                            utm,
                                                            'merged-update-info'
                                                        ) &&
                                                        getUtmMessageData(utm, 'merged-update-info')
                                                            ?.timestamp
                                                    "
                                                >
                                                    <td class="title">
                                                        <i class="fal fa-fw fa-calendar-alt"></i>
                                                        {{ T("Last upgrade") }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            getDate(
                                                                getUtmMessageData(
                                                                    utm,
                                                                    "merged-update-info"
                                                                )?.timestamp
                                                            ) || ""
                                                        }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br />
                                        <a
                                            href="https://wiki.securepoint.de/UTM/Changelog"
                                            target="_blank"
                                        >
                                            {{
                                                T("Click here for information about this firmware.")
                                            }}
                                        </a>
                                        <h4>{{ T("License") }}</h4>
                                        <table class="noborder border-h margin-xs-0">
                                            <tbody>
                                                <tr>
                                                    <td class="title">
                                                        <i class="fal fa-fw fa-server"></i>
                                                        {{ T("Product") }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            getUtmMessageData(
                                                                utm,
                                                                "spcli-system-info"
                                                            )?.license?.product || ""
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="title">
                                                        <i class="fal fa-fw fa-key"></i>
                                                        {{ T("Licensed") }}
                                                    </td>
                                                    <td>
                                                        <i
                                                            class="fal"
                                                            :class="{
                                                                'fa-check': utm.licensed,
                                                                'fa-times': !utm.licensed
                                                            }"
                                                        ></i>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="title">
                                                        <i class="fal fa-fw fa-calendar-alt"></i>
                                                        {{ T("Expires on") }}
                                                    </td>
                                                    <td>
                                                        {{ getDate(utm.expirationDate) || "" }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <h5>{{ T("Organization") }}</h5>
                                        <table class="noborder border-h">
                                            <tbody>
                                                <tr>
                                                    <td class="title">
                                                        <i class="fal fa-fw fa-thumbtack"></i>
                                                        {{ T("Name") }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            getUtmMessageData(
                                                                utm,
                                                                "spcli-system-info"
                                                            )?.license?.organisation?.name || ""
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="title">
                                                        <i class="fal fa-fw fa-thumbtack"></i>
                                                        {{ T("Country") }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            getUtmMessageData(
                                                                utm,
                                                                "spcli-system-info"
                                                            )?.license?.organisation?.country || ""
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="title">
                                                        <i class="fal fa-fw fa-thumbtack"></i>
                                                        {{ T("State") }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            getUtmMessageData(
                                                                utm,
                                                                "spcli-system-info"
                                                            )?.license?.organisation?.state || ""
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="title">
                                                        <i class="fal fa-fw fa-thumbtack"></i>
                                                        {{ T("City") }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            getUtmMessageData(
                                                                utm,
                                                                "spcli-system-info"
                                                            )?.license?.organisation?.city || ""
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="title">
                                                        <i class="fal fa-fw fa-sitemap"></i>
                                                        {{ T("Unit") }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            typeof getUtmMessageData(
                                                                utm,
                                                                "spcli-system-info"
                                                            )?.license?.organisation?.unit ==
                                                            "string"
                                                                ? (
                                                                      getUtmMessageData(
                                                                          utm,
                                                                          "spcli-system-info"
                                                                      ).license?.organisation
                                                                          ?.unit || ""
                                                                  )?.replace(/unknown/gi, "")
                                                                : ""
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="title">
                                                        <i class="fal fa-fw fa-user"></i>
                                                        {{ T("Contact") }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            getUtmMessageData(
                                                                utm,
                                                                "spcli-system-info"
                                                            )?.license?.organisation?.contact?.replace(
                                                                /unknown/gi,
                                                                ""
                                                            ) || ""
                                                        }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="title">
                                                        <i class="fal fa-fw fa-user"></i>
                                                        {{ T("E-Mail") }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            getUtmMessageData(
                                                                utm,
                                                                "spcli-system-info"
                                                            )?.license?.organisation?.email?.replace(
                                                                /unknown/gi,
                                                                ""
                                                            ) || ""
                                                        }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-xs-24 col-lg-12" style="position: relative">
                                        <template
                                            v-if="
                                                utm?.inventory?.inventoryLocation?.latitude &&
                                                utm?.inventory?.inventoryLocation?.longitude
                                            "
                                        >
                                            <h3 class="float-xs-left">
                                                {{ T("Device location") }}
                                            </h3>
                                            <a
                                                v-on:click="getInventoryLocationDialog()"
                                                class="btn padding-xs-y-0 float-xs-right"
                                                :class="
                                                    darkmode
                                                        ? 'btn-darkbluegrey'
                                                        : 'btn-white color-gray'
                                                "
                                                :title="T('Set inventory device location')"
                                                ><i class="fal fa-edit"></i
                                            ></a>
                                        </template>
                                        <div class="clearfix"></div>
                                        <template
                                            v-if="
                                                utm &&
                                                utm.inventory &&
                                                utm.inventory.inventoryLocation &&
                                                utm.inventory.inventoryLocation.latitude &&
                                                utm.inventory.inventoryLocation.longitude
                                            "
                                        >
                                            <googleMapComponent
                                                id="inventoryMap"
                                                :entries="[
                                                    {
                                                        position: {
                                                            lat: utm.inventory.inventoryLocation
                                                                .latitude,
                                                            lng: utm.inventory.inventoryLocation
                                                                .longitude
                                                        },
                                                        infoHtml: getInfoBoxText()
                                                    }
                                                ]"
                                            />
                                        </template>

                                        <template
                                            v-if="
                                                jsonHelpers.isObjectEmpty(
                                                    utm.inventory
                                                        ? utm.inventory.inventoryLocation
                                                        : {}
                                                )
                                            "
                                        >
                                            <div style="position: relative; min-height: 250px">
                                                <div
                                                    :style="
                                                        (darkmode
                                                            ? 'background: rgba(0, 0, 0, 0.2);'
                                                            : 'background: rgba(0, 0, 0, 0.08);') +
                                                        'top: 0;' +
                                                        'height:100%;' +
                                                        'left:8px;' +
                                                        'right:8px;' +
                                                        'position: absolute;'
                                                    "
                                                >
                                                    <div
                                                        style="
                                                            position: absolute;
                                                            top: 50%;
                                                            transform: translate(0, -50%);
                                                            width: 100%;
                                                        "
                                                        class="text-center"
                                                    >
                                                        <h3 style="width: 100%; text-align: center">
                                                            {{ T("Inventory location is not set") }}
                                                        </h3>
                                                        <p>
                                                            {{
                                                                T(
                                                                    "Click the following link to set the inventory location:"
                                                                )
                                                            }}
                                                            <br />
                                                            <br /><a
                                                                class="btn"
                                                                :title="
                                                                    T(
                                                                        'Set inventory device location'
                                                                    )
                                                                "
                                                                v-on:click="
                                                                    getInventoryLocationDialog()
                                                                "
                                                            >
                                                                <i class="fal fa-fw fa-edit"></i>
                                                                {{ T("Set location") }}</a
                                                            >
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-slot:cluster v-if="utm?.cluster?.isCluster">
                            <div class="row">
                                <table class="noborder border-h">
                                    <tbody>
                                        <tr>
                                            <td class="title">Cluster-UUID:</td>
                                            <td>
                                                {{ utm.license.clusterUuid }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="noborder border-h">
                                    <tbody>
                                        <tr>
                                            <td class="title">Status:</td>
                                            <td v-if="utmStatus?.online">
                                                {{
                                                    T(
                                                        stringHelpers.capitalizeFirstLetter(
                                                            getUtmMessageData(
                                                                utm,
                                                                "spcli-system-info"
                                                            )?.cluster?.state || ""
                                                        )
                                                    )
                                                }}
                                            </td>
                                            <td v-else>
                                                {{
                                                    T(
                                                        "The cluster member must be active for this information"
                                                    )
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="title">{{ T("Cluster version") }}:</td>
                                            <td v-if="utmStatus?.online">
                                                {{
                                                    T(
                                                        getUtmMessageData(utm, "spcli-system-info")
                                                            ?.cluster?.version || ""
                                                    )
                                                }}
                                            </td>
                                            <td v-else>
                                                {{
                                                    T(
                                                        "The cluster member must be active for this information"
                                                    )
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="title">
                                                {{ T("Synchronisation status") }}:
                                            </td>
                                            <td v-if="utmStatus?.online">
                                                {{
                                                    T(
                                                        stringHelpers.capitalizeFirstLetter(
                                                            getUtmMessageData(
                                                                utm,
                                                                "spcli-system-info"
                                                            )?.cluster?.sync || ""
                                                        )
                                                    )
                                                }}
                                            </td>
                                            <td v-else>
                                                {{
                                                    T(
                                                        "The cluster member must be active for this information"
                                                    )
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="title">{{ T("License serial number") }}:</td>
                                            <td>
                                                {{
                                                    getUtmMessageData(utm, "spcli-system-info")
                                                        ?.license?.serial || ""
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <a
                                                    v-on:click="goToClusterUtm()"
                                                    :title="T('Go to other cluster UTM')"
                                                >
                                                    {{ T("Go to other cluster UTM") }}
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </template>
                        <template v-slot:operations>
                            <div class="row padding-xs-y">
                                <div class="col-xs-24 text-right">
                                    <a
                                        id="refreshOperations"
                                        v-on:click="getOperationsLogData"
                                        class="btn btn-primary btn-loader twist-in"
                                        :class="{
                                            'btn-loading': operationsLogTableData.loading,
                                            disabled:
                                                !utmStatus?.online || operationsLogTableData.loading
                                        }"
                                        :disabled="
                                            !utmStatus?.online ||
                                            operationsLogTableData.loading ||
                                            null
                                        "
                                    >
                                        <span class="animate">
                                            <loader
                                                v-if="deviceActionStatus('system info').loading"
                                                style="
                                                    font-size: 1.5em;
                                                    top: calc(50% - 0.375em - 1px);
                                                    position: absolute;
                                                    left: calc(50% - 0.375em - 0.5px);
                                                "
                                            ></loader>
                                        </span>
                                        <span><i class="fal fa-sync"></i></span>
                                    </a>
                                </div>
                            </div>

                            <div class="row padding-xs-y">
                                <div class="col-xs-2">
                                    <p style="margin-top: 8px">
                                        <strong>
                                            {{ T("Device") }}
                                        </strong>
                                    </p>
                                </div>
                                <div class="col-xs-22">
                                    <div class="row">
                                        <div class="col-xs-24 col-md-12 col-lg-6 padding-xs-x-2">
                                            <buttonComponent
                                                class="col-xs-24"
                                                v-if="deviceActionStatus('system reboot').view"
                                                :buttonOptions="{
                                                    loading:
                                                        deviceActionStatus('system reboot').loading,
                                                    disabled:
                                                        deviceActionStatus('system reboot')
                                                            .disabled ||
                                                        deviceActionStatus('system poweroff')
                                                            .rebooting ||
                                                        !utmStatus?.online ||
                                                        !canUseFeature(objectId, 'sendJobs')
                                                            .result ||
                                                        false,
                                                    title:
                                                        deviceActionStatus('system reboot')
                                                            .disabledReason || T('Reboot'),
                                                    text: T('Reboot'),
                                                    icon: 'fal fa-sync',
                                                    onClick: () => {
                                                        execDeviceAction(utm, 'system reboot')
                                                    },
                                                    topRightCircle: { icon: 'fa fa-lock' }
                                                }"
                                            ></buttonComponent>
                                        </div>
                                        <div class="col-xs-24 col-md-12 col-lg-6 padding-xs-x-2">
                                            <buttonComponent
                                                class="col-xs-24"
                                                v-if="deviceActionStatus('system poweroff').view"
                                                :buttonOptions="{
                                                    loading:
                                                        deviceActionStatus('system poweroff')
                                                            .loading,
                                                    disabled:
                                                        deviceActionStatus('system poweroff')
                                                            .disabled ||
                                                        deviceActionStatus('system poweroff')
                                                            .rebooting ||
                                                        !utmStatus?.online ||
                                                        !canUseFeature(objectId, 'sendJobs')
                                                            .result ||
                                                        false,
                                                    title:
                                                        deviceActionStatus('system poweroff')
                                                            .disabledReason || T('Shut down'),
                                                    text: T('Shut down'),
                                                    icon: 'fal fa-power-off',
                                                    onClick: () => {
                                                        execDeviceAction(utm, 'system poweroff')
                                                    },
                                                    topRightCircle: { icon: 'fa fa-lock' }
                                                }"
                                            ></buttonComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />

                            <div class="row padding-xs-y">
                                <div class="col-xs-2">
                                    <p style="margin-top: 8px">
                                        <strong>
                                            {{ T("Firmware") }}
                                        </strong>
                                    </p>
                                </div>
                                <div class="col-xs-22">
                                    <template
                                        v-if="
                                            [0, -1].indexOf(
                                                versionCompare(
                                                    '12.3',
                                                    String(
                                                        getUtmMessageData(utm, 'merged-update-info')
                                                            ?.cur || '0'
                                                    )
                                                ) || 1
                                            ) && hasLessThan3GBRam(utm)
                                        "
                                    >
                                        <div class="row">
                                            <div class="col-xs-24 padding-xs-2">
                                                <p
                                                    class="notification"
                                                    :class="{ 'color-white': darkmode }"
                                                >
                                                    <i
                                                        class="fal fa-exclamation-triangle fa-fw color-red"
                                                    ></i
                                                    >&nbsp;&nbsp;{{
                                                        T(
                                                            "Attention: This UTM has less than 3GB of memory. If a update is performed, this UTM will no longer have a virus scanner."
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                        </div>
                                    </template>
                                    <div class="row">
                                        <div class="col-xs-24 col-md-12 col-lg-6 padding-xs-x-2">
                                            <buttonComponent
                                                class="col-xs-24"
                                                v-if="
                                                    deviceActionStatus(
                                                        'system config factorysettings'
                                                    ).view
                                                "
                                                :buttonOptions="{
                                                    loading: deviceActionStatus(
                                                        'system config factorysettings'
                                                    ).loading,
                                                    disabled:
                                                        deviceActionStatus(
                                                            'system config factorysettings'
                                                        ).disabled ||
                                                        deviceActionStatus(
                                                            'system config factorysettings'
                                                        ).rebooting ||
                                                        !utmStatus?.online ||
                                                        !canUseFeature(objectId, 'sendJobs')
                                                            .result ||
                                                        false,
                                                    title:
                                                        deviceActionStatus(
                                                            'system config factorysettings'
                                                        ).disabledReason ||
                                                        T('Reset factory settings'),
                                                    text: T('Reset factory settings'),
                                                    icon: 'fal fa-undo',
                                                    onClick: () => {
                                                        execDeviceAction(
                                                            utm,
                                                            'system config factorysettings'
                                                        )
                                                    },
                                                    topRightCircle: { icon: 'fa fa-lock' }
                                                }"
                                            ></buttonComponent>
                                        </div>
                                        <template
                                            v-if="
                                                getUtmMessageData(utm, 'merged-update-info') &&
                                                (
                                                    getUtmMessageData(utm, 'merged-update-info')
                                                        .hint || ''
                                                ).indexOf('rollback') != -1
                                            "
                                        >
                                            <div
                                                class="col-xs-24 col-md-12 col-lg-6 padding-xs-x-2"
                                            >
                                                <buttonComponent
                                                    class="col-xs-24"
                                                    v-if="
                                                        deviceActionStatus(
                                                            'system upgrade rollback'
                                                        ).view
                                                    "
                                                    :buttonOptions="{
                                                        loading:
                                                            deviceActionStatus(
                                                                'system upgrade rollback'
                                                            ).loading,
                                                        disabled:
                                                            deviceActionStatus(
                                                                'system upgrade rollback'
                                                            ).disabled ||
                                                            deviceActionStatus(
                                                                'system upgrade rollback'
                                                            ).rebooting ||
                                                            !utmStatus?.online ||
                                                            !canUseFeature(objectId, 'sendJobs')
                                                                .result ||
                                                            false,
                                                        title:
                                                            shouldDisplayLock &&
                                                            deviceActionStatus(
                                                                'system upgrade rollback'
                                                            ).disabled
                                                                ? deviceActionStatus(
                                                                      'system upgrade rollback'
                                                                  ).disabledReason
                                                                : T('Rollback to version') +
                                                                  ': ' +
                                                                  getUtmMessageData(
                                                                      utm,
                                                                      'merged-update-info'
                                                                  ).old,
                                                        text:
                                                            T('Rollback to version') +
                                                            ': ' +
                                                            getUtmMessageData(
                                                                utm,
                                                                'merged-update-info'
                                                            ).old,
                                                        icon: 'fal fa-sync',
                                                        onClick: () => {
                                                            execDeviceAction(
                                                                utm,
                                                                'system upgrade rollback'
                                                            )
                                                        },
                                                        topRightCircle: shouldDisplayLock
                                                            ? { icon: 'fa fa-lock' }
                                                            : undefined
                                                    }"
                                                ></buttonComponent>
                                            </div>
                                        </template>

                                        <template
                                            v-if="
                                                getUtmMessageData(utm, 'merged-update-info') &&
                                                (
                                                    getUtmMessageData(utm, 'merged-update-info')
                                                        .hint || ''
                                                ).indexOf('dryrun') != -1 &&
                                                getUtmMessageData(utm, 'merged-update-info') &&
                                                (
                                                    getUtmMessageData(utm, 'merged-update-info')
                                                        .hint || ''
                                                ).indexOf('confirm eula') == -1 &&
                                                getUtmMessageData(utm, 'merged-update-info') &&
                                                (
                                                    getUtmMessageData(utm, 'merged-update-info')
                                                        .hint || ''
                                                ).indexOf('confirm privacy') == -1
                                            "
                                        >
                                            <div
                                                class="col-xs-24 col-md-12 col-lg-6 padding-xs-x-2"
                                            >
                                                <buttonComponent
                                                    class="col-xs-24"
                                                    v-if="
                                                        deviceActionStatus('system upgrade dryrun')
                                                            .view
                                                    "
                                                    :buttonOptions="{
                                                        loading:
                                                            deviceActionStatus(
                                                                'system upgrade dryrun'
                                                            ).loading,
                                                        disabled:
                                                            deviceActionStatus(
                                                                'system upgrade dryrun'
                                                            ).disabled ||
                                                            deviceActionStatus(
                                                                'system upgrade dryrun'
                                                            ).rebooting ||
                                                            !utmStatus?.online ||
                                                            !canUseFeature(objectId, 'sendJobs')
                                                                .result ||
                                                            false,
                                                        title:
                                                            shouldDisplayLock &&
                                                            deviceActionStatus(
                                                                'system upgrade dryrun'
                                                            ).disabled
                                                                ? deviceActionStatus(
                                                                      'system upgrade dryrun'
                                                                  ).disabledReason
                                                                : T('Dryrun the new Version') +
                                                                  ': ' +
                                                                  getUtmMessageData(
                                                                      utm,
                                                                      'merged-update-info'
                                                                  ).new,
                                                        text:
                                                            T('Dryrun the new Version') +
                                                            ': ' +
                                                            getUtmMessageData(
                                                                utm,
                                                                'merged-update-info'
                                                            ).new,
                                                        icon: 'fal fa-sync',
                                                        onClick: () => {
                                                            execDeviceAction(
                                                                utm,
                                                                'system upgrade dryrun'
                                                            )
                                                        },
                                                        topRightCircle: shouldDisplayLock
                                                            ? { icon: 'fa fa-lock' }
                                                            : undefined
                                                    }"
                                                ></buttonComponent>
                                            </div>
                                        </template>
                                        <template
                                            v-if="
                                                getUtmMessageData(utm, 'merged-update-info') &&
                                                getUtmMessageData(utm, 'spcli-system-info') &&
                                                (
                                                    getUtmMessageData(utm, 'merged-update-info')
                                                        .hint || ''
                                                ).indexOf('finalize') != -1
                                            "
                                        >
                                            <div
                                                class="col-xs-24 col-md-12 col-lg-6 padding-xs-x-2"
                                            >
                                                <buttonComponent
                                                    class="col-xs-24"
                                                    v-if="
                                                        deviceActionStatus(
                                                            'system upgrade finalize'
                                                        ).view
                                                    "
                                                    :buttonOptions="{
                                                        loading:
                                                            deviceActionStatus(
                                                                'system upgrade finalize'
                                                            ).loading,
                                                        disabled:
                                                            deviceActionStatus(
                                                                'system upgrade finalize'
                                                            ).disabled ||
                                                            deviceActionStatus(
                                                                'system upgrade finalize'
                                                            ).rebooting ||
                                                            !utmStatus?.online ||
                                                            !canUseFeature(objectId, 'sendJobs')
                                                                .result ||
                                                            false,
                                                        title:
                                                            shouldDisplayLock &&
                                                            deviceActionStatus(
                                                                'system upgrade finalize'
                                                            ).disabled
                                                                ? deviceActionStatus(
                                                                      'system upgrade finalize'
                                                                  ).disabledReason
                                                                : T('Finalize the new Version') +
                                                                  ': ' +
                                                                  getUtmMessageData(
                                                                      utm,
                                                                      'spcli-system-info'
                                                                  ).version,
                                                        text:
                                                            T('Finalize the new Version') +
                                                            ': ' +
                                                            getUtmMessageData(
                                                                utm,
                                                                'spcli-system-info'
                                                            ).version,
                                                        icon: 'fal fa-sync',
                                                        onClick: () => {
                                                            execDeviceAction(
                                                                utm,
                                                                'system upgrade finalize'
                                                            )
                                                        },
                                                        topRightCircle: shouldDisplayLock
                                                            ? { icon: 'fa fa-lock' }
                                                            : undefined
                                                    }"
                                                ></buttonComponent>
                                            </div>
                                        </template>
                                        <template
                                            v-if="
                                                getUtmMessageData(utm, 'merged-update-info') &&
                                                (
                                                    getUtmMessageData(utm, 'merged-update-info')
                                                        .hint || ''
                                                ).indexOf('confirm eula') != -1
                                            "
                                        >
                                            <div
                                                class="col-xs-24 col-md-12 col-lg-6 padding-xs-x-2"
                                            >
                                                <buttonComponent
                                                    class="col-xs-24"
                                                    v-if="
                                                        deviceActionStatus(
                                                            'system upgrade confirm eula'
                                                        ).view
                                                    "
                                                    :buttonOptions="{
                                                        loading: deviceActionStatus(
                                                            'system upgrade confirm eula'
                                                        ).loading,
                                                        disabled:
                                                            deviceActionStatus(
                                                                'system upgrade confirm eula'
                                                            ).disabled ||
                                                            deviceActionStatus(
                                                                'system upgrade confirm eula'
                                                            ).rebooting ||
                                                            !utmStatus?.online ||
                                                            !canUseFeature(objectId, 'sendJobs')
                                                                .result ||
                                                            false,
                                                        title:
                                                            shouldDisplayLock &&
                                                            deviceActionStatus(
                                                                'system upgrade confirm eula'
                                                            ).disabled
                                                                ? deviceActionStatus(
                                                                      'system upgrade confirm eula'
                                                                  ).disabledReason
                                                                : T('Accept eula'),
                                                        text: T('Accept eula'),
                                                        icon: 'fal fa-check',
                                                        onClick: () => {
                                                            execDeviceAction(
                                                                utm,
                                                                'system upgrade confirm eula'
                                                            )
                                                        },
                                                        topRightCircle: shouldDisplayLock
                                                            ? { icon: 'fa fa-lock' }
                                                            : undefined
                                                    }"
                                                ></buttonComponent>
                                            </div>
                                        </template>
                                        <template
                                            v-if="
                                                getUtmMessageData(utm, 'merged-update-info') &&
                                                (
                                                    getUtmMessageData(utm, 'merged-update-info')
                                                        .hint || ''
                                                ).indexOf('confirm privacy') != -1
                                            "
                                        >
                                            <div
                                                class="col-xs-24 col-md-12 col-lg-6 padding-xs-x-2"
                                            >
                                                <buttonComponent
                                                    class="col-xs-24"
                                                    v-if="
                                                        deviceActionStatus(
                                                            'system upgrade confirm privacy'
                                                        ).view
                                                    "
                                                    :buttonOptions="{
                                                        loading: deviceActionStatus(
                                                            'system upgrade confirm privacy'
                                                        ).loading,
                                                        disabled:
                                                            deviceActionStatus(
                                                                'system upgrade confirm privacy'
                                                            ).disabled ||
                                                            deviceActionStatus(
                                                                'system upgrade confirm privacy'
                                                            ).rebooting ||
                                                            !utmStatus?.online ||
                                                            !canUseFeature(objectId, 'sendJobs')
                                                                .result ||
                                                            false,
                                                        title:
                                                            shouldDisplayLock &&
                                                            deviceActionStatus(
                                                                'system upgrade confirm privacy'
                                                            ).disabled
                                                                ? deviceActionStatus(
                                                                      'system upgrade confirm privacy'
                                                                  ).disabledReason
                                                                : T('Accept privacy'),
                                                        text: T('Accept privacy'),
                                                        icon: 'fal fa-check',
                                                        onClick: () => {
                                                            execDeviceAction(
                                                                utm,
                                                                'system upgrade confirm privacy'
                                                            )
                                                        },
                                                        topRightCircle: shouldDisplayLock
                                                            ? { icon: 'fa fa-lock' }
                                                            : undefined
                                                    }"
                                                ></buttonComponent>
                                            </div>
                                        </template>

                                        <div class="col-xs-24 col-md-12 col-lg-6 padding-xs-x-2">
                                            <buttonComponent
                                                class="col-xs-24"
                                                :button-options="{
                                                    icon: 'fal fa-play',
                                                    text: T('Schedule a one-time update'),
                                                    onClick: getPlanSingleUpdateDialog
                                                }"
                                            ></buttonComponent>
                                        </div>

                                        <div class="col-xs-24 padding-xs-x-2">
                                            <p>
                                                <a
                                                    href="https://wiki.securepoint.de/UTM/Changelog"
                                                    target="_blank"
                                                >
                                                    {{
                                                        T(
                                                            "Click here for information about this firmware."
                                                        )
                                                    }}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-slot:websession>
                            <div class="row">
                                <div class="padding-xs-t" style="flex-wrap: wrap">
                                    <template v-if="!canUseWebsession">
                                        <p
                                            class="notification bg-red color-white"
                                            style="display: inline-block"
                                        >
                                            {{
                                                T("Invalid scopes. Needed some of:") +
                                                T("Full administration") +
                                                ", " +
                                                T("Monitoring access") +
                                                "."
                                            }}<br />
                                            {{
                                                T(
                                                    "Scopes can be edited in roles by the administrator."
                                                )
                                            }}
                                        </p>
                                    </template>
                                    <p>
                                        {{
                                            T(
                                                "Here you can open the administrative web interface of the UTM. If the UTM is accessible via internet connection, no user credentials are required."
                                            )
                                        }}
                                        <br />
                                        {{
                                            T(
                                                "When the websession is started, an additional pop-up window opens. Please note that pop-up blockers may prevent the window from opening."
                                            )
                                        }}
                                    </p>
                                    <p>
                                        <template v-if="canUseWebsession">
                                            <buttonComponent
                                                :buttonOptions="{
                                                    loading: false,
                                                    disabled: false,
                                                    title: T('Start new websession'),
                                                    text: T('Start new websession'),
                                                    icon: 'fal fa-laptop',
                                                    onClick: getWebsessionDialog,
                                                    topRightCircle: { icon: 'fa fa-lock' }
                                                }"
                                            ></buttonComponent>
                                        </template>
                                        <template v-else>
                                            <a class="btn btn-primary" disabled="true">
                                                <i class="fal fa-fw fa-exclamation-triangle"></i>
                                                {{ T("Missing permission scopes") }}
                                            </a>
                                        </template>
                                    </p>
                                </div>
                            </div>
                        </template>
                        <template v-slot:cloudBackup>
                            <div>
                                <div class="text-right padding-xs-y">
                                    <a
                                        id="refreshBackups"
                                        v-on:click="getBackupInfo"
                                        class="btn btn-primary btn-loader twist-in"
                                        :class="{
                                            'btn-loading disabled': backupsTableData.loading
                                        }"
                                        :disabled="backupsTableData.loading || null"
                                    >
                                        <span class="animate">
                                            <loader
                                                v-if="backupsTableData.loading"
                                                style="
                                                    font-size: 1.5em;
                                                    top: calc(50% - 0.375em - 1px);
                                                    position: absolute;
                                                    left: calc(50% - 0.375em - 0.5px);
                                                "
                                            ></loader>
                                        </span>
                                        <span><i class="fal fa-sync"></i></span>
                                    </a>
                                </div>
                                <div style="flex-wrap: wrap">
                                    <template v-if="utmStatus?.online">
                                        <template v-if="utmVars.GLOB_CLOUDBACKUP_TIME != undefined">
                                            <template
                                                v-if="
                                                    utmVars.GLOB_CLOUDBACKUP_TIME == 0 ||
                                                    !utmVars.GLOB_CLOUDBACKUP_TIME
                                                "
                                            >
                                                <p>
                                                    {{ T("Cloud backup is disabled for this UTM") }}
                                                </p>
                                            </template>
                                            <template v-else>
                                                <p v-if="utmVars.GLOB_CLOUDBACKUP_TIME">
                                                    {{
                                                        T(
                                                            "The automatic backup is scheduled daily at"
                                                        )
                                                    }}
                                                    {{
                                                        utmVars.GLOB_CLOUDBACKUP_TIME.split(" ")[1]
                                                    }}:{{
                                                        utmVars.GLOB_CLOUDBACKUP_TIME.split(" ")[0]
                                                    }}
                                                    {{ T("Xo'clock") }}.
                                                </p>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <loader style="font-size: 1.5em"></loader>
                                        </template>
                                        <hr />
                                    </template>
                                    <template v-if="utmStatus?.online">
                                        <h4>{{ T("Operations") }}</h4>
                                        <p>
                                            <template v-if="!utmVars.hasOwnProperty('passwordSet')">
                                                <loader style="font-size: 1.5em"></loader>
                                            </template>
                                            <template
                                                v-if="
                                                    utmVars.GLOB_CLOUDBACKUP_TIME != undefined &&
                                                    utmVars.GLOB_CLOUDBACKUP_TIME != 0 &&
                                                    utmVars.hasOwnProperty('passwordSet')
                                                "
                                            >
                                                <a
                                                    id="disableCloudBackup"
                                                    v-on:click="disableCloudBackup"
                                                    class="btn btn-loader twist-in"
                                                    :class="{
                                                        'btn-loading': backupsTableData.loading
                                                    }"
                                                >
                                                    <span class="animate">
                                                        <loader
                                                            v-if="backupsTableData.loading"
                                                            style="
                                                                font-size: 1.5em;
                                                                top: calc(50% - 0.375em);
                                                                position: absolute;
                                                                left: calc(50% - 0.375em);
                                                            "
                                                        ></loader>
                                                    </span>
                                                    <span>
                                                        <i class="fal fa-power-off"></i>
                                                        {{ T("Disable cloud-backup") }}
                                                    </span>
                                                </a>
                                            </template>
                                            <template
                                                v-if="
                                                    utmVars.hasOwnProperty('passwordSet') &&
                                                    utmVars.GLOB_CLOUDBACKUP_TIME != undefined &&
                                                    utmVars.GLOB_CLOUDBACKUP_TIME == 0
                                                "
                                            >
                                                <a
                                                    id="enableCloudBackup"
                                                    v-on:click="enableCloudBackup"
                                                    class="btn btn-loader twist-in"
                                                    :class="{
                                                        'btn-loading': backupsTableData.loading
                                                    }"
                                                >
                                                    <span class="animate">
                                                        <loader
                                                            v-if="backupsTableData.loading"
                                                            style="
                                                                font-size: 1.5em;
                                                                top: calc(50% - 0.375em);
                                                                position: absolute;
                                                                left: calc(50% - 0.375em);
                                                            "
                                                        ></loader>
                                                    </span>
                                                    <span>
                                                        <i class="fal fa-power-on"></i>
                                                        {{ T("Enable cloud-backup") }}
                                                    </span>
                                                </a>
                                            </template>
                                            <template v-if="utmVars.hasOwnProperty('passwordSet')">
                                                <a
                                                    id="setBackupPassword"
                                                    v-on:click="
                                                        () => {
                                                            renderSetCloudBackupPassword(false)
                                                        }
                                                    "
                                                    class="btn btn-loader twist-in"
                                                    :class="{
                                                        'btn-loading': backupsTableData.loading
                                                    }"
                                                >
                                                    <span class="animate">
                                                        <loader
                                                            v-if="backupsTableData.loading"
                                                            style="
                                                                font-size: 1.5em;
                                                                top: calc(50% - 0.375em);
                                                                position: absolute;
                                                                left: calc(50% - 0.375em);
                                                            "
                                                        ></loader>
                                                    </span>
                                                    <span>
                                                        <i class="fal fa-key"></i>
                                                        {{ T("Set new password") }}
                                                    </span>
                                                </a>
                                            </template>
                                            <template v-if="utmVars.passwordSet == true">
                                                <a
                                                    v-on:click="makeBackup"
                                                    class="btn btn-loader twist-in"
                                                    :class="{ 'btn-loading': makingBackUp }"
                                                >
                                                    <span class="animate">
                                                        <loader
                                                            v-if="makingBackUp"
                                                            style="
                                                                font-size: 1.5em;
                                                                top: calc(50% - 0.375em - 1px);
                                                                position: absolute;
                                                                left: calc(50% - 0.375em - 0.5px);
                                                            "
                                                        ></loader>
                                                    </span>
                                                    <span
                                                        ><i class="fal fa-plus"></i>
                                                        {{ T("Create a new backup") }}</span
                                                    >
                                                </a>
                                            </template>
                                        </p>
                                    </template>
                                    <tableNext
                                        :selectable-columns="backupsTableInfo"
                                        :object-list="utmVars.backups || []"
                                        :has-options="false"
                                        :isLoading="backupsTableData.loading"
                                    ></tableNext>
                                </div>
                            </div>
                        </template>
                        <template v-slot:operationsLog>
                            <div class="text-right padding-xs-y">
                                <buttonComponent
                                    id="refreshOperations"
                                    :buttonOptions="{
                                        loading:
                                            deviceActionStatus('system info').loading ||
                                            operationsLogTableData.loading,
                                        disabled:
                                            deviceActionStatus('system info').disabled ||
                                            deviceActionStatus('system poweroff').rebooting ||
                                            !utmStatus?.online ||
                                            false,
                                        title: T('Refresh'),
                                        text: undefined,
                                        icon: 'fal fa-sync',
                                        onClick: getOperationsLogData
                                    }"
                                ></buttonComponent>
                            </div>

                            <tableNext
                                :selectable-columns="
                                    operationsLogTableData.operationsLogSelectableColumns
                                "
                                :object-list="mergedOperationsLog"
                                :initial-sort-property="'timestamp'"
                                :initial-sort-direction="'DESC'"
                                :has-options="false"
                                :isLoading="operationsLogTableData.loading"
                            ></tableNext>
                        </template>
                        <template v-slot:applications>
                            <div class="text-right padding-xs-y">
                                <a
                                    id="refreshOperations"
                                    v-on:click="execDeviceAction(utm, 'appmgmt status')"
                                    class="btn btn-primary btn-loader twist-in"
                                    :class="{
                                        'btn-loading': deviceActionStatus('appmgmt status').loading,
                                        disabled:
                                            !utmStatus?.online ||
                                            deviceActionStatus('appmgmt status').loading
                                    }"
                                    :disabled="
                                        !utmStatus?.online ||
                                        deviceActionStatus('appmgmt status').loading ||
                                        null
                                    "
                                >
                                    <span class="animate">
                                        <loader
                                            v-if="deviceActionStatus('appmgmt status').loading"
                                            style="
                                                font-size: 1.5em;
                                                top: calc(50% - 0.375em - 1px);
                                                position: absolute;
                                                left: calc(50% - 0.375em - 0.5px);
                                            "
                                        ></loader>
                                    </span>
                                    <span><i class="fal fa-sync"></i></span>
                                </a>
                            </div>
                            <tableNext
                                :selectable-columns="appsTableInfo || []"
                                :object-list="applicationsTableData.tableData || []"
                                :has-options="false"
                                :isLoading="applicationsTableData.loading"
                                :initial-sort-property="'application'"
                                :initial-sort-direction="'ASC'"
                            ></tableNext>
                        </template>
                        <template v-slot:status>
                            <div class="row padding-xs-y">
                                <div class="col-xs-24 col-md-12">
                                    <small
                                        >{{ T("Last update:") }}
                                        <span>
                                            {{
                                                rrdInfo.timestamp
                                                    ? getDate(rrdInfo.timestamp, true)
                                                    : T("Unknown")
                                            }}</span
                                        ></small
                                    >
                                </div>
                                <div class="col-xs-24 col-md-12 text-right">
                                    <a
                                        id="refreshUtmInfo"
                                        v-if="deviceActionStatus('system info').view"
                                        :disabled="
                                            deviceActionStatus('system info').disabled ||
                                            deviceActionStatus('system poweroff').rebooting ||
                                            !utmStatus?.online ||
                                            null
                                        "
                                        v-on:click="getRrdInfo()"
                                        class="btn btn-primary btn-loader twist-in"
                                        :class="{
                                            'btn-loading':
                                                deviceActionStatus('system info').loading,
                                            disabled: !utmStatus?.online
                                        }"
                                    >
                                        <span class="animate">
                                            <loader
                                                v-if="deviceActionStatus('system info').loading"
                                                style="
                                                    font-size: 1.5em;
                                                    top: calc(50% - 0.375em - 1px);
                                                    position: absolute;
                                                    left: calc(50% - 0.375em - 0.5px);
                                                "
                                            ></loader>
                                        </span>
                                        <span><i class="fal fa-sync"></i></span>
                                    </a>
                                </div>
                            </div>
                            <div class="row flexrow padding-xs-t" style="flex-wrap: wrap">
                                <div class="col-xs-24 col-md-12 padding-xs">
                                    <div class="box-shadow padding-xs" style="min-height: 302px">
                                        <h4>{{ T("CPU-0") }}</h4>
                                        <hr class="margin-xs-b" />

                                        <template v-if="!charts.cpu0Chart">
                                            <p
                                                class="text-size-4 text-center color-red padding-xs-y-8"
                                            >
                                                <loader />
                                            </p>
                                        </template>
                                        <template v-else>
                                            <highcharts
                                                class="highcharts-nobg"
                                                style="height: 100%"
                                                :options="charts.cpu0Chart"
                                            ></highcharts>
                                        </template>
                                    </div>
                                </div>
                                <div class="col-xs-24 col-md-12 padding-xs">
                                    <div class="box-shadow padding-xs" style="min-height: 302px">
                                        <h4>{{ T("Load") }}</h4>
                                        <hr class="margin-xs-b" />
                                        <template v-if="!charts.loadChart">
                                            <p
                                                class="text-size-4 text-center color-red padding-xs-y-8"
                                            >
                                                <loader />
                                            </p>
                                        </template>
                                        <template v-else>
                                            <highcharts
                                                class="highcharts-nobg"
                                                style="height: 100%"
                                                :options="charts.loadChart"
                                            ></highcharts>
                                        </template>
                                    </div>
                                </div>
                                <div class="col-xs-24 col-md-12 padding-xs">
                                    <div class="box-shadow padding-xs" style="min-height: 302px">
                                        <h4>{{ T("Memory") }}</h4>
                                        <hr class="margin-xs-b" />
                                        <template v-if="!charts.memoryChart">
                                            <p
                                                class="text-size-4 text-center color-red padding-xs-y-8"
                                            >
                                                <loader />
                                            </p>
                                        </template>
                                        <template v-else>
                                            <highcharts
                                                class="highcharts-nobg"
                                                style="height: 100%"
                                                :options="charts.memoryChart"
                                            ></highcharts>
                                        </template>
                                    </div>
                                </div>
                                <div class="col-xs-24 col-md-12 padding-xs">
                                    <div class="box-shadow padding-xs" style="min-height: 302px">
                                        <h4>{{ T("Disk Storage") }}</h4>
                                        <hr class="margin-xs-b" />
                                        <template v-if="!charts.dfDataChart">
                                            <p
                                                class="text-size-4 text-center color-red padding-xs-y-8"
                                            >
                                                <loader />
                                            </p>
                                        </template>
                                        <template v-else>
                                            <highcharts
                                                class="highcharts-nobg"
                                                style="height: 100%"
                                                :options="charts.dfDataChart"
                                            ></highcharts>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-slot:securityScan>
                            <div class="row padding-xs-y">
                                <div class="text-right col-lg-24">
                                    <a
                                        id="startNewScan"
                                        v-on:click="startNewScan"
                                        class="btn btn-primary btn-loader twist-in text-right"
                                        :class="{ 'btn-loading': scansLoading }"
                                    >
                                        <span
                                            ><i class="fal fa-eye"></i>
                                            {{ T("Start new scan") }}</span
                                        >
                                    </a>
                                    <a
                                        id="refreshScans"
                                        v-on:click="
                                            () => {
                                                getScansData()
                                            }
                                        "
                                        class="btn btn-primary btn-loader twist-in"
                                        :class="{
                                            'btn-loading': scansLoading,
                                            disabled: !utmStatus?.online
                                        }"
                                        :disabled="!utmStatus?.online || null"
                                    >
                                        <span class="animate">
                                            <loader
                                                v-if="deviceActionStatus('system info').loading"
                                                style="
                                                    font-size: 1.5em;
                                                    top: calc(50% - 0.375em - 1px);
                                                    position: absolute;
                                                    left: calc(50% - 0.375em - 0.5px);
                                                "
                                            ></loader>
                                        </span>
                                        <span><i class="fal fa-sync"></i></span>
                                    </a>
                                </div>
                            </div>
                            <hr class="margin-xs-y-2" />
                            <div class="row">
                                <div class="col-xs-24">
                                    <tableNext
                                        :selectable-columns="scansTable"
                                        :object-list="scans || []"
                                        :has-options="false"
                                        :isLoading="scansLoading"
                                    ></tableNext>
                                </div>
                            </div>
                        </template>
                        <template v-slot:inventory>
                            <div class="row">
                                <div class="col-xs-24">
                                    <div class="row">
                                        <div class="col-xs-24 padding-xs-x">
                                            <div
                                                class="row form-group validation-base border-bottom padding-xs-y"
                                            >
                                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                    <label class="control-label" for="billNumber">
                                                        {{ T("Bill number") }}
                                                    </label>
                                                </div>
                                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                    <label class="form-field margin-xs-b-0">
                                                        <input
                                                            id="billNumber"
                                                            type="text"
                                                            class="form-control user-selection"
                                                            :placeholder="T('Bill number')"
                                                            v-model="inventoryForm.billNumber"
                                                            data-validation-property="/billNumber"
                                                        />
                                                    </label>
                                                </div>
                                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                    <p class="input-description">
                                                        {{ T("Bill number") }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                class="row form-group validation-base border-bottom padding-xs-y"
                                            >
                                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                    <label class="control-label" for="billDate">
                                                        {{ T("Bill date") }}
                                                    </label>
                                                </div>
                                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                    <label class="form-field margin-xs-b-0">
                                                        <input-date
                                                            v-model="inventoryForm.billDate"
                                                            :placeholder="T('Select a date')"
                                                            id="billDate"
                                                        />
                                                    </label>
                                                </div>
                                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                    <p class="input-description">
                                                        {{ T("Bill date") }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                class="row form-group validation-base border-bottom padding-xs-y"
                                            >
                                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                    <label
                                                        class="control-label"
                                                        for="warrantyStartDate"
                                                    >
                                                        {{ T("Warranty starts on") }}
                                                    </label>
                                                </div>
                                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                    <label class="form-field margin-xs-b-0">
                                                        <input-date
                                                            v-model="
                                                                inventoryForm.warrantyStartDate
                                                            "
                                                            :placeholder="T('Select a date')"
                                                            id="warrantyStartDate"
                                                        />
                                                    </label>
                                                </div>
                                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                    <p class="input-description">
                                                        {{ T("Warranty start") }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                class="row form-group validation-base border-bottom padding-xs-y"
                                            >
                                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                    <label
                                                        class="control-label"
                                                        for="warrantyEndDate"
                                                    >
                                                        {{ T("Warranty ends on") }}
                                                    </label>
                                                </div>
                                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                    <label class="form-field margin-xs-b-0">
                                                        <input-date
                                                            v-model="inventoryForm.warrantyEndDate"
                                                            :placeholder="T('Select a date')"
                                                            id="warrantyEndDate"
                                                        />
                                                    </label>
                                                </div>
                                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                    <p class="input-description">
                                                        {{ T("Warranty end") }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                class="row form-group validation-base border-bottom padding-xs-y"
                                            >
                                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                    <label
                                                        class="control-label"
                                                        for="inventoryExternalLink"
                                                    >
                                                        {{ T("External inventory link") }}
                                                    </label>
                                                </div>
                                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                    <label class="form-field margin-xs-b-0">
                                                        <input
                                                            id="inventoryExternalLink"
                                                            type="text"
                                                            class="form-control user-selection"
                                                            :placeholder="T('URL')"
                                                            v-model="
                                                                inventoryForm.inventoryExternalLink
                                                            "
                                                            data-validation-property="/inventoryExternalLink"
                                                        />
                                                    </label>
                                                </div>
                                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                    <p class="input-description">
                                                        {{ T("Set link to inventory software") }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                class="row form-group validation-base border-bottom padding-xs-y"
                                            >
                                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                    <label
                                                        class="control-label"
                                                        for="hardwareDescription"
                                                    >
                                                        {{ T("Description") }}
                                                    </label>
                                                </div>
                                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                    <label class="form-field margin-xs-b-0">
                                                        <textarea
                                                            id="hardwareDescription"
                                                            class="form-control user-selection"
                                                            :placeholder="T('Description')"
                                                            v-model="
                                                                inventoryForm.hardwareDescription
                                                            "
                                                            data-validation-property="/hardwareDescription"
                                                        ></textarea>
                                                    </label>
                                                </div>
                                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                    <p class="input-description">
                                                        {{ T("Device description") }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-24 padding-xs-t-4">
                                    <div class="row">
                                        <div class="col-xs-24">
                                            <h4>{{ T("Contract") }}</h4>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                                <hr class="margin-xs-y-2" />
                                <div class="col-xs-24">
                                    <div class="row">
                                        <div class="col-xs-24 padding-xs-x">
                                            <div
                                                class="row form-group validation-base border-bottom padding-xs-y"
                                            >
                                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                    <label class="control-label" for="providerName">
                                                        {{ T("Provider") }}
                                                    </label>
                                                </div>
                                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                    <label class="form-field margin-xs-b-0">
                                                        <input
                                                            id="providerName"
                                                            type="text"
                                                            class="form-control user-selection"
                                                            :placeholder="T('Provider')"
                                                            v-model="inventoryForm.providerName"
                                                            data-validation-property="/providerName"
                                                        />
                                                    </label>
                                                </div>
                                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                    <p class="input-description">
                                                        {{ T("The name of the provider") }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                class="row form-group validation-base border-bottom padding-xs-y"
                                            >
                                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                    <label
                                                        class="control-label"
                                                        for="providerTariff"
                                                    >
                                                        {{ T("Tariff") }}
                                                    </label>
                                                </div>
                                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                    <label class="form-field margin-xs-b-0">
                                                        <input
                                                            id="providerTariff"
                                                            type="text"
                                                            class="form-control user-selection"
                                                            :placeholder="T('Tariff')"
                                                            v-model="inventoryForm.providerTariff"
                                                            data-validation-property="/providerTariff"
                                                        />
                                                    </label>
                                                </div>
                                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                    <p class="input-description">
                                                        {{ T("Tariff") }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                class="row form-group validation-base border-bottom padding-xs-y"
                                            >
                                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                    <label
                                                        class="control-label"
                                                        for="providerCustomerNumber"
                                                    >
                                                        {{ T("Customer number") }}
                                                    </label>
                                                </div>
                                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                    <label class="form-field margin-xs-b-0">
                                                        <input
                                                            id="providerCustomerNumber"
                                                            type="text"
                                                            class="form-control user-selection"
                                                            :placeholder="T('Customer number')"
                                                            v-model="
                                                                inventoryForm.providerCustomerNumber
                                                            "
                                                            data-validation-property="/providerCustomerNumber"
                                                        />
                                                    </label>
                                                </div>
                                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                    <p class="input-description">
                                                        {{ T("Customer number") }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                class="row form-group validation-base border-bottom padding-xs-y"
                                            >
                                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                    <label
                                                        class="control-label"
                                                        for="contractNumber"
                                                    >
                                                        {{ T("Contract number") }}
                                                    </label>
                                                </div>
                                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                    <label class="form-field margin-xs-b-0">
                                                        <input
                                                            id="contractNumber"
                                                            type="text"
                                                            class="form-control user-selection"
                                                            :placeholder="T('Contract number')"
                                                            v-model="inventoryForm.contractNumber"
                                                            data-validation-property="/contractNumber"
                                                        />
                                                    </label>
                                                </div>
                                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                    <p class="input-description">
                                                        {{ T("Contract number") }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                class="row form-group validation-base border-bottom padding-xs-y"
                                            >
                                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                    <label class="control-label" for="contractDate">
                                                        {{ T("Start of contract") }}
                                                    </label>
                                                </div>
                                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                    <label class="form-field margin-xs-b-0">
                                                        <input-date
                                                            v-model="inventoryForm.contractDate"
                                                            :placeholder="T('Select a date')"
                                                            id="contractDate"
                                                        />
                                                    </label>
                                                </div>
                                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                    <p class="input-description">
                                                        {{ T("Start of contract") }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                class="row form-group validation-base border-bottom padding-xs-y"
                                            >
                                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                    <label
                                                        class="control-label"
                                                        for="contractDuration"
                                                    >
                                                        {{ T("Contract term") }}
                                                    </label>
                                                </div>
                                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                    <label class="form-field margin-xs-b-0">
                                                        <input-number
                                                            id="contractDuration"
                                                            :min="0"
                                                            class="form-control user-selection"
                                                            :placeholder="T('Months')"
                                                            v-model="inventoryForm.contractDuration"
                                                            data-validation-property="/contractDuration"
                                                        ></input-number>
                                                    </label>
                                                </div>
                                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                    <p class="input-description">
                                                        {{ T("Contract term") }}
                                                        {{ T("In months") }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                class="row form-group validation-base border-bottom padding-xs-y"
                                            >
                                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                                    <label class="control-label" for="handoverDate">
                                                        {{ T("Handover date") }}
                                                    </label>
                                                </div>
                                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                                    <label class="form-field margin-xs-b-0">
                                                        <input-date
                                                            v-model="inventoryForm.handoverDate"
                                                            :placeholder="T('Select a date')"
                                                            id="handoverDate"
                                                        />
                                                    </label>
                                                </div>
                                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                                    <p class="input-description">
                                                        {{ T("Handover date") }}
                                                    </p>
                                                </div>
                                            </div>
                                            <button
                                                class="btn margin-xs-b-0 margin-xs-t-2 btn-loader twist-in float-xs-right"
                                                v-on:click="saveInventory()"
                                                :class="{ 'btn-loading': savingInventory == true }"
                                                :disabled="savingInventory == true || false"
                                                id="saveButton"
                                                title="Save"
                                                type="submit"
                                            >
                                                <span class="animate">
                                                    <loader
                                                        v-if="savingInventory == true"
                                                        style="
                                                            font-size: 1.5em;
                                                            top: calc(50% - 0.375em - 1px);
                                                            position: absolute;
                                                            left: calc(50% - 0.375em - 0.5px);
                                                        "
                                                    ></loader>
                                                </span>
                                                <span
                                                    ><i class="fal fa-save"></i>&nbsp;
                                                    <span
                                                        class="display-xs-none display-lg-inline"
                                                        >{{ T("Save") }}</span
                                                    ></span
                                                >
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template
                            v-slot:utmState
                            v-if="tenantHelpers.hasAccountRole('__SUPPORT__')"
                        >
                            <div class="row">
                                <div class="col-xs-24 padding-xs">
                                    <div class="box-shadow">
                                        <div class="box-body">
                                            <div class="padding-xs-t padding-xs-l">
                                                <div class="toolbar flexrow">
                                                    <div
                                                        class="col-xs flex-shrink"
                                                        style="
                                                            margin-bottom: 8px;
                                                            margin-right: 8px;
                                                        "
                                                        :title="T('Period')"
                                                    >
                                                        <label class="form-field margin-xs-b-0">
                                                            <span class="form-icon-prefix"
                                                                ><i class="fal fa-clock"></i
                                                            ></span>
                                                            <select
                                                                id="tenant-report-dashboard-period"
                                                                v-model="utmVars.state.period"
                                                                class="form-control"
                                                            >
                                                                <option value="today">
                                                                    {{ T("Today") }}
                                                                </option>
                                                                <option value="lastday">
                                                                    {{ T("Yesterday") }}
                                                                </option>
                                                                <option value="currentweek">
                                                                    {{ T("Current week") }}
                                                                </option>
                                                                <option value="currentmonth">
                                                                    {{ T("Current month") }}
                                                                </option>
                                                                <option value="last30days">
                                                                    {{ T("Last 30 days") }}
                                                                </option>
                                                            </select>
                                                        </label>
                                                    </div>
                                                    <div
                                                        class="col-xs flex-grow"
                                                        style="
                                                            margin-bottom: 8px;
                                                            margin-right: 8px;
                                                            text-align: right;
                                                        "
                                                    >
                                                        <a
                                                            id="refreshUtmState"
                                                            v-on:click="getUtmState(true)"
                                                            class="btn btn-primary btn-loader twist-in"
                                                            :class="{
                                                                'btn-loading':
                                                                    utmVars.state.loading,
                                                                disabled:
                                                                    !utmStatus?.online ||
                                                                    utmVars.state.loading
                                                            }"
                                                            :disabled="
                                                                !utmStatus?.online ||
                                                                utmVars.state.loading ||
                                                                null
                                                            "
                                                        >
                                                            <span class="animate">
                                                                <loader
                                                                    v-if="utmVars.state.loading"
                                                                    style="
                                                                        font-size: 1.5em;
                                                                        top: calc(
                                                                            50% - 0.375em - 1px
                                                                        );
                                                                        position: absolute;
                                                                        left: calc(
                                                                            50% - 0.375em - 0.5px
                                                                        );
                                                                    "
                                                                ></loader>
                                                            </span>
                                                            <span><i class="fal fa-sync"></i></span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-24 col-md-12 padding-xs">
                                    <div class="box-shadow">
                                        <div class="box-body">
                                            <div class="box-icon icon-center">
                                                <div class="icon-circle icon-circle bg-yellow">
                                                    <i class="fal fa-ban"></i>
                                                </div>
                                            </div>
                                            <div class="box-content-2 text-center">
                                                <p
                                                    class="text-size-3"
                                                    v-if="
                                                        utmVars.state.totalRejects.value !=
                                                        undefined
                                                    "
                                                >
                                                    {{ utmVars.state.totalRejects.value }}
                                                </p>
                                                <p v-else class="text-size-3 color-red">
                                                    <loader></loader>
                                                </p>
                                            </div>
                                            <hr />
                                            <div class="text-center text-bold padding-xs-y-2">
                                                {{ T("Total Rejects") }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-24 col-md-12 padding-xs">
                                    <div class="box-shadow">
                                        <div class="box-body">
                                            <div class="box-icon icon-center">
                                                <div
                                                    class="icon-circle icon-circle"
                                                    :class="{
                                                        'bg-red': utmStatus?.online === false,
                                                        'bg-green': utmStatus?.online === true,
                                                        'bg-yellow': utmStatus == undefined
                                                    }"
                                                >
                                                    <i class="fal fa-power-off"></i>
                                                </div>
                                            </div>
                                            <div class="box-content-2 text-center">
                                                <p class="text-size-3">
                                                    <template v-if="utmStatus?.online === false">
                                                        {{ T("Disconnected") }}
                                                    </template>
                                                    <template v-if="utmStatus?.online === true">
                                                        {{ T("Connected") }}
                                                    </template>
                                                    <template v-if="utmStatus == undefined">
                                                        {{ T("Status unknown") }}
                                                    </template>
                                                </p>
                                            </div>
                                            <hr />
                                            <div class="text-center text-bold padding-xs-y-2">
                                                {{ T("Current connection status") }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-24 padding-xs">
                                    <div class="box-shadow">
                                        <div class="box-body">
                                            <div class="box-content">
                                                <h3>
                                                    <i class="fa-fw fal fa-chart-area"></i>
                                                    {{ T("Rejected connections") }}
                                                </h3>
                                            </div>
                                            <hr class="margin-xs-0" />
                                            <div class="box-content-2">
                                                <div
                                                    id="utm-rejects-histogramm-container"
                                                    style="min-height: 200px"
                                                    v-if="!utmVars.state.rejectsHistogramm.InfoText"
                                                >
                                                    <canvas id="utm-rejects-histogramm"></canvas>
                                                </div>
                                                <div
                                                    style="
                                                        line-height: 200px;
                                                        font-size: 32px;
                                                        text-align: center;
                                                    "
                                                    v-else
                                                >
                                                    {{
                                                        T(utmVars.state.rejectsHistogramm.InfoText)
                                                    }}
                                                </div>
                                                <div
                                                    v-if="utmVars.state.rejectsHistogramm.loading"
                                                    class="overlay"
                                                    style="background: rgba(255, 255, 255, 0.8)"
                                                >
                                                    <loader
                                                        style="
                                                            stroke: #e74c3c;
                                                            font-size: 48px;
                                                            position: absolute;
                                                            top: 50%;
                                                            left: 50%;
                                                            transform: translate(-50%, -50%);
                                                        "
                                                    ></loader>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-24 padding-xs">
                                    <div class="box-shadow">
                                        <div class="box-body">
                                            <div class="box-content">
                                                <h3>
                                                    <i class="fa-fw fal fa-chart-area"></i>
                                                    {{ T("Latest rejects") }}
                                                </h3>
                                            </div>
                                            <hr class="margin-xs-0" />
                                            <div class="box-content-2">
                                                <table2Component
                                                    :table="utmVars.state.latestRejects.tableData"
                                                    :scrollbar="true"
                                                    :maxheight="500"
                                                    :loading="utmVars.state.latestRejects.loading"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-slot:jobs>
                            <div class="row padding-xs-y">
                                <div class="col-xs-24 text-right">
                                    <a
                                        id="refreshOperations"
                                        v-on:click="getCronoJobsList"
                                        class="btn btn-primary btn-loader twist-in"
                                        :class="{ 'btn-loading': gettingHistory }"
                                        :disabled="
                                            !utmStatus?.online ||
                                            operationsLogTableData.loading ||
                                            null
                                        "
                                    >
                                        <span class="animate">
                                            <loader
                                                v-if="deviceActionStatus('system info').loading"
                                                style="
                                                    font-size: 1.5em;
                                                    top: calc(50% - 0.375em - 1px);
                                                    position: absolute;
                                                    left: calc(50% - 0.375em - 0.5px);
                                                "
                                            ></loader>
                                        </span>
                                        <span><i class="fal fa-sync"></i></span>
                                    </a>
                                </div>
                            </div>
                            <cronojoblistComponent ref="cronojoblistRef" />
                        </template>
                    </tabsComponent>
                </div>
            </div>
        </div>
        <div class="padding-xs-y-4"></div>
    </section>
</template>
