import config from "@/classes/config"
import { T } from "@/classes/i18n"
import devLog from "@/classes/log"
import frontendNotifications from "@/classes/notifications"
import tenantHelpers from "@/helpers/helpers.tenants"
import requestHandler from "@/queries/requests"
import useRouterStore from "@/router/routerStore"
import { MutationTypes, useStore } from "@/store/vuex.store"
import ModalObject, {
    type Modal,
    ModalObjectButton
} from "@/templates/components/modals/modalClass"
import { defineStore } from "pinia"
import { computed, onMounted, onUnmounted, reactive, ref, watch } from "vue"
import products from "../.."

export type AndroidProfilePublishState = {
    publishId: string
    publishTotal: number
    published: number
    templatePolicyName: string
    errors: AndroidProfilePublishError[]
    isWaiting: boolean
}

type AndroidProfilePublishError = {
    message: string
    data: { policyName: string }
    timestamp: number
}

export const useAndroidPublishStore = defineStore("androidProfilePublish", () => {
    const activeAccountId = computed(() => {
        return useStore()?.state?.session?.activeAccountId || ""
    })

    const publishId = ref("")
    const publishState = reactive({
        publishId: "",
        publishTotal: 0,
        published: 0,
        templatePolicyName: "",
        errors: [],
        isWaiting: false
    } as AndroidProfilePublishState)

    const isPublishing = computed(() => {
        return (
            publishState.publishId &&
            publishState.templatePolicyName &&
            typeof publishState.publishTotal == "number" &&
            typeof publishState.published == "number" &&
            publishState.publishTotal > 0 &&
            publishState.publishTotal > publishState.published
        )
    })

    const activePage = computed(() => {
        return useRouterStore()?.getActivePage || ""
    })

    function resetState() {
        publishId.value = ""
        publishState.publishId = ""
        publishState.publishTotal = 0
        publishState.published = 0
        publishState.errors = []
        publishState.templatePolicyName = ""
        publishState.isWaiting = false
    }

    function hasPublishModal() {
        const modals1: (Modal | ModalObject)[] =
            useStore()?.state?.session?.accounts?.[activeAccountId.value]?.modals || []
        const modals2: (Modal | ModalObject)[] = useStore()?.state?.modals || []
        let result = false

        if (Array.isArray(modals1) && Array.isArray(modals2)) {
            function isTemplateModal(modal: ModalObject | Modal | undefined) {
                if (
                    typeof modal?.id == "string" &&
                    modal.id &&
                    modal.id == "templacePolicyProgress"
                ) {
                    return true
                } else {
                    return false
                }
            }

            result =
                modals1.some((modal) => isTemplateModal(modal)) ||
                modals2.some((modal) => isTemplateModal(modal))
        }
        return result
    }

    const renderTemplatePolicyProgressModal = () => {
        if (
            isPublishing.value &&
            activePage.value.includes("android") &&
            activeAccountId.value &&
            hasPublishModal() == false
        ) {
            new ModalObject({
                accountId: activeAccountId.value,
                id: "templacePolicyProgress",
                content: {
                    body: {
                        component: "template-policy-progress",
                        properties: undefined
                    },
                    title: {
                        icon: "fal fa-bars-progress",
                        text: T("Creating Template Policies")
                    }
                },
                abortable: true,
                buttons: [
                    new ModalObjectButton({
                        text: T("Schließen und im Hintergrund weiter ausführen"),
                        icon: "fal fa-times",
                        disabled: false,
                        onClick: () => {
                            useStore().commit(MutationTypes.removeModal, {})
                        }
                    })
                ]
            }).showFirst()
        } else if (isPublishing.value && activeAccountId.value) {
            frontendNotifications.addNotification(activeAccountId.value, {
                accountId: activeAccountId.value,
                content: {
                    body: {
                        content: T(
                            "Die Veröffentlichung für ein Template Profil hat gestartet. Einige Aktionen für die Benutzer oder Android Management sind für diesen Zeitraum blockiert."
                        )
                    },
                    title: {
                        text: T("Template Profil Veröffentlichung"),
                        icon: "fal fa-upload"
                    }
                },
                highlightNew: true
            })
        }
    }

    // Get state
    async function getPublishState() {
        if (activeAccountId.value) {
            try {
                let result: AndroidProfilePublishState | Error | undefined =
                    await requestHandler.request(
                        "GET",
                        config.mgtApiUriNext +
                            "/tenants/" +
                            tenantHelpers.getTenantDomain(activeAccountId.value) +
                            "/android/emm/enterprise/publishInfos"
                    )
                if (result instanceof Error) {
                    throw result
                } else if (result?.publishId) {
                    Object.keys(result).forEach((key) => {
                        publishState[key as keyof typeof publishState] = result[
                            key as keyof typeof result
                        ] as never
                    })
                    publishState.publishId = result.publishId
                    publishId.value = result.publishId
                }
            } catch (e: unknown) {
                console.error(e)

                if (e && typeof e == "object" && "status" in e && e["status"] == 404) {
                    resetState()
                }
            }
        }
    }

    watch([activeAccountId, publishId], getPublishState)
    watch(
        () => [publishState.publishId, publishState.templatePolicyName],
        renderTemplatePolicyProgressModal
    )
    watch(activeAccountId, async (newVal, oldValue) => {
        if (oldValue) {
            devLog.log(
                "[TemplatePolicyProgress]",
                "Websocket Hook for TemplatePolicyProgress removed",
                activeAccountId.value
            )
            useStore().commit(MutationTypes.deleteSubscriptionHook, {
                accountId: oldValue,
                hookKey: "templatePolicyProgress"
            })
        }

        if (newVal) {
            devLog.log(
                "[TemplatePolicyProgress]",
                "Websocket Hook for TemplatePolicyProgress added",
                activeAccountId.value
            )
            useStore().commit(MutationTypes.addSubscriptionHook, {
                accountId: newVal,
                hookKey: "templatePolicyProgress",
                hookFunction: async (message: any) => {
                    const topic: string = message?.topic || ""
                    const data: any = message?.data

                    if (topic == "/profile/publish/start") {
                        publishId.value = data?.publishId || ""
                    } else if (
                        (topic == "/profile/publish" || topic == "/profile/converted") &&
                        data?.publishId &&
                        data.publishId == publishState.publishId &&
                        typeof publishState.published == "number" &&
                        typeof publishState.publishTotal == "number" &&
                        publishState.published < publishState.publishTotal
                    ) {
                        publishState.published++
                        if (Array.isArray(data?.errors) && data.errors.length > 0) {
                            publishState.errors = publishState.errors.concat(data.errors)
                        }
                    } else if (
                        topic == "/profile/publish/end" &&
                        data?.publishId == publishId.value
                    ) {
                        products.mobileSecurity.androidProfiles.queries.getObjectsFromApi(
                            activeAccountId.value,
                            undefined,
                            undefined,
                            true,
                            true
                        )

                        if (
                            typeof publishState.published == "number" &&
                            typeof publishState.publishTotal == "number" &&
                            publishState.publishTotal > publishState.published
                        ) {
                            publishState.published = publishState.publishTotal
                        }
                    }
                }
            })
        }
    })

    onMounted(async () => {
        devLog.log("[TemplatePolicyProgress]", "Store Mounted")
        await getPublishState()
    })

    onUnmounted(() => {
        devLog.log("[TemplatePolicyProgress]", "Store Unmounted")
        useStore().commit(MutationTypes.deleteSubscriptionHook, {
            accountId: activeAccountId.value,
            hookKey: "templatePolicyProgress"
        })
    })

    return {
        publishState,
        publishId,
        isPublishing
    }
})
