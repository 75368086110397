<script setup lang="ts">
import { useVue } from "@/app"
import { T } from "@/classes/i18n"
import products from "@/classes/objectTypes"
import type { UtmNode } from "@/classes/objectTypes/unifiedSecurityConsole/nodes"
import { type SunNkViewNode } from "@/classes/objectTypes/unifiedSecurityConsole/topologies"
import getterHelpers from "@/helpers/helpers.getters"
import tenantHelpers from "@/helpers/helpers.tenants"
import validationHelpers from "@/helpers/helpers.validation"
import ipaddr from "@/lib/ipaddr"
import { computed, onMounted, ref, watch } from "vue"
import loader from "../components/loader.vue"
import inputTextComponent from "../inputtypes/input-text.vue"
import inputSelectComponent from "../inputtypes/input-vue-select.vue"

const props = defineProps<{
    properties: {
        nodeId: string
        topologyId: string
        isCoreDns?: true
        type: "source" | "destination"
    }
}>()

const nameRegex: RegExp = new RegExp("^[^\\s]+$")
// Refs
const node = ref(<undefined | UtmNode>undefined)
const loading = ref(false)
const error = ref("")

const networkObject = ref("")
const networkGroup = ref("")
const type = ref("host")
const address = ref("")
const hasAddressChanged = ref(false)
const zone = ref(<number | null>null)

// Computed Refs
const activeAccountId = computed(() => {
    return getterHelpers.useStore()?.getters.getActiveAccountId || ""
})
const activeTenantDomain = computed(() => {
    return tenantHelpers.getTenantDomain(activeAccountId.value)
})

const typeSelectOptions = computed(() => {
    return [
        {
            text: T("Host"),
            id: "host"
        },
        {
            text: T("Network"),
            id: "network-address"
        }
    ] as selectOption[]
})
const networkGroupSelectOptions = computed(() => {
    return Array.isArray(node.value?.nkView?.nodes)
        ? node.value.nkView.nodes
              .map((node) => {
                  if (Array.isArray(node.node_refs))
                      return {
                          id: node.name,
                          text: node.name,
                          objectInfo: node
                      } as selectOption
              })
              .filter((option) => option !== undefined)
        : []
})
const zoneSelectOptions = computed(() => {
    return (node.value?.nkView?.zones || []).map((zone: any) => {
        return {
            text: zone.name,
            id: zone.id
        } as selectOption
    })
})
const selectedZone = computed(() => {
    return zoneSelectOptions.value.find((thisZone: selectOption) => {
        return thisZone.id == zone.value
    }) as selectOption
})
const selectedNetworkGroupInfo = computed(() => {
    return networkGroupSelectOptions.value.find((option: selectOption) => {
        let nodeInfo = <SunNkViewNode>option.objectInfo
        return nodeInfo.name == networkGroup.value
    })?.objectInfo
})
const reservedObjects = computed(() => {
    return (node.value?.nkView?.reservedObjects || []) as string[]
})
const addressErrors = computed(() => {
    let thisErrors: string[] = []
    try {
        if (hasAddressChanged.value) {
            if (address.value.length >= 1) {
                const thisAddress = new ipaddr(address.value)
                if (thisAddress.isIPv4() || thisAddress.isIPv6()) {
                    const validationMap = {
                        host: () => {
                            if (thisAddress.hasCidr() == false) {
                                thisErrors.push("Missing network mask.")
                            } else {
                                if (thisAddress.isIPv4()) {
                                    if (Number(thisAddress.cidr) !== 32) {
                                        thisErrors.push("Network mask must be /32.")
                                    } else {
                                        const baseCheckResult = validationHelpers.isCorrectIpV4Base(
                                            thisAddress.addr,
                                            Number(thisAddress.cidr) as 32
                                        )
                                        if (baseCheckResult !== true) {
                                            thisErrors.push(baseCheckResult.message)
                                        }
                                    }
                                }
                                if (thisAddress.isIPv6()) {
                                    if (
                                        Number(thisAddress.cidr) > 128 ||
                                        Number(thisAddress.cidr) < 0
                                    ) {
                                        thisErrors.push("Network mask must be /128.")
                                    } else {
                                        const baseCheckResult = validationHelpers.isCorrectIpV6Base(
                                            thisAddress.addr,
                                            Number(thisAddress.cidr) as 128
                                        )
                                        if (baseCheckResult !== true) {
                                            thisErrors.push(baseCheckResult.message)
                                        }
                                    }
                                }
                            }
                        },
                        "network-address": () => {
                            if (thisAddress.hasCidr() == false) {
                                thisErrors.push("Missing network mask.")
                            } else {
                                if (thisAddress.isIPv4()) {
                                    if (
                                        Number(thisAddress.cidr) > 32 ||
                                        Number(thisAddress.cidr) < 0
                                    ) {
                                        thisErrors.push("Enter a network mask between /0 and /32.")
                                    } else {
                                        const baseCheckResult = validationHelpers.isCorrectIpV4Base(
                                            thisAddress.addr,
                                            Number(thisAddress.cidr) as NumericRange<
                                                CreateArrayWithLengthX<0>,
                                                32
                                            >
                                        )
                                        if (baseCheckResult !== true) {
                                            thisErrors.push(baseCheckResult.message)
                                        }
                                    }
                                }

                                if (thisAddress.isIPv6()) {
                                    if (
                                        Number(thisAddress.cidr) > 128 ||
                                        Number(thisAddress.cidr) < 0
                                    ) {
                                        thisErrors.push("Enter a network mask between /0 and /128.")
                                    } else {
                                        const baseCheckResult = validationHelpers.isCorrectIpV6Base(
                                            thisAddress.addr,
                                            Number(thisAddress.cidr) as NumericRange<
                                                CreateArrayWithLengthX<0>,
                                                128
                                            >
                                        )
                                        if (baseCheckResult !== true) {
                                            thisErrors.push(baseCheckResult.message)
                                        }
                                    }
                                }
                            }
                        }
                    }
                    validationMap[type.value as keyof typeof validationMap]?.()
                } else {
                    thisErrors.push("The specified address does not match an ipv4 or ipv6 address.")
                }
            } else {
                thisErrors.push("Enter an IP address.")
            }
        }
    } catch (e) {
        thisErrors.push("The specified address does not match an ipv4 or ipv6 address.")
    }
    return thisErrors
})

// Functions
const checkReadyState = () => {
    if (
        networkObject.value != "" &&
        !reservedObjects.value.includes(networkObject.value) &&
        ((networkGroup.value != "" && nameRegex.test(networkGroup.value)) ||
            networkGroup.value == "") &&
        !isNaN(zone.value || NaN) &&
        validationHelpers &&
        type.value != "" &&
        error.value == "" &&
        addressErrors.value.length == 0 &&
        errors.value.length == 0
    )
        useVue().$refs.modals.$refs.modal.modal.buttons[1].disabled = false
    else useVue().$refs.modals.$refs.modal.modal.buttons[1].disabled = true
}

const errors = computed(() => {
    let result: string[] = []
    if (error.value) {
        result.push(error.value)
    }
    if (reservedObjects.value.includes(networkObject.value)) {
        result.push(T("Some of the provided names collide with the reserved names of the UTM."))
    }
    if (
        reservedObjects.value.includes(networkGroup.value) &&
        !networkGroupSelectOptions.value.some(
            (option: selectOption) => option.id == networkGroup.value
        )
    ) {
        result.push(T("Some of the provided names collide with the reserved names of the UTM."))
    }
    if (
        (networkObject.value &&
            (!nameRegex.test(networkObject.value) || networkObject.value?.indexOf("\\") != -1)) ||
        (networkGroup.value &&
            (!nameRegex.test(networkGroup.value) || networkGroup.value?.indexOf("\\") != -1))
    ) {
        result.push(T("Some of the provided names contain backslashes or whitespaces."))
    }
    if (
        networkObject.value != "" &&
        networkGroup.value != "" &&
        networkObject.value == networkGroup.value
    ) {
        result.push(T("Please chose unique identifiers for name and service group name."))
    }
    return result
})

onMounted(async () => {
    loading.value = true
    node.value = products.unifiedSecurityConsole.utmNodes
        .useStore?.()
        .getObjectStoreObject(activeAccountId.value, props.properties.nodeId)
    loading.value = false
})
watch([networkGroup, networkObject, address, zone, type], () => {
    clearErrorString()
})
watch([networkGroup, networkObject, error, address, zone, type], () => {
    checkReadyState()
})

watch(address, (newAddress, oldAddress) => {
    hasAddressChanged.value = true
    try {
        if (
            newAddress[newAddress.length - 1] == "/" &&
            newAddress.length == oldAddress.length + 1
        ) {
            const thisAddress = new ipaddr(oldAddress)
            if (type.value == "network-address") {
                if (!thisAddress.hasCidr()) {
                    if (thisAddress.isIPv4()) {
                        address.value += "24"
                    } else if (thisAddress.isIPv6()) {
                        address.value += "64"
                    }
                }
            } else {
                if (!thisAddress.hasCidr()) {
                    if (thisAddress.isIPv4()) {
                        address.value += "32"
                    } else if (thisAddress.isIPv6()) {
                        address.value += "128"
                    }
                }
            }
        }
    } catch (e) {}
})

const setErrorString = (err: string) => {
    error.value = err
}
const clearErrorString = () => {
    error.value = ""
}

defineExpose({
    networkObject,
    address,
    selectedZone,
    networkGroup,
    setErrorString,
    clearErrorString,
    selectedNetworkGroupInfo
})
</script>
<template>
    <div class="content-2">
        <template v-if="loading == false">
            <!-- Name -->
            <div class="row padding-xs-t padding-xs-b-2 form-group border-bottom">
                <div class="first col-xs-24 col-lg-6 col-xl-6">
                    <label class="control-label inputname" for="sourceNodeSelect">
                        {{ T("Name") }}
                    </label>
                </div>
                <div class="input col-xs-24 col-lg-13 col-xl-10">
                    <inputTextComponent
                        :placeholder="T('Name')"
                        v-model="networkObject"
                    ></inputTextComponent>
                </div>
                <div class="input-description col-lg-7 col-xl-8">
                    <strong>{{ T("Pflichtfeld") }}</strong>
                </div>
            </div>

            <!-- Type -->
            <div class="row padding-xs-t padding-xs-b-2 form-group border-bottom">
                <div class="first col-xs-24 col-lg-6 col-xl-6">
                    <label class="control-label inputname" for="sourceNodeSelect">
                        {{ T("Type") }}
                    </label>
                </div>
                <div class="input col-xs-24 col-lg-13 col-xl-10">
                    <inputSelectComponent
                        :select-options="typeSelectOptions"
                        v-model="type"
                    ></inputSelectComponent>
                </div>
                <div class="input-description col-lg-7 col-xl-8">
                    <strong>{{ T("Pflichtfeld") }}</strong>
                </div>
            </div>

            <!-- Address -->
            <div class="row padding-xs-t padding-xs-b-2 form-group border-bottom">
                <div class="first col-xs-24 col-lg-6 col-xl-6">
                    <label class="control-label inputname" for="sourceNodeSelect">
                        {{ T("Address") }}
                    </label>
                </div>
                <div class="input col-xs-24 col-lg-13 col-xl-10">
                    <inputTextComponent
                        v-model="address"
                        :placeholder="
                            type == 'host' ? T('IP address') : T('IP address with subnet mask')
                        "
                    ></inputTextComponent>
                    <template v-if="addressErrors.length">
                        <template v-for="err in addressErrors">
                            <span class="error-bubble label bg-red margin-xs-t"> {{ T(err) }}</span>
                        </template>
                    </template>
                </div>
                <div class="desc col-lg-7 col-xl-8">
                    <p class="input-description">
                        <strong>{{ T("Pflichtfeld") }}.</strong>
                        {{ T("IPv4 or IPv6 address to be used as the transfer network.") }}
                    </p>
                </div>
            </div>

            <!-- Zone -->
            <div class="row padding-xs-t padding-xs-b-2 form-group border-bottom">
                <div class="first col-xs-24 col-lg-6 col-xl-6">
                    <label class="control-label inputname" for="sourceNodeSelect">
                        {{ T("Zone") }}
                    </label>
                </div>
                <div class="input col-xs-24 col-lg-13 col-xl-10">
                    <inputSelectComponent
                        :select-options="zoneSelectOptions"
                        :placeholder="T('Select a zone')"
                        v-model="zone"
                    >
                    </inputSelectComponent>
                </div>
                <div class="input-description col-lg-7 col-xl-8">
                    <strong>{{ T("Pflichtfeld") }}</strong>
                </div>
            </div>

            <!-- Groups -->
            <div
                class="row padding-xs-t padding-xs-b-2 form-group"
                v-if="!properties.isCoreDns === true"
            >
                <div class="first col-xs-24 col-lg-6 col-xl-6">
                    <label class="control-label inputname" for="sourceNodeSelect">
                        {{ T("Network Group") }}
                    </label>
                </div>
                <div class="input col-xs-24 col-lg-13 col-xl-10">
                    <inputSelectComponent
                        :placeholder="T('Select Network Group')"
                        :select-options="networkGroupSelectOptions"
                        v-model="networkGroup"
                        :clearable="true"
                        :tags="true"
                    ></inputSelectComponent>
                </div>
                <div class="col-lg-7 col-xl-8">
                    <p class="input-description">
                        {{
                            T(
                                "Adds a network object to an existing network group or creates a new group"
                            )
                        }}.
                        <br />
                        <br />
                        <template v-if="properties.type == 'destination'">
                            {{ T("The selected network group is adopted as the destination") }}.
                        </template>
                        <template v-else>
                            {{ T("The selected network group is adopted as the source") }}.
                        </template>
                    </p>
                </div>
            </div>
        </template>

        <template v-else>
            <div class="text-size-3 text-center padding-xs-t-4">
                <div class="text-size-2">
                    <loader class="color-red"></loader>
                </div>
            </div>
            <div class="text-center padding-xs-t-2" style="opacity: 0.8">
                <span>
                    {{ T("Getting Node Information...") }}
                </span>
            </div>
        </template>
        <template v-if="errors?.length">
            <template v-for="error in errors">
                <p class="notification bg-red color-white">
                    {{ T(error) }}
                </p>
            </template>
        </template>
    </div>
</template>
