import { type OldStore } from "@/store/vuex.store"

const getterHelpers = {
    useVue: () => {
        return window.app?.vue
    },
    useStore: () => {
        return window.app?.vue?.$store as OldStore
    },
    useFeatureStore: () => {
        return window.app?.vue?.featureStore as
            | {
                  isEnabled: (feature: string) => boolean
                  getters: {
                      isEnabled: (feature: string) => boolean
                      getFeatureListFromStore: () => Set<string>
                  }
              }
            | undefined
    }
}
export default getterHelpers
