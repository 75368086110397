<script setup lang="ts">
import config from "@/classes/config"
import { T } from "@/classes/i18n"
import objectStores from "@/classes/init"
import products from "@/classes/objectTypes"
import type { UscUtm as UscUtmNew } from "@/classes/objectTypes/unifiedSecurityConsole/uscUtms"
import websocketHandler from "@/classes/websocket"
import getterHelpers from "@/helpers/helpers.getters"
import tenantHelpers from "@/helpers/helpers.tenants"
import timeHelpers from "@/helpers/helpers.time"
import validationHelpers from "@/helpers/helpers.validation"
import requestHandler from "@/queries/requests"
import useRouterStore from "@/router/routerStore"
import { MutationTypes } from "@/store/vuex.store"
import {
    computed,
    getCurrentInstance,
    onBeforeUnmount,
    onMounted,
    ref,
    useTemplateRef,
    watch
} from "vue"
import Loader from "../components/loader.vue"
import inputPin from "../inputtypes/input-pin.vue"
import InputVueSelect from "../inputtypes/input-vue-select.vue"

const instance = getCurrentInstance()

const props = defineProps<{
    properties: {
        accountId: string
        utmId: string
    }
}>()

const activeAccountId = computed(() => {
    return (
        props.properties.accountId || getterHelpers.useStore().state.session.activeAccountId || ""
    )
})
const activePage = computed(() => {
    return useRouterStore().getActivePage || ""
})
const objectId = computed(() => {
    return props.properties.utmId || useRouterStore().getObjectId || ""
})

const utm = computed(() => {
    const utm = config.canUseNewObjectType("uscUtms")
        ? products.unifiedSecurityConsole.uscUtms
              .useStore?.()
              .getObjectStoreObject(activeAccountId.value, props.properties.utmId)
        : objectStores.uscUtms.getObjectFromStore(activeAccountId.value, props.properties.utmId)
    return utm
})
const startWebsessionEnabled = computed(() => {
    return utm.value?.permissions?.startWebsessionEnabled
})
const hasOnlyAdmin = computed(() => {
    return (
        (selectOptions.value.users || []).length == 1 && selectOptions.value.users[0]?.id == "admin"
    )
})

const initialized = ref(false)
const loaderText = ref("Getting UTM settings...")
const showNotification = ref(false)
const errors = ref(<string[]>[])
const selectOptions = ref({
    addressesInitialized: false,
    addresses: <selectOption[]>[],
    users: <selectOption[]>[]
})
const formdata = ref({
    address: "",
    portInitialized: <boolean>false,
    port: "11115",
    version: undefined,
    user: "admin",
    publicAddressSelected: <boolean>false,
    isPinDisabled: <boolean>false,
    usePin: <undefined | boolean>undefined,
    pin: "",
    rememberPin: <boolean>false,
    showRememberPin: <boolean>true
})
const canUseNatWebsession = ref(<undefined | boolean>undefined)
const url = ref("")
const loading = ref(true)

const init = async () => {
    if (config.canUseNewObjectType("uscUtms")) {
        await products.unifiedSecurityConsole.uscUtms.queries.getObjectFromApi(
            props.properties.accountId,
            props.properties.utmId
        )
    } else {
        await objectStores.uscUtms.getObjectFromApi(
            props.properties.accountId,
            props.properties.utmId
        )
    }

    if (startWebsessionEnabled.value) {
        websocketHandler.connect(props.properties.accountId)
        await (
            config.canUseNewObjectType("uscUtms")
                ? products.unifiedSecurityConsole.uscUtms
                : objectStores.uscUtms
        ).sendSpcliMessage(props.properties.accountId, props.properties.utmId, {
            data: ["system", "info"]
        })
        await (
            config.canUseNewObjectType("uscUtms")
                ? products.unifiedSecurityConsole.uscUtms
                : objectStores.uscUtms
        ).sendSpcliMessage(props.properties.accountId, props.properties.utmId, {
            data: ["interface", "address", "get"]
        })
        await (
            config.canUseNewObjectType("uscUtms")
                ? products.unifiedSecurityConsole.uscUtms
                : objectStores.uscUtms
        ).sendSpcliMessage(props.properties.accountId, props.properties.utmId, {
            data: ["extc", "value", "get", "application", "webserver", "variable", "ADMIN_PORT"]
        })
        await (
            config.canUseNewObjectType("uscUtms")
                ? products.unifiedSecurityConsole.uscUtms
                : objectStores.uscUtms
        ).sendSpcliMessage(props.properties.accountId, props.properties.utmId, {
            data: ["user", "admin", "get"]
        })

        const utmVersion = config.canUseNewObjectType("uscUtms")
            ? products.unifiedSecurityConsole.uscUtms.getUtmMessageData(
                  utm.value as UscUtmNew,
                  "spcli-system-info"
              )?.version
            : objectStores.uscUtms.getUtmMessageData(utm.value as UscUtmNew, "spcli-system-info")
                  ?.version
        if (
            utmVersion != undefined &&
            validationHelpers.versionCompare("12.5.0", utmVersion) != 1
        ) {
            await (
                config.canUseNewObjectType("uscUtms")
                    ? products.unifiedSecurityConsole.uscUtms
                    : objectStores.uscUtms
            ).sendSpcliMessage(props.properties.accountId, props.properties.utmId, {
                data: [
                    "extc",
                    "value",
                    "get",
                    "application",
                    "spcloudpuppet",
                    "variable",
                    "SESSIONAUTH_ENABLE"
                ]
            })
        } else {
            formdata.value.usePin = false
        }
        initialized.value = true
        loading.value = false
        const storagePin = sessionStorage.getItem(getPinStorageKey())
        if (storagePin) {
            formdata.value.pin = storagePin
            formdata.value.showRememberPin = false
        }

        // #33726 If still loading it should display a hint
        await timeHelpers.sleep(45000)
        showNotification.value = true
    } else {
        getterHelpers.useStore().getters.getActiveModal().content.title.text = T(
            "Websession ist nicht aktiviert"
        )
        formdata.value.usePin = false
        canUseNatWebsession.value = false

        loading.value = false
        initialized.value = true
        getterHelpers.useStore().getters.getActiveModal().buttons[0].text = T("Close")
        getterHelpers.useStore().getters.getActiveModal().buttons.splice(1, 1)
    }
}

const checkReadyState = () => {
    if (startWebsessionEnabled.value) {
        if (
            getterHelpers.useStore().getters.getActiveModal() != undefined &&
            getterHelpers.useStore().getters.getActiveModal().id.indexOf("newUtmSession") != -1 &&
            loading.value === false
        ) {
            if (canUseNatWebsession.value === true) {
                getterHelpers.useStore().getters.getActiveModal().buttons[1].text =
                    T("Start New Websession")
                getterHelpers.useStore().getters.getActiveModal().buttons[1].loading = true
                getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled = true

                if (formdata.value.usePin && formdata.value.pin.length < 6) {
                    getterHelpers.useStore().getters.getActiveModal().buttons[1].loading = false
                    getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled = true
                } else if (formdata.value.usePin && !selectOptions.value.users?.length) {
                    getterHelpers.useStore().getters.getActiveModal().buttons[1].loading = false
                    getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled = true
                } else if (formdata.value.usePin && formdata.value.pin.length >= 6) {
                    getterHelpers.useStore().getters.getActiveModal().buttons[1].loading = false
                    getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled = false
                } else if (formdata.value.usePin === false) {
                    getterHelpers.useStore().getters.getActiveModal().buttons[1].loading = false
                    getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled = false
                }
            } else if (canUseNatWebsession.value === false) {
                if (formdata.value.address != "" && formdata.value.address != null) {
                    formdata.value.publicAddressSelected =
                        validationHelpers.isPublicIPv4(formdata.value.address) ||
                        validationHelpers.isPublicIpV6(formdata.value.address)
                    getterHelpers.useStore().getters.getActiveModal().buttons[1].text =
                        formdata.value.publicAddressSelected === false
                            ? T("Go to webinterface")
                            : T("Start new websession")
                }
                if (formdata.value.version != undefined && selectOptions.value.users.length > 0) {
                    if (url.value != "") {
                        getterHelpers.useStore().getters.getActiveModal().buttons[1].loading = false
                        getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled =
                            false
                    } else if (
                        formdata.value.portInitialized &&
                        formdata.value.address != null &&
                        formdata.value.address != ""
                    ) {
                        getterHelpers.useStore().getters.getActiveModal().buttons[1].loading = false
                        getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled =
                            false
                    }
                }
                if (formdata.value.usePin && formdata.value.pin.length < 6) {
                    getterHelpers.useStore().getters.getActiveModal().buttons[1].loading = false
                    getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled = true
                } else if (formdata.value.usePin && formdata.value.pin.length >= 6) {
                    getterHelpers.useStore().getters.getActiveModal().buttons[1].loading = false
                    getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled = false
                }
                const utm = config.canUseNewObjectType("uscUtms")
                    ? products.unifiedSecurityConsole.uscUtms
                          .useStore?.()
                          .getObjectStoreObject(activeAccountId.value, props.properties.utmId)
                    : objectStores.uscUtms.getObjectFromStore(
                          activeAccountId.value,
                          props.properties.utmId
                      )

                if (
                    utm &&
                    (config.canUseNewObjectType("uscUtms")
                        ? products.unifiedSecurityConsole.uscUtms
                        : objectStores.uscUtms
                    ).getUtmMessageData(utm as UscUtmNew, "spcli-system-info")?.version !=
                        undefined &&
                    validationHelpers.versionCompare(
                        "12.5.0",
                        (config.canUseNewObjectType("uscUtms")
                            ? products.unifiedSecurityConsole.uscUtms
                            : objectStores.uscUtms
                        ).getUtmMessageData(utm as UscUtmNew, "spcli-system-info")?.version
                    ) != 1
                ) {
                    if (formdata.value.address != "" && formdata.value.address != null) {
                        if (formdata.value.publicAddressSelected === false) {
                            getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled =
                                false
                            formdata.value.usePin = false
                            formdata.value.pin = ""
                        } else if (formdata.value.publicAddressSelected === true) {
                            formdata.value.usePin = true
                        }
                    }
                } else {
                    formdata.value.usePin = false
                }
            }
            instance?.proxy?.$forceUpdate()
        }
    } else if (getterHelpers.useStore().getters.getActiveModal().buttons[1]) {
        getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled = true
    }
}

const submit = async () => {
    if (loading.value == false) {
        loading.value = true
        errors.value = []

        getterHelpers.useStore().getters.getActiveModal().buttons[1].loading = true
        getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled = true
        formdata.value.isPinDisabled = true
        try {
            if (canUseNatWebsession.value) {
                let websessionPayload = {
                    //usePin: formdata.value.usePin,
                    admin: formdata.value.user,
                    pin: formdata.value.usePin ? formdata.value.pin : ""
                }

                let result = await requestHandler.request(
                    "POST",
                    config.mgtApiUriNext +
                        "/tenants/" +
                        tenantHelpers.getTenantDomain(props.properties.accountId) +
                        "/utms/" +
                        props.properties.utmId +
                        "/jobs/model",
                    {
                        name: "new-ws-sessionid",
                        data: websessionPayload
                    }
                )
                if (result?.errors?.error == true) {
                    throw result.errors.payload
                }
            } else if (formdata.value.publicAddressSelected) {
                let websessionPayload = {
                    targetIp: formdata.value.address,
                    targetPort: parseInt(formdata.value.port),
                    admin: formdata.value.user,
                    pin: <any>(formdata.value.usePin ? formdata.value.pin : "")
                }
                if (formdata.value.usePin == false) delete websessionPayload.pin

                let result = await requestHandler.request(
                    "POST",
                    config.mgtApiUriNext +
                        "/tenants/" +
                        tenantHelpers.getTenantDomain(props.properties.accountId) +
                        "/utms/" +
                        props.properties.utmId +
                        "/jobs/model",
                    {
                        name: "new-sessionid",
                        data: websessionPayload
                    }
                )
                if (result?.errors?.error == true) {
                    throw result.errors.payload
                }
            } else {
                window.open(
                    "https://" +
                        (validationHelpers.isIpV6(formdata.value.address)
                            ? "[" + formdata.value.address + "]"
                            : formdata.value.address) +
                        ":" +
                        formdata.value.port,
                    "_blank"
                )
                getterHelpers.useStore()?.commit(MutationTypes.removeModal, {
                    accountId: activeAccountId.value
                })
            }
        } catch (e: any) {
            errors.value = []
            errors.value.push(e)
            getterHelpers.useStore().getters.getActiveModal().buttons[1].loading = false
            getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled = false
        } finally {
        }
    }
}
const getPinStorageKey = () => {
    return `utm_pin:${props.properties.accountId}:${props.properties.utmId}`
}

onMounted(() => {
    getterHelpers.useStore().commit(MutationTypes.addSubscriptionHook, {
        accountId: activeAccountId.value,
        hookKey: "utmInterfaces",
        hookFunction: async (message: any) => {
            if (message.topic == "/usc/utm/" + objectId.value + "/message") {
                let jobType: string = message.data?.type || "spcli"
                let jobContext: string =
                    message.data?.clientContext
                        .replace(jobType + "-", "")
                        .split("-")
                        .join(" ") || "UNKNOWN"
                if (jobContext == "interface address get") {
                    let options: { id: string; text: string; address: string }[] = []
                    let data: any[] = message.data?.data || []

                    let publicAddressInfos = data.filter((entry: any) => {
                        let address =
                            entry?.address?.replaceAll("&#x2f;", "/").split("/")[0] || entry.address
                        return (
                            validationHelpers.isPublicIPv4(address) ||
                            validationHelpers.isPublicIpV6(address)
                        )
                    })

                    if (publicAddressInfos.length) {
                        options = publicAddressInfos.map((entry: any) => {
                            let address =
                                entry?.address?.replaceAll("&#x2f;", "/").split("/")[0] ||
                                entry.address
                            return {
                                id: address,
                                address: address,
                                type: "public",
                                text: `${address} (${entry.device}) [Public]`
                            }
                        })
                    }

                    options = options.concat(
                        data
                            .filter((entry: any) => {
                                let address =
                                    entry?.address?.replaceAll("&#x2f;", "/").split("/")[0] ||
                                    entry.address
                                return (
                                    validationHelpers.isPrivateIPv4(address) ||
                                    validationHelpers.isPrivateIpV6(address)
                                )
                            })
                            .map((entry: any) => {
                                let address =
                                    entry?.address?.replaceAll("&#x2f;", "/").split("/")[0] ||
                                    entry.address
                                return {
                                    id: address,
                                    address: address,
                                    type: "local",
                                    text: `${address} (${entry.device}) [Local]`
                                }
                            })
                    )

                    selectOptions.value.addresses = options
                    if (options.length) {
                        formdata.value.address = options[0].id
                    }
                    selectOptions.value.addressesInitialized = true
                    checkReadyState()
                }
                if (jobContext == "extc value get") {
                    let data: any[] = message.data?.data || []
                    if (data.length && data[0].name == "ADMIN_PORT") {
                        formdata.value.port = data[0].value
                        formdata.value.port = data[0].value
                        formdata.value.portInitialized = true
                    }
                    if (data.length && data[0].name == "SESSIONAUTH_ENABLE") {
                        formdata.value.usePin = data[0].value == "enabled"
                    }

                    checkReadyState()
                }
                if (jobContext == "system info") {
                    let data: any = message.data?.data || {}
                    if (data?.version != undefined) {
                        formdata.value.version = data.version
                        canUseNatWebsession.value =
                            validationHelpers.versionCompare(data.version, "12.5.1") != -1
                    }
                    checkReadyState()
                }
                if (
                    jobContext == "utm admin session" ||
                    jobContext == "new sessionid" ||
                    "new ws sessionid"
                ) {
                    if (message.data?.status == 200) {
                        if (message.data?.data.status == 500 || message.data?.data.status == 403) {
                            let jobStatusText: string =
                                message.data?.data?.message ||
                                message.data?.data?.hint?.message ||
                                message.data?.data?.[0]?.message ||
                                message.data?.data?.payload?.[0]?.message ||
                                message.data?.data?.error ||
                                ""
                            if (
                                jobStatusText.indexOf(
                                    "Websession address differs from request source:"
                                ) != -1
                            ) {
                                jobStatusText = jobStatusText.replace(
                                    "Websession address differs from request source:",
                                    T("Websession address differs from request source:")
                                )
                            }
                            errors.value = []
                            errors.value.push(jobStatusText)
                            loading.value = false
                            checkReadyState()
                        }
                    } else if (message.data?.status == 201) {
                        // connection url received
                        let messageUrl = JSON.parse(message.data?.data)?.url
                        if (messageUrl) {
                            url.value = messageUrl
                            window.open(url.value, "_blank")
                            getterHelpers.useStore().commit(MutationTypes.deleteSubscriptionHook, {
                                accountId: activeAccountId.value,
                                hookKey: "utmInterfaces"
                            })
                            getterHelpers.useStore()?.commit(MutationTypes.removeModal, {
                                accountId: activeAccountId.value
                            })
                        }

                        if (formdata.value.rememberPin) {
                            sessionStorage.setItem(getPinStorageKey(), formdata.value.pin)
                        }

                        loading.value = false
                        checkReadyState()
                    }
                }
                if (jobContext == "user admin get") {
                    let data: { name: string }[] = message.data?.data || []
                    if (data.length > 0) {
                        selectOptions.value.users = data.map((userInfo) => {
                            return {
                                id: userInfo.name,
                                text: userInfo.name
                            }
                        })
                        formdata.value.user = data[0].name
                    }
                    checkReadyState()
                }
            }
        }
    })
    init()
})

const pinFieldRef = useTemplateRef("pinFieldRef")

watch(startWebsessionEnabled, () => {
    if (startWebsessionEnabled.value == false) {
        formdata.value.pin = ""
    }
})

watch(
    formdata,
    (formdata) => {
        checkReadyState()
    },
    {
        deep: true
    }
)
watch(
    errors,
    (errorArray) => {
        if (errorArray.indexOf("Invalid pin") != -1) {
            formdata.value.pin = ""
            formdata.value.isPinDisabled = false
            setTimeout(() => {
                pinFieldRef.value?.focusInput?.()
            }, 500)
        }
    },
    {
        deep: true
    }
)

onBeforeUnmount(() => {
    websocketHandler.connect(activeAccountId.value)
    getterHelpers.useStore().commit(MutationTypes.deleteSubscriptionHook, {
        accountId: activeAccountId.value,
        hookKey: "utmInterfaces"
    })
})
defineExpose({
    submit
})
</script>
<template>
    <div>
        <template
            v-if="
                !initialized || canUseNatWebsession === undefined || formdata.usePin === undefined
            "
        >
            <div class="text-size-3 text-center padding-xs-t-4">
                <div class="text-size-2">
                    <Loader class="color-red"></Loader>
                </div>
            </div>
            <template v-if="loaderText">
                <div class="text-center padding-xs-t-2" style="opacity: 0.8">
                    <span>
                        {{ T(loaderText) }}
                    </span>
                </div>
            </template>
            <template v-if="showNotification == true">
                <div class="text-center color-red padding-xs-b-4" style="opacity: 0.8">
                    <br />
                    <span>
                        {{
                            T(
                                "Receiving the websocket messages takes unusually long. Please check your connection to the UTM."
                            )
                        }}
                    </span>
                </div>
            </template>
            <template v-else>
                <div class="padding-xs-b-4"></div>
            </template>
        </template>
        <template v-else-if="startWebsessionEnabled === false">
            <div class="padding-xs-2">
                <div class="row padding-xs-y">
                    <div class="col-xs-24">
                        <p>
                            <strong>{{ T("Websession ist nicht aktiviert") }}</strong>
                        </p>
                        <p
                            v-html="
                                T(
                                    `Dieses Feature ist auf dieser UTM nicht aktiviert. Wenn Sie die Websession für diese UTM nutzen wollen, aktualisieren Sie die USC-Rechte auf der UTM. Das Vorgehen finden Sie im Wiki unter $1 verwalten.`
                                ).replace(
                                    '$1',
                                    `<a href='https://wiki.securepoint.de/USC/UTM' target='_blank'>` +
                                        T('USC-Rechte') +
                                        `</a>`
                                )
                            "
                        ></p>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="padding-xs-2">
                <div
                    class="row padding-xs-y form-group"
                    v-if="
                        formdata.version &&
                        validationHelpers.versionCompare(formdata.version, '12.5.0') != 1
                    "
                >
                    <div class="col-xs-24">
                        <div class="alert notification content bg-red">
                            <i class="fal fa-fw fa-exclamation-triangle"></i>
                            {{
                                T(
                                    "From version 12.5.1, the UTM initiates a connection to our cloud services and can be administered via these without a public IP address. The older way for UTMs with version 12.5.0 or older will therefore be disabled from portal version 1.28 and the UTMs will no longer be accessible via websession. Please install the necessary update as soon as possible."
                                )
                            }}
                        </div>
                    </div>
                </div>

                <div class="row padding-xs-y form-group">
                    <div class="col-xs-24">
                        <p style="text-align: center">
                            <template v-if="canUseNatWebsession === true">
                                {{
                                    T(
                                        "The UTM initiates a connection to our cloud services and can be administered via these without a public IP address. Details can be found in our"
                                    ) + " "
                                }}<a
                                    href="https://wiki.securepoint.de/USC/UTM#Websession"
                                    target="_blank"
                                    >{{ T("wiki") }}</a
                                >.
                                <template v-if="formdata.usePin === false">
                                    <br /><br />{{
                                        T(
                                            "Since the websession PIN is deactivated, automatic login is not possible. You need to log in using your username and password."
                                        )
                                    }}
                                </template>
                            </template>
                            <template v-else>
                                {{
                                    T(
                                        "Here you can open the administrative web interface of the UTM. If the UTM is accessible via internet connection, no user credentials are required."
                                    )
                                }}<br /><br />
                                {{
                                    T(
                                        "If the UTM is not accessible via internet connection, a link to the local administration web interface is made available. To sign in without the help of USC, you need user credentials with administrative rights for the UTM, your IP must be registered as manager IP on the UTM and you need a direct connection to the UTM."
                                    )
                                }}
                            </template>
                        </p>
                    </div>
                </div>
                <div class="text-center">
                    <template v-for="error in errors">
                        <p
                            class="label bg-red"
                            v-if="['locked', 'Invalid pin'].indexOf(error) == -1"
                        >
                            <i class="fal fa-fw fa-exclamation"></i> {{ T(error) }}
                        </p>
                    </template>
                </div>

                <div
                    class="padding-xs-y form-group border-bottom border-top text-center"
                    v-if="!canUseNatWebsession"
                >
                    <label for="addresses" class="control-label">
                        {{ T("IP address") }}
                    </label>

                    <div class="input">
                        <label class="form-field" v-if="selectOptions.addressesInitialized">
                            <input-vue-select
                                id="addresses"
                                :selectOptions="selectOptions.addresses"
                                v-model="formdata.address"
                            />
                        </label>
                        <Loader v-else class="color-red" />
                    </div>
                </div>

                <div
                    class="padding-xs-y form-group border-bottom text-center"
                    v-if="formdata.usePin === true && hasOnlyAdmin == false"
                >
                    <label for="user" class="control-label">
                        {{ T("User") }}
                    </label>
                    <div class="input">
                        <label class="form-field" v-if="selectOptions.users.length > 0">
                            <input-vue-select
                                id="user"
                                :selectOptions="selectOptions.users"
                                :disabled="loading ? true : false"
                                v-model="formdata.user"
                            />
                        </label>
                        <Loader v-else class="color-red" />
                    </div>
                    <div class="desc">
                        <p class="input-description">
                            {{
                                T(
                                    "Please select the user with whom the websession should be started."
                                )
                            }}
                        </p>
                    </div>
                </div>

                <div class="row padding-xs-y form-group" v-if="formdata.usePin === true">
                    <div class="input col-xs-24 text-center">
                        <label
                            class="form-field"
                            style="display: inline-block"
                            v-if="formdata.usePin !== undefined"
                        >
                            <inputPin
                                ref="pinFieldRef"
                                v-model="formdata.pin"
                                :isDisabled="formdata.isPinDisabled === true"
                                :pinLength="6"
                                :numbersOnly="true"
                                v-on:submit="submit"
                                :focusOnMount="true"
                                :hidePinOnMount="true"
                                :canToggleVisibility="true"
                            />
                            <span></span>
                        </label>
                        <Loader v-else class="color-red" />
                    </div>
                    <div class="desc col-xs-24 text-center">
                        <p class="input-description">
                            {{
                                T(
                                    "This UTM requires a PIN for authentication via the websession function."
                                )
                            }}
                            <br />
                            {{
                                T(
                                    "If the PIN is entered incorrectly several times (default value 5, unless otherwise configured on the UTM), login is only possible via user name and password until the PIN functionality is reactivated."
                                )
                            }}
                            <br />
                            {{
                                T(
                                    "This authentication is mandatory from UTM version 12.5.0. The initial PIN is 000000 and must be changed after the first login."
                                )
                            }}
                            <br />
                        </p>
                        <label v-if="formdata.showRememberPin" class="form-field toggle checkbox">
                            <input
                                v-model="formdata.rememberPin"
                                id="login-rememberme"
                                type="checkbox"
                                class="toggle margin-xs-0"
                            />&nbsp;&nbsp;
                            <span></span>
                            {{ T("Remember PIN for the current session") }}
                        </label>
                    </div>
                </div>

                <template v-if="errors.indexOf('Invalid pin') != -1">
                    <div class="text-center">
                        <p class="label bg-red">
                            {{ T("The PIN you have entered is incorrect") }}
                        </p>
                    </div>
                </template>
                <template v-if="errors.indexOf('locked') != -1">
                    <template v-if="errors.indexOf('Invalid pin') != -1"> <br /><br /> </template>
                    <div class="text-center">
                        <p class="label bg-red">
                            {{
                                T(
                                    "The USC websession has been blocked on this UTM due to multiple entries of an incorrect PIN. To unlock the UTM for the USC webssession again, please log in to the administrator interface."
                                )
                            }}
                        </p>
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>
