<script setup lang="ts">
import { T } from "@/classes/i18n"
import type { Label } from "@/classes/objectTypes/objectType"
import mixinHelpers from "@/helpers/helpers.mixins"
import { computed, onMounted, ref } from "vue"
import { VueDraggableNext } from "vue-draggable-next"
import Tooltip from "../directives/tooltip/tooltip"
import inputText from "../inputtypes/input-text.vue"
import Button from "./button/button"
import buttonComponent from "./button/button.vue"
import NewIconComponent from "./icon/newIconComponent.vue"
import LabelComponent from "./label/label.vue"
import Loader from "./loader.vue"
import scrollToTopButton from "./scrollToTopButton.vue"

export interface TabsEntry {
    icon?: string
    text?: string
    disabled?: boolean | (() => boolean)
    visible?: boolean | (() => boolean)
    loading?: boolean
    tooltip?: Tooltip
    id: string
    onClick?: Function
    labels?: Label[]
    hasError?: boolean
    hasChanges?: boolean
    showHeaderInContent?: boolean
    width?: number
    buttonClass?: string
    renamable?: {
        onRename: (newName: string, tab: TabsEntry) => void
    }
}
// Props
const props = withDefaults(
    defineProps<{
        tabs: TabsEntry[]
        buttonsBefore?: Button[]
        buttonsAfter?: Button[]
        menuType?: "vertical" | "horizontal"
        activeTab?: string
        disableContentBox?: boolean
        onReorder?: (tabs: TabsEntry[]) => void
        setActiveTab?: (tabId: string) => void
    }>(),
    {
        tabs: () => {
            return []
        },
        menuType: "horizontal"
    }
)
const darkmode = computed(() => {
    return mixinHelpers.getDarkmode()
})
const activeTab = ref(<string | undefined>undefined)
const computedActiveTab = computed(() => {
    return props.activeTab || activeTab.value
})

const setActiveTab = (tabname: string) => {
    props.setActiveTab?.(tabname)
    activeTab.value = tabname
}
const container = ref(<HTMLElement | undefined>undefined)

// RENAMING
// list of ids
const renamingTab = ref(<TabsEntry["id"]>"")
const renamingTabName = ref("")
const renameTab = (tab: TabsEntry) => {
    renamingTab.value = tab.id
    renamingTabName.value = tab.text || ""
}

const abortRename = (tab: TabsEntry) => {
    renamingTabName.value = ""
    renamingTab.value = ""
}
const doRename = async (tab: TabsEntry) => {
    if (tab && tab.text) {
        try {
            await tab.renamable?.onRename(tab.text, tab)
        } catch (e) {
            console.error(e)
        }
    }
    renamingTab.value = ""
}

onMounted(() => {
    if (props.tabs?.length) {
        activeTab.value = props.tabs[0].id
    }
})

defineExpose({
    setActiveTab
})
</script>
<template>
    <div class="tabs" :class="{ 'menu-vertical': props.menuType == 'vertical' }">
        <nav>
            <template v-if="props.buttonsBefore">
                <div class="buttonsBefore">
                    <template v-for="button in props.buttonsBefore">
                        <buttonComponent :button-options="button"></buttonComponent>
                    </template>
                </div>
            </template>
            <VueDraggableNext
                :list="tabs"
                class="list-group nav-tabs"
                handle=".handle"
                tag="ul"
                ghost-class="ghost"
                :sort="props.onReorder != undefined"
                @change="
                    (e: any) => {
                        console.log(e)
                        props.onReorder?.(tabs)
                    }
                "
            >
                <template v-for="tab in tabs" :key="tab.id">
                    <li
                        :style="'width:' + (tab.width ? tab.width + 'px' : 'auto')"
                        :id="'id-' + tab.id"
                        v-tooltip="tab.tooltip || undefined"
                    >
                        <div class="handle" v-if="props.onReorder != undefined">
                            <NewIconComponent
                                :icon-options="{
                                    iconName: 'grip-dots-vertical',
                                    iconType: 'fontawesomeLight'
                                }"
                            ></NewIconComponent>
                        </div>
                        <template v-if="tab.text && renamingTab == tab.id">
                            <div class="renameWrapper">
                                <input-text
                                    v-model="tab.text"
                                    v-on:keydown.enter="doRename(tab)"
                                ></input-text>
                                <a
                                    v-on:click.stop="doRename(tab)"
                                    class="btn padding-xs-y-0"
                                    :class="
                                        darkmode == '1'
                                            ? 'btn-darkbluegrey'
                                            : 'btn-white color-gray'
                                    "
                                >
                                    <i class="fal fa-save"></i>
                                </a>
                                <a
                                    v-on:click.stop="abortRename(tab)"
                                    class="btn padding-xs-y-0"
                                    :class="
                                        darkmode == '1'
                                            ? 'btn-darkbluegrey'
                                            : 'btn-white color-gray'
                                    "
                                >
                                    <i class="fal fa-times"></i>
                                </a>
                            </div>
                        </template>
                        <template v-else>
                            <!-- prettier-ignore -->
                            <a
                            v-if="
                                typeof tab.visible == 'function'
                                    ? tab.visible() !== false
                                    : tab.visible !== false
                            "
                            
                            :class="[{
                                active: computedActiveTab == tab.id,
                                disabled:
                                    (typeof tab.disabled == 'function'
                                        ? tab.disabled()
                                        : tab.disabled === true) || tab.loading === true
                            },tab.buttonClass]"
                            v-on:click="
                                activeTab = tab.id;
                                tab.onClick ? tab.onClick() : null
                            "
                        >
                            <template v-if="tab.loading">
                                <span
                                    style="width: 1.25em; display: inline-block"
                                    class="text-center"
                                >
                                    <Loader class="color-red" style="top: 0.1em"></Loader>
                                </span>
                            </template>
                            
                            <span class="buttonContent" v-else>
                                
                                <template v-if="tab.icon">
                                    <i :class="tab.icon" class="fa-fw"></i>
                                </template>
                                
                                {{ tab.text }}

                                <template v-if="tab.hasError == true">
                                    <i class="error fal fa-exclamation-triangle color-red text-bold"></i>
                                </template>
                                <template v-else-if="tab.hasChanges == true">
                                    <i class="changes fal fa-circle color-yellow text-bold"></i>
                                </template>
                            </span>
                            <template v-if="tab.renamable">
                                <a class="renameButton" v-on:click.stop="renameTab(tab)" :title="T('Rename')">
                                    <i class="fal fa-edit"></i>
                                </a>
                            </template>

                        </a>
                        </template>
                    </li>
                </template>
            </VueDraggableNext>
            <template v-if="props.buttonsAfter">
                <div class="buttonsAfter">
                    <template v-for="button in props.buttonsAfter">
                        <buttonComponent :button-options="button"></buttonComponent>
                    </template>
                </div>
            </template>
        </nav>
        <div
            class="tab-content"
            :class="{ 'col-xs': props.menuType == 'vertical' }"
            ref="container"
        >
            <template v-for="tab in tabs">
                <div
                    class="tab-pane"
                    :class="{ hidden: computedActiveTab != tab.id }"
                    style="position: relative"
                >
                    <div
                        :class="{
                            'box-content-4': props.menuType == 'vertical',
                            'box-content-2': !props.disableContentBox
                        }"
                    >
                        <template v-if="tab.showHeaderInContent != false">
                            <h3 class="margin-xs-b-3">
                                <i :class="tab.icon" class="fa-fw" v-if="tab.icon"></i>
                                {{ T(tab.text) }}

                                <label-component
                                    class="text-small"
                                    v-for="label in tab.labels"
                                    :label="label"
                                />
                            </h3>
                            <hr class="margin-xs-y" />
                        </template>
                        <slot></slot>
                        <slot :name="tab.id"></slot>
                    </div>
                </div>
            </template>
            <scrollToTopButton :container-el="container"></scrollToTopButton>
        </div>
    </div>
</template>
<style lang="scss">
.tabs {
    nav {
        display: flex;
        align-items: center;
        .buttonsBefore {
            padding: 0 3px;
        }
        .buttonsAfter {
            padding: 0 3px;
        }
        .nav-tabs,
        > ul {
            li {
                position: relative;

                a {
                    display: flex;
                    gap: 10px;
                    &.active {
                        i:not(.changes) {
                            color: #e52b2b;
                        }
                        .renameButton {
                            i {
                                margin: 0;
                                color: rgba(0, 0, 0, 0.3);
                            }
                        }
                    }
                    &:hover {
                        color: rgba(0, 0, 0, 0.7);
                    }
                    &.disabled {
                        color: rgba(0, 0, 0, 0.2);
                    }
                    .buttonContent {
                        flex-grow: 1;
                    }
                    .renameButton {
                        flex-shrink: 1;
                        border: 0px solid rgba(0, 0, 0, 0.1) !important;
                        padding: 0;
                        width: auto;
                        display: inline;
                        i {
                            margin: 0;
                            color: rgba(0, 0, 0, 0.3);
                        }
                        &:hover {
                            i {
                                color: #e52b2b;
                            }
                        }
                    }
                }
                .renameWrapper {
                    display: flex;
                    input {
                        padding: 12px 0 12px 12px;
                    }
                    a {
                        padding: 12px 6px;
                        border: none;
                        &:hover {
                            color: #e52b2b;
                        }
                        i {
                            margin: 0;
                        }
                    }
                }

                .handle {
                    position: absolute;
                    top: 50%;
                    left: 11px;
                    transform: translate(0%, -50%);
                    z-index: 2;
                    opacity: 0.3;
                    cursor: grab;
                    &:hover {
                        opacity: 1;
                    }
                }
                .handle + a,
                .handle + .renameWrapper {
                    padding-left: 26px;
                }
            }
        }
    }
}

.tabs {
    &.menu-vertical {
        display: flex;
        nav {
            min-height: calc(100vh - 64px - 150px - 64px - 68px - 32px - 48px);
            max-height: calc(100vh - 64px - 150px - 64px - 68px - 32px - 48px);
            border-bottom: 0;
            min-width: fit-content;
            width: fit-content;
            overflow-y: auto;
            overflow-x: hidden;
            .nav-tabs {
                width: fit-content;
                height: 100%;
                li {
                    display: block;
                    a {
                        margin-bottom: 0;
                        border-right: 2px solid rgba(0, 0, 0, 0.08);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                        &.active {
                            border-right: 2px solid #e52b2b;
                            color: rgba(0, 0, 0, 0.7);
                        }
                    }
                }
            }
        }
        .tab-content {
            min-height: calc(100vh - 64px - 150px - 64px - 68px - 32px - 48px);
            max-height: calc(100vh - 64px - 150px - 64px - 68px - 32px - 48px);
            overflow-y: auto;
            overflow-x: hidden;
            position: relative;
        }
        &.noMaxHeight {
            nav {
                max-height: calc(100vh);
            }
            .tab-content {
                max-height: calc(100vh);
            }
        }
    }
}

.darkmode {
    .tabs {
        nav {
            .nav-tabs {
                li {
                    a {
                        &:hover,
                        &.active {
                            color: rgba(255, 255, 255, 0.7);
                        }
                        &.disabled {
                            color: rgba(255, 255, 255, 0.2);
                        }
                        .renameButton {
                            i {
                                margin: 0;
                                color: rgba(255, 255, 255, 0.3);
                            }
                            &:hover {
                                i {
                                    color: #e52b2b;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
