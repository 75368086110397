<script setup lang="ts">
import { T } from "@/classes/i18n"
import getterHelpers from "@/helpers/helpers.getters"
import darkImage from "@/img/vpn_landingpage_dark.svg"
import lightImgae from "@/img/vpn_landingpage_light.svg"
import router from "@/router/router"
import { useStore } from "@/store/vuex.store"
import { computed, ref } from "vue"
import Button from "../button/button"
import buttonComponent from "../button/button.vue"

const darkmode = computed(() => {
    return getterHelpers.useStore().state.browserSettings.darkmode == "1"
})

const accountId = computed(() => {
    return useStore().state.session.activeAccountId || ""
})

const addProfileButton = ref(
    new Button({
        icon: "fa-plus",
        text: T("Windows Profil erstellen"),
        title: T("Windows Profil erstellen"),
        onClick: () => {
            router.navigate(`#add-tenant-${accountId.value}.sms-windows-profiles`)
        }
    })
)
</script>

<template>
    <div class="row flexrow">
        <div class="col-xs-24 col-lg-16">
            <div class="box-shadow padding-xs-2" style="height: 100%">
                <h4 class="margin-xs-b-2">
                    {{ T("Windows Profile") }}
                </h4>

                <p>
                    {{
                        T(
                            "Die Zuweisung von vorkonfigurierten Profilen zu Windows VPN Clients ermöglicht eine schnelle und einfache Konfiguration Ihrer Clients. Durch die Zuweisung dieser Profile zu einem Roadwarrior-Pool in der VPN-Konfiguration können Sie Ihre Windows-Geräte einfach mit einer Unternehmens-UTM verbinden."
                        )
                    }}
                </p>

                <p class="text-bold margin-xs-t-3">
                    {{ T("Wichtig:") }}
                </p>
                <p>
                    {{
                        T(
                            "Nutzen Sie für Ihre Windows-Geräte die neueste Version des Securepoint VPN Clients (>= 3.0.0)."
                        )
                    }}
                </p>

                <p class="text-bold margin-xs-t-3">{{ T("Erste Schritte:") }}</p>
                <ol class="margin-xs-b-3">
                    <li>
                        {{
                            T(
                                "Erstellen Sie ein Windows Profil mit den gewünschten Einstellungen und Berechtigungen"
                            )
                        }}
                    </li>
                    <li>
                        {{
                            T(
                                "Ordnen Sie Windows VPN Clients direkt oder über die Vergabe eines Tags zu"
                            )
                        }}
                    </li>
                    <li>
                        {{
                            T(
                                "Sobald das Profil gespeichert wurde, werden die Profileinstellungen automatisch an bereits registrierte Windows VPN-Clients übertragen. Für zukünftige Clients erfolgt die Übertragung der Profileinstellungen, sobald sie sich im Portal anmelden"
                            )
                        }}
                    </li>
                </ol>

                <p>
                    {{ T("Weitere Informationen finden Sie in unserem Wiki unter") }}
                    <a href="https://wiki.securepoint.de/VPN" target="_blank">{{
                        T("Windows VPN")
                    }}</a
                    >.
                </p>

                <p class="margin-xs-t-4 text-center" style="font-size: 1.15em">
                    <buttonComponent :button-options="addProfileButton" />
                </p>
            </div>
        </div>
        <div class="col-xs-24 col-lg-8">
            <div class="box-shadow padding-xs-2 text-center" style="height: 100%">
                <img
                    :src="darkmode ? darkImage : lightImgae"
                    style="
                        display: inline;
                        max-width: 400px;
                        width: 100%;
                        filter: grayscale(1);
                        opacity: 0.7;
                    "
                />
            </div>
        </div>
    </div>
</template>
