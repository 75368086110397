import ObjectType, { type ObjectTypePayload } from "../objectType"

export interface UscUtmState {
    deviceId: string
    lastContact: number
    online: boolean
    multipleConnects?: boolean
}

class UscUtmStates extends ObjectType<UscUtmState> {
    constructor(payload: ObjectTypePayload<UscUtmState>) {
        super(payload)
    }
}

const uscUtmStates = new UscUtmStates({
    productType: "unifiedSecurityConsole",
    slug: "uscUtmStates",
    objectType: "uscUtmStates",
    hasStore: true,
    appearance: {
        iconClass: "fal fa-question",
        text: {
            plural: "States",
            singular: "State",
            title: "States",
            sidebarName: "States"
        },
        color: "red",
        showInSidebar: false,
        showOnDashboard: false
    },
    objectTypeInfo: {
        primaryKeyProperty: {
            property: "deviceId"
        },
        nameProperty: {
            primary: "deviceId"
        }
    },
    apiInfo: {
        url: "/sms-mgt-api/api/2.0",
        getCountGETProperties: "?select=data.count",
        // GET
        getObjectListResponseProperty: "",
        getObjectListMethod: "GET",
        getObjectListPath: "/tenants/{tenantDomain}/utms/state"
    }
})

export default uscUtmStates
