import apis from "@/classes/apis"
import config from "@/classes/config"
import { T } from "@/classes/i18n"
import devLog from "@/classes/log"
import deviceHelpers from "@/helpers/helpers.devices"
import encodingHelpers from "@/helpers/helpers.encoding"
import getterHelpers from "@/helpers/helpers.getters"
import jsonHelpers from "@/helpers/helpers.json"
import jsonFormHelpers from "@/helpers/helpers.jsonForms"
import licenseHelpers from "@/helpers/helpers.license"
import numberHelpers from "@/helpers/helpers.numbers"
import stringHelpers from "@/helpers/helpers.strings"
import tenantHelpers from "@/helpers/helpers.tenants"
import requestHandler from "@/queries/requests"
import router from "@/router/router"
import { ActionTypes, MutationTypes, useStore } from "@/store/vuex.store"
import Button from "@/templates/components/button/button"
import download from "downloadjs"
import products from "../.."
import ObjectType, {
    type AccountId,
    type AddObjectTypeObject,
    type ItemlistItemDetail,
    type ObjectTypePayload
} from "../../objectType"
import type { SunRoadwarriorEntity } from "../../unifiedSecurityConsole/topologies"
import { useAndroidPublishStore } from "./androidProfilePublish"
import type { EnterpriseDevice } from "./enterpriseDevices"

class AndroidProfiles extends ObjectType<AndroidProfile> {
    constructor(payload: ObjectTypePayload<AndroidProfile>) {
        super(payload)

        this.itemlist.getSortingOptions = () => {
            return [
                {
                    id: "profileId",
                    text: T("Name")
                }
            ]
        }
        this.itemlist.getInfoBoxContent = (accountId: string, itemlistComponent: any) => {
            let result = ""

            if (
                useAndroidPublishStore().isPublishing == true &&
                useAndroidPublishStore().publishState?.templatePolicyName
            ) {
                result =
                    `<i class="fal fa-info-circle"></i> ` +
                    T(
                        "Aktuell werden Profile für %s generiert und konvertiert. Für diesen Zeitraum kann man keine Template Policies hinzufügen, löschen oder bearbeiten."
                    ).replace(
                        "%s",
                        useAndroidPublishStore().publishState.templatePolicyName.split("/").pop() ??
                            ""
                    )
            }

            return result
        }
        this.itemlist.getToolbarButtons = (accountId, itemlistComponent) => {
            itemlistComponent = itemlistComponent?.exposed
                ? itemlistComponent?.exposed
                : itemlistComponent
            let result = <any>[]
            if (licenseHelpers.hasOneOfLicenses(accountId, ["Mobile Security", "MDM"], "valid")) {
                result.push({
                    icon: "fal fa-plus",
                    title: T("Add profile"),
                    link:
                        "#add-tenant-" +
                        tenantHelpers.getTenantDomain(accountId) +
                        "-android-profile",
                    id: "profilesButtonAdd",
                    disabled: !tenantHelpers.hasFunctionality(accountId, "enterprise"),
                    vIf: false
                })
            }

            return result
        }

        this.itemlistItem.getLabels = (accountId, item) => {
            let thisLabels = []

            if (item?.type != undefined) {
                if (item?.type == "autogenerated policy") {
                    thisLabels.push({
                        title: T("Generated"),
                        text: T("Generated"),
                        class: "",
                        icon: "fa fa-cog"
                    })
                } else if (item?.type == "template policy") {
                    thisLabels.push({
                        title: T("Template"),
                        text: T("Template"),
                        class: "",
                        icon: "fa fa-file-export"
                    })
                }
            }

            if (licenseHelpers.hasLicense(accountId, "MDM") || item?.applications) {
                let hasSecurity: boolean = item?.applications
                    ? item.applications.filter(function (application) {
                          return application.packageName == "de.securepoint.ms.agent"
                      }).length > 0
                    : false
                if (hasSecurity) {
                    let policyName: any = item?.profileId
                    let devices: any =
                        useStore().getters.getObjectTypeStore({
                            accountId: accountId,
                            objectType: "devices"
                        })?.items || []
                    let hasNonSecurityDevicesInPolicy: boolean =
                        devices.filter(function (device: any) {
                            // ONLY DEVICES WITH THIS POLICY AND WITHOUT MOBSEC LICENSE
                            if (
                                jsonHelpers.getObjectProperty(device, "profile.profileName") ==
                                policyName
                            ) {
                                return (
                                    jsonHelpers.getObjectProperty(
                                        device,
                                        "licenseInformation.type"
                                    ) != "Mobile Security"
                                )
                            }
                        }).length > 0

                    if (hasNonSecurityDevicesInPolicy) {
                        thisLabels.push({
                            title: T("License conflict"),
                            text: T("License conflict"),
                            class: "bg-red"
                        })
                    }
                }
            }
            return thisLabels
        }

        this.itemlistItem.hasCheckbox = (item) => {
            let result = true
            if (item?.type == "autogenerated policy") result = false
            return result
        }

        this.itemlistItem.getTitle = (item, component) => {
            let result = {
                title: ""
            }
            if (item?.type == "autogenerated policy") {
                result.title = item?.profileId.replace(/\^(.*)/, " ($1)")
            } else {
                result.title = item?.profileId
            }
            return result
        }
        this.itemlistItem.isClickable = (accountId, item) => {
            if (
                (!licenseHelpers.hasOneOfLicenses(accountId, ["Mobile Security", "MDM"], "valid") ||
                    jsonHelpers.getObjectProperty(item, "type") == "autogenerated policy" ||
                    jsonHelpers.getObjectProperty(item, "profilename") == "unlicensed") &&
                !(useAndroidPublishStore().isPublishing == true && item?.type == "template policy")
            ) {
                return false
            }

            return true
        }
        this.itemlistItem.getDisabledState = (accountId, item) => {
            return (
                !tenantHelpers.hasFunctionality(accountId, "dep") &&
                item?.hasOwnProperty("iOSPerUserChannelUsers")
            )
        }
        this.itemlistItem.onClick = (accountId, item) => {
            if (
                jsonHelpers.getObjectProperty(item, "profilename") != "unlicensed" &&
                item?.profileId &&
                (useAndroidPublishStore().isPublishing != true ||
                    (useAndroidPublishStore().isPublishing == true &&
                        item.type != "template policy"))
            ) {
                router.navigate(
                    "#edit-tenant-" + accountId + ".sms-android-profile-" + item?.profileId
                )
            }
        }
        this.itemlistItem.getMenuEntries = (accountId, item, component) => {
            component = component?.exposed ? component?.exposed : component
            let profileName = item.profileId
            if (item.type == "autogenerated policy") {
                profileName = item.profileId.replace(/\^(.*)/, " ($1)")
            } else {
                profileName = item.profileId
            }

            let menuLinks = []
            if (
                licenseHelpers.hasOneOfLicenses(accountId, ["Mobile Security", "MDM"], "valid") &&
                (useAndroidPublishStore().isPublishing != true ||
                    (useAndroidPublishStore().isPublishing == true &&
                        item?.type != "template policy"))
            ) {
                if (item.profileId != "unlicensed") {
                    if (item.type == "autogenerated policy") {
                        menuLinks.push(
                            new Button({
                                id: "edittemplate",
                                title: T("Edit Template"),
                                text: T("Edit Template"),
                                link:
                                    "#edit-tenant-" +
                                    tenantHelpers.getTenantDomain(accountId) +
                                    "-android-profile-" +
                                    item?.profileId.slice(0, item?.profileId.indexOf("^")),
                                icon: "fal fa-edit",
                                disabled: useAndroidPublishStore().isPublishing == true
                            })
                        )
                    } else {
                        menuLinks.push(
                            new Button({
                                id: "edit",
                                title: T("Edit"),
                                text: T("Edit"),
                                link:
                                    "#edit-tenant-" +
                                    tenantHelpers.getTenantDomain(accountId) +
                                    "-android-profile-" +
                                    item?.profileId,
                                icon: "fal fa-edit"
                            })
                        )
                    }
                    if (item.type != "autogenerated policy") {
                        menuLinks.push(
                            new Button({
                                id: "copy",
                                title: T("Copy"),
                                text: T("Copy"),
                                onClick: () => {
                                    this.saveToClipBoard(accountId, item)
                                },
                                icon: "fal fa-clone"
                            })
                        )
                    }

                    if (item.type != "autogenerated policy") {
                        menuLinks.push(
                            new Button({
                                id: "export",
                                title: T("Export"),
                                text: T("Export"),
                                onClick: () => {
                                    this.exportObject(item)
                                },
                                icon: "fal fa-download"
                            })
                        )
                    }
                }
                if (item?.devices?.length == 0) {
                    menuLinks.push(
                        new Button({
                            id: "delete",
                            title: T("Delete"),
                            text: T("Delete"),
                            onClick: () => {
                                this.dialogs.getDeleteObjectDialog(accountId, item)
                            },
                            icon: "fal fa-trash"
                        })
                    )
                }
            } else if (
                item?.devices?.length == 0 &&
                (useAndroidPublishStore().isPublishing != true ||
                    (useAndroidPublishStore().isPublishing == true &&
                        item?.type != "template policy"))
            ) {
                let profileName = item.profileId
                if (item.type == "autogenerated policy") {
                    profileName = item.profileId.replace(/\^(.*)/, " ($1)")
                } else {
                    profileName = item.profileId
                }
                menuLinks.push(
                    new Button({
                        title: T("Delete"),
                        text: T("Delete"),
                        onClick: () => {
                            this.dialogs.getDeleteObjectDialog(accountId, item)
                        },
                        icon: "fal fa-trash"
                    })
                )
            }
            return menuLinks
        }

        this.itemlistItem.getDetails = (accountId, item, component) => {
            let thisDetails: ItemlistItemDetail[] = []
            let thisPartsArray: any[] = []
            let thisDevicesArray: any[] = []

            const androidDevices = (products.mobileSecurity.androidDevices
                .useStore?.()
                .getObjectStoreObjects(accountId) || []) as EnterpriseDevice[]
            const thisDevicesInfo = androidDevices.filter((device) => {
                let devicePolicyName: string = ""
                if (device.policyName != undefined) {
                    devicePolicyName = device.policyName.split("/")[3]
                } else if (device.appliedPolicyName != undefined) {
                    devicePolicyName = device.appliedPolicyName.split("/")[3]
                }
                if (devicePolicyName == item?.profileId) {
                    return true
                }
            })

            if (thisDevicesInfo.length >= 5) {
                thisDevicesArray = [
                    {
                        id: "none",
                        text: thisDevicesInfo.length + " " + T("Devices"),
                        title: thisDevicesInfo
                            .map((thisDevice: any) => {
                                return deviceHelpers.getAliasedShortDeviceId(
                                    thisDevice?.deviceId || thisDevice?.id,
                                    thisDevice?.alias || thisDevice?.info?.deviceName || undefined,
                                    false
                                )
                            })
                            .join(", "),
                        onClick: undefined,
                        displayType: "label"
                    }
                ]
            } else {
                thisDevicesArray = thisDevicesInfo?.map((thisDevice: any) => {
                    return {
                        id: thisDevice.deviceId,
                        text: deviceHelpers.getAliasedShortDeviceId(
                            thisDevice?.deviceId || thisDevice?.id,
                            thisDevice?.alias || thisDevice?.info?.deviceName || undefined,
                            false
                        ),
                        title: deviceHelpers.getAliasedShortDeviceId(
                            thisDevice?.deviceId || thisDevice?.id,
                            thisDevice?.alias || thisDevice?.info?.deviceName || undefined,
                            false
                        ),
                        onClick: function () {
                            router.navigate(
                                "#show-tenant-" +
                                    accountId +
                                    "-android-devices-" +
                                    thisDevice.deviceId
                            )
                        },
                        displayType: "label"
                    }
                })
            }

            if (this.getProfileParts(item).length >= 5) {
                thisPartsArray = [
                    {
                        id: "mulipleParts",
                        text: this.getProfileParts(item).length + " " + T("Parts"),
                        title: this.getProfileParts(item).join(", "),
                        displayType: "label"
                    }
                ]
            } else {
                thisPartsArray = this.getProfileParts(item).map((part: string) => {
                    return {
                        id: item.profileId + "_" + part,
                        text: part,
                        title: part,
                        displayType: "label"
                    }
                })
            }

            thisDetails.push(
                {
                    iconClass: "fal fa-fw fa-mobile-alt",
                    title: T("Devices"),
                    key: T("Devices"),
                    [item ? "labels" : "value"]: item
                        ? thisDevicesArray
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fas-fw fa-puzzle-piece",
                    title: T("Parts"),
                    key: T("Parts"),
                    [item ? "labels" : "value"]: item
                        ? thisPartsArray
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                }
            )
            return thisDetails
        }

        this.queries.addObjectToApi = async (
            accountId,
            object,
            customerId?,
            updateStore = true
        ) => {
            let result: AndroidProfile | Error
            try {
                let devices: any = []
                if (object.devices) {
                    devices = JSON.parse(JSON.stringify(object.devices))
                }
                let policyName: string = object.name.trimStart().trim()
                object.name = policyName
                delete object.devices
                let response = await requestHandler.request(
                    this.options.apiInfo.addObjectMethod,
                    this.getAddObjectApiUrlPath(accountId, customerId).replace(
                        "{objectId}",
                        encodingHelpers.encodeURI(policyName)
                    ),
                    object
                )

                for (let i: number = 0; devices.length > i; i++) {
                    try {
                        const thisDevice = (await products.mobileSecurity.androidDevices
                            .useStore?.()
                            .getObjectStoreObject(accountId, devices[i])) as EnterpriseDevice
                        let fullPolicyName: string | string[] =
                            thisDevice.policyName?.split("/") || []
                        fullPolicyName =
                            fullPolicyName[0] +
                            "/" +
                            fullPolicyName[1] +
                            "/" +
                            fullPolicyName[2] +
                            "/" +
                            policyName
                        await products.mobileSecurity.androidDevices.queries.updateObjectPropertiesFromApi(
                            accountId,
                            devices[i],
                            { policyName: fullPolicyName },
                            "username",
                            undefined,
                            undefined,
                            "?updateMask[]=policyName"
                        )
                    } catch (e: any) {
                        console.error(e)
                    }
                }

                if (!jsonHelpers.isObjectEmpty(response)) {
                    result = object
                    if (response[this.options.objectTypeInfo.primaryKeyProperty.property]) {
                        object[this.options.objectTypeInfo.primaryKeyProperty.property] =
                            response[this.options.objectTypeInfo.primaryKeyProperty.property]
                    } else if (typeof response == "string") {
                        object[this.options.objectTypeInfo.primaryKeyProperty.property] = response
                    }
                } else {
                    throw "Error getting objects"
                }
                if (updateStore) {
                    this.useStore?.().addObjectTypeObject(accountId, object)
                }
                return result
            } catch (e: any) {
                devLog.log("ObjectType", e.message, e, "error")
                throw e as Error
            }
        }

        this.queries.updateObjectFromApi = async (
            accountId,
            objectId,
            object,
            customerId,
            updateStore = true
        ) => {
            let result: AndroidProfile | Error
            try {
                let devices: any = []
                if (object.devices) {
                    devices = JSON.parse(JSON.stringify(object.devices))
                }
                let policyName: string | null = object.name?.trim() || null
                delete object.devices
                let response = await requestHandler.request(
                    this.options.apiInfo.updateObjectMethod,
                    this.getUpdateObjectApiUrlPath(accountId, objectId, customerId),
                    object
                )
                for (let i: number = 0; devices.length > i; i++) {
                    try {
                        const thisDevice = (await products.mobileSecurity.androidDevices
                            .useStore?.()
                            .getObjectStoreObject(accountId, devices[i])) as EnterpriseDevice
                        let fullPolicyName: string | string[] =
                            thisDevice.policyName?.split("/") || []
                        fullPolicyName =
                            fullPolicyName[0] +
                            "/" +
                            fullPolicyName[1] +
                            "/" +
                            fullPolicyName[2] +
                            "/" +
                            policyName

                        if (thisDevice.policyName != fullPolicyName) {
                            await products.mobileSecurity.androidDevices.queries.updateObjectPropertiesFromApi(
                                accountId,
                                devices[i],
                                { policyName: fullPolicyName },
                                "username",
                                undefined,
                                undefined,
                                "?updateMask[]=policyName"
                            )
                        }
                    } catch (e: any) {
                        console.error(e)
                    }
                }
                if (!jsonHelpers.isObjectEmpty(response)) {
                    result = response as AndroidProfile
                } else {
                    throw new Error("Error updating objects")
                }
                if (updateStore) {
                    this.useStore?.().setObjectTypeObject(accountId, String(objectId), object)
                }
                return result
            } catch (e: any) {
                devLog.log("ObjectType", e?.message, e, "error")
                throw e as Error
            }
        }
    }

    async beforeSavingForm(
        accountId: string,
        form: any,
        object: AndroidProfile,
        objectId?: string,
        rootObject?: AndroidProfile | undefined
    ): Promise<void> {
        object = jsonFormHelpers.deleteUseLessLoopfields(object)

        if (jsonHelpers.getObjectProperty(object, "useConditionVariables") == true) {
            delete object.useConditionVariables
            ;(object.applications || []).forEach((application: any) => {
                if (application.managedConfigurationTemplate != undefined) {
                    if (
                        application.managedConfigurationTemplate.configurationVariables == undefined
                    ) {
                        application.managedConfigurationTemplate.configurationVariables = {
                            "%users%": ""
                        }
                    }
                    if (object.conditionVariablesUsers != undefined) {
                        application.managedConfigurationTemplate.configurationVariables["%users%"] =
                            object.conditionVariablesUsers.toString()
                    } else {
                        application.managedConfigurationTemplate.configurationVariables["%users%"] =
                            ""
                    }
                }
            })
            if (jsonHelpers.getObjectProperty(object, "conditionVariablesUsers") != undefined) {
                delete object.conditionVariablesUsers
            }
        } else if (jsonHelpers.getObjectProperty(object, "applications")) {
            ;(object.applications || []).forEach((application: any) => {
                if (
                    jsonHelpers.getObjectProperty(application, "managedConfigurationTemplate") !=
                    undefined
                ) {
                    if (
                        jsonHelpers.getObjectProperty(
                            application,
                            "managedConfigurationTemplate.configurationVariables"
                        ) != undefined
                    ) {
                        delete application.managedConfigurationTemplate.configurationVariables
                    }
                }
            })
        }

        const spVpnApp = "de.securepoint.ms.agent"

        let rootSecurepointApp = (rootObject?.applications || []).find((application: any) => {
            return application.packageName == spVpnApp
        })
        let securepointApp = (object.applications || []).find((application: any) => {
            return application.packageName == spVpnApp
        })

        // prevent deleting vpn app if sun ist used
        if (object.sun && !securepointApp && rootSecurepointApp) {
            if (!object.applications) {
                object.applications = []
            }
            object.applications.push(rootSecurepointApp)
        }

        let securityToggle = form.toggleEnterpriseSecuritySection

        if (object.smsManagedConfiguration) {
            if (securepointApp) {
                const authSettingValue = object.smsManagedConfiguration.authSetting
                if (authSettingValue != undefined) {
                    object.smsManagedConfiguration.authSetting = authSettingValue
                        ? "FORCED"
                        : "ALLOWED"
                }
                securepointApp.managedConfiguration = object.smsManagedConfiguration
                if (securityToggle?.value === true) {
                    securepointApp.lockTaskAllowed = true
                    if (!securepointApp.managedConfiguration) {
                        securepointApp.managedConfiguration = {}
                    }
                    if (!securepointApp.managedConfiguration?.exclude_ssid) {
                        securepointApp.managedConfiguration.exclude_ssid = []
                    }
                    if (!securepointApp.managedConfiguration.disallowedApplications) {
                        securepointApp.managedConfiguration.disallowedApplications = []
                    }

                    const androidVDT =
                        getterHelpers.useStore()?.state.session.accounts[accountId]?.mobileSecurity
                            .settings?.android?.enterprise?.VDT
                    securepointApp.managedConfiguration.vpnConfigDownloadUrl =
                        "https://" +
                        config.hostname +
                        "/sms-mgt-api/api/" +
                        apis.getApiVersionForFeature("androidEnterprise") +
                        "/tenants/" +
                        tenantHelpers.getTenantDomain(accountId) +
                        "/android/emm/enterprise/policies/" +
                        object.name +
                        "/enrollVPN/" +
                        androidVDT

                    object.setupActions ??= []
                    if (
                        !object.setupActions.find(
                            (action: any) => action.launchApp.packageName === spVpnApp
                        )
                    ) {
                        object.setupActions.push({
                            title: {
                                defaultMessage: "Finalise VPN setup",
                                localizedMessages: {
                                    "de-DE": "VPN-Einrichtung abschließen"
                                }
                            },
                            description: {
                                defaultMessage:
                                    "The app will guide you through the setup process. All points must be confirmed so that the setup can be completed automatically.",
                                localizedMessages: {
                                    "de-DE":
                                        "Sie werden von der App durch die Einrichtung geleitet. Es müssen alle Punkte bestätigt werden, damit die Einrichtung automatisch abgeschlossen werden kann."
                                }
                            },
                            launchApp: {
                                packageName: spVpnApp
                            }
                        })
                    }
                }
            }

            delete object.smsManagedConfiguration
        }

        // remove setup action if sp vpn app is not required for setup (make ben happy again)
        if (
            !(object.applications || []).find(
                (app: any) =>
                    app.packageName === spVpnApp && app.installType === "REQUIRED_FOR_SETUP"
            ) &&
            object.setupActions
        ) {
            object.setupActions = object.setupActions.filter((action: any) => {
                return action.launchApp.packageName != spVpnApp
            })
        }

        // SUN ANDROID
        if (object.sun) {
            let promises: Promise<any>[] = []
            object.sun.forEach((sunInfo: any) => {
                sunInfo.pools?.forEach((pool: any) => {
                    let entity = pool.entities.find((entity: any) => {
                        return entity.id == objectId || entity.id.split("/")?.[3] == objectId
                    })
                    if (entity) {
                        promises.push(
                            products.unifiedSecurityConsole.topologies.updateProfilePoolEntities(
                                accountId,
                                sunInfo.topologyId,
                                pool.id,
                                entity.entityId,
                                { vpnOnDemand: entity.vpnOnDemand || false }
                            )
                        )
                    }
                })
            })

            if (promises.length > 0) {
                try {
                    await Promise.all(promises)
                } catch (e) {}
            }

            // check if any sun values have changed (#43080)
            let hasChanges = false
            if (rootObject?.sun) {
                ;(<any[]>object.sun || []).forEach((sunInfo, i) => {
                    const rootSunInfo = rootObject.sun?.[i]
                    ;(<any[]>sunInfo.pools || [])?.forEach((pool, i) => {
                        const rootPool = rootSunInfo?.pools?.[i]
                        const rootEntity = (<any[]>rootPool?.entities).find((entity, i) => {
                            return entity.id == objectId || entity.id.split("/")?.[3] == objectId
                        })
                        const entity = (<any[]>pool.entities).find((entity, i) => {
                            return entity.id == objectId || entity.id.split("/")?.[3] == objectId
                        })
                        if (
                            rootEntity.vpnOnDemand == undefined ||
                            rootEntity.vpnOnDemand !== entity.vpnOnDemand
                        ) {
                            hasChanges = true
                        }
                    })
                })
            }

            if (rootSecurepointApp) {
                if (securepointApp && rootSecurepointApp?.managedConfiguration?.sun != undefined) {
                    if (securepointApp?.managedConfiguration != undefined) {
                        securepointApp.managedConfiguration.sun =
                            rootSecurepointApp?.managedConfiguration?.sun
                    } else {
                        securepointApp.managedConfiguration = {
                            sun: rootSecurepointApp?.managedConfiguration?.sun
                        }
                    }
                    // update checksum uuid if changes were made (#43080)
                    if (hasChanges) {
                        securepointApp.managedConfiguration.sun.sunChecksum =
                            stringHelpers.generateUUID()
                    }
                }
                delete object.sun
            }
            object?.deviceConnectivityManagement?.tetheringSettings ==
            "TETHERING_SETTINGS_UNSPECIFIED"
                ? delete object.deviceConnectivityManagement.tetheringSettings
                : undefined
        }

        if (object.toolboxCertificates && object.toolboxCertificates.length > 0) {
            let toolboxApp = (object.applications || []).find(function (application: any) {
                return application.packageName == "de.securepoint.emm.toolbox"
            })
            if (toolboxApp != undefined) {
                const uninstallCerts = object.toolboxCertificatesOld
                    .filter((certInfo: string) => {
                        return object.toolboxCertificates.indexOf(certInfo) == -1
                    })
                    .concat(object.toolboxCertificatesUninstallOld)

                toolboxApp.managedConfiguration = {
                    certificates: {
                        install: object.toolboxCertificates,
                        uninstall: uninstallCerts
                    }
                }
            }
        }
        delete object.toolboxCertificatesUninstallOld
        delete object.toolboxCertificatesOld
        delete object.toolboxCertificates

        // Filter Signed Out Devices #38761
        if (object.devices && object.devices.length > 0) {
            let signedInDevices: any[] = []
            const storedDevices =
                products.mobileSecurity.androidDevices
                    .useStore?.()
                    .getObjectStoreObjects(accountId) || []
            storedDevices
                .filter((device) => {
                    if (object.devices) {
                        return object.devices.includes(device.id)
                    }
                    return false
                })
                .forEach((device: any) => {
                    if (device.signedIn == true) {
                        signedInDevices.push(device.id)
                    }
                })
            object.devices = signedInDevices
        }
    }

    /**
     *
     * @param accountId Current Account ID
     * @param objectIds Object ID's which should be deleted from store
     * @param templatePolicyName Old Policy Name in Order to Reconstruct new Names
     */
    deleteUniqueUsernamesForTemplatePoliciesFromStore(
        accountId: string,
        objectIds: string[],
        templatePolicyName: string
    ) {
        objectIds.forEach((objectId: string) => {
            this.useStore?.().deleteObjectTypeObjectFromStore(
                accountId,
                templatePolicyName + "^" + objectId
            )
        })
    }

    /**
     *
     * @param accountId Current Account ID
     * @param newObject To Be Updated / Deleted Profile
     * @param objectId Object ID of the Profile
     * @returns A combined Array of Usernames from ConfigurationTeplates of the New and Old Profile
     */
    getUniqueUsernamesForTemplatePolicies(
        accountId: string,
        newObject: AndroidProfile | undefined,
        objectId: string | number
    ): string[] {
        let result: string[] = []
        const oldObject = this.useStore?.().getObjectStoreObject(accountId, objectId)
        const oldUserNames = this.getUsernamesForTemplatePolicies(oldObject) || []
        const newUserNames = this.getUsernamesForTemplatePolicies(newObject) || []

        if (oldUserNames.length > 0 || newUserNames.length > 0)
            result = Array.from(new Set<string>(oldUserNames.concat(newUserNames)))

        return result
    }

    /**
     *
     * @param profile The Android Profile from which the usernames should be extracted
     * @returns Usernames from Configuration Templates for the Profile
     */
    getUsernamesForTemplatePolicies(profile: AndroidProfile | undefined): string[] | undefined {
        return profile?.applications
            ?.find((application) => {
                return (
                    typeof application?.managedConfigurationTemplate?.configurationVariables?.[
                        "%users%"
                    ] == "string"
                )
            })
            ?.managedConfigurationTemplate?.configurationVariables?.["%users%"].split(",")
            .filter((username) => {
                if (username) return username
            })
    }

    getProfileParts = (item: any) => {
        let parts: any = []
        let profileForm: any = JSON.parse(
            JSON.stringify(useStore().state.resources.shemas["androidProfiles"])
        )
        let tabs: any = {}
        if (item) {
            profileForm.fields[0].fields.map(function (tab: any) {
                return (tabs[tab.id] = tab)
            })

            // get mobile security app
            if (item?.applications) {
                if (item.applications.length) {
                    for (let i in item.applications) {
                        let app: any = item.applications[i]
                        if (app.packageName == "de.securepoint.ms.agent") {
                            item.smsManagedConfiguration = app.managedConfiguration
                        }
                    }
                }
            }

            let hasApps: boolean = item.applications != undefined ? true : false
            let hasPasscode: boolean = item.passwordRequirements != undefined ? true : false
            let hasNetwork: boolean = this.findFieldsForTab(tabs.emm_networks, item)
            let hasRestrictions: boolean = this.findFieldsForTab(tabs.emm_restrictions, item)
            let hasSecurity: boolean = this.findFieldsForTab(tabs.tab_emm_security, item)
            let hasSun: boolean = item.sun && item.sun.length > 0
            let hasSecureDns = item.applications?.some((app: any) => {
                return (
                    app.packageName === "de.securepoint.securedns" &&
                    app.managedConfiguration?.configId
                )
            })

            if (hasApps) {
                parts.push(T("Applications"))
            }
            if (hasRestrictions) {
                parts.push(T("Restrictions"))
            }
            if (hasSecurity) {
                parts.push(T("Security"))
            }
            if (hasPasscode) {
                parts.push(T("Passcode"))
            }
            if (hasNetwork) {
                parts.push(T("Network"))
            }
            if (hasSun) {
                parts.push(T("VPNs"))
            }
            if (hasSecureDns) {
                parts.push(T("Cloud Shield"))
            }
        }

        return parts
    }

    findFieldsForTab = (formJson: any, object: any, path: any = []): any => {
        let result: boolean = false
        for (let fieldIndex in formJson.fields) {
            if (result == true) {
                break
            }
            let field = formJson.fields[fieldIndex]
            let fieldProperty: any = field.property || null
            let fieldPath: any = JSON.parse(JSON.stringify(path))
            if (fieldProperty) {
                fieldPath.push(fieldProperty)
            }
            if (field.type == "tabs" || field.type == "tab" || field.type == "section") {
                result = this.findFieldsForTab(field, object, fieldPath)
                if (result == true) {
                    break
                }
            } else if (field.type == "loopentry") {
                for (let objectIndex in object) {
                    result = this.findFieldsForTab(field, object[objectIndex], [])
                    if (result == true) {
                        break
                    }
                }
            } else if (field.type == "loop") {
                let objectValue: any = jsonHelpers.getObjectProperty(object, fieldPath.join("."))
                if (objectValue != undefined && objectValue != null) {
                    result = true
                    break
                }
            } else {
                if (fieldProperty != null) {
                    let objectValue: any = jsonHelpers.getObjectProperty(
                        object,
                        fieldPath.join(".")
                    )
                    if (objectValue != undefined && objectValue != null) {
                        result = true
                        break
                    }
                }
            }
        }
        return result
    }

    saveToClipBoard = (
        accountId: string,
        object: AndroidProfile | any,
        addNotification: boolean = true
    ) => {
        if (object) {
            if (typeof object == "object") {
                object = jsonHelpers.copyObject(object)
            }

            // Enterprise Profiles
            object.oldItemName = object.profileId

            object.name = object.profileId + T("_Copy")
            object.devices = []
            delete object.profileId
            delete object.smsManagedConfiguration
            delete object.type
            delete object.profilename
            ;(object?.applications || []).forEach((application: any) => {
                if (
                    jsonHelpers.getObjectProperty(
                        application,
                        "managedConfigurationTemplate.configurationVariables.%users%"
                    ) != undefined
                ) {
                    application.managedConfigurationTemplate.configurationVariables["%users%"] = ""
                    delete application.managedConfigurationTemplate.configurationVariables[
                        "%users%"
                    ]
                }
            })

            useStore().commit(MutationTypes.setClipboard, {
                objectType: "androidProfiles",
                objects: [object]
            })
            if (addNotification) {
                useStore().dispatch(ActionTypes.addNotification, {
                    accountId: accountId,
                    content: {
                        title: {
                            icon: "fal fa-exclamation-triangle",
                            text: "Clipboard"
                        },
                        body: {
                            content: "Added item to clipboard"
                        }
                    }
                })
            }
        }
    }

    exportObject(object: AndroidProfile) {
        try {
            object = jsonHelpers.copyObject(object)
            let filename: string = (function () {
                return object.name.split("/")[3]
            })()
            object.name = filename
            delete object.devices
            //@ts-ignore
            delete object.profileId
            delete object.version
            //@ts-ignore
            delete object.$itemlist
            //@ts-ignore
            delete object.toJSON
            //@ts-ignore
            delete object.type

            download(
                new Blob([JSON.stringify(object)]),
                `${filename}.enterpriseProfile`,
                "application/json"
            )
        } catch (e: any) {
            console.error(e)
        }
    }

    convertObjectForStore(accountId: AccountId, objectBase: AddObjectTypeObject<AndroidProfile>) {
        objectBase.devices = [] //devices
        objectBase.profileId = encodingHelpers.unescapeHTML(
            objectBase.name?.split("/")[3] ? objectBase.name?.split("/")[3] : objectBase.name + ""
        )
    }
}

const androidProfiles = new AndroidProfiles({
    productType: "mobileSecurity",
    objectType: "androidProfiles",
    slug: "androidProfiles",
    appearance: {
        iconClass: "fal fa-shield-alt",
        text: {
            plural: "Profiles",
            title: "Profiles",
            singular: "Profile",
            sidebarName: "Profiles"
        }
    },
    objectTypeInfo: {
        primaryKeyProperty: {
            property: "profileId",
            encode: encodingHelpers.encodeURI
        },
        nameProperty: {
            primary: "profileId"
        }
    },
    hasStore: true,
    apiInfo: {
        url: "/sms-mgt-api/api/2.0",
        getCountGETProperties: "?fields=policies.name&props[]=null&select=data.count",
        // GET
        getObjectListResponseProperty: "policies",
        getObjectListMethod: "GET",
        getObjectListPath: "/tenants/{tenantDomain}/android/emm/enterprise/policies",
        // Update
        addObjectMethod: "PUT",
        addObjectPath: "/tenants/{tenantDomain}/android/emm/enterprise/policies/{objectId}",
        // Update
        updateObjectMethod: "PUT",
        updateObjectPath: "/tenants/{tenantDomain}/android/emm/enterprise/policies/{objectId}"
    }
})
export default androidProfiles

export interface ProfileSunPoolInfo {
    id: string
    name: string
    numberOfDevices: number
    transferNetworkCapacity: number
    transferNetwork: string
    serverNodeId: string
    state: string
    entities: SunRoadwarriorEntity[]
    errors: string[]
}
export interface ProfileSunInfo {
    coreUtmId: string
    coreUtmName: string
    topologyId: string
    pools: ProfileSunPoolInfo[]
}

export interface AndroidProfile extends ObjectType<AndroidProfile> {
    profileId: string //set by frontend (last part of name)
    devices?: Array<string> //set by frontend
    type: "policy" | "autogenerated policy" | "template policy" //set by api

    sun?: ProfileSunInfo[]
    accountTypesWithManagementDisabled?: string[] | null
    addUserDisabled?: boolean | null
    adjustVolumeDisabled?: boolean | null
    advancedSecurityOverrides?: Schema$AdvancedSecurityOverrides
    alwaysOnVpnPackage?: Schema$AlwaysOnVpnPackage
    androidDevicePolicyTracks?: string[] | null
    appAutoUpdatePolicy?: string | null
    applications?: Schema$ApplicationPolicy[]
    autoDateAndTimeZone?: string | null
    autoTimeRequired?: boolean | null
    blockApplicationsEnabled?: boolean | null
    bluetoothConfigDisabled?: boolean | null
    bluetoothContactSharingDisabled?: boolean | null
    bluetoothDisabled?: boolean | null
    cameraAccess?: string | null
    cameraDisabled?: boolean | null
    cellBroadcastsConfigDisabled?: boolean | null
    choosePrivateKeyRules?: Schema$ChoosePrivateKeyRule[]
    complianceRules?: Schema$ComplianceRule[]
    createWindowsDisabled?: boolean | null
    credentialsConfigDisabled?: boolean | null
    crossProfilePolicies?: Schema$CrossProfilePolicies
    dataRoamingDisabled?: boolean | null
    debuggingFeaturesAllowed?: boolean | null
    defaultPermissionPolicy?: string | null
    deviceConnectivityManagement?: Schema$DeviceConnectivityManagement
    deviceOwnerLockScreenInfo?: Schema$UserFacingMessage
    deviceRadioState?: Schema$DeviceRadioState
    encryptionPolicy?: string | null
    ensureVerifyAppsEnabled?: boolean | null
    factoryResetDisabled?: boolean | null
    frpAdminEmails?: string[] | null
    funDisabled?: boolean | null
    installAppsDisabled?: boolean | null
    installUnknownSourcesAllowed?: boolean | null
    keyguardDisabled?: boolean | null
    keyguardDisabledFeatures?: string[] | null
    kioskCustomization?: Schema$KioskCustomization
    kioskCustomLauncherEnabled?: boolean | null
    locationMode?: string | null
    longSupportMessage?: Schema$UserFacingMessage
    maximumTimeToLock?: string | null
    microphoneAccess?: string | null
    minimumApiLevel?: number | null
    mobileNetworksConfigDisabled?: boolean | null
    modifyAccountsDisabled?: boolean | null
    mountPhysicalMediaDisabled?: boolean | null
    name: string
    networkEscapeHatchEnabled?: boolean | null
    networkResetDisabled?: boolean | null
    oncCertificateProviders?: Schema$OncCertificateProvider[]
    openNetworkConfiguration?: { [key: string]: any } | null
    outgoingBeamDisabled?: boolean | null
    outgoingCallsDisabled?: boolean | null
    passwordPolicies?: Schema$PasswordRequirements[]
    passwordRequirements?: Schema$PasswordRequirements
    permissionGrants?: Schema$PermissionGrant[]
    permittedAccessibilityServices?: Schema$PackageNameList
    permittedInputMethods?: Schema$PackageNameList
    persistentPreferredActivities?: Schema$PersistentPreferredActivity[]
    personalUsagePolicies?: Schema$PersonalUsagePolicies
    playStoreMode?: string | null
    policyEnforcementRules?: Schema$PolicyEnforcementRule[]
    preferentialNetworkService?: string | null
    privateKeySelectionEnabled?: boolean | null
    recommendedGlobalProxy?: Schema$ProxyInfo
    removeUserDisabled?: boolean | null
    safeBootDisabled?: boolean | null
    screenCaptureDisabled?: boolean | null
    setupActions?: Schema$SetupAction[]
    setUserIconDisabled?: boolean | null
    setWallpaperDisabled?: boolean | null
    shareLocationDisabled?: boolean | null
    shortSupportMessage?: Schema$UserFacingMessage
    skipFirstUseHintsEnabled?: boolean | null
    smsDisabled?: boolean | null
    statusBarDisabled?: boolean | null
    statusReportingSettings?: Schema$StatusReportingSettings
    stayOnPluggedModes?: string[] | null
    systemUpdate?: Schema$SystemUpdate
    tetheringConfigDisabled?: boolean | null
    uninstallAppsDisabled?: boolean | null
    unmuteMicrophoneDisabled?: boolean | null
    usageLog?: Schema$UsageLog
    usbFileTransferDisabled?: boolean | null
    usbMassStorageEnabled?: boolean | null
    version?: string | null
    vpnConfigDisabled?: boolean | null
    wifiConfigDisabled?: boolean | null
    wifiConfigsLockdownEnabled?: boolean | null

    useConditionVariables?: boolean
    conditionVariablesUsers?: Array<string>
    smsManagedConfiguration?: any
    toolboxCertificatesUninstallOld?: any
    toolboxCertificatesOld?: any
    toolboxCertificates?: any
}

interface Schema$AlwaysOnVpnPackage {
    lockdownEnabled?: boolean | null
    packageName?: string | null
}

interface Schema$AlwaysOnVpnPackage {
    lockdownEnabled?: boolean | null
    packageName?: string | null
}

interface Schema$ApplicationPolicy {
    accessibleTrackIds?: string[] | null
    alwaysOnVpnLockdownExemption?: string | null
    autoUpdateMode?: string | null
    connectedWorkAndPersonalApp?: string | null
    defaultPermissionPolicy?: string | null
    delegatedScopes?: string[] | null
    disabled?: boolean | null
    extensionConfig?: Schema$ExtensionConfig
    installType?: string | null
    lockTaskAllowed?: boolean | null
    managedConfiguration?: { [key: string]: any } | null
    managedConfigurationTemplate?: Schema$ManagedConfigurationTemplate
    minimumVersionCode?: number | null
    packageName?: string | null
    permissionGrants?: Schema$PermissionGrant[]
    workProfileWidgets?: string | null
}

interface Schema$ExtensionConfig {
    notificationReceiver?: string | null
    signingKeyFingerprintsSha256?: string[] | null
}

interface Schema$ManagedConfigurationTemplate {
    configurationVariables?: { [key: string]: string } | null
    templateId?: string | null
}

interface Schema$PermissionGrant {
    permission?: string | null
    policy?: string | null
}

interface Schema$UsageLog {
    enabledLogTypes?: string[] | null
    uploadOnCellularAllowed?: string[] | null
}

interface Schema$SystemUpdate {
    endMinutes?: number | null
    freezePeriods?: Schema$FreezePeriod[]
    startMinutes?: number | null
    type?: string | null
}

interface Schema$FreezePeriod {
    endDate?: Schema$Date
    startDate?: Schema$Date
}

interface Schema$Date {
    day?: number | null
    month?: number | null
    year?: number | null
}

interface Schema$StatusReportingSettings {
    applicationReportingSettings?: Schema$ApplicationReportingSettings
    applicationReportsEnabled?: boolean | null
    commonCriteriaModeEnabled?: boolean | null
    deviceSettingsEnabled?: boolean | null
    displayInfoEnabled?: boolean | null
    hardwareStatusEnabled?: boolean | null
    memoryInfoEnabled?: boolean | null
    networkInfoEnabled?: boolean | null
    powerManagementEventsEnabled?: boolean | null
    softwareInfoEnabled?: boolean | null
    systemPropertiesEnabled?: boolean | null
}

interface Schema$ApplicationReportingSettings {
    includeRemovedApps?: boolean | null
}

interface Schema$UserFacingMessage {
    defaultMessage?: string | null
    localizedMessages?: { [key: string]: string } | null
}

interface Schema$ChoosePrivateKeyRule {
    packageNames?: string[] | null
    privateKeyAlias?: string | null
    urlPattern?: string | null
}

interface Schema$ComplianceRule {
    apiLevelCondition?: Schema$ApiLevelCondition
    disableApps?: boolean | null
    nonComplianceDetailCondition?: Schema$NonComplianceDetailCondition
    packageNamesToDisable?: string[] | null
}

interface Schema$ApiLevelCondition {
    minApiLevel?: number | null
}

interface Schema$NonComplianceDetailCondition {
    nonComplianceReason?: string | null
    packageName?: string | null
    settingName?: string | null
}

interface Schema$CrossProfilePolicies {
    crossProfileCopyPaste?: string | null
    crossProfileDataSharing?: string | null
    showWorkContactsInPersonalProfile?: string | null
    workProfileWidgetsDefault?: string | null
}

interface Schema$DeviceConnectivityManagement {
    configureWifi?: string | null
    tetheringSettings?: string | null
    usbDataAccess?: string | null
    wifiDirectSettings?: string | null
}

interface Schema$DeviceRadioState {
    airplaneModeState?: string | null
    wifiState?: string | null
}

interface Schema$SetupAction {
    description?: Schema$UserFacingMessage
    launchApp?: Schema$LaunchAppAction
    title?: Schema$UserFacingMessage
}

interface Schema$LaunchAppAction {
    packageName?: string | null
}

interface Schema$KioskCustomization {
    deviceSettings?: string | null
    powerButtonActions?: string | null
    statusBar?: string | null
    systemErrorWarnings?: string | null
    systemNavigation?: string | null
}

interface Schema$OncCertificateProvider {
    certificateReferences?: string[] | null
    contentProviderEndpoint?: Schema$ContentProviderEndpoint
}

interface Schema$ContentProviderEndpoint {
    packageName?: string | null
    signingCertsSha256?: string[] | null
    uri?: string | null
}

interface Schema$PasswordRequirements {
    maximumFailedPasswordsForWipe?: number | null
    passwordExpirationTimeout?: string | null
    passwordHistoryLength?: number | null
    passwordMinimumLength?: number | null
    passwordMinimumLetters?: number | null
    passwordMinimumLowerCase?: number | null
    passwordMinimumNonLetter?: number | null
    passwordMinimumNumeric?: number | null
    passwordMinimumSymbols?: number | null
    passwordMinimumUpperCase?: number | null
    passwordQuality?: string | null
    passwordScope?: string | null
    requirePasswordUnlock?: string | null
    unifiedLockSettings?: string | null
}

interface Schema$PackageNameList {
    packageNames?: string[] | null
}

interface Schema$PersistentPreferredActivity {
    actions?: string[] | null
    categories?: string[] | null
    receiverActivity?: string | null
}

interface Schema$PersonalUsagePolicies {
    accountTypesWithManagementDisabled?: string[] | null
    cameraDisabled?: boolean | null
    maxDaysWithWorkOff?: number | null
    personalApplications?: Schema$PersonalApplicationPolicy[]
    personalPlayStoreMode?: string | null
    screenCaptureDisabled?: boolean | null
}

interface Schema$PersonalApplicationPolicy {
    installType?: string | null
    packageName?: string | null
}

interface Schema$PolicyEnforcementRule {
    blockAction?: Schema$BlockAction
    settingName?: string | null
    wipeAction?: Schema$WipeAction
}

interface Schema$BlockAction {
    blockAfterDays?: number | null
    blockScope?: string | null
}

interface Schema$WipeAction {
    preserveFrp?: boolean | null
    wipeAfterDays?: number | null
}

interface Schema$ProxyInfo {
    excludedHosts?: string[] | null
    host?: string | null
    pacUri?: string | null
    port?: number | null
}
interface Schema$AdvancedSecurityOverrides {
    commonCriteriaMode?: string | null
    developerSettings?: string | null
    googlePlayProtectVerifyApps?: string | null
    personalAppsThatCanReadWorkNotifications?: string[] | null
    untrustedAppsPolicy?: string | null
}
