<script setup lang="ts">
import { useVue } from "@/app"
import config from "@/classes/config"
import { T } from "@/classes/i18n"
import objectStores from "@/classes/init"
import { useModalStateStore, type AddEditRuleState } from "@/classes/modalStates"
import products from "@/classes/objectTypes"
import {
    type NetworkViewNode,
    type NetworkViewService
} from "@/classes/objectTypes/unifiedSecurityConsole/nodes"
import type {
    CreateNode,
    CreateService,
    SunNkViewNode,
    UpdateDnsEntry
} from "@/classes/objectTypes/unifiedSecurityConsole/topologies"
import encodingHelpers from "@/helpers/helpers.encoding"
import getterHelpers from "@/helpers/helpers.getters"
import { ActionTypes, MutationTypes } from "@/store/vuex.store"
import { computed, onMounted, ref, watch } from "vue"
import buttonComponent from "../components/button/button.vue"
import loader from "../components/loader.vue"
import ModalObject, { ModalObjectButton } from "../components/modals/modalClass"
import inputVueSelect from "../inputtypes/input-vue-select.vue"

const props = defineProps<{
    properties: {
        coreUtmId: "" | string
        node: "" | string
        service: ""
        topologyId: string
        type: "add" | "edit"
        objectType: "primaryCoreDNS" | "secondaryCoreDNS"
        primaryDNS: UpdateDnsEntry
        secondaryDNS: UpdateDnsEntry
    }
}>()

const activeAccountId = computed(() => {
    return getterHelpers.useStore()?.getters.getActiveAccountId
})
const utmNameMap = computed(() => {
    const nameMap: {
        [id: string]: {
            utmName: string
            icon: string
        }
    } = {}

    ;(config.canUseNewObjectType("uscUtms")
        ? products.unifiedSecurityConsole.uscUtms
              .useStore?.()
              .getObjectStoreObjects(activeAccountId.value) || []
        : objectStores.uscUtms.getObjectsFromStore(activeAccountId.value) || []
    )
        .filter((utm) => {
            return [props.properties.coreUtmId].includes(utm.utmId)
        })
        .forEach((utm) => {
            nameMap[utm.utmId] = {
                utmName: utm.utmname || utm.utmId,
                icon:
                    utm.utmId == props.properties.coreUtmId
                        ? "fal fa-crown"
                        : "fal fa-satellite-dish"
            }
        })
    return nameMap
})

const modalState = computed(() => {
    return useModalStateStore().getStateById("addEditRule") as AddEditRuleState | undefined
})

const initialized = ref(false)

// FUNCTIONS
const checkReadyState = () => {
    if (initialized.value == true && coreUtmId.value && node.value && service.value) {
        getterHelpers.useStore().getters.getActiveModal().buttons[1].loading = false
        getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled = false
    } else {
        getterHelpers.useStore().getters.getActiveModal().buttons[1].disabled = true
    }
}

const getGroupedNodes = (nodes: NetworkViewNode[]) => {
    const groups = {
        /*
            networkGroups: {
                name: 'Network Groups',
                nodes: [] as selectOption[]
            },
            */
        networkObjects: {
            name: "Network Objects",
            nodes: [] as selectOption[]
        }
    }

    nodes.forEach((node) => {
        if (Array.isArray(node.node_refs)) {
            /*
                groups.networkGroups.nodes.push({
                    id: node.id ? node.id : node.name,
                    text: node.name,
                    groupId: `#${'Network Groups'.replace(" ", "")}Header`,
                    icon: "icon icon-node-group"
                })
                    */
        } else {
            groups.networkObjects.nodes.push({
                id: node.id ? node.id : node.name,
                text:
                    node.name +
                    (node.node_address ? " (" + node.node_address + ")" : "") +
                    (node?.node_zone?.name ? " (" + node?.node_zone?.name + ")" : ""),
                groupId: `#${"Network Objects".replace(" ", "")}Header`,
                icon: products.unifiedSecurityConsole.topologies.view.getIconClassForNode(node)
            })
        }
    })

    // added node for core dns
    if (addedNode.value) {
        let node = addedNode.value
        groups.networkObjects.nodes.push({
            id: node.name,
            text:
                node.name +
                (node.node_address ? " (" + node.node_address + ")" : "") +
                (node?.node_zone?.name ? " (" + node?.node_zone?.name + ")" : ""),
            groupId: `#${"Network Objects".replace(" ", "")}Header`,
            icon: products.unifiedSecurityConsole.topologies.view.getIconClassForNode(node)
        })
    }

    return Object.values(groups).reduce((acc, group) => {
        if (group.nodes.length > 0) {
            acc.push({
                id: `#${group.name.replace(" ", "")}Header`,
                text: T(group.name),
                type: "groupName",
                disabled: true
            })
            acc.push(
                ...group.nodes
                    .sort((a, b) => a.text.localeCompare(b.text))
                    .sort((a, b) => (a.icon || "").localeCompare(b.icon || ""))
            )
        }
        return acc
    }, [] as selectOption[])
}

const getGroupedServices = (services: NetworkViewService[]) => {
    const groups = {
        serviceGroups: {
            name: "Service Groups",
            services: [] as selectOption[]
        },
        services: {
            name: "Services",
            services: [] as selectOption[]
        }
    }
    services.forEach((service) => {
        if (Array.isArray(service.service_refs)) {
            groups.serviceGroups.services.push({
                id: service.id ? service.id : service.name,
                text: service.name,
                groupId: `#${"Service Groups".replace(" ", "")}Header`,
                icon: "icon icon-serviceobject-group",
                tooltip: {
                    htmlTooltip: true,
                    customId: service.name + service.id,
                    content: `
                            <table style="margin:0;">
                                <tr>
                                    <td><strong>${T("Service Group")}</strong></td>
                                </tr>
                                <tr>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>${T("Services:")}</td>
                                </tr>
                                <tr>
                                    <td>
                                        ${service.service_refs.join("<br>")}
                                    </td>
                                </tr>
                            </table>
                        `
                }
            })
        } else if (Array.isArray(service?.services) && service.id == undefined) {
            let allServices = ""
            service.services.forEach((service) => {
                allServices = allServices + encodingHelpers.escapeHTML(service.name) + "<br>"
            })
            groups.serviceGroups.services.push({
                id: service.name,
                text: service.name,
                groupId: `#${"Service Groups".replace(" ", "")}Header`,
                icon: "icon icon-serviceobject-group",
                tooltip: {
                    htmlTooltip: true,
                    customId: service.name,
                    content: `
                            <table style="margin:0;">
                                <tr>
                                    <td><strong>${T("Service Group")}</strong></td>
                                </tr>
                                <tr>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>${T("Services:")}</td>
                                </tr>
                                <tr>
                                    <td>
                                        ${allServices}
                                    </td>
                                </tr>
                            </table>
                        `
                }
            })
        } else {
            groups.services.services.push({
                id: service.id ? service.id : service.name,
                text: service.name,
                groupId: `#${"Services".replace(" ", "")}Header`,
                icon: /^(tcp|udp|icmp|ipv6-icmp)$/.test(service.protocol)
                    ? "icon icon-serviceobject-" + service.protocol
                    : "icon icon-serviceobject-other",
                tooltip: {
                    htmlTooltip: true,
                    customId: service.name + service.id,
                    content: `
                            <table style="margin:0;">
                                <tr>
                                    <td><strong>${T("Service")}</strong></td>
                                </tr>
                                <tr>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>${T("Protocol")}:</td>
                                    <td>${service.protocol}</td>
                                </tr>
                                <tr>
                                    <td>${T("Source ports")}:</td>
                                    <td>${service["src-ports"]?.join(", ") || "-"}</td>
                                </tr>
                                <tr>
                                    <td>${T("Destination ports")}:</td>
                                    <td>${service["dst-ports"]?.join(", ") || "-"}</td>
                                </tr>
                            </table>
                        `
                }
            })
        }
    })

    // added node for core dns
    if (addedService.value) {
        let service = addedService.value
        groups.services.services.push({
            id: service.name,
            text: service.name,
            groupId: `#${"Services".replace(" ", "")}Header`,
            icon: /^(tcp|udp|icmp|ipv6-icmp)$/.test(service.protocol)
                ? "icon icon-serviceobject-" + service.protocol
                : "icon icon-serviceobject-other",
            tooltip: {
                htmlTooltip: true,
                customId: service.name,
                content: `
                        <table style="margin:0;">
                            <tr>
                                <td><strong>${T("Service")}</strong></td>
                            </tr>
                            <tr>
                                <td></td>
                            </tr>
                            <tr>
                                <td>${T("Protocol")}:</td>
                                <td>${service.protocol}</td>
                            </tr>
                            <tr>
                                <td>${T("Source ports")}:</td>
                                <td>${service["src-ports"]?.join(", ") || "-"}</td>
                            </tr>
                            <tr>
                                <td>${T("Destination ports")}:</td>
                                <td>${service["dst-ports"]?.join(", ") || "-"}</td>
                            </tr>
                        </table>
                    `
            }
        })
    }

    return Object.values(groups).reduce((acc, group) => {
        if (group.services.length > 0) {
            acc.push({
                id: `#${group.name.replace(" ", "")}Header`,
                text: T(group.name),
                type: "groupName",
                disabled: true
            })
            acc.push(...group.services.sort((a, b) => a.text.localeCompare(b.text)))
        }
        return acc
    }, [] as selectOption[])
}

const serviceOptions = computed(() => {
    const destinationNode = nodeInfo.value
    if (destinationNode && destinationNode.nkView?.services) {
        return getGroupedServices(destinationNode.nkView?.services)
    } else {
        return []
    }
})

const renderAddServiceDialog = () => {
    useModalStateStore().addStateToStore("addEditRule", {
        destinationUTM: coreUtmId.value,
        destinationNode: node.value,
        service: service.value
    })
    let previousAddedNode = addedNode.value
    let previousAddedService = addedService.value
    new ModalObject({
        accountId: activeAccountId.value,
        id: "addServiceDialog",
        content: {
            body: {
                component: "add-service",
                properties: {
                    sattelitteId: coreUtmId.value,
                    topologyId: props.properties.topologyId || "",
                    isCoreDns: true
                }
            },
            title: {
                icon: "fal fa-plus",
                text: T("Add Service")
            }
        },
        abortable: true,
        buttons: [
            new ModalObjectButton({
                text: T("Abort"),
                icon: "fal fa-times",
                disabled: false,
                onClick: () => {
                    setTimeout(async () => {
                        if (previousAddedNode) {
                            useVue().$refs.modals.$refs.modal.$refs.modalComponent.addedNode =
                                previousAddedNode
                        }
                        if (previousAddedService) {
                            useVue().$refs.modals.$refs.modal.$refs.modalComponent.addedService =
                                previousAddedService
                        }
                    }, 700)
                    getterHelpers.useStore().commit(MutationTypes.removeModal, {})
                }
            }),
            new ModalObjectButton({
                text: T("Add Service"),
                icon: "fal fa-plus",
                disabled: true,
                loading: false,
                onClick: async (modalWrapper, modal) => {
                    const modalComponent = modalWrapper.$refs.modalComponent
                    const payload = await modalComponent.getPayload()
                    setTimeout(async () => {
                        useVue().$refs.modals.$refs.modal.$refs.modalComponent.addedService =
                            payload
                        if (previousAddedNode) {
                            useVue().$refs.modals.$refs.modal.$refs.modalComponent.addedNode =
                                previousAddedNode
                        }
                    }, 700)
                    modal.delete()
                }
            })
        ]
    }).showFirst()
}

const renderAddNetworkObjectDialog = () => {
    useModalStateStore().addStateToStore("addEditRule", {
        destinationUTM: coreUtmId.value,
        destinationNode: node.value,
        service: service.value
    })
    let previousAddedNode = addedNode.value
    let previousAddedService = addedService.value
    const utmId = coreUtmId.value
    new ModalObject({
        accountId: activeAccountId.value,
        id: "addNetworkObject",
        content: {
            body: {
                component: "add-network-object",
                properties: {
                    nodeId: utmId,
                    topologyId: props.properties.topologyId || "",
                    isCoreDns: true,
                    type: "destination"
                }
            },
            title: {
                icon: "fal fa-plus",
                text: T("Add Network Object")
            }
        },
        abortable: true,
        buttons: [
            new ModalObjectButton({
                text: T("Abort"),
                icon: "fal fa-times",
                disabled: false,
                onClick: () => {
                    setTimeout(() => {
                        if (previousAddedNode) {
                            useVue().$refs.modals.$refs.modal.$refs.modalComponent.addedNode =
                                previousAddedNode
                        }
                        if (previousAddedService) {
                            useVue().$refs.modals.$refs.modal.$refs.modalComponent.addedService =
                                previousAddedService
                        }
                    }, 700)
                    getterHelpers.useStore().commit(MutationTypes.removeModal, {})
                }
            }),
            new ModalObjectButton({
                text: T("Add Network Object"),
                icon: "fal fa-plus",
                disabled: true,
                loading: false,
                onClick: async (modalWrapper: any, modal) => {
                    modal.getButton(1)?.startLoader()

                    const modalComponent = modalWrapper.$refs.modalComponent
                    const networkObject = modalComponent.networkObject
                    const address = modalComponent.address
                    const selectedZone = modalComponent.selectedZone
                    const networkGroup = modalComponent.networkGroup
                    const selectedNetworkGroupInfo = modalComponent.selectedNetworkGroupInfo
                    const groupId = networkGroup
                        ? selectedNetworkGroupInfo
                            ? (<SunNkViewNode>selectedNetworkGroupInfo)?.id
                            : networkGroup
                        : ""
                    modalComponent.clearErrorString()

                    let payload: CreateNode = {
                        name: networkObject,
                        node_address: address,
                        node_zone: {
                            id: selectedZone.id,
                            name: selectedZone.text
                        }
                    }
                    modal.getButton(1)?.stopLoader()
                    setTimeout(() => {
                        useVue().$refs.modals.$refs.modal.$refs.modalComponent.addedNode = payload
                        if (previousAddedService) {
                            useVue().$refs.modals.$refs.modal.$refs.modalComponent.addedService =
                                previousAddedService
                        }
                    }, 700)
                    modal.delete()
                }
            })
        ]
    }).showFirst()
}

const destinationNodeOptions = computed(() => {
    const thisNode = nodeInfo.value
    return getGroupedNodes(thisNode?.nkView?.nodes || [])
})

const coreUtmId = ref("")
const node = ref("")
const service = ref("")

const addedNode = ref(<CreateNode | undefined>undefined)
const addedService = ref(<CreateService | undefined>undefined)

const nodeInfo = computed(() => {
    return products.unifiedSecurityConsole.utmNodes
        .useStore?.()
        .getObjectStoreObject(activeAccountId.value, coreUtmId.value)
})

onMounted(async () => {
    if (config.canUseNewObjectType("uscUtmStates")) {
        await products.unifiedSecurityConsole.uscUtmStates.queries.getObjectsFromApi(
            activeAccountId.value
        )
    } else {
        await getterHelpers.useStore().dispatch(ActionTypes.getObjectInfos, {
            accountId: activeAccountId.value,
            objectTypes: ["ccutmStates"]
        })
    }
    coreUtmId.value = props.properties.coreUtmId
    if (props.properties.node) {
        node.value = props.properties.node
    }
    if (props.properties.service) {
        service.value = props.properties.service
    }
    if (modalState.value) {
        if (modalState.value?.destinationUTM) coreUtmId.value = modalState.value.destinationUTM
        if (modalState.value?.destinationNode) node.value = modalState.value.destinationNode
        if (modalState.value?.service) service.value = modalState.value.service
        useModalStateStore().removeStateFromStore("addEditRule")
    }

    if (props.properties.primaryDNS) {
        addedService.value = props.properties.primaryDNS.service as any
        addedNode.value = props.properties.primaryDNS.node as any
    } else if (props.properties.secondaryDNS) {
        addedService.value = props.properties.secondaryDNS.service as any
        addedNode.value = props.properties.secondaryDNS.node as any
    }
    initialized.value = true
    checkReadyState()
})

watch(coreUtmId, checkReadyState)
watch(node, checkReadyState)
watch(service, checkReadyState)
watch(initialized, checkReadyState)

watch(addedService, () => {
    if (addedService.value) {
        service.value = addedService.value.name
    }
})
watch(addedNode, () => {
    if (addedNode.value) {
        node.value = addedNode.value.name
    }
})
// EXPOSE SELECTED VALUES
defineExpose({
    coreUtmId,
    node,
    service,
    addedNode,
    addedService,
    nkView: nodeInfo
})
</script>
<template>
    <div class="content-2">
        <template v-if="initialized">
            <div class="row padding-xs-t padding-xs-b-2 form-group border-bottom">
                <div class="first col-xs-24 col-lg-6 col-xl-4">
                    <label class="control-label inputname" for="destinationNodeSelect">
                        {{ T("Network object") }}
                    </label>
                </div>
                <div class="input col-xs-24 col-lg-10">
                    <input-vue-select
                        id="destinationNodeSelect"
                        v-model="node"
                        :disabled="coreUtmId ? null : true"
                        :select-options="destinationNodeOptions"
                        :placeholder="T('Please select a network object')"
                        append-to-body
                    ></input-vue-select>
                </div>
                <div class="col-xs-24 col-lg-2">
                    <buttonComponent
                        :button-options="{
                            icon: 'fal fa-plus',
                            id: 'addServiceButton',
                            text: '',
                            title: T('Add Network Object'),
                            onClick: renderAddNetworkObjectDialog,
                            size: 'sm'
                        }"
                    ></buttonComponent>
                </div>
                <div class="input-description col-lg-7">
                    {{
                        T(
                            "Wählen Sie ein bestehendes Netzwerkobjekt aus oder legen Sie ein neues an."
                        )
                    }}
                </div>
            </div>
            <div class="row padding-xs-y form-group border-bottom">
                <div class="first col-xs-24 col-lg-6 col-xl-4">
                    <label class="control-label inputname" for="serviceSelect">
                        {{ T("Service") }}
                    </label>
                </div>
                <div class="input col-xs-24 col-lg-10">
                    <input-vue-select
                        id="serviceSelect"
                        v-model="service"
                        :disabled="coreUtmId ? null : true"
                        :select-options="serviceOptions"
                        :placeholder="T('Select a service')"
                        append-to-body
                    ></input-vue-select>
                </div>
                <div class="col-xs-24 col-lg-2">
                    <buttonComponent
                        :button-options="{
                            icon: 'fal fa-plus',
                            id: 'addServiceButton',
                            text: '',
                            title: T('Add Service'),
                            onClick: renderAddServiceDialog,
                            size: 'sm'
                        }"
                    >
                    </buttonComponent>
                </div>
                <div class="input-description col-lg-7">
                    {{
                        T("Wählen Sie einen bestehenden Dienst aus oder legen Sie einen neuen an.")
                    }}
                </div>
            </div>
        </template>
        <template v-else>
            <div class="text-center padding-xs-y-8 col-xs">
                <div class="text-size-3">
                    <loader class="text-size-2 color-red" />
                </div>
            </div>
        </template>
    </div>
</template>
