<template>
    <div class="row flexrow">
        <div class="col-xs-24 col-lg-16">
            <div class="box-shadow padding-xs-2" style="height: 100%">
                <h4 class="margin-xs-b-2">
                    <secure-dns-nav-icon color="#e74b3c" />

                    {{ T("Cloud Shield") }}
                </h4>
                <p>
                    {{
                        T(
                            "Cloud Shield schützt vor Phishing, Malware und unerwünschten Webseiten, indem schädliche Domains automatisch blockiert werden. Durch die zentrale Verteilung per MDM wird sichergestellt, dass alle Firmen-Smartphones einheitlich geschützt sind und Unternehmensrichtlinien zur sicheren Internetnutzung eingehalten werden. Gleichzeitig werden Tracking und Datendiebstahl reduziert, während die Verbindungsgeschwindigkeit optimiert bleibt. Zusätzlich können externe Geräte, die nicht im Unified Security Portal verwaltet werden, durch manuelle Konfiguration geschützt werden."
                        )
                    }}
                </p>

                <p>
                    {{
                        T(
                            "Securepoint Cloud Shield leitet alle DNS-Anfragen der Geräte über eigene, sichere Server, die verdächtige oder schädliche Domains anhand von regelmäßig aktualisierten Filterlisten blockieren. Die Anfragen werden dabei verschlüsselt (DNS-over-TLS/HTTPS), um Manipulationen und Abhörversuche zu verhindern. Nicht erlaubte Inhalte werden gefiltert, indem der Zugriff auf entsprechende IP-Adressen verweigert und eine Blockseite angezeigt wird."
                        )
                    }}
                </p>

                <p class="text-bold margin-xs-t-3">
                    {{ T("Ablauf der Konfiguration mit MDM") }}
                </p>

                <p>
                    {{
                        T(
                            "Um Android- und iOS-Smartphones mit Securepoint Cloud Shield abzusichern, führen Sie bitte die folgenden Schritte durch:"
                        )
                    }}
                </p>

                <ol>
                    <li>
                        <b>{{ T("Erstellen eines Cloud Shield Profils") }}</b
                        >:
                        {{
                            T(
                                "Legen Sie alle relevanten Einstellungen wie Filterregeln und Sicherheitsrichtlinien zentral fest."
                            )
                        }}
                    </li>
                    <li>
                        <b>{{ T("Erstellen eines Konfigurationsprofils für Geräte") }}</b
                        >:
                        {{
                            T(
                                "Erstellen Sie iOS- und Android-Geräte Konfigurationsprofile. Dabei wird das zuvor angelegte Cloud Shield Profil ausgewählt und mit dem jeweiligen Konfigurationsprofil verknüpft."
                            )
                        }}
                    </li>
                    <li>
                        <b>{{ T("Verteilung auf die Smartphones") }}</b
                        >:
                        {{
                            T(
                                "Die Securepoint Cloud Shield App wird automatisch auf die Smartphones verteilt und entsprechend den definierten Einstellungen konfiguriert."
                            )
                        }}
                    </li>
                </ol>

                <b>{{
                    T(
                        "Hinweis: Für iOS-Geräte können ggf. VPP-Lizenzen erforderlich sein, um die App automatisiert bereitzustellen."
                    )
                }}</b>

                <p class="text-bold margin-xs-t-3">
                    {{ T("Ablauf der manuellen Konfiguration von externen Geräten") }}
                </p>

                <ol>
                    <li>
                        <b>{{ T("Erstellen eines Cloud Shield Profils für externe Geräte") }}</b
                        >:
                        {{
                            T(
                                "Legen Sie alle relevanten Einstellungen wie Filterregeln und Sicherheitsrichtlinien zentral fest."
                            )
                        }}
                    </li>

                    <li>
                        <b>{{ T("Folgen Sie den Einrichtungsanweisungen im Popup-Fenster.") }}</b
                        ><br />
                        {{
                            T(
                                'Das Popup-Fenster kann zu einem späteren Zeitpunkt über "Konfiguration anzeigen" in der Profilkachel erneut geöffnet werden.'
                            )
                        }}
                    </li>
                </ol>

                <p>
                    {{
                        T(
                            "Nach der Einrichtung leiten die Geräte ihre DNS-Anfragen an die Securepoint DNS-Server. Dadurch werden sie zuverlässig vor schädlichen Webseiten und unerwünschten Inhalten geschützt."
                        )
                    }}
                </p>

                <p>
                    {{ T("Weitere Informationen finden Sie in unserem Wiki unter") }}
                    <a href="https://wiki.securepoint.de/" target="_blank">{{
                        T("Cloud Shield")
                    }}</a
                    >.
                </p>

                <p class="margin-xs-t-4 text-center" style="font-size: 1.15em">
                    <btn :button-options="addProfileButton" />
                </p>
            </div>
        </div>
        <div class="col-xs-24 col-lg-8">
            <div class="box-shadow padding-xs-2 text-center" style="height: 100%">
                <img
                    :src="darkmode ? diagramImageDark : diagramImage"
                    style="
                        display: inline;
                        max-width: 400px;
                        width: 100%;
                        filter: grayscale(1);
                        opacity: 0.7;
                    "
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { T } from "@/classes/i18n"
import getterHelpers from "@/helpers/helpers.getters"
import diagramImageDark from "@/img/lp_cloudshield_dark.svg"
import diagramImage from "@/img/lp_cloudshield_light.svg"
import router from "@/router/router"
import { useStore } from "@/store/vuex.store"
import { computed, ref } from "vue"
import Button from "../button/button"
import btn from "../button/button.vue"
import secureDnsNavIcon from "../secure-dns-nav-icon.vue"

const darkmode = computed(() => {
    return getterHelpers.useStore().state.browserSettings.darkmode == "1"
})

const accountId = computed(() => {
    return useStore().state.session.activeAccountId || ""
})

const addProfileButton = ref(
    new Button({
        icon: "fa-plus",
        text: T("Cloud Shield Profil jetzt hinzufügen"),
        title: T("Cloud Shield Profil jetzt hinzufügen"),
        onClick: () => {
            router.navigate(`#add-tenant-${accountId.value}.sms-secureDnsProfiles`)
        }
    })
)
</script>
