<script setup lang="ts">
import { T } from "@/classes/i18n"
import getterHelpers from "@/helpers/helpers.getters"
import { computed, onMounted } from "vue"

const store = getterHelpers.useStore()
const accountId = computed(() => {
    return store.state.session.activeAccountId
})
const licenseQuota = computed(() => {
    return accountId.value
        ? store.state.session.accounts[accountId.value].unifiedSecurity.licenseQuota
        : undefined
})
const totalLicenses = computed(() => {
    return (
        licenseQuota.value?.used.mdm +
            licenseQuota.value?.used.windows +
            licenseQuota.value?.used.secureDns +
            licenseQuota.value?.free || 0
    )
})

const mdmPercentage = computed(() => {
    let onePercent = totalLicenses.value / 100 || 0
    return licenseQuota.value?.used.mdm / onePercent || 0
})
const winPercentage = computed(() => {
    let onePercent = totalLicenses.value / 100 || 0
    return licenseQuota.value?.used.windows / onePercent || 0
})
const secureDnsPercentage = computed(() => {
    let onePercent = totalLicenses.value / 100 || 0
    return licenseQuota.value?.used.secureDns / onePercent || 0
})
const freePercentage = computed(() => {
    let onePercent = totalLicenses.value / 100 || 0
    return licenseQuota.value?.free / onePercent || 0
})

onMounted(async () => {})
defineExpose({
    licenseQuota
})
</script>

<template>
    <div id="license-graph">
        <div
            class="progress-bar progress-bar-sm"
            style="background-color: rgba(0, 0, 0, 0.3)"
            :data-progress="mdmPercentage + winPercentage + secureDnsPercentage"
        >
            <div
                class="bg-red"
                style="position: absolute; height: 4px; top: 0px"
                :style="{
                    width: mdmPercentage + '%'
                }"
            ></div>
            <div
                class="bg-green"
                style="position: absolute; height: 4px; top: 0px"
                :style="{
                    left: mdmPercentage + '%',
                    width: winPercentage + '%'
                }"
            ></div>
            <div
                class="bg-blue"
                style="position: absolute; height: 4px; top: 0px"
                :style="{
                    left: mdmPercentage + winPercentage + '%',
                    width: secureDnsPercentage + '%'
                }"
            ></div>
        </div>
        <p v-if="licenseQuota?.used.mdm">
            <i class="fa fa-circle color-red"></i> {{ T("Android / iOS / Andere VPNs:") }}
            {{ licenseQuota?.used.mdm || "0" }}
        </p>
        <p v-if="licenseQuota?.used.windows">
            <i class="fa fa-circle color-green"></i> {{ T("Windows VPN-Clients:") }}
            {{ licenseQuota?.used.windows || "0" }}
        </p>
        <p v-if="licenseQuota?.used.secureDns">
            <i class="fa fa-circle color-blue"></i> {{ T("Externe Geräte mit Cloud Shield:") }}
            {{ licenseQuota?.used.secureDns || "0" }}
        </p>
        <p :title="T('Verwaltete Geräte sind in Cloud Shield inkl.')">
            <i class="fa fa-circle" style="color: rgba(0, 0, 0, 0.3)"></i>
            {{ T("Freie Lizenz-Plätze:") }}
            {{ licenseQuota?.free || "0" }}
        </p>
    </div>
</template>

<style scoped lang="scss">
#main-sidebar nav #license-graph {
    color: #fff;
    padding-right: 16px;
}
</style>
