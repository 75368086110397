<script setup lang="ts">
import config from "@/classes/config"
import { T } from "@/classes/i18n"
import objectStores from "@/classes/init"
import products from "@/classes/objectTypes"
import getterHelpers from "@/helpers/helpers.getters"
import { computed, onMounted, ref, watch } from "vue"
import inputPin from "../inputtypes/input-pin.vue"

const props = defineProps<{
    properties: {
        topologyId: string
    }
}>()

const activeAccountId = computed(() => {
    return getterHelpers.useStore()?.state.session.activeAccountId || ""
})
const topologies = computed(() => {
    return (
        products.unifiedSecurityConsole.topologies
            .useStore?.()
            .getObjectStoreObjects(activeAccountId.value) || []
    )
})

const selectedTopology = computed(() => {
    return topologies.value.find((topology) => {
        return topology.id == props.properties.topologyId
    })
})

const selectedCoreUtmId = computed(() => {
    return selectedTopology.value?.data?.coreId
})

const coreUtmInfo = computed(() => {
    return config.canUseNewObjectType("uscUtms")
        ? products.unifiedSecurityConsole.uscUtms
              .useStore?.()
              .getObjectStoreObject(activeAccountId.value, selectedCoreUtmId.value || "")
        : objectStores.uscUtms.getObjectFromStore(
              activeAccountId.value,
              selectedCoreUtmId.value || ""
          )
})

const utmStates = computed(() => {
    return config.canUseNewObjectType("uscUtmStates")
        ? products.unifiedSecurityConsole.uscUtmStates
              .useStore?.()
              .getObjectStoreObjects(activeAccountId.value)
        : getterHelpers.useStore()?.getters.getObjects({
              accountId: activeAccountId.value,
              productType: "unifiedSecurityConsole",
              objectType: "ccutmStates"
          })
})

const corePinState = computed(() => {
    return coreUtmInfo.value?.pinState
})

const isCoreOnline = computed(() => {
    return (
        (utmStates.value || []).find((utmstate: any) => {
            return utmstate.deviceId == selectedCoreUtmId.value
        })?.online === true
    )
})
const hasCorePermission = computed(() => {
    return coreUtmInfo.value?.permissions?.manageVPNEnabled
})

const doOfflineSatellitesExist = computed(() => {
    let utmIds = products.unifiedSecurityConsole.topologies.view.getSatelliteUtmIdsForCoreUtm(
        selectedTopology.value,
        selectedCoreUtmId.value
    )
    return (utmStates.value || [])
        .filter((utmstate: any) => {
            return utmIds.indexOf(utmstate.deviceId) != -1
        })
        .some((utmstate: any) => {
            return utmstate.online == false
        })
})
const doSatellitesWithoutSunPermissionExist = computed(() => {
    let utmIds = products.unifiedSecurityConsole.topologies.view.getSatelliteUtmIdsForCoreUtm(
        selectedTopology.value,
        selectedCoreUtmId.value
    )
    return (
        config.canUseNewObjectType("uscUtms")
            ? products.unifiedSecurityConsole.uscUtms
                  .useStore?.()
                  .getObjectStoreObjects(activeAccountId.value) || []
            : objectStores.uscUtms.getObjectsFromStore(activeAccountId.value) || []
    )
        .filter((utm) => {
            return utmIds.indexOf(utm.utmId) != -1
        })
        .some((utm) => {
            return utm.permissions?.manageVPNEnabled == false
        })
})

const showRememberPin = ref(true)
const rememberPin = ref(false)
const pinStorageKey = computed(() => {
    return `utm_pin:${activeAccountId.value}:${selectedCoreUtmId.value}`
})

const onEnter = () => {
    document.getElementById("dialogbutton-1-publishConfig")?.click()
}

const errors = ref(<string[]>[])
const pinError = ref("")

const computedErrors = computed(() => {
    let result = errors.value
    if (corePinState.value?.locked == true) {
        result.push(
            T(
                "Die Websession PIN ist aufgrund mehrerer Fehleingaben gesperrt. Bitte schalten Sie die Websession PIN auf der Core-UTM wieder frei."
            )
        )
    } else if (corePinState.value?.enabled == false) {
        result.push(
            T(
                "Die Websession PIN ist auf der UTM deaktiviert. Bitte aktivieren Sie die PIN um die Konfiguration veröffentlichen zu können."
            )
        )
    }

    if (isCoreOnline.value === false) {
        result.push(
            T(
                "Die Core-UTM ist offline. Bitte stellen Sie sicher dass die Core-UTM erreichbar ist."
            )
        )
    }
    if (hasCorePermission.value === false) {
        result.push(
            T(
                "Die Core-UTM ist nicht für die Unified Network Console freigeschaltet. Bitte aktivieren Sie die Unified Network Console für die Core-UTM."
            )
        )
    }
    return result
})

const computedWarnings = computed(() => {
    let result: string[] = []
    if (doOfflineSatellitesExist.value == true) {
        result.push(
            T(
                "Nicht alle UTMs in der Konfiguration sind online. Unter Umständen müssen Sie die Veröffentlichung der Konfiguration erneut durchführen, wenn die betroffenen UTMs wieder online sind."
            )
        )
    }

    if (doSatellitesWithoutSunPermissionExist.value == true) {
        result.push(
            T(
                "Nicht alle UTMs in der Konfiguration sind für die Unified Network Console freigeschaltet. Bitte aktivieren Sie die Unified Network Console für alle UTMs aus dieser Konfiguration, um die Funktionalität auf allen UTMs nutzen zu können."
            )
        )
    }

    return result
})

const setError = (error: string) => {
    console.log(error)
    errors.value.push(error)
}
const setPinError = (error: string) => {
    pinError.value = error
}
const clearErrors = () => {
    errors.value = []
}
const clearPin = () => {
    pin.value = ""
}

const onSuccess = () => {
    if (rememberPin.value) {
        sessionStorage.setItem(pinStorageKey.value, pin.value)
    }
}

const pin = ref("")

const checkConditions = () => {
    if (computedErrors.value.length == 0 && pin.value.length == 6) {
        getterHelpers.useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled =
            false
    } else {
        getterHelpers.useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled =
            true
    }
}

watch(pin, () => {
    checkConditions()
})

watch(coreUtmInfo, () => {
    checkConditions()
})
watch(computedErrors, () => {
    checkConditions()
})

onMounted(async () => {
    const storageKey = sessionStorage.getItem(pinStorageKey.value)
    if (storageKey) {
        pin.value = storageKey
        showRememberPin.value = false
    }
    checkConditions()
})

defineExpose({
    pin,
    setError,
    setPinError,
    onSuccess,
    clearErrors,
    clearPin
})
</script>
<template>
    <div class="padding-xs">
        <p class="text-center margin-xs-b-2">
            {{
                T(
                    "The PIN of the core UTM $1 is required to publish the VPN configuration."
                ).replace("$1", coreUtmInfo?.utmname)
            }}
            {{
                T(
                    "To increase security for actions that could disrupt ongoing operations, the new authentication layer is mandatory from UTM version 12.5.2."
                )
            }}
            {{
                T(
                    "The PIN is identical to the websession PIN and is initially 000000 until the first login to the administration web interface."
                )
            }}
        </p>
        <template v-if="computedWarnings.length > 0">
            <div class="text-center">
                <template v-for="warning in computedWarnings">
                    <p class="label bg-yellow content">
                        <i class="fal fa-fw fa-bell"></i> {{ T(warning) }}
                    </p>
                    <br />
                </template>
            </div>
        </template>
        <template v-if="computedErrors.length > 0">
            <div class="text-center">
                <template v-for="error in computedErrors">
                    <p class="label bg-red content">
                        <i class="fal fa-fw fa-exclamation"></i> {{ T(error) }}
                    </p>
                    <br />
                </template>
            </div>
        </template>

        <div id="pinInputRow" class="margin-xs-t-4 margin-xs-b-2">
            <inputPin
                v-model="pin"
                :pinLength="6"
                :isDisabled="false"
                :numbersOnly="true"
                :focusOnMount="true"
                v-on:submit="onEnter"
                :hidePinOnMount="true"
                :canToggleVisibility="true"
            >
            </inputPin>
        </div>
        <div v-if="showRememberPin" class="row padding-xs-t">
            <div class="col-xs-24 text-center">
                <label class="form-field toggle checkbox">
                    <input
                        v-model="rememberPin"
                        id="login-rememberme"
                        type="checkbox"
                        class="toggle margin-xs-0"
                    />&nbsp;&nbsp;
                    <span></span>
                    {{ T("Remember PIN for the current session") }}
                </label>
            </div>
        </div>
        <template v-if="pinError.length > 0">
            <p class="text-center">
                <span class="label bg-red">
                    <i class="fal fa-fw fa-exclamation"></i> {{ T(pinError) }}
                </span>
            </p>
        </template>
    </div>
</template>
<style lang="scss">
.sms-table.table-next.small {
    td {
        padding: 8px;
        i.icon {
            margin-top: -3px;
        }
    }
}
.iconWrapper-publish-state {
    display: inline-block;
    width: 1.75em;
    height: 1.75em;
    line-height: 1.75em;
    text-align: center;
    border-radius: 50%;
    font-size: 0.9em;
}
#pinInputRow {
    display: flex;
    justify-content: center;
    justify-items: center;
}
</style>
