const scanPorts = [
    { port: 7, usedBy: "Echo-Service" },
    { port: 9, usedBy: "Discard-Server" },
    { port: 13, usedBy: "Daytime [RFC867]" },
    { port: 21, usedBy: "File Transfer Protocol [RFC 959]" },
    { port: 22, usedBy: "Secure Shell (SSH) Protocol [RFC 4251]" },
    { port: 23, usedBy: "Telnet" },
    { port: 25, usedBy: "SMTP" },
    { port: 26, usedBy: "RSFTP, alternate to port 25 SMTP" },
    { port: 37, usedBy: "TIME protocol [RFC 868] [RFC 956]" },
    { port: 53, usedBy: "Domain Name System (DNS)" },
    { port: 79, usedBy: "Finger" },
    { port: 80, usedBy: "Hyper Text Transfer Protocol (HTTP)" },
    { port: 81, usedBy: "Hyper Text Transfer Protocol (HTTP)" },
    { port: 88, usedBy: "Kerberos (v5)" },
    { port: 106, usedBy: "poppassd" },
    { port: 110, usedBy: "POP3" },
    { port: 111, usedBy: "Sun Remote Procedure Call" },
    { port: 113, usedBy: "IDENT" },
    { port: 119, usedBy: "Network News Transfer Protocol [RFC3977]" },
    { port: 135, usedBy: "Remote Procedure Call (RPC)" },
    { port: 139, usedBy: "NetBIOS" },
    { port: 143, usedBy: "IMAP (Internet Mail Access Protocol)" },
    { port: 144, usedBy: "Universal Management Architecture" },
    { port: 179, usedBy: "Border Gateway Protocol(BGP) [RFC 4960]" },
    { port: 199, usedBy: "SMUX" },
    { port: 389, usedBy: "Lightweight Directory Access Protocol (LDAP)" },
    { port: 443, usedBy: "HTTP protocol over TLS/SSL [RFC4960]" },
    { port: 444, usedBy: "Cisco Webex Teams, Simple Network Paging Protocol [RFC1568]" },
    { port: 445, usedBy: "Microsoft-DS Active Directory, Windows shares" },
    { port: 465, usedBy: "Outgoing SMTP Mail over SSL (SMTPS) [RFC 2487]" },
    { port: 513, usedBy: "Login via Telnet" },
    { port: 514, usedBy: "Shell(RSH), Syslog" },
    { port: 515, usedBy: "Printer Daemon" },
    { port: 543, usedBy: "Kerberos login" },
    { port: 544, usedBy: "Kerberos Remote Shell" },
    { port: 548, usedBy: "Apple Filing Protocol (AFP)" },
    { port: 554, usedBy: "Real Time Streaming Protocol (RTSP) Windows" },
    { port: 587, usedBy: "Outgoing SMTP Mail [RFC2476]" },
    { port: 631, usedBy: "Internet Printing Protocol" },
    { port: 646, usedBy: "Label Distribution Protocol  (LDP)" },
    { port: 873, usedBy: "QNAP NAS, Rsync" },
    { port: 990, usedBy: " FTP over TLS/SSL" },
    { port: 993, usedBy: "IMAP (Internet Mail Access Protocol)" },
    { port: 995, usedBy: "POP3 mail over SSL" },
    { port: 1025, usedBy: "Windows RPC" },
    { port: 1433, usedBy: "MsSQL-Server" },
    { port: 1494, usedBy: "Citrix ICA Terminal-Server" },
    { port: 1720, usedBy: "Microsoft NetMeeting" },
    { port: 1723, usedBy: "Point-to-Point Tunneling Protocol (PPTP)" },
    { port: 2001, usedBy: "Often use by trojans/backdoors" },
    { port: 2049, usedBy: "Network File System (NFS) [RFC 1813] [RFC5665]." },
    { port: 2121, usedBy: "FTP-Proxy" },
    { port: 2717, usedBy: "KnowShowGo P2P" },
    { port: 3000, usedBy: "Ruby on Rails applications, NodeJS etc." },
    { port: 3128, usedBy: "Squid HTTP-Proxy Standard" },
    { port: 3306, usedBy: "MySQL-Server" },
    { port: 3389, usedBy: "Windows Remote Desktop" },
    { port: 4899, usedBy: "Radmin remote administration tool" },
    { port: 5000, usedBy: "Universal Plug and Play (UPnP)" },
    { port: 5004, usedBy: "Cisco Webex Teams" },
    { port: 5009, usedBy: "Apple AirPort Admin Utility, Microsoft Windows Filesystem" },
    { port: 5060, usedBy: "Session Initiation Protocol (SIP)" },
    { port: 5101, usedBy: "Yahoo P2P Instant Messages" },
    { port: 5190, usedBy: "ICQ, AIM (AOL Instant Messenger), Apple iChat" },
    { port: 5432, usedBy: "PostgreSQL Database" },
    { port: 5631, usedBy: "PC-Anywhere" },
    { port: 5666, usedBy: "Nagios NRPE" },
    { port: 5800, usedBy: "Virtual Network Computing (VNC)" },
    { port: 5900, usedBy: "Virtual Network Computing (VNC)" },
    { port: 6000, usedBy: "X Window System" },
    { port: 6001, usedBy: "X Window System" },
    { port: 6178, usedBy: "Securepoint Operation Center (SOC)" },
    { port: 8000, usedBy: "Alternate HTTP port" },
    { port: 8008, usedBy: "Citrix virtual Desktops" },
    { port: 8080, usedBy: "HTTP-Proxy" },
    { port: 8081, usedBy: "HTTP-Proxy" },
    { port: 8110, usedBy: "Pop3Proxy" },
    { port: 8443, usedBy: "Alternate HTTP over TLS/SSL port" },
    { port: 8888, usedBy: "Alternate HTTP port" },
    { port: 9200, usedBy: "Elasticsearch Query & Adminitration" },
    { port: 9999, usedBy: "SOC Logcenter" },
    { port: 10000, usedBy: "Webmin - Web-based Linux admin tool" },
    { port: 11115, usedBy: "Securepoint UTM Administration" },
    { port: 49152, usedBy: "Microsoft Lync, P2P Torent, Apple AirPlay" }
]
export default scanPorts
