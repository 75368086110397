import encodingHelpers from "@/helpers/helpers.encoding"
import numberHelpers from "@/helpers/helpers.numbers"
import tenantHelpers from "@/helpers/helpers.tenants"
import timeHelpers from "@/helpers/helpers.time"
import requestHandler from "@/queries/requests"
import router from "@/router/router"
import { useStore } from "@/store/vuex.store"
import Button from "@/templates/components/button/button"
import apis from "../apis"
import config from "../config"
import {
    type GenericObject,
    GenericObjectStore,
    type GetPropertiesObjectList,
    type ItemlistDetail,
    type ItemListStatus,
    type Label,
    type MenuEntry,
    type shemaErrors
} from "../genericObjectStore"
import { T } from "../i18n"
import devLog from "../log"

export interface License extends GenericObject<License> {
    accountId: number
    accountName: string
    assignedUser: number
    dead: boolean
    deadDate: string
    deadTimestamp: number
    deviceId: string
    expirationDate: string
    expirationTimestamp: number
    expired: boolean
    expiredDays: number
    extendsLicenseId: null
    family: string
    id: number
    laasEnabled: true
    mobsec: boolean
    name: string
    serial: string
    spare: boolean
    state: string
    type: string
    uscMessagingEnabled: boolean
    uscMonitoringEnabled: boolean
    user: number
    uuid: string
    clusterUuid: string | null
    licenseScopes: string[]
    enrollmentLimit: number
    mspLicenseId: number
    isAllowedToSetLimit: boolean
}

export default class Licenses extends GenericObjectStore<License> {
    constructor() {
        super()
        this.settings.primaryKeyProperty = "id"
        this.settings.nameProperty.primary = "name"
        this.settings.productType = "unifiedSecurity"
        this.settings.objectType = "licenses"
        this.settings.appearance.iconClass = "fal fa-key"

        this.settings.appearance.text.singular = "License"
        this.settings.appearance.text.plural = "Licenses"
        this.settings.appearance.text.title = "Licenses"

        this.settings.apiInfo.listPath = "/tenants/{tenantDomain}/licenses"
        this.settings.apiInfo.objectListPropertyInResponse = "licenses"
        this.itemlist.hasCheckBox = false

        this.itemlist.getToolbar = (accountId, itemlistComponent) => {
            let toolBarEntries = []
            if (useStore()?.state.session.isReseller) {
                toolBarEntries.push({
                    icon: "fal fa-sync",
                    title: T("License sync"),
                    onClick: async () => {
                        await requestHandler.request(
                            "POST",
                            config.mgtApiUri + "/tenants/" + accountId + ".sms/licenses/synchronize"
                        )
                        itemlistComponent.refresh()
                    },
                    id: "licenseSync",
                    vIf: false
                })
            }
            toolBarEntries.push({
                icon: "fal fa-key",
                title: T("Show expired licenses"),
                onClick: async () => {
                    itemlistComponent.exposed.toggleExpiredLicenses()
                },
                class: itemlistComponent.exposed.showExpiredLicenses.value == true ? "active" : ""
            })
            return toolBarEntries
        }

        this.itemlist.isDisabled = (item): boolean => {
            return false
        }
        this.itemlist.isClickable = (accountId, item): boolean => {
            let result = true
            if (item.state == "expired") {
                result = false
            }
            return result
        }
        this.itemlist.onClick = (accountId: string, item) => {
            let objectId = item[this.settings.primaryKeyProperty] as string
            router.navigate(
                "#show-tenant-" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "-license-" +
                    objectId +
                    "-details"
            )
        }
        this.itemlist.getTitle = (item) => {
            return {
                title: item.name
            }
        }

        this.itemlist.getStatus = (accountId: string, item) => {
            let result: ItemListStatus = {
                color: "green",
                tooltip: {
                    title: "Valid",
                    text: "License status is valid"
                }
            }
            if (item.state == "expired") {
                result = {
                    color: "green",
                    tooltip: {
                        title: "Expired",
                        text: "This license has expired"
                    }
                }
            } else if (item.state != "valid") {
                result = {
                    color: "yellow",
                    tooltip: {
                        title: item.state,
                        text: "License status is " + item.state
                    }
                }
            }
            return result
        }

        this.itemlist.getMenuEntries = (accountId: string, item) => {
            let menuEntries: MenuEntry[] = []
            if (item) {
                let link: string =
                    (document.location.origin.indexOf("portal-dev") != -1
                        ? "https://devmy."
                        : "https://my.") +
                    "securepoint.de/2.0/licenses?search=" +
                    item.name

                menuEntries.push(
                    new Button({
                        title: T("Details"),
                        text: T("Details"),
                        icon: "fal fa-info-circle",
                        onClick: () => {
                            router.navigate(
                                "#show-tenant-" +
                                    tenantHelpers.getTenantDomain(accountId) +
                                    "-license-" +
                                    item.id +
                                    "-details"
                            )
                        }
                    })
                )

                menuEntries.push(
                    new Button({
                        title: T("Edit"),
                        text: T("Edit"),
                        link: link,
                        icon: "fal fa-edit",
                        target: "_blank"
                    })
                )
            }
            return menuEntries
        }

        this.itemlist.getLabels = (accountId: string, item) => {
            let result: Label[] = []
            if (item?.laasEnabled) {
                result.push({
                    title: T("USR") + " " + T("booked"),
                    text: T("USR") + " " + T("booked"),
                    class: "bg-green"
                })
            }
            if (item?.uscMessagingEnabled) {
                result.push({
                    title: T("USC") + " " + T("booked"),
                    text: T("USC") + " " + T("booked"),
                    class: "bg-green"
                })
            }
            if (item?.state != "valid") {
                result.push({
                    title: T(item?.state),
                    text: T(item?.state),
                    class: "bg-yellow"
                })
            }
            return result
        }

        this.itemlist.getDetails = (accountId: string, item?) => {
            let result: ItemlistDetail[] = []
            let licenceUser = item?.user
            if (
                ["MDM", "Mobile Security"].indexOf(item?.type) != -1 &&
                item?.enrollmentLimit != undefined
            ) {
                licenceUser = item.enrollmentLimit
            }
            result = [
                {
                    iconClass: "fal fa-fw fa-key",
                    title: T("Type"),
                    key: T("Type"),
                    value: item
                        ? item.type
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-user",
                    title: T("User"),
                    key: T("User"),
                    value: item
                        ? ["Mobile Security", "MDM"].indexOf(item?.type) != -1
                            ? item?.assignedUser + "/" + licenceUser
                            : licenceUser || ""
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-hashtag",
                    title: T("License") + " UUID",
                    key: T("License") + " UUID",
                    value: item
                        ? item.uuid || ""
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-clock",
                    title: T("Expires on"),
                    key: T("Expires on"),
                    value: item
                        ? timeHelpers.formatDate(
                              item?.expirationDate,
                              timeHelpers.getDateFormatI18n(false)
                          )
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                }
            ]

            return result
        }
        this.itemlist.sortingOptions = [
            {
                id: "name",
                text: "Name"
            },
            {
                id: "assignedUser",
                text: "Assigned Users"
            },
            {
                id: "state",
                text: "State"
            }
        ]
    }

    async getCountFromApi(accountId: string, updateLocalStore: boolean = true) {
        let result: number | Error = 0
        try {
            const response = await requestHandler.request(
                "GET",
                "/sms-mgt-api/api/" +
                    config.mgtApiVersionNext +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/licenses?props[]=null&select=data.count"
            )
            if (typeof response === "number") {
                result = response
                if (updateLocalStore) {
                    this.setCount(accountId, response)
                }
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error getting count for licenses")
            }
        } catch (e: any) {
            if (e instanceof Error) {
                result = new Error(e.message)
            } else {
                result = new Error("Error getting count for licenses")
            }
            devLog.log("LicensesStoreClass", result.message, result, "error")
        }
        return result
    }

    async getObjectsFromApi(
        accountId: string,
        props?: GetPropertiesObjectList,
        updateLocalStore: boolean = true,
        newApi?: boolean
    ): Promise<License[] | Error> {
        const tenantDomain = tenantHelpers.getTenantDomain(accountId)
        const propertiesString: string = props ? this.getPropertiesString(props) : ""
        let result: License[] | Error
        try {
            let response = await requestHandler.request(
                "GET",
                this.settings.apiInfo.urlNew +
                    (this.settings.apiInfo.listPath || this.settings.apiInfo.objectPath).replace(
                        "{tenantDomain}",
                        tenantDomain
                    ) +
                    propertiesString
            )
            response = apis.parseApiResponse(response)

            if (
                response[this.settings.apiInfo.objectListPropertyInResponse as string] != undefined
            ) {
                result = response[
                    this.settings.apiInfo.objectListPropertyInResponse as string
                ] as License[]
            } else {
                throw "Error getting objects"
            }

            if (propertiesString.indexOf("with_subtenants") == -1 && Array.isArray(result)) {
                if (updateLocalStore) {
                    this.addOrUpdateObjectsInStore(accountId, result) // Add Licenses to store
                }
                return result
            } else {
                let unmappedResult = result
                let mappedResult: { [accountId: string]: License[] } = {}

                unmappedResult.forEach((license) => {
                    if (!mappedResult[license.accountId]) {
                        mappedResult[license.accountId] = [] as License[]
                    }
                    mappedResult[license.accountId].push(license)
                })

                for (let thisAccountId in mappedResult) {
                    if (updateLocalStore) {
                        this.addOrUpdateObjectsInStore(thisAccountId, mappedResult[thisAccountId]) // Add Licenses to store
                    }
                }
                return unmappedResult
            }
        } catch (e: any) {
            devLog.log("GenericStoreClass", e.message, e, "error")
            throw e as Error
        }
    }

    async getObjectFromApi(
        accountId: string,
        objectId: string,
        props?: GetPropertiesObjectList,
        updateLocalStore: boolean = true
    ) {
        let result: License | Error
        try {
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            const response = await requestHandler.request(
                "GET",
                this.settings.apiInfo.urlNew +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/licenses/" +
                    encodingHelpers.encodeURI(objectId) +
                    propertiesString
            )
            if (response[this.settings.primaryKeyProperty]) {
                result = response as License
                if (updateLocalStore) {
                    this.addOrUpdateObjectsInStore(accountId, result) // Add license to store
                }
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error getting license")
            }
        } catch (e: any) {
            if (e instanceof Error) {
                result = new Error(e.message)
            } else {
                result = new Error("Error getting license")
            }

            devLog.log("LicensesStoreClass", result.message, result, "error")
        }
        return result
    }

    async updateEnrollmentlimitFromLicense(
        accountId: string,
        licenseId: string,
        enrollmentLimit: number
    ) {
        let result: License | Error | shemaErrors
        let response
        try {
            const tenantDomain = tenantHelpers.getTenantDomain(accountId)
            const payload = { enrollmentLimit: enrollmentLimit }
            response = await requestHandler.request(
                "PUT",
                this.settings.apiInfo.urlNew +
                    "/tenants/" +
                    tenantDomain +
                    "/licenses/" +
                    licenseId,
                payload
            )
            response = apis.parseApiResponse(response)
            if (response.errors) {
                throw response.errors
            } else if (response.error) {
                throw new Error(response.error)
            }
            result = response
        } catch (e: any) {
            if (e instanceof Error) {
                result = new Error(e.message)
                devLog.log("LicenseStoreClass", (<Error>result).message, result, "error")
            } else {
                result = e as shemaErrors
                devLog.log("LicenseStoreClass", "Error updating License", result, "error")
            }
        }
        return apis.parseApiResponse(result)
    }

    async deleteEntrollmentlimitFromLicense(accountId: string, licenseId: string) {
        let result: License | Error | shemaErrors
        let response: any
        try {
            const tenantDomain = tenantHelpers.getTenantDomain(accountId)
            response = await requestHandler.request(
                "DELETE",
                this.settings.apiInfo.urlNew +
                    "/tenants/" +
                    tenantDomain +
                    "/licenses/" +
                    licenseId +
                    "/property",
                "enrollmentLimit"
            )
            response = apis.parseApiResponse(response)
            if (response.errors) {
                throw response.errors
            } else if (response.error) {
                throw new Error(response.error)
            }
            result = response
        } catch (e: any) {
            if (e instanceof Error) {
                result = new Error(e.message)
                devLog.log("LicenseStoreClass", (<Error>result).message, result, "error")
            } else {
                result = e as shemaErrors
                devLog.log("LicenseStoreClass", "Error updating License", result, "error")
            }
        }
        return apis.parseApiResponse(result)
    }
}
