<script setup lang="ts">
import { T } from "@/classes/i18n"
import getterHelpers from "@/helpers/helpers.getters"
import tenantHelpers from "@/helpers/helpers.tenants"

import { ActionTypes, useStore } from "@/store/vuex.store"
import { computed } from "vue"

const featureStore = getterHelpers.useFeatureStore()
const features = computed(() => featureStore?.getters.getFeatureListFromStore?.())
const activeAccountId = computed(() => {
    return useStore().state.session.activeAccountId
})

const userInfo = computed(() => {
    return useStore().state.session.userInfo || {}
})

const account = computed(() => {
    let thisComponent: any = this
    return (
        useStore().state.session.userInfo.scopes.list_accounts.filter((account: any) => {
            return activeAccountId.value == account.accountid
        })[0] || undefined
    )
})

const timezone = computed({
    get: function () {
        return useStore().state.session.accounts[activeAccountId.value || ""]?.unifiedSecurity
            ?.account?.timezone
    },
    set: function (timezone: any) {
        useStore().dispatch(ActionTypes.setTenantProperty, {
            accountId: activeAccountId.value,
            property: "timezone",
            value: timezone
        })
    }
})
</script>
<template>
    <section class="padding-xs padding-md-2 padding-lg-4 pagecontent">
        <div>
            <div class="row">
                <div class="col-xs-24 padding-xs media-screen-only">
                    <div
                        class="box-shadow bg-white padding-xs-2"
                        v-if="tenantHelpers.hasAccountRole('__SUPPORT__')"
                    >
                        <h3>Extended Info <small>(Support user only)</small></h3>
                        <div class="row padding-xs-y">
                            <div class="col-xs-24 col-sm-12 col-lg-6 col-xl-4">
                                <strong> Account ID </strong>
                            </div>
                            <div class="col-xs-24 col-sm-12 col-lg-18 col-xl-20">
                                {{ activeAccountId }}
                            </div>
                        </div>
                        <div class="row padding-xs-y">
                            <div class="col-xs-24 col-sm-12 col-lg-6 col-xl-4">
                                <strong> Roles </strong>
                            </div>
                            <div class="col-xs-24 col-sm-12 col-lg-18 col-xl-20">
                                <template v-for="(role, i) in userInfo.roles">
                                    <span class="label margin-xs-r">{{ role }}</span>
                                </template>
                            </div>
                        </div>
                        <div class="row padding-xs-y">
                            <div class="col-xs-24 col-sm-12 col-lg-6 col-xl-4">
                                <strong> Active Featureflags </strong>
                            </div>
                            <div class="col-xs-24 col-sm-12 col-lg-18 col-xl-20">
                                <template v-for="feature in features">
                                    <span class="label margin-xs-r">{{ feature }}</span
                                    ><br />
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-24 padding-xs media-screen-only" v-if="account != undefined">
                    <div class="box-shadow bg-white padding-xs-2">
                        <h3>Accountinfo</h3>
                        <div class="row padding-xs-y">
                            <div class="col-xs-24 col-sm-12 col-lg-6 col-xl-4">
                                <strong> {{ T("Customer No.") }}: </strong>
                            </div>
                            <div class="col-xs-24 col-sm-12 col-lg-18 col-xl-20">
                                {{ account.account_no }}
                            </div>
                        </div>
                        <div class="row padding-xs-y">
                            <div class="col-xs-24 col-sm-12 col-lg-6 col-xl-4">
                                <strong> {{ T("Accountname") }}: </strong>
                            </div>
                            <div class="col-xs-24 col-sm-12 col-lg-18 col-xl-20">
                                {{ account.accountname }}
                            </div>
                        </div>
                        <div class="row padding-xs-y">
                            <div class="col-xs-24 col-sm-12 col-lg-6 col-xl-4">
                                <strong> {{ T("Street") }}: </strong>
                            </div>
                            <div class="col-xs-24 col-sm-12 col-lg-18 col-xl-20">
                                {{ account.bill_street }}
                            </div>
                        </div>
                        <div class="row padding-xs-y">
                            <div class="col-xs-24 col-sm-12 col-lg-6 col-xl-4">
                                <strong> {{ T("City") }}: </strong>
                            </div>
                            <div class="col-xs-24 col-sm-12 col-lg-18 col-xl-20">
                                {{ account.bill_code }} {{ account.bill_city }}
                            </div>
                        </div>
                        <div class="row padding-xs-y">
                            <div class="col-xs-24 col-sm-12 col-lg-6 col-xl-4">
                                <strong> {{ T("State") }}: </strong>
                            </div>
                            <div class="col-xs-24 col-sm-12 col-lg-18 col-xl-20">
                                {{ account.bill_state }}
                            </div>
                        </div>
                        <div class="row padding-xs-y">
                            <div class="col-xs-24 col-sm-12 col-lg-6 col-xl-4">
                                <strong> {{ T("Country") }}: </strong>
                            </div>
                            <div class="col-xs-24 col-sm-12 col-lg-18 col-xl-20">
                                {{ account.bill_country }}
                            </div>
                        </div>
                        <div class="row padding-xs-y">
                            <div class="col-xs-24 col-sm-12 col-lg-6 col-xl-4">
                                <strong> {{ T("E-Mail") }}: </strong>
                            </div>
                            <div class="col-xs-24 col-sm-12 col-lg-18 col-xl-20">
                                {{ account.email }}
                            </div>
                        </div>
                        <div class="row padding-xs-y">
                            <div class="col-xs-24 col-sm-12 col-lg-6 col-xl-4">
                                <strong> {{ T("Reseller") }}: </strong>
                            </div>
                            <div class="col-xs-24 col-sm-12 col-lg-18 col-xl-20">
                                {{ account.parent_accountname }}
                            </div>
                        </div>

                        <div class="row padding-xs-y">
                            <div class="col-xs-24 col-sm-12 col-lg-6 col-xl-4">
                                <label for="tenant-info-timezone" class="control-label">
                                    {{ T("Timezone") }}
                                </label>
                            </div>
                            <div class="col-xs-24 col-sm-12 col-lg-18 col-xl-20">
                                <select
                                    id="tenant-info-stats-timezone"
                                    class="form-control"
                                    v-model="timezone"
                                >
                                    <option value="-12:00">
                                        {{ T("(GMT -12:00) Eniwetok, Kwajalein") }}
                                    </option>
                                    <option value="-11:00">
                                        {{ T("(GMT -11:00) Midway Island, Samoa") }}
                                    </option>
                                    <option value="-10:00">{{ T("(GMT -10:00) Hawaii") }}</option>
                                    <option value="-09:00">{{ T("(GMT -9:00) Alaska") }}</option>
                                    <option value="-08:00">
                                        {{ T("(GMT -8:00) Pacific Time (US &amp; Canada") }})
                                    </option>
                                    <option value="-07:00">
                                        {{ T("(GMT -7:00) Mountain Time (US &amp; Canada") }})
                                    </option>
                                    <option value="-06:00">
                                        {{
                                            T(
                                                "(GMT -6:00) Central Time (US &amp; Canada), Mexico City"
                                            )
                                        }}
                                    </option>
                                    <option value="-05:00">
                                        {{
                                            T(
                                                "(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima"
                                            )
                                        }}
                                    </option>
                                    <option value="-04:00">
                                        {{
                                            T("(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz")
                                        }}
                                    </option>
                                    <option value="-03:00">
                                        {{ T("(GMT -3:00) Brazil, Buenos Aires, Georgetown") }}
                                    </option>
                                    <option value="-02:00">
                                        {{ T("(GMT -2:00) Mid-Atlantic") }}
                                    </option>
                                    <option value="-01:00">{{ T("(GMT -1:00) Azores") }}</option>
                                    <option value="+00:00">
                                        {{ T("(GMT) Western Europe Time") }}
                                    </option>
                                    <option value="+01:00">
                                        {{ T("(GMT +1:00) Central European Time") }}
                                    </option>
                                    <option value="+02:00">
                                        {{ T("(GMT +2:00) Central European Summer Time") }}
                                    </option>
                                    <option value="+02:00">
                                        {{ T("(GMT +2:00) Eastern European Time") }}
                                    </option>
                                    <option value="+03:00">
                                        {{
                                            T("(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg")
                                        }}
                                    </option>
                                    <option value="+04:00">
                                        {{ T("(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi") }}
                                    </option>
                                    <option value="+05:00">
                                        {{
                                            T(
                                                "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent"
                                            )
                                        }}
                                    </option>
                                    <option value="+06:00">
                                        {{ T("(GMT +6:00) Almaty, Dhaka, Colombo") }}
                                    </option>
                                    <option value="+07:00">
                                        {{ T("(GMT +7:00) Bangkok, Hanoi, Jakarta") }}
                                    </option>
                                    <option value="+08:00">
                                        {{ T("(GMT +8:00) Beijing, Perth, Singapore, Hong Kong") }}
                                    </option>
                                    <option value="+09:00">
                                        {{ T("(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk") }}
                                    </option>
                                    <option value="+10:00">
                                        {{ T("(GMT +10:00) Eastern Australia, Guam, Vladivostok") }}
                                    </option>
                                    <option value="+11:00">
                                        {{
                                            T(
                                                "(GMT +11:00) Magadan, Solomon Islands, New Caledonia"
                                            )
                                        }}
                                    </option>
                                    <option value="+12:00">
                                        {{
                                            T("(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka")
                                        }}
                                    </option>
                                    <option value="+13:00">
                                        {{ T("(GMT +13:00) Apia, Nukualofa") }}
                                    </option>
                                    <option value="+14:00">
                                        {{ T("(GMT +14:00) Line Islands, Tokelau") }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
