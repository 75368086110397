import apis from "@/classes/apis"
import config from "@/classes/config"
import { T } from "@/classes/i18n"
import devLog from "@/classes/log"
import jsonHelpers from "@/helpers/helpers.json"
import mixinHelpers from "@/helpers/helpers.mixins"
import numberHelpers from "@/helpers/helpers.numbers"
import tenantHelpers from "@/helpers/helpers.tenants"
import timeHelpers from "@/helpers/helpers.time"
import queries from "@/queries/queries"
import requestHandler from "@/queries/requests"
import router from "@/router/router"
import { useStore } from "@/store/vuex.store"
import Button from "@/templates/components/button/button"
import ObjectType, {
    type ItemlistItemStatus,
    type Label,
    type MenuEntry,
    type ObjectTypeObject,
    type ObjectTypePayload
} from "../../objectType"

export interface License extends ObjectType<License> {
    accountId: number
    accountName: string
    assignedUser: number
    dead: boolean
    deadDate: string
    deadTimestamp: number
    deviceId: string
    expirationDate: string
    expirationTimestamp: number
    expired: boolean
    expiredDays: number
    extendsLicenseId: null
    family: string
    id: number
    laasEnabled: true
    mobsec: boolean
    name: string
    serial: string
    spare: boolean
    state: string
    tenantDomain: string
    type: string
    uscMessagingEnabled: boolean
    uscMonitoringEnabled: boolean
    user: number
    uuid: string
    licenseScopes: string[]
    enrollmentLimit: number
    clusterUuid: string | null
    mspLicenseId: number
    isAllowedToSetLimit: boolean
}

class Licenses extends ObjectType<License> {
    constructor(payload: ObjectTypePayload<License>) {
        super(payload)
        this.queries.getObjectsFromApi = async (
            accountId,
            customerId?,
            props?,
            updateStore = true
        ) => {
            let store = this.useStore?.().getObjectStore(accountId)
            let isRequestable = (function () {
                return store?.gettingObjects == false
            })()
            if ((store && isRequestable) || store == undefined) {
                if (store) {
                    store.gettingObjects = true
                    store.objectsTimestamp = timeHelpers.getUnixTimeStamp()
                }
                accountId = tenantHelpers.getAccountId(accountId)
                const propertiesString: string = props ? this.getPropertiesString(props) : ""
                let result: License[] | Error
                try {
                    let response = await requestHandler.request(
                        "GET",
                        this.getListUrl(accountId) + propertiesString
                    )
                    response = apis.parseApiResponse(response)
                    if (
                        response[this.options.apiInfo.getObjectListResponseProperty as string] !=
                        undefined
                    ) {
                        result = response[
                            this.options.apiInfo.getObjectListResponseProperty as string
                        ] as License[]
                    } else {
                        throw "Error getting objects"
                    }
                    if (
                        propertiesString.indexOf("with_subtenants") == -1 &&
                        Array.isArray(result)
                    ) {
                        if (updateStore) {
                            this.useStore?.()?.setObjectTypeObjects(accountId, result) // Add Licenses to store
                        }
                        if (store) {
                            store.gettingObjects = false
                        }
                        return result
                    } else {
                        let unmappedResult = result
                        let mappedResult: { [accountId: string]: License[] } = {}
                        unmappedResult.forEach((license) => {
                            if (!mappedResult[license.accountId]) {
                                mappedResult[license.accountId] = [] as License[]
                            }
                            mappedResult[license.accountId].push(license)
                        })
                        for (let thisAccountId in mappedResult) {
                            if (updateStore) {
                                this.useStore?.()?.setObjectTypeObjects(
                                    thisAccountId,
                                    mappedResult[thisAccountId]
                                ) // Add Licenses to store
                            }
                        }
                        if (store) {
                            store.gettingObjects = false
                        }
                        return unmappedResult
                    }
                } catch (e: any) {
                    if (store) {
                        store.gettingObjects = false
                    }
                    devLog.log("GenericStoreClass", e.message, e, "error")
                    throw e as Error
                }
            } else {
                return this.useStore?.().getObjectStoreObjects(accountId) || []
            }
        }
        this.queries.updateEnrollmentlimitFromLicense = async (
            accountId: string,
            licenseId: string,
            enrollmentLimit: number
        ) => {
            let result: License | Error
            let response
            try {
                accountId = tenantHelpers.getAccountId(accountId)
                const payload = { enrollmentLimit: enrollmentLimit }
                response = await requestHandler.request(
                    "PUT",
                    this.getSingleObjectUrl(accountId, licenseId),
                    payload
                )
                response = apis.parseApiResponse(response)
                if (response.errors) {
                    throw response.errors
                } else if (response.error) {
                    throw new Error(response.error)
                }
                this.useStore?.().setObjectTypeObjectProperty(
                    accountId,
                    licenseId,
                    enrollmentLimit,
                    "enrollmentLimit"
                )
                result = response
            } catch (e: any) {
                if (e instanceof Error) {
                    result = new Error(e.message)
                    devLog.log("LicenseStoreClass", (<Error>result).message, result, "error")
                } else {
                    devLog.log("LicenseStoreClass", "Error updating License", e, "error")
                    throw e
                }
            }
            return apis.parseApiResponse(result)
        }
        this.queries.deleteEntrollmentlimitFromLicense = async (
            accountId: string,
            licenseId: string
        ) => {
            let result: true | Error
            let response: any
            try {
                accountId = tenantHelpers.getAccountId(accountId)
                response = await requestHandler.request(
                    "DELETE",
                    this.getSingleObjectUrl(accountId, licenseId) + "/property",
                    "enrollmentLimit"
                )
                response = apis.parseApiResponse(response)
                if (response.errors) {
                    throw response.errors
                } else if (response.error) {
                    throw new Error(response.error)
                }
                let license = this.useStore?.().getObjectStoreObject(accountId, licenseId)
                if (license) {
                    this.useStore?.().setObjectTypeObjectProperty(
                        accountId,
                        licenseId,
                        license.user,
                        "enrollmentLimit"
                    )
                }
                result = true
            } catch (e: any) {
                if (e instanceof Error) {
                    result = new Error(e.message)
                    devLog.log("LicenseStoreClass", (<Error>result).message, result, "error")
                } else {
                    devLog.log("LicenseStoreClass", "Error updating License", e, "error")
                    throw e
                }
            }
            return apis.parseApiResponse(result)
        }
        this.itemlist.getToolbarButtons = (accountId, itemlistComponent) => {
            let toolBarEntries: any[] = []
            if (window.app?.vue?.$store?.state.session.isReseller) {
                toolBarEntries.push({
                    icon: "fal fa-sync",
                    title: T("License sync with RSP"),
                    onClick: async () => {
                        await requestHandler.request(
                            "POST",
                            config.mgtApiUri + "/tenants/" + accountId + ".sms/licenses/synchronize"
                        )
                        itemlistComponent.exposed.refresh()
                    },
                    id: "licenseSync",
                    vIf: false,
                    hoverText: T(
                        "This will trigger the license synchronization with the reseller portal"
                    )
                })
            }
            toolBarEntries.push({
                icon: "fal fa-clock",
                title: itemlistComponent.exposed.showExpiredLicenses.value
                    ? T("Hide expired licenses")
                    : T("Show expired licenses"),
                onClick: async () => {
                    itemlistComponent.exposed.toggleExpiredLicenses()
                },
                class: itemlistComponent.exposed.showExpiredLicenses.value == true ? "active" : ""
            })

            return toolBarEntries
        }
        this.itemlist.getSortingOptions = (accountId, itemlistComponent) => [
            {
                id: "name",
                text: "Name"
            },
            {
                id: "assignedUser",
                text: T("Assigned Users")
            },
            {
                id: "state",
                text: T("Status")
            }
        ]

        this, (this.itemlistItem.hasCheckbox = (item) => false)
        this.itemlistItem.getDisabledState = (item): boolean => {
            return false
        }
        this.itemlistItem.isClickable = (accountId, item): boolean => {
            let result = true
            if (item.state == "expired") {
                result = false
            }
            return result
        }
        this.itemlistItem.onClick = (accountId, item) => {
            let primaryKey = this.options.objectTypeInfo.primaryKeyProperty
                .property as keyof ObjectTypeObject<License>
            if (primaryKey) {
                let objectId = item[primaryKey]
                router.navigate(
                    "#show-tenant-" +
                        tenantHelpers.getTenantDomain(accountId) +
                        "-license-" +
                        objectId +
                        "-details"
                )
            }
        }
        this.itemlistItem.getTitle = (item) => {
            return {
                title: item?.name
            }
        }
        this.itemlistItem.getStatus = (accountId, item) => {
            let result: ItemlistItemStatus = {
                color: "green",
                tooltip: {
                    title: "Valid",
                    text: "License status is valid"
                }
            }
            if (item.state == "expired") {
                result = {
                    color: "green",
                    tooltip: {
                        title: "Expired",
                        text: "This license has expired"
                    }
                }
            } else if (item.state != "valid") {
                result = {
                    color: "yellow",
                    tooltip: {
                        title: item.state,
                        text: "License status is " + item.state
                    }
                }
            }
            return result
        }
        this.itemlistItem.getMenuEntries = (accountId, item) => {
            let menuEntries: MenuEntry[] = []
            if (item) {
                let link: string =
                    (document.location.origin.indexOf("portal-dev") != -1
                        ? "https://devmy."
                        : "https://my.") +
                    "securepoint.de/2.0/licenses?search=" +
                    item.name

                if (item?.state == "valid") {
                    menuEntries.push(
                        new Button({
                            title: T("Details"),
                            text: T("Details"),
                            icon: "fal fa-info-circle",
                            onClick: () => {
                                router.navigate(
                                    "#show-tenant-" +
                                        tenantHelpers.getTenantDomain(accountId) +
                                        "-license-" +
                                        item.id +
                                        "-details"
                                )
                            }
                        })
                    )
                }

                menuEntries.push(
                    new Button({
                        title: T("Edit"),
                        text: T("Edit"),
                        link: link,
                        icon: "fal fa-edit",
                        target: "_blank"
                    })
                )

                if (
                    item &&
                    (item.type == "Mobile Security" || item.type == "MDM") &&
                    item.uuid &&
                    item.assignedUser < mixinHelpers.getLicenseLimit(item) &&
                    !item.expired &&
                    !item.dead
                ) {
                    menuEntries.push(
                        new Button({
                            title: T("Assign as iOS default"),
                            text: T("Assign as iOS default"),
                            icon: "fal fa-key",
                            onClick: async () => {
                                try {
                                    await queries.mobileSecurity.setDefaultDeviceLicenseUUID(
                                        accountId,
                                        item.uuid,
                                        true
                                    )
                                } catch (e: unknown) {
                                    console.error(e)
                                }
                            }
                        })
                    )
                }
            }
            return menuEntries
        }
        this.itemlistItem.getLabels = (accountId, item) => {
            let result: Label[] = []
            const defaultDeviceLicenseUUID: string =
                useStore()?.state?.session?.accounts[accountId || ""]?.mobileSecurity?.settings
                    ?.defaultDeviceLicenseUUID || ""

            if (item?.laasEnabled) {
                result.push({
                    title: T("USR") + " " + T("booked"),
                    text: T("USR") + " " + T("booked"),
                    class: "bg-green",
                    icon: "fa fa-info-circle"
                })
            }
            if (item?.uscMessagingEnabled) {
                result.push({
                    title: T("USC") + " " + T("booked"),
                    text: T("USC") + " " + T("booked"),
                    class: "bg-green",
                    icon: "fa fa-info-circle"
                })
            }
            if (item?.state != "valid") {
                result.push({
                    title: T(item?.state),
                    text: T(item?.state),
                    class: "bg-yellow",
                    icon: "fa fa-bell"
                })
            }
            if (item?.uuid && defaultDeviceLicenseUUID == item?.uuid) {
                result.push({
                    title: T("iOS Default"),
                    text: T("iOS Default"),
                    class: "bg-blue",
                    icon: "fa fa-info-circle"
                })
            }
            return result
        }
        this.itemlistItem.getDetails = (accountId, item?) => {
            let licenceUser = item?.user
            if (
                ["MDM", "Mobile Security"].indexOf(item?.type || "") != -1 &&
                item?.enrollmentLimit != undefined
            ) {
                licenceUser = item.enrollmentLimit
            }
            return [
                {
                    iconClass: "fal fa-fw fa-key",
                    title: T("Type"),
                    key: T("Type"),
                    value: item
                        ? item.type
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-user",
                    title: T("User"),
                    key: T("User"),
                    value: item
                        ? ["Mobile Security", "MDM"].indexOf(item?.type) != -1
                            ? item?.assignedUser + "/" + licenceUser
                            : licenceUser || ""
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-hashtag",
                    title: T("License") + " UUID",
                    key: T("License") + " UUID",
                    value: item
                        ? item.uuid || ""
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-clock",
                    title: T("Expires on"),
                    key: T("Expires on"),
                    value: item
                        ? timeHelpers.formatDate(
                              item?.expirationDate,
                              timeHelpers.getDateFormatI18n(false)
                          )
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                }
            ]
        }
    }
    public helpers = {
        hasLicense: (
            accountid: string,
            licenseName: string,
            state: string | undefined = undefined
        ) => {
            let result = false
            accountid = tenantHelpers.getAccountId(accountid)

            let licenses = config.canUseNewObjectType("licenses")
                ? this.useStore?.().getObjectStoreObjects(accountid)
                : window.app?.vue?.$store?.state.session.accounts[accountid].unifiedSecurity
                      .licenses.items
            let license: License
            if (licenses) {
                for (let i: number = 0; licenses.length > i; i++) {
                    license = licenses[i]
                    if (state ? license.state == state : true) {
                        if (licenseName == "laas") {
                            if (
                                license.laasEnabled == true ||
                                license.licenseScopes.indexOf("laas_01") != -1
                            ) {
                                result = true
                                break
                            }
                        } else if (["uscMessaging", "uscMonitoring"].indexOf(licenseName) != -1) {
                            if (
                                jsonHelpers.getObjectProperty(license, licenseName + "_enabled") ==
                                    true ||
                                jsonHelpers.getObjectProperty(license, licenseName + "Enabled") ==
                                    true
                            ) {
                                result = true
                                break
                            }
                        } else if (["unifiedSecurityConsole"].indexOf(licenseName) != -1) {
                            if (
                                jsonHelpers.getObjectProperty(license, "uscMessagingEnabled") ==
                                    true ||
                                jsonHelpers.getObjectProperty(license, "uscMonitoringEnabled") ==
                                    true
                            ) {
                                result = true
                                break
                            }
                        } else {
                            if (
                                jsonHelpers.getObjectProperty(license, "type") == licenseName ||
                                jsonHelpers.getObjectProperty(license, "type") == licenseName
                            ) {
                                result = true
                                break
                            }
                        }
                    }
                }
            }
            return result
        },
        isLicenseValid: function (license: License) {
            return license.state == "valid"
        },
        isLicenseExpired: function (license: License) {
            return license.state == "expired"
        },
        hasOneOfLicenses: (
            accountid: string,
            licenseNames: string[],
            state: string | undefined = undefined
        ) => {
            let result = false
            accountid = tenantHelpers.getAccountId(accountid)
            for (let i: number = 0; (licenseNames || []).length > i; i++) {
                if (this.helpers.hasLicense(accountid, licenseNames[i], state)) {
                    result = true
                    break
                }
            }
            return result
        }
    }
}

const licenses = new Licenses({
    objectType: "licenses",
    productType: "unifiedSecurity",
    slug: "licenses",
    hasStore: true,
    appearance: {
        iconClass: "fal fa-key",
        text: {
            plural: "Licenses",
            title: "Licenses",
            sidebarName: "Licenses",
            singular: "License"
        },
        color: "red",
        showInSidebar: true,
        showOnDashboard: true
    },
    objectTypeInfo: {
        primaryKeyProperty: {
            property: "id",
            pathToPrimaryProperty: undefined
        },
        nameProperty: {
            primary: "name",
            pathToPrimaryProperty: undefined,
            secondary: undefined,
            pathToSecondaryProperty: undefined
        }
    },
    apiInfo: {
        url: "/sms-mgt-api/api/2.0",
        getCountGETProperties: "?props[]=null&select=data.count",
        // GET
        getObjectListResponseProperty: "licenses",
        getObjectListMethod: "GET",
        getObjectListPath: "/tenants/{tenantDomain}/licenses",
        // Update
        updateObjectMethod: "PUT",
        updateObjectPath: "/tenants/{tenantDomain}/licenses/{objectId}"
    }
})

export default licenses
