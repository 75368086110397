<template>
    <template v-if="loading == true">
        <div class="text-size-3 text-center padding-xs-t-4">
            <div class="text-size-2">
                <loader class="color-red" />
            </div>
        </div>
        <div class="text-center padding-xs-t-2" style="opacity: 0.8">
            <span>{{ T(loaderText) }} </span>
        </div>
    </template>
    <template v-else>
        <template v-if="errors.length > 0">
            <div v-for="error of errors" class="alert notification content bg-red text-center">
                <i class="fal fa-fw fa-exclamation-triangle"></i> {{ T(error) }}
            </div>
            <br />
        </template>

        <div class="row padding-xs-y form-group border-bottom">
            <div class="first col-xs-24 col-lg-5">
                <label class="control-label inputname">
                    {{ T("Profil") }}
                </label>
            </div>
            <div class="input col-xs-24 col-lg-10">
                <span class="form-field margin-xs-b-0">
                    <input-vue
                        v-model="selectedProfile"
                        :selectOptions="profileSelectOptions"
                        :placeholder="T('Profil')"
                        :multiple="false"
                    />
                </span>
            </div>
            <div class="desc col-xs-24 col-lg-9">
                <p class="input-description">
                    {{ T("The profile for which an invitation is to be sent.") }}
                </p>
            </div>
        </div>

        <div class="row padding-xs-y form-group border-bottom">
            <div class="first col-xs-24 col-lg-5">
                <label class="control-label inputname">
                    {{ T("Email-Recipients") }}
                </label>
            </div>
            <div class="input col-xs-24 col-lg-10">
                <span class="form-field margin-xs-b-0">
                    <input-vue
                        v-model="selectedUsers"
                        :selectOptions="userSelectOptions"
                        :placeholder="T('Email-Recipients')"
                        :multiple="true"
                        :tags="true"
                        id="emails"
                    />
                </span>
            </div>
            <div class="desc col-xs-24 col-lg-9">
                <p class="input-description">
                    {{ T("The recipients who should receive the invitation.") }}
                </p>
            </div>
        </div>

        <div class="row padding-xs-y form-group">
            <div class="first col-xs-24 col-lg-5">
                <label class="control-label inputname">
                    {{ T("Configure device names") }}
                </label>
            </div>
            <div class="input col-xs-24 col-lg-10">
                <label class="form-field margin-xs-b-0 checkbox toggle">
                    <input type="checkbox" class="checkbox" v-model="applyUserName" />
                    <span />
                </label>
            </div>
            <div class="desc col-xs-24 col-lg-9">
                <p class="input-description">
                    {{
                        T(
                            "You can optionally preconfigure the device names in the invitation. If no device name has been set, the device uses a default name."
                        )
                    }}
                </p>
            </div>
        </div>

        <div
            v-if="applyUserName"
            v-for="user in selectedUsers"
            :key="user"
            class="row padding-xs-y form-group"
        >
            <div class="col-xs-24 col-lg-5">
                <label class="control-label inputname input-custom-user">
                    {{ user }}
                </label>
            </div>
            <div class="input col-xs-24 col-lg-10">
                <span class="form-field margin-xs-b-0">
                    <input
                        v-model="userCustomNameMappping[user]"
                        maxlength="25"
                        type="text"
                        :placeholder="T('Device name')"
                    />
                </span>
            </div>
            <div class="desc col-xs-24 col-lg-9">
                <p class="input-description">
                    {{ T("Device name of the user") }}
                </p>
            </div>
        </div>
    </template>
</template>

<script setup lang="ts">
import config from "@/classes/config"
import { T } from "@/classes/i18n"
import products from "@/classes/objectTypes"
import { ESecDnsType } from "@/classes/objectTypes/mobileSecurity/secure-dns/secure-dns"
import getterHelpers from "@/helpers/helpers.getters"
import tenantHelpers from "@/helpers/helpers.tenants"
import requestHandler from "@/queries/requests"
import { MutationTypes, useStore } from "@/store/vuex.store"
import { computed, onMounted, ref, watch } from "vue"
import type Button from "../components/button/button"
import loader from "../components/loader.vue"
import InputVue from "../inputtypes/input-vue-select.vue"

const activeAccountId = computed(() => useStore().state.session.activeAccountId || "")

const loading = ref(true)
const loaderText = ref("")

const modalButtons = computed(() => {
    return getterHelpers.useStore().getters.getActiveModal(activeAccountId.value)
        .buttons as Button[]
})

const errors = ref<string[]>([])
const applyUserName = ref(false)

const users = computed(
    () =>
        products.unifiedSecurity.users.useStore?.().getObjectStoreObjects(activeAccountId.value) ||
        []
)
const profiles = computed(() =>
    (
        products.secureDns.secureDnsProfiles
            .useStore?.()
            .getObjectStoreObjects(activeAccountId.value) || []
    ).filter((profile) => {
        return profile.type === ESecDnsType.EXTERNAL_DEVICES
    })
)

const selectedUsers = ref<string[]>([])
const userSelectOptions = computed(() => {
    return users.value.map((user) => {
        return {
            id: user.profile?.email,
            text: `${user.profile?.firstname} ${user.profile?.lastname} (${user.profile?.email})`
        } as selectOption
    })
})
const userCustomNameMappping = ref<Record<string, string>>({})

const selectedProfile = ref<string>("")
const profileSelectOptions = computed(() => {
    return profiles.value.map((profile) => {
        return {
            id: profile.id,
            text: `${profile.name} (${profile.endpoints.shortId})`
        } as selectOption
    })
})

async function submit() {
    errors.value = []
    modalButtons.value[1].loading = true

    try {
        const body = {
            profileId: selectedProfile.value,
            users: selectedUsers.value.map((user) => {
                if (!applyUserName.value) {
                    return {
                        email: user
                    }
                }

                return {
                    email: user,
                    name: userCustomNameMappping.value[user]
                }
            })
        }

        await requestHandler.request(
            "POST",
            `${config.mgtApiUriNext}/tenants/${tenantHelpers.getTenantDomain(activeAccountId.value)}/secure-dns/emails/invite`,
            body
        )

        getterHelpers.useStore().commit(MutationTypes.removeModal)
    } catch (error: any) {
        errors.value.push(error.message || error.data || "Unknown error")
    }

    modalButtons.value[1].loading = false
}

onMounted(async () => {
    loaderText.value = "Getting Users..."
    await products.unifiedSecurity.users.queries.getObjectsFromApi(activeAccountId.value)

    loaderText.value = "Getting SecureDns Profiles..."
    await products.secureDns.secureDnsProfiles.queries.getObjectsFromApi(activeAccountId.value)

    loading.value = false
})

watch(loading, () => {
    modalButtons.value[1].loading = loading.value
})

watch([selectedUsers, selectedProfile], () => {
    if (selectedUsers.value.length > 0 && selectedProfile.value) {
        modalButtons.value[1].disabled = false
    } else {
        modalButtons.value[1].disabled = true
    }
})

watch(selectedUsers, (newUsers, oldUsers) => {
    newUsers.forEach((userEmail) => {
        if (!userCustomNameMappping.value[userEmail]) {
            const userProfile = users.value.find((u) => u.profile?.email === userEmail)?.profile

            if (userProfile) {
                userCustomNameMappping.value[userEmail] =
                    `${userProfile.firstname} ${userProfile.lastname}`
            } else {
                userCustomNameMappping.value[userEmail] = userEmail
            }
        }
    })
})

defineExpose({
    submit
})
</script>

<style scoped>
/* shows dots (...) on overflow */
.input-custom-user {
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>
