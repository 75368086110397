import config from "@/classes/config"
import tenantHelpers from "@/helpers/helpers.tenants"
import requestHandler from "@/queries/requests"
import { defineStore } from "pinia"

interface UscSettingsStore {
    ENABLE_CROSS_TENANT_SUN_CONFIGURATION: boolean
}
interface UscSettingsStores {
    [accountId: string]: UscSettingsStore
}
type UscSettingToModule = {
    [setting in keyof UscSettingsStore]: string
}
const settingToModule: UscSettingToModule = {
    ENABLE_CROSS_TENANT_SUN_CONFIGURATION: "SUN"
}

export const useUscSettingsStore = defineStore("uscSettings", {
    state: () => {
        return {
            accounts: <UscSettingsStores>{}
        }
    },
    getters: {
        getSetting: (state) => {
            return (accountId: string, settingName: keyof UscSettingsStore) => {
                return state.accounts[accountId][settingName]
            }
        }
    },
    actions: {
        /**
         * Sets stores for each tenant in list
         */
        addAccount(accountId: string) {
            accountId = tenantHelpers.getAccountId(accountId)
            if (!this.accounts[accountId]) {
                this.accounts[accountId] = {
                    ENABLE_CROSS_TENANT_SUN_CONFIGURATION: false
                }
            }
        },
        /**
         * Sets stores for each tenant in list
         */
        setAccounts(tenants: { tenantDomain: string }[]) {
            tenants?.forEach((tenant) => {
                this.addAccount(tenantHelpers.getAccountId(tenant.tenantDomain))
            })
        },
        /**
         * clear store
         */
        clearStore() {
            this.accounts = <UscSettingsStores>{}
        },
        async getSettingsFromApi(accountId: string) {
            let response = await requestHandler.request(
                "GET",
                config.mgtApiUriNext + "/tenants/" + accountId + ".sms/usc/options"
            )
            if (Array.isArray(response)) {
                response.forEach(
                    (setting: {
                        name: keyof UscSettingsStores
                        module: string
                        value?: any
                        defaultValue: any
                    }) => {
                        let userStore = this.accounts[accountId]

                        userStore[setting.name as keyof typeof userStore] = Object.hasOwn(
                            setting,
                            "value"
                        )
                            ? setting.value
                            : setting.defaultValue
                    }
                )
            }
        },
        async setSetting(
            accountId: string,
            property: keyof UscSettingsStore,
            value: UscSettingsStore[keyof UscSettingsStore]
        ) {
            let payload = [
                {
                    module: settingToModule[property],
                    name: property,
                    value: value
                }
            ]
            try {
                let response = await requestHandler.request(
                    "PUT",
                    config.mgtApiUriNext + "/tenants/" + accountId + ".sms/usc/options",
                    payload
                )
                if (response.code == 200) {
                    let userStore = this.accounts[accountId]
                    userStore[property as keyof typeof userStore] = value
                } else {
                    throw "error"
                }
            } catch (e) {
                console.error(e)
            }
        },
        async enableCrossTenantSunConfiguration(accountId: string) {
            await this.setSetting(accountId, "ENABLE_CROSS_TENANT_SUN_CONFIGURATION", true)
        },
        async disableCrossTenantSunConfiguration(accountId: string) {
            await this.setSetting(accountId, "ENABLE_CROSS_TENANT_SUN_CONFIGURATION", false)
        }
    }
})
