<script setup lang="ts">
import { T } from "@/classes/i18n"
import products from "@/classes/objectTypes"
import arrayHelpers from "@/helpers/helpers.arrays"
import encodingHelpers from "@/helpers/helpers.encoding"
import getterHelpers from "@/helpers/helpers.getters"
import licenseHelpers from "@/helpers/helpers.license"
import tenantHelpers from "@/helpers/helpers.tenants"
import requestHandler from "@/queries/requests"
import router from "@/router/router"
import useRouterStore from "@/router/routerStore"
import { ActionTypes, useStore } from "@/store/vuex.store"
import { computed } from "vue"
import vSelect from "vue-select"
import ModalObject, { ModalObjectButton } from "../components/modals/modalClass"
import tooltipComponent from "../components/tooltip.vue"
const store = getterHelpers.useStore()

const objectId = computed(() => {
    return useRouterStore().getObjectId
})

const activePage = computed(() => {
    return useRouterStore().getActivePage
})

const activeAccountId = computed({
    get: () => {
        return store.getters.getActiveAccountId || ""
    },
    set: async (accountId: string) => {
        let isNotRegistered = accountOptions.value.some((option) => {
            return option.id == accountId && option.groupId == "#unavailableTenants"
        })

        if (isNotRegistered) {
            let canBeCreated = false
            let modalContent = ""
            let modalButtons: ModalObjectButton[] = []
            try {
                const response = await requestHandler.request(
                    "POST",
                    "/sms-mgt-api/api/2.0/tenants/create?dry_run=1",
                    {
                        tenantDomain: tenantHelpers.getTenantDomain(accountId)
                    }
                )
                if (response.canBeCreated) {
                    canBeCreated = true
                } else {
                    canBeCreated = false
                }
            } catch (e) {
                console.log(e)
            }

            if (canBeCreated) {
                modalContent = T("Möchten Sie diesen Tenant wirklich anlegen?")
                modalButtons.push(
                    new ModalObjectButton({
                        text: T("Abort"),
                        icon: "fal fa-times",
                        onClick(modalComponent, modal) {
                            modal.delete()
                        }
                    }),
                    new ModalObjectButton({
                        text: T("Tenant anlegen"),
                        icon: "fal fa-plus",
                        onClick: async (modalComponent, modal) => {
                            modal.getButton(0)?.startLoader()
                            modal.getButton(0)?.disable()
                            modal.getButton(1)?.startLoader()
                            modal.getButton(1)?.disable()
                            try {
                                const response = await requestHandler.request(
                                    "POST",
                                    "/sms-mgt-api/api/2.0/tenants/create",
                                    {
                                        tenantDomain: tenantHelpers.getTenantDomain(accountId)
                                    }
                                )

                                if (store.state.session.accounts[String(accountId)]) {
                                    await products.unifiedSecurity.licenses.queries.getObjectsFromApi(
                                        accountId
                                    )
                                    store.state.session.accounts[String(accountId)].available = true
                                }

                                if (modal.content?.body?.content != undefined) {
                                    modal.content.body.content = T(
                                        "Der Tenant wurde erfolgreich angelegt. Sie können dieses Fenster jetzt schließen."
                                    )
                                    if (modal.content.title) {
                                        modal.content.title = {
                                            icon: "fal fa-check",
                                            text: T("Tenant erfolgreich angelegt")
                                        }
                                    }
                                }

                                modal.buttons = [
                                    new ModalObjectButton({
                                        text: T("Schließen"),
                                        icon: "fal fa-times",
                                        onClick(modalComponent, modal) {
                                            modal.delete()
                                        }
                                    }),
                                    new ModalObjectButton({
                                        text: T("Zu Tenant wechseln"),
                                        icon: "fal fa-sync",
                                        onClick(modalComponent, modal) {
                                            if (
                                                objectId.value != undefined ||
                                                (activePage.value &&
                                                    !tenantHelpers.canOpenRoute(
                                                        accountId,
                                                        activePage.value
                                                    ))
                                            ) {
                                                router.navigate(
                                                    "#show-tenant-" +
                                                        tenantHelpers.getTenantDomain(accountId) +
                                                        "-dashboard"
                                                )
                                            } else {
                                                store.dispatch(ActionTypes.switchAccount, accountId)
                                            }
                                            modal.delete()
                                        }
                                    })
                                ]
                            } catch (e) {
                                if (modal.content?.body?.content != undefined) {
                                    modal.content.body.content = T(
                                        "Der Tenant konnte nicht angelegt werden. Bitte versuchen Sie es erneut."
                                    )
                                    if (modal.content.title) {
                                        modal.content.title = {
                                            icon: "fal fa-exclamation-triangle",
                                            text: T("Tenant konnte nicht angelegt werden")
                                        }
                                    }
                                }
                                modal.buttons = [
                                    new ModalObjectButton({
                                        text: T("Verstanden"),
                                        icon: "fal fa-check",
                                        onClick(modalComponent, modal) {
                                            modal.delete()
                                        }
                                    })
                                ]
                            }
                        }
                    })
                )
            } else {
                modalContent = T(
                    "Der Tenant kann nicht angelegt werden, da dieser nicht über benötigte Lizenzen verfügt."
                )
                modalButtons.push(
                    new ModalObjectButton({
                        text: T("Verstanden"),
                        icon: "fal fa-check",
                        onClick(modalComponent, modal) {
                            modal.delete()
                        }
                    })
                )
            }

            new ModalObject({
                id: "addTenantDialog",
                content: {
                    title: {
                        icon: canBeCreated ? "fal fa-plus" : "fal fa-exclamation-triangle",
                        text: canBeCreated
                            ? T("Tenant anlegen")
                            : T("Tenant kann nicht angelegt werden")
                    },
                    body: {
                        content: modalContent,
                        use: true
                    }
                },
                buttons: modalButtons
            }).show()
        } else if (
            objectId.value != undefined ||
            (activePage.value && !tenantHelpers.canOpenRoute(accountId, activePage.value))
        ) {
            router.navigate(
                "#show-tenant-" + tenantHelpers.getTenantDomain(accountId) + "-dashboard"
            )
        } else {
            store.dispatch(ActionTypes.switchAccount, accountId)
        }
    }
})

const accountOptions = computed(() => {
    let result: selectOption[] = []
    let accounts = (<SessionScopes>useStore().state.session.userInfo.scopes).list_accounts
    let reseller: any[] = []
    let tenants: selectOption[] = []
    accounts.forEach((account) => {
        let isAccountAvailable = store.state.session.accounts[String(account.accountid)]?.available
        if (!account.parent_accountid) {
            reseller.push({
                id: String(account.accountid),
                text: encodingHelpers.unescapeHTML(account.accountname),
                child: false,
                tooltip: {
                    content: T("Reseller"),
                    customId: "account_" + String(account.accountid)
                }
            })
        } else {
            let hasLicense = licenseHelpers.hasOneOfLicenses(String(account.accountid), [
                "laas",
                "unifiedSecurityConsole",
                "Mobile Security",
                "MDM",
                "utmLicense"
            ])
            tenants.push(<selectOption>{
                id: String(account.accountid),
                text:
                    encodingHelpers.unescapeHTML(account.accountname) +
                    (hasLicense ? "" : !isAccountAvailable ? "" : " (" + T("Keine Lizenz") + ")"),
                child: true,
                disabled: !hasLicense && isAccountAvailable,
                tooltip: {
                    content: hasLicense
                        ? T("Tenant")
                        : !isAccountAvailable
                          ? T("Tenant anlegen")
                          : T(
                                "Der Tenant verfügt über keine Lizenz. Bitte nutzen Sie bei Bedarf das Resellerportal für den Erwerb einer Lizenz."
                            ),
                    customId: "account_" + String(account.accountid)
                },
                groupId: !isAccountAvailable ? "#unavailableTenants" : undefined
            })
        }
    })

    result = reseller.concat(arrayHelpers.sortByObjProperty(tenants, "text"))

    result.push({
        type: "groupName",
        id: "#unavailableTenants",
        text: T("Nicht angelegt"),
        disabled: true
    })
    const foundGroups = result.filter((option) => {
        return option?.type == "groupName"
    })
    if (foundGroups.length > 0) {
        let ungroupedResult: selectOption[] = result.filter((option) => {
            return option.groupId == undefined && option.type != "groupName"
        })

        let groupedResult: selectOption[] = []
        foundGroups.forEach((groupOption) => {
            const groupId = groupOption.id
            groupedResult.push(groupOption)

            const childOptions = result.filter((option) => {
                return option.groupId == groupId
            })
            groupedResult.push(...childOptions)
        })

        result = ungroupedResult.concat(groupedResult)
    }

    return result
})

function isSelectable(option: selectOption) {
    if (option.disabled === true) {
        return false
    }
    return true
}

const onSelect = (event: any) => {
    // close tooltip
    if (event?.tooltip?.customId) {
        let tooltipId = event.tooltip.customId
        let contentEl = document.getElementById("tooltip#" + tooltipId)
        if (contentEl) {
            contentEl.remove()
        }
    }
}
</script>

<template>
    <v-select
        append-to-body
        :options="accountOptions"
        v-model="activeAccountId"
        label="text"
        :reduce="(option: selectOption) => option.id"
        :clearable="false"
        id="accountSelect"
        uid="sideBarDropdown"
        @option:selecting="
            (event: any) => {
                onSelect(event)
            }
        "
        :selectable="isSelectable"
    >
        <template #no-options>
            {{ T("No options found") }}
        </template>
        <template #option="{ id, text, child, tooltip, type, groupId }">
            <component
                :is="tooltip != undefined ? tooltipComponent : 'span'"
                :tooltip="(<selectOption['tooltip']>tooltip)?.content || ''"
                :htmlTooltip="(<selectOption['tooltip']>tooltip)?.htmlTooltip || false"
                :customId="(<selectOption['tooltip']>tooltip)?.customId || undefined"
                :isTag="'span'"
            >
                <span v-if="child">&nbsp;&nbsp;&nbsp;</span
                ><i
                    :class="{
                        'fal fa-fw fa-crown':
                            !child && type != 'groupName' && groupId != '#unavailableTenants',
                        'fal fa-fw fa-building':
                            child && type != 'groupName' && groupId != '#unavailableTenants',
                        'fal fa-plus': groupId == '#unavailableTenants'
                    }"
                ></i
                >&nbsp;&nbsp;{{ text }}
            </component>
        </template>
        <template #selected-option="{ id, text, child, type, groupId }">
            <span
                :title="
                    child && type != 'groupName'
                        ? T('Tenant')
                        : type != 'groupName'
                          ? T('Reseller')
                          : text
                "
                ><i
                    :class="{
                        'fal fa-fw fa-crown':
                            !child && type != 'groupName' && groupId != '#unavailableTenants',
                        'fal fa-fw fa-building':
                            child && type != 'groupName' && groupId != '#unavailableTenants'
                    }"
                ></i
                >&nbsp;&nbsp;{{ text }}</span
            >
        </template>
    </v-select>
</template>

<style lang="scss">
#main-sidebar nav ul li.select {
    > div input.vs__search {
        border: none !important;
        box-shadow: none;
        background: none;
        display: flex;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 1px solid transparent;
        border-left: none;
        outline: none;
        margin: 4px 0 0;
        padding: 0 7px;
        background: none;
        box-shadow: none;
        width: 0 !important;
        max-width: 100%;
        flex-grow: 1;
        z-index: 1;
        color: #fafafa;
        font-size: 1em;
        min-height: 25px;
    }

    .vs__dropdown-toggle {
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 2px;
        background: darken(#545861, 5%);

        .vs__actions {
            width: 20px;
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAhCAYAAADZPosTAAAAdklEQVRIS+2S0Q3AIAgFYbOO4hpMwBhNJ6WxSaNpFA0Yf4rfcHkeD2Hxw8U8CKDfaDgMhwYDe2rDzAkRTy2giFxElL4z3YQatAfLcPXLLagGGwLzQA0dwaaALxQAjpazaYeGxjwre2pjTRcJPebKblzZ7/GHDm+LjCQiegl+qAAAAABJRU5ErkJggg==");
            background-position: right 0;
            background-repeat: no-repeat;
        }

        .vs__selected {
            color: #fff;
        }

        svg.vs__open-indicator {
            display: none;
        }

        .vs__selected-options {
            flex-wrap: nowrap;
        }
    }

    .vs__dropdown-menu {
        background: darken(#545861, 5%);
        color: #fff;
        position: absolute;
        left: 0;
        width: min-content;
        > li {
            padding: 8px;

            &.vs__dropdown-option--highlight {
                background: #e74c3c;
            }

            &.vs__dropdown-option--selected {
            }
        }
    }
}
body > .vs__dropdown-menu#vssideBarDropdown__listbox {
    width: max-content !important;
}
body > ul[id*="__listbox"] {
    width: max-content !important;
}
</style>
