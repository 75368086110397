<template>
    <section id="secureDnsLogs">
        <div class="row margin-xs-0" v-if="accountInitialized != undefined">
            <div class="col-xs-24 padding-xs-x-4 padding-xs-t-4">
                <div class="box-shadow">
                    <div class="header-section">
                        <div class="padding-xs-t padding-xs-l">
                            <div class="toolbar flexrow">
                                <div class="col-xs">
                                    <label class="form-inputgroup margin-xs-b-0">
                                        <span class="form-icon-prefix">
                                            <i class="fal fa-search"></i>
                                        </span>
                                        <input
                                            id="itemlistSearch"
                                            type="text"
                                            v-model="search"
                                            :placeholder="T('Search')"
                                        />
                                    </label>
                                </div>

                                <div
                                    class="btn-toolbar col-xs-1"
                                    style="margin-bottom: 8px; margin-right: 8px"
                                >
                                    <div class="btn-group">
                                        <a
                                            class="btn"
                                            style="margin-bottom: 8px; margin-right: 8px"
                                            v-on:click="
                                                advancedFilterEnabled = !advancedFilterEnabled
                                            "
                                            :class="{ active: advancedFilterEnabled }"
                                        >
                                            <i
                                                :title="T('Enable advanced filter settings')"
                                                class="fal fa-fw fa-filter"
                                            ></i>
                                        </a>
                                    </div>
                                </div>

                                <div
                                    class="col-xs-4"
                                    style="margin-bottom: 8px; margin-right: 4px"
                                    :title="T('Automatic refresh')"
                                >
                                    <label class="form-field margin-xs-b-0">
                                        <span class="form-icon-prefix"
                                            ><i class="fal fa-stopwatch"></i
                                        ></span>
                                        <select v-model="autoRefreshInterval" class="form-control">
                                            <!-- value = seconds -->
                                            <option value="0">{{ T("Disabled") }}</option>
                                            <option value="30">
                                                {{ `30 ${T("seconds")}` }}
                                            </option>
                                            <option value="60">{{ `1 ${T("minute")}` }}</option>
                                            <option value="300">
                                                {{ `5 ${T("minutes")}` }}
                                            </option>
                                        </select>
                                    </label>
                                </div>

                                <div
                                    class="btn-toolbar"
                                    style="margin-bottom: 8px; margin-right: 8px"
                                >
                                    <div class="btn-group">
                                        <a
                                            id="itemlistButtonRefresh"
                                            class="btn btn-menu btn-loader twist-in"
                                            style="max-height: 34.5px; max-width: 35.75px"
                                            v-on:click="load(true, true, true)"
                                            :class="{ 'btn-loading': refreshing }"
                                        >
                                            <span class="animate">
                                                <i
                                                    v-if="refreshing"
                                                    class="progress-circular no-progress"
                                                    style="
                                                        font-size: 1.5em;
                                                        top: 50%;
                                                        position: absolute;
                                                        left: 50%;
                                                        transform: translate(-50%, -50%);
                                                    "
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="1em"
                                                        height="1em"
                                                        viewBox="0 0 50 50"
                                                        style="stroke-width: 4px"
                                                    >
                                                        <circle
                                                            cx="25"
                                                            cy="25"
                                                            r="20"
                                                            style="
                                                                stroke: rgba(0, 0, 0, 0.1);
                                                                fill: none;
                                                            "
                                                        ></circle>
                                                        <g transform="translate(25,25) rotate(-90)">
                                                            <circle
                                                                stroke-dasharray="110"
                                                                stroke-dashoffset="0"
                                                                cx="0"
                                                                cy="0"
                                                                r="20"
                                                                style="
                                                                    fill: none;
                                                                    stroke-linecap: round;
                                                                "
                                                                transform="rotate(14.0181)"
                                                            >
                                                                <animate
                                                                    attributeName="stroke-dashoffset"
                                                                    values="360;140"
                                                                    dur="2.2s"
                                                                    keyTimes="0;1"
                                                                    calcMode="spline"
                                                                    fill="freeze"
                                                                    keySplines="0.41,0.314,0.8,0.54"
                                                                    repeatCount="indefinite"
                                                                    begin="0"
                                                                ></animate>
                                                                <animate
                                                                    attributeName="stroke"
                                                                    fill="freeze"
                                                                    dur="8s"
                                                                    begin="0"
                                                                    repeatCount="indefinite"
                                                                ></animate>
                                                            </circle>
                                                        </g>
                                                    </svg>
                                                </i>
                                            </span>
                                            <span v-if="!refreshing"
                                                ><i
                                                    :title="T('Refresh')"
                                                    class="fal fa-fw fa-sync"
                                                ></i
                                            ></span>

                                            <span v-if="refreshing">
                                                <loaderComponent class="text-size-2 color-red" />
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <template v-if="advancedFilterEnabled">
                                <loaderComponent
                                    v-if="advancedFilterLoading"
                                    class="text-size-2 color-red"
                                />
                                <div v-else class="toolbar flexrow">
                                    <div class="col-xs padding-xs-0 padding-xs-b padding-xs-r">
                                        <select v-model="advancedFilterStatus">
                                            <option value="">
                                                {{ T("All Return Codes") }}
                                            </option>
                                            <option
                                                v-for="option in advancedFilterItems?.status"
                                                :key="option"
                                                :value="option"
                                            >
                                                {{ option }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="col-xs padding-xs-0 padding-xs-b padding-xs-r">
                                        <select v-model="advancedFilterType">
                                            <option value="">
                                                {{ T("All Request Types") }}
                                            </option>
                                            <option
                                                v-for="option in advancedFilterItems?.type"
                                                :key="option"
                                                :value="option"
                                            >
                                                {{ option }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="col-xs padding-xs-0 padding-xs-b padding-xs-r">
                                        <select v-model="advancedFilterDeviceName">
                                            <option value="">
                                                {{ T("All Clients") }}
                                            </option>
                                            <option
                                                v-for="option in advancedFilterItems?.deviceName"
                                                :key="option"
                                                :value="option"
                                            >
                                                {{ option }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="col-xs padding-xs-0 padding-xs-b padding-xs-r">
                                        <select v-model="advancedFilterProfileId">
                                            <option value="">
                                                {{ T("All Profiles") }}
                                            </option>

                                            <option
                                                v-for="option in advancedFilterItems?.profileId"
                                                :key="option"
                                                :value="option"
                                            >
                                                {{
                                                    secDnsIdNameMapping[option] ||
                                                    `${T("Unknown")} (${option})`
                                                }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="col-xs padding-xs-0 padding-xs-b padding-xs-r">
                                        <select v-model="advancedFilterCountry">
                                            <option value="">
                                                {{ T("All Countries") }}
                                            </option>
                                            <option
                                                v-for="option in advancedFilterItems?.country"
                                                :key="option"
                                                :value="option"
                                            >
                                                {{ T(worldIsoMap[option]) }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="col-xs padding-xs-0 padding-xs-b padding-xs-r">
                                        <select v-model="advancedFilterBlockReason">
                                            <option value="">
                                                {{ T("All Results") }}
                                            </option>
                                            <option :value="null">
                                                {{ T("Only blocked domains") }}
                                            </option>
                                            <option
                                                v-for="option in advancedFilterItems?.blockReason"
                                                :key="option"
                                                :value="option"
                                            >
                                                {{
                                                    tenantHelpers.secureDnsCategoryIdToName(option)
                                                }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="content-section">
                        <template v-if="!loadingAnimation">
                            <div
                                v-if="logEntries.length === 0"
                                style="text-align: center; font-size: 1.5em; padding: 72px 0"
                            >
                                <i class="fal fa-empty-set" style="font-size: 3em"></i>
                                <p>{{ T("No data available") }}</p>
                            </div>
                            <div v-else class="domain-list">
                                <div v-for="entry in logEntries" class="domain-record">
                                    <div class="record-left">
                                        <span
                                            class="label"
                                            title=""
                                            style="
                                                margin-right: 1px;
                                                width: 5em;
                                                text-align: center;
                                            "
                                            ><span style="padding: 1px 0px">{{
                                                entry.data.type
                                            }}</span></span
                                        >

                                        <div class="vertical-line"></div>

                                        <tooltip
                                            :tooltip="
                                                entry.data.blockReason !== null
                                                    ? tenantHelpers.secureDnsCategoryIdToName(
                                                          entry.data.blockReason
                                                      )
                                                    : entry.data.status
                                            "
                                        >
                                            <template v-if="entry.data.blockReason === null">
                                                <span
                                                    v-if="entry.data.status === 'NOERROR'"
                                                    class="color-green"
                                                    style="font-size: 1rem"
                                                    title=""
                                                    ><span><i class="fa fa-check"></i> </span>
                                                </span>

                                                <!-- some error, like NXDOMAIN, SERVFAIL, .. -->
                                                <span
                                                    v-else
                                                    class="color-yellow"
                                                    style="font-size: 1rem"
                                                    title=""
                                                    ><span><i class="fa fa-circle-x"></i> </span>
                                                </span>
                                            </template>
                                            <span
                                                v-else
                                                class="color-red"
                                                style="font-size: 1rem"
                                                title=""
                                                ><span><i class="fa fa-ban"></i> </span>
                                            </span>
                                        </tooltip>

                                        <div class="vertical-line"></div>

                                        <template v-if="entry.data.domain">
                                            <div class="domain-name">
                                                <span
                                                    v-if="entry.data.domain.split('.').length > 2"
                                                    >{{
                                                        entry.data.domain
                                                            .split(".")
                                                            .slice(0, -2)
                                                            .join(".") + "."
                                                    }}</span
                                                >
                                                <span class="text-bold">{{
                                                    entry.data.domain.split(".").slice(-2).join(".")
                                                }}</span>
                                            </div>
                                        </template>
                                        <div v-else class="domain-name">
                                            <span>{{ T("Unknown domain") }}</span>
                                        </div>
                                    </div>
                                    <div class="record-right">
                                        <div class="record-info">
                                            <span>{{ formatTime(entry.data.time) }}</span>
                                            <div class="record-info-row">
                                                <span>
                                                    <i class="fa fa-shield-alt"></i>
                                                    {{
                                                        secDnsIdNameMapping[entry.data.profileId] ||
                                                        T("Unknown")
                                                    }}
                                                </span>

                                                <div class="vertical-line"></div>

                                                <span v-if="entry.data.deviceName">
                                                    <i class="fa fa-user-shield"></i>
                                                    {{ entry.data.deviceName }}
                                                </span>

                                                <div
                                                    v-if="entry.data.deviceName"
                                                    class="vertical-line"
                                                ></div>

                                                <tooltip
                                                    :tooltip="
                                                        T(
                                                            entry.data.country
                                                                ? tenantHelpers.countryCodeToName(
                                                                      entry.data.country
                                                                  )
                                                                : 'Unknown'
                                                        )
                                                    "
                                                >
                                                    <span>
                                                        <i
                                                            v-if="!!entry.data.country"
                                                            class="fi"
                                                            :class="`fi-${entry.data.country}`"
                                                        ></i>
                                                        <i
                                                            v-else
                                                            class="fa fa-question country-unknown"
                                                        ></i>
                                                    </span>
                                                </tooltip>
                                            </div>
                                        </div>

                                        <div class="exception-rule-btn">
                                            <button-component
                                                class=""
                                                :button-options="getEntryMenu(entry)"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div ref="observerTarget" class="loading-indicator">
                                    <loaderComponent
                                        v-if="loadingMore"
                                        class="color-red text-size-2"
                                    />
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div style="text-align: center; padding: 122px 0">
                                <loaderComponent class="color-red text-size-3"></loaderComponent>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import { T } from "@/classes/i18n"
import secureDns from "@/classes/objectTypes/mobileSecurity/secure-dns/secure-dns"
import dialogs from "@/dialogs/dialogs"
import secureDnsDialogs from "@/dialogs/dialogs.secure.dns"
import tenantHelpers from "@/helpers/helpers.tenants"
import timeHelpers from "@/helpers/helpers.time"
import requestHandler from "@/queries/requests"
import loaderComponent from "@/templates/components/loader.vue"
import tooltip from "@/templates/components/tooltip.vue"
import worldGeo from "@highcharts/map-collection/custom/world.geo.json"
import moment from "moment"
import { debounce } from "throttle-debounce"
import { computed, onMounted, onUnmounted, ref, watch } from "vue"
import { useStore } from "vuex"
import Button from "../components/button/button"
import ButtonComponent from "../components/button/button.vue"

interface ProfileArrayData {
    [key: string]: string[]
}

const activeAccountId = computed(() => {
    return useStore().state.session.activeAccountId
})

const accountInitialized = computed(() => {
    return useStore().state.session.accounts[activeAccountId.value || ""].initialized
})

const autoRefreshIntervalStorageKey = `secdns:protocols:${activeAccountId.value}:autoRefreshInterval`
const autoRefreshInterval = ref(localStorage.getItem(autoRefreshIntervalStorageKey) || "30")
watch(autoRefreshInterval, () =>
    localStorage.setItem(autoRefreshIntervalStorageKey, autoRefreshInterval.value)
)

const itemsPerPage = ref(100)

const search = ref("")

const autoRefreshTimer = ref<ReturnType<typeof setInterval> | null>(null)
const loadingAnimation = ref(false)
const refreshing = ref(false)
const allowedDomains = ref<ProfileArrayData>({})
const blockedDomains = ref<ProfileArrayData>({})

const totalEntries = ref(0)
const logEntries = ref<any[]>([])
const secDnsIdNameMapping = ref<{ [id: string]: string }>({})
const currentPage = ref(1)
const totalPages = computed(() => Math.ceil(totalEntries.value / itemsPerPage.value) || 1)
const offset = computed(() => (currentPage.value - 1) * itemsPerPage.value)

const loadingMore = ref(false)
const observerTarget = ref<HTMLElement | null>(null)

const advancedFilterEnabled = ref(false)
const advancedFilterLoading = ref(false)
const advancedFilterItems = ref<
    | {
          status: string[]
          profileId: string[]
          blockReason: number[]
          type: string[]
          deviceName: string[]
          country: string[]
      }
    | undefined
>(undefined)

const worldIsoMap = computed(() => {
    return worldGeo.features.reduce(
        (acc, feature) => {
            if (feature.properties && feature.properties.name) {
                const countryIso = feature.properties["hc-key"]

                acc[countryIso] = feature.properties.name
            }

            return acc
        },
        {} as {
            [key: string]: string
        }
    )
})

const advancedFilterStatus = ref("")
const advancedFilterProfileId = ref("")
const advancedFilterBlockReason = ref("")
const advancedFilterType = ref("")
const advancedFilterDeviceName = ref("")
const advancedFilterCountry = ref("")

function setupInfiniteScroll() {
    const options = {
        root: null,
        rootMargin: "100px",
        threshold: 0.1
    } satisfies IntersectionObserverInit

    const observer = new IntersectionObserver(async (entries) => {
        const entry = entries[0]
        if (entry.isIntersecting && !loadingMore.value && currentPage.value < totalPages.value) {
            loadingMore.value = true
            currentPage.value++
            await load(false, false)
            loadingMore.value = false
        }
    }, options)

    if (observerTarget.value) {
        observer.observe(observerTarget.value)
    }

    watch(observerTarget, () => {
        if (observerTarget.value) {
            observer.observe(observerTarget.value)
        }
    })

    return observer
}

async function load(loadProfiles = false, animate = true, reset = false) {
    if (animate) {
        loadingAnimation.value = true
    }

    if (reset) {
        currentPage.value = 1
    }

    refreshing.value = true

    const filters = []
    if (search.value !== "") {
        filters.push({
            field: "domain",
            operator: "wildcard",
            value: search.value
        })
    }

    if (advancedFilterStatus.value !== "") {
        filters.push({
            field: "status",
            operator: "equal",
            value: advancedFilterStatus.value
        })
    }

    if (advancedFilterProfileId.value !== "") {
        filters.push({
            field: "profileId",
            operator: "equal",
            value: advancedFilterProfileId.value
        })
    }

    if (advancedFilterBlockReason.value === null) {
        filters.push({
            field: "blockReason",
            operator: "notequal",
            value: "0"
        })
    } else if (advancedFilterBlockReason.value !== "") {
        filters.push({
            field: "blockReason",
            operator: "equal",
            value: advancedFilterBlockReason.value
        })
    }

    if (advancedFilterType.value !== "") {
        filters.push({
            field: "type",
            operator: "equal",
            value: advancedFilterType.value
        })
    }

    if (advancedFilterDeviceName.value !== "") {
        filters.push({
            field: "deviceName",
            operator: "equal",
            value: advancedFilterDeviceName.value
        })
    }

    if (advancedFilterCountry.value !== "") {
        filters.push({
            field: "country",
            operator: "equal",
            value: advancedFilterCountry.value
        })
    }

    const data = await requestHandler.request(
        "POST",
        "/sms-mgt-api/api/2.0/tenants/" +
            tenantHelpers.getTenantDomain(activeAccountId.value || "") +
            "/stats/execute",
        {
            query: {
                modul: "SecureDns",
                name: "Protocols",
                options: {
                    size: parseInt(itemsPerPage.value as any as string),
                    offset: offset.value,
                    filter: filters
                }
            }
        }
    )

    totalEntries.value = data.result.totalCount
    const newEntries = data.result.entries.map((entry: any) => ({ data: entry }))

    if (currentPage.value === 1 || reset) {
        logEntries.value = newEntries
    } else {
        logEntries.value = [...logEntries.value, ...newEntries]
    }

    if (loadProfiles) {
        const items = await secureDns.queries.getObjectsFromApi(activeAccountId.value || "")
        if (items instanceof Error) {
            dialogs.misc.errorDialog(
                activeAccountId.value,
                T("Error"),
                T("Failed to load secdns profiles")
            )
            console.error("failed to load secdns profiles", items)
            return
        }

        items.forEach((item) => {
            allowedDomains.value[item.id] = item.allowlist.hosts
            blockedDomains.value[item.id] = item.denylist.hosts
            secDnsIdNameMapping.value[item.id] = item.name
        })
    }

    loadingAnimation.value = false
    refreshing.value = false
}

async function loadAdvancedFilter() {
    advancedFilterLoading.value = true

    try {
        const data = await requestHandler.request(
            "POST",
            "/sms-mgt-api/api/2.0/tenants/" +
                tenantHelpers.getTenantDomain(activeAccountId.value || "") +
                "/stats/execute",
            {
                query: {
                    modul: "SecureDns",
                    name: "ProtocolItems",
                    options: {}
                }
            }
        )

        advancedFilterItems.value = data.result

        if (advancedFilterItems.value?.profileId) {
            advancedFilterItems.value.profileId = advancedFilterItems.value?.profileId?.filter(
                (profileId) => {
                    return secDnsIdNameMapping.value[profileId] !== undefined
                }
            )
        }
    } catch (e) {
        console.error("failed to load advanced filter", e)
        dialogs.misc.errorDialog(
            activeAccountId.value,
            T("Error"),
            T("Failed to load advanced filter")
        )
    } finally {
        advancedFilterLoading.value = false
    }
}

function updateAutoRefreshTimer(val: string) {
    if (val === "0") {
        return
    }

    autoRefreshTimer.value = setInterval(
        async () => {
            await load(false, false)
        },
        parseInt(val) * 1000
    )
}

function formatTime(time: string) {
    const momentDate = moment.utc(time).local()

    return momentDate.format(timeHelpers.getDateFormatI18n(true))
}

function getEntryMenu(entry: any) {
    return new Button({
        icon: "fal fa-ellipsis-vertical",
        title: T("Menü öffnen"),
        color: "darkbg",
        type: "icon",
        dropdownEntries: [
            new Button({
                icon: "fal fa-plus",
                text: T("Add exception rule"),
                disabled: !entry.data.domain,
                onClick: () => {
                    secureDnsDialogs.protocolEditDialog(activeAccountId.value, entry)
                }
            })
        ]
    })
}

const loadDebounce = debounce(500, load)

onMounted(async () => {
    updateAutoRefreshTimer(autoRefreshInterval.value)
    await load(true)
    const observer = setupInfiniteScroll()

    onUnmounted(() => {
        observer.disconnect()
        if (autoRefreshTimer.value) {
            clearInterval(autoRefreshTimer.value)
        }
    })
})

watch(search, async () => {
    await loadDebounce()
})

watch([search], async () => {
    currentPage.value = 1
})

watch(advancedFilterEnabled, async (active) => {
    if (active) {
        await loadAdvancedFilter()
    }
})

watch(
    [
        advancedFilterBlockReason,
        advancedFilterCountry,
        advancedFilterDeviceName,
        advancedFilterProfileId,
        advancedFilterStatus,
        advancedFilterType
    ],
    async () => {
        await load(false, true, true)
    }
)

watch(advancedFilterEnabled, (newVal) => {
    if (newVal === false) {
        advancedFilterStatus.value = ""
        advancedFilterProfileId.value = ""
        advancedFilterBlockReason.value = ""
        advancedFilterType.value = ""
        advancedFilterDeviceName.value = ""
        advancedFilterCountry.value = ""
    }
})

watch(autoRefreshInterval, (newVal) => {
    if (autoRefreshTimer.value) {
        clearInterval(autoRefreshTimer.value)
    }

    updateAutoRefreshTimer(newVal)
})
</script>

<style scoped lang="scss">
$border-color-light: #ccc;
$border-color-dark: #48494e;

$header-height: auto;

.box-shadow {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 260px);
    position: relative;
}

.header-section {
    position: sticky;
    top: 0;
    z-index: 10;
    border-bottom: 1px solid $border-color-light;
}

.darkmode .header-section {
    border-color: $border-color-dark;
}

.content-section {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
}

.domain-list {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif;
}

.domain-record {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    border-bottom: 1px solid $border-color-light;
}

.darkmode .domain-record {
    border-color: $border-color-dark;
}

.darkmode .domain-record {
    border-color: $border-color-dark;
}

.record-left {
    display: flex;
    align-items: center;
    gap: 12px;
}

.record-right {
    display: flex;
    align-items: center;
    gap: 16px;
}

.domain-name {
    font-size: 14px;
}

.record-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
}

.record-info span:first-child {
    font-size: 12px;
}

.record-info-row {
    display: flex;
    align-items: center;
    gap: 8px;
}

.vertical-line {
    box-sizing: border-box;
    margin: 0px;
    width: 1px;
    height: 1.2rem;
    background-color: $border-color-light;
}

.darkmode .vertical-line {
    background-color: $border-color-dark;
}

.loading-indicator {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.country-unknown {
    position: relative;
    display: inline-block;
    width: 1.333333em;
    line-height: 1em;
    padding-left: 5px;
}

:deep(.btn.btn2.btn-loader > span.btn-wrapper) {
    > span {
        padding: 0;
    }
}
</style>
