<template>
    <div
        id="mediabrowser"
        ondragover="$('#mediabrowser').addClass('dragging')"
        ondragleave="$('#mediabrowser').removeClass('dragging')"
        ondrop="$('#mediabrowser').removeClass('dragging')"
    >
        <div class="padding-xs-y">
            <div class="box box-shadow padding-xs-2">
                <a class="btn btn-primary" id="mediabrowserUploadButton">
                    {{ T("Upload new image") }} (JPG/PNG) </a
                ><br />
                Max. 5MB
                <hr class="margin-xs-y" />
                <div class="row">
                    <template v-if="images">
                        <template v-for="image in images">
                            <div class="col-xs-12 col-md-8 col-lg-6 padding-xs">
                                <div
                                    class="media box-shadow padding-xs-2"
                                    :class="{ selected: selectedImageId == image.imageId }"
                                >
                                    <template v-if="selectedImageId == image.imageId">
                                        <a
                                            class="deletebutton"
                                            style="z-index: 1"
                                            v-on:click="deleteImage(image.imageId)"
                                        >
                                            <i class="fal fa-trash"></i>
                                        </a>
                                    </template>
                                    <a class="frame" v-on:click="selectImage(image.imageId)">
                                        <img
                                            :src="`/sms-mgt-api/api/2.0/tenants/${activeTenantDomain}/images/${image.imageId}?type=png`"
                                        />
                                    </a>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
            </div>
        </div>
        <div class="dropzone hzScroll" id="mediabrowserDropZone">
            <div class="uploadcontent">
                <i class="fal fa-upload"></i>&nbsp;{{ T("Upload file") }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import config from "@/classes/config"
import { useFeatureStore } from "@/classes/featureStore"
import { T } from "@/classes/i18n"
import products from "@/classes/objectTypes"
import { type Image } from "@/classes/objectTypes/unifiedSecurity/images/images"
import getterHelpers from "@/helpers/helpers.getters"
import timeHelpers from "@/helpers/helpers.time"
import { useStore } from "@/store/vuex.store"
// @ts-ignore
import Dropzone from "dropzone"
import { computed, onMounted, ref, watch } from "vue"

const images = ref<Image[]>([])
const selectedImageId = ref<string | null>(null)
const activeAccountId = computed(() => {
    return useStore().state.session.activeAccountId || ""
})
const activeTenantDomain = computed(() => {
    return activeAccountId.value + ".sms"
})

const featureStore = useFeatureStore()

async function getImageList() {
    const ret = await products.unifiedSecurity.images.queries.getObjectsFromApi(
        activeAccountId.value
    )
    if (ret instanceof Error) {
        console.error("failed to fetch images", ret)
        return
    }

    images.value = ret
}

async function deleteImage(imageId: string) {
    await products.unifiedSecurity.images.queries.deleteObjectFromApi(
        activeAccountId.value,
        imageId
    )
    await getImageList()
}

function initDropzone() {
    let dropZone = {
        acceptedFiles: "image/jpg,image/jpeg,image/png",
        url:
            "/sms-mgt-api/api/" +
            (featureStore.isEnabled("IMAGES_V2_ROUTES")
                ? config.mgtApiVersionNext
                : config.mgtApiVersion) +
            "/tenants/" +
            activeTenantDomain.value +
            "/images",
        clickable: "#mediabrowserUploadButton",
        uploadMultiple: false,
        paramName: "image",
        maxFilesize: 5,
        previewTemplate: `
                    <div class="dz-preview dz-file-preview">
                        <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                        <div class="dz-success-mark"><span><i class="fal fa-check color-lightgreen"></i> ${T("FILE SUCCESSFULLY UPLOADED")}</span></div>
                        <div class="dz-error-mark"><span><i class="fal fa-times color-red"></i> ${T("ERROR UPLOADING FILE")}</span></div>
                        <br>
                        <div class="dz-details">
                            <div class="dz-filename"><strong>File:</strong> <span data-dz-name></span></div>
                        </div>
                        <div class="dz-error-message"><strong>Error:</strong> <span data-dz-errormessage></span></div>
                        <br>
                        <div class="dz-close empty"></div>
                    </div>`
    }
    let myDropzone = new Dropzone("#mediabrowserDropZone", dropZone)

    myDropzone.on("success", async () => {
        await getImageList()
        $(".dz-preview.dz-processing.dz-image-preview.dz-success.dz-complete").remove()
        $("#mediabrowserDropZone").removeClass("dz-started")
    })

    myDropzone.on("error", async () => {
        $(".dz-error-message").append(
            "<p class='margin-xs-t-2'>" +
                T("This message disappears in") +
                " <span class='secondsCounter'>5</span> " +
                T("seconds") +
                "</p>"
        )
        await timeHelpers.sleep(1000)
        $(".secondsCounter").text("4")
        await timeHelpers.sleep(1000)
        $(".secondsCounter").text("3")
        await timeHelpers.sleep(1000)
        $(".secondsCounter").text("2")
        await timeHelpers.sleep(1000)
        $(".secondsCounter").text("1")
        await timeHelpers.sleep(1000)
        $(".dz-preview.dz-file-preview.dz-error.dz-complete").remove()
        $("#mediabrowserDropZone").removeClass("dz-started")
    })
}

function selectImage(imageId: string) {
    selectedImageId.value = imageId
}

onMounted(async () => {
    let modal = getterHelpers.useStore().getters.getActiveModal(activeAccountId.value)
    if (modal?.buttons && modal?.buttons[1] !== undefined) {
        modal.buttons[1].disabled = true
    }
    await getImageList()

    initDropzone()
})

watch(selectedImageId, () => {
    let modal = getterHelpers.useStore().getters.getActiveModal(activeAccountId.value)
    if (modal?.buttons && modal?.buttons[1] !== undefined) {
        if (selectedImageId.value == "") {
            modal.buttons[1].disabled = true
        } else {
            modal.buttons[1].disabled = false
        }
    }
})

defineExpose({ selectedImageId })
</script>
