<script setup lang="ts">
import config from "@/classes/config"
import { T } from "@/classes/i18n"
import objectStores from "@/classes/init"
import products from "@/classes/objectTypes"
import deviceHelpers from "@/helpers/helpers.devices"
import getterHelpers from "@/helpers/helpers.getters"
import stringHelpers from "@/helpers/helpers.strings"
import tenantHelpers from "@/helpers/helpers.tenants"
import timeHelpers from "@/helpers/helpers.time"
import router from "@/router/router"
import useRouterStore from "@/router/routerStore"
import { ActionTypes, useStore } from "@/store/vuex.store"
import { computed, onMounted, ref } from "vue"
import Button from "./button/button"
import Label from "./label/label"
import TableNext, { TableEntryInfo } from "./tableNext.vue"

const activeAccountId = computed(() => {
    return getterHelpers.useStore()?.state.session.activeAccountId || ""
})
const activePage = computed(() => {
    return useRouterStore().getActivePage || ""
})
const objectId = computed(() => {
    return useRouterStore().getObjectId || ""
})

const initialized = ref(false)
const thisData = ref(<any>[])
const table = ref({
    loading: true,
    tableData: {
        titles: <any[]>[],
        rows: <any[]>[]
    }
})
const openedSubTables = ref(<string[]>[])
const tableInfo: TableEntryInfo[] = [
    {
        property: "execution",
        text: T("Execution time"),
        displayType: "text",
        getValue: (entry) => {
            return timeHelpers.formatDate(
                entry.nextTrigger * 1000,
                timeHelpers.getDateFormatI18n(true)
            )
        },
        getSortValue: (entry) => {
            return entry.nextTrigger
        }
    },
    {
        property: "entity",
        text: T(activePage.value == "uscProfiles" ? "UTM" : "Profile"),
        displayType: "labels",
        getValue: (entry) => {
            let relatedItem = undefined
            if (activePage.value == "uscProfiles" && config.canUseNewObjectType("uscUtms")) {
                relatedItem = products.unifiedSecurityConsole.uscUtms
                    .useStore?.()
                    .getObjectStoreObject(activeAccountId.value, entry.data.deviceId)
            } else if (activePage.value == "uscUtms" && config.canUseNewObjectType("uscProfiles")) {
                relatedItem = products.unifiedSecurityConsole.uscProfiles
                    .useStore?.()
                    .getObjectStoreObject(activeAccountId.value, entry.data.profileId)
            } else {
                relatedItem = useStore().getters.getObject({
                    accountId: activeAccountId.value,
                    objectId:
                        activePage.value == "uscProfiles"
                            ? entry.data.deviceId
                            : entry.data.profileId,
                    objectType: activePage.value == "uscProfiles" ? "uscUtms" : "uscProfiles"
                })
            }

            let nameProperty = tenantHelpers.getObjectNamePropertyForObjectType(
                activePage.value == "uscProfiles" ? "uscUtms" : "uscProfiles"
            ).primary
            let relatedItemName = relatedItem?.[nameProperty]
            return [
                new Label({
                    title: relatedItemName
                        ? deviceHelpers.getAliasedShortDeviceId(
                              activePage.value == "uscProfiles"
                                  ? entry.data.deviceId
                                  : entry.data.profileId,
                              relatedItemName
                          )
                        : T("Action was not triggered by a profile"),
                    text: relatedItemName
                        ? deviceHelpers.getAliasedShortDeviceId(
                              activePage.value == "uscProfiles"
                                  ? entry.data.deviceId
                                  : entry.data.profileId,
                              relatedItemName
                          )
                        : T("No profile"),
                    class: "",
                    onClick: () => {
                        if (relatedItemName) {
                            if (activePage.value == "uscProfiles") {
                                router.navigate(
                                    "show-tenant-" +
                                        activeAccountId.value +
                                        ".sms-usc-utms-" +
                                        entry.data.deviceId
                                )
                            } else {
                                router.navigate(
                                    "edit-tenant-" +
                                        activeAccountId.value +
                                        ".sms-usc-profiles-" +
                                        entry.data.profileId
                                )
                            }
                        }
                    }
                })
            ]
        }
    },
    {
        property: "type",
        text: T("Type"),
        displayType: "text",
        getValue: (entry) => {
            return T(stringHelpers.capitalizeFirstLetter(entry.type.replace("utm/", "")))
        }
    },
    {
        property: "state",
        text: T("xState"),
        displayType: "labels",
        getValue: (entry) => {
            return [
                new Label({
                    title:
                        entry.status == "ERROR"
                            ? T(entry.statusText)
                            : T(stringHelpers.capitalizeFirstLetter(entry.status)),
                    text: T(stringHelpers.capitalizeFirstLetter(entry.status)),
                    class:
                        entry.status == "ERROR"
                            ? "bg-red"
                            : entry.status == "FINISHED"
                              ? "bg-green"
                              : "",
                    icon:
                        entry.status == "ERROR"
                            ? "fal fa-exclamation-triangle"
                            : entry.status == "PENDING"
                              ? "fal fa-bell"
                              : "fal fa-info"
                })
            ]
        }
    },
    {
        property: "actions",
        text: T("Actions"),
        displayType: "buttons",
        getValue: (entry) => {
            return [
                new Button({
                    text:
                        openedSubTables.value.indexOf(entry.jobId as string) == -1
                            ? T("Show history")
                            : T("Hide history"),
                    title:
                        openedSubTables.value.indexOf(entry.jobId as string) == -1
                            ? T("Show history")
                            : T("Hide history"),
                    icon: "fal fa-info-circle",
                    onClick: () => {
                        if (openedSubTables.value.indexOf(entry.jobId as string) != -1) {
                            openedSubTables.value = openedSubTables.value.filter((id) => {
                                return id != entry.jobId
                            })
                        } else {
                            openedSubTables.value.push(entry.jobId)
                        }
                    },
                    disabled: false,
                    loading: false
                })
            ]
        }
    },
    {
        displayType: "subTable",
        getObjects: (entry) => {
            return entry.stateHistory
        },
        isOpened: (entry) => {
            return openedSubTables.value.indexOf(entry.jobId as string) != -1
        },
        entries: [
            {
                property: "issued",
                title: T("Issued"),
                text: T("Issued"),
                displayType: "text",
                getValue: (subEntry) => {
                    return timeHelpers.formatDate(
                        subEntry.issued * 1000,
                        timeHelpers.getDateFormatI18n(true)
                    )
                }
            },
            {
                property: "status",
                text: T("xState"),
                displayType: "labels",
                getValue: (subEntry) => {
                    return [
                        new Label({
                            title:
                                subEntry.status == "ERROR"
                                    ? T(subEntry.statusText)
                                    : T(stringHelpers.capitalizeFirstLetter(subEntry.status)),
                            text: T(stringHelpers.capitalizeFirstLetter(subEntry.status)),
                            class:
                                subEntry.status == "ERROR"
                                    ? "bg-red"
                                    : subEntry.status == "FINISHED"
                                      ? "bg-green"
                                      : "",
                            icon:
                                subEntry.status == "ERROR"
                                    ? "fal fa-exclamation-triangle"
                                    : subEntry.status == "PENDING"
                                      ? "fal fa-bell"
                                      : "fal fa-info"
                        })
                    ]
                }
            },
            {
                property: "statusText",
                title: "Info",
                text: "Info",
                displayType: "text",
                getValue: (subEntry) => {
                    return T(subEntry.statusText)
                }
            }
        ]
    }
]

const getJobList = async () => {
    let data
    if (activePage.value == "uscProfiles") {
        data = config.canUseNewObjectType("uscProfiles")
            ? await products.unifiedSecurityConsole.uscProfiles.getProfileHistoryJobsFromApi(
                  activeAccountId.value,
                  objectId.value
              )
            : await objectStores.uscProfiles.getProfileHistoryJobsFromApi(
                  activeAccountId.value,
                  objectId.value
              )
    } else if (activePage.value == "uscUtms") {
        if (config.canUseNewObjectType("uscProfiles")) {
            products.unifiedSecurityConsole.uscProfiles.queries.getObjectsFromApi(
                activeAccountId.value
            )
        } else {
            useStore().dispatch(ActionTypes.getObjectInfos, {
                accountId: activeAccountId.value,
                objectTypes: ["uscProfiles"]
            })
        }
        data = config.canUseNewObjectType("uscProfiles")
            ? await products.unifiedSecurityConsole.uscProfiles.getUTMHistoryJobsFromApi(
                  activeAccountId.value,
                  objectId.value
              )
            : await objectStores.uscProfiles.getUTMHistoryJobsFromApi(
                  activeAccountId.value,
                  objectId.value
              )
    }

    if (data && !(data instanceof Error)) {
        thisData.value = data
    }
}
onMounted(() => {
    getJobList()
})
defineExpose({
    thisData,
    getJobList
})
</script>
<template>
    <div>
        <TableNext
            :selectable-columns="tableInfo"
            :object-list="thisData || []"
            :has-options="false"
            :isLoading="table.loading"
        ></TableNext>
    </div>
</template>
