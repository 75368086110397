<script setup lang="ts">
import i18n, { T } from "@/classes/i18n"
import getterHelpers from "@/helpers/helpers.getters"
import moment from "moment"
import { computed, onMounted, ref, watch } from "vue"
import buttonComponent from "../components/button/button.vue"
import inputDate from "../inputtypes/input-date.vue"
const LANG = i18n.getLanguage()
const props = withDefaults(
    defineProps<{
        properties: {
            existingDate?: string
        }
    }>(),
    {
        properties: () => {
            return {
                existingDate: ""
            }
        }
    }
)
const activeAccountId = computed(() => {
    return getterHelpers.useStore()?.state.session.activeAccountId || ""
})
const today = moment().format("YYYY/MM/DD")

let dateValue = ref(<any>undefined)

onMounted(() => {
    if (props.properties.existingDate) {
        dateValue.value = props.properties.existingDate
    }
    updateButtons()
})

const setDate = (value: string) => {
    dateValue.value = value
}

const canSaveInput = computed(() => {
    let result = true
    if (props.properties.existingDate == dateValue.value) {
        result = false
    }
    if (dateValue.value?.length < 1) {
        result = false
    }
    return result
})
const canDeleteInput = computed(() => {
    let result = true
    if (dateValue.value == "" || dateValue.value == undefined) {
        result = false
    }
    return result
})

const updateButtons = () => {
    const modalButtons =
        getterHelpers.useStore().state.session.accounts[activeAccountId.value].modals[0].buttons
    if (canSaveInput.value) {
        if (modalButtons.length == 3) {
            modalButtons[2].disabled = false
        } else {
            modalButtons[1].disabled = false
        }
    } else {
        if (modalButtons.length == 3) {
            modalButtons[2].disabled = true
        } else {
            modalButtons[1].disabled = true
        }
    }

    if (canDeleteInput.value) {
        if (modalButtons?.length == 3) {
            modalButtons[1].disabled = false
        }
    } else {
        if (modalButtons?.length == 3) {
            modalButtons[1].disabled = true
        }
    }
}

watch(canSaveInput, () => {
    updateButtons()
})
watch(canDeleteInput, () => {
    updateButtons()
})

defineExpose({
    setDate,
    dateValue
})
</script>
<template>
    <div>
        <p>
            {{
                T(
                    "Here you have the possibility to plan a one-time update for your UTM. Enter a date and the UTM will check for updates on that day."
                )
            }}
        </p>
        <p>
            {{
                T(
                    'Information on the planned updates can be found in the "Cloud Scheduler Log" section of the UTM overview.'
                )
            }}
        </p>
        <div class="row form-group padding-xs-t-2">
            <div class="first col-xs-24 col-lg-6 col-xl-7">
                <label class="control-label inputname" for="tokenAdditionals">
                    {{ T("Search for updates on") }}
                </label>
            </div>
            <div class="input col-xs-24 col-lg-8">
                <label>
                    <input-date
                        v-model="dateValue"
                        :date-time="true"
                        :value="dateValue"
                        :placeholder="T('Select a date')"
                        :min-date="today"
                    ></input-date>
                </label>
            </div>
            <div class="desc col-xs-24 col-lg-10 col-xl-9">
                <buttonComponent
                    :button-options="{
                        text: T('Now'),
                        onClick: () => {
                            setDate(
                                moment().format(
                                    LANG == 'de' ? 'DD.MM.YYYY HH:mm' : 'DD/MM/YYYY HH:mm'
                                )
                            )
                            //const modalButtons = getterHelpers.useStore().state.session.accounts[activeAccountId.value].modals[0].buttons
                        }
                    }"
                ></buttonComponent>
            </div>
        </div>
    </div>
</template>
