<script setup lang="ts">
import { T } from "@/classes/i18n"
import { useUscSettingsStore } from "@/classes/objectTypes/unifiedSecurityConsole/settingsStore"
import getterHelpers from "@/helpers/helpers.getters"
import { computed, onMounted } from "vue"
import inputToggleComponent from "../inputtypes/input-toggle.vue"
const activeAccountId = computed(function () {
    return getterHelpers.useStore().state.session.activeAccountId
})
const accountInitialized = computed(function () {
    return getterHelpers.useStore().state.session.accounts[activeAccountId.value || ""].initialized
})
const activeAccount = computed(function () {
    return getterHelpers.useStore().state.session.accounts[activeAccountId.value || ""]
})

const isResellerTenant = computed(() => {
    return activeAccount.value?.unifiedSecurity?.account?.isReseller || false
})

const crossTenantVpns = computed({
    get: function () {
        return activeAccountId.value
            ? useUscSettingsStore().getSetting(
                  activeAccountId.value,
                  "ENABLE_CROSS_TENANT_SUN_CONFIGURATION"
              )
            : false
    },
    set: function (value: boolean) {
        if (activeAccountId.value && value !== crossTenantVpns.value) {
            useUscSettingsStore().setSetting(
                activeAccountId.value,
                "ENABLE_CROSS_TENANT_SUN_CONFIGURATION",
                value
            )
        }
    }
})

onMounted(() => {})

defineExpose({})
</script>
<template>
    <section class="padding-xs padding-md-2 padding-lg-4 pagecontent">
        <div class="row">
            <div class="col-xs-24 col-print-24">
                <div class="box-shadow">
                    <div class="box-content-2">
                        <template v-if="isResellerTenant">
                            <h4>
                                <i class="fal fa-fw fa-puzzle"></i>
                                {{ T("Tenant-übergreifende VPN-Konfiguration") }}
                            </h4>
                            <div class="row padding-xs-y form-group">
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label for="control-label inputname" class="control-label">
                                        {{ T("Aktiv") }}
                                    </label>
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-7">
                                    <inputToggleComponent
                                        v-model="crossTenantVpns"
                                    ></inputToggleComponent>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-12">
                                    <p class="input-description">
                                        Wenn diese Konfiguration aktiviert ist, können Ihre UTMs
                                        über den Reseller-Account einer Tenant-übergreifenden
                                        VPN-Konfiguration hinzugefügt werden.
                                    </p>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
