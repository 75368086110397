const applicationNames = {
    bird: {
        en: "Routing Server",
        de: "Routing Server"
    },
    bird6: {
        en: "Routing Server IPv6",
        de: "Routing Server IPv6"
    },
    charon: {
        en: "IPSec",
        de: "IPSec"
    },
    checkrcpt: {
        en: "SMTP RCPT Check",
        de: "SMTP RCPT Check"
    },
    clamd: {
        en: "ClamAV Virusscanner",
        de: "ClamAV Virenscanner"
    },
    collectd: {
        en: "Statistics",
        de: "Statistiken"
    },
    csamd: {
        en: "Cyren Virusscanner",
        de: "Cyren Virenscanner"
    },
    ctasd: {
        en: "Spamfilter",
        de: "Spamfilter"
    },
    cvpn: {
        en: "Clientless VPN",
        de: "Clientless VPN"
    },
    dhcpd: {
        en: "DHCP-Server",
        de: "DHCP-Server"
    },
    dhcprelay: {
        en: "DHCP-Relay",
        de: "DHCP-Relay"
    },
    dovecot: {
        en: "IMAP Daemon",
        de: "IMAP Daemon"
    },
    fetchmail: {
        en: "Mail-Connector",
        de: "Mail-Connector"
    },
    "postfix-policy-greylist": {
        en: "Greylisting Filter",
        de: "Greylisting Filter"
    },
    "postfix-policy-spf": {
        en: "SPF Filter",
        de: "SPF Filter"
    },
    http_proxy: {
        en: "HTTP-Proxy",
        de: "HTTP-Proxy"
    },
    ipsec: {
        en: "IPSEC",
        de: "IPSEC"
    },
    ipsec_starter: {
        en: "IPSec",
        de: "IPSec"
    },
    kryptochef: {
        en: "ACME",
        de: "ACME"
    },
    l2tpd: {
        en: "L2TP VPN",
        de: "L2TP VPN"
    },
    mailfilter: {
        en: "Mailfilter",
        de: "Mailfilter"
    },
    named: {
        en: "Nameserver",
        de: "Nameserver"
    },
    ntpd: {
        en: "NTP-Server",
        de: "NTP-Server"
    },
    openvpn: {
        en: "SSL-VPN",
        de: "SSL-VPN"
    },
    pop3_proxy: {
        en: "POP3-Proxy",
        de: "POP3-Proxy"
    },
    rproxy: {
        en: "Reverse-Proxy",
        de: "Reverse-Proxy"
    },
    smtpd: {
        en: "Mailrelay",
        de: "Mailrelay"
    },
    snmpd: {
        en: "SNMP-Server",
        de: "SNMP-Server"
    },
    spalertd: {
        en: "Alerting Center",
        de: "Alerting Center"
    },
    spf2bd: {
        en: "IPS Bans",
        de: "IPS Sperrungen"
    },
    squid: {
        en: "HTTP-Proxy",
        de: "HTTP-Proxy"
    },
    "squid-reverse": {
        en: "Reverse-Proxy",
        de: "Reverse-Proxy"
    },
    sshd: {
        en: "SSH-Server",
        de: "SSH-Server"
    },
    syslog: {
        en: "Log",
        de: "Log"
    },
    "Paket filter": {
        en: "Paketfilter",
        de: "Paketfilter"
    },
    wap: {
        en: "WLAN-Server",
        de: "WLAN-Server"
    },
    webfilter: {
        en: "Content-Filter",
        de: "Content-Filter"
    },
    webserver: {
        en: "HTTP-Server",
        de: "HTTP-Server"
    },
    winbindd: {
        en: "Active Direcory Service",
        de: "Active Direcory Dienst"
    },
    voip_proxy: {
        en: "VOIP Proxy",
        de: "VOIP Proxy"
    }
}

export default applicationNames
