<script setup lang="ts">
import NewIcon from "@/templates/components/icon/newIcon"
import NewIconComponent from "@/templates/components/icon/newIconComponent.vue"
import tableNext, { type TableEntryInfo } from "@/templates/components/tableNext.vue"
import Tooltip from "@/templates/directives/tooltip/tooltip"
import { ref } from "vue"
import InputVueSelect from "../inputtypes/input-vue-select.vue"
const testIcon = new NewIcon({
    iconType: "fontawesomeBrands",
    iconName: "windows",
    tooltip: new Tooltip({
        contents: ["test"]
    })
})

let json = [
    {
        id: "679107fefcf3c86402da4a15",
        apiKey: "26931b85-03ce-49c6-a35b-d56b707d22be",
        roles: ["Allen", "Wells", "Rush"],
        test: "",
        selected: [],
        children: [
            {
                id: "679107fe25dd529ab8223338",
                apiKey: "2a125f04-036b-4c02-94d1-fb96e0b54958",
                roles: ["Morris"],
                test: "",
                selected: []
            },
            {
                id: "679107fe882cabba5a1fee76",
                apiKey: "07aab7e1-143a-4c35-b350-e1b1b97315ee",
                roles: ["Nadine"],
                test: "",
                selected: []
            },
            {
                id: "679107fe44decfc15bfe668d",
                apiKey: "23db2860-79bb-4137-8139-3836ca6337e3",
                roles: ["Tisha"],
                test: "",
                selected: []
            },
            {
                id: "679107fe71a0fbd5fac87c84",
                apiKey: "fdf7c58b-1f56-41a7-a038-b26247c9d0c5",
                roles: ["Elsa", "Anastasia", "Estrada", "Bean", "Summers"],
                test: "",
                selected: []
            },
            {
                id: "679107fe9ad8f3c52f43869c",
                apiKey: "f64f0199-f722-4d1c-bac0-396be4cd6db9",
                roles: ["Tamra", "Decker", "Downs", "Millie", "Toni"],
                test: "",
                selected: []
            },
            {
                id: "679107fe711d554dc05a77ca",
                apiKey: "92f89011-b360-4382-a754-ea11ee85ee4a",
                roles: ["Nannie", "Nixon", "Pacheco", "Cleo", "Mcleod"],
                test: "",
                selected: []
            },
            {
                id: "679107feadb9b48c3a4dae8c",
                apiKey: "335870af-53d2-4167-88e0-f21a5435c4ca",
                roles: ["Good", "Knapp"],
                test: "",
                selected: []
            },
            {
                id: "679107fee5f3a2aa6ca075ec",
                apiKey: "f7d6fd97-a081-4491-b7ae-2dbb500c8203",
                roles: ["Pickett", "Hensley", "Chapman"],
                test: "",
                selected: []
            },
            {
                id: "679107fe1641a6bf51c7b35b",
                apiKey: "baa4d8c9-bba2-41a8-92df-04dfca5dbb27",
                roles: ["Dora", "Cervantes", "Mclean"],
                test: "",
                selected: []
            },
            {
                id: "679107fe90bdd6ae965cdf61",
                apiKey: "6c2b87ee-11f9-487f-a99c-209d57ae4ed1",
                roles: ["Higgins", "Richardson", "Vanessa", "Nunez"],
                test: "",
                selected: []
            },
            {
                id: "679107fe78767905aad982cd",
                apiKey: "5fd8c1c5-4064-4665-8d62-a83ac3df5b72",
                roles: ["Stuart", "Violet"],
                test: "",
                selected: []
            },
            {
                id: "679107fe5c33fd4aea104236",
                apiKey: "356c2498-dd21-4bbf-b568-c796c7aa7842",
                roles: ["Vega", "Sellers", "Potts", "Carver", "Lillie"],
                test: "",
                selected: []
            },
            {
                id: "679107fe025b71e0eaef5260",
                apiKey: "d4c9d95e-7da0-4a74-bec6-49ab9c2a6c5f",
                roles: ["Branch", "Jarvis"],
                test: "",
                selected: []
            },
            {
                id: "679107fe0397ca4b92f1698a",
                apiKey: "ead18843-84f6-478b-9f45-59f70edbbb58",
                roles: ["Horton", "Chan", "Elma"],
                test: "",
                selected: []
            },
            {
                id: "679107fe8c8bde15437e0acb",
                apiKey: "219983a4-7091-44be-b34b-b8fdf4ae9adb",
                roles: ["Natalie", "Nita"],
                test: "",
                selected: []
            },
            {
                id: "679107fe43950ee1c8025228",
                apiKey: "65db0ce2-6333-4f65-8096-dada48837421",
                roles: ["Carmella", "Dolores", "Blanchard"],
                test: "",
                selected: []
            },
            {
                id: "679107fe49adf178fe54a4f5",
                apiKey: "2811f6c0-2529-468b-b721-8cdd50bad16d",
                roles: ["Erin"],
                test: "",
                selected: []
            },
            {
                id: "679107fe43e608e79baa8d48",
                apiKey: "70d2282d-efd6-49fb-a148-1075017c916d",
                roles: ["Thomas"],
                test: "",
                selected: []
            },
            {
                id: "679107fed16625b75cdea92a",
                apiKey: "baf9e812-4e49-4e76-87d0-ee923a6dc118",
                roles: ["Leona", "Foley", "Vera", "Celia", "Lara"],
                test: "",
                selected: []
            },
            {
                id: "679107fea98f44e187a7ec5e",
                apiKey: "4847cf8d-434a-43f5-992b-c453b1f75f1e",
                roles: ["Hattie", "Wheeler"],
                test: "",
                selected: []
            },
            {
                id: "679107fe27eedf9686dd4daa",
                apiKey: "11be25f5-dcc0-48e9-92db-ae9adc9cf2cf",
                roles: ["Meadows", "Calderon", "Morse"],
                test: "",
                selected: []
            },
            {
                id: "679107fe03bd4aa4efeac47d",
                apiKey: "c7565d02-e7c7-4821-ab86-c29888af1961",
                roles: ["Haley"],
                test: "",
                selected: []
            },
            {
                id: "679107fe4c69671855d1ff43",
                apiKey: "ccdae5cf-d04b-4b62-81ef-fc7b2cc4f69e",
                roles: ["Marshall", "Elba", "Mccormick"],
                test: "",
                selected: []
            },
            {
                id: "679107fe421561f037734d7d",
                apiKey: "49233dab-b772-4ad4-9f21-b7b7b34f0f8a",
                roles: ["Greene", "Hahn", "Susanne"],
                test: "",
                selected: []
            },
            {
                id: "679107fed75400477b6e3c6e",
                apiKey: "2f95dce2-3ee3-4caf-a10a-07d0a4cda179",
                roles: ["Jenny", "Deanne", "Reeves", "Benjamin", "Walton"],
                test: "",
                selected: []
            },
            {
                id: "679107fe2f8b7a4647d47921",
                apiKey: "c13f36d9-475f-4b4b-baf4-b7fe61767f1e",
                roles: ["Fields", "Wynn"],
                test: "",
                selected: []
            },
            {
                id: "679107fec5c1571dc8ad69f9",
                apiKey: "3f21c374-3f59-4d41-a225-3de3c8d21b47",
                roles: ["Marcie", "Willa", "Miller"],
                test: "",
                selected: []
            },
            {
                id: "679107fe59c2988e9112490b",
                apiKey: "e71f845b-5822-4885-b22a-7f09250ae8a6",
                roles: ["Perry", "Shauna", "Hendrix", "Ratliff"],
                test: "",
                selected: []
            },
            {
                id: "679107fe9de98ac2963fc0ee",
                apiKey: "4933da91-c86c-4698-a92f-f0e89e402569",
                roles: ["Dena", "Owen", "Darcy", "Murray"],
                test: "",
                selected: []
            },
            {
                id: "679107fe953369b23a729a2b",
                apiKey: "a7ab522d-2136-4f27-9d6d-cfb5bbf94bf1",
                roles: ["Agnes", "Theresa", "Jeri", "Rochelle", "Lara"],
                test: "",
                selected: []
            },
            {
                id: "679107fe8028e0e9a27dd5aa",
                apiKey: "0114ef93-eac6-4918-82d8-3a13714e11f8",
                roles: ["Margarita", "Cindy"],
                test: "",
                selected: []
            },
            {
                id: "679107fecd1fa5e83aaa825d",
                apiKey: "02ceeb34-41b3-4bda-88c1-3dd0a0c7d1f2",
                roles: ["Huffman", "Carr"],
                test: "",
                selected: []
            },
            {
                id: "679107fecca03c788fa0b401",
                apiKey: "46905a25-399c-4d12-9db6-78048df482ca",
                roles: ["Estelle", "Marina", "Sandy", "Leonard"],
                test: "",
                selected: []
            },
            {
                id: "679107fe08bcdb7405d4568a",
                apiKey: "beb9519f-2857-403f-b613-3e33714dc881",
                roles: ["Dianna", "Rosalie", "Julianne"],
                test: "",
                selected: []
            },
            {
                id: "679107feab957e97a3a2cdb4",
                apiKey: "e2a38925-617c-44aa-89db-d460ec9b5f12",
                roles: ["Natasha", "Michele", "Ashlee"],
                test: "",
                selected: []
            },
            {
                id: "679107fec33f4a3b1066f6af",
                apiKey: "4a133320-7d29-4609-9ca8-fe453ab0c9c3",
                roles: ["Fleming", "Simpson"],
                test: "",
                selected: []
            },
            {
                id: "679107fe45e8e1a1049d9028",
                apiKey: "94a51646-1c28-496f-833d-0b8a3ccf8a40",
                roles: ["Mcdaniel", "Gill", "Lynn"],
                test: "",
                selected: []
            },
            {
                id: "679107febef47914282187e8",
                apiKey: "3342806e-419f-48ec-9f19-6c8aa2c38a9d",
                roles: ["Ethel", "Cassandra", "Campos", "Augusta"],
                test: "",
                selected: []
            },
            {
                id: "679107fed0a614d3220ba83d",
                apiKey: "81948ee0-983f-42b2-9528-ec3d47f3f01e",
                roles: ["Elvira", "Isabel"],
                test: "",
                selected: []
            },
            {
                id: "679107fe71b32968e6b9e164",
                apiKey: "68dcac21-5619-4574-b4ab-5017a3e8e6ec",
                roles: ["Petersen", "Chang", "Monroe"],
                test: "",
                selected: []
            },
            {
                id: "679107fe3668ed68a44f0c6b",
                apiKey: "218bfde5-82c5-49ad-91b2-7bd6d743aee5",
                roles: ["Leblanc"],
                test: "",
                selected: []
            },
            {
                id: "679107febb34339ba70438ab",
                apiKey: "f5432f1e-42ff-4142-b6cb-e90e4961f4f5",
                roles: ["Angie", "Holland"],
                test: "",
                selected: []
            },
            {
                id: "679107fe60a1792433578ce5",
                apiKey: "713afccd-cf74-45f2-988e-6ca8a3355fc9",
                roles: ["Brandi", "Henson"],
                test: "",
                selected: []
            },
            {
                id: "679107fe8f28f830b8f95561",
                apiKey: "d80b6418-cf4f-440a-ac1b-5ff588d4bf9e",
                roles: ["Wolfe", "Williams", "Alston"],
                test: "",
                selected: []
            },
            {
                id: "679107fe63c958c0ef92f82d",
                apiKey: "5d44f506-20f1-4e5a-9ef8-bfca763a2423",
                roles: ["Ida"],
                test: "",
                selected: []
            },
            {
                id: "679107fe2702965de252e738",
                apiKey: "c3b5042d-cbf4-4e3c-a602-2378a0706d07",
                roles: ["Davis", "Mercer"],
                test: "",
                selected: []
            },
            {
                id: "679107fe9b364adb1c22b07e",
                apiKey: "e5f0dab7-57ac-4fee-be0e-53f20a1439d0",
                roles: ["Gould", "Yolanda", "Aisha"],
                test: "",
                selected: []
            },
            {
                id: "679107fef2fae3c9c427c572",
                apiKey: "bd95abde-2314-4920-afc7-5937971450f1",
                roles: ["Todd"],
                test: "",
                selected: []
            },
            {
                id: "679107fe1926b79f2671deba",
                apiKey: "7809b9f2-a19b-4b94-95e0-b10b34ca1a80",
                roles: ["Allyson", "Melinda", "Edwina"],
                test: "",
                selected: []
            },
            {
                id: "679107fe652f1c714775c24f",
                apiKey: "1343c019-f306-4b1e-bd6a-6f77b68fd7bb",
                roles: ["Lorna", "Conley", "Watson", "Gracie", "Weaver"],
                test: "",
                selected: []
            },
            {
                id: "679107fe9e06bab07c9ff9e1",
                apiKey: "c94a673a-08ff-4165-ae60-756f3574ff78",
                roles: ["Lucille", "Terri", "Luella", "Ana", "Jerri"],
                test: "",
                selected: []
            },
            {
                id: "679107fe189e199700e15434",
                apiKey: "e3a7a551-ab43-4f7c-97a6-85a8398c1af2",
                roles: ["Blackwell"],
                test: "",
                selected: []
            },
            {
                id: "679107fe9658fda7c862fe88",
                apiKey: "74938484-8ef1-401b-bda9-a93d29dbb204",
                roles: ["Stevenson", "Banks", "Katelyn", "Laurel", "Opal"],
                test: "",
                selected: []
            },
            {
                id: "679107fec72f4e8e4fda1592",
                apiKey: "4887bdca-f93d-4f10-bb11-9308e0b4bb11",
                roles: ["Elliott", "Tameka"],
                test: "",
                selected: []
            }
        ]
    }
]

let selectableColumns = <TableEntryInfo[]>[
    {
        displayType: "text",
        property: "id",
        text: "ID",
        getValue: (entry) => {
            return entry.id
        },
        getSortValue: (entry) => {
            return entry.id
        }
    },
    {
        displayType: "subTable",
        property: "children",
        isOpened: true,
        subTableMaxHeight: 300,
        getObjects(entry, parentEntry) {
            return entry.children
        },
        entries: [
            {
                displayType: "text",
                property: "id",
                text: "ID",
                getValue: (entry) => {
                    console.log(entry)
                    return entry.id
                },
                getSortValue: (entry) => {
                    console.log(entry)
                    return entry.id
                }
            }
        ]
    }
]

const selectValue = ref([])
</script>
<template>
    <section class="padding-xs padding-md-2 padding-lg-4 pagecontent">
        <div class="box-shadow padding-xs-2">
            <InputVueSelect
                v-model="selectValue"
                :select-options="[
                    { id: 'option1', text: 'Option 1' },
                    { id: 'option2', text: 'Option 2' }
                ]"
                :multiple="true"
                :tags="true"
            ></InputVueSelect>

            <NewIconComponent :iconOptions="testIcon"></NewIconComponent>
            <tableNext
                :selectableColumns="selectableColumns"
                :objectList="json"
                :subTablePaddingLeft="45"
            ></tableNext>
        </div>
    </section>
</template>
