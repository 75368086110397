import config from "@/classes/config"
import { type shemaErrors } from "@/classes/genericObjectStore"
import { T } from "@/classes/i18n"
import objectStores from "@/classes/init"
import devLog from "@/classes/log"
import dialogs from "@/dialogs/dialogs"
import deviceHelpers from "@/helpers/helpers.devices"
import encodingHelpers from "@/helpers/helpers.encoding"
import getterHelpers from "@/helpers/helpers.getters"
import jsonHelpers from "@/helpers/helpers.json"
import numberHelpers from "@/helpers/helpers.numbers"
import stringHelpers from "@/helpers/helpers.strings"
import tenantHelpers from "@/helpers/helpers.tenants"
import timeHelpers from "@/helpers/helpers.time"
import requestHandler from "@/queries/requests"
import router from "@/router/router"
import { MutationTypes } from "@/store/vuex.store"
import Button from "@/templates/components/button/button"
import products from ".."
import IntroductionComponent from "../../../templates/components/introductions/usc-zt-introduction.vue"
import ObjectType, {
    type GetPropertiesObjectList,
    type ItemlistItemDetail,
    type Label,
    type MenuEntry,
    type ObjectTypeObject,
    type ObjectTypePayload
} from "../objectType"

export interface UtmProfile {
    name: string
    id: string
    priority?: number
    profile?: {
        autoUpdateOnce: boolean
        autoUpdateCronValues: string
        autoUpdateEnabled: boolean
        update: {
            enabled: boolean
            enabledUpdate: boolean
            cronValue: string
        }
    }
    template?: {
        tenantDomains?: string[]
        uuid: string
        childProfiles?: { [tenantDoman: string]: string } // tenantDomain:uuid
    }
    status: string
    tags: string[]
    tenantDomain: string
    comment: string
    utms: string[]
}

class UscProfiles extends ObjectType<UtmProfile> {
    private usedStatusSet = new Set(["USED", "ERROR"])

    constructor(payload: ObjectTypePayload<UtmProfile>) {
        super(payload)

        this.itemlist.getSortingOptions = () => {
            return [
                {
                    id: "name",
                    text: "Name"
                },
                {
                    id: "priority",
                    text: T("Priority")
                }
            ]
        }

        this.itemlist.getToolbarButtons = (accountId, itemlistComponent) => {
            let thisToolbarEntries = []

            thisToolbarEntries.push({
                icon: "fal fa-plus",
                title: T("Add profile"),
                link: "#add-tenant-" + tenantHelpers.getTenantDomain(accountId) + "-usc-profiles",
                id: "addUscProfile"
            })

            return thisToolbarEntries
        }

        this.itemlistItem.hasCheckbox = () => {
            return true
        }

        this.itemlistItem.getMenuEntries = (accountId, item) => {
            let menuEntries: MenuEntry[] = []
            if (item) {
                let objectId = item[
                    this.options.objectTypeInfo.primaryKeyProperty.property as keyof typeof item
                ] as string

                if (item?.template?.childProfiles != undefined) {
                    menuEntries.push(
                        new Button({
                            text: T("Edit"),
                            title: T("Edit"),
                            icon: "fal fa-edit",
                            onClick: () => {
                                router.navigate(
                                    "edit-tenant-" +
                                        tenantHelpers.getTenantDomain(accountId) +
                                        "-usc-profiles-" +
                                        objectId
                                )
                            }
                        })
                    )
                }
                if (item?.template == undefined) {
                    menuEntries.push(
                        new Button({
                            text: T("Edit"),
                            title: T("Edit"),
                            icon: "fal fa-edit",
                            onClick: () => {
                                router.navigate(
                                    "edit-tenant-" +
                                        tenantHelpers.getTenantDomain(accountId) +
                                        "-usc-profiles-" +
                                        objectId
                                )
                            }
                        })
                    )
                }

                if (item?.template?.childProfiles != undefined) {
                    menuEntries.push(
                        new Button({
                            text: T("Delete"),
                            title: T("Delete"),
                            icon: "fal fa-trash",
                            onClick: () => {
                                this.getDeleteTemplateObjectDialog(accountId, item)
                            }
                        })
                    )
                } else if (item?.template?.uuid == undefined) {
                    menuEntries.push(
                        new Button({
                            text: T("Delete"),
                            title: T("Delete"),
                            icon: "fal fa-trash",
                            onClick: () => {
                                this.dialogs.getDeleteObjectDialog(accountId, item)
                            }
                        })
                    )
                }
            }

            return menuEntries
        }

        const dateFormat = timeHelpers.getDateFormatI18n(false)

        this.itemlistItem.getDetails = (accountId, item) => {
            let result: ItemlistItemDetail[] = []

            let thisTagsArray = item?.tags?.map(function (tag) {
                return {
                    id: tag,
                    text: tag,
                    title: tag,
                    onClick: function () {},
                    displayType: "label"
                }
            })

            let utms = config.canUseNewObjectType("uscUtms")
                ? products.unifiedSecurityConsole.uscUtms
                      .useStore?.()
                      .getObjectStoreObjects(
                          String(getterHelpers.useStore()?.state.session.activeAccountId)
                      ) || []
                : objectStores.uscUtms.getObjectsFromStore(
                      String(getterHelpers.useStore()?.state.session.activeAccountId)
                  ) || []

            const thisUtms: Label[] = utms
                ?.filter((utm) => {
                    return item ? item.utms.indexOf(utm.utmId) != -1 : false
                })
                ?.map((utm) => {
                    return {
                        text: deviceHelpers.getAliasedShortDeviceId(utm.utmId, utm.utmname, false),
                        title: deviceHelpers.getAliasedShortDeviceId(utm.utmId, utm.utmname, false),
                        displayType: "label",
                        onClick: () => {
                            router.navigate(
                                "show-tenant-" +
                                    tenantHelpers.getTenantDomain(accountId) +
                                    "-usc-utms-" +
                                    utm.utmId
                            )
                        }
                    }
                })
            result.push({
                iconClass: "fal fa-tags",
                key: T("Tags"),
                title: T("Tags"),
                [item ? "labels" : "value"]: item
                    ? thisTagsArray
                    : '<span class="content-placeholder" style="width:' +
                      numberHelpers.getRandomArbitrary(50, 250) +
                      'px;" title="' +
                      T("Loading...") +
                      '"></span>',
                editableContent: undefined
            })
            if (item?.template?.tenantDomains) {
                result.push({
                    iconClass: "fal fa-tags",
                    key: T("Tenants"),
                    title: T("Tenants"),
                    [item?.template?.tenantDomains?.length ? "labels" : "value"]: item?.template
                        ?.tenantDomains.length
                        ? item?.template?.tenantDomains.map((tenantDomain: string) => {
                              let accountInfo = getterHelpers
                                  .useStore()
                                  ?.getters.getAccountInfo(tenantHelpers.getAccountId(tenantDomain))
                              return {
                                  text: accountInfo?.accountname + " (" + tenantDomain + ")",
                                  title: accountInfo?.accountname + " (" + tenantDomain + ")",
                                  displayType: "label"
                              }
                          })
                        : "",
                    editableContent: undefined
                })
            } else {
                result.push({
                    iconClass: "fal fa-tags",
                    key: T("UTMs"),
                    title: T("UTMs"),
                    [item?.utms.length ? "labels" : "value"]: item?.utms.length ? thisUtms : "",
                    editableContent: undefined
                })
            }
            result.push({
                iconClass: "fal fa-sync",
                key: T("Auto Updates"),
                title: T("Auto Updates"),
                value: item
                    ? item.profile?.autoUpdateEnabled || item.profile?.update?.enabledUpdate
                        ? item.profile?.autoUpdateOnce
                            ? T("Once (deprecated)")
                            : T("Enabled")
                        : T("Disabled")
                    : '<span class="content-placeholder" style="width:' +
                      numberHelpers.getRandomArbitrary(50, 250) +
                      'px;" title="' +
                      T("Loading...") +
                      '"></span>',
                editableContent: undefined
            })

            return result
        }

        this.itemlistItem.onClick = (accountId, item) => {
            let objectId = item[
                this.options.objectTypeInfo.primaryKeyProperty.property as keyof typeof item
            ] as string
            router.navigate(
                "edit-tenant-" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "-usc-profiles-" +
                    objectId
            )
        }

        this.itemlistItem.getDisabledState = (accountId, item) => {
            return false
        }

        this.itemlistItem.isClickable = (accountId, item) => {
            return true
        }
        this.itemlistItem.getLabels = (accountId: string, item: ObjectTypeObject<UtmProfile>) => {
            let result: Label[] = []
            if (item?.template?.tenantDomains != undefined) {
                result.push({
                    text: T("Cross-tenant profile"),
                    title: T("Cross-tenant profile"),
                    icon: "fa fa-circle-nodes"
                })
            }
            if (item?.priority != null) {
                result.push({
                    title: T("Priority") + ": " + item?.priority.toString(),
                    text: item.priority.toString(),
                    class: "",
                    icon: "fal fa-sort"
                })
            }
            if (item?.template?.tenantDomains == undefined && item?.template?.uuid != undefined) {
                result.push({
                    text: T("Generated"),
                    title: T("Generated from Cross-tenant profile"),
                    icon: "fa fa-cog"
                })
            }
            return result
        }

        this.itemlist.getIntroductionComponent = () => {
            return IntroductionComponent
        }

        this.queries.updateObjectFromApi = async (
            accountId,
            objectId,
            object,
            customerId?,
            updateStore = true,
            altMethod?
        ) => {
            let result: UtmProfile | Error | shemaErrors
            try {
                let response: any
                let originalObject = this.useStore?.().getObjectStoreObject(accountId, objectId)
                if (
                    object.template?.tenantDomains != undefined ||
                    (originalObject && originalObject.template?.tenantDomains != undefined)
                ) {
                    const existingTenants: string[] =
                        originalObject?.template?.childProfiles != undefined
                            ? Object.keys(originalObject.template.childProfiles)
                            : []
                    let addProfiles: string[] = []
                    let updateProfiles: string[] = []
                    let deleteProfiles: string[] = []

                    if (object.template?.tenantDomains?.length) {
                        if (!object.template.uuid) {
                            object.template.uuid = stringHelpers.generateUUID()
                        }

                        // GET PROFILES THAT SHOULD BE ADDED OR UPDATED
                        object.template?.tenantDomains.forEach((tenantDomain) => {
                            if (existingTenants.indexOf(tenantDomain) == -1) {
                                if (tenantHelpers.tenantExists(tenantDomain)) {
                                    addProfiles.push(tenantDomain)
                                }
                            } else {
                                if (
                                    originalObject?.template?.childProfiles?.[tenantDomain] !=
                                    undefined
                                ) {
                                    if (tenantHelpers.tenantExists(tenantDomain)) {
                                        updateProfiles.push(
                                            tenantDomain +
                                                "/" +
                                                originalObject.template.childProfiles[tenantDomain]
                                        )
                                    }
                                }
                            }
                        })
                    }
                    if (existingTenants.length) {
                        // GET PROFILES THAT SHOULD BE DELETED
                        existingTenants.forEach((tenantDomain) => {
                            if (
                                (object.template?.tenantDomains || []).indexOf(tenantDomain) == -1
                            ) {
                                if (
                                    originalObject?.template?.childProfiles?.[tenantDomain] !=
                                    undefined
                                ) {
                                    if (tenantHelpers.tenantExists(tenantDomain)) {
                                        deleteProfiles.push(
                                            tenantDomain +
                                                "/" +
                                                originalObject?.template.childProfiles[tenantDomain]
                                        )
                                    }
                                }
                            }
                        })
                    }
                    response = await requestHandler.request(
                        "PUT",
                        this.options.apiInfo.url +
                            "/tenants/" +
                            tenantHelpers.getTenantDomain(accountId) +
                            "/utms/profiles/" +
                            encodingHelpers.encodeURI(String(objectId)),
                        object
                    )

                    if (addProfiles.length || updateProfiles.length || deleteProfiles.length) {
                        // Create child Object
                        let childObject = jsonHelpers.copyObject(object)
                        // delete tenantDomains from childObject
                        delete childObject?.template?.tenantDomains
                        delete childObject?.template?.childProfiles

                        if (
                            addProfiles.length + updateProfiles.length + deleteProfiles.length >=
                            1
                        ) {
                            this.getManyTemplateRequestsDialog(
                                accountId,
                                "update",
                                childObject,
                                addProfiles,
                                updateProfiles,
                                deleteProfiles
                            )
                        } else {
                            if (addProfiles.length) {
                                // add profiles
                                for (let i = 0; addProfiles.length > i; i++) {
                                    const tenantDomain = addProfiles[i]
                                    await requestHandler.request(
                                        "POST",
                                        this.options.apiInfo.url +
                                            "/tenants/" +
                                            tenantDomain +
                                            "/utms/profiles",
                                        childObject
                                    )
                                }
                            }
                            if (updateProfiles.length) {
                                // update profiles
                                for (let i = 0; updateProfiles.length > i; i++) {
                                    const tenantDomain = updateProfiles[i].split("/")[0]
                                    const profileId = updateProfiles[i].split("/")[1]
                                    await requestHandler.request(
                                        "PUT",
                                        this.options.apiInfo.url +
                                            "/tenants/" +
                                            tenantDomain +
                                            "/utms/profiles/" +
                                            encodingHelpers.encodeURI(profileId),
                                        childObject
                                    )
                                }
                            }
                            if (deleteProfiles.length) {
                                // delete profiles
                                for (let i = 0; deleteProfiles.length > i; i++) {
                                    const tenantDomain = deleteProfiles[i].split("/")[0]
                                    const profileId = deleteProfiles[i].split("/")[1]
                                    await requestHandler.request(
                                        "DELETE",
                                        this.options.apiInfo.url +
                                            "/tenants/" +
                                            tenantDomain +
                                            "/utms/profiles/" +
                                            encodingHelpers.encodeURI(profileId)
                                    )
                                }
                            }
                        }
                    }
                } else {
                    response = await requestHandler.request(
                        "PUT",
                        this.options.apiInfo.url +
                            "/tenants/" +
                            tenantHelpers.getTenantDomain(accountId) +
                            "/utms/profiles/" +
                            encodingHelpers.encodeURI(String(objectId)),
                        object
                    )
                }
                if (response?.id) {
                    result = object
                    result.id = response.id
                    if (updateStore) {
                        this.useStore?.().setObjectTypeObject(
                            accountId,
                            object.id || result.id,
                            object
                        ) // Add UscProfile to store
                    }
                } else if (response.id) {
                    result = object
                    result.id = response.id
                    if (updateStore) {
                        this.useStore?.().setObjectTypeObject(
                            accountId,
                            object.id || result.id,
                            object
                        ) // Add UscProfile to store
                    }
                } else if (response.errors) {
                    throw response.errors
                } else if (response.error) {
                    throw new Error(response.error)
                } else {
                    throw new Error("Error updating UscProfile")
                }
            } catch (e: any) {
                result = e.responseJSON || e
                const message = e.responseJSON?.message || e.message
                devLog.log("uscProfiles", message, result, "error")
                throw result
            }
            return result
        }

        this.queries.addObjectToApi = async (
            accountId,
            object,
            customerId?,
            updateStore = true
        ) => {
            let result: UtmProfile | Error
            try {
                let response: any = {}
                if ((object.template?.tenantDomains?.length || 0) > 0) {
                    // adding template
                    // generate UUID

                    object.template.uuid = stringHelpers.generateUUID()

                    response = await requestHandler.request(
                        "POST",
                        this.options.apiInfo.url +
                            "/tenants/" +
                            tenantHelpers.getTenantDomain(accountId) +
                            "/utms/profiles",
                        object
                    )

                    // Create child Object
                    let childObject = jsonHelpers.copyObject(object)
                    // delete tenantDomains from childObject
                    delete childObject.template.tenantDomains

                    if (object.template.tenantDomains.length >= 1) {
                        this.getManyTemplateRequestsDialog(
                            accountId,
                            "add",
                            childObject,
                            object.template.tenantDomains
                        )
                    } else {
                        for (let i = 0; object.template.tenantDomains.length > i; i++) {
                            const tenantDomain = object.template.tenantDomains[i]
                            let thisResult = await requestHandler.request(
                                "POST",
                                this.options.apiInfo.url +
                                    "/tenants/" +
                                    tenantDomain +
                                    "/utms/profiles",
                                childObject
                            )
                        }
                        await this.queries.getObjectsFromApi(accountId)
                    }
                } else {
                    response = await requestHandler.request(
                        "POST",
                        this.options.apiInfo.url +
                            "/tenants/" +
                            tenantHelpers.getTenantDomain(accountId) +
                            "/utms/profiles",
                        object
                    )
                }
                if (response?.id) {
                    result = object as UtmProfile
                    result.id = response.id
                    if (updateStore) {
                        this.useStore?.().addObjectTypeObject(accountId, object) // Add UscProfile to store
                    }
                } else if (response.errors) {
                    throw response.errors
                } else if (response.error) {
                    throw new Error(response.error)
                } else {
                    throw new Error("Error adding UscProfile")
                }
            } catch (e: any) {
                result = e.responseJSON || e
                const message = e.responseJSON?.message || e.message
                devLog.log("uscProfiles", message, result, "error")
                throw result
            }
            return result
        }
        this.queries.deleteObjectFromApi = async (
            accountId,
            objectId,
            customerId?,
            updateStore = true,
            customPayload?
        ) => {
            let result: true | Error
            try {
                const response = await requestHandler.request(
                    "DELETE",
                    this.options.apiInfo.url +
                        "/tenants/" +
                        tenantHelpers.getTenantDomain(accountId) +
                        "/utms/profiles/" +
                        encodeURI(objectId as string)
                )
                if (response.error) {
                    throw new Error(response.error)
                }
                result = true
                if (updateStore) {
                    this.useStore?.().deleteObjectTypeObjectFromStore(accountId, objectId) // Remove UscProfile from store
                }
            } catch (e: any) {
                if (e instanceof Error) {
                    result = new Error(e.message)
                } else {
                    result = new Error("Error deleting UscProfile")
                }
                devLog.log("uscProfiles", result.message, result, "error")
            }
            return result
        }
    }

    async getProfileJobsFromApi(
        accountId: string,
        objectId: string,
        props?: GetPropertiesObjectList
    ) {
        let result: any[] | Error
        try {
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            const response = await requestHandler.request(
                "GET",
                this.options.apiInfo.url +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/utms/profiles/" +
                    encodingHelpers.encodeURI(objectId as string) +
                    "/jobs" +
                    propertiesString
            )
            if (response.code == 200) {
                result = response.data
            } else if (Array.isArray(response)) {
                result = response
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error getting jobs for usc profile")
            }
        } catch (e: any) {
            result = e.responseJSON || e
            const message = e.responseJSON?.message || e.message
            devLog.log("uscProfiles", message, result, "error")
            throw result
        }
        return result
    }
    async getProfileHistoryJobsFromApi(
        accountId: string,
        objectId: string,
        props?: GetPropertiesObjectList
    ) {
        let result: any[] | Error
        try {
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            const response = await requestHandler.request(
                "GET",
                this.options.apiInfo.url +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/utms/profiles/" +
                    encodingHelpers.encodeURI(objectId as string) +
                    "/jobs/history" +
                    propertiesString
            )
            if (Array.isArray(response)) {
                result = response
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error getting jobs history for usc profile")
            }
        } catch (e: any) {
            result = e.responseJSON || e
            const message = e.responseJSON?.message || e.message
            devLog.log("uscProfiles", message, result, "error")
            throw result
        }
        return result
    }

    async getUTMJobsFromApi(accountId: string, objectId: string, props?: GetPropertiesObjectList) {
        let result: any[] | Error
        try {
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            const response = await requestHandler.request(
                "GET",
                this.options.apiInfo.url +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/utms/" +
                    encodingHelpers.encodeURI(objectId as string) +
                    "/jobs" +
                    propertiesString
            )
            if (response.code == 200) {
                result = response.data
            } else if (Array.isArray(response)) {
                result = response
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error getting jobs for utm")
            }
        } catch (e: any) {
            result = e.responseJSON || e
            const message = e.responseJSON?.message || e.message
            devLog.log("uscProfiles", message, result, "error")
            throw result
        }
        return result
    }

    async getUTMHistoryJobsFromApi(
        accountId: string,
        objectId: string,
        props?: GetPropertiesObjectList
    ) {
        let result: any[] | Error
        try {
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            const response = await requestHandler.request(
                "GET",
                this.options.apiInfo.url +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/utms/" +
                    encodingHelpers.encodeURI(objectId as string) +
                    "/jobs/history" +
                    propertiesString
            )
            if (response.code == 200) {
                result = response.data
            } else if (Array.isArray(response)) {
                result = response
            } else if (Array.isArray(response)) {
                result = response
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error getting jobs history for utm")
            }
        } catch (e: any) {
            result = e.responseJSON || e
            const message = e.responseJSON?.message || e.message
            devLog.log("uscProfiles", message, result, "error")
            throw result
        }
        return result
    }

    async getPublishMessages(accountId: string, objectId: string, props?: GetPropertiesObjectList) {
        let result: any[] | Error
        try {
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            const response = await requestHandler.request(
                "GET",
                this.options.apiInfo.url +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/utms/profiles/" +
                    encodingHelpers.encodeURI(objectId as string) +
                    "/messages" +
                    propertiesString
            )
            if (response.code == 200) {
                result = response.data
            } else if (Array.isArray(response)) {
                result = response
            } else if (Array.isArray(response)) {
                result = response
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error getting jobs history for utm")
            }
        } catch (e: any) {
            result = e.responseJSON || e
            const message = e.responseJSON?.message || e.message
            devLog.log("uscProfiles", message, result, "error")
            throw result
        }
        return result
    }

    getDeleteTemplateObjectDialog = (accountId: string, item: UtmProfile) => {
        let singularOfObjectType = this.options.appearance.text.singular.toLocaleLowerCase()
        let objectIdProperty = this.options.objectTypeInfo.primaryKeyProperty.property
        dialogs.misc.confirmDialog(
            accountId,
            T("Confirm delete"),
            T("Do you really want to delete this " + singularOfObjectType + "?") +
                "<br><br><strong>" +
                T("Warning") +
                "</strong><p>" +
                T(
                    "This will also delete the automatic generated profiles for all affected tenants"
                ) +
                "</p>",
            async () => {
                let deleteProfiles = []
                await this.queries.deleteObjectFromApi(
                    accountId,
                    item[objectIdProperty as keyof typeof item] as string
                )
                const existingTenants: string[] =
                    item.template?.childProfiles != undefined
                        ? Object.keys(item.template.childProfiles)
                        : []
                for (let i = 0; existingTenants.length > i; i++) {
                    if (item.template?.childProfiles) {
                        deleteProfiles.push(
                            existingTenants[i] +
                                "/" +
                                item.template.childProfiles[existingTenants[i]]
                        )
                    }
                }
                this.getManyTemplateRequestsDialog(
                    accountId,
                    "delete",
                    undefined,
                    undefined,
                    undefined,
                    deleteProfiles
                )
            }
        )
    }

    getManyTemplateRequestsDialog = (
        accountId: string,
        type: "add" | "update" | "delete",
        childObject?: UtmProfile,
        addProfiles?: string[],
        updateProfiles?: string[],
        deleteProfiles?: string[]
    ) => {
        let modal: any = {
            accountId: accountId,
            id: "utmsUpdateCheck",
            abortable: true,
            content: {
                title: {
                    text:
                        type == "add"
                            ? T("Add profiles")
                            : type == "update"
                              ? T("Update profiles")
                              : type == "delete"
                                ? T("Delete profiles")
                                : "",
                    icon: "fal fa-info-circle"
                },
                body: {
                    use: true,
                    content: undefined,
                    component: "uscProfiles-many-requests-dialog",
                    properties: {
                        childObject: childObject,
                        addProfiles: addProfiles,
                        updateProfiles: updateProfiles,
                        deleteProfiles: deleteProfiles
                    }
                }
            },
            buttons: [
                {
                    text: T("Cancel"),
                    icon: "fal fa-times",
                    onClick: async function () {
                        getterHelpers.useStore().commit(MutationTypes.removeModal, { accountId })
                    },
                    align: "left",
                    loading: false
                }
            ]
        }
        getterHelpers.useStore()?.commit(MutationTypes.addModal, modal)
    }
}

const uscProfiles = new UscProfiles({
    productType: "unifiedSecurityConsole",
    slug: "uscProfiles",
    objectType: "uscProfiles",
    hasStore: true,
    appearance: {
        iconClass: "fal fa-shield",
        text: {
            plural: "Profiles",
            singular: "Profile",
            title: "Profiles",
            sidebarName: "Profile"
        },
        color: "red",
        showInSidebar: true,
        showOnDashboard: true
    },
    objectTypeInfo: {
        primaryKeyProperty: {
            property: "id"
        },
        nameProperty: {
            primary: "name"
        }
    },
    apiInfo: {
        url: "/sms-mgt-api/api/2.0",
        getCountGETProperties: "?select=data.count",
        // GET
        getObjectListResponseProperty: "utmProfiles",
        getObjectListMethod: "GET",
        getObjectListPath: "/tenants/{tenantDomain}/utms/profiles",
        // Update
        updateObjectMethod: "PUT",
        updateObjectPath: "/tenants/{tenantDomain}/utms/profiles/{objectId}"
    }
})

export default uscProfiles
