<script setup lang="ts">
import config from "@/classes/config"
import { T } from "@/classes/i18n"
import {
    type Connection,
    type VPNWebsocketContexts
} from "@/classes/objectTypes/mobileSecurity/windows/windowsVpns"
import getterHelpers from "@/helpers/helpers.getters"
import tenantHelpers from "@/helpers/helpers.tenants"
import requestHandler from "@/queries/requests"
import { MutationTypes, useStore } from "@/store/vuex.store"
import { computed, onMounted, onUnmounted, ref, watch } from "vue"

const props = defineProps<{
    properties: {
        connection: Connection
        vpnId: string
    }
}>()

// Ref Values
const loading = ref(false)
const deleting = ref(false)
const timeOutId = ref(<undefined | number>undefined)
const input = ref("")
const enterDeleteInput = ref(<any>null)
const error = ref("")

// Computed Values
const activeAccountId = computed(() => {
    return getterHelpers.useStore().getters.getActiveAccountId
})
const canDelete = computed(() => {
    return input.value == T("Delete")
})

// Lifecycles
onMounted(() => {
    loading.value = true

    useStore().commit(MutationTypes.addSubscriptionHook, {
        accountId: activeAccountId.value,
        hookKey: "vpnConnectionRemove",
        hookFunction: (message: any) => {
            const messageTopic = message?.topic || message?.data?.topic || ""
            const clientContext: VPNWebsocketContexts = message?.data?.clientContext || ""

            if (
                typeof messageTopic == "string" &&
                messageTopic.includes("/windows/vpn/") &&
                clientContext == "vpn-connection-remove"
            ) {
                if (message?.data?.data?.error == true) {
                    resetLoadingState(T("Beim Löschen der Verbindung ist ein Fehler aufgetreten."))
                    if (timeOutId.value) clearTimeout(timeOutId.value)
                } else {
                    resetLoadingState()
                    if (timeOutId.value) clearTimeout(timeOutId.value)

                    getterHelpers.useStore().commit(MutationTypes.removeModal)
                }
            }
        }
    })

    setTimeout(() => {
        if (enterDeleteInput.value?.focus) {
            enterDeleteInput.value.focus()
        }
    }, 0)
})

onUnmounted(() => {
    useStore().commit(MutationTypes.deleteSubscriptionHook, {
        accountId: activeAccountId.value,
        hookKey: "vpnConnectionRemove"
    })
})

//Watch
watch(canDelete, () => {
    if (canDelete.value == true) {
        useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled = false
    } else {
        useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled = true
    }
})

// Functions
const submit = async () => {
    if (canDelete.value) {
        error.value = ""
        deleting.value = true

        useStore().getters.getActiveModal(activeAccountId.value).buttons[0].loading = true
        useStore().getters.getActiveModal(activeAccountId.value).buttons[0].disabled = true

        useStore().getters.getActiveModal(activeAccountId.value).buttons[1].loading = true
        useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled = true

        try {
            const response = await requestHandler.request(
                "POST",
                config.mgtApiUriNext +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(activeAccountId.value) +
                    "/windows/devices/" +
                    props.properties.vpnId +
                    "/jobs/vpn",
                {
                    name: "connection-remove",
                    data: {
                        connectionId: props.properties.connection.id
                    }
                }
            )
        } catch (e: unknown) {
            console.error(e)
        }

        timeOutId.value = setTimeout(() => {
            if ((deleting.value = true)) {
                resetLoadingState(
                    T(
                        "Das Löschen der Verbindung hat die Zeit überschritten. Bitte überprüfen Sie die Internetverbindung des VPN-Clients und versuchen es erneut."
                    )
                )
            }
        }, 45000)
    }
}

const resetLoadingState = (errorMsg = "") => {
    input.value = input.value
    deleting.value = false
    error.value = errorMsg

    useStore().getters.getActiveModal(activeAccountId.value).buttons[0].loading = false
    useStore().getters.getActiveModal(activeAccountId.value).buttons[0].disabled = false

    useStore().getters.getActiveModal(activeAccountId.value).buttons[1].loading = false
    useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled = !canDelete.value

    setTimeout(() => {
        if (enterDeleteInput.value?.focus) {
            enterDeleteInput.value.focus()
        }
    }, 0)
}

defineExpose({
    submit
})
</script>
<template>
    <div class="padding-xs">
        <div class="margin-xs-b-2">
            {{ T("Möchten Sie die VPN-Verbindung wirklich löschen?") }}
            <p>
                <span>{{ T("Please enter") }}</span> <strong>{{ T("Delete") }}</strong>
                <span>{{ " " + T("Xto confirm:") + "." }}</span>
            </p>
        </div>

        <input
            type="text"
            v-on:keyup.enter="submit()"
            :placeholder="T('Delete')"
            v-model="input"
            ref="enterDeleteInput"
            :disabled="deleting == true"
        />

        <p v-if="error != ''" class="label bg-red margin-xs-t-2">
            <i class="fal fa-fw fa-exclamation"></i> {{ error }}
        </p>
    </div>
</template>
