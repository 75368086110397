import { useVue } from "@/app"
import dialogs from "@/dialogs/dialogs"
import arrayHelpers from "@/helpers/helpers.arrays"
import deviceHelpers from "@/helpers/helpers.devices"
import encodingHelpers from "@/helpers/helpers.encoding"
import getterHelpers from "@/helpers/helpers.getters"
import licenseHelpers from "@/helpers/helpers.license"
import numberHelpers from "@/helpers/helpers.numbers"
import type { PromiseObject } from "@/helpers/helpers.promises"
import promiseHelpers from "@/helpers/helpers.promises"
import sessionHelpers from "@/helpers/helpers.session"
import stringHelpers from "@/helpers/helpers.strings"
import tenantHelpers from "@/helpers/helpers.tenants"
import validationHelpers from "@/helpers/helpers.validation"
import requestHandler from "@/queries/requests"
import router from "@/router/router"
import useRouterStore from "@/router/routerStore"
import { ActionTypes, MutationTypes, useStore } from "@/store/vuex.store"
import Button from "@/templates/components/button/button"
import ModalObject, { type Modal } from "@/templates/components/modals/modalClass"
import moment from "moment"
import type { AccountId } from "../../../../types/vuex"
import config from "../config"
import {
    GenericObjectStore,
    type AddItem,
    type GenericObject,
    type GetPropertiesObjectList,
    type ItemlistDetail,
    type Label as itemListLabel,
    type MenuEntry,
    type shemaErrors
} from "../genericObjectStore"
import { T } from "../i18n"
import objectStores from "../init"
import devLog from "../log"
import products from "../objectTypes"
import type { License } from "../unifiedSecurity/licenses"

export type CanUseFeatureNames =
    | "websession"
    | "sendJobs"
    | "cloudScan"
    | "cloudBackup"
    | "status"
    | "applications"
    | "scans"
    | "USC"

type SpcliSystemInfoData = {
    timestamp: number
    hardwareSerial: string
    hostname: string
    version: string
    productname?: string

    cluster?: {
        licenseId: string
        state: string
        sync: string
        version: string
    }
    license: {
        organisation: {
            name: string
            unit: string
            contact: string
        }
        serial: string
        valid: string
        users: string
        product: string
        version: string
    }
    av: {
        clamav: {
            updated: string
            lastrun: string
            status: string
        }
        ctav: {
            updated: string
            lastrun: string
            status: string
        }
    }
    storage: {
        total: number
        available: number
    }
    cpu: {
        name: string
        mhz: number
        stats: {
            user: string
            nice: string
            system: string
            idle: string
        }
    }
    memory: {
        total: number
        available: number
    }
    swap: {
        total: number
        available: number
    }
    uptime: string
    network: {
        connections: number
        interfaces: number
    }
    shutdownState: string
    update: {
        old: string
        cur: string
        new: string
        hint: string
    }
} | null

type MergedUpdateInfoData = {
    old: string
    cur: string
    new: string
    hint: string
} | null

type AutoUpdateStateData = any | null
type SpcliAppmgmtStatusData = any | null
type SpcliInterfaceAddressGetData =
    | { id: number; flags: string[]; device: string; address: string }[]
    | null
type MergedRebootInfoData = any | null
type clusterInformation = {
    isCluster: boolean
    licenseRole: "none" | "spare" | "master"
}

export interface UscUtm extends GenericObject<UscUtm> {
    dead: boolean
    expirationDate: string
    inventory: ObjectInventory
    license: License
    licenseInformation?: License
    licensed: boolean
    sunTopologyIds?: string[]
    messages: {
        "auto-update-state"?: AutoUpdateStateData | { timestamp: number; data: AutoUpdateStateData }
        "spcli-system-info": SpcliSystemInfoData | { timestamp: number; data: SpcliSystemInfoData }
        "spcli-appmgmt-status":
            | SpcliAppmgmtStatusData
            | { timestamp: number; data: SpcliAppmgmtStatusData }
        "merged-update-info":
            | MergedUpdateInfoData
            | { timestamp: number; data: MergedUpdateInfoData }
        "merged-reboot-info":
            | MergedRebootInfoData
            | { timestamp: number; data: MergedRebootInfoData }
        "spcli-interface-address-get":
            | SpcliInterfaceAddressGetData
            | { timestamp: number; data: SpcliInterfaceAddressGetData }
    }
    model: string
    offlineReason?: {
        data?: {
            clientContext: string
            since: number
        }
        clientContext: string
        since: number
    }
    lastContact?: number
    spare: boolean
    tags: string[]
    tenantDomain: string
    utmId: string
    utmname: string
    operationsLog?: []
    cluster: clusterInformation
    appliedProfileId?: string
    appliedProfileState?: "NONE" | "PENDING" | "ERROR" | "DISABLED" | "APPLIED"
    pinState?: {
        enabled: boolean
        locked: boolean
    }
    permissions?: {
        PINCommandsEnabled: boolean
        SPCLICommandsEnabled: boolean
        cloudScanEnabled: boolean
        cloudUpdateEnabled: boolean
        manageVPNEnabled: boolean
        profileAutoUpdateEnabled: boolean
        profileCloudBackupEnabled: boolean
        profileDNSServerEnabled: boolean
        profileEnabled: boolean
        profileGeoIpEnabled: boolean
        profileManagerEnabled: boolean
        profilePrivacyEnabled: boolean
        profileServerSettingsEnabled: boolean
        profileTIFEnabled: boolean
        profileTimeSettingsEnabled: boolean
        readRRDDataEnabled: boolean
        startWebsessionEnabled: boolean
    }
}

export default class UscUtms extends GenericObjectStore<UscUtm> {
    public wasUtmUpdateDialogShown = false
    public wasUtmTerminationShown = false

    constructor() {
        super()

        this.settings.primaryKeyProperty = "utmId"
        this.settings.nameProperty.primary = "utmname"
        this.settings.productType = "unifiedSecurityConsole"
        this.settings.objectType = "uscUtms"
        this.settings.appearance.iconClass = "fal fa-server"
        this.settings.appearance.text.singular = "UTM"
        this.settings.appearance.text.plural = "UTMs"
        this.settings.appearance.text.title = "UTMs"
        this.settings.apiInfo.listPath = "/tenants/{tenantDomain}/utms"
        this.settings.apiInfo.objectListPropertyInResponse = ""

        this.itemlist.getToolbar = (accountId, itemlistComponent) => {
            return []
        }
        // setup itemlist options
        this.itemlist.isDisabled = (accountId, item): boolean => {
            return false
        }
        this.itemlist.isClickable = (accountId, item): boolean => {
            return true
        }
        this.itemlist.onClick = (accountId: string, item) => {
            let objectId = item[this.settings.primaryKeyProperty] as string
            router.navigate(
                "show-tenant-" + tenantHelpers.getTenantDomain(accountId) + "-usc-utms-" + objectId
            )
        }
        this.itemlist.hasCheckBox = false
        this.itemlist.getTitle = (item, component) => {
            component = component?.exposed ? component.exposed : component
            return {
                title:
                    item?.utmname ||
                    (item
                        ? this.getUtmMessageData(item, "spcli-system-info")?.productname +
                          " (" +
                          deviceHelpers.getShortDeviceId(item.utmId) +
                          ")"
                        : ""),
                link: {
                    innerHtml: '<i class="fal fa-edit"></i>',
                    onClick: function () {
                        component.editAlias.value = true
                    },
                    showIf: function () {
                        if (component) {
                            return component.editAlias.value == false
                        }
                        return false
                    }
                }
            }
        }
        this.itemlist.getStatus = (accountId: string, item) => {
            return undefined
        }

        this.itemlist.getMenuEntries = (accountId: string, item) => {
            let menuEnties: MenuEntry[] = []
            if (item && item.utmId) {
                let objectId = item[this.settings.primaryKeyProperty] as string
                if (!useRouterStore().getObjectId) {
                    menuEnties.push(
                        new Button({
                            text: T("Details"),
                            title: T("Details"),
                            icon: "fal fa-info-circle",
                            onClick: () => {
                                router.navigate(
                                    "show-tenant-" +
                                        tenantHelpers.getTenantDomain(accountId) +
                                        "-usc-utms-" +
                                        objectId
                                )
                            }
                        })
                    )
                }
                let utmStates = config.canUseNewObjectType("uscUtmStates")
                    ? products.unifiedSecurityConsole.uscUtmStates
                          .useStore?.()
                          .getObjectStoreObjects(accountId)
                    : useStore()?.getters.getObjects({
                          accountId: accountId,
                          productType: this.settings.productType,
                          objectType: "ccutmStates"
                      })

                let thisState = utmStates.find((state: any) => {
                    return objectId == state.deviceId
                })
                if (
                    thisState != undefined &&
                    thisState.online &&
                    sessionHelpers.hasOneOfScopes(["usc:administration", "usc:monitoring"])
                ) {
                    menuEnties.push(
                        new Button({
                            title: T("Start new websession"),
                            text: T("Start new websession"),
                            onClick: () => {
                                this.dialogs.renderNewWebSessionDialog(accountId, objectId)
                            },
                            icon: "fal fa-laptop"
                        })
                    )
                }
            }

            return menuEnties
        }
        this.itemlist.getLabels = (accountId: string, item) => {
            let result: itemListLabel[] = []

            const topologies = products.unifiedSecurityConsole.topologies
                .useStore?.()
                ?.getObjectStoreObjects(accountId)

            const thisTopology = topologies?.find((topology) => {
                return (
                    topology.data?.coreId == item.utmId ||
                    topology.data?.satellites?.some((satellite) => {
                        return satellite.id == item.utmId
                    })
                )
            })

            if (item?.cluster?.isCluster === true && item.license.clusterUuid) {
                result.push({
                    title: "Cluster " + deviceHelpers.getShortDeviceId(item.license.clusterUuid, 4),
                    text: "Cluster " + deviceHelpers.getShortDeviceId(item.license.clusterUuid, 4),
                    class: "bg-blue",
                    icon: "fa fa-circle-nodes"
                })
            }

            if (this.isUpdateNeeded(item)) {
                result.push({
                    title: T("Update strongly recommended!"),
                    text: T("Update strongly recommended!"),
                    class: "bg-red",
                    icon: "fa fa-sync"
                })
            }

            if (item && objectStores.uscUtms.getLaasState(item) == "active") {
                result.push({
                    title: T("USR active"),
                    text: T("USR active"),
                    class: "bg-green",
                    icon: "fa fa-check"
                })
            } else if (item && objectStores.uscUtms.getLaasState(item) == "licensed") {
                result.push({
                    title: T("USR inactive"),
                    text: T("USR inactive"),
                    class: "bg-red",
                    icon: "fa fa-exclamation-triangle"
                })
            }
            if (item && objectStores.uscUtms.getUscMessagingState(item) == "active") {
                result.push({
                    title: T("USC active"),
                    text: T("USC active"),
                    class: "bg-green",
                    icon: "fa fa-check"
                })
            } else if (item && objectStores.uscUtms.getUscMessagingState(item) == "licensed") {
                result.push({
                    title: T("USC inactive"),
                    text: T("USC inactive"),
                    class: "bg-red",
                    icon: "fa fa-exclamation-triangle"
                })
            }

            if (thisTopology) {
                result.push({
                    title: T(
                        "Diese UTM wird von der Unified Network Console unterstützt und befindet sich in einer Konfiguration"
                    ),
                    text: "VPN",
                    class: "bg-blue",
                    icon: "fa fa-puzzle"
                })
            }

            let utmStates = config.canUseNewObjectType("uscUtmStates")
                ? products.unifiedSecurityConsole.uscUtmStates
                      .useStore?.()
                      .getObjectStoreObjects(accountId)
                : useStore()?.getters.getObjects({
                      accountId: accountId,
                      productType: this.settings.productType,
                      objectType: "ccutmStates"
                  })

            if ((utmStates || []).length) {
                let thisState = utmStates.find((state: any) => {
                    return item.utmId == state.deviceId
                })
                if (thisState?.online) {
                    result.push({
                        title: T("Connected"),
                        text: T("Connected"),
                        class: "bg-green",
                        icon: "fa fa-cloud"
                    })
                    if (
                        (this.getUtmMessageData(item, "merged-update-info")?.hint || "").indexOf(
                            "dryrun"
                        ) != -1
                    ) {
                        result.push({
                            title: T("Update available"),
                            text: T("Update available"),
                            class: "bg-green",
                            icon: "fa fa-sync"
                        })
                    }
                } else {
                    if (
                        item.offlineReason?.clientContext &&
                        item.offlineReason.clientContext != ""
                    ) {
                        let contextArray = item.offlineReason.clientContext.split("-")
                        const status = stringHelpers.capitalizeFirstLetter(
                            contextArray[contextArray.length - 1]
                        )
                        result.push({
                            title: T("Disconnected") + " (" + T(status) + ")",
                            text: T("Disconnected") + " (" + T(status) + ")",
                            class: "bg-red",
                            icon: "fa fa-cloud-slash"
                        })
                    } else {
                        result.push({
                            title: T("Disconnected"),
                            text: T("Disconnected"),
                            class: "bg-red",
                            icon: "fa fa-cloud-slash"
                        })
                    }
                }
            }
            return result
        }
        this.itemlist.getDetails = (accountId: string, item, component) => {
            component = component ? component.exposed : component
            let thisClass = this

            let result: ItemlistDetail[] = []

            const objectId = item?.utmId

            const utmStates = config.canUseNewObjectType("uscUtmStates")
                ? products.unifiedSecurityConsole.uscUtmStates
                      .useStore?.()
                      .getObjectStoreObjects(accountId)
                : useStore()?.getters.getObjects({
                      accountId: accountId,
                      productType: this.settings.productType,
                      objectType: "ccutmStates"
                  })
            const uscProfiles = config.canUseNewObjectType("uscProfiles")
                ? products.unifiedSecurityConsole.uscProfiles
                      .useStore?.()
                      .getObjectStoreObjects(accountId)
                : objectStores.uscProfiles.getObjectsFromStore(accountId)

            const thisProfile =
                item?.appliedProfileId && uscProfiles
                    ? uscProfiles.find((profile: any) => {
                          return profile.id == item.appliedProfileId
                      })
                    : undefined
            const thisState = utmStates.find((state: any) => {
                return objectId == state.deviceId
            })
            const thisTagsInlineEditable = true
            const thisTagsArray = item?.tags?.map(function (tag: string) {
                return {
                    id: tag,
                    text: tag,
                    title: tag,
                    displayType: "label"
                }
            })
            result = []

            if (
                this.hasLessThan3GBRam(item) &&
                validationHelpers.versionCompare(
                    this.getCurrentUtmVersion(item) || "0",
                    "12.3.0"
                ) === -1
            ) {
                result.push({
                    iconClass: "fal fa-fw fa-exclamation-triangle",
                    title: T("Attention"),
                    key: T("Attention"),
                    value: T(
                        "This UTM is excluded from automatic updates due to the working memory limit"
                    )
                })
            }

            if (
                item?.cluster?.isCluster === true &&
                !this.getUtmMessageData(item, "spcli-system-info")
            ) {
                result.push({
                    iconClass: "fal fa-fw fa-exclamation-triangle",
                    title: T("Attention"),
                    key: T("Attention"),
                    value: T(
                        "The UTM has not yet transmitted any data to the Unified Security Console, so not all information may be displayed here."
                    )
                })
            }

            if (useStore()?.state.session.userInfo.roles.indexOf("__SUPPORT__") != -1) {
                result.push({
                    iconClass: "fal fa-fw fa-hashtag",
                    title: T("ID"),
                    key: T("ID"),
                    value: item
                        ? objectId
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                })
            }
            let productName = this.getUtmMessageData(item, "spcli-system-info")?.productname
            result.push(
                {
                    iconClass: "fal fa-fw fa-server",
                    title: T("Productname"),
                    key: T("Productname"),
                    value: item
                        ? productName || ""
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-microchip",
                    title: T("CPU"),
                    key: T("CPU"),
                    value: item
                        ? thisClass.getUtmMessageData(item, "spcli-system-info")?.cpu?.name
                            ? thisClass.getUtmMessageData(item, "spcli-system-info")?.cpu?.name +
                              " @ " +
                              numberHelpers.readableNumber(
                                  thisClass.getUtmMessageData(item, "spcli-system-info")?.cpu?.mhz,
                                  true
                              ) +
                              " MHz"
                            : ""
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-microchip",
                    title: T("RAM"),
                    key: T("RAM"),
                    value: item
                        ? thisClass.getUtmMessageData(item, "spcli-system-info")?.memory?.total
                            ? numberHelpers.formatBytes(
                                  (thisClass.getUtmMessageData(item, "spcli-system-info")?.memory
                                      ?.total || 0) * 1000,
                                  2
                              ).value +
                              " " +
                              numberHelpers.formatBytes(
                                  (thisClass.getUtmMessageData(item, "spcli-system-info")?.memory
                                      ?.total || 0) * 1000,
                                  2
                              ).unit
                            : ""
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-hdd",
                    title: T("HDD"),
                    key: T("HDD"),
                    value: item
                        ? thisClass.getUtmMessageData(item, "spcli-system-info")?.storage?.total
                            ? numberHelpers.formatBytes(
                                  thisClass.getUtmMessageData(item, "spcli-system-info")?.storage
                                      ?.total || 0
                              ).value +
                              " " +
                              numberHelpers.formatBytes(
                                  thisClass.getUtmMessageData(item, "spcli-system-info")?.storage
                                      ?.total || 0
                              ).unit
                            : ""
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-globe",
                    title: T("Interfaces"),
                    key: T("Interfaces"),
                    [item && thisClass.getUtmMessageData(item, "spcli-interface-address-get")
                        ? "labels"
                        : "value"]:
                        item && thisClass.getUtmMessageData(item, "spcli-interface-address-get")
                            ? (
                                  thisClass.getUtmMessageData(
                                      item,
                                      "spcli-interface-address-get"
                                  ) || []
                              ).map((addressInfo: any) => {
                                  return {
                                      id: addressInfo.id + "_" + addressInfo.address,
                                      text: addressInfo.device + ": " + addressInfo.address,
                                      title: addressInfo.device + ": " + addressInfo.address,
                                      displayType: "label"
                                  }
                              })
                            : ""
                },
                {
                    iconClass: "fal fa-fw fa-globe",
                    title: T("Hostname"),
                    key: T("Hostname"),
                    value: item
                        ? thisClass.getUtmMessageData(item, "spcli-system-info")?.hostname !=
                          undefined
                            ? thisClass.getUtmMessageData(item, "spcli-system-info")?.hostname
                            : ""
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-hashtag",
                    title: T("Version"),
                    key: T("Version"),
                    value: item
                        ? this.getCurrentUtmVersion(item)
                            ? this.getCurrentUtmVersion(item)
                            : this.getUtmMessageData(item, "spcli-system-info")?.version
                              ? this.getUtmMessageData(item, "spcli-system-info").version
                              : ""
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-tags",
                    title: T("Tags"),
                    key: T("Tags"),
                    [item ? "labels" : "content"]: item
                        ? thisTagsArray
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>",
                    editableContent: thisTagsInlineEditable
                        ? {
                              type: "select2",
                              options: "tags",
                              select2Settings: {
                                  tags: true,
                                  multiple: true,
                                  placeholder: "Select tags"
                              },
                              ref: "editTags",
                              value: item?.tags || [],
                              editingBoolProperty: "editTags",
                              editButton: new Button({
                                  onClick: function () {
                                      component.editTags.value = true
                                  },
                                  icon: "fal fa-edit"
                              }),
                              submitFunction: async function (value: string[]) {
                                  try {
                                      let result = await useStore()?.dispatch(
                                          ActionTypes.updateObjectProperty,
                                          {
                                              accountId: accountId,
                                              objectId: objectId,
                                              productType: "unifiedSecurityConsole",
                                              objectType: "uscUtms",
                                              queryType: "uscUtms",
                                              value: value,
                                              object: {
                                                  utmname: item.utmname,
                                                  tags: value
                                              },
                                              property: "tags"
                                          }
                                      )
                                      getterHelpers
                                          .useStore()
                                          .commit(MutationTypes.setObjectInfos, {
                                              accountId: accountId,
                                              products: {
                                                  mobileSecurity: {
                                                      tags: value
                                                          .filter(arrayHelpers.onlyUniqueFilter)
                                                          .map(function (tag: string) {
                                                              return { id: tag, text: tag }
                                                          })
                                                  }
                                              }
                                          })

                                      if (!result) throw "Error updating tags"
                                      component.editTags.value = false
                                  } catch (e: any) {
                                      component.editTags.value = false
                                      component.error.value = true
                                      e = e.data
                                      if (e?.errors?.errors?.[0]?.message != undefined) {
                                          component.errorMsg.value =
                                              e?.errors?.errors?.[0]?.message +
                                              ". " +
                                              T("Tags may not contain spaces or umlauts.")
                                      }
                                      setTimeout(function () {
                                          component.error.value = false
                                          component.errorMsg.value = ""
                                      }, 4000)
                                  }
                              },
                              abortFunction: function () {
                                  component.editTags.value = false
                              }
                          }
                        : undefined
                },
                {
                    iconClass: "fal fa-shield",
                    title: T("Profile"),
                    key: T("Profile"),
                    [item ? "labels" : "value"]: item
                        ? (function () {
                              let result: itemListLabel[] = []

                              if (item.permissions?.profileEnabled) {
                                  if (thisProfile) {
                                      result.push({
                                          id: objectId + "_profile_" + thisProfile?.name,
                                          text: thisProfile?.name,
                                          title: thisProfile?.name,
                                          onClick: function () {
                                              router.navigate(
                                                  "#edit-tenant-" +
                                                      accountId +
                                                      ".sms-usc-profiles-" +
                                                      thisProfile?.id
                                              )
                                          },
                                          displayType: "label"
                                      })
                                  }
                                  if (
                                      item.appliedProfileState &&
                                      item.appliedProfileState != "NONE" &&
                                      item.appliedProfileState != "APPLIED"
                                  ) {
                                      result.push({
                                          id: objectId + "_profile_" + thisProfile?.name,
                                          text: T(
                                              stringHelpers.capitalizeFirstLetter(
                                                  item.appliedProfileState
                                              )
                                          ),
                                          title: T(
                                              stringHelpers.capitalizeFirstLetter(
                                                  item.appliedProfileState
                                              )
                                          ),
                                          displayType: "label",
                                          onClick: () => {},
                                          class:
                                              item.appliedProfileState == "ERROR"
                                                  ? "bg-red"
                                                  : "bg-yellow"
                                      })
                                  }
                              } else {
                                  result.push({
                                      id: objectId + "_profile_disabled",
                                      icon: "fal fa-ban",
                                      text: T("Disabled"),
                                      title: T("Disabled"),
                                      displayType: "label",
                                      onClick: () => {},
                                      class: "bg-red"
                                  })
                              }

                              return result
                          })()
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-barcode",
                    title: "Hardware SN",
                    key: "Hardware SN",
                    value: item
                        ? T(
                              thisClass.getUtmMessageData(item, "spcli-system-info")?.hardwareSerial
                          ) || ""
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-user",
                    title: T("Licensee"),
                    key: T("Licensee"),
                    value: item
                        ? thisClass.getUtmMessageData(item, "spcli-system-info")?.license
                              ?.organisation?.name
                            ? thisClass.getUtmMessageData(item, "spcli-system-info")?.license
                                  ?.organisation?.name
                            : ""
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-user",
                    title: T("License"),
                    key: T("License"),
                    [item ? "labels" : "value"]: item
                        ? (function () {
                              let result: itemListLabel[] = [
                                  {
                                      id: objectId + "_" + item?.license?.name,
                                      text:
                                          item?.license?.name +
                                          " (" +
                                          deviceHelpers.getShortDeviceId(item?.utmId) +
                                          ")",
                                      title:
                                          item?.license?.name +
                                          " (" +
                                          deviceHelpers.getShortDeviceId(item?.utmId) +
                                          ")",
                                      onClick: function () {
                                          if ((<License>item?.license)?.state == "valid") {
                                              router.navigate(
                                                  "show-tenant-" +
                                                      accountId +
                                                      ".sms-license-" +
                                                      item?.license?.id +
                                                      "-details"
                                              )
                                          }
                                      },
                                      displayType: "label"
                                  }
                              ]

                              if (objectStores.uscUtms.utmHasLicenseConflict(accountId, item)) {
                                  result.push({
                                      id: objectId + "_license_error",
                                      text: T("License conflict"),
                                      title: T(
                                          "A license conflict occurs when the same license is installed on several UTMs. In addition to billing problems, this can lead to unpredictable side effects and incorrect configurations. Make sure that a unique license is installed on each UTM to ensure stable and reliable operation of the UTM."
                                      ),
                                      class: "bg-red",
                                      icon: "fal fa-fw fa-exclamation-triangle",
                                      displayType: "label"
                                  })
                              }
                              return result
                          })()
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-barcode",
                    title: T("License SN"),
                    key: T("License SN"),
                    value: item
                        ? thisClass.getUtmMessageData(item, "spcli-system-info")?.license?.serial ||
                          ""
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                }
            )

            return result
        }

        this.itemlist.sortingOptions = [
            {
                id: "utmname",
                text: "Name"
            },
            {
                id: "tags",
                text: "Tags"
            }
        ]

        this.dialogs.renderNewScanDialog = async (accountId: string, callback: any) => {
            let modal: any = {
                id: "utmNewScan",
                content: {
                    title: {
                        text: T("Start new scan"),
                        icon: "fal fa-search"
                    },
                    body: {
                        content: undefined,
                        component: "new-scan-dialog",
                        properties: {
                            callback: callback
                        }
                    }
                },
                buttons: [
                    {
                        text: T("Cancel"),
                        icon: "fal fa-times",
                        onClick: async function () {
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "left",
                        loading: false
                    }
                ]
            }
            if (
                objectStores.uscUtms.canUseFeature(
                    useRouterStore().getObjectId || "",
                    "cloudScan",
                    accountId
                ).result === false
            ) {
                modal.content.title.text = T("Security-Scan is not available")
                modal.content.body.component = undefined
                modal.content.body.content = "<div class='modal-body'>"
                modal.content.body.content +=
                    "<p class='text-bold'>" + T("Security-Scan is not available") + "</p>"
                modal.content.body.content +=
                    "<p>" +
                    objectStores.uscUtms
                        .canUseFeature(useRouterStore().getObjectId || "", "cloudScan", accountId)
                        .reasons?.join(",") +
                    "</p>"
                modal.content.body.content += "</div>"
                modal.buttons[0].align = "right"
                modal.buttons[0].text = T("Close")
            } else {
                modal.buttons.push({
                    text: T("Start scan"),
                    icon: "fal fa-search",
                    onClick: async () => {
                        let dialog: any = useVue().$refs.modals.$refs.modal.$refs.modalComponent
                        await dialog.submit()
                    },
                    align: "right",
                    loading: true,
                    disabled: true
                })
            }
            useVue().$refs.modals.addModal(modal)
        }

        this.dialogs.renderNewWebSessionDialog = async (accountId: string, utmId: string) => {
            let modal: any = {
                id: "newUtmSession-" + utmId,
                content: {
                    title: {
                        text: T("Start New Websession"),
                        icon: "fal fa-laptop"
                    },
                    body: {
                        content: undefined,
                        component: "new-utm-websession-dialog",
                        properties: {
                            accountId: accountId,
                            utmId: utmId
                        }
                    }
                },
                buttons: [
                    {
                        text: T("Cancel"),
                        icon: "fal fa-times",
                        onClick: async function () {
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "left",
                        loading: false
                    }
                ]
            }
            if (
                objectStores.uscUtms.canUseFeature(utmId || "", "websession", accountId).result ===
                false
            ) {
                modal.content.title.text = T("Websessions are not available")
                modal.content.body.component = undefined
                modal.content.body.content = "<div class='modal-body'>"
                modal.content.body.content +=
                    "<p class='text-bold'>" + T("Websessions are not available") + "</p>"
                modal.content.body.content +=
                    "<p>" +
                    objectStores.uscUtms
                        .canUseFeature(utmId || "", "websession", accountId)
                        .reasons?.join(",") +
                    "</p>"
                modal.content.body.content += "</div>"
                modal.buttons[0].align = "right"
                modal.buttons[0].text = T("Close")
            } else {
                modal.buttons.push({
                    text: T("Start session"),
                    icon: "fal fa-play",
                    onClick: async function () {
                        let dialog: any = useVue().$refs.modals.$refs.modal.$refs.modalComponent
                        dialog.submit()
                    },
                    align: "right",
                    loading: true,
                    disabled: true
                })
            }
            useVue().$refs.modals.addModal(modal)
        }

        this.dialogs.renderUtmLicenseConditions = async (callback: any) => {
            let modal: any = {
                id: "utmLicenseConditions",
                abortable: false,
                content: {
                    title: {
                        text: T("License conditions"),
                        icon: "fal fa-info-circle"
                    },
                    body: {
                        content: undefined,
                        component: "utm-license-conditions-dialog",
                        properties: {
                            callback: callback
                        }
                    }
                },
                buttons: [
                    {
                        text: T("Cancel"),
                        icon: "fal fa-times",
                        onClick: async function () {
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "left",
                        loading: false
                    },
                    {
                        text: T("Accept"),
                        icon: "fal fa-check",
                        onClick: async function () {
                            let dialog: any = useVue().$refs.modals.$refs.modal.$refs.modalComponent
                            await dialog.submit()
                        },
                        disabled: true,
                        align: "right",
                        loading: false
                    }
                ]
            }
            useVue().$refs.modals.addModal(modal)
        }

        this.dialogs.renderUtmUpdateCheck = async (accountId: string | undefined) => {
            let modal: any = {
                accountId: accountId,
                id: "utmsUpdateCheck",
                abortable: true,
                content: {
                    title: {
                        text: T("UTM Update check"),
                        icon: "fal fa-info-circle"
                    },
                    body: {
                        use: true,
                        content: undefined,
                        component: "utms-check-for-updates-dialog"
                    }
                },
                buttons: [
                    {
                        text: T("Cancel"),
                        icon: "fal fa-times",
                        onClick: async function () {
                            getterHelpers
                                .useStore()
                                .commit(MutationTypes.removeModal, { accountId })
                        },
                        align: "left",
                        loading: false
                    },
                    {
                        text: T("Check for Updates"),
                        icon: "fal fa-play-circle",
                        onClick: async function () {
                            useVue().$refs.modals.$refs.modal.$refs.modalComponent.submit()
                        },
                        disabled: false,
                        align: "right",
                        loading: false
                    }
                ]
            }
            useStore()?.commit(MutationTypes.addModal, modal)
        }

        this.dialogs.renderUtmNeedsUpdate = async (
            accountId: string | undefined,
            accountIds: { [accountId: string]: Array<"USC" | "USR"> }
        ) => {
            if (!this.wasUtmUpdateDialogShown) {
                this.wasUtmUpdateDialogShown = true

                let affectedAccountsString = "<ul>"

                Object.keys(accountIds).forEach((affectedId) => {
                    let thisAccountName =
                        useStore()?.state.session.userInfo.scopes.list_accounts.find(
                            (accountInfo: any) => {
                                return String(accountInfo.accountid) == affectedId
                            }
                        )?.accountname || ""
                    if (accountIds[affectedId].indexOf("USC") != -1) {
                        affectedAccountsString +=
                            '<li><a href="#show-tenant-' +
                            affectedId +
                            '.sms-usc-utms-dashboard">' +
                            affectedId +
                            ".sms - " +
                            thisAccountName +
                            "</a></li>"
                    } else {
                        affectedAccountsString +=
                            '<li><a href="#show-tenant-' +
                            affectedId +
                            '.sms-utms-dashboard">' +
                            affectedId +
                            ".sms - " +
                            thisAccountName +
                            "</a></li>"
                    }
                })
                affectedAccountsString += "</ul>"

                dialogs.misc.confirmDialog(
                    accountId || (useStore()?.state.session.activeAccountId as string),
                    "Update strongly recommended!",
                    T(
                        "UTM versions were detected for which a security update is available. Please install them immediately!"
                    ) +
                        "<br><br>" +
                        T("The following accounts are affected:") +
                        affectedAccountsString +
                        "<br><br><p>" +
                        T("More information can be found") +
                        ' <a href="https://wiki.securepoint.de/UTM/Changelog#Build_12.2.5.1" target="_blank">' +
                        T("here") +
                        "</a>.</p>",
                    () => {},
                    undefined,
                    "OK",
                    undefined,
                    false
                )
            }
        }

        this.dialogs.renderUtmPrivacyPolicy = async (callback: any) => {
            let modal: any = {
                id: "utmPrivacypolicy",
                abortable: false,
                content: {
                    title: {
                        text: T("Privacy policy"),
                        icon: "fal fa-info-circle"
                    },
                    body: {
                        content: undefined,
                        component: "utm-privacy-policy-dialog",
                        properties: {
                            callback: callback
                        }
                    }
                },
                buttons: [
                    {
                        text: T("Cancel"),
                        icon: "fal fa-times",
                        onClick: async function () {
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "left",
                        loading: false
                    },
                    {
                        text: T("Accept"),
                        icon: "fal fa-check",
                        onClick: async function (modal: any) {
                            let dialog: any = useVue().$refs.modals.$refs.modal.$refs.modalComponent
                            await dialog.submit()
                        },
                        disabled: true,
                        align: "right",
                        loading: false
                    }
                ]
            }
            useVue().$refs.modals.addModal(modal)
        }

        this.dialogs.renderUtmRestoreBackup = async (callback: any) => {
            let modal: any = {
                id: "utmRestoreBackup",
                abortable: true,
                content: {
                    title: {
                        text: T("Restore backup"),
                        icon: "fal fa-question-circle"
                    },
                    body: {
                        content: undefined,
                        component: "utm-restore-backup-dialog",
                        properties: {
                            callback: callback
                        }
                    }
                },
                buttons: [
                    {
                        text: T("Cancel"),
                        icon: "fal fa-times",
                        onClick: async function () {
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "left",
                        loading: false
                    },
                    {
                        text: T("Accept"),
                        icon: "fal fa-check",
                        onClick: async function () {
                            let dialog: any = useVue().$refs.modals.$refs.modal.$refs.modalComponent
                            await dialog.submit()
                        },
                        disabled: false,
                        align: "right",
                        loading: false
                    }
                ]
            }
            useVue().$refs.modals.addModal(modal)
        }

        this.dialogs.utmForcedUpdateDialog = async (accountIds: string[]) => {
            let activeAccountId = useStore().state.session.activeAccountId
            let url =
                "/sms-mgt-api/api/2.0/tenants/" +
                tenantHelpers.getTenantDomain(activeAccountId || "") +
                "/utms?with_subtenants=true" +
                "&props[]=utmId" +
                "&props[]=utmname" +
                "&props[]=license" +
                "&props[]=messages" +
                "&select=data.utms[?(license.licenseScopes[?contains(@,%27cloud:messaging%27)%20==`true`])]"
            let utms = await requestHandler.request("GET", url)
            utms.forEach((utm: UscUtm) => {
                objectStores.uscUtms.addOrUpdateObjectInStore(String(utm.license.accountId), utm)
            })

            if (!this.wasUtmTerminationShown) {
                this.wasUtmTerminationShown = true

                // Map: AccountId and UscUtms
                let affectedAccountIds: string[] = []

                for (const accountId of accountIds) {
                    // Utms for Tenant
                    let utmsForTenant = useStore().getters.getObjects({
                        accountId: accountId,
                        productType: "unifiedSecurityConsole",
                        objectType: "uscUtms"
                    })

                    // If Tenant has Utms
                    if (Array.isArray(utmsForTenant)) {
                        // Filter for Versions < 12.2.2.8
                        for (const utm of utmsForTenant) {
                            if (
                                this.getUtmMessageData(utm, "spcli-system-info")?.version &&
                                validationHelpers.versionCompare(
                                    this.getUtmMessageData(utm, "spcli-system-info").version,
                                    "12.2.2.8"
                                ) !== 1
                            ) {
                                affectedAccountIds.push(accountId)
                                break
                            }
                        }
                    }
                }
                if (affectedAccountIds.length > 0) {
                    let accountId = useStore().state.session.activeAccountId

                    let modal = {
                        accountId: accountId,
                        id: "utmForceUpdateDialog",
                        abortable: true,
                        content: {
                            title: {
                                text: T("UTM update required!"),
                                icon: "fal fa-fire-flame-curved"
                            },
                            body: {
                                use: true,
                                component: "utm-force-update",
                                properties: {
                                    affectedAccountIds: affectedAccountIds
                                }
                            }
                        },
                        buttons: [
                            {
                                text: T("Ok"),
                                icon: "fal fa-check",
                                onClick: async function () {
                                    useStore().commit(MutationTypes.removeModal, {
                                        accountId: accountId
                                    })
                                },
                                align: "right",
                                loading: false
                            }
                        ]
                    }
                    useStore().commit(MutationTypes.addModal, modal)
                }
            }
        }

        this.dialogs.utmEnterPinDialog = async (
            item: UscUtm,
            context: string,
            payload?: any,
            title = "Enter Pin",
            isCouldBackup = false,
            ctime: string = ""
        ) => {
            const accountId = useStore().state.session.activeAccountId
            let modal: Modal = {
                accountId: accountId,
                id: "utmEnterPinDialog",
                abortable: true,
                content: {
                    title: {
                        text: T(title),
                        icon: ""
                    },
                    body: {
                        use: true,
                        component: "utm-enter-pin",
                        properties: {
                            utm: item,
                            context: context,
                            payload: payload,
                            isCouldBackup: isCouldBackup,
                            ctime: ctime
                        }
                    }
                },
                buttons: [
                    {
                        text: T("Cancel"),
                        icon: "fal fa-times",
                        onClick: async function () {
                            getterHelpers
                                .useStore()
                                .commit(MutationTypes.removeModal, { accountId })
                        },
                        align: "left",
                        loading: false
                    },
                    {
                        text: T("Submit"),
                        icon: "fal fa-check",
                        onClick: async function () {
                            await useVue().$refs.modals.$refs.modal.$refs.modalComponent.submit()
                        },
                        align: "right",
                        loading: true,
                        disabled: true
                    }
                ]
            }
            useStore().commit(MutationTypes.addModal, modal)
        }
    }

    async utmsUpdateCheck(accountId: string, utms: UscUtm[] | undefined = undefined) {
        if (!this.wasUtmUpdateDialogShown) {
            if (utms?.length) {
            } else {
                utms = (await this.getObjectsFromApi(accountId, undefined, true)) as UscUtm[]
            }
            if (Array.isArray(utms)) {
                utms = utms?.filter((utm: any) => {
                    return utm.license.licenseScopes.some((scope: string) => {
                        return scope === "cloud:messaging"
                    })
                })
                if (utms.length) {
                    let needsUpdate = utms.some((utm: any) => {
                        if (this.getCurrentUtmVersion(utm) != undefined) {
                            return this.isUpdateNeeded(utm)
                        }
                        return false
                    })
                    if (needsUpdate) {
                        return true
                    }
                }
            }
        }
        return false
    }

    isUpdateNeeded(item: any) {
        return this.getCurrentUtmVersion(item)
            ? [
                  "12.2.5",
                  "12.2.4.1",
                  "12.2.4",
                  "12.2.3.4",
                  "12.2.3.3",
                  "12.2.3.2",
                  "12.2.3.1"
              ].indexOf(this.getCurrentUtmVersion(item)) != -1
            : false
    }

    getCurrentUtmVersion(item: UscUtm) {
        return (
            this.getUtmMessageData(item, "spcli-system-info")?.version ||
            this.getUtmMessageData(item, "merged-update-info")?.cur ||
            this.getUtmMessageData(item, "spcli-system-info")?.update?.cur ||
            undefined
        )
    }

    hasLessThan3GBRam(item: UscUtm) {
        return this.getUtmMessageData(item, "spcli-system-info")?.memory?.total
            ? numberHelpers.formatBytesToMb(
                  this.getUtmMessageData(item, "spcli-system-info")?.memory.total * 1024,
                  2
              ).value < 3000
            : false
    }

    getUtmMessageData(utm: UscUtm, messageDataType: keyof UscUtm["messages"]) {
        if (!utm) {
            return undefined
        } else if (!utm?.messages) {
            return undefined
        } else if (
            utm?.messages?.[messageDataType] &&
            utm.messages[messageDataType].data != undefined &&
            utm.messages[messageDataType].timestamp != undefined
        ) {
            return utm.messages[messageDataType].data
        } else {
            return utm.messages[messageDataType]
        }
    }

    addGenericObjectInfos(accountId: string, item: AddItem<UscUtm>) {
        if (item?.$itemlist == undefined)
            (<GenericObject<UscUtm>>item).$itemlist = {
                isCheckboxChecked: false,
                isCheckboxHovering: false,
                title: this.itemlist.getTitle(item as UscUtm, undefined),
                details: this.itemlist.getDetails(accountId, item as UscUtm, undefined),
                menuEntries: this.itemlist.getMenuEntries(accountId, item as UscUtm),
                labels: this.itemlist.getLabels(accountId, item as UscUtm)
            }

        if ((<UscUtm>item)?.operationsLog == undefined) {
            ;(<UscUtm>item).operationsLog = []
        }

        if (!item.toJSON) {
            item.toJSON = function () {
                let item: any = {}
                let addItem = this
                for (const key in addItem) {
                    if (
                        Object.prototype.hasOwnProperty.call(addItem, key) &&
                        ["$itemlist", "toJSON"].indexOf(key) == -1
                    ) {
                        item[key] = this[key as keyof typeof addItem]
                    }
                }
                return item as UscUtm
            }
        }
        return item as UscUtm
    }

    async getCountFromApi(accountId: string, updateLocalStore: boolean = true) {
        let result: number | Error = 0
        try {
            let thisResult = await requestHandler.request(
                "GET",
                config.mgtApiUriNext +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/utms?props[]=license&select=data.utms[?(license.licenseScopes[?contains(@,'cloud:messaging') ==`true`])]"
            )
            thisResult = thisResult?.filter((utm: UscUtm) => {
                return utm.license.licenseScopes.some((scope: string) => {
                    return scope === "cloud:messaging"
                })
            })
            const countResult = thisResult.length
            if (typeof countResult === "number") {
                result = countResult
                if (updateLocalStore) {
                    this.setCount(accountId, countResult)
                }
            } else if (countResult.error) {
                throw new Error(countResult.error)
            } else {
                throw new Error("Error getting count for utms")
            }
        } catch (e: any) {
            if (e instanceof Error) {
                result = new Error(e.message)
            } else {
                result = new Error("Error getting count for utms")
            }
            devLog.log("UtmsStoreClass", result.message, result, "error")
        }
        return result
    }
    async getObjectsFromApi(
        accountId: string,
        props?: GetPropertiesObjectList,
        updateLocalStore: boolean = true
    ): Promise<UscUtm[] | Error> {
        const tenantDomain = tenantHelpers.getTenantDomain(accountId)
        let propertiesString: string = props ? this.getPropertiesString(props) : ""

        let result: UscUtm[] | Error
        try {
            let response = await requestHandler.request(
                "GET",
                config.mgtApiUriNext + "/tenants/" + tenantDomain + "/utms" + propertiesString
            )
            response = response.utms?.filter((utm: UscUtm) => {
                let license = licenseHelpers.getLicenseByUUID(accountId, utm.utmId)
                if (license) {
                    utm.license = license
                }
                return utm.license.licenseScopes.some((scope: string) => {
                    return scope === "cloud:messaging"
                })
            })
            if (
                typeof this.settings.apiInfo.objectListPropertyInResponse == "string" &&
                this.settings.apiInfo.objectListPropertyInResponse.length > 0 &&
                response[this.settings.apiInfo.objectListPropertyInResponse]
            ) {
                result = response[
                    this.settings.apiInfo.objectListPropertyInResponse as string
                ] as UscUtm[]
            } else if (Array.isArray(response)) {
                result = response as UscUtm[]
            } else {
                throw "Error getting UTMs"
            }
            if (updateLocalStore) {
                this.addOrUpdateObjectsInStore(accountId, result) // Add Users to store
            }
            return result
        } catch (e: any) {
            devLog.log("UscUtmsStoreClass", e.message, e, "error")
            throw e as Error
        }
    }

    async getObjectFromApi(
        accountId: string,
        objectId: UscUtm[keyof UscUtm],
        props?: GetPropertiesObjectList,
        updateLocalStore: boolean = true
    ) {
        let result: UscUtm | Error
        try {
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            const response = await requestHandler.request(
                "GET",
                config.mgtApiUriNext +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/utms/" +
                    encodingHelpers.encodeURI(objectId as string) +
                    propertiesString
            )
            if (response.utmId) {
                result = response as UscUtm
                if (updateLocalStore) {
                    this.addOrUpdateObjectsInStore(accountId, result) // Add utm to store
                }
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error getting utm")
            }
        } catch (e: any) {
            result = e.responseJSON || e
            const message = e.responseJSON?.message || e.message
            devLog.log("UscUtmsStoreClass", message, result, "error")
            throw result
        }
        return result
    }

    async updateObjectFromApi(
        accountId: string,
        objectId: UscUtm["utmId"],
        object: UscUtm,
        updateLocalStore: boolean = true
    ) {
        let result: UscUtm | Error | shemaErrors
        try {
            const response = await requestHandler.request(
                "PUT",
                this.settings.apiInfo.url +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/laas/utms/" +
                    encodingHelpers.encodeURI(objectId),
                object
            )
            if (response.utmId) {
                result = object
                result.utmId = response.utmId
                if (updateLocalStore) {
                    this.addOrUpdateObjectsInStore(accountId, object) // Add utm to store
                }
            } else if (response.errors) {
                throw response.errors
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error updating utm")
            }
        } catch (e: any) {
            result = e.responseJSON || e
            const message = e.responseJSON?.message || e.message
            devLog.log("UscUtmsStoreClass", message, result, "error")
            throw result
        }
        return result
    }

    async updateObjectInventory(
        accountId: string,
        objectId: string,
        object: any
    ): Promise<boolean | Error | shemaErrors> {
        let result = false
        try {
            let thisName = this.getObjectFromStore(accountId, objectId)?.utmname
            if (thisName) {
                object.utmname = thisName
            }
            let thisResult: any = await this.updateObjectFromApi(accountId, objectId, object, true)
            if (thisResult.code == 200) {
                result = true
                let device = this.getObjectFromStore(accountId, objectId)
                if (device.inventory == undefined) {
                    device.inventory = {}
                } else {
                    let keys = Object.keys(object)
                    keys.forEach((key) => {
                        let value: any

                        if (key == "inventoryLocation") {
                            value = JSON.parse(object[key])
                        } else {
                            value = object[key]
                        }

                        ;(<any>device.inventory)[key as keyof typeof device.inventory] = value
                    })
                }
            } else {
                result = thisResult
            }
        } catch (e: any) {
            result = e
        }
        return result
    }

    async sendUtmMessage(accountid: string, utmId: UscUtm["utmId"], message: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain = tenantHelpers.getTenantDomain(accountid)
            return await requestHandler.request(
                "POST",
                config.mgtApiUriNext +
                    "/tenants/" +
                    tenantDomain +
                    "/utms/" +
                    utmId +
                    "/jobs/" +
                    message
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            }
        }
        return result
    }

    async getUtmJob(accountid: string, utmId: UscUtm["utmId"], message: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain = tenantHelpers.getTenantDomain(accountid)
            return await requestHandler.request(
                "GET",
                config.mgtApiUriNext +
                    "/tenants/" +
                    tenantDomain +
                    "/utms/" +
                    utmId +
                    "/jobs/" +
                    message
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            }
        }
        return result
    }

    async getUtmLatestRejects(accountid: string, utmId: UscUtm["utmId"], period: string = "today") {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }

            let timezone =
                useStore()?.state.session.accounts[accountid]?.unifiedSecurity.account.timezone ||
                "+02:00"
            let tenantDomain = tenantHelpers.getTenantDomain(accountid)

            let payload = {
                query: {
                    modul: "CloudUtm",
                    name: "ConnectionRejectLatest",
                    options: {
                        relate: "count",
                        period: period,
                        size: 10,
                        offset: 0,
                        timezone: timezone,
                        order: {
                            asc: false
                        },
                        filter: [
                            {
                                field: "device",
                                operator: "equal",
                                value: utmId
                            }
                        ]
                    }
                }
            }
            result = await requestHandler.request(
                "POST",
                "/sms-mgt-api/api/2.0/tenants/" + tenantDomain + "/stats/execute",
                payload
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            }
            console.error(e)
        }
        return result
    }

    async getCcutmWebInterfaces(accountId: string, utmIds: string[]) {
        let result: any = null
        try {
            accountId = tenantHelpers.getAccountId(accountId)
            if (useStore()?.getters.hasAccountid(accountId) == false) {
                throw { status: 401 }
            }
            let tenantDomain = tenantHelpers.getTenantDomain(accountId)
            let promiseObj: PromiseObject = {}
            utmIds.forEach((utmId: UscUtm["utmId"]) => {
                promiseObj[utmId] = requestHandler.request(
                    "GET",
                    config.mgtApiUriNext +
                        "/tenants/" +
                        tenantDomain +
                        "/utms/" +
                        utmId +
                        "/webinterface"
                )
            })
            result = await promiseHelpers.resolvePromiseObject(promiseObj)
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            }
            console.error(e)
        }
        return result
    }

    async setUtmProperties(tenantDomain: string, utmId: UscUtm["utmId"], properties: any) {
        return requestHandler.request(
            "POST",
            "/sms-mgt-api/api/1.1/tenants/" + tenantDomain + "/laas/utms/" + utmId + "/properties",
            properties
        )
    }

    formatErrorMessage(message: any = "") {
        let formattedMessage = ""
        if (message?.message) message = message.message
        if (message.indexOf("Invalid scopes. Needed some of") != -1) {
            formattedMessage = T("Invalid scopes. Needed some of:") + " "
            let scopes = []
            if (message.indexOf("usc:administration") != -1) {
                scopes.push(T("Full administration"))
            }
            if (message.indexOf("usc:monitoring") != -1) {
                scopes.push(T("Monitoring access"))
            }
            formattedMessage += scopes.join(", ") + ". "
            formattedMessage += T("Scopes can be edited in roles by the administrator.")
        } else {
            formattedMessage = message
        }

        return formattedMessage
    }

    async sendSpcliMessage(accountId: AccountId, utmId: UscUtm["utmId"], payload: any) {
        let result: any
        accountId = tenantHelpers.getAccountId(accountId)
        const tenantDomain = tenantHelpers.getTenantDomain(accountId)
        try {
            result = await requestHandler.request(
                "POST",
                config.mgtApiUriNext +
                    "/tenants/" +
                    tenantDomain +
                    "/utms/" +
                    utmId +
                    "/jobs/spcli",
                payload
            )
            if (result?.errors?.error === true) {
                throw new Error(result.errors.payload)
            }
        } catch (e: any) {
            let errorMessage = e?.message
            if (e?.data?.data?.errors?.payload) {
                errorMessage = e.data.data.errors.payload
            } else if (e?.data?.errors?.payload) {
                errorMessage = e?.data?.errors?.payload
            } else if (e?.data?.errors?.error) {
                errorMessage = e.data.errors.error
            }
            result = false
            let errorMsg = this.formatErrorMessage(errorMessage)
            let activeModal = useStore()?.getters.getActiveModal(accountId)
            if (activeModal != undefined) {
                useStore()?.commit(MutationTypes.removeModal, { accountId })
            }
            dialogs.misc.errorDialog(accountId, T("Error"), T(errorMsg))
        }
        return result
    }
    async sendRrdMessage(accountId: AccountId, utmId: UscUtm["utmId"], payload: any) {
        let result: any
        accountId = tenantHelpers.getAccountId(accountId)
        const tenantDomain = tenantHelpers.getTenantDomain(accountId)
        try {
            result = await requestHandler.request(
                "POST",
                config.mgtApiUriNext + "/tenants/" + tenantDomain + "/utms/" + utmId + "/jobs/rrd",
                payload
            )
            if (result?.errors?.error === true) {
                throw new Error(result.errors.payload)
            }
        } catch (e: any) {
            let errorMessage = e?.message
            if (e?.data?.data?.errors?.payload) {
                errorMessage = e.data.data.errors.payload
            } else if (e?.data?.errors?.payload) {
                errorMessage = e?.data?.errors?.payload
            } else if (e?.data?.errors?.error) {
                errorMessage = e.data.errors.error
            }
            result = false
            let errorMsg = this.formatErrorMessage(errorMessage)
            let activeModal = useStore()?.getters.getActiveModal(accountId)
            if (activeModal != undefined) {
                useStore()?.commit(MutationTypes.removeModal, { accountId })
            }
            dialogs.misc.errorDialog(accountId, T("Error"), T(errorMsg))
        }
        return result
    }
    async revokeJob(accountId: AccountId, utmId: UscUtm["utmId"], jobId: string) {
        let result: any
        accountId = tenantHelpers.getAccountId(accountId)
        const tenantDomain = tenantHelpers.getTenantDomain(accountId)
        try {
            result = await requestHandler.request(
                "POST",
                config.mgtApiUriNext +
                    "/tenants/" +
                    tenantDomain +
                    "/utms/" +
                    utmId +
                    "/jobs/" +
                    jobId +
                    "/revoke"
            )
            if (result?.errors?.error === true) {
                throw new Error(result.errors.payload)
            }
        } catch (e: any) {
            let errorMessage = e?.message
            if (e?.data?.data?.errors?.payload) {
                errorMessage = e.data.data.errors.payload
            } else if (e?.data?.errors?.payload) {
                errorMessage = e?.data?.errors?.payload
            } else if (e?.data?.errors?.error) {
                errorMessage = e.data.errors.error
            }
            result = false
            let errorMsg = this.formatErrorMessage(errorMessage)
            let activeModal = useStore()?.getters.getActiveModal(accountId)
            if (activeModal != undefined) {
                useStore()?.commit(MutationTypes.removeModal, { accountId })
            }
            dialogs.misc.errorDialog(accountId, T("Error"), T(errorMsg))
        }
        return result
    }
    async getJobs(accountId: AccountId, utmId: UscUtm["utmId"], postFix?: string) {
        let result: any
        accountId = tenantHelpers.getAccountId(accountId)
        const tenantDomain = tenantHelpers.getTenantDomain(accountId)
        try {
            result = await requestHandler.request(
                "GET",
                config.mgtApiUriNext +
                    "/tenants/" +
                    tenantDomain +
                    "/utms/" +
                    utmId +
                    "/jobs" +
                    postFix
            )
            if (result?.errors?.error === true) {
                throw new Error(result.errors.payload)
            }
        } catch (e: any) {
            let errorMessage = e?.message
            if (e?.data?.data?.errors?.payload) {
                errorMessage = e.data.data.errors.payload
            } else if (e?.data?.errors?.payload) {
                errorMessage = e?.data?.errors?.payload
            } else if (e?.data?.errors?.error) {
                errorMessage = e.data.errors.error
            }
            result = false
            let errorMsg = this.formatErrorMessage(errorMessage)
            let activeModal = useStore()?.getters.getActiveModal(accountId)
            if (activeModal != undefined) {
                useStore()?.commit(MutationTypes.removeModal, { accountId })
            }
            dialogs.misc.errorDialog(accountId, T("Error"), T(errorMsg))
        }
        return result
    }
    async getScans(accountId: AccountId, utmId: UscUtm["utmId"], postFix?: string) {
        let result: any
        accountId = tenantHelpers.getAccountId(accountId)
        const tenantDomain = tenantHelpers.getTenantDomain(accountId)
        try {
            result = await requestHandler.request(
                "GET",
                config.mgtApiUriNext +
                    "/tenants/" +
                    tenantDomain +
                    "/utms/" +
                    utmId +
                    "/scans" +
                    postFix
            )
            if (result?.errors?.error === true) {
                throw new Error(result.errors.payload)
            }
        } catch (e: any) {
            let errorMessage = e?.message
            if (e?.data?.data?.errors?.payload) {
                errorMessage = e.data.data.errors.payload
            } else if (e?.data?.errors?.payload) {
                errorMessage = e?.data?.errors?.payload
            } else if (e?.data?.errors?.error) {
                errorMessage = e.data.errors.error
            }
            result = false
            let errorMsg = this.formatErrorMessage(errorMessage)
            let activeModal = useStore()?.getters.getActiveModal(accountId)
            if (activeModal != undefined) {
                useStore()?.commit(MutationTypes.removeModal, { accountId })
            }
            dialogs.misc.errorDialog(accountId, T("Error"), T(errorMsg))
        }
        return result
    }
    async startScan(accountId: AccountId, utmId: UscUtm["utmId"], scanData: any) {
        let result: any
        accountId = tenantHelpers.getAccountId(accountId)
        const tenantDomain = tenantHelpers.getTenantDomain(accountId)
        try {
            result = await requestHandler.request(
                "POST",
                config.mgtApiUriNext +
                    "/tenants/" +
                    tenantDomain +
                    "/utms/" +
                    utmId +
                    "/jobs/model",
                { name: "emit-cloud-scan", data: scanData }
            )
            if (result?.errors?.error === true) {
                throw new Error(result.errors.payload)
            }
        } catch (e: any) {
            let errorMessage = e?.message
            if (e?.data?.data?.errors?.payload) {
                errorMessage = e.data.data.errors.payload
            } else if (e?.data?.errors?.payload) {
                errorMessage = e?.data?.errors?.payload
            } else if (e?.data?.errors?.error) {
                errorMessage = e.data.errors.error
            }
            result = false
            let errorMsg = this.formatErrorMessage(errorMessage)
            let activeModal = useStore()?.getters.getActiveModal(accountId)
            if (activeModal != undefined) {
                useStore()?.commit(MutationTypes.removeModal, { accountId })
            }
            dialogs.misc.errorDialog(accountId, T("Error"), T(errorMsg))
        }
        return result
    }

    utmHasLicenseConflict(accountId: string, utm: UscUtm) {
        const thisUtmState = config.canUseNewObjectType("uscUtmStates")
            ? products.unifiedSecurityConsole.uscUtmStates
                  .useStore?.()
                  .getObjectStoreObject(accountId, utm.utmId)
            : getterHelpers.useStore()?.getters.getObject({
                  accountId: accountId,
                  productType: "unifiedSecurityConsole",
                  objectType: "ccutmStates",
                  objectId: utm.utmId
              })
        if (thisUtmState) {
            return thisUtmState.multipleConnects === true
        } else {
            return false
        }
    }

    getLaasState(utm: UscUtm) {
        let result: "not_licensed" | "licensed" | "active" = "not_licensed"
        const capabilities: any =
            utm.license?.licenseScopes || utm.licenseInformation?.licenseScopes || []
        const laasLicensed = utm?.licenseInformation?.laasEnabled // at least licensed
        const laasLicensedDefinitive = capabilities.some((scope: any) => {
            return scope == "capability:laas_01"
        })
        const laasActive = capabilities.some((scope: any) => {
            return scope == "laas_01"
        })
        if (laasLicensed || laasLicensedDefinitive) {
            result = "licensed"
        }
        if (laasActive) {
            result = "active"
        }
        return result
    }

    getUscMessagingState(utm: UscUtm) {
        let result: "not_licensed" | "licensed" | "active" = "not_licensed"
        const capabilities: any =
            utm.license?.licenseScopes || utm.licenseInformation?.licenseScopes || []
        const messagingLicensed = utm?.licenseInformation?.uscMessagingEnabled // at least licensed
        const messagingLicensedDefinitive = capabilities.some((scope: any) => {
            return scope == "capability:cloud:messaging"
        })
        const messagingActive = capabilities.some((scope: any) => {
            return scope == "cloud:messaging"
        })
        if (messagingLicensed || messagingLicensedDefinitive) {
            result = "licensed"
        }
        if (messagingActive) {
            result = "active"
        }
        return result
    }
    getUscMonitoringState(utm: UscUtm) {
        let result: "not_licensed" | "licensed" | "active" = "not_licensed"
        const capabilities: any =
            utm.license?.licenseScopes || utm.licenseInformation?.licenseScopes || []
        const monitoringLicensed = utm?.licenseInformation?.uscMonitoringEnabled // at least licensed
        const monitoringLicensedDefinitive = capabilities.some((scope: any) => {
            return scope == "capability:cloud:monitoring"
        })
        const monitoringActive = capabilities.some((scope: any) => {
            return scope == "cloud:monitoring"
        })
        if (monitoringLicensed || monitoringLicensedDefinitive) {
            result = "licensed"
        }
        if (monitoringActive) {
            result = "active"
        }
        return result
    }
    canUseFeature(utmId: UscUtm["utmId"], featureName: CanUseFeatureNames, accountId?: string) {
        type utmFeatureCondition =
            | {
                  conditionType: "minVersion"
                  value: string
              }
            | {
                  conditionType: "hasLicenseFlag"
                  value: "laasEnabled" | "uscMessagingEnabled" | "uscMonitoringEnabled"
              }
            | {
                  conditionType: "hasUserPermission"
                  value:
                      | "usc:administration"
                      | "usc:monitoring"
                      | "usc:administration:jobs:read"
                      | "usc:administration:jobs:write"
                      | "usc:administration:jobs:appmgmt"
                      | "usc:administration:jobs:extc"
                      | "usc:administration:jobs:vpn"
                      | "usc:administration:jobs:backup"
                      | "usc:administration:jobs:upgrade"
                      | "usc:administration:webinterface"
              }
            | {
                  conditionType: "hasLicenseScope"
                  value:
                      | "capability:cloud:messaging"
                      | "capability:cloud:monitoring"
                      | "capability:cloud:scan"
                      | "capability:laas_01"
                      | "cloud:messaging"
                      | "cloud:scan"
                      | "laas_01"
              }

        let features = {
            websession: <utmFeatureCondition[]>[
                {
                    conditionType: "minVersion",
                    value: "12.5.1"
                },
                {
                    conditionType: "hasUserPermission",
                    value: "usc:administration"
                }
            ],
            USC: <utmFeatureCondition[]>[
                {
                    conditionType: "hasUserPermission",
                    value: "usc:administration"
                }
            ],
            sendJobs: <utmFeatureCondition[]>[
                {
                    conditionType: "hasUserPermission",
                    value: "usc:administration"
                }
            ],
            cloudScan: <utmFeatureCondition[]>[
                {
                    conditionType: "minVersion",
                    value: "12.2.1"
                }
            ],
            cloudBackup: <utmFeatureCondition[]>[
                {
                    conditionType: "hasUserPermission",
                    value: "usc:administration"
                }
            ],
            status: <utmFeatureCondition[]>[
                {
                    conditionType: "hasUserPermission",
                    value: "usc:monitoring"
                }
            ],
            applications: <utmFeatureCondition[]>[
                {
                    conditionType: "hasUserPermission",
                    value: "usc:administration"
                }
            ],
            scans: <utmFeatureCondition[]>[
                {
                    conditionType: "hasUserPermission",
                    value: "usc:administration"
                }
            ]
        }
        let utm: UscUtm | undefined = useStore()?.getters.getObject({
            accountId: accountId || useStore()?.state.session.activeAccountId || "",
            productType: "unifiedSecurityConsole",
            objectType: "uscUtms",
            objectId: utmId
        })
        if (features[featureName] != undefined && utm !== undefined) {
            let utmVersion: string | undefined =
                this.getUtmMessageData(utm, "spcli-system-info")?.version || undefined
            let capabilities: any =
                utm.license?.licenseScopes || utm.licenseInformation?.licenseScopes || []
            let websessionConditions: boolean = true
            // check if any condition is NOT met
            let incompatibilities: string[] = []
            const canUseFeature = !features[featureName].some((condition) => {
                let result = true
                switch (condition.conditionType) {
                    case "minVersion":
                        if (typeof utmVersion == "string") {
                            var minimumVersionParts = condition.value.split(".").map(function (
                                    rec: any
                                ) {
                                    return parseInt(rec, 10) || 0
                                }),
                                currentVersionParts = utmVersion
                                    .replace("-pre", "")
                                    .replace(" (final)", "")
                                    .split(".")
                                    .map(function (rec: any) {
                                        return parseInt(rec, 10) || 0
                                    }),
                                i

                            while (minimumVersionParts.length !== currentVersionParts.length) {
                                if (minimumVersionParts.length < currentVersionParts.length) {
                                    minimumVersionParts.push(0)
                                } else {
                                    currentVersionParts.push(0)
                                }
                            }

                            for (i = 0; i < minimumVersionParts.length; i++) {
                                if (minimumVersionParts[i] < currentVersionParts[i]) {
                                    result = true
                                    break
                                } else if (minimumVersionParts[i] > currentVersionParts[i]) {
                                    result = false
                                    break
                                }
                            }
                            if (!result)
                                incompatibilities.push(
                                    T(
                                        "This feature is no longer available for this UTM. Please update your UTM."
                                    )
                                )
                        } else {
                            result = false
                            incompatibilities.push(
                                T(
                                    "This feature is currently not available as the UTM has not yet transmitted all the necessary information. Please wait a few minutes until the information is available."
                                )
                            )
                        }

                        if (featureName == "websession")
                            websessionConditions = websessionConditions && result

                        break
                    case "hasLicenseFlag":
                        if (utm?.license) result = utm.license[condition.value] === true
                        else result = false

                        if (!result)
                            incompatibilities.push("Missing license flag: " + condition.value)
                        break
                    case "hasUserPermission":
                        result = (useStore()?.state.session.userInfo.permissionScopes || []).some(
                            (permission: string) => {
                                if (permission == "usc:administration") {
                                    return condition.value.indexOf("usc:administration") != -1
                                } else {
                                    return permission == condition.value
                                }
                            }
                        )
                        if (featureName == "websession")
                            websessionConditions = websessionConditions && result
                        if (!result)
                            incompatibilities.push(
                                T("Missing required user permission:") +
                                    " " +
                                    (condition.value == "usc:administration"
                                        ? T("Full USC administration")
                                        : condition.value == "usc:monitoring"
                                          ? "USC Monitoring"
                                          : condition.value)
                            )
                        break
                    case "hasLicenseScope":
                        result = capabilities.indexOf(condition.value) != -1
                        if (!result)
                            incompatibilities.push("Missing license scope: " + condition.value)
                        break
                }
                // return true if any conditions result is false
                if (result == false) {
                    return true
                }
            })
            if (canUseFeature && websessionConditions) {
                return { result: true }
            } else {
                return { result: false, reasons: incompatibilities }
            }
        } else {
            if (utm) {
                return { result: false, reasons: ["Feature " + featureName + " does not exist"] }
            } else {
                return { result: false, reasons: ["UTM with Id " + utmId + " does not exist"] }
            }
        }
    }

    execDeviceAction = async (item: UscUtm, context: string, payload?: any) => {
        let utmDetailsComponent: any = useVue().$refs.utmDetails
        let result: any = undefined
        let usesCallback: boolean = false
        let accountId = useStore().state.session.activeAccountId || ""
        let dataArray: string[] = context.split(" ")
        if (payload) {
            dataArray.push(payload)
        }
        let doRequest = async (context: string, dataArray: string[]) => {
            let contextMap = products.unifiedSecurityConsole.uscUtms.contextMap
            context = context.replace("-", " ").replace("variable", " ")
            context = contextMap[context as keyof typeof contextMap] || context
            try {
                let result = await objectStores.uscUtms.sendSpcliMessage(
                    useStore().state.session.activeAccountId || "",
                    item.utmId,
                    { data: dataArray }
                )

                if (result) {
                    result.context = context
                    result.issued = result.timestamp
                    result.lastUpdate = result.timestamp
                    result.queue = "out"
                    result.statusText = ""
                    result.type = "spcli"
                    result.status = "PENDING"

                    let index: number = -1

                    if (utmDetailsComponent) {
                        if (result.jobId) {
                            index = utmDetailsComponent?.operationsLog?.findIndex(function (
                                entry: any
                            ) {
                                return entry.jobId == result.jobId
                            })
                        }
                        if (index != -1) {
                            for (let property in result) {
                                utmDetailsComponent.operationsLog[index][property] =
                                    result[property]
                            }
                        } else {
                            utmDetailsComponent?.operationsLog?.unshift?.(result)
                        }
                    }
                }
            } catch (e: any) {
                let errorMessage = e?.message
                if (e?.data?.data?.errors?.payload) {
                    errorMessage = e.data.data.errors.payload
                } else if (e?.data?.errors?.payload) {
                    errorMessage = e?.data?.errors?.payload
                }
                result = false
                let errorMsg = this.formatErrorMessage(errorMessage)
                let activeModal = useStore()?.getters.getActiveModal(accountId)
                if (activeModal != undefined) {
                    useStore()?.commit(MutationTypes.removeModal, { accountId })
                }
                dialogs.misc.errorDialog(accountId, T("Error"), errorMsg)
            }
            return result
        }

        switch (context) {
            case "system upgrade rollback":
                /* Version ist aktuell, es ist kein Update verfügbar */
                usesCallback = true
                this.dialogs.utmEnterPinDialog(
                    item,
                    context,
                    payload,
                    T("Rollback"),
                    false,
                    "",
                    undefined
                )
                break
            case "system upgrade dryrun":
                /* Version ist nicht aktuell, es ist ein Update verfügbar */
                usesCallback = true
                this.dialogs.utmEnterPinDialog(
                    item,
                    context,
                    payload,
                    item.utmname + " - " + T("Dryrun"),
                    false,
                    "",
                    undefined
                )
                break
            case "system upgrade finalize":
                /* Version ist aktuell, aber nicht finalisiert. Nach einem Reboot wird die alte Version geladen */
                usesCallback = true
                this.dialogs.utmEnterPinDialog(
                    item,
                    context,
                    payload,
                    item.utmname + " - " + T("Finalize new version"),
                    false,
                    "",
                    undefined
                )
                break
            case "system upgrade confirm eula":
                /* Version ist nicht aktuell, es ist ein Update verfügbar, Eula muss bestätigt werden */
                usesCallback = true
                await this.dialogs.renderUtmLicenseConditions(
                    async (dialogResult: {
                        accepted: boolean
                        saveDecision: boolean
                        pinEntered: string
                    }) => {
                        if (dialogResult.accepted === true) {
                            if (dialogResult.pinEntered.length == 6) {
                                const payload = {
                                    name: "pin-spcli",
                                    data: {
                                        pin: dialogResult.pinEntered,
                                        command: dataArray
                                    }
                                }
                                await requestHandler.request(
                                    "POST",
                                    config.mgtApiUriNext +
                                        "/tenants/" +
                                        tenantHelpers.getTenantDomain(accountId) +
                                        "/utms/" +
                                        item.utmId +
                                        "/jobs/model",
                                    payload
                                )
                            } else {
                                result = await doRequest(context, dataArray)
                            }
                            if (dialogResult.saveDecision === true) {
                                await useStore()?.dispatch(ActionTypes.setTenantProperty, {
                                    accountId: useStore().state.session.activeAccountId,
                                    property: "flags",
                                    value: {
                                        utmEulaAccepted: true
                                    }
                                })
                            }
                        }
                        await this.execDeviceAction(item, "system info")
                        if (dialogResult.pinEntered.length == 0) {
                            useStore().commit(MutationTypes.removeModal, {
                                accountId: accountId
                            })
                        }
                    }
                )
                break
            case "system upgrade confirm privacy":
                /* Version ist nicht aktuell, es ist ein Update verfügbar, Privacy muss bestätigt werden */
                usesCallback = true
                await this.dialogs.renderUtmPrivacyPolicy(
                    async (dialogResult: {
                        accepted: boolean
                        saveDecision: boolean
                        pinEntered: string
                    }) => {
                        if (dialogResult.accepted === true) {
                            if (dialogResult.pinEntered.length == 6) {
                                const payload = {
                                    name: "pin-spcli",
                                    data: {
                                        pin: dialogResult.pinEntered,
                                        command: dataArray
                                    }
                                }
                                await requestHandler.request(
                                    "POST",
                                    config.mgtApiUriNext +
                                        "/tenants/" +
                                        tenantHelpers.getTenantDomain(accountId) +
                                        "/utms/" +
                                        item.utmId +
                                        "/jobs/model",
                                    payload
                                )
                            } else {
                                result = await doRequest(context, dataArray)
                            }
                            if (dialogResult.saveDecision === true) {
                                await useStore()?.dispatch(ActionTypes.setTenantProperty, {
                                    accountId: useStore().state.session.activeAccountId,
                                    property: "flags",
                                    value: {
                                        utmPrivacyAccepted: true
                                    }
                                })
                            }
                        }
                        await this.execDeviceAction(item, "system info")
                        if (dialogResult.pinEntered.length == 0) {
                            useStore().commit(MutationTypes.removeModal, {
                                accountId: accountId
                            })
                        }
                    }
                )
                break
            case "system reboot":
                usesCallback = true
                await this.dialogs.utmEnterPinDialog(
                    item,
                    context,
                    payload,
                    T("Reboot"),
                    false,
                    "",
                    undefined
                )
                break
            case "system poweroff":
                usesCallback = true
                await this.dialogs.utmEnterPinDialog(
                    item,
                    context,
                    payload,
                    T("Shutdown"),
                    false,
                    "",
                    undefined
                )
                break
            case "system config factorysettings":
                usesCallback = true
                await this.dialogs.utmEnterPinDialog(
                    item,
                    context,
                    payload,
                    T("Reset"),
                    false,
                    "",
                    undefined
                )
                break
        }

        if (!usesCallback) {
            result = await doRequest(context, dataArray)
        }
    }
    async getUtmMessages(accountId: string, utmId: string, limit: number = 50) {
        let result: any = null
        try {
            accountId = tenantHelpers.getAccountId(accountId)
            if (useStore()?.getters.hasAccountid(accountId) == false) {
                throw { status: 401 }
            }
            result = await requestHandler.request(
                "GET",
                "/sms-mgt-api/api/2.0/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/utms/" +
                    utmId +
                    "/messages?limit=" +
                    limit
            )
        } catch (e) {}
        return result
    }
    async getUtmMessage(accountId: string, utmId: string, messageId: string) {
        let result: any = null
        try {
            accountId = tenantHelpers.getAccountId(accountId)
            if (useStore()?.getters.hasAccountid(accountId) == false) {
                throw { status: 401 }
            }
            result = await requestHandler.request(
                "GET",
                "/sms-mgt-api/api/2.0/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/utms/" +
                    utmId +
                    "/messages/" +
                    messageId
            )
        } catch (e) {
            console.error(e)
        }
        return result
    }

    async setUtmUpdateTime(accountId: string, utmId: string, timestamp: number) {
        let result: any = null
        try {
            accountId = tenantHelpers.getAccountId(accountId)
            if (useStore()?.getters.hasAccountid(accountId) == false) {
                throw { status: 401 }
            }
            result = await requestHandler.request(
                "POST",
                "/sms-mgt-api/api/2.0/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/utms/" +
                    utmId +
                    "/scheduler/jobs/",
                {
                    timestamp: timestamp
                }
            )
        } catch (e) {
            result = e
            console.error(e)
        }
        return result
    }
    async deleteUtmUpdateTime(accountId: string, utmId: string) {
        let result: any = null
        try {
            accountId = tenantHelpers.getAccountId(accountId)
            if (useStore()?.getters.hasAccountid(accountId) == false) {
                throw { status: 401 }
            }
            result = await requestHandler.request(
                "DELETE",
                "/sms-mgt-api/api/2.0/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/utms/" +
                    utmId +
                    "/scheduler/jobs/"
            )
        } catch (e) {
            result.e
            console.error(e)
        }
        return result
    }

    getPlanSingleUpdateDialog = function (
        accountId: string,
        utmId: string,
        existingDate: string | undefined = undefined
    ) {
        try {
            let modal: any = {
                id: "uscOneTimeUpdate",
                accountId: accountId,
                abortable: true,
                content: {
                    title: {
                        text: T("One Time Update"),
                        icon: "fal fa-sync"
                    },
                    body: {
                        use: true,
                        content: undefined,
                        component: "usc-one-time-update",
                        properties: {
                            existingDate: existingDate
                        }
                    }
                },
                buttons: [
                    {
                        text: T("Close"),
                        icon: "fal fa-times",
                        onClick: async function () {
                            if (
                                getterHelpers.useVue()?.$refs?.utmDetails?.$refs.cronojoblistRef
                                    ?.getJobList != undefined
                            ) {
                                getterHelpers
                                    .useVue()
                                    .$refs.utmDetails.$refs.cronojoblistRef.getJobList()
                            }
                            getterHelpers
                                .useStore()
                                .commit(MutationTypes.removeModal, { accountId })
                        },
                        loading: false
                    }
                ]
            }
            const deleteButton = {
                text: T("Delete"),
                icon: "fal fa-trash",
                onClick: async function (component: any) {
                    getterHelpers.useStore().state.session.accounts[
                        accountId
                    ].modals[0].buttons[1].disabled = true
                    getterHelpers.useStore().state.session.accounts[
                        accountId
                    ].modals[0].buttons[2].disabled = true
                    getterHelpers.useStore().state.session.accounts[
                        accountId
                    ].modals[0].buttons[1].loading = true
                    getterHelpers.useStore().state.session.accounts[
                        accountId
                    ].modals[0].buttons[2].loading = true

                    await objectStores.uscUtms.deleteUtmUpdateTime(accountId, utmId)
                    component?.$refs.modalComponent.setDate("")
                    if (
                        getterHelpers.useVue()?.$refs?.utmDetails?.$refs.cronojoblistRef
                            ?.getJobList != undefined
                    ) {
                        getterHelpers.useVue().$refs.utmDetails.$refs.cronojoblistRef.getJobList()
                    }

                    getterHelpers.useStore().state.session.accounts[
                        accountId
                    ].modals[0].buttons[1].disabled = false
                    getterHelpers.useStore().state.session.accounts[
                        accountId
                    ].modals[0].buttons[2].disabled = false
                    getterHelpers.useStore().state.session.accounts[
                        accountId
                    ].modals[0].buttons[1].loading = false
                    getterHelpers.useStore().state.session.accounts[
                        accountId
                    ].modals[0].buttons[2].loading = false
                    getterHelpers
                        .useStore()
                        .state.session.accounts[accountId].modals[0].buttons.splice(1, 1)
                },
                loading: false
            }

            if (existingDate) {
                modal.buttons.push(deleteButton)
            }
            modal.buttons.push({
                text: T("Save"),
                icon: "fal fa-save",
                onClick: async function (this: any, component: any, test: any) {
                    const dateString = component?.$refs.modalComponent.dateValue
                    const date = moment(dateString, "DD.MM.YYYY HH:mm")
                    const unixTimestamp = date.unix()

                    let thisIndex = 1
                    if (
                        getterHelpers.useStore().state.session.accounts[accountId].modals[0].buttons
                            .length == 3
                    ) {
                        thisIndex = 2
                    }
                    let thisbutton =
                        getterHelpers.useStore().state.session.accounts[accountId].modals[0]
                            .buttons[thisIndex]

                    getterHelpers.useStore().state.session.accounts[
                        accountId
                    ].modals[0].buttons[1].disabled = true
                    if (
                        getterHelpers.useStore().state.session.accounts[accountId].modals[0].buttons
                            .length == 3
                    ) {
                        getterHelpers.useStore().state.session.accounts[
                            accountId
                        ].modals[0].buttons[2].disabled = true
                    }
                    getterHelpers.useStore().state.session.accounts[
                        accountId
                    ].modals[0].buttons[1].loading = true
                    if (
                        getterHelpers.useStore().state.session.accounts[accountId].modals[0].buttons
                            .length == 3
                    ) {
                        getterHelpers.useStore().state.session.accounts[
                            accountId
                        ].modals[0].buttons[2].loading = true
                    }

                    await objectStores.uscUtms.setUtmUpdateTime(accountId, utmId, unixTimestamp)

                    if (
                        getterHelpers.useVue()?.$refs?.utmDetails?.$refs.cronojoblistRef
                            ?.getJobList != undefined
                    ) {
                        getterHelpers.useVue().$refs.utmDetails.$refs.cronojoblistRef.getJobList()
                    }

                    thisbutton.text = T("Saved") + "!"
                    thisbutton.icon = "fal fa-check"
                    thisbutton.loading = false
                    thisbutton.disabled = false
                    if (
                        getterHelpers.useStore().state.session.accounts[accountId].modals[0].buttons
                            .length == 2
                    ) {
                        modal.buttons.splice(1, 0, deleteButton)
                    }

                    if (
                        getterHelpers.useStore().state.session.accounts[accountId].modals[0].buttons
                            .length == 3
                    ) {
                        getterHelpers.useStore().state.session.accounts[
                            accountId
                        ].modals[0].buttons[1].disabled = false
                    }
                    getterHelpers.useStore().state.session.accounts[
                        accountId
                    ].modals[0].buttons[1].loading = false
                    if (
                        getterHelpers.useStore().state.session.accounts[accountId].modals[0].buttons
                            .length == 3
                    ) {
                        getterHelpers.useStore().state.session.accounts[
                            accountId
                        ].modals[0].buttons[2].loading = false
                    }

                    setTimeout(() => {
                        thisbutton.text = T("Save")
                        thisbutton.icon = "fal fa-save"
                    }, 2000)
                },
                disabled: false,
                loading: false
            })

            getterHelpers.useVue().$refs.modals.addModal(modal)
        } catch (e: any) {
            devLog.log("dialog", e, undefined, "error", 0)
        }
    }

    async getUtmRejectsHistogramm(
        accountid: string,
        utmId: UscUtm["utmId"],
        period: string = "today"
    ) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }

            let timezone =
                useStore()?.state.session.accounts[accountid]?.unifiedSecurity.account.timezone ||
                "+02:00"
            let tenantDomain = tenantHelpers.getTenantDomain(accountid)

            let payload = {
                query: {
                    modul: "CloudUtm",
                    name: "ConnectionRejectHistogramm",
                    options: {
                        relate: "count",
                        period: period,
                        size: 10,
                        offset: 0,
                        timezone: timezone,
                        order: {
                            asc: false
                        },
                        filter: [
                            {
                                field: "device",
                                operator: "equal",
                                value: utmId
                            }
                        ]
                    }
                }
            }
            result = await requestHandler.request(
                "POST",
                "/sms-mgt-api/api/2.0/tenants/" + tenantDomain + "/stats/execute/",
                payload
            )
        } catch (e: any) {
            let errorMessage = e?.message
            if (e?.data?.data?.errors?.payload) {
                errorMessage = e.data.data.errors.payload
            } else if (e?.data?.errors?.payload) {
                errorMessage = e?.data?.errors?.payload
            } else if (e?.data?.errors?.error) {
                errorMessage = e.data.errors.error
            }
            result = false
            let errorMsg = this.formatErrorMessage(errorMessage)
            let activeModal = useStore()?.getters.getActiveModal(accountid)
            if (activeModal != undefined) {
                useStore()?.commit(MutationTypes.removeModal, { accountid })
            }
            dialogs.misc.errorDialog(accountid, T("Error"), T(errorMsg))
        }
        return result
    }

    getNetworkObjectsDialog(accountId: string, utmId: string) {
        new ModalObject({
            accountId: accountId,
            id: "USCNetworkObjects",
            content: {
                title: {
                    icon: "fal fa-wrench",
                    text: "Netzwerkobjekte"
                },
                body: {
                    component: "uscNetworkobjectTable",
                    properties: {
                        accountId: accountId,
                        utmId: utmId
                    }
                }
            }
        }).show()
    }

    handleGlobalWebsocketHooks() {
        getterHelpers.useStore().commit(MutationTypes.addSubscriptionHook, {
            hookKey: "uscUtmsGlobal",
            hookFunction: async (message: any) => {
                const clientContext = message?.data?.clientContext || ""
                if (
                    clientContext == "utm-event-utm-value-changed" ||
                    clientContext == "utm-event-startup-states"
                ) {
                    const changes: { property: string; value: any }[] | undefined =
                        message?.data?.data?.changes || message?.data?.changes
                    const utmId: string | undefined = message?.data?.utmId || message?.utmId
                    const tenantdomain: string | undefined =
                        message?.data?.tenantDomain || message?.tenantDomain
                    const accountId: string | undefined = tenantdomain
                        ? tenantHelpers.getAccountId(tenantdomain)
                        : undefined
                    const utm = accountId
                        ? objectStores.uscUtms.getObjectFromStore(accountId, utmId)
                        : undefined
                    if (utm) {
                        changes?.forEach((change) => {
                            if (change.property == "pinEnabled") {
                                // set pinState if nonexistend
                                if (!utm.pinState) {
                                    utm.pinState = {
                                        enabled: false,
                                        locked: false // if pin can be locked it has to be enabled
                                    }
                                }
                                utm.pinState.enabled = change.value == 1
                            } else if (change.property == "pinLocked") {
                                // set pinState if nonexistend
                                if (!utm.pinState) {
                                    utm.pinState = {
                                        enabled: true, // if pin can be locked it has to be enabled
                                        locked: false
                                    }
                                }
                                utm.pinState.locked = change.value == 1
                            } else if (change.property.indexOf("permissions.") != -1) {
                                // CD permissions
                                const utmPermissions = utm.permissions
                                // Get permission propertyname (schema: permissions.propertyName)
                                let permissionProperty = change.property.split(".")[1]
                                if (utmPermissions) {
                                    if (permissionProperty == "websessionEnabled") {
                                        permissionProperty = "startWebsessionEnabled"
                                    }
                                    if (permissionProperty == "sunEnabled") {
                                        permissionProperty = "manageVPNEnabled"
                                    }

                                    utmPermissions[
                                        permissionProperty as keyof typeof utmPermissions
                                    ] = change.value == 1
                                }
                            }
                        })
                    }
                }
            }
        })
    }
}
