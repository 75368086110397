<template>
    <template v-if="initialized">
        <template v-if="tenant.mobileSecurity.settings.ios.apns.expired === false">
            <p
                v-if="enrollmentType === '1'"
                class="notification"
                :class="{ 'color-white': darkmode == '1' }"
            >
                <i class="fal fa-exclamation-triangle fa-fw color-red"></i>
                &nbsp;&nbsp;
                <b>{{ T("Attention") }}</b
                >:
                {{
                    T(
                        "Profile-based user enrollment is no longer available from iOS 18 and higher."
                    )
                }}
            </p>

            <div class="row padding-xs-y form-group border-bottom">
                <div class="first col-xs-24 col-lg-3">
                    <label class="control-label inputname" for="enrollmentType">
                        {{ T("Enrollment type") }}
                    </label>
                </div>
                <div class="input col-xs-24 col-lg-12">
                    <label>
                        <select v-model="enrollmentType" id="enrollmentType">
                            <option value="0">
                                {{ T("iOS device enrollment") }}
                            </option>
                            <option
                                value="1"
                                v-if="tenant.mobileSecurity.settings.ios.apns.expired === false"
                            >
                                {{ T("Profile Based User Enrollment") }}
                            </option>
                            <option
                                value="2"
                                v-if="tenant.mobileSecurity.settings.ios.apns.expired === false"
                            >
                                {{ T("Account Based User Enrollment") }}
                            </option>
                        </select>
                    </label>
                </div>
                <div class="desc col-xs-24 col-lg-9">
                    <p class="input-description">
                        {{ T("Enrollment type") }}
                    </p>
                </div>
            </div>
            <template v-if="['0', '1'].indexOf(enrollmentType) != -1">
                <div
                    class="row padding-xs-y form-group border-bottom"
                    v-if="license.licenseOptions.length"
                >
                    <div class="first col-xs-24 col-lg-3">
                        <label class="control-label inputname" for="licenseUUID">
                            {{ T("License") }}
                        </label>
                    </div>
                    <div class="input col-xs-24 col-lg-12">
                        <label>
                            <select
                                id="licenseUUID"
                                :disabled="license.disabled"
                                v-model="license.selected"
                                style="width: 100%"
                            >
                                <option value="" disabled>
                                    {{ T("Please select a license") }}
                                </option>
                                <template v-for="license in license.licenseOptions">
                                    <option :value="license.id" :disabled="license.disabled">
                                        {{ license.text }}
                                    </option>
                                </template>
                            </select>
                        </label>
                    </div>
                    <div class="desc col-xs-24 col-lg-9">
                        <p class="input-description">
                            {{ T("The license that will be used for newly enrolled devices.") }}
                        </p>
                    </div>
                </div>
                <div
                    class="row padding-xs-y form-group border-bottom"
                    v-if="license.licenseOptions.length <= 0"
                >
                    <div class="first col-xs-24 col-lg-12">
                        <p class="notification">
                            <i class="fas fa-exclamation-triangle color-red"></i>
                            {{ T("No valid license found") }}
                        </p>
                    </div>
                </div>
            </template>

            <div class="row padding-xs-y form-group border-bottom">
                <div class="first col-xs-24 col-lg-3">
                    <label
                        class="control-label inputname"
                        for="emails"
                        v-html="enrollmentType == '0' ? T('Emails') : T('Managed Apple IDs')"
                    ></label>
                </div>
                <div class="input col-xs-24 col-lg-12">
                    <label :class="{ hidden: enrollmentType != '0' }">
                        <input-vue-select
                            v-model="selected"
                            :selectOptions="options"
                            :placeholder="T('Emails')"
                            :multiple="true"
                            :tags="true"
                            id="emails"
                        >
                        </input-vue-select>
                    </label>

                    <label :class="{ hidden: enrollmentType === '0' }">
                        <input-vue-select
                            v-model="selected"
                            :selectOptions="appleIdOptions"
                            :placeholder="T('Managed Apple IDs')"
                            :multiple="true"
                            id="appleIds"
                        >
                        </input-vue-select>
                    </label>
                </div>
                <div class="desc col-xs-24 col-lg-9">
                    <p class="input-description">
                        <template v-if="enrollmentType == '0'">
                            {{ T("Choose a user or enter an email address") }}
                        </template>
                        <template v-else>
                            {{ T("Choose a user") }}
                        </template>
                    </p>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="row padding-xs-y form-group border-bottom">
                <div class="first col-xs-24 col-lg-12">
                    <p class="notification">
                        <i class="fas fa-exclamation-triangle color-red"></i>
                        <strong> {{ T("No invite/enrollment type usable") }} </strong><br />
                        {{ T("Please set up an apple push certificate.") }}
                    </p>
                    <a
                        :href="'#show-tenant-' + activeTenantDomain + '-mobile-security-settings'"
                        class="btn btn-red"
                    >
                        <i class="fal fa-cogs"></i> {{ T("Set up now") }}
                    </a>
                </div>
            </div>
        </template>
    </template>
    <template v-else-if="!initialized">
        <div class="text-size-3 text-center padding-xs-t-4">
            <loader class="color-red" />
        </div>
        <template v-if="loaderText">
            <div class="text-center padding-xs-t-2 padding-xs-b-4" style="opacity: 0.8">
                <span>{{ T(loaderText) }}</span>
            </div>
        </template>
    </template>
</template>

<script setup lang="ts">
import config from "@/classes/config"
import { T } from "@/classes/i18n"
import objectStores from "@/classes/init"
import products from "@/classes/objectTypes"
import { type User } from "@/classes/unifiedSecurity/users"
import getterHelpers from "@/helpers/helpers.getters"
import mixinHelpers from "@/helpers/helpers.mixins"
import { ActionTypes, useStore } from "@/store/vuex.store"
import { computed, onMounted, ref, watch } from "vue"
import Loader from "../components/loader.vue"
import InputVueSelect from "../inputtypes/input-vue-select.vue"

const activeAccountId = computed(() => {
    return useStore().getters.getActiveAccountId
})
const activeTenantDomain = computed(() => {
    return activeAccountId.value ? activeAccountId.value + ".sms" : undefined
})

const tenant = computed(() => {
    return useStore().state.session.accounts[activeAccountId.value]
})

const loaderText = ref<string | null>("Loading...")
const initialized = ref<boolean>(false)
const enrollmentType = ref<string>("0")

const selected = ref<any[]>([])
const options = ref<any[]>([])
const appleIdOptions = ref<any[]>([])
const license = ref({
    licenseOptions: [] as any[],
    selected: "",
    disabled: false
})
const darkmode = computed(() => {
    return mixinHelpers.getDarkmode()
})

async function init() {
    getterHelpers
        .useStore()
        .getters.getActiveModal(
            getterHelpers.useStore().state.session.activeAccountId
        ).buttons[1].disabled = true

    const accountId = activeAccountId.value

    loaderText.value = "Getting licenses..."

    await useStore().dispatch(ActionTypes.getObjectInfos, {
        accountId: accountId,
        objectTypes: ["licenses"]
    })

    let licenses: object[] = [],
        licenseOptions: any[] = []

    licenses =
        (config.canUseNewObjectType("licenses")
            ? products.unifiedSecurity.licenses.useStore?.().getObjectStoreObjects(accountId)
            : objectStores.licenses.getObjectsFromStore(accountId)) || []

    for (let i: number = 0; licenses.length > i; i++) {
        let license: any = licenses[i]
        let licenceUser = mixinHelpers.getLicenseLimit(license)
        if (
            (license.type == "Mobile Security" || license.type == "MDM") &&
            license.uuid &&
            license.assignedUser < licenceUser &&
            !license.expired &&
            !license.dead
        ) {
            licenseOptions.push({
                id: license.uuid,
                text: `${license.name} | ${license.type} [${license.assignedUser}/${licenceUser}] (${license.uuid.substring(0, 4)})`,
                disabled: license.assignedUser >= licenceUser || license.expired || license.dead,
                licenseType: license.type
            })
        }
    }
    license.value.licenseOptions = licenseOptions

    if (licenseOptions.length) {
        license.value.selected = licenseOptions[0].id
    }

    loaderText.value = "Getting registered Email addresses..."
    try {
        let users: any = {}
        //@ts-ignore
        if (tenant.value.unifiedSecurity.users.length > 0) {
            users = JSON.parse(JSON.stringify(tenant.value.unifiedSecurity.users))
        } else {
            if (config.canUseNewObjectType("users")) {
                await products.unifiedSecurity.users.queries.getObjectsFromApi(accountId)
            } else {
                await useStore().dispatch(ActionTypes.getObjectInfos, {
                    accountId: accountId,
                    objectTypes: ["users"]
                })
            }

            users = config.canUseNewObjectType("users")
                ? products.unifiedSecurity.users.useStore?.()?.getObjectStoreObjects(accountId)
                : useStore().getters.getObjectTypeStore({
                      accountId: accountId,
                      objectType: "users"
                  })?.items || []
        }

        if (users.length) {
            let leadingZeros: number = users.length.toString().length

            for (let userIndex in users) {
                let user: User = users[userIndex]
                options.value.push({
                    text: `${user.username} <${user.profile?.email}>`,
                    id:
                        user.profile?.email +
                        "#usrNr" +
                        (Array(leadingZeros + 1).join("0") + userIndex).slice(-leadingZeros)
                })
                if (user.profile?.appleId) {
                    appleIdOptions.value.push({
                        text: `${user.username} <${user.profile?.appleId}>`,
                        id: JSON.stringify({
                            name: user.username,
                            email: user.profile?.email,
                            appleId: user.profile?.appleId,
                            usrNr: (Array(leadingZeros + 1).join("0") + userIndex).slice(
                                -leadingZeros
                            ),
                            clientUserIdStr: user.profile?.clientUserIdStr
                        })
                    })
                }
            }
        }
    } catch (e: any) {
        console.error(e)
        loaderText.value =
            "<i class='fas fa-exclamation-triangle color-red'></i> Something went wrong"
    }

    loaderText.value = "Finished loading..."
    initialized.value = true
}

function updateButtonState() {
    if (["1", "2"].indexOf(enrollmentType.value) != -1 && license.value.selected == "") {
        getterHelpers
            .useStore()
            .getters.getActiveModal(
                getterHelpers.useStore().state.session.activeAccountId
            ).buttons[1].disabled = true
    } else if (selected.value.length == 0) {
        getterHelpers
            .useStore()
            .getters.getActiveModal(
                getterHelpers.useStore().state.session.activeAccountId
            ).buttons[1].disabled = true
    } else {
        getterHelpers
            .useStore()
            .getters.getActiveModal(
                getterHelpers.useStore().state.session.activeAccountId
            ).buttons[1].disabled = false
    }
}

watch([selected, license.value.selected], () => {
    updateButtonState()
})

watch([enrollmentType], () => {
    selected.value = []
    updateButtonState()
})

onMounted(() => {
    init()
})

defineExpose({
    value: selected,
    enrollmentType,
    license
})
</script>
